import { useState, useRef } from "react";
// types
import { DBTeam } from "../../../firestore/teams";
// context
import TeamShowProvider from "../TeamShowProvider";
import TeamInfoProvider from "../TeamInfoProvider";
// components
import ProfileHero from "./hero/Hero";
import Details from "./details/Details";
import SubViews from "./subViews/SubViews";
import EditModals from "./editModals/EditModals";
// icons
import { LuUnlink } from "react-icons/lu";
import { ImSpinner8 } from "react-icons/im";
import AboutMobile from "./details/AboutMobile";
import TrophiesMobile from "./details/TrophiesMobile";

const Team = () => {
  const [team, setTeam] = useState<DBTeam | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const handleTeamChange = (team: DBTeam | null) => {
    setTeam(team);
  }

  const handleLoadingChange = (loading: boolean) => {
    setLoading(loading);
  }

  const profileHeroRef = useRef(null);

  return (
    <>
      <TeamShowProvider handleTeamChange={handleTeamChange} handleLoadingChange={handleLoadingChange}>
        <TeamInfoProvider team={team}>
          {loading ? (
            <div className="relative w-full min-h-[80vh]
                            flex items-center justify-center">
              <ImSpinner8 className="w-[60px] h-auto aspect-square animate-spin text-green"/>
            </div>
          ) : team ? (
            <>
            <EditModals/>
            {team.dissolved ? (
              <div className="relative w-full min-h-[80vh]">
                <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                              text-xl sm:text-4xl font-semibold text-green
                              flex flex-col sm:flex-row items-center gap-x-4">
                  <p>Team has been dissolved</p>
                  <LuUnlink/>
                </h3>
              </div>
            ) : (
              <div className="text-white pb-[5rem] custom_container">
                <div className="flex gap-x-12 h-fit sm:px-8 lg:px-12">
                  <div className="h-fit w-[65%] flex-grow flex flex-col gap-y-6">
                    <div ref={profileHeroRef} className="w-full h-fit">
                      <ProfileHero className="w-full" />
                    </div>
                    <div className="md:hidden">
                      <AboutMobile/>
                      <TrophiesMobile/>
                    </div>
                    <SubViews/>
                  </div>
                  <Details profileHero={profileHeroRef.current ?? null}/>
                </div>
              </div>
            )}
            </>
          ) : (
            <div className="relative w-full min-h-[80vh]">
              <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                            text-xl sm:text-4xl font-semibold text-green
                            flex flex-col sm:flex-row items-center gap-x-4">
                <p>Team not found</p>
                <LuUnlink/>
              </h3>
            </div>
          )}
        </TeamInfoProvider>
      </TeamShowProvider>
    </>
  );
};

export default Team;
