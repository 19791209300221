import { useState, useEffect, useMemo } from 'react';
// context
import { useTeamContext } from "@components/team/TeamShowProvider";
// types
import { PerformanceHistory, PerformanceHistoryStatus } from "@src/firestore/performanceHistory";
// components
import PerformanceHistoryCard from './PerformanceHistoryCard';
// icons
import { VscDebugDisconnect } from 'react-icons/vsc';

const Performance = () => {
  const { teamPerformanceHistories} = useTeamContext();
  const filteredPerformanceHistories = useMemo(() => teamPerformanceHistories
  .filter((performanceHistory) => (performanceHistory.status !== PerformanceHistoryStatus.declined
                                   && performanceHistory.status !== PerformanceHistoryStatus.ignored)), [teamPerformanceHistories]);

  const [performanceHistoriesByYear, setPerformanceHistoriesByYear] = useState<[string, PerformanceHistory[]][]>([]);

  useEffect(() => {
    const localPerformanceHistoriesByYearObject: Record<string, PerformanceHistory[]> = {};

    for (const performanceHistory of filteredPerformanceHistories) {
      const year = performanceHistory.tournamentStartDate.getFullYear().toString();

      if (localPerformanceHistoriesByYearObject[year]) {
        localPerformanceHistoriesByYearObject[year].push(performanceHistory);
      } else {
        localPerformanceHistoriesByYearObject[year] = [performanceHistory];
      }
    }

    const localPerformanceHistoriesByYearEntries: [string, PerformanceHistory[]][] =
    Object.entries(localPerformanceHistoriesByYearObject).sort((a, b) => parseInt(b[0]) - parseInt(a[0]));

    setPerformanceHistoriesByYear(localPerformanceHistoriesByYearEntries);

  }, [filteredPerformanceHistories]);

  return (
    <div className='flex flex-col gap-y-2 mt-2'>
      <h2 className="font-wide font-bold text-xl !leading-6 uppercase text-white flex items-center gap-2">
        <span>Performance History</span>
      </h2>
      <div className='flex flex-col'>
        {performanceHistoriesByYear.length > 0 ? (
          <>
           {performanceHistoriesByYear.map(([year, histories], index) => {
              return (
                <div key={`performance-history-year-group-${year}`}>
                  {histories.map((performanceHistory) => {
                    return (
                      <PerformanceHistoryCard key={`performance-history-card-${performanceHistory.id}`}
                                              performanceHistory={performanceHistory}/>
                    );
                  })}

                  <div className={`relative w-full h-[1px] bg-lightGray mt-5 sm:mt-4 mb-2 sm:mb-3 ${index === performanceHistoriesByYear.length - 1 ? 'hidden' : ''}`}>
                    <p className='bg-black px-2 font-compact font-medium text-steelGray w-fit leading-4
                                    absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
                      {year}
                    </p>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className='flex flex-col items-center mt-10 gap-y-2'>
            <div className='flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full'>
              <VscDebugDisconnect className='text-white text-2xl'/>
            </div>
            <p className='font-wide text-white text-2xl font-semibold uppercase'>No Data</p>
            <p className='text-steelGray font-compact'>Performance histories will show here after team participates in a tournament</p>
          </div>
        )}
      </div>
    </div>
  )
};

export default Performance;
