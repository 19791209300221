// context
import { useHeaderContext } from "../../../provider/HeaderProvider";
// components
import LoginInput from "./LoginInput";
// icons
import { DiscordIcon } from "@icons/AuthIcons";
import { CrossIcon } from "@icons/Header";
import { DiscordLogin } from "@components/common/authicons-wrapper/DiscordLogin";

const isDev = import.meta.env.DEV;
const discordLoginLink = (isDev: boolean) => {
  if (isDev) {
    return import.meta.env.VITE_DISCORD_LOGIN_LINK_DEV
  } else {
    return import.meta.env.VITE_DISCORD_LOGIN_LINK;
  }
}

const Login = () => {
  const { setNavbarState, navbarState } = useHeaderContext();

  const closeModal = () => {
    setNavbarState({
      ...navbarState,
      logInMenuVisible: false,
    });
  };

  return (
    <div className="bg-lightBlack sm:rounded-xl w-full sm:h-[71vh] overflow-y-auto theme_scroll_bar max-h-[80vh] sm:max-h-[33rem]">
      {/* Header section with title and close button */}
      <div className="flex items-center justify-between border-b border-lightGray px-4 py-3">
        <h2 className="font-wide text-white text-base font-bold leading-normal tracking-[0.01rem]">
          LOGIN
        </h2>
        {/* Close button */}
        <span className="cursor-pointer" onClick={closeModal}>
          <CrossIcon />
        </span>
      </div>
      {/* Social login options section */}
      <div className="p-4 sm:p-6">
        {/* Icons for Google, Discord, and Twitch login */}
        <div className="flex justify-between gap-4 border-b border-lightGray pb-6">
          {/* <AuthIcons url="https://www.twitch.tv/" Icon={<GoogleIcon />} /> */}
          <DiscordLogin url={discordLoginLink(isDev)} Icon={<DiscordIcon />} />
          {/* <AuthIcons url="https://www.twitch.tv/" Icon={<TwitchIcon />} /> */}
        </div>
        {/* Component for handling login input fields */}
        <LoginInput/>
      </div>
    </div>
  );
};

// Exporting the Login component as the default export
export default Login;
