import { doc, DocumentSnapshot, getDoc, SnapshotOptions } from "firebase/firestore";
import { firestore } from "../firebase";
import { DBTeam } from "./teams";

export const trophyPositionToString = (finalPosition: number) => {
  switch (finalPosition) {
    case 1:
      return 'Winner'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return '';
  }
}

enum TrophyType {
  placement,
  special
}

export interface TournamentTrophy {
  id: string,
  dateRecieved: Date,
  tournamentId: string,
  tournamentName: string,
  trophyType: TrophyType,
  position: number,
  winnings: number,
  team: string,
  teamName: string,
  gameName: string,
  players: string[],
  getTeamLogo: () => Promise<string>,
}

export const tournamentTrophyConverter = {
  toFirestore: (trophy: TournamentTrophy) => {
      return {
        id: trophy.id ?? '',
        dateRecieved: trophy.dateRecieved ?? new Date(),
        tournamentId: trophy.tournamentId,
        tournamentName: trophy.tournamentName,
        trophyType: trophy.trophyType,
        winnings: trophy.winnings || 0,
        position: trophy.position ?? null,
        team: trophy.team,
        gameName: trophy.gameName,
        teamName: trophy.teamName,
        players: trophy.players ?? [],
      }
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
      const data = snapshot.data(options)!;
      const trophy: TournamentTrophy = {
        id: data.id ?? snapshot.id ?? '',
        dateRecieved: new Date(data.dateRecieved.seconds * 1000),
        tournamentId: data.tournamentId,
        tournamentName: data.tournamentName,
        trophyType: data.trophyType,
        winnings: data.winnings ?? 0,
        position: data.position ?? null,
        team: data.team,
        gameName: data.gameName,
        teamName: data.teamName,
        players: data.players ?? [],
        getTeamLogo: async () => {
          const teamRef = doc(firestore, 'teams', data.team);
          const teamLogo = ((await getDoc(teamRef)).data() as DBTeam).logoUrl;
          return teamLogo;
        }
      }
      return trophy;
  }
}
