import { useProfileContext } from "../ProfileProvider";
import NoBio from "./NoBio";

interface IBioStandalone {
  className?: string,
}

const BioStandalone: React.FC<IBioStandalone> = ({ className }) => {
  const { profile, editor } = useProfileContext();

  return profile && (profile.bio || editor) ? (
    <div className={`${className}`}>
      <h2 className="font-wide font-bold text-white !leading-5 uppercase
                      flex items-center gap-x-2">
        Bio
      </h2>
      <div className="bg-lightBlack w-full max-[23.438rem]:p-4 p-8 sm:p-4 lg:p-8 rounded-2xl  mt-3 sm:mt-4">
        {profile.bio ? (
          <p className="font-compact font-normal text-base text-steelGray !leading-8 line-clamp-4">
            {profile.bio}
          </p>
        ) : (
          <NoBio editor={editor}/>
        )}
      </div>
    </div>
  ) : '';
}

export default BioStandalone;
