
// context
import UserInfoProvider from "./UserInfoProvider";
import SettingsProvider from "./SettingsProvider";
// components
import SettingsInner from "./SettingsInner";

const Settings = () => {
  return (
    <SettingsProvider>
      <UserInfoProvider>
        <SettingsInner/>
      </UserInfoProvider>
    </SettingsProvider>
  )
}

export default Settings;
