import { useState, useEffect } from 'react';
// context
import { useTournamentContext } from "../TournamentProvider";
// types
import { TournamentGroup } from '@src/firestore/tournaments';
import { DBTeam } from "@src/firestore/teams";
import { LeaderboardGroup } from "../LeaderboardProvider";
// utils
import { getMapCoverImageFromAcronym, getMapNameFromAcronym } from '@utils/Games/ApexLegends';
// icons
import { FaCheck, FaCrown } from "react-icons/fa";
import { SwordsIcon } from "@components/common/icons/TournamentIcons";
import { VscDebugDisconnect } from "react-icons/vsc";
import { MatchPointPlannedMap } from '@src/firestore/tournaments';
import { LocationDropIcon } from '@src/components/common/icons/Common';
import PopoverText from '@src/components/ui/PopoverText';
import { useALGSModeMapContext } from '../ALGSModeMapProvider';


interface IGameMapCard {
  winningTeam: DBTeam | null,
  gameName: string,
  mapAcronym: string,
  groupStarted: boolean,
  gameCompleted: boolean,
  gameInProgress: boolean,
  matchPointWin: boolean,
  groupId: string,
  gameNumber: number,
  algsMode: boolean,
}

const GameMapCard: React.FC<IGameMapCard> = ({groupId, gameNumber, gameName, mapAcronym, groupStarted, gameCompleted, gameInProgress, winningTeam, matchPointWin, algsMode}) => {
  const { setMapOpen, setGroupId, setGameNumber } = useALGSModeMapContext();

  const handleOpenPoiMap = (groupId: string, gameNumber: number) => {
    setMapOpen(true);
    setGroupId(groupId);
    setGameNumber(gameNumber);
  }

  return (
    <div className={`group min-w-[175px] sm:min-w-0 w-full h-auto aspect-[1/1.3] rounded-xl bg-lightBlack p-4
                     border ${gameInProgress && groupStarted ? 'border-green' : 'border-transparent'} flex flex-col gap-4
                     ${algsMode ? 'cursor-pointer' : 'cursor-default'}`}
         onClick={algsMode ? () => handleOpenPoiMap(groupId, gameNumber) : () => false}>
      <div className="relative h-4/5 rounded-lg w-full overflow-hidden">
        {winningTeam ? (
          <div className="w-full flex flex-col gap-y-3 items-center z-[2] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <p className="text-white font-wide font-semibold uppercase text-lg w-full text-center">
              {winningTeam.teamName}
            </p>
            <div className="w-[55%] h-auto aspect-square rounded-lg overflow-hidden border border-white bg-lightBlack">
              <img src={winningTeam.logoUrl} alt="winning team logo"
                  className="w-full h-full object-cover " />
            </div>
          </div>
        ) : ''}
        <img src={getMapCoverImageFromAcronym(mapAcronym)} alt="World's Edge Apex Map"
          className={`w-full h-full object-cover ${gameCompleted ? 'opacity-50' : ''} transition-opacity`}/>
      </div>
      <div className="flex items-center justify-between h-1/6 flex-grow w-full">
        <div className='w-full h-full flex flex-col items-start justify-center gap-y-2'>
          <p className={`text-sm font-wide font-semibold text-white uppercase leading-4`}>{getMapNameFromAcronym(mapAcronym)}</p>
          <p className={`flex items-center gap-x-2 leading-4
                          font-compact ${gameInProgress && groupStarted ? 'text-green' : 'text-steelGray'}`}>
            <span>{gameName}</span>
            {gameInProgress && groupStarted ? (
              <SwordsIcon className="w-[14px] -translate-y-[1px] h-auto aspect-square fill-green"/>
            )
            : gameCompleted ? matchPointWin ? (
              <FaCrown className="w-[12px] -translate-y-[1px] h-auto aspect-square fill-green"/>
            ): (
              <FaCheck className="w-[12px] -translate-y-[1px] h-auto aspect-square fill-green"/>
            ) : ''}
          </p>
        </div>
        {algsMode ? (
          <div className="group/poiButton w-fit h-fit relative">
            <PopoverText visible={true} className='opacity-0 group-hover/poiButton:opacity-100'>
              View POI Dropspots
            </PopoverText>
            <div className='w-[36px] h-auto aspect-square flex-shrink-0 flex items-center justify-center rounded-xl bg-lightGray'>
              <LocationDropIcon className='w-[18px] h-auto aspect-square fill-white group-hover:fill-green transition-colors'/>
            </div>
          </div>
        ) : ''}
      </div>
    </div>
  );
}

interface IGroupMaps {
  group: LeaderboardGroup
}

const GroupMaps: React.FC<IGroupMaps> = ({group}) => {
  const { tournament, tournamentTeams, tournamentStages } = useTournamentContext();

  const [tournamentGroup, setTournamentGroup] = useState<TournamentGroup | null>(null);

  const finalStage = tournament !== null && group.stageNum === tournament.totalStages - 1;

  useEffect(() => {
    const stageNum = group.stageNum;
    const groupId = group.groupId;

    if (tournamentStages.length > 0) {
      const localTournamentGroup = tournamentStages.find((stage) => stage.stageNum === stageNum)?.groups.find((group) => group.id === groupId) ?? null;
      setTournamentGroup(localTournamentGroup);
    }

  }, [group, tournamentStages]);

  const [plannedMaps, setPlannedMaps] = useState<MatchPointPlannedMap[]>([]);

  useEffect(() => {
    if (tournament && tournament.format === 'Match Point') {
      setPlannedMaps(tournament.matchPointFormatPlannedMaps)
    }
  }, [tournament])

  const activeGroupGame = tournamentGroup ? tournamentGroup.activeGame : 0;

  const leaderboardGames = group.games;

  const getWinningTeamFromGame = (gameId: string): DBTeam | null => {
    const leaderboardGame = leaderboardGames.find((game) => game.gameId === gameId);
    const result = leaderboardGame?.result ?? undefined;


    if (result) {
      const winningTeamNum = result.matchResult.team_results.find((team) => team.teamPlacement === 1)!.teamNum - 1;
      const teamsInGroup = tournamentTeams.filter((team) => team.groups.some((teamGroup) => teamGroup.groupId === group.groupId));

      const winningTeamDoc = teamsInGroup.find((team) => team.groups.find((teamGroup) => teamGroup.groupId === group.groupId)?.groupPosition === winningTeamNum);
      if (winningTeamDoc) {
        return winningTeamDoc.DBTeam;
      }
    }

    return null;
  }

  const games = tournamentGroup ? tournamentGroup.games?.sort((a,b) => a.gamePosition - b.gamePosition) ?? [] : [];

  const totalGamesCount = games.length;
  const shellGamesCount = tournament ? tournament.matchPointFormatMaxGames - totalGamesCount : 0;

  const groupStarted = games.find((game) => game.gamePosition === 1)?.playerCodesDistributed ?? false;

  return tournament && games.length > 0 && games.every((game) => game.map) ? (
    <div className='max-sm:maskedListHorz px-[25px] sm:px-0 flex flex-nowrap overflow-x-scroll sm:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 custom_xl:grid-cols-6 gap-x-6 gap-y-8 w-full'>
      {games.map((game, index) => (
        <GameMapCard key={`${game.gameName}-map-card`}
                     groupId={group.groupId}
                     gameNumber={game.gamePosition}
                     groupStarted={groupStarted}
                     matchPointWin={index === totalGamesCount - 1 && tournament.status === 4 && tournament.matchPointFormatFinish}
                     winningTeam={getWinningTeamFromGame(game.id)}
                     gameName={game.gameName}
                     mapAcronym={game.map}
                     gameInProgress={index + 1 === activeGroupGame}
                     gameCompleted={index + 1 < activeGroupGame}
                     algsMode={tournament.gameMode === 'ALGS Mode'}/>
      ))}
      {tournament.format === 'Match Point' && finalStage && tournament.status < 4 ? (
        <>
          {Array.from({length: shellGamesCount}).map((_game, index) => (
            <GameMapCard key={`${totalGamesCount + 1 + index}-map-card`}
                         groupId={group.groupId}
                         gameNumber={totalGamesCount + 1 + index}
                         groupStarted={groupStarted}
                         matchPointWin={false}
                         winningTeam={null}
                         gameName={`Game ${totalGamesCount + 1 + index}`}
                         mapAcronym={plannedMaps.find((plannedMap) => plannedMap.gameNum === totalGamesCount + 1 + index)?.map ?? 'WE'}
                         gameInProgress={false}
                         gameCompleted={false}
                         algsMode={tournament.gameMode === 'ALGS Mode'}/>
          ))}
        </>
      ) : ''}
    </div>
  ) : (
    <div className='flex flex-col items-center mt-10 gap-y-2'>
      <div className='flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full'>
        <VscDebugDisconnect className='text-white text-2xl'/>
      </div>
      <p className='font-wide text-white text-2xl font-semibold uppercase'>Maps not confirmed</p>
      <p className='text-steelGray font-compact'>Maps will show here after they have been confirmed</p>
    </div>
  );
}

export default GroupMaps;
