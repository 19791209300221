import { useEffect, useState } from 'react';
// context
import { useTournamentContext } from './TournamentProvider';
// components
import Modal from '@ui/Modal'
import PillButton from '@ui/PillButton';
import ApexMap from '@components/apex/ApexMap/ApexMap';
import NoticeText from '../ui/NoticeText';

interface IPoiAllocationsVisualiserModal {
  isOpen: boolean,
  close: () => void,
  groupId?: string,
  gameNumber?: number,
}

const PoiAllocationsVisualiserModal: React.FC<IPoiAllocationsVisualiserModal> = ({ isOpen, close, groupId, gameNumber }) => {
  const { tournament, tournamentStages, tournamentTeams } = useTournamentContext();

  const [currentGroupId, setCurrentGroupId] = useState<string>('');
  const [currentGroupIdInitiallySet, setCurrentGroupIdInitiallySet] = useState<boolean>(false);
  const [currentGameNum, setCurrentGameNum] = useState<number>(1);

  const allGroups = tournamentStages.map((stage) => [...stage.groups]).flat();

  useEffect(() => {
    if (allGroups.length > 0 && !currentGroupIdInitiallySet) {
      setCurrentGroupId(allGroups.sort((a, b) => a.stage - b.stage)[0]?.id ?? '');
      setCurrentGroupIdInitiallySet(true);
    }
  }, [allGroups, currentGroupIdInitiallySet]);

  useEffect(() => {
    setCurrentGameNum(1);
  }, [currentGroupId])

  const currentGroup = allGroups.find((group) => group.id === currentGroupId) ?? null;
  const groupGames = (currentGroup ? currentGroup.games ?? [] : []).sort((a, b) => a.gamePosition - b.gamePosition);

  const groupTeams = currentGroup ? tournamentTeams.filter((team) => team.groups.some((group) => group.groupId === currentGroup.id)) : [];
  const allocationsReady = groupTeams.length > 0 ? groupTeams[0].poiAllocations[currentGroup?.id ?? ''] !== undefined : false;

  const currentGame = groupGames.find((game) => game.gamePosition === currentGameNum) ?? null;
  const currentGameMap = currentGame ? currentGame.map : null;

  useEffect(() => {
    if (groupId) {
      setCurrentGroupId(groupId);
      if (gameNumber) {
        setCurrentGameNum(gameNumber);
      }
    }
  }, [groupId, gameNumber]);

  const groupTeamMapLabels = groupTeams.map((team) => ({
    teamId: team.id,
    teamName: team.teamName,
    teamTag: team.DBTeam.teamTag,
    teamLogo: team.DBTeam.logoUrl,
    participatingPlayerData: team.participatingPlayerData,
    DBTeam: team.DBTeam,
    poiAllocation: team.poiAllocations[currentGroup?.id ?? ''] ? team.poiAllocations[currentGroup?.id ?? ''][currentGameMap as 'WE' | 'SP' | 'ED'] : -1
  }));


  const gameMapPresentAndALGSCompatible = currentGame !== null && ['WE', 'SP', 'ED'].includes(currentGame.map);

  return tournament ? (
    <Modal title={'POI Allocations'} xl={true}
           open={isOpen} setOpen={(open: boolean) => !open ? close() : null}
           disableClickOff={true}>
      <div className="flex flex-col gap-y-4">
        <div className='flex flex-col gap-y-2'>

          <div className='flex items-center gap-x-2'>
            {allGroups.map((group) => (
              <PillButton key={`${group.id}-group-pill-button`}
                          active={currentGroupId === group.id} onClick={() => setCurrentGroupId(group.id)}>
                {group.groupName}
              </PillButton>
            ))}
          </div>

          <div className='flex items-center gap-x-2'>
            {groupGames.map((game) => (
              <PillButton key={`${game.id}-game-pill-button`}
                          active={currentGameNum === game.gamePosition} onClick={() => setCurrentGameNum(game.gamePosition)}>
                {game.gameName}
              </PillButton>
            ))}
          </div>
        </div>
        {gameMapPresentAndALGSCompatible ? (
          <ApexMap map={currentGame && currentGame.map !== '' ? currentGame.map as 'WE' | 'SP' | 'ED': 'WE'}
                   teamMapLabels={groupTeamMapLabels}
                   allocationsReady={allocationsReady}/>
        ) : (
          <div className='w-full h-[300px] flex items-center justify-center'>
            <NoticeText>
              Game map does not support visualisation
            </NoticeText>
          </div>
        )}
      </div>
    </Modal>
  ) : '';
}

export default PoiAllocationsVisualiserModal;
