import {
  AuthError,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInWithCustomToken,
  sendEmailVerification,
  User
} from "firebase/auth";
import { collection, query, where, getDocs } from 'firebase/firestore';
import { doc, setDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { toast } from "react-toastify";
import { DBUser, userConverter } from "../firestore/users";
import { v4 as uuidv4 } from 'uuid';


export const login = async (usernameOrEmail: string, password: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(usernameOrEmail)) {
    // email
    await signInWithEmailAndPassword(auth, usernameOrEmail, password);
  } else {
    // username
    const usersCollection = collection(firestore, 'users');
    const userQuery = query(usersCollection, where('username', '==', usernameOrEmail));
    const querySnapshot = await getDocs(userQuery);
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const email = userDoc.data().email;
      await signInWithEmailAndPassword(auth, email, password);
    } else {
      throw new Error('User not found');
    }
  }
};

export interface UserCustomClaims {
  tournamentAdmin: true | undefined;
}

export const getCustomClaims = async (user: User) => {
  const tokenResult = await user.getIdTokenResult();
  return (tokenResult.claims as unknown as UserCustomClaims);
}

export const loginWithDiscord = (url: string) => {
  const state = uuidv4(); // Generate a unique state value
  localStorage.setItem('oauthState', state); // Store the state value locally to validate later

  window.location.href = `${url}?state=${state}`;
};


export const loginCustomToken = async (token: string) => {
  const isDevelopment = import.meta.env.DEV;
  const oauthState = localStorage.getItem("oauthState")
  localStorage.removeItem('oauthState')

  const clearToken = (isDev: boolean) => {
    if (isDev) {
      return import.meta.env.VITE_REMOVE_TOKEN_URL_DEV + `${oauthState}`
    } else {
      return import.meta.env.VITE_REMOVE_TOKEN_URL + `${oauthState}`
    }
  }

  try {
    const userCredential = await signInWithCustomToken(auth, token)
    const user = userCredential.user
    await fetch(clearToken(isDevelopment)).then(() => {
    })

    return user
  } catch (err) {
    console.error(err);
  }
}

export const logout = async () => {
  await signOut(auth);
  toast.info('Signed out successfully')
};

export const signup = async (
  email: string,
  password: string,
  userName: string
) => {
  // Create an authentication user
  const authUser = await createUserWithEmailAndPassword(auth, email, password);

  await sendEmailVerification(authUser.user)

  const userRef = doc(firestore, "users", authUser.user.uid).withConverter(userConverter);

  const partialNewUser = {
    uid: authUser.user.uid,
    email: authUser.user.email || "",
    displayName: userName,
    username: userName,
  };

  const serialisedUser = userConverter.toFirestore(partialNewUser as unknown as DBUser);

  await setDoc(userRef, serialisedUser);
};

export function handleFirebaseAuthError(error: AuthError) {
  let errorMessage = '';
  console.dir(error);
  switch (error.code) {
    case 'auth/wrong-password':
      errorMessage = 'Log In details incorrect. Please try again.';
      break;
    case 'auth/user-not-found':
      errorMessage = 'User not found. Please check your credentials.';
      break;
    case 'auth/too-many-requests':
      errorMessage = 'Too many unsuccessful login attempts. Please try again later.';
      break;
    case 'auth/user-disabled':
      errorMessage = 'This account has been disabled. Please contact support for assistance.';
      break;
    case 'auth/email-already-in-use':
      errorMessage = 'The email address is already in use by another account.';
      break;
    case 'auth/invalid-email':
      errorMessage = 'Invalid email address.';
      break;
    default:
      errorMessage = 'An error occurred during authentication.';
  }

  toast.error(errorMessage);
}
