import { Fragment } from "react";
// context
import { useHeaderContext } from "@provider/HeaderProvider";
// components
import { Dialog, Transition } from "@headlessui/react";
import ForgotPassword from "./ForgotPassword";

const ForgotPasswordModal = () => {
  const { navbarState } = useHeaderContext();

  return (
    <div className="fixed">
        <Transition
          appear
          show={navbarState.forgotPasswordMenuVisible}
          as={Fragment}
        >
          {/* Dialog component for the modal */}
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => false}>
            {/* Overlay for the modal background */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-blackOverLay"
              />
            </Transition.Child>

            {/* Modal content */}
            <div className="fixed inset-0 overflow-y-auto mt-[4.75rem] lg:mt-[3.75erm]">
              <div className="relative flex flex-col min-h-full items-center justify-end md:justify-center text-center">
                {/* Content transition */}
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {/* Panel inside the Dialog */}
                  <Dialog.Panel className="w-full transform overflow-hidden md:max-w-[30rem] mx-auto flex items-center">
                    {/* Rendering the Login component */}
                    <ForgotPassword />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
    </div>
  );
};

export default ForgotPasswordModal;
