import { useEffect } from "react";
// icons
import { GoChecklist } from "react-icons/go";
import { IoCloseSharp } from "react-icons/io5";

interface ICheckListToggle {
  checkListOpen: boolean,
  setCheckListOpen: (open: boolean) => void,
}

const CheckListToggle: React.FC<ICheckListToggle> = ({ checkListOpen, setCheckListOpen }) => {

  useEffect(() => {
    const closeCheckList = () => {
      setCheckListOpen(false);
    };

    window.addEventListener('resize', closeCheckList);

    return () => window.removeEventListener('resize', closeCheckList);
  }, []);

  return (
    <button type='button'
            onClick={() => setCheckListOpen(!checkListOpen)}
            className="fixed lg:hidden z-[11] border-2 border-white bottom-[6rem] right-[6rem] w-[40px] h-auto aspect-square rounded-full
                    flex items-center justify-center bg-green text-black">
      <div className="relative w-fit h-fit">
        <GoChecklist className={`${checkListOpen ? 'opacity-30' : ''} text-3xl transition-opacity`}/>
        <IoCloseSharp className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
                                  text-4xl text-red ${checkListOpen ? '' : 'opacity-0'} transition-opacity`}/>
      </div>
    </button>
  )
}

export default CheckListToggle;
