import { Fragment, useState } from "react";
// packages
import { Transition } from "@headlessui/react";
// icons
import { BellIcon} from "@icons/Common";
import { useNotificationContext } from "@provider/NotificationProvider";
import NotificationsInner from "./Notifications";

const NotificationMenu = () => {
  const { notificationsCount } = useNotificationContext();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      {menuOpen ? (
        <div
          className="fixed top-0 left-0 bg-black h-screen w-screen bg-opacity-[0.4] z-[21]"
          onClick={() => {
            document.body.classList.remove("overflow_hidden");
            setMenuOpen(false);
          }}></div>
      ): ''}

      <div className="mt-[0.5rem]">
        <div className="relative inline-block text-left w-full">
          <button type="button"
                  onClick={() => {
                    document.body.classList.add("overflow_hidden");
                    setMenuOpen(true);
                  }}
                  className="relative w-full flex items-center">
            <span className={`${notificationsCount > 0 ? "" : "hidden"} absolute top-[-0.3rem] z-10 right-[-0.5rem] pt-1
                            font-compact bg-green text-sm text-black font-semibold w-[1.1rem] h-[1.1rem] rounded-full
                            flex items-center justify-center`}>
              {notificationsCount}
            </span>
            <span className="pt-[0.188rem] navigationDropDownIcon header_dropdown_icons -translate-y-[1px]">
              <BellIcon className="w-[22px] h-auto aspect-square fill-steelGray" />
            </span>
          </button>
          <Transition
            as={Fragment}
            show={menuOpen}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div className="fixed custom_lg:absolute z-[22] bg-lightBlack top-[5.2rem] custom_lg:top-[3.9rem] right-3 custom_lg:right-0 rounded-xl min-w-[25rem] w-fit">
              <NotificationsInner setDropdownOverlay={setMenuOpen} />
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

// Exporting the component
export default NotificationMenu;
