import { ChangeEvent, useState, useRef, useEffect, useCallback } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { collection, doc, getDocs, setDoc, Timestamp, where, query, onSnapshot } from 'firebase/firestore';
import { functions } from '@src/firebase';
import { httpsCallable } from "firebase/functions";
// types
import { ApexCode, apexCodeConverter } from "@src/firestore/apexCodes";
// packages
import { toast } from 'react-toastify';
// components
import Modal from '@ui/Modal';
// icons
import { FaCode, FaFileAlt, FaFileUpload } from "react-icons/fa";
import { FaXmark } from 'react-icons/fa6';
import { ImSpinner8 } from 'react-icons/im';
import { formatDate } from '@utils/Date';
import NoticeText from '@ui/NoticeText';

const getLastMatchIndexFromToken = httpsCallable(functions, 'getLastMatchIndexFromToken');

interface IUploadApexCodes {
  highPerformance: boolean
}

const UploadApexCodes: React.FC<IUploadApexCodes> = ({highPerformance}) => {
  const csvInputRef = useRef(null);

  const [codes, setCodes] = useState<ApexCode[]>([]);
  const [savingCodes, setSavingCodes] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);

  const getCodesFromCSV = (e: ChangeEvent<HTMLInputElement>) => {

    const file = e.target.files![0]
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onload = (event) => {
        const localCodes: ApexCode[] = [];

        const csvData = (event.target as FileReader).result;
        if (csvData) {
          const codesRowsRaw = csvData?.toString().split('\n').slice(1);
          const codeRows = codesRowsRaw.map((codeRowRaw) => codeRowRaw.split(','));
          codeRows.forEach((codeRow) => {
            codeRow = codeRow.map((entry) => entry.trim())
            const [batch, activation, expiration, statsToken, adminToken, playerToken] = codeRow;
            if (!playerToken || playerToken[0] !== 'p') return;

            const activationTimestamp = new Date(activation);
            const expirationTimestamp = new Date(expiration);

            const codeObj = {
              groupId: '',
              batch,
              activation: activationTimestamp,
              expiration: expirationTimestamp,
              lastMatchIndex: -1,
              statsToken,
              adminToken,
              playerToken,
              inUse: false,
              used: false,
              highPerformance: highPerformance
            } as ApexCode;

            localCodes.push(codeObj);
          })
        }

        setCodes(localCodes);
      }
      reader.readAsText(file);
    } else {
      setFile(null);
    }
  }

  const clickCSVInput = () => {
    if (csvInputRef.current) {
      (csvInputRef.current as HTMLInputElement).click();
    }
  }

  const clearCSVInput = () => {
    setFile(null);
    if (csvInputRef.current) {
      (csvInputRef.current as HTMLInputElement).value = '';
    }
  }

  const handleSaveCodes = async () => {
    setSavingCodes(true);
    const apexCodesCollection = collection(firestore, 'apexCodes').withConverter(apexCodeConverter);
    const previousCodes = (await getDocs(apexCodesCollection)).docs.map((doc) => doc.data() as ApexCode);

    let duplicateCodeCount = 0;

    const codePromises: Promise<unknown>[] = [];
    for (const code of codes) {
      const newCodeRef = doc(apexCodesCollection);
      if (previousCodes.find((prevCode) => prevCode.playerToken === code.playerToken)) {
        duplicateCodeCount++
        return;
      }

      interface getLastMatchIndexFromTokenResp {
        data: {
          lastMatchIndex: number,
        }
      }

      const lastMatchIndex = (await getLastMatchIndexFromToken({token: code.statsToken}) as getLastMatchIndexFromTokenResp).data.lastMatchIndex;
      code.id = newCodeRef.id;
      code.lastMatchIndex = lastMatchIndex;

      codePromises.push(setDoc(newCodeRef, code));
    }

    const combinedPromise = Promise.all(codePromises);

    toast.promise(combinedPromise, {
      pending: 'Saving apex server codes, this could take a short while...',
      success: duplicateCodeCount > 0 ? `${codes.length - duplicateCodeCount} Apex ${highPerformance ? ' high performance ' : 'regular'} codes saved, ${duplicateCodeCount} duplicates skipped` :
      `${codes.length - duplicateCodeCount} Apex ${highPerformance ? ' high performance ' : 'regular'} codes saved`,
      error: 'Error saving apex server codes'
    })

    combinedPromise.then(() => {
      setSavingCodes(false);
      clearCSVInput();
    })
  }

  interface CodesWithExpiry {
    expirationString: string,
    expirationTimestamp: number,
    codes: ApexCode[]
  }

  const [existingCodes, setExistingCodes] = useState<ApexCode[]>([]);
  const codesInUse = existingCodes.filter((code) => code.inUse).length;
  const [existingCodesGroupedByExpiry, setExistingCodesGroupedByExpiry] = useState<CodesWithExpiry[]>([]);
  const [existingCodesModalOpen, setExistingCodesModalOpen] = useState<boolean>(false);

  const getAllNonExpiredCodesGroupedByExpiry = useCallback(() => {
    // get all non expired codes and group by expiry date
    const apexCodesCollection = collection(firestore, 'apexCodes').withConverter(apexCodeConverter);
    const apexCodesQuery = query(apexCodesCollection, where('expiration', '>', Timestamp.now()), where('highPerformance', '==', highPerformance));
    const unsubscribe = onSnapshot(apexCodesQuery, (snapshots) => {
      const codes = snapshots.docs.map((doc) => doc.data());
      setExistingCodes(codes);
    })

    return unsubscribe;
  }, []);

  const groupExistingCodesByExpiry = useCallback(() => {
    const localExistingCodesGroupedByExpiry: CodesWithExpiry[] = [];

    for (const existingCode of existingCodes) {
      const codeExpirationDateString = formatDate(existingCode.expiration);

      const existingGroupIndex = localExistingCodesGroupedByExpiry.findIndex((codeGroup) => codeGroup.expirationString === codeExpirationDateString);
      if (existingGroupIndex !== -1) {
        localExistingCodesGroupedByExpiry[existingGroupIndex].codes.push(existingCode);
      } else {
        localExistingCodesGroupedByExpiry.push({
          expirationString: codeExpirationDateString,
          expirationTimestamp: existingCode.expiration.getTime(),
          codes: [existingCode]
        });
      }
    }

    setExistingCodesGroupedByExpiry(localExistingCodesGroupedByExpiry.sort((a,b) => a.expirationTimestamp - b.expirationTimestamp));
  }, [existingCodes]);

  useEffect(() => {
    groupExistingCodesByExpiry();
  }, [existingCodes, groupExistingCodesByExpiry]);

  useEffect(() => {
    const unsubscribe = getAllNonExpiredCodesGroupedByExpiry();

    return () => unsubscribe();
  }, [getAllNonExpiredCodesGroupedByExpiry])

  return (
    <div className="flex flex-col items-center bg-lightBlack border border-lightGray p-3 rounded-lg gap-y-2">
      <Modal title="Save Codes"
             open={confirmSaveModalOpen}
             setOpen={setConfirmSaveModalOpen}
             buttonText="Save Codes"
             buttonOnClick={() => {
              handleSaveCodes();
              setConfirmSaveModalOpen(false);
             }}>
        <p className='text-white font-compact font-medium'>
          <span>
            You are about to save {codes.length}
          </span>
          <em className={`${highPerformance ? 'text-red/70' : 'text-green'} not-italic`}>
            {highPerformance ? ' High Performance ' : ' Regular '}
          </em>
          <span>
            codes
          </span>
        </p>
      </Modal>
      <Modal title={`Active ${highPerformance ? ' High Performance ' : ' Regular '} Codes`}
             open={existingCodesModalOpen}
             setOpen={setExistingCodesModalOpen}>
        <div className='text-steelGray font-compact font-medium'>
          {existingCodesGroupedByExpiry.length > 0 ? (
            <>
              <p className='text-white text-lg border-b border-lightGray flex w-full justify-center items-center gap-x-2'>
                <span>
                  Total: <em className='not-italic text-green'>{existingCodesGroupedByExpiry.reduce((acc, curr) => acc + curr.codes.length, 0)}</em>
                </span>
                <span className='text-sm text-steelGray'>(<span className="text-white">{codesInUse}</span> in use)</span>
              </p>
              {existingCodesGroupedByExpiry.map((codeGroup) => {
                const remainingAfterBatchExpiry = existingCodesGroupedByExpiry.reduce((acc, curr) => {
                  if (curr.expirationTimestamp > codeGroup.expirationTimestamp) {
                    return acc + curr.codes.length
                  } else {
                    return acc;
                  }
                }, 0);

                return (
                  <div key={`${highPerformance ? 'Performance' : 'Regular'}-code-group-${codeGroup.expirationTimestamp}`}
                       className="py-3 border-b border-lightGray">
                    <p>
                      <em className='not-italic text-green'>{codeGroup.codes.length}</em> codes expiring: <strong className='text-white'>{codeGroup.expirationString}</strong>
                    </p>
                    <p className={`text-xs`}>
                      (<span className={`${remainingAfterBatchExpiry > 10 ? 'text-white' : remainingAfterBatchExpiry > 0 ? 'text-orange' : 'text-red/70'}`}>
                        {remainingAfterBatchExpiry}
                      </span> will then remain)
                    </p>
                  </div>
                );
              })}
            </>
          ) : (
            <NoticeText>
              No Active Codes In System
            </NoticeText>
          )}
        </div>
      </Modal>
      <div className='flex flex-col gap-y-1 items-center'>
        <h3 className='font-wide text-white uppercase w-fit font-semibold'>Upload <em className='not-italic text-green'>{highPerformance ? ' High Performance ' : 'Regular'}</em> Codes</h3>
        {/* <p className='text-red/70 font-compact text-sm'>{highPerformance ? ' High Performance ' : 'Regular'}</p> */}
        <button type='button'
                onClick={() => setExistingCodesModalOpen(true)}
                aria-label='See more info on available codes'
                className='bg-ebonyClay p-1 px-2 rounded-lg flex items-center gap-x-3 justify-center w-full text-white
                           hover:opacity-75 transition-opacity font-compact'>
          <p><em className='not-italic text-green'>{existingCodes.length}</em> active codes</p>
        </button>
      </div>
      <div className='w-fit'>
        {file ? (
          <>
            <p className='text-white flex items-center gap-x-2 font-compact'>
              <FaCode className='mb-1' />
              <span>
                <em className='not-italic text-green font-semicold'>{codes.length}</em> codes found.
              </span>
            </p>
            <div className='bg-steelGray text-white p-1 rounded my-2 flex items-center justify-between'>
              <div className='flex items-center gap-x-2'>
                <FaFileAlt />
                <p className='max-w-[150px] overflow-scroll whitespace-nowrap'>
                  {file.name}
                </p>
              </div>

              <button type="button" className='text-red/60 hover:text-red/80 transition-colors p-1'
                onClick={clearCSVInput}>
                <FaXmark className='text-xl' />
              </button>
            </div>
          </>
        ) : (
          <p className='my-2 font-compact text-sm font-semibold text-steelGray'>Upload a CSV file to extract codes</p>
        )}

        {file ? (
          <>
            <button type="button"
              disabled={codes.length === 0 || savingCodes}
              aria-label='attach apex server code CSV file'
              className="bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green p-2 px-3 mx-auto text-black rounded-lg flex items-center gap-x-2 uppercase font-wide leading-5 font-semibold"

              onClick={() => setConfirmSaveModalOpen(true)}>
              <span> Save Codes </span>
              {savingCodes ? (
                <ImSpinner8 className="animate-spin"/>
              ) : (
                <FaCode className="mb-1"/>
              )}
            </button>
          </>
        ) : (
          <>
            <button type="button"
              aria-label='attach apex server code CSV file'
              className="bg-green hover:bg-gorse py-2 px-3 mx-auto text-black rounded-lg flex items-center gap-x-2 uppercase font-wide font-semibold"
              onClick={clickCSVInput}>
              <span className='-mb-1'> Upload Codes </span>
              <FaFileUpload className='text-xl' />
            </button>
            {/* <p className='font-compact font-semibold text-center text-steelGray mt-1 w-1/2 min-w-[100px] mx-auto'></p> */}
          </>
        )}
      </div>
      <input type="file"
        accept='.csv'
        ref={csvInputRef}
        onChange={getCodesFromCSV}
        className='hidden' />
    </div>
  )
}

export default UploadApexCodes;
