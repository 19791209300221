import {
  PrivacyPolicyList,
  PrivacyPolicyTitleList,
} from "../common/content/ConditonsHelper";
import { ConditionTitleWrapper } from "../common/condition-wrapper/ConditionWrapper";

const PrivacyPolicy = () => {
  return (
    <div className="xl:pb-[100px] sm:mt-0 mt-[72px] ">
      <ConditionTitleWrapper
        title="Privacy Policy"
        conditionsList={PrivacyPolicyList}
        conditiontitleList={PrivacyPolicyTitleList}
        
      />
    </div>
  );
};

export default PrivacyPolicy;
