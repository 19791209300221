import { Fragment } from "react";
// context
import { useHeaderContext } from "../../../provider/HeaderProvider";
// packages
import { Dialog, Transition } from "@headlessui/react";
// components
import SignUp from "./SignUp";

const SignUpModal = () => {
  const { navbarState } = useHeaderContext();

  return (
    <div className="fixed">
      {/* Checking if the mobile popup should be visible */}
      <Transition
        appear
        show={navbarState.signUpMenuVisible}
        as={Fragment}
      >
        {/* Dialog component for the modal */}
        <Dialog
          as="div"
          className="relative z-[200]"
          onClose={() => false}>
          {/* Overlay for the modal background */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-blackOverLay" />
          </Transition.Child>

          {/* Modal content */}
          <div className="fixed inset-0 overflow-y-auto mt-[4.75rem] lg:mt-[3.75rem]">
            <div className="flex min-h-full items-center justify-center text-center">
              {/* Content transition */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                {/* Panel inside the Dialog */}
                <Dialog.Panel className="w-full self-end md:self-center transform overflow-hidden md:max-w-[30rem] mx-auto flex items-center">
                  {/* Rendering the SignUp component */}
                  <SignUp />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default SignUpModal;
