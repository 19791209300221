import { useEffect, useState, useCallback } from 'react';
import { useTournamentContext } from "../../TournamentProvider";
// types
import { TournamentSubViews } from '@components/tournament/types';
// utils
import { formatDateCountdown } from "../../../../utils/Date";
// sub components
import InfoTop from "./InfoTop";
// icons
import JoinButton from './JoinButton/JoinButton';
import { TournamentStatus } from '../../../../firestore/tournaments';

interface IInfo {
  setCurrentSubView: (subView: TournamentSubViews) => void,
}

const Info: React.FC<IInfo> = ({setCurrentSubView}) => {
  const {
    tournament,
    userTournamentState
  } = useTournamentContext();

  const [checkInCountdown, setCheckInCountdown] = useState<string>('');
  const [checkInClosesCountdown, setCheckInClosesCountdown] = useState<string>('');

  const processCheckInCountdown = useCallback((): NodeJS.Timeout => {
    return setInterval(() => {
      if (tournament) {
        const latestCheckInCountdown = formatDateCountdown(tournament.statusDates.confirmation);
        const latestCheckInClosesCountdown = formatDateCountdown(new Date(tournament.statusDates.confirmation.getTime() + 3_600_000));
        setCheckInCountdown(latestCheckInCountdown);
        setCheckInClosesCountdown(latestCheckInClosesCountdown);

        const millisecondsUntilRegistrationClosing = (tournament.statusDates.confirmation.getTime() - new Date().getTime());
        const localRegistrationClosingSoon = millisecondsUntilRegistrationClosing < 86_400_000; //  < 1 day in milliseconds
        setRegistrationClosingSoon(localRegistrationClosingSoon);
      } else {
        setCheckInCountdown('');
      }
    }, 1000)
  }, [tournament]);

  useEffect(() => {
    // process countdowns dynamically
    const interval = processCheckInCountdown();
    return () => clearInterval(interval);
  }, [tournament, processCheckInCountdown]);

  const [registrationClosingSoon, setRegistrationClosingSoon] = useState<boolean>(false);

  if (tournament) {
    return (
      <div className='h-full'>
        <img
            className="w-full aspect-[2.15] h-auto object-cover md:hidden"
            src={tournament.cardImage}
            alt="tournament hero image"
          />
        <div className="rounded-b-xl custom_xl:rounded-xl bg-lightBlack p-6 custom_2xl:min-w-[27rem] h-full flex flex-col">
          <InfoTop />
          <div className='flex flex-col h-fit flex-grow justify-between'>
            <div className='mt-0'>
            {!userTournamentState.registered && !userTournamentState.waiting && !userTournamentState.confirmed ? (
              <>
                <div>
                    <div className="custom_xl:flex hidden flex-col md:flex-row items-center justify-center md:justify-normal md:gap-3 progress-bar w-full h-[5rem] md:w-auto md:h-auto mt-3 xl:mt-4">
                      <p className={`text-lg text-center font-medium tracking-[0.01rem] font-compact !leading-4
                                    text-green`}>
                        {Math.round(tournament.entrantsCount / tournament.teamCapacity * 100)}%
                      </p>
                      <div className={`hidden sm:block relative duration-300 group-hover:bg-ebonyClay bg-lightGray h-[6px] w-full rounded-md after:content-[''] after:absolute after:top-0 after:left-0 after:h-[6px] after:bg-green after:rounded-md after:shadow-[0px_0px_24px_4px_yellowGreen] overflow-y-hidden`}>
                      <span style={{ width: `${(tournament.entrantsCount / tournament.teamCapacity) * 100}%` }}
                        className={`absolute h-full bg-green`}></span>
                    </div>
                    <p className="text-sm font-normal tracking-[0.009rem] font-compact text-darkGray !leading-4">
                      {tournament.entrantsCount}/{tournament.teamCapacity}
                    </p>
                  </div>
                </div>
              </>
            ) : ''}
              {tournament.status === TournamentStatus.registration ? (
                <div className="text-base font-normal justify-center font-compact !leading-4 text-white hidden md:flex gap-x-2 mt-4">
                  {checkInCountdown ? (
                  <>
                    <p className="text-steelGray">Check-in Opens: </p>
                    <p className={`${registrationClosingSoon ? 'text-red/80' : ''}`}>{checkInCountdown}</p>
                  </>
                  ) : (
                    <p className="text-steelGray">Check-in Opens soon</p>
                  )}
                </div>
              ) : tournament.status === TournamentStatus.confirmation ? (
                <div className="text-base font-normal justify-center font-compact !leading-4 text-white hidden md:flex gap-x-2 mt-4">
                  {checkInClosesCountdown ? (
                  <>
                    <p className="text-steelGray">Check-in Closes: </p>
                    <p>{checkInClosesCountdown}</p>
                  </>
                  ) : (
                    <p className="text-steelGray">Check-in closes soon</p>
                  )}
                </div>
              ) : tournament.status === TournamentStatus.prep ? (
                <div className="text-base font-normal justify-center font-compact !leading-4 text-white hidden md:flex gap-x-2 mt-4">
                    <p className="text-steelGray">Tournament Starting Soon</p>
                </div>
              ) : tournament.status === TournamentStatus.ongoing ? (
                <div className="text-base font-normal justify-center font-compact !leading-4 text-white hidden md:flex gap-x-2 mt-4">
                    <p className="text-steelGray">Tournament Ongoing</p>
                </div>
              ) : (
                <div className="text-base font-normal justify-center font-compact !leading-4 text-white hidden md:flex gap-x-2 mt-4">
                    <p className="text-steelGray">Tournament Complete</p>
                </div>
              )}
            </div>
            <div className="flex gap-x-2 items-center mt-4 flex-grow">
              <JoinButton setCurrentSubView={setCurrentSubView}/>
            </div>
          </div>
        </div>
      </div>
  ) } else {
    return '';
  }
};

export default Info;
