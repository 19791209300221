import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// firebase
import { firestore } from '../../../../firebase';
import { collection, doc, getDoc, onSnapshot, query, updateDoc } from 'firebase/firestore';
// types
import { tournamentConverter, TournamentTeam, TournamentTeamStatus, Tournament } from '../../../../firestore/tournaments';
import { Notification } from '../../../../firestore/notifications';
// context
import { useNotificationContext } from '@provider/NotificationProvider';
// icons
import { MouseClickIcon, LeaveIcon, MegaphoneIcon } from "../../../common/icons/Common";
import { ImSpinner8 } from 'react-icons/im';
import { useAuthContext } from '../../../../provider/AuthContextProvider';
import { FaCheck } from 'react-icons/fa';

interface ITournamentCheckInNotif {
  notification: Notification,
  closeMenu: () => void,
}

export const TournamentWaitingCheckInNotif: React.FC<ITournamentCheckInNotif> = ({notification, closeMenu}) => {
  const navigate = useNavigate();

  const body = notification.body as {tournamentName: string, tournamentId: string}
  const { tournamentName, tournamentId } = body;

  const { userTeam } = useAuthContext();
  const { currentTime, declineTournamentPresence } = useNotificationContext();

  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [tooLittlePlayers, setTooLittlePlayers] = useState<boolean>(true);
  const [tooManyPlayers, setTooManyPlayers] = useState<boolean>(true);

  const [tournamentSlotsLeft, setTournamentSlotsLeft] = useState<number>(-1);
  const [submitting, setSubmitting] = useState<boolean>(false);


  const notifDuration = notification.timeout!.getTime() - notification.timeReceived.getTime();
  const timeLeft = notification.timeout!.getTime() - currentTime; // ms
  const minutesLeft = Math.round(timeLeft / 60_000);
  const percentageTimeLeft = Math.round((timeLeft / notifDuration) * 100);

  const getTournament = async () => {
    try {
      const tournamentRef = doc(firestore, 'tournaments', tournamentId).withConverter(tournamentConverter);
      const tournamentSnapshot = await getDoc(tournamentRef);
      const localTournament = tournamentSnapshot.data()!;
      setTournament(localTournament);
    } catch (err) {
      console.error(err);
    }

  }

  useEffect(() => {
    if (tournament && userTeam) {
      // setTooLittlePlayers(userTeam.players.length < tournament.teamSize);
      // setTooManyPlayers(userTeam.players.length > tournament.maxTeamSize);
      setTooLittlePlayers(false);
      setTooManyPlayers(false);
    }
  }, [tournament, userTeam])

  useEffect(() => {
    getTournament();
  }, [])

  const handleConfirm = async () => {
    setSubmitting(true);
    navigate(`/tournaments/${tournamentId}/checkIn`);
    closeMenu();
    setSubmitting(false);
  }

  const handleDecline = async () => {
    setSubmitting(true);
    await declineTournamentPresence(notification);
    setSubmitting(false);
  }

  const getTournamentSlotsLeft = async () => {
    const tournamentRef = doc(firestore, 'tournaments', tournamentId).withConverter(tournamentConverter);
    const tournament = (await getDoc(tournamentRef)).data()!;
    const tournamentTeamsCollection = collection(firestore, 'tournaments', tournamentId, 'teams');
    const tournamentTeamsQuery = query(tournamentTeamsCollection);
    return onSnapshot(tournamentTeamsQuery, (snapshots) => {
      const tournamentTeams = snapshots.docs.map((snapshot) => snapshot.data() as TournamentTeam);
      setTournamentSlotsLeft(tournament.teamCapacity - tournamentTeams.filter((team) => team.status === TournamentTeamStatus.confirmed).length);
    })
  }

  useEffect(() => {
    getTournamentSlotsLeft();
  }, [])

  if (tournamentSlotsLeft === 0) {
    const notificationRef = doc(firestore, 'notifications', notification.id);
    updateDoc(notificationRef, {dismissed: true});
  }

  // Check in Players check to tournament (Leave team card)

  return tournamentSlotsLeft > 0 ? (
  <div className="relative bg-lightGray rounded-xl p-4 pr-10" key={notification.id}>
    <div className="flex items-start mb-1 gap-x-3">
      <div className='min-w-[30px] w-[30px] h-[30px] rounded-full bg-steelGray/30
                        flex items-center justify-center flex-shrink-0'>
        <MegaphoneIcon className='w-[16px] h-auto aspect-square fill-white text-xl'/>
      </div>
      <div>
        <h3 className="text-base leading-6 text-white font-compact">
          <Link to={`/tournaments/${tournamentId}`} className='text-green not-italic uppercase'>{tournamentName}</Link>
          {' has '}
          <em className='text-green not-italic'>
            {tournamentSlotsLeft}
            {tournamentSlotsLeft !== 1 ? ' spots' : ' spot'}
          </em>
          {' available!'}
          <span className='ml-2 text-steelGray text-sm whitespace-nowrap'>
            {minutesLeft} mins left
          </span>
        </h3>
      </div>
    </div>
    {tournament && (tooLittlePlayers || tooManyPlayers) ? (
        <p className="text-red/70 text-sm font-compact my-2 text-center">
          {`You no longer meet team size requirements!`}
        </p>
      ) : ''}
    <div className="flex items-center justify-between">
      {/* Accept Button */}
      <button
          disabled={tooLittlePlayers || tooManyPlayers || submitting}
          onClick={handleConfirm}
          className={`relative w-[48%] bg-green/40 overflow-hidden uppercase font-medium focus_visible_hidden text-base leading-4 px-3 pt-[0.60rem] pb-[0.425rem] rounded-xl font-compact flex items-center gap-2 justify-center
                      hover:opacity-75 transition-opacity border-green border-2 disabled:opacity-50 disabled:pointer-events-none`}>
          <div className="absolute h-full left-0 top-0 bg-green/80" style={{width: `${percentageTimeLeft}%`, transition: 'width 5s linear'}}></div>
          <div className="relative z-[10] flex items-center gap-x-2">
            <p>Claim</p>
            <span className="mb-1">
              {submitting ? (
                <ImSpinner8 className='animate-spin'/>
              ) : (
                <MouseClickIcon className='fill-black w-[12px] h-auto aspect-square' />
              )}
            </span>
          </div>
        </button>
        {/* Decline Button */}
        <button
          disabled={submitting}
          onClick={handleDecline}
          className="w-[48%] bg-red uppercase font-medium text-base leading-4 px-3 pt-[0.7rem] pb-[0.525rem] rounded-xl font-compact text-black flex items-center gap-2 justify-center
                    hover:opacity-75 transition-opacity disabled:opacity-50 disabled:pointer-events-none h-full">
          Cancel
          <span className="mb-1">
            {submitting ? (
              <ImSpinner8 className='animate-spin'/>
            ) : (
              <LeaveIcon className='w-[14px] h-auto aspect-square fill-black' />
            )}
          </span>
        </button>
    </div>
  </div>
  ) : '';
}

export const TournamentCheckInNotif: React.FC<ITournamentCheckInNotif> = ({notification, closeMenu}) => {
  const navigate = useNavigate();

  const body = notification.body as {tournamentName: string, tournamentId: string};
  const { tournamentId, tournamentName } = body;
  const { userTeam } = useAuthContext();

  const { currentTime, declineTournamentPresence } = useNotificationContext();
  const [tournament, setTournament] = useState<Tournament | null>(null);
  const [tooLittlePlayers, setTooLittlePlayers] = useState<boolean>(true);
  const [tooManyPlayers, setTooManyPlayers] = useState<boolean>(true);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const notifDuration = notification.timeout!.getTime() - notification.timeReceived.getTime();
  const timeLeft = notification.timeout!.getTime() - currentTime; // ms
  const minutesLeft = Math.round(timeLeft / 60_000);
  const percentageTimeLeft = Math.round((timeLeft / notifDuration) * 100);


  const getTournament = async () => {
    try {
      const tournamentRef = doc(firestore, 'tournaments', tournamentId).withConverter(tournamentConverter);
      const tournamentSnapshot = await getDoc(tournamentRef);
      const localTournament = tournamentSnapshot.data()!;
      setTournament(localTournament);
    } catch (err) {
      console.error(err);
    }

  }

  useEffect(() => {
    if (tournament && userTeam) {
      setTooLittlePlayers(userTeam.players.length < tournament.teamSize);
      setTooManyPlayers(userTeam.players.length > tournament.maxTeamSize);
    }
  }, [tournament, userTeam])

  useEffect(() => {
    getTournament();
  }, [])

  const handleConfirm = async () => {
    setSubmitting(true);
    navigate(`tournaments/${tournamentId}/checkIn`);
    closeMenu();
    setSubmitting(false);
  };

  const handleDecline = async () => {
    setSubmitting(true);
    await declineTournamentPresence(notification);
    setSubmitting(false);
  };


  return (
    <div className="relative bg-lightGray rounded-xl p-4 pr-10" key={notification.id}>
      <div className="flex items-start mb-1 gap-x-3">
        <div className='w-[30px] h-[30px] rounded-full bg-steelGray/30
                        flex items-center justify-center flex-shrink-0'>
          <MouseClickIcon className='w-[14px] h-auto aspect-square fill-white text-xl'/>
        </div>
        <div>
          <h3 className="text-base leading-6 text-white font-compact">
            <Link to={`/tournaments/${tournamentId}/checkIn`} className='text-green not-italic uppercase'>{tournamentName}</Link> check in is open!
          </h3>
          <p className='font-compact text-sm text-steelGray'>
            {minutesLeft} mins left
          </p>
        </div>
      </div>
      {tournament && (tooLittlePlayers || tooManyPlayers) ? (
        <p className="text-red/70 text-sm font-compact my-2 text-center">
          {`You no longer meet team size requirements!`}
        </p>
      ) : ''}
      <div className="flex items-center justify-between">
        {/* Accept Button */}
        <button
          disabled={tooLittlePlayers || tooManyPlayers || submitting}
          onClick={handleConfirm}
          className={`relative w-[48%] bg-green/40 overflow-hidden uppercase font-medium focus_visible_hidden text-base leading-4 px-3 pt-[0.60rem] pb-[0.425rem] rounded-xl font-compact flex items-center gap-2 justify-center
                      hover:opacity-75 transition-opacity border-green border-2 disabled:opacity-50 disabled:pointer-events-none`}>
          <div className="absolute h-full left-0 top-0 bg-green/80" style={{width: `${percentageTimeLeft}%`, transition: 'width 5s linear'}}></div>
          <div className="relative z-[10] flex items-center gap-x-2">
            <p>Check-In</p>
            <span className="mb-1">
              {submitting ? (
                <ImSpinner8 className='animate-spin'/>
              ) : (
                <FaCheck />
              )}
            </span>
          </div>
        </button>
        {/* Decline Button */}
        <button
          disabled={submitting}
          onClick={handleDecline}
          className="w-[48%] bg-red uppercase font-medium text-base leading-4 px-3 pt-[0.7rem] pb-[0.525rem] rounded-xl font-compact text-black flex items-center gap-2 justify-center
                    hover:opacity-75 transition-opacity disabled:opacity-50 disabled:pointer-events-none h-full">
          Cancel
          <span className="mb-1">
            {submitting ? (
              <ImSpinner8 className='animate-spin'/>
            ) : (
              <LeaveIcon className='w-[14px] h-auto aspect-square fill-black' />
            )}
          </span>
        </button>
      </div>
    </div>
  );
};
