// Importing icons from the specified file
import {
  ApexGrayIcon,
  TournamentMobileIcon,
  DownRedArrow,
  FortniteGrayIcon,
  GrayMegaphoneIcon,
  GrayProfileIcon,
  GrayTeamIcon,
  GrayTournamentIcon,
  GrayVictoryIcon,
  HomeMobileGrayIcon,
  MembershipInsightsFeatures,
  MembershipTeamFeatures,
  MembershipTournamentsFeatures,
  MembershipTrackingFeatures,
  MembershipVisibilityFeatures,
  NewsMobileIcon,
  ProfileDropdownEditProfileIcon,
  ProfileDropdownMyGamesIcon,
  ProfileDropdownSecurityIcon,
  ProfileDropdownSettingIcon,
  RocketLeagueGrayIcon,
  StateMobileIcon,
  UpGreenArrow,
  ValorantGrayIcon,
  WatchMobileIcon,
} from "../icons/Header";
import { DiscordIcon, TwitchIcon, TwitterIcon, YouTubeIcon } from "../icons/Socials";

// Desktop navigation links
export const DesktopNavigationLinks = [
  // { link: "Home", path: "/" },
  { link: "Tournaments", path: "/tournaments" },
  { link: "Teams", path: "/teams" },
  // { link: "News", path: "/news" },
  // { link: "Creators", path: "/creators" },
  // { link: "Players", path: "/players", label: "Coming Soon" },
];

export const ComingSoonLinks = [
  // { link: "News", path: "/news" },
  // { link: "Creators", path: "/creators" },
  { link: "Players", path: "/players", label: "Coming Soon" },
]

// Mobile navigation links with icons
export const MobileNavigationLinks = [
  { icon: <NewsMobileIcon />, path: "/news" },
  { icon: <WatchMobileIcon />, path: "/creators" },
  { icon: <StateMobileIcon />, path: "/tournaments" },
  { icon: <TournamentMobileIcon />, path: "/teams" },
];

// Profile dropdown navigation links
export const ProfileDropDownNavigationLinks = [
  {
    icon: <ProfileDropdownSettingIcon />,
    title: "Settings",
    link: "/settings",
  },
  {
    icon: <ProfileDropdownEditProfileIcon />,
    title: "Edit Profile",
    link: "/settings",
  },
  {
    icon: <ProfileDropdownSecurityIcon />,
    title: "Account",
    link: "/settings/account",
  },
  {
    icon: <ProfileDropdownMyGamesIcon />,
    title: "Linked Games",
    link: "/settings/games",
  }
];

// Profile dropdown social links with icons
export const ProfileDropDownNavigationSocialLinks = [
  { icon: <TwitchIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />, link: "https://www.twitch.tv/versus_gg_" },
  { icon: <YouTubeIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />, link: "https://youtube.com/@versus_gg" },
  { icon: <TwitterIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />, link: "https://twitter.com/gg_versus" },
  { icon: <DiscordIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />, link: "https://discord.com/invite/versus-gg" },
];

// Membership dropdown features with icons, titles, and paragraphs
export const MembershipFeaturesList = [
  {
    icon: <MembershipTeamFeatures />,
    title: "Teams",
    description: "Create and manage esports teams using custom tools.",
  },
  {
    icon: <MembershipTournamentsFeatures />,
    title: "Tournaments",
    description: "Access exclusive tournaments and compete for prize pools.",
  },
  {
    icon: <MembershipTrackingFeatures />,
    title: "Tracking",
    description: "Track performance trends and identify areas of improvement.",
  },
  {
    icon: <MembershipInsightsFeatures />,
    title: "Insights",
    description: "Stay ahead of the game with data insights, expert analysis and exclusive content.",
  },
  {
    icon: <MembershipVisibilityFeatures />,
    title: "Visibility",
    description: "Showcase earnings, amplify achievements, and attract attention.",
  },
];

// NOTIFICATION POP-UP LIST
export const NotificationPopUpList = [
  {
    icon: <GrayTeamIcon />,
    title: "arochinski invited you to join the team.",
    time: "2 hours ago",
  },
  {
    icon: <GrayTournamentIcon />,
    title: "Your tournament starts in 1 hour, stay tuned.",
    time: "37 min ago",
  },
  {
    icon: <GrayTournamentIcon />,
    title:
      "The tournament you are participating in has changed its date or time. Check it out now.",
    time: "37 min ago",
  },
  {
    icon: <GrayMegaphoneIcon />,
    title: "New tournaments available. See now.",
    time: "37 min ago",
  },
  {
    icon: <GrayVictoryIcon />,
    title:
      "Congratulations on your tournament victory. Your prize will be available in the next few hours.",
    time: "37 min ago",
  },
];

export const HeaderGameRemotePopUpList = [
  { icons: <ValorantGrayIcon /> },
  { icons: <ApexGrayIcon /> },
  { icons: <FortniteGrayIcon /> },
  { icons: <RocketLeagueGrayIcon /> },
];
export const HeaderLocationPopUpList = [{ title: "NA" }, { title: "EU" }];

export const TransactionHistoryWalletPopUpList = [
  { icon: <UpGreenArrow />, price: "€950,50", time: "Today, 13:45" },
  { icon: <DownRedArrow />, price: "€50,00", time: "Yesterday, 05:37" },
  { icon: <DownRedArrow />, price: "€100,00", time: "10 July, 05:37" },
  { icon: <UpGreenArrow />, price: "€500,00", time: "7 July, 09:12" },
  { icon: <DownRedArrow />, price: "€50,00", time: "Yesterday, 05:37" },
  { icon: <DownRedArrow />, price: "€100,00", time: "10 July, 05:37" },
];

export const WalletDepositPopList = [
  {
    price: "€ 10,00",
  },
  {
    price: "€ 25,00",
  },
  {
    price: "€ 50,00",
  },
  {
    price: "€ 75,00",
  },
  {
    price: "€ 100,00",
  },
];

// HOMEPAGE SIDE-BAR LIST

export const HomepageHeaderSideBarList = [
  {
    headerItems: [
      {
        icon: <HomeMobileGrayIcon />,
        title: "Home",
        link: "/",
      },
      { icon: <NewsMobileIcon />, title: "News", link: "/news" },
      { icon: <WatchMobileIcon />, title: "Creators", link: "/creators" },
      {
        icon: <TournamentMobileIcon />,
        title: "Tournaments",
        link: "/tournaments",
      },
      { icon: <GrayTeamIcon />, title: "Teams", link: "/teams" },
      { icon: <GrayProfileIcon />, title: "Players", link: "/players" },
    ],
    sideBarItems: [
      { title: "About", link: "/about" },
      { title: "Services", link: "/services" },
      { title: "Terms of use", link: "/terms" },
      { title: "Privacy Policy", link: "/privacy-policy" },
      { title: "Contact", link: "/contact" },
    ],
  },
];
