import { createContext, useContext, ReactNode, useState } from "react";

interface SettingsContext {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
}

const defaultSettingsContext = {
  sidebarOpen: false,
  setSidebarOpen: (open: boolean) => {return open},
}

export const SettingsContext = createContext<SettingsContext>(defaultSettingsContext);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    console.error("useSettingsContext must be used within SettingsProvider");
  }
  return context;
}

interface ISettingsProvider {
  children: ReactNode;
}

const SettingsProvider: React.FC<ISettingsProvider> = ({ children }) => {
  // Mobile sidebar visible state
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const contextValue: SettingsContext = {
    sidebarOpen,
    setSidebarOpen
  };

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
}

export default SettingsProvider;
