import { ReactNode } from 'react';
// types
import { DBTeam } from "@src/firestore/teams";
import { TournamentTeamStatusWithEliminated } from "@components/tournament/TournamentProvider";
// icons
import { Flag, LeaveIcon, TargetIcon } from '@icons/Common';
import { FaClock, FaCheck } from "react-icons/fa";
import PopoverText from '@ui/PopoverText';

const tournamentTeamStatusWithEliminatedToString= (status: TournamentTeamStatusWithEliminated): string => {
  switch (status) {
    case TournamentTeamStatusWithEliminated.registered:
      return 'Registered';
    case TournamentTeamStatusWithEliminated.waiting:
      return 'In Waiting List';
    case TournamentTeamStatusWithEliminated.confirmed:
      return 'Checked-In';
    case TournamentTeamStatusWithEliminated.declined:
      return 'Declined Check In';
    case TournamentTeamStatusWithEliminated.ignored:
      return 'Ignored Check In';
    case TournamentTeamStatusWithEliminated.eliminated:
      return 'Eliminated';
    default:
      return '';
  }
}

const getEntrantsTeamIconFromStatus = (status: TournamentTeamStatusWithEliminated): ReactNode => {
  switch (status) {
    case TournamentTeamStatusWithEliminated.registered:
      return <FaClock className='text-white w-[18px] h-auto aspect-square'/>
    case TournamentTeamStatusWithEliminated.waiting:
      return <FaClock className='text-white w-[18px] h-auto aspect-square'/>
    case TournamentTeamStatusWithEliminated.confirmed:
      return <FaCheck className='text-green w-[18px] h-auto aspect-square'/>
    case TournamentTeamStatusWithEliminated.declined:
      return <LeaveIcon className='w-[18px] h-auto aspect-square fill-steelGray'/>
    case TournamentTeamStatusWithEliminated.ignored:
      return <Flag className='w-[18px] h-auto aspect-square fill-red'/>
    case TournamentTeamStatusWithEliminated.eliminated:
      return <TargetIcon className='w-[18px] h-auto aspect-square fill-steelGray'/>
    default:
      return '';
  }
}

interface IMiniTeamCard {
  team: DBTeam | null,
  status: TournamentTeamStatusWithEliminated | null
}

const MiniTeamCard: React.FC<IMiniTeamCard> = ({team, status}) => {
  return team ? (
    <div className='flex items-center justify-between gap-x-4 w-full'>
      <div className="w-full flex items-start gap-x-4">
        <img src={team.logoUrl} alt={`${team.teamName} logo`}
            className="w-[50px] h-auto aspect-square rounded-lg object-cover"/>
        <div>
          <p className="font-wide lg:text-lg uppercase text-white font-medium">
            {team.teamName}
          </p>
          <p className="text-steelGray font-compact">
            {team.teamTag}
          </p>
        </div>
      </div>
      {status !== null ? (
        <div className="group w-fit h-fit relative">
          <PopoverText visible={true} className='opacity-0 group-hover:opacity-100 transition-opacity'>
            {tournamentTeamStatusWithEliminatedToString(status)}
          </PopoverText>
          <div className='flex items-center justify-center w-fit p-2 h-auto aspect-square rounded-full bg-lightGray'>
            {getEntrantsTeamIconFromStatus(status)}
          </div>
        </div>
      ) : ''}
    </div>
  ) : '';
};

export default MiniTeamCard;
