// context
import { useAuthContext } from '@provider/AuthContextProvider';
import { useNotificationContext } from "@provider/NotificationProvider";
// components
import TeamInviteNotif from './TeamInviteNotif';


const TeamInviteNotifs = () => {
  const { userObj } = useAuthContext();
  const { teamInvites, acceptTeamInvite, declineTeamInvite } = useNotificationContext();

  return (
    <>
      {teamInvites.map((invite, index) => (
        <TeamInviteNotif key={`team-invite-${invite.teamId}}`}
                         index={index}
                         invite={invite}
                         userObj={userObj}
                         acceptTeamInvite={acceptTeamInvite}
                         declineTeamInvite={declineTeamInvite}/>
      ))}
    </>
  )
}

export default TeamInviteNotifs;
