import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// context
import { TeamInfoContext } from "../TeamInfoProvider";
// types
import { DocumentReference } from "firebase/firestore";
// components
import SearchTeamMate from "./SearchTeamMate";
// icons
import { CreateBackArrowIcon, RightCheckIcon } from "../../common/icons/ProfileIcons";
import { CrossIcon } from "../../common/icons/FilterIcons";
import { ImSpinner8 } from "react-icons/im";

interface IProps {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

const CreateTeamInvitation = (props: IProps) => {
  const navigate = useNavigate();

  const { edit, submitTeam, updateTeam } = useContext(TeamInfoContext);

  const { changeModalStep, closeModal } = props;
  // tracking whether form is submitting to prevent multple submittion attemps (button disabled on submitting)
  const [submitting, setSubmitting] = useState<boolean>(false);


  const handleTeamUpdate = async () => {
    setSubmitting(true); // state to track whether form currently submitting
    const teamUpdated: boolean = await updateTeam();
    if (teamUpdated) {
      closeModal();
    }
    setSubmitting(false);
  }

  const handleTeamSubmit = async () => {
      setSubmitting(true); // state to track whether form currently submitting
      const docSnapshot = await submitTeam();
      if (docSnapshot instanceof DocumentReference) {
        navigate(`/my-team`);
        closeModal();
      }
      setSubmitting(false);
  }

  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="px-4 py-[0.75rem] border-b-[0.063rem] border-lightGray">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <span
              className="cursor-pointer"
              onClick={() => changeModalStep(-1)}
            >
              <CreateBackArrowIcon />
            </span>
            <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
              {edit ? 'Edit Team' : 'Create team'}
            </h5>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => closeModal()}
          >
            <CrossIcon />
          </span>
        </div>
      </div>
      <div className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px] flex flex-col">

        <div className="w-full p-[1.5rem] ">
          <div className="flex gap-[1.375rem] items-center justify-center">
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center">
              <RightCheckIcon />
            </div>
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center progress_tricks_line after:!bg-green">
              <RightCheckIcon />
            </div>
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center progress_tricks_line after:!bg-green">
              <RightCheckIcon />
            </div>
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center progress_tricks_line after:!bg-green">
              <RightCheckIcon />
            </div>
            <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:bg-green bg-lightBlack"></div>
          </div>
        </div>
        <SearchTeamMate/>
      </div>
      <div
        className="py-[0.75rem] px-4 flex border-t-[0.063rem] border-lightGray"
      >
        <button
          type="submit"
          disabled={submitting}
          onClick={() => {
            if (edit) {
              handleTeamUpdate()
            } else {
              handleTeamSubmit()
            }
          }}
          className={`flex justify-center items-center font-compact w-full text-base font-medium py-4 !leading-4 rounded-xl uppercase text-center common_green_btn gap-2
                      ${submitting ? 'opacity-75 cursor-not-allowed' : ''}`}
        >
          <span className="mb-[-0.1rem]">{edit ? 'Update Team' : 'Create team'}</span>
          <span className="mb-[0.01rem]">
            {submitting ? (
              // icon spinner
              <ImSpinner8 className="text-lg animate-spin"/>
            ) : (
              <RightCheckIcon />
            )}
          </span>
        </button>
      </div>
    </div>
  );
};

export default CreateTeamInvitation;
