import ComingSoon from "../../common/coming-soon/ComingSoon";

const Stats = () => {
  return (
    <div className="pt-16 text-center">
      <ComingSoon title={'Stats'} className="h-[450px]"/>
    </div>
  );
};

export default Stats;
