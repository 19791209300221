interface IconProps {
  className?: string
}

const SurfsharkIcon: React.FC<IconProps> = ({className}) => (
  <div className={`${className} overflow-hidden`}>
    <svg viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        className="scale-[1.5]">
      <path d="m706 356.3v-.3c-.3-4.3-.6-9.3-.9-14.6-.6-10-1.3-20.8-1.9-29-1-5.7-2.4-10.8-4-15.4-10.2-22.6-30.1-33.5-51.1-38.7-9.6-1.7-21.3-2-33.8-2.3-.6 0-1.2 0-1.8 0h-118.8c-77.7 4.2-110.3 49.2-119.1 73.1-34 101.7-55.2 233.5-70.5 328.6-.3 1.9-.6 3.7-.9 5.5l-5 45.2c-.3 7.1.4 14.9 2.3 22.4 9.5 27.6 38.3 50.8 101.7 27.7 59.9-26 130.6-58.4 203.7-94.7 41.6-24 102.6-79.2 105.8-148.9-.5-51.6-2.2-105.8-5.7-158.6zm-96.3 38.2c0 6-4.9 10.9-10.9 10.9-36.3 0-65.6 29.4-65.6 65.6v40.2c0 67.5-54.7 122.1-122.1 122.1-5.9 0-10.6-4.8-10.6-10.6v-33.3c0-6 4.9-10.9 10.9-10.9 36.3 0 65.6-29.4 65.6-65.6v-40.2c0-67.4 54.7-122.1 122.1-122.1 5.9 0 10.6 4.8 10.6 10.6z" fill="#fff" className="fill-inherit"/>
    </svg>
  </div>
);


export default SurfsharkIcon;
