import { useState, useEffect, useCallback} from "react";
import { Link } from "react-router-dom";
// content
import { useTournamentProvider } from "../../../provider/LayoutProvider";
// types
import { tournamentRegionToString, TournamentStatus, Tournament } from "../../../firestore/tournaments";
// utils
import { gameToString } from '@customTypes/Games/Games';
import { formatDate, formatDateCountdown } from "../../../utils/Date";
// assetts
import trophyGoldNumber from "@assets/images/tournament/trophyGoldNumber.png";
// icons
import { TrophyIcon, CogIcon, SlidersIcon } from "../../common/icons/Common";
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon  } from "../../common/icons/Games";
import { TeamFeesIcon } from "../../common/icons/TournamentIcons";
import { FaCheck } from "react-icons/fa";
import { TargetIcon } from "@icons/Common";

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[18px] h-auto aspect-square fill-white"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[18px] h-auto aspect-square fill-white"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[18px] h-auto aspect-square fill-white"/>)
  }
  return 'no icon'
};

const minifyGameModeString = (gameMode: string): string => {
  switch (gameMode) {
    case 'Battle Royale: Solos':
      return 'BR: Solos';
    case 'Battle Royale: Duos':
      return 'BR: Duos';
    case 'Battle Royale: Trios':
      return 'BR: Trios';
    case 'ALGS Mode':
      return 'ALGS';
    default:
      return '';
  }
}

interface ITournamentCard {
  tournament: Tournament
}

const TournamentCard: React.FC<ITournamentCard> = ({tournament}) => {
  const { listLayout } = useTournamentProvider();

  const completed = tournament.completed;
  const winner = tournament.winner;

  const [checkInCountdown, setCheckInCountdown] = useState<string>('');
  const [checkInClosesCountdown, setCheckInClosesCountdown] = useState<string>('');

  const totalPrizePool = tournament ? tournament.prizePool.prizes.reduce((acc, prize) => acc + prize.prizeAmount, 0) : 0;
  const capacityPercentage = Math.round(Math.min((tournament.entrantsCount / tournament.teamCapacity) * 100, 100));

  const processCountdowns = useCallback((): NodeJS.Timeout => {
    return setInterval(() => {
      const latestCheckInCountdown = formatDateCountdown(tournament.statusDates.confirmation);
      const latestCheckInClosesCountdown = formatDateCountdown(new Date(tournament.statusDates.confirmation.getTime() + 3_600_000));
      setCheckInCountdown(latestCheckInCountdown);
      setCheckInClosesCountdown(latestCheckInClosesCountdown);
    }, 1000)
  }, [tournament.statusDates.confirmation]);

  useEffect(() => {
    const interval = processCountdowns();

    return () => clearInterval(interval);
  }, [processCountdowns]);

  return (
      <div className={`${listLayout ? "w-full" : "w-[90%] md:w-full flex-shrink-0 max-w-[325px] h-auto aspect-[0.57]"}`}>
          <div
              className={`relative tournaments_card_parent  block bg-lightBlack md:hover:bg-graphiteGray duration-300 group overflow-hidden rounded-lg h-full ${listLayout ? "flex justify-between w-full" : "flex flex-col"
                  }`}>
              <Link to={`/tournaments/${tournament.id}${tournament.completed ? '/results' : ''}`}
                    className="absolute top-0 left-0 w-full h-full z-[1]">
              </Link>
              {completed ? (
                <div className="absolute z-[10] top-2 right-2 w-[30px] h-auto aspect-square rounded-full bg-lightGray
                                flex items-center justify-center">
                  <FaCheck className="fill-green"/>
                </div>
              ) : ''}
              <div className={`${listLayout ? "w-[25rem] h-full" : "w-full h-[30%]"} overflow-hidden`}>
                <img
                    className={`image-smooth w-full h-full object-cover object-top group-hover:scale-105 transition-transform`}
                    src={tournament.cardImage}
                    alt="image"
                />
              </div>
              <div className={`p-4 py-6 xl:p-6 w-full flex-grow ${listLayout ? "flex justify-between pl-3 md:pl-6 !py-0 !pr-0" : "flex flex-col"}`}>
                  <div className={`${listLayout ? "pr-2 lg:!pr-4 w-full" : ""}`}>
                          <div>
                              <div className="flex justify-between">
                                  <div className="w-full">
                                      <div className="flex items-center gap-x-2">

                                        <div className={`px-[0.65rem] py-[0.475rem] sm:px-3 sm:py-[0.594rem] bg-lightGray rounded-full flex items-center whitespace-nowrap ${listLayout ? "hidden" : ""} mt-1`}>
                                          <span className="inline-block">
                                              {gameNameToIcon(gameToString(tournament.game))}
                                          </span>
                                          <span className={`font-compact font-normal text-base !leading-4 ms-[0.6rem] tracking-[0.009rem] -mb-[0.188rem]`}>
                                              {gameToString(tournament.game)}
                                          </span>
                                        </div>

                                        <div className={`px-[0.65rem] py-[0.475rem] sm:px-3 sm:py-[0.594rem] bg-lightGray rounded-full flex items-center whitespace-nowrap ${listLayout ? "hidden" : ""} mt-1`}>
                                          <span className="inline-block">
                                            <TargetIcon className="w-[16px] h-auto aspect-square fill-white"/>
                                          </span>
                                          <span className={`font-compact font-normal text-base !leading-4 ms-[0.6rem] tracking-[0.009rem] -mb-[0.188rem]`}>
                                            {tournamentRegionToString(tournament.region)}
                                          </span>
                                        </div>

                                      </div>
                                      <h3 className={`relative group/title font-wide sm:pr-5 xl:pr-0 overflow-visible text-white duration-300 group-hover:text-green font-bold text-[1.65rem] min-h-[3rem]  xl:min-h-[4rem] xl:text-3xl uppercase mt-6 !leading-6 xl:!leading-8
                                                      ${listLayout ? "mb-3 md:text-xl xl:text-2xl pt-2" : "mb-4 xl:mb-6"}`}>
                                          {tournament.name.length > 30 && !listLayout ? (
                                            <>
                                              <span>{tournament.name.slice(0,30)}...</span>
                                              <span className="font-compact font-normal p-1 opacity-0 group-hover/title:opacity-100 transition-opacity
                                                               absolute z-40 bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2
                                                               text-sm w-fit min-w-[200px] h-fit text-white/75 bg-lightGray/90 border border-steelGray/50 rounded-lg">
                                                {tournament.name}
                                              </span>
                                            </>
                                          )
                                          :
                                            tournament.name
                                          }
                                      </h3>
                                  </div>
                              </div>
                          </div>
                          <div
                              className={`flex flex-row items-center justify-normal gap-3 mb-4  w-full h-auto`}>
                              <p className="text-lg font-medium tracking-[0.01rem] font-compact text-green !leading-4">
                                {Math.round(capacityPercentage)}%
                              </p>
                              <div className={`block relative duration-300 group-hover:bg-ebonyClay bg-lightGray h-[6px] w-full rounded-md after:content-[''] after:absolute after:top-0 after:left-0 after:h-[6px] after:bg-green after:rounded-md after:shadow-[0px_0px_24px_4px_yellowGreen] overflow-y-hidden`}>
                                  <span
                                      style={{ width: `${capacityPercentage}%` }}
                                      className={`absolute  bg-green h-full`}
                                  ></span>
                              </div>
                              <p className="font-normal tracking-[0.009rem] font-compact text-darkGray !leading-4">
                                {tournament.entrantsCount}/{tournament.teamCapacity}
                              </p>
                          </div>
                      {completed ? (
                        <div className="flex items-center justify-center gap-x-1 my-2">
                          <p className="text-green font-compact font-thin text-center w-fit">
                            Event Complete:
                          </p>
                          <p className="text-white font-compact font-thin text-center w-fit">
                            {formatDate(tournament.statusDates.results)}
                          </p>
                        </div>
                      ) : (
                        <span
                            className={`font-compact font-medium text-lg tracking-[0.01rem] my-5 flex sm:justify-between items-center text-white gap-4
                                        ${listLayout ? "!justify-start !text-[0.625rem] sm:!text-sm lg:!text-sm": ""}`}>
                            <div className="w-full">
                              {tournament.status === TournamentStatus.registration ? (
                                <>
                                  {checkInCountdown ? (
                                    <div className={`w-full flex gap-x-2 items-center ${listLayout ? "justify-start": "justify-between"}`}>
                                      <p className="text-steelGray">Check-in Opens: </p>
                                      <p>{checkInCountdown}</p>
                                    </div>
                                    ) : (
                                      <p className="text-steelGray">Check-in Opens soon</p>
                                    )}
                                </>
                              ) : tournament.status < TournamentStatus.ongoing ? (
                                <>
                                  {checkInClosesCountdown ? (
                                  <>
                                    <div className={`w-full flex gap-x-2 items-center ${listLayout ? "justify-start": "justify-between"}`}>
                                      <p className="text-steelGray">Check-in Closes: </p>
                                      <p>{checkInClosesCountdown}</p>
                                    </div>
                                  </>
                                  ) : (
                                    <p className="text-steelGray">Check-in closes soon</p>
                                  )}
                                </>
                              ) : tournament.status === TournamentStatus.ongoing ? (
                                <p className="text-steelGray">Tournament Ongoing</p>
                              ) : (
                                <p className="text-steelGray">Tournament Complete</p>
                              )}
                            </div>
                        </span>
                      )}
                  </div>
                  {completed ? (
                    <div className="w-full flex flex-col flex-grow justify-end gap-y-6">
                      {winner ? (
                        <div className="bg-lightGray rounded-xl p-4 flex items-center justify-between w-full">
                          <div className="flex items-center gap-x-5">
                            <div className="relative w-[42px] h-auto aspect-square flex-shrink-0">
                              <img src={trophyGoldNumber} alt="Gold trophy icon with number 1"
                                   className="absolute top-0 right-0 translate-x-1/3 -translate-y-1/3
                                              w-[25px] h-auto object-contain" />
                              <img src={winner.DBTeam.logoUrl} alt={`Winning team logo: ${winner.DBTeam.teamName}`}
                                  className="w-full h-full rounded-xl object-cover"/>
                            </div>

                            <div className="flex flex-col items-start gap-y-1 h-fit">
                              <p className="font-wide text-lg leading-[1rem] uppercase font-semibold text-white">{winner.DBTeam.teamName}</p>
                              <p className="font-wide uppercase text-steelGray font-medium leading-[1rem]">{winner.DBTeam.teamTag}</p>
                            </div>
                          </div>

                          <div className="flex flex-col items-center gap-y-1 h-fit">
                            <p className="font-wide text-lg leading-[1rem] uppercase font-semibold text-[#D9A746]">{winner.points}</p>
                            <p className="font-wide text-steelGray font-medium uppercase leading-[1rem]">PTS</p>
                          </div>
                        </div>
                      ) : ''}
                      <Link to={`/tournaments/${tournament.id}/results`}
                            className="relative z-[2] w-full font-wide uppercase text-lg font-semibold text-black p-2 py-[1rem]  bg-green hover:bg-gorse
                                       flex items-center gap-x-4 justify-center rounded-xl tracking-tight">
                        <span className="-mb-1">View Results</span>
                        <TrophyIcon className="w-[14px] h-auto aspect-square fill-black"/>
                      </Link>
                    </div>
                  ) : (
                    <div className="flex flex-col justify-between flex-grow">

                      <div className={`flex ${listLayout ? "hidden" : ""} items-center justify-evenly duration-300 bg-lightGray p-4 py-6 rounded-lg`}>
                          <div
                              className={`flex flex-col justify-center items-center gap-2`}>
                              <h3 className="flex items-center gap-x-2 font-compact font-normal text-base tracking-[0.01rem] text-darkGray !leading-4">
                                <CogIcon className="w-[14px] -translate-y-[1px] h-auto aspect-square fill-darkGray"/>
                                <span className="font-medium">Mode</span>
                              </h3>
                              <p className="flex items-center gap-2 font-medium text-base font-compact">
                                {minifyGameModeString(tournament.gameMode)}
                              </p>
                          </div>
                          <span className={`h-[3rem] w-[1px] bg-ebonyClay duration-300 divide-black mx-5 lg:mx-7 xl:mx-9`}></span>
                          <div className={`flex flex-col justify-center items-center gap-2`}>
                            <h3 className="flex items-center gap-x-2 font-compact font-normal text-base tracking-[0.01rem] text-darkGray !leading-4">
                              <SlidersIcon className="w-[16px] -translate-y-[1px] h-auto aspect-square stroke-darkGray fill-darkGray"/>
                              <span className="font-medium">Format</span>
                            </h3>
                            <p className="flex items-center gap-2 font-medium text-base font-compact">
                              {tournament.format}
                            </p>
                          </div>
                      </div>
                      <div className={`flex flex-col gap-y-3 text-lg font-medium font-compact mt-4 xl:mt-6
                                      ${listLayout ?
                                        `px-2 border-l border-l-lightGray group-hover:border-l-ebonyClay
                                        flex-col items-center justify-around !text-green !font-bold
                                        min-w-[100px]`
                                        : ""}`}>
                        <p className={`font-compact text-white flex justify-between items-center
                                       ${listLayout ? "flex-col" : ""}`}>
                          <span
                              className={`text-darkGray font-compact
                                          ${listLayout ? "text-sm whitespace-nowrap xl" : ""}`}>
                              Prize Pool
                          </span>
                          <span className="font-medium font-wide text-white group-hover:text-green transition-colors">
                            {totalPrizePool > 0 ? `${tournament.prizePool.currency}${totalPrizePool}` : 'N/A'}
                          </span>
                        </p>
                        <p className={`font-compact text-white flex justify-between items-center
                                        ${listLayout ? "flex-col hidden" : ""}`}>
                            <span className="text-darkGray">Entry</span>
                            <span className="flex items-center gap-x-2">
                              {tournament.entryFee ? (
                                <TeamFeesIcon />
                              ) : ''}
                              <span className={`uppercase font-wide font-medium ${tournament.entryFee ? "text-green" : "text-white"}`}>
                                {tournament.entryFee ? `${tournament.entryFee} tokens` : 'Free'}
                              </span>
                            </span>
                        </p>
                      </div>
                    </div>
                  )}
              </div>
          </div>
      </div>
  );
}

export default TournamentCard;
