import { initializeApp } from "firebase/app";

import {
  getAuth,
  connectAuthEmulator,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  connectFirestoreEmulator
} from "firebase/firestore";

import {
  getFunctions,
  connectFunctionsEmulator
} from "firebase/functions";

import {
  getStorage,
  connectStorageEmulator
} from "firebase/storage";

import {
  getAnalytics,
  Analytics
} from "firebase/analytics";

export const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

const firebase = initializeApp(config);

const firestore = getFirestore(firebase);

const auth = getAuth();

let analytics: Analytics | null = null;

if (import.meta.env.VITE_ENV !== "development") {
  analytics = getAnalytics(firebase);
}

export const logout = async () => {
  try {
    await signOut(auth)
    window.location.replace("/login");
  } catch (err) {
    console.error(err);
  }
}

const gProvider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, gProvider);
};

const functions = getFunctions(firebase);

const storage = getStorage();

if (import.meta.env.VITE_ENV === "development") {
  connectFirestoreEmulator(firestore, "localhost", 8081);

  connectAuthEmulator(auth, "http://localhost:9099");

  connectFunctionsEmulator(functions, "localhost", 5002);

  connectStorageEmulator(storage, "localhost", 9199);
}

export default firebase;
export { firestore, storage, functions, auth, analytics };
