// context
import { useTeamContext } from '@components/team/TeamShowProvider';
// components
import TeamTrophyCard from './TrophyCard';

const TrophiesMobile = () => {
  const { team, teamTrophies } = useTeamContext();

  return team ? (
    <div className='w-full max-h-[400px] overflow-auto flex flex-row flex-nowrap mt-4 px-2 sm:px-0 md:mt-0 gap-4'>
      {teamTrophies && teamTrophies.length > 0 ? (
        <>
          {teamTrophies.map((trophy, index) => (
            <TeamTrophyCard key={`profile-trophy-${index}`} trophy={trophy}
                            fullWidth={teamTrophies.length === 1}/>
          ))}
        </>
      ) : (
        <div className='w-full h-auto flex items-center justify-center bg-lightBlack my-4 rounded-lg aspect-[3.15]'>
          <p className="font-wide font-semibold uppercase text-2xl text-steelGray text-center">
            No trophies
          </p>
        </div>
      )}
    </div>
  ): '';
};

export default TrophiesMobile;
