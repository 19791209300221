import { useEffect, useState, useMemo } from "react";
// firebase
import { firestore } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
// context
import { useTournamentAdminContext } from "../TournamentAdminProvider";
// types
import { TournamentGroup, MatchPointPlannedMap } from "../../../../firestore/tournaments";
// components
import GameMaps from "./GameMaps";
// icons
import { IoIosCheckmark } from "react-icons/io";
import { useTournamentContext } from "../../TournamentProvider";
import { toast } from "react-toastify";
import PlannedGameMaps from "./PlannedGameMaps";
import { FaQuestion } from "react-icons/fa";

interface IGroupMaps {
  group: TournamentGroup, // either the group's id, or strings: 'unassigned'  | 'declined' | 'ignored'
}

const GroupMaps: React.FC<IGroupMaps> = ({group}) => {
  const { tournament } = useTournamentContext();
  const { groupGames } = useTournamentAdminContext();

  const games = useMemo(() => groupGames[group.id]?.sort((a,b) => a.gamePosition - b.gamePosition) ?? [], [group, groupGames]);
  const [currentGame, setCurrentGame] = useState<number>(0);
  const [currentGameInitiallySet, setCurrentGameInitiallySet] = useState<boolean>(false);

  const matchPointTournament = tournament !== null && tournament.format === 'Match Point';
  const finalStage = tournament !== null &&  group.stage === (tournament.totalStages - 1);

  const [plannedMaps, setPlannedMaps] = useState<MatchPointPlannedMap[]>([]);
  const [plannedMapsInitiallyLoaded, setPlannedMapsInitiallyLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (matchPointTournament && finalStage) {
      const plannedMapsChanged = plannedMaps.some((plannedMap) => plannedMap.map !== tournament.matchPointFormatPlannedMaps.find((tournamentPlannedMap) => tournamentPlannedMap.gameNum === plannedMap.gameNum)?.map)
      if (!plannedMapsInitiallyLoaded || plannedMapsChanged) {
        const nonExistingPlannedMaps =
        tournament.matchPointFormatPlannedMaps.filter((plannedMap) => plannedMap.gameNum > games.length)
        .sort((a, b) => a.gameNum - b.gameNum);
        setPlannedMaps(nonExistingPlannedMaps);
        if (!plannedMapsInitiallyLoaded) setPlannedMapsInitiallyLoaded(true);
      }
    }
  }, [plannedMaps, tournament, matchPointTournament, finalStage]);

  const updatePlannedMap = async (gameNum: number, map: string) => {
    if (tournament) {
      const updatedPlannedGames = [...plannedMaps];
      updatedPlannedGames.splice(updatedPlannedGames.findIndex((game) => game.gameNum === gameNum), 1);
      updatedPlannedGames.push({
        gameNum,
        map
      });

      const tournamentRef = doc(firestore, 'tournaments', tournament.id);

      const updatePromise = updateDoc(tournamentRef, {
        matchPointFormatPlannedMaps: updatedPlannedGames
      });

      toast.promise(updatePromise, {
        pending: 'Updating planned map',
        success: 'Planned map updated',
        error: 'Error updating planned map'
      })

      await updatePromise;
    }
  }

  useEffect(() => {
    if (!currentGameInitiallySet) {
      const index = games.indexOf(games.find((game) => game.gamePosition === group.activeGame) ?? games[games.length-1]);
      setCurrentGame(index);
      setCurrentGameInitiallySet(true);
    }
  }, [games, group.activeGame, currentGameInitiallySet]);

  return tournament ? (
    <div>
      <div className='flex items-center gap-x-2 w-full'>
        <div className='flex items-center gap-x-2'>
          {games.map((game, index) => (
            <button key={`game-button-${index}`} type="button"
                    onClick={() => setCurrentGame(index)}
                    className={`relative p-1 px-2 border-[1px] border-lightGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentGame === index ? `bg-lightGray ${game.gamePosition === group.activeGame ? 'text-green' : 'text-white/85'}`
                                  : `hover:bg-lightGray/20 ${game.gamePosition === group.activeGame ? 'text-green/70 hover:text-green' : 'text-steelGray hover:border-steelGray'}`}`}>
              {game.gameName}
              {game.completed ? (
                <IoIosCheckmark className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 text-xl text-green bg-black border border-lightGray rounded-full"/>
              ) : ''}
            </button>
          ))}
          {tournament.format === 'Match Point' ? (
            <>
              {plannedMaps.map((map) => (
                <button key={`planned-game-button-${map.gameNum}`} type="button"
                        onClick={() => setCurrentGame(map.gameNum - 1)}
                        className={`relative p-1 px-2 border-[1px] border-lightGray font-medium rounded-full transition-colors whitespace-nowrap flex items-center gap-x-1 justify-center
                                    ${currentGame === map.gameNum - 1 ? 'bg-lightGray text-white' : 'hover:bg-lightGray/20 text-steelGray hover:border-steelGray'}`}>
                  <span>
                    Game {map.gameNum}
                  </span>
                  <FaQuestion className="text-xs"/>
                </button>
              ))}
            </>
          ) : ''}
        </div>
      </div>
      <div>
        {games.map((game, index) => (
          <div key={`game-view-${index}`}>
          {currentGame === index ? (
            <GameMaps game={game} group={group}/>
          ) : ''}
          </div>
        ))}
        {plannedMaps.map((game, index) => (
          <div key={`planned-game-view-${index}`}>
          {currentGame === games.length + index ? (
            <PlannedGameMaps plannedGame={game} updatePlannedMap={updatePlannedMap}/>
          ) : ''}
          </div>
        ))}
      </div>
    </div>
  ) : '';
}

export default GroupMaps;
