import { ReactElement, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// context
import TournamentProvider from "./TournamentProvider";
import LeaderboardProvider from "./LeaderboardProvider";
import TournamentAdminProvider from "./AdminSettings/TournamentAdminProvider";
// types
import { Tournament } from "@src/firestore/tournaments";
import { TournamentSubViews } from '@components/tournament/types';
// components
import Hero from "./Hero/Hero";
import SubViewBar from "./SubViewBar";
import { SkeletonDark } from "../common/skeleton-loader/Skeletons";
// subview components
// ---- Overview
import Status from "./Overview/Status";
import Summary from "./Overview/Summary";
import Rules from "./Overview/Rules";
// ----Entrants
import Entrants from "./Entrants/Entrants";
// ---- PrizePool
import PrizePool from "./PrizePool/PrizePool";
// ---- Leaderboards/Results
import Leaderboards from "./Leaderboards/Leaderboards"
// ---- Maps
import Maps from "./Maps/Maps";
// ---- Stats
import Stats from "./Stats/Stats";
// ---- Admin Settings
import AdminSettings from "./AdminSettings/AdminSettings";
// icons
import { LuUnlink } from "react-icons/lu";
import ALGSModeMapProvider from "./ALGSModeMapProvider";

const TournamentView = (): ReactElement => {
  const location = useLocation();

  const [currentSubView, setCurrentSubView] = useState<TournamentSubViews>(TournamentSubViews.overview);

  const [, setTournament] = useState<Tournament | null>(null);
  const [tournamentLoaded, setTournamentLoaded] = useState<boolean>(false);
  const [tournamentFound, setTournamentFound] = useState<boolean>(false);

  useEffect(() => {
    const pathName = location.pathname;
    const resultsPattern = /\/results$/
    if (resultsPattern.test(pathName)) {
      setCurrentSubView(TournamentSubViews.leaderboards);
    }
  }, [location.pathname]);

  return (
    <div className="custom_container pb-10 md:pb-14">
      <TournamentProvider announceTournament={setTournament} setTournamentLoaded={setTournamentLoaded} setTournamentFound={setTournamentFound}>
        <TournamentAdminProvider>
          <LeaderboardProvider>
            <ALGSModeMapProvider>
              {tournamentLoaded ? (
                <>
                  {tournamentFound ? (
                    <>
                      <Hero setCurrentSubView={setCurrentSubView}/>
                      <SubViewBar currentSubView={currentSubView} setCurrentSubView={setCurrentSubView} />
                      {/* Subviews */}
                      <div className="w-full h-fit relative">
                        <div className={`${currentSubView === TournamentSubViews.overview ? '' : 'hidden'}`}>
                          <Status />
                          <Summary />
                          <Rules />
                        </div>

                        <div className={`${currentSubView === TournamentSubViews.prizePool ? '' : 'hidden'}`}>
                          <PrizePool />
                        </div>

                        <div className={`${currentSubView === TournamentSubViews.entrants ? '' : 'hidden'}`}>
                          <Entrants />
                        </div>

                        <div className={`${currentSubView === TournamentSubViews.leaderboards ? '' : 'hidden'}`}>
                          <Leaderboards />
                        </div>

                        <div className={`${currentSubView === TournamentSubViews.maps ? '' : 'hidden'}`}>
                          <Maps />
                        </div>

                        <div className={`${currentSubView === TournamentSubViews.stats ? '' : 'hidden'}`}>
                          <Stats />
                        </div>

                        <div className={`${currentSubView === TournamentSubViews.adminSettings ? '' : 'hidden'}`}>
                          <AdminSettings/>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="relative w-full min-h-[80vh]">
                      <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                    text-xl sm:text-4xl font-semibold text-green
                                    flex flex-col sm:flex-row items-center gap-x-4">
                        <p>Tournament not found</p>
                        <LuUnlink/>
                      </h3>
                    </div>
                  )}
                </>
              ) : (
                <div className="custom_container pb-10 md:pb-14 mt-[80px]">
                  <div className="w-full flex justify-between items-center">
                    <SkeletonDark className="w-[75px] h-[40px] rounded-lg"/>
                    <SkeletonDark className="w-[100px] h-[20px] rounded-lg"/>
                  </div>
                  <div className="flex justify-between mt-5">
                    <SkeletonDark className="w-[33%] h-[300px] rounded-lg"/>
                    <SkeletonDark className="w-[63%] h-[300px] rounded-lg"/>
                  </div>
                  <div className="flex gap-x-5 mt-[50px]">
                    <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
                    <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
                    <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
                    <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
                    <SkeletonDark className="w-[85px] h-[25px] rounded-lg"/>
                  </div>
                  <div className="mt-7">
                    <SkeletonDark className="w-[150px] h-[25px] rounded-lg"/>
                    <div className="flex w-full justify-between mt-3">
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                    </div>
                  </div>
                  <div className="mt-[50px]">
                    <SkeletonDark className="w-[150px] h-[25px] rounded-lg"/>
                    <div className="flex w-full justify-between mt-3">
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                      <SkeletonDark className="w-1/5 h-[100px] rounded-lg"/>
                    </div>
                  </div>
                  <div>
                    <SkeletonDark className="w-1/6 h-[20px] rounded-lg mt-2"/>
                    <SkeletonDark className="w-2/6 h-[20px] rounded-lg mt-2"/>
                    <SkeletonDark className="w-3/6 h-[20px] rounded-lg mt-2"/>
                    <SkeletonDark className="w-4/6 h-[20px] rounded-lg mt-2"/>
                    <SkeletonDark className="w-1/6 h-[20px] rounded-lg mt-2"/>
                    <SkeletonDark className="w-2/6 h-[20px] rounded-lg mt-2"/>
                    <SkeletonDark className="w-3/6 h-[20px] rounded-lg mt-2"/>
                    <SkeletonDark className="w-3/6 h-[20px] rounded-lg mt-2"/>
                  </div>
                </div>
              )}
            </ALGSModeMapProvider>
          </LeaderboardProvider>
        </TournamentAdminProvider>
      </TournamentProvider>
    </div>
  );
};

export default TournamentView;
