import { Link, Outlet, useLocation } from "react-router-dom";
// context
import { useSettingsContext } from "./SettingsProvider";
// components
import SettingsSidebar from "./SettingsSidebar";
import { GoBackIcon, NextRouteIcon } from "../common/icons/TournamentIcons";
import { useEffect, useState } from "react";

interface ILocationBar {
  backLink: string,
  parentLocation: string,
  currentLocation: string,
}

const LocationBar: React.FC<ILocationBar> = ({backLink, parentLocation, currentLocation}) => {

  return (
    <div className="md:flex justify-between items-center hidden">
      <Link
        to={backLink}
        className="font-compact py-3 ps-3 pe-4 font-medium text-base !leading-4 !text-white gap-2 border rounded-xl border-lightGray flex items-center group hover:opacity-80"
      >
        <span className="duration-300 group-hover:translate-x-[-0.313rem]">
          <GoBackIcon />
        </span>
        <span className="-mb-0.5"> Back</span>
      </Link>
      <div className="flex gap-4 items-center">
        <Link
          to="/settings"
          className="font-compact font-normal text-sm !leading-4 text-ebonyClay duration-200 hover:text-slateGray -mb-0.5"
        >
          {parentLocation}
        </Link>
        <span>
          <NextRouteIcon />
        </span>
        <span className="font-compact font-normal text-sm !leading-4 text-slateGray -mb-0.5">
          {currentLocation}
        </span>
      </div>
    </div>
  );
}

const SettingsInner = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const { sidebarOpen } = useSettingsContext();

  const [activePage, setActivePage] = useState<string>('account');
  const [locationName, setLocationName] = useState<string>('');

  useEffect(() => {
    const page = pathName.split('settings')[1];
    switch (page) {
      case "":
        setActivePage('account');
        setLocationName('Account');
      break;
      case "/account":
        setActivePage('account');
        setLocationName('Account');
        break;
      case "/notifications":
        setActivePage('notifications');
        setLocationName('Notifications');
        break;
      case "/games":
        setActivePage('games');
        setLocationName('My Games');
        break;
    }
  }, [pathName])

  const calculateContainerClasses = () => {
    if (location.pathname === "/settings/games") {
      return "min-h-[calc(100vh-10rem)] sm:min-h-[calc(100vh-30rem)]";
    } else if (location.pathname === "/settings") {
      return "pb-12 sm:pb-28";
    } else if (location.pathname === "/settings/account") {
      return "min-h-[calc(100vh-37rem)] lg:min-h-[calc(100vh-30rem)]";
    } else {
      return "";
    }
  };

  const containerClasses = `flex px-4 sm:px-8 lg:px-12 gap-8 custom_container ${calculateContainerClasses()}`;

  const sidebarClasses = `max-lg:fixed max-lg:h-screen max-lg:w-screen max-lg:z-20 duration-300
                          ${sidebarOpen ? "block" : "hidden lg:block"}`;

  return (
    <div className="overflow-clip">
      <div className={`${containerClasses} relative flex flex-col`}>
        <LocationBar backLink="/my-profile" parentLocation="Settings" currentLocation={locationName}/>
        <div className={`flex gap-6`}>
          <div className={sidebarClasses}>
            <SettingsSidebar activePage={activePage}/>
          </div>
          <div className="w-full">
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsInner;
