import {  DocumentData, DocumentSnapshot, SnapshotOptions, Timestamp } from 'firebase/firestore';

export interface ProfileGame {
  title: string,
  platform: 'PC' | 'Playstation' | 'XBOX' | 'SteamDeck' | 'Switch' | '',
  input: string,
  mainLegend?: string,
  altLegend?: string,
  favouriteWeapon?: string,
}

interface TeamInviteRaw {
  teamId: string,
  timeReceived: Timestamp | undefined,
  timeRecieved: Timestamp | undefined,
}

interface TeamInvite {
  teamId: string,
  timeReceived: Date,
}

interface TrophyData {
  id: string,
  teamId: string,
  teamName: string,
  tournamentName: string,
  gameName: string,
  tournamentId: string,
  position: number,
  dateRecieved: Date,
  trophyType: number,
  winnings: number,
}

export interface DBUser {
  uid: string,
  id: string,
  region: string,
  teamLogoUrl: string,
  totalWinnings: number,
  trophyCount: number,
  tournamentsPlayed: number,
  trophyData: TrophyData[],
  apexMainLegend: string,
  apexInput: string,
  apexPlatform: string,
  profileComplete: boolean,
  showAge: boolean,
  dateOfBirth: Date,
  nationality: string,
  addedGames: ProfileGame[],
  mainGame: string,
  displayName: string,
  username: string,
  email: string,
  discordId: string,
  discord: string,
  discordNotificationsEnabled: boolean,
  twitter: string,
  twitch: string,
  instagram: string,
  youtube: string,
  displayImage: string;
  bannerImage: string;
  bio: string;
  team: string;
  teamRequests: TeamInvite[];
  competedTournaments: string[],
  dismissedGlobalNotifications: string[],
  createdAt: Date,
  admin: boolean
}

export const userConverter = {
  toFirestore: (user: DBUser): DBUser => {
    return {
      uid: user.uid ?? '',
      id: user.uid ?? '',
      region: user.region ?? '',
      teamLogoUrl: user.teamLogoUrl ?? '',
      totalWinnings: user.totalWinnings ?? 0,
      trophyCount: user.trophyCount ?? 0,
      tournamentsPlayed: user.tournamentsPlayed ?? 0,
      trophyData: user.trophyData ?? [],
      profileComplete: user.profileComplete ?? false,
      apexMainLegend: user.apexMainLegend ?? '',
      apexInput: user.apexInput ?? '',
      apexPlatform: user.apexPlatform ?? '',
      showAge: user.showAge ?? true,
      dateOfBirth: user.dateOfBirth ?? new Date(),
      nationality: user.nationality ?? 'gb',
      addedGames: user.addedGames ?? [],
      mainGame: user.mainGame ?? '',
      displayName: user.displayName ?? '',
      username: user.username ?? 'username',
      email: user.email ?? '',
      discordId: user.discordId ?? '',
      discord: user.discord ?? '',
      discordNotificationsEnabled: user.discordNotificationsEnabled ?? true,
      twitter: user.twitter ?? '',
      twitch: user.twitch ?? '',
      instagram: user.instagram ?? '',
      youtube: user.youtube ?? '',
      displayImage: user.displayImage ?? '',
      bannerImage: user.bannerImage ?? '',
      bio: user.bio ?? '',
      team: user.team ?? '',
      createdAt: user.createdAt ?? new Date(),
      teamRequests: user.teamRequests ?? [],
      dismissedGlobalNotifications: user.dismissedGlobalNotifications ?? [],
      competedTournaments: user.competedTournaments ?? [],
      admin: user.admin ?? false
    };
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions): DBUser => {
    const data = snapshot.data(options)!;
    const teamRequests = data.teamRequests as TeamInviteRaw[];

    const formattedTeamRequests = teamRequests.filter((request) => typeof request.teamId === 'string' && (request.timeReceived !== undefined || request.timeRecieved !== undefined)).map((request) => {
      let convertedTimeRecieved: Date = new Date();
      if (request.timeReceived !== undefined) {
        convertedTimeRecieved = new Date(request.timeReceived.seconds * 1000);
      } else if (request.timeRecieved !== undefined) {
        convertedTimeRecieved = new Date(request.timeRecieved.seconds * 1000);
      }
      const formattedRequest: TeamInvite = {
        teamId: request.teamId,
        timeReceived: convertedTimeRecieved,
      }
      return formattedRequest;
    });

    return {
      uid: data.uid ?? snapshot.id ?? '',
      id: data.uid ?? snapshot.id ?? '',
      region: data.region ?? '',
      teamLogoUrl: data.teamLogoUrl ?? '',
      totalWinnings: data.totalWinnings ?? 0,
      trophyCount: data.trophyCount ?? 0,
      tournamentsPlayed: data.tournamentsPlayed ?? 0,
      trophyData: data.trophyData ?? [],
      profileComplete: data.profileComplete ?? false,
      apexMainLegend: data.apexMainLegend ?? '',
      apexInput: data.apexInput ?? '',
      apexPlatform: data.apexPlatform ?? '',
      showAge: data.showAge ?? true,
      dateOfBirth: data.dateOfBirth ? new Date(data.dateOfBirth.seconds * 1000) : new Date(),
      nationality: data.nationality ?? 'gb',
      addedGames: data.addedGames ?? [],
      mainGame: data.mainGame,
      displayName: data.displayName,
      username: data.username ?? 'username',
      email: data.email,
      discordId: data.discordId ?? '',
      discord: data.discord ?? '',
      discordNotificationsEnabled: data.discordNotificationsEnabled ?? true,
      twitter: data.twitter ?? '',
      twitch: data.twitch ?? '',
      instagram: data.instagram ?? '',
      youtube: data.youtube ?? '',
      displayImage: data.displayImage ?? '',
      bannerImage: data.bannerImage ?? '',
      bio: data.bio,
      team: data.team,
      createdAt: data.createdAt?.toDate(),
      teamRequests: formattedTeamRequests ?? [],
      dismissedGlobalNotifications: data.dismissedGlobalNotifications ?? [],
      competedTournaments: data.competedTournaments ?? [],
      admin: data.admin
    } as DBUser;
  }
}

// To be used for result objs (hits) that algolia returns
// data on hits may be out of date with our structure, if the document it represents is stale

export const algoliaUserConverter = (rawUser: DocumentData): DBUser => {
  const teamRequests = rawUser.teamRequests as TeamInviteRaw[];

  const formattedTeamRequests = teamRequests.filter((request) => typeof request.teamId === 'string' && (request.timeReceived !== undefined || request.timeRecieved !== undefined)).map((request) => {
    let convertedTimeRecieved: Date = new Date();
    if (request.timeReceived !== undefined) {
      convertedTimeRecieved = new Date(request.timeReceived.seconds * 1000);
    } else if (request.timeRecieved !== undefined) {
      convertedTimeRecieved = new Date(request.timeRecieved.seconds * 1000);
    }
    const formattedRequest: TeamInvite = {
      teamId: request.teamId,
      timeReceived: convertedTimeRecieved,
    }
    return formattedRequest;
  });

  return {
    uid: rawUser.uid ?? '',
    id: rawUser.uid ?? '',
    region: rawUser.region ?? '',
    teamLogoUrl: rawUser.teamLogoUrl ?? '',
    totalWinnings: rawUser.totalWinnings ?? 0,
    trophyCount: rawUser.trophyCount ?? 0,
    tournamentsPlayed: rawUser.tournamentsPlayed ?? 0,
    trophyData: rawUser.trophyData ?? [],
    profileComplete: rawUser.profileComplete ?? false,
    apexMainLegend: rawUser.apexMainLegend ?? '',
    apexInput: rawUser.apexInput ?? '',
    apexPlatform: rawUser.apexPlatform ?? '',
    showAge: rawUser.showAge ?? true,
    dateOfBirth: rawUser.dateOfBirth ? new Date(rawUser.dateOfBirth) : new Date(),
    nationality: rawUser.nationality ?? 'gb',
    addedGames: rawUser.addedGames ?? [],
    mainGame: rawUser.mainGame,
    displayName: rawUser.displayName,
    username: rawUser.username ?? 'username',
    email: rawUser.email,
    discordId: rawUser.discordId ?? '',
    discord: rawUser.discord ?? '',
    discordNotificationsEnabled: rawUser.discordNotificationsEnabled ?? false,
    twitter: rawUser.twitter ?? '',
    twitch: rawUser.twitch ?? '',
    instagram: rawUser.instagram ?? '',
    youtube: rawUser.youtube ?? '',
    displayImage: rawUser.displayImage ?? '',
    bannerImage: rawUser.bannerImage ?? '',
    bio: rawUser.bio,
    team: rawUser.team,
    createdAt: rawUser.createdAt ? new Date(rawUser.createdAt) : new Date(),
    teamRequests: formattedTeamRequests,
    dismissedGlobalNotifications: rawUser.dismissedGlobalNotifications ?? [],
    competedTournaments: rawUser.competedTournaments ?? [],
    admin: rawUser.admin
  };
}
