export enum TeamsGameOption {
  Apex,
  Valorant,
  Fortnite,
  RocketLeague
}

export enum TeamsRegionOption {
  ALL,
  EMEA,
  NA,
  LATAM,
  APAC,
}

export enum TeamsFilterOption {
  lookingForPlayers,
  threeTournaments,
  threeMembers
}

export enum TeamsSortingOption {
  totalWinningsDesc,
  nameDesc,
  playerCountDesc,
  trophiesWonDesc,
  tournamentsPlayedDesc,
  apexWinNumDesc,
  apexWinRateDesc
}
