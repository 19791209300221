import { useEffect, useRef, useState, useContext } from "react";
// context
import { TeamInfoContext } from "../TeamInfoProvider";
// content
import { MainGameDropDownOptions, TeamRegionDropDown} from "../../common/content/Profile";
import { CreateBackArrowIcon, RightCheckIcon } from "../../common/icons/ProfileIcons";
// icons
import { CrossIcon } from "../../common/icons/FilterIcons";
import { BlackArrow,DownSelectArrow } from "../../common/icons/Common";

interface IProps {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

type Game = { // type representation of a Game from  'MainGameDropdownOptions'
  svgIcon: JSX.Element,
  title: string;
}

const CreateTeamRegion = (props: IProps) => {
  const scrollContainerRef = useRef(null);

  const { changeModalStep, closeModal } = props;
  const [isFormFilled, setIsFormFilled] = useState(false);

  const {teamInfo, setTeamInfo, edit} = useContext(TeamInfoContext);

  // region dropdown
  const regionDropdownRef = useRef<HTMLDivElement>(null);
  const [regionDropdownOpen, setRegionDropdownOpen] = useState<boolean>(false);

  // main game dropdown
  const mainGameDropdownRef = useRef<HTMLDivElement>(null);
  const [mainGameDropdownOpen, setMainGameDropdownOpen] = useState(false);

  // step fields
  const [region, setRegion] = useState<string>(teamInfo.region);

  const [teamBio, setTeamBio] = useState(teamInfo.teamBio);
  const teamBioMaxLength = 200;

  const [mainGame, setMainGame] = useState<string>(teamInfo.mainGame);

  const [mainGameObj, setMainGameObj] = useState<Game | undefined>(() => { // a obj representation of selected main game including associated SVG for render purposes
    return MainGameDropDownOptions.find((game) => game.title === teamInfo.mainGame);
  })

  const handleTeamBioChange = (newTeamBio: string) => {
    if (newTeamBio.length <= teamBioMaxLength) {
      setTeamBio(newTeamBio);
    }
  }

  const handleMainGameChange = (game: Game) => {
    setMainGame(game.title);
    setMainGameObj(game);
    setMainGameDropdownOpen(false);
  };

  const closeDropdownOnOutsideClick = (
    dropdownRef: React.RefObject<HTMLDivElement>,
    dropdownStateSetter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (e: { target: any }) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        dropdownStateSetter(false);
      }
    };
  };

  useEffect(() => {
    document.addEventListener(
      "mousedown",
      closeDropdownOnOutsideClick(regionDropdownRef, setRegionDropdownOpen)
    );
    document.addEventListener(
      "mousedown",
      closeDropdownOnOutsideClick(mainGameDropdownRef, setMainGameDropdownOpen)
    );

    return () => {
      document.removeEventListener(
        "mousedown",
        closeDropdownOnOutsideClick(regionDropdownRef, setRegionDropdownOpen)
      );
      document.removeEventListener(
        "mousedown",
        closeDropdownOnOutsideClick(mainGameDropdownRef, setMainGameDropdownOpen)
      );
    };
  }, []);

  useEffect(() => {
    const isFormFilled = region.trim() !== "" && mainGame.length > 0;
    setIsFormFilled(isFormFilled);
  }, [region, teamBio, mainGame]);

  const saveStepInfo = () => {
    setTeamInfo({...teamInfo, teamBio: teamBio, mainGame: mainGame, region: region });
  }

  const handleStepSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveStepInfo();
    changeModalStep(1);
  };

  return (
    <>
      <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack">
        <form onSubmit={handleStepSubmit} className="h-full flex flex-col">
          <div className="px-4 py-3 border-b-[0.063rem] border-lightGray">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    saveStepInfo();
                    changeModalStep(-1);
                  }}>
                  <CreateBackArrowIcon />
                </span>
                <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
                  {edit ? 'Edit Team' : 'Create team'}
                </h5>
              </div>
              <span
                className="cursor-pointer"
                onClick={() => {
                  saveStepInfo();
                  closeModal();
                }}>
                <CrossIcon />
              </span>
            </div>
          </div>
          <div ref={scrollContainerRef} className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]">

            <div className="flex gap-[1.375rem] items-center justify-center pt-6">
              <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center">
                <RightCheckIcon />
              </div>
              <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-green bg-lightBlack"></div>
              <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
              <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
              <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
            </div>
            <div className="px-6">
              <h2 className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
                Team Region *
              </h2>
              <div className="w-full block mt-2">
                <div className="relative inline-block w-full" ref={regionDropdownRef}>
                  <div
                    className={`gap-3 flex items-center justify-between bg-black rounded-xl py-3 px-4 relative cursor-pointer border-2 ${regionDropdownOpen ? 'border-green': 'border-black/0'}`}
                    onClick={() => setRegionDropdownOpen(!regionDropdownOpen)}
                  >
                    <div className="flex items-center align-middle">
                      <p className={`font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${region ? 'text-white' : 'text-steelGray'}`}>
                        {region ? region : "Select your region"}
                      </p>
                    </div>
                    <span
                      className={` ${
                        regionDropdownOpen ? "open rotate-[180deg]" : ""
                      }`}
                    >
                      <DownSelectArrow />
                    </span>
                  </div>
                  {regionDropdownOpen && (
                    <div className="absolute left-0 top-11 z-50 bg-lightBlack bg-no-repeat bg-cover w-full rounded-[6rounded-md">
                      <div className="flex flex-col rounded-md border border-white border-opacity-[0.1] overflow-hidden">
                        {TeamRegionDropDown.map((data, index) => {
                          return (
                            <div
                              onClick={() => {
                                setRegion(data.regionType),
                                setRegionDropdownOpen(false);
                              }}
                              key={index}
                              className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center"
                            >
                              {data.regionType}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <h2 className="font-compact font-normal text-start text-base !tracking-[0.01rem] text-steelGray mt-6 flex items-center gap-x-2">
                <span>Team Bio</span>
                <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
              </h2>
              <textarea
                rows={8}
                onChange={(e) => handleTeamBioChange(e.target.value)}
                value={teamBio}
                placeholder="Team Bio here"
                className="w-full py-3 px-4 2 bg-black font-compact font-normal text-sm !leading-4 tracking-[0.009rem] placeholder:text-darkGray placeholder:text-sm rounded-xl   resize-none outline-none border-2 border-transparent focus:border-green text-white"
              ></textarea>
              <p className={`text-end font-compact font-normal text-sm !leading-4 tracking-[0.009rem] mt-2 text-steelGray`}>
                {teamBio.length}/{teamBioMaxLength}
              </p>
            </div>
            <div className="px-6 pb-[100px]">
              <h2 className="font-compact text-start font-normal text-base !tracking-[0.01rem] text-darkGray mt-3">
                Main Game *
              </h2>
              <div className="w-full block mt-2">
                <div className="relative inline-block w-full" ref={mainGameDropdownRef}>
                  <div
                    className={`gap-3 flex items-center justify-between bg-black rounded-xl py-3 px-4 relative cursor-pointer border-2 ${mainGameDropdownOpen ? 'border-green': 'border-black/0'}`}
                    onClick={() => {
                      if (!mainGameDropdownOpen && scrollContainerRef.current) {
                        const container = (scrollContainerRef.current as HTMLDivElement);
                        container.scrollTop = container.scrollHeight;
                      }
                      setMainGameDropdownOpen(!mainGameDropdownOpen);
                    }}>
                    <div className={`flex items-center align-middle gap-2 ${mainGame ? 'text-white' : 'text-steelGray'}`}>
                      <span className="w-[1.2rem] h-[1.2rem] main_game_dropdown_icon">
                        {mainGameObj ? mainGameObj.svgIcon : ''}
                      </span>
                      <p className="font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem]">
                        {mainGameObj
                          ? mainGameObj.title
                          : "Select team main game"}
                      </p>
                    </div>
                    <span
                      className={` ${
                        mainGameDropdownOpen ? "open rotate-[180deg]" : ""
                      }`}
                    >
                      <DownSelectArrow />
                    </span>
                  </div>
                  {mainGameDropdownOpen && (
                    <div className="absolute left-0 top-11 z-50 bg-lightBlack bg-no-repeat bg-cover w-full rounded-md">
                      <div className="flex flex-col rounded-md border border-white border-opacity-[0.1] overflow-hidden">
                        {MainGameDropDownOptions.map((game, index) => {
                          return (
                            <div
                              onClick={() => {handleMainGameChange(game)}}
                              key={index}
                              className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 flex items-center"
                            >
                              <span className="me-2">{game.svgIcon}</span>
                              {game.title}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="mt-4">
              <h2 className="px-6 font-compact text-start font-normal text-base !tracking-[0.01rem] text-darkGray pb-2">
                Team Games
              </h2>
              <div className="px-[1.125rem]">
                <TeamGames games={games} setGames={setGames}/>
              </div>
            </div> */}
          </div>
          <div className="border-t-[0.063rem] border-lightGray pt-3 pb-3 px-4 h-fit">
            <button
              type="submit"
              className={`font-compact text-base font-medium !leading-4 !tracking-[0.01rem] uppercase w-full rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2 ${
                isFormFilled
                  ? "cursor-pointer opacity-100"
                  : "cursor-not-allowed opacity-30"
              }`}
              disabled={!isFormFilled}
            >
              <span className="pt-[0.2rem]">next</span>
              <span className="pt-[0.09rem]">
                <BlackArrow />
              </span>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateTeamRegion;
