import { useEffect, useState } from 'react'
// firebase
import { firestore } from '../../../../firebase';
import { addDoc, collection } from 'firebase/firestore';
// types
import { ApexPointSystem, apexPointSystemConverter, positionNumberToString } from '../../../../firestore/tournamentPointsSystems';
// libraries
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { toast } from 'react-toastify';
// assetts
import skullWhite from "../../../../assets/images/tournamentPoints/skullWhite.png";
import damageWhite from "../../../../assets/images/tournamentPoints/damageWhite.png";
import assistWhite from "../../../../assets/images/tournamentPoints/assistWhite.png";
// icons
import { FaPen } from 'react-icons/fa';
import { CrossIcon } from '../../../common/icons/Header';

interface ICustomPointSystem {
  setCurrentPointsSystem: (pointSystemId: string) => void,
}

const CustomPointSystem: React.FC<ICustomPointSystem> = ({setCurrentPointsSystem}) => {

  const [editActionsMode, setEditActionsMode] = useState<boolean>(false);
  const [editPlacementsMode, setEditPlacementsMode] = useState<boolean>(false);

  const [saveOpen, setSaveOpen] = useState<boolean>(false);

  const defaultPoints = {
    id: '',
    name: 'New Template',
    game: 'Apex Legends',
    perKill: 0,
    perDamage: 0,
    perAssist: 0,
    placements: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    tournamentsUsing: [],
  }

  const [placementsValidationErrors, setPlacementsValidationErrors] = useState<boolean[]>([]);

  const [points, setPoints] = useState<ApexPointSystem>(defaultPoints)

  useEffect(() => {
    const localPlacementsValidationErrors: boolean[] = [];
    let lastValue = Infinity;
    points.placements.forEach((point, index) => {
      if (point > lastValue) {
        localPlacementsValidationErrors[index] = true;
      }
      lastValue = point;
    })
    setPlacementsValidationErrors(localPlacementsValidationErrors);
  }, [points])

  const handleSavePreset = async () => {
    try {
      const pointsCollection = collection(firestore, 'apexTournamentPointSystems').withConverter(apexPointSystemConverter);
      const createPromise = addDoc(pointsCollection, points);
      toast.promise(createPromise, {
        pending: 'Saving preset',
        success: 'Preset saved',
        error: 'Error saving preset'
      });
      const docRef = await createPromise;
      setCurrentPointsSystem(docRef.id);
    } catch (err) {
      console.error(err);
      toast.error('Error saving preset');
    }
  }

  return (
    <div className='text-white font-compact flex flex-col gap-y-6'>
      <div>
        <h3>Action Points</h3>
        <div className='flex flex-col gap-y-2 mt-2 w-full lg:w-2/3'>

          <div className="relative flex items-center gap-x-2 w-full h-[45px]">
            <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
            <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-3/5 rounded-xl pl-5">
              <img src={skullWhite} alt="Skull icon"
                className='w-[20px] h-auto' />
              <p className='uppercase font-wide text-white px-4 font-semibold'>Per Kill</p>
            </div>
            <div className={`w-2/5 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white ${editActionsMode ? '' : 'px-4'}`}>
              {editActionsMode ? (
                <input type="text" value={points.perKill}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setPoints({ ...points, perKill: 0 })
                    } else if (/^\d*$/.test(e.target.value)) {
                      setPoints({ ...points, perKill: parseInt(e.target.value) })
                    }
                  }}
                  className='block w-full h-full font-semibold outline-none bg-black/40 border border-green/0 rounded-xl focus:border-green px-4' />
              ) : (
                <p className='text-sm font-semibold'>{points.perKill} point{points.perKill > 1 || points.perKill === 0 ? 's' : ''}</p>
              )}
            </div>
          </div>

          <div className="relative flex items-center gap-x-2 w-full h-[45px]">
            <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
            <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-3/5 rounded-xl pl-5">
              <img src={damageWhite} alt="Skull icon"
                className='w-[20px] h-auto' />
              <p className='uppercase font-wide text-white px-4 font-semibold'>Per Damage</p>
            </div>
            <div className={`w-2/5 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white ${editActionsMode ? '' : 'px-4'}`}>
              {editActionsMode ? (
                <input type="text" value={points.perDamage}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setPoints({ ...points, perDamage: 0 })
                    } else if (/^\d*$/.test(e.target.value)) {
                      setPoints({ ...points, perDamage: parseInt(e.target.value) })
                    }
                  }}
                  className='block w-full h-full font-semibold outline-none bg-black/40 border border-green/0 rounded-xl focus:border-green px-4' />
              ) : (
                <p className='text-sm font-semibold'>{points.perDamage} point{points.perDamage > 1 || points.perDamage === 0 ? 's' : ''}</p>
              )}
            </div>
          </div>

          <div className="relative flex items-center gap-x-2 w-full h-[45px]">
            <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
            <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-3/5 rounded-xl pl-5">
              <img src={assistWhite} alt="Skull icon"
                className='w-[20px] h-auto' />
              <p className='uppercase font-wide text-white px-4 font-semibold'>Per Assist</p>
            </div>
            <div className={`w-2/5 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white ${editActionsMode ? '' : 'px-4'}`}>
              {editActionsMode ? (
                <input type="text" value={points.perAssist}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setPoints({ ...points, perAssist: 0 })
                    } else if (/^\d*$/.test(e.target.value)) {
                      setPoints({ ...points, perAssist: parseInt(e.target.value) })
                    }
                  }}
                  className='block w-full h-full font-semibold outline-none bg-black/40 border border-green/0 rounded-xl focus:border-green px-4' />
              ) : (
                <p className='text-sm font-semibold'>{points.perAssist} point{points.perAssist > 1 || points.perAssist === 0 ? 's' : ''}</p>
              )}
            </div>
          </div>

          {editActionsMode ? (
            <button type='button'
              onClick={() => setEditActionsMode(false)}
              className='w-full h-[45px] flex items-center gap-x-2 justify-center bg-lightGray rounded-xl font-compact font-medium uppercase
                              hover:opacity-75 transition-opacity'>
              <span>Confirm Changes</span>
            </button>
          ) : (
            <button type='button'
              onClick={() => setEditActionsMode(true)}
              className='w-full h-[45px] flex items-center gap-x-2 justify-center bg-lightGray rounded-xl font-compact font-medium uppercase
                              hover:opacity-75 transition-opacity'>
              <span>Edit</span>
              <FaPen className='text-sm mb-1 text-green hidden sm:block' />
            </button>
          )}
        </div>
      </div>

      <div className='mt-6'>
        <h3>Placement Points</h3>
        <div className='flex gap-x-6 mt-2'>
          <div className='flex flex-col gap-y-2 w-1/2'>
            {points.placements.slice(0, 10).map((placementPoints, index) => (
              <div key={`placement-${index+1}-points`} className="relative flex items-center gap-x-2 w-full h-[45px]">
                <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
                <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-1/2 rounded-xl pl-5">
                  <p className='uppercase font-wide text-white font-semibold'>
                    {positionNumberToString(index + 1)}
                  </p>
                </div>
                <div className={`w-1/2 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white ${editPlacementsMode ? '' : 'px-4'}`}>
                  {editPlacementsMode ? (
                    <input type="text" value={placementPoints}
                      onChange={(e) => {
                        const localPlacements = [...points.placements];
                        if (e.target.value === '') {
                          localPlacements[index] = 0;
                          setPoints({ ...points, placements: localPlacements });
                        } else if (/^\d*$/.test(e.target.value)) {
                          localPlacements[index] = parseInt(e.target.value);
                          setPoints({ ...points, placements: localPlacements });
                        }
                      }}
                      className={`block w-full h-full font-semibold outline-none bg-black/40 border border-green/0 rounded-xl focus:border-green px-4
                                  ${placementsValidationErrors[index] ? '!border-red' : ''}`} />
                  ) : (
                    <p className='text-sm font-semibold'>{placementPoints} point{placementPoints > 1 || placementPoints === 0 ? 's' : ''}</p>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col gap-y-2 w-1/2'>
            {points.placements.slice(10, 20).map((placementPoints, index) => (
              <div key={`placement-${index+11}-points`} className="relative flex items-center gap-x-2 w-full h-[45px]">
                <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
                <div className="flex items-center gap-x-2 bg-lightGray/80 h-full w-1/2 rounded-xl pl-5">
                  <p className='uppercase font-wide text-white font-semibold'>
                    {positionNumberToString(index + 11)}
                  </p>
                </div>
                <div className={`w-1/2 h-full flex items-center gap-x-1 bg-lightGray rounded-xl uppercase font-wide text-white ${editPlacementsMode ? '' : 'px-4'}`}>
                  {editPlacementsMode ? (
                    <input type="text" value={placementPoints}
                      onChange={(e) => {
                        const localPlacements = [...points.placements];
                        if (e.target.value === '') {
                          localPlacements[index + 10] = 0;
                          setPoints({...points, placements: localPlacements});
                        } else if (/^\d*$/.test(e.target.value)) {
                          localPlacements[index + 10] = parseInt(e.target.value);
                          setPoints({...points, placements: localPlacements});
                        }
                      }}
                      className='block w-full h-full font-semibold outline-none bg-black/40 border border-green/0 rounded-xl focus:border-green px-4' />
                  ) : (
                    <p className='text-sm font-semibold'>{placementPoints} point{placementPoints > 1 || placementPoints === 0 ? 's' : ''}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='mt-2'>
          {editPlacementsMode ? (
            <button type='button'
                    disabled={placementsValidationErrors.length > 0}
              onClick={() => setEditPlacementsMode(false)}
              className='w-full h-[45px] flex items-center gap-x-2 justify-center bg-lightGray rounded-xl font-compact font-medium uppercase
                              hover:opacity-75 transition-opacity disabled:opacity-50'>
              <span>Confirm Changes</span>
            </button>
          ) : (
            <button type='button'
              onClick={() => setEditPlacementsMode(true)}
              className='w-full h-[45px] flex items-center gap-x-2 justify-center bg-lightGray rounded-xl font-compact font-medium uppercase
                              hover:opacity-75 transition-opacity'>
              <span>Edit</span>
              <FaPen className='text-sm mb-1 text-green hidden sm:block' />
            </button>
          )}

          <button type='button'
                  disabled={editPlacementsMode || editActionsMode}
            onClick={() => setSaveOpen(true)}
            className='w-full h-[45px] flex items-center gap-x-2 justify-center bg-green text-black rounded-xl font-compact font-semibold uppercase
                            hover:opacity-75 transition-opacity mt-2 disabled:opacity-50'>
            <span>Save Preset</span>
          </button>
        </div>
      </div>

      <Transition appear show={saveOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setSaveOpen(false)}>
          <div className="fixed inset-0 overflow-y-auto mt-[4.75rem] lg:mt-[7.5rem]">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center w-full after:w-full after:h-full after:absolute after:bg-black/70">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full transform overflow-hidden max-w-[30rem] mx-auto flex items-center relative z-10">
                    <div className="w-full mx-auto h-fit overflow-y-auto scrollbar_none !bg-lightBlack rounded-xl max-h-[28.125rem]">
                      <div className="px-4 py-3 border-b-[0.063rem] border-lightGray">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <h5 className="text-white font-bold font-compact text-base leading-[1rem] mt-1 uppercase">
                              Save Preset
                            </h5>
                          </div>
                          <button type='button' aria-label='Close save preset dialogue'
                                  onClick={() => setSaveOpen(false)}
                                  className="cursor-pointer">
                            <CrossIcon/>
                          </button>
                        </div>
                      </div>
                      <div className='my-2 w-full flex flex-col gap-y-6 items-start'>
                        <div className='p-5 w-full'>
                          <p className='font-compact text-start text-steelGray'>
                            Preset Name
                          </p>
                          <input type="text"
                                  value={points.name}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 20) setPoints({...points, name: e.target.value})
                                  }}
                                  className='w-full bg-black p-2 rounded-lg px-4 text-white font-compact mt-2
                                             outline-none border border-green/0 focus:border-green'/>
                          <p className='text-steelGray mt-2 text-right'>{points.name.length}/20</p>
                        </div>
                        <div className="py-3 px-4 border-t-[0.063rem] border-lightGray w-full">
                          <button
                            disabled={points.name.length === 0}
                            onClick={handleSavePreset}
                            className="flex justify-center items-center font-compact w-full text-base font-semibold py-[0.938rem] !leading-4 rounded-xl
                                       uppercase text-center bg-green hover:bg-gorse gap-2 disabled:opacity-50 disabled:hover:bg-green">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>

    </div>
  )
}

export default CustomPointSystem;
