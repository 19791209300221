import { useEffect, useState } from "react";
// firebase
import { functions } from '@src/firebase';
import { httpsCallable } from "firebase/functions";
// context
import { useTournamentContext } from "../../TournamentProvider";
import { DBTeamWithGroups, useTournamentAdminContext } from "../TournamentAdminProvider";
// types
import { TournamentGroup } from "@src/firestore/tournaments";
// libraries
import { toast } from "react-toastify";
// icons
import { VscDebugDisconnect } from "react-icons/vsc";
import { DownSelectArrow } from "../../../common/icons/Common";
// local types
interface GroupTeam {
  teamName: string;
  groupPosition: number;
}

const setTeamNames = httpsCallable(functions, 'setTeamNames');
const allocatePoisForGroupTeams = httpsCallable(functions, 'allocatePoisForGroupTeams');

interface IGroup {
  stageNum: number,
  group: TournamentGroup,
  unassignedTeams: DBTeamWithGroups[] // either the group's id, or strings: 'unassigned'  | 'declined' | 'ignored'
}

const Group: React.FC<IGroup> = ({stageNum, group, unassignedTeams}) => {
  const { tournament, tournamentTeams, tournamentStages } = useTournamentContext();
  const { confirmedTeams, changeTeamGroup } = useTournamentAdminContext();

  const [groupTeams, setGroupTeams] = useState<DBTeamWithGroups[]>([]);
  const groupPositions = Array.from({length: 20}, (_, index) => index + 1);

  const [nextEmptyGroupPositionNum, setNextEmptyGroupPositionNum] = useState<number>(-1);
  const [activeAddTeamDropdown, setActiveAddTeamDropdown] = useState<number>(-1); // the team position (number) for which dropdown is open

  useEffect(() => {
    const listener = (e: MouseEvent) => {
      if (!(e.target as Element).closest('.dropdown-toggle, .dropdown')) {
        setActiveAddTeamDropdown(-1);
      }
    };

    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, []);

  useEffect(() => {
    setNextEmptyGroupPositionNum(groupTeams.length + 1);
  }, [groupTeams]);

  useEffect(() => {
    setGroupTeams(confirmedTeams.filter((team) => team.groups.some((teamGroup) => teamGroup.groupId === group.id)));
  }, [group, confirmedTeams]);

  const processTeamNameSetterObjects = (): GroupTeam[] => {
    let algsModeMap: 'WE' | 'SP' | 'ED' | null = null;
    
    if (tournament && tournament.gameMode === 'ALGS Mode') {
      // get group's active game's map
      const tournamentGroups = tournamentStages.map((stage) => [...stage.groups]).flat();
      const tournamentGroup = tournamentGroups.find((tournamentGroup) => tournamentGroup.id === group.id);
      const activeGame = tournamentGroup?.games?.find((game) => game.gamePosition === tournamentGroup.activeGame) ?? null;

      if (activeGame){
        algsModeMap = activeGame.map as 'WE' | 'SP' | 'ED';
      }
    }

    return groupTeams
      .map(team => {
        const relevantGroup = team.groups.find(teamGroup => teamGroup.groupId === group.id);

        let teamGroupPoiAllocation: number | null = null;
        const tournamentTeam = tournamentTeams.find((tournamentTeam) => tournamentTeam.id === team.id);

        if (algsModeMap && tournamentTeam && tournamentTeam.poiAllocations) {
          const teamPoiAllocationsForGroup = tournamentTeam.poiAllocations[group.id];
          teamGroupPoiAllocation = teamPoiAllocationsForGroup[algsModeMap];
        }

        return {
          teamName: `${team.teamName}${teamGroupPoiAllocation ? ` @${teamGroupPoiAllocation}` : ''}`,
          groupPosition: relevantGroup?.groupPosition || 0
        };
      })
      .sort((a, b) => a.groupPosition - b.groupPosition);
  };

  const handleAllocatePois = async () => {
    if (tournament) {
      try {
        const allocatePoisPromise =  allocatePoisForGroupTeams({tournamentId: tournament.id, groupId: group.id});
        toast.promise(allocatePoisPromise, {
          pending: 'Allocating POIs to teams',
          success: 'POIs allocated to teams',
          error: 'Error allocating POIs to teams'
        })
        await allocatePoisPromise;
      }
      catch(err) {
        console.error(err);
        toast.error('Error allocating POIs to teams');
      }
    }
  };

  const handleSetTeamNames = async () => {
    if (tournament) {
      try {
        const teamNameSetterObjects = processTeamNameSetterObjects();

        const setTeamPromise =  setTeamNames(teamNameSetterObjects);
        toast.promise(setTeamPromise, {
          pending: 'Applying team names to lobby',
          success: 'Teams names added to lobby',
          error: 'Error applying team names'
        })
        await setTeamPromise;
      }
      catch(err) {
        console.error(err);
        toast.error('Error setting team names');
      }
    }
  };

  return (
    <>
      <div className={`text-white flex flex-col gap-y-2 pb-2
                      ${tournament && tournament.lockedStages.includes(stageNum) ? 'opacity-50 pointer-events-none' : ''}`}>
        {groupPositions.map((groupPosition) => {
          const teamInPosition = groupTeams.find((team) => team.groups.find((teamGroup) => teamGroup.groupId === group.id)!.groupPosition === groupPosition);
          return (
            <div key={`group-slot-${groupPosition}`} className="flex items-center h-[55px] gap-x-2">
              <div className="relative flex items-center bg-lightGray h-full w-1/4 rounded-xl">
                <div className="absolute w-[2px] h-1/2 left-0 top-1/2 -translate-y-1/2 bg-green rounded"></div>
                <p className="ml-5 font-wide font-medium text-white uppercase text-sm sm:text-base">Team {`${groupPosition < 10 ? `0${groupPosition}` : groupPosition}`}</p>
              </div>
              {teamInPosition ? (
                <div className="relative flex items-center gap-x-2 h-full w-3/4">
                  <div className="flex items-center gap-x-3 bg-lightGray/80 h-full w-2/3 rounded-xl pl-5">
                    <img src={teamInPosition.logoUrl} alt={`${teamInPosition.teamName} logo`}
                        className="h-3/5 w-auto aspect-square rounded-lg"/>
                    <div>
                      <p className="text-white font-compact text-sm sm:text-base">
                        {teamInPosition.teamName}
                      </p>
                      <p className="text-steelGray font-compact text-sm sm:text-base">
                        {teamInPosition.teamTag}
                      </p>
                    </div>
                  </div>
                  <button type='button'
                          onClick={() => changeTeamGroup(teamInPosition, group, true)}
                          className="flex items-center justify-center gap-x-2 text-red/80 bg-red/20 h-full w-1/3 rounded-xl font-wide font-medium uppercase text-sm sm:text-base
                                     hover:opacity-75 transition-opacity">
                    <span>Unassign</span>
                    <VscDebugDisconnect className="text-xl hidden sm:block"/>
                  </button>
                </div>
              ) : (
                <div className="relative flex items-center gap-x-2 h-full w-3/4">
                  <div className="flex items-center bg-lightGray/80 h-full w-2/3 rounded-xl">
                    <p className="ml-5 text-steelGray font-wide font-bold uppercase text-lg">
                      Empty Slot
                    </p>
                  </div>
                  <div className="relative h-full w-1/3">
                  <button type='button'
                          disabled={nextEmptyGroupPositionNum !== groupPosition}
                          onClick={() => {
                            setActiveAddTeamDropdown(groupPosition);
                          }}
                          className={`flex dropdown-toggle items-center justify-center w-full h-full rounded-xl gap-x-2 bg-lightGray font-wide font-medium text-white uppercase'
                                      ${nextEmptyGroupPositionNum === groupPosition ? 'hover:opacity-75' : ''} disabled:opacity-50 transition-opacity
                                      ${activeAddTeamDropdown === groupPosition ? 'hidden' : ''}`}>
                    <span className="text-sm sm:text-base">Add Team</span>
                    <DownSelectArrow/>
                  </button>
                  <div className={`absolute z-[50] dropdown w-full h-fit rounded-xl flex flex-col gap-y-2 bg-lightGray min-h-full
                                  ${activeAddTeamDropdown === groupPosition ? '' : 'hidden'}`}>
                    {unassignedTeams.length > 0 ? (
                      <>
                        {unassignedTeams.map((team) => (
                          <button key={team.id!}
                                  type='button'
                                  onClick={() => {
                                    setActiveAddTeamDropdown(-1);
                                    changeTeamGroup(team, group, false);
                                  }}
                                  className="w-full h-[55px] flex items-center justify-center gap-x-4 rounded-xl
                                          text-white font-wide border border-green/0 hover:opacity-75 hover:border-green transition-opacity">
                            <img src={team.logoUrl} alt={`${team.teamName} logo`}
                                 className="h-3/5 w-auto aspect-square rounded hidden sm:block"/>
                            <p className="text-sm sm:text-base">{team.teamName}</p>
                          </button>
                        ))}
                      </>
                    ) : (
                      <p className="w-full h-[55px] flex items-center justify-center text-sm rounded-xl text-center
                                  text-white font-wide">
                        No unassigned teams
                      </p>
                    )}
                  </div>
                </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <button type='button'
              disabled={false}
              onClick={() => handleAllocatePois()}
              className='flex items-center justify-center gap-x-2 w-full py-[0.8125rem] text-black bg-green rounded-xl font-compact font-medium text-base !leading-6 uppercase
                          hover:bg-gorse transition-colors mb-2'>
        <span>Allocate POIs</span>
      </button>

      <button type='button'
              disabled={false}
              onClick={() => handleSetTeamNames()}
              className='flex items-center justify-center gap-x-2 w-full py-[0.8125rem] text-black bg-green rounded-xl font-compact font-medium text-base !leading-6 uppercase
                          hover:bg-gorse transition-colors'>
        <span>Apply Team Names</span>
      </button>
    </>
  );
};

export default Group;
