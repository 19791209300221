// types
import { DropDownOption } from "@ui/DropDown";
// assets
import worldsEdgeMap from '@assets/images/apex/maps/WE/map.png';
import stormPointMap from '@assets/images/apex/maps/SP/map.png';
import eDistrictMap from '@assets/images/apex/maps/ED/map.png';

export const apexGameModes = ['Battle Royale: Solos', 'Battle Royale: Duos', 'Battle Royale: Trios', 'ALGS Mode'];

export const apexGameModesAsDropDownOptions: DropDownOption[] = apexGameModes.map((gameMode, index) => ({
  value: index,
  label: gameMode
}));

export const apexFormats = ['Series', 'Match Point'];

export const apexFormatsAsDropDownOptions: DropDownOption[] = apexFormats.map((format, index) => ({
  value: index,
  label: format
}));

interface Coordinates {
  x: number,
  y: number
}

export interface ApexMapMeasurements {
  originOffset: {x: number, y: number},
  x: {
    coordinateToWidth: number,
    widthToCoordinate: number
  },
  y: {
    coordinateToWidth: number,
    widthToCoordinate: number
  }
}

export interface ApexMapPoi {
  map: string, // map acronym ex: 'WE'
  poiName: string,
  poiNumber: number,
  coordinates?: Coordinates,
  congested?: boolean,
  inverted?: boolean
}

interface ApexMapInfo {
  image: string,
  measurements: ApexMapMeasurements,
  pois: ApexMapPoi[],
  surveyBeaconCoords: Coordinates[],
  ringConsoleCoords: Coordinates[],
  respawnBeaconCoords: Coordinates[],
  jumpTowerCoords: Coordinates[],
  tridentCoords: Coordinates[],
}

export const ApexMapInfos: Record<string, ApexMapInfo> = {
  'WE': {
    image: worldsEdgeMap,
    measurements: {
      originOffset: {x: 0.004, y: 0},
      x: {
        coordinateToWidth: (1/922.6),
        widthToCoordinate: 922.6
      },
      y: {
        coordinateToWidth: (1/896),
        widthToCoordinate: 896
      },
    },
    pois: [
      { map: 'WE', poiName: 'Skyhook East', poiNumber: 1, coordinates: { x: -7168, y: 28911 } },
      { map: 'WE', poiName: 'Skyhook West', poiNumber: 2, coordinates: { x: -23336, y: 22663 } },
      { map: 'WE', poiName: 'Countdown', poiNumber: 3, coordinates: { x: -18994, y: 12724 } },
      { map: 'WE', poiName: 'Lava Fissure', poiNumber: 4, coordinates: { x: -30158, y: 9110 } },
      { map: 'WE', poiName: 'Landslide', poiNumber: 5, coordinates: { x: -14187, y: 3839 } },
      { map: 'WE', poiName: 'Mirage A Trios', poiNumber: 6, coordinates: { x: -28420, y: -3030 } },
      { map: 'WE', poiName: 'Staging', poiNumber: 7, coordinates: { x: -17289, y: -8809 } },
      { map: 'WE', poiName: 'Thermal Station', poiNumber: 8, coordinates: { x: -23801, y: -22362 } },
      { map: 'WE', poiName: 'Harvester', poiNumber: 9, coordinates: { x: 1628, y: -7454 } },
      { map: 'WE', poiName: 'The Tree', poiNumber: 10, coordinates: { x: -5194, y: -31247 } },
      { map: 'WE', poiName: 'Siphon West', poiNumber: 11, coordinates: { x: 1007, y: -25374 } },
      { map: 'WE', poiName: 'Siphon East', poiNumber: 12, coordinates: { x: 12172, y: -15134 } },
      { map: 'WE', poiName: 'Launch Site', poiNumber: 13, coordinates: { x: 4574, y: -35915 } },
      { map: 'WE', poiName: 'The Dome', poiNumber: 14, coordinates: { x: 20080, y: -38023 } },
      { map: 'WE', poiName: 'Stacks', poiNumber: 15, coordinates: { x: 22560, y: -28536 } },
      { map: 'WE', poiName: 'Big Maude', poiNumber: 16, coordinates: { x: 26902, y: -16489 } },
      { map: 'WE', poiName: 'The Geyser', poiNumber: 17, coordinates: { x: 23956, y: -8658 } },
      { map: 'WE', poiName: 'Fragment', poiNumber: 18, coordinates: { x: 15428, y: 1280 } },
      { map: 'WE', poiName: 'Monument', poiNumber: 19, coordinates: { x: -232, y: 6851 } },
      { map: 'WE', poiName: 'Survey Camp', poiNumber: 20, coordinates: { x: 2713, y: 24922 } },
      { map: 'WE', poiName: 'The Epicenter', poiNumber: 21, coordinates: { x: 14497, y: 18145 } },
      { map: 'WE', poiName: 'Climatizer West', poiNumber: 22, coordinates: { x: 13722, y: 34108 } },
      { map: 'WE', poiName: 'Climatizer East', poiNumber: 23, coordinates: { x: 27988, y: 20856 } },
      { map: 'WE', poiName: 'Overlook', poiNumber: 24, coordinates: { x: 30158, y: 10465 } }
    ],
    ringConsoleCoords: [
      { x: -17289, y: 24018 },
      { x: -17754, y: 14230 },
      { x: -30158, y: 9110 },
      { x: -12947, y: 5195 },
      { x: -27212, y: -828 },
      { x: -17444, y: -5044 },
      { x: -1162, y: -8960 },
      { x: 6279, y: 7905 },
      { x: 6589, y: 26277 },
      { x: 13412, y: 21157 },
      { x: 22250, y: 24470 },
      { x: 34035, y: 6701 },
      { x: 29383, y: -7152 },
      { x: 25352, y: -17844 },
      { x: 23646, y: -30343 },
      { x: 22095, y: -39228 },
      { x: 6279, y: -36517 },
      { x: 6279, y: -19501 },
      { x: -7365, y: -29289 },
      { x: -20235, y: -19049 }
    ],
    respawnBeaconCoords: [
      { x: -15583, y: 30192 },
      { x: -11396, y: 24320 },
      { x: -387, y: 34108 },
      { x: 6434, y: 28837 },
      { x: 77, y: 19952 },
      { x: -19770, y: 11218 },
      { x: -27833, y: 11971 },
      { x: 19304, y: 32451 },
      { x: 11396, y: 22964 },
      { x: 28918, y: 14381 },
      { x: 16668, y: 12272 },
      { x: 11086, y: 9712 },
      { x: 2093, y: 4743 },
      { x: 19770, y: 2484 },
      { x: 30624, y: -5496 },
      { x: -15273, y: 4291 },
      { x: -28918, y: 225 },
      { x: -16823, y: -7002 },
      { x: -12172, y: -9562 },
      { x: -23181, y: -16037 },
      { x: -27367, y: -25524 },
      { x: -18064, y: -28687 },
      { x: -2868, y: -20705 },
      { x: -7830, y: -31548 },
      { x: 232, y: -23717 },
      { x: 13102, y: -8960 },
      { x: 12792, y: -11821 },
      { x: 23646, y: -22512 },
      { x: 14808, y: -26428 },
      { x: 20080, y: -38023 },
      { x: 3023, y: -38174 }
    ],
    jumpTowerCoords: [
      { x: -2713, y: 34108 },
      { x: 2139, y: 16881 },
      { x: 9226, y: 24470 },
      { x: -20390, y: 19200 },
      { x: -28918, y: 11068 },
      { x: -23801, y: -1430 },
      { x: 24731, y: 9562 },
      { x: 23801, y: 20404 },
      { x: 25352, y: -22512 },
      { x: 13257, y: -15736 },
      { x: 10931, y: -33957 },
      { x: -2713, y: -23416 },
      { x: -6745, y: -13327 },
      { x: -18684, y: -27934 },
      { x: -25662, y: -23868 }
    ],
    surveyBeaconCoords: [
      { x: -19304, y: 22211 },
      { x: -11551, y: 24470 },
      { x: -3178, y: 19200 },
      { x: 5659, y: 27934 },
      { x: 22716, y: 25524 },
      { x: 12637, y: 20254 },
      { x: 34345, y: 9411 },
      { x: 20855, y: 677 },
      { x: 9846, y: 5647 },
      { x: -29228, y: 13327 },
      { x: -14032, y: 2484 },
      { x: -29228, y: -3388 },
      { x: -19304, y: -7755 },
      { x: -6745, y: -10767 },
      { x: -23336, y: -20254 },
      { x: 7985, y: -21308 },
      { x: 24731, y: -9863 },
      { x: 28918, y: -20856 },
      { x: 24731, y: -26729 },
      { x: 22095, y: -39679 },
      { x: 5814, y: -37722 },
      { x: -16978, y: -29289 },
      { x: -12327, y: -31397 }
    ],
    tridentCoords: []
  },
  'SP':{
    image: stormPointMap,
    measurements: {
      originOffset: {x: 0.005, y: -0.01},
      x: {
        coordinateToWidth: (1/1026.1),
        widthToCoordinate: 1026.1
      },
      y: {
        coordinateToWidth: (1/1022.6),
        widthToCoordinate: 1022.6
      },
    },
    pois: [
      { map: 'SP', poiName: 'Checkpoint North', poiNumber: 1, coordinates: { x: -19401, y: 21740 } },
      { map: 'SP', poiName: 'Checkpoint South', poiNumber: 2, coordinates: { x: -24747, y: 10741 } },
      { map: 'SP', poiName: 'Trident', poiNumber: 3, coordinates: { x: -11985, y: 28959 } },
      { map: 'SP', poiName: 'North Pad', poiNumber: 4, coordinates: { x: -25954, y: 36865 } },
      { map: 'SP', poiName: 'Downed Beast', poiNumber: 5, coordinates: { x: -35956, y: 22428 } },
      { map: 'SP', poiName: 'The Mill', poiNumber: 6, coordinates: { x: -38543, y: 2320 } },
      { map: 'SP', poiName: 'Cenote Cave', poiNumber: 7, coordinates: { x: -31472, y: -18819 } },
      { map: 'SP', poiName: 'Barometer South', poiNumber: 8, coordinates: { x: -21125, y: -28615 } },
      { map: 'SP', poiName: 'Barometer North', poiNumber: 9, coordinates: { x: -11640, y: -14694 } },
      { map: 'SP', poiName: 'Ceto Station', poiNumber: 10, coordinates: { x: -12847, y: 601 } },
      { map: 'SP', poiName: 'Cascade Falls', poiNumber: 11, coordinates: { x: 1293, y: 12116 } },
      { map: 'SP', poiName: 'Command Center', poiNumber: 12, coordinates: { x: 20608, y: 18647 } },
      { map: 'SP', poiName: 'The Wall', poiNumber: 13, coordinates: { x: 4225, y: 36521 } },
      { map: 'SP', poiName: 'Zeus Station', poiNumber: 14, coordinates: { x: 28886, y: 42536 } },
      { map: 'SP', poiName: 'Lightning Rod', poiNumber: 15, coordinates: { x: 31817, y: 28100 } },
      { map: 'SP', poiName: 'Cliff Side', poiNumber: 16, coordinates: { x: 42164, y: 14694 } },
      { map: 'SP', poiName: 'Storm Catcher', poiNumber: 17, coordinates: { x: 27161, y: 13319 } },
      { map: 'SP', poiName: 'Prowler Nest', poiNumber: 18, coordinates: { x: 26471, y: 85 } },
      { map: 'SP', poiName: 'Launch Pad', poiNumber: 19, coordinates: { x: 26299, y: -12632 } },
      { map: 'SP', poiName: 'Devastated Coast', poiNumber: 20, coordinates: { x: 32679, y: -22428 } },
      { map: 'SP', poiName: 'Echo HQ', poiNumber: 21, coordinates: { x: 16296, y: -33084 } },
      { map: 'SP', poiName: 'Coastal Camp', poiNumber: 22, coordinates: { x: 1120, y: -35490 } },
      { map: 'SP', poiName: 'The Pylon', poiNumber: 23, coordinates: { x: 9743, y: -16413 } },
      { map: 'SP', poiName: 'Jurassic', poiNumber: 24, coordinates: { x: -1293, y: -7132 } },
      { map: 'SP', poiName: 'Lift', poiNumber: 25, coordinates: { x: 10261, y: 3695 } }
    ],
    surveyBeaconCoords: [
      { x: -24302, y: 33400 },
      { x: -11754, y: 26435 },
      { x: -19378, y: 18204 },
      { x: -34785, y: 22953 },
      { x: -31132, y: 5698 },
      { x: -14136, y: 3165 },
      { x: -24302, y: -7439 },
      { x: -31608, y: -18520 },
      { x: -10959, y: -25802 },
      { x: 2223, y: -34667 },
      { x: 9053, y: -17412 },
      { x: 13501, y: -32767 },
      { x: 27002, y: -21370 },
      { x: -158, y: -9972 },
      { x: 10165, y: 6331 },
      { x: 158, y: 10605 },
      { x: 3335, y: 23902 },
      { x: 7465, y: 39574 },
      { x: 28114, y: 40207 },
      { x: 31767, y: 28018 },
      { x: 25255, y: 10447 },
      { x: 22078, y: -3165 },
      { x: 27320, y: -12505 }
    ],
    ringConsoleCoords: [
      { x: -25573, y: 35458 },
      { x: -20331, y: 21845 },
      { x: -32244, y: 20262 },
      { x: -35103, y: 791 },
      { x: -14295, y: 3165 },
      { x: 317, y: 15354 },
      { x: 6353, y: 36250 },
      { x: 14613, y: 21845 },
      { x: 29544, y: 42898 },
      { x: 33515, y: 30234 },
      { x: 25096, y: 9656 },
      { x: 1270, y: -4590 },
      { x: 7783, y: -18679 },
      { x: -16360, y: -22319 },
      { x: -35897, y: -21686 },
      { x: 2859, y: -33559 },
      { x: -15883, y: -27860 },
      { x: 28591, y: -22161 },
      { x: 31608, y: -11239 }
    ],
    respawnBeaconCoords: [
      { x: 11595, y: 45906 },
      { x: 30020, y: 43215 },
      { x: 5718, y: 36250 },
      { x: -5241, y: 35775 },
      { x: -22078, y: 29918 },
      { x: -33991, y: 17254 },
      { x: -20172, y: 21370 },
      { x: -23031, y: 12030 },
      { x: -40662, y: 10447 },
      { x: -4924, y: 10131 },
      { x: 6512, y: 15829 },
      { x: -12548, y: -1266 },
      { x: -1906, y: -5065 },
      { x: -25414, y: -3957 },
      { x: -40980, y: -9656 },
      { x: -33515, y: -11239 },
      { x: -37803, y: -23744 },
      { x: -12707, y: -24694 },
      { x: 1588, y: -36883 },
      { x: 4924, y: -28335 },
      { x: 9848, y: -19787 },
      { x: 16519, y: -27227 },
      { x: 27479, y: -26910 },
      { x: 29702, y: -11872 },
      { x: 31450, y: -4274 },
      { x: 20490, y: -4748 },
      { x: 10324, y: -158 },
      { x: 25414, y: 11080 },
      { x: 41298, y: 12030 },
      { x: 42886, y: 22953 },
      { x: 35579, y: 29918 }
    ],
    jumpTowerCoords: [
      {x: 26049, y: 39890}
    ],
    tridentCoords: [
      { x: -6353, y: 32450 },
      { x: -29385, y: 20578 },
      { x: -40980, y: 7598 },
      { x: -43839, y: -7914 },
      { x: -32561, y: -11239 },
      { x: -23508, y: -26593 },
      { x: 20649, y: -29918 },
      { x: 41456, y: 23269 }
    ],
  },
  'ED':{
    image: eDistrictMap,
    measurements: {
      originOffset: {x: 0.002, y: 0},
      x: {
        coordinateToWidth: (1/859.4),
        widthToCoordinate: 859.4
      },
      y: {
        coordinateToWidth: (1/862.2),
        widthToCoordinate: 862.2
      },
    },
    pois: [
      { map: 'ED', poiName: 'Resort', poiNumber: 1, coordinates: { x: -26630, y: 26244 } },
      { map: 'ED', poiName: 'Lotus North', poiNumber: 2, coordinates: { x: -5849, y: 31517 } },
      { map: 'ED', poiName: 'Lotus South', poiNumber: 3, coordinates: { x: -3827, y: 23112 } },
      { map: 'ED', poiName: 'Electro Dam', poiNumber: 4, coordinates: { x: 11771, y: 23982 } },
      { map: 'ED', poiName: 'Boardwalk', poiNumber: 5, coordinates: { x: -30115, y: 12244 } },
      { map: 'ED', poiName: 'City Hall North', poiNumber: 6, coordinates: { x: -18704, y: 18185 } },
      { map: 'ED', poiName: 'City Hall South', poiNumber: 7, coordinates: { x: -14804, y: 8622 } },
      { map: 'ED', poiName: 'Canal Plaza', poiNumber: 8, coordinates: { x: -505, y: 13693 } },
      { map: 'ED', poiName: 'Galleria', poiNumber: 9, coordinates: { x: 10904, y: 11954 } },
      { map: 'ED', poiName: 'Vibe Isle', poiNumber: 10, coordinates: { x: 17837, y: 217 } },
      { map: 'ED', poiName: 'Heights', poiNumber: 11, coordinates: { x: 26793, y: 12244 } },
      { map: 'ED', poiName: 'Blossom Drive', poiNumber: 12, coordinates: { x: -33292, y: -2390 } },
      { map: 'ED', poiName: 'Neon Square West', poiNumber: 13, coordinates: { x: -23904, y: -5289 }, congested: true },
      { map: 'ED', poiName: 'Neon Square East', poiNumber: 14, coordinates: { x: -20726, y: -5289 }, congested: true, inverted: true },
      { map: 'ED', poiName: 'Energy Bank West', poiNumber: 15, coordinates: { x: -2816, y: 4564 }, congested: true  },
      { map: 'ED', poiName: 'Energy Bank East', poiNumber: 16, coordinates: { x: 7438, y: 4564 }, congested: true  },
      { map: 'ED', poiName: 'Energy Bank South', poiNumber: 17, coordinates: { x: 2383, y: -6448 } },
      { map: 'ED', poiName: 'Settlement', poiNumber: 18, coordinates: { x: 12493, y: -11085 } },
      { map: 'ED', poiName: 'Stadium North', poiNumber: 19, coordinates: { x: 26359, y: -3550 } },
      { map: 'ED', poiName: 'Stadium South', poiNumber: 20, coordinates: { x: 26359, y: -17026 } },
      { map: 'ED', poiName: 'Street Market', poiNumber: 21, coordinates: { x: -19282, y: -19345 } },
      { map: 'ED', poiName: 'Street Market Coast', poiNumber: 22, coordinates: { x: -34303, y: -17316 }, congested: true },
      { map: 'ED', poiName: 'Viaduct', poiNumber: 23, coordinates: { x: -14660, y: -24272 } },
      { map: 'ED', poiName: 'Draft Point', poiNumber: 24, coordinates: { x: -9171, y: -13983 } },
      { map: 'ED', poiName: 'Draft Point South', poiNumber: 25, coordinates: { x: -649, y: -24127 } },
      { map: 'ED', poiName: 'Shipyard Arcade', poiNumber: 26, coordinates: { x: -4260, y: -34560 } },
      { map: 'ED', poiName: 'Old Town', poiNumber: 27, coordinates: { x: 11916, y: -34850 } },
      { map: 'ED', poiName: 'Humbert Labs', poiNumber: 28, coordinates: { x: 17404, y: -24851 } }
    ],
    surveyBeaconCoords: [
      { x: -26789, y: 25147 },
      { x: -33421, y: 12906 },
      { x: -15384, y: 7184 },
      { x: -31697, y: 532 },
      { x: -20954, y: -5721 },
      { x: -387, y: 1729 },
      { x: 928, y: 14503 },
      { x: -5702, y: 29804 },
      { x: -17373, y: -25679 },
      { x: -23739, y: -19559 },
      { x: -4243, y: -35525 },
      { x: -2917, y: -14236 },
      { x: 10079, y: -35525 },
      { x: 13129, y: -25014 },
      { x: 23606, y: -8781 },
      { x: 17771, y: -1330 },
      { x: 26657, y: 7584 },
      { x: 7824, y: 11841 },
      { x: 9151, y: 28740 }
    ],
    ringConsoleCoords: [
      { x: -25331, y: 27542 },
      { x: -30901, y: 11708 },
      { x: -31962, y: -6120 },
      { x: -24137, y: -4923 },
      { x: -25861, y: -19559 },
      { x: -14588, y: -28207 },
      { x: -4111, y: -31534 },
      { x: -8090, y: -14369 },
      { x: 12466, y: -33530 },
      { x: 13129, y: -27010 },
      { x: 21617, y: -11309 },
      { x: 27850, y: 8382 },
      { x: 5172, y: 1729 },
      { x: 13129, y: 10910 },
      { x: 16577, y: 21288 },
      { x: -3182, y: 24615 },
      { x: -13660, y: 12906 }
    ],
    respawnBeaconCoords: [
      { x: -21219, y: 20889 },
      { x: -28514, y: 11442 },
      { x: -22015, y: 4124 },
      { x: -11803, y: 11043 },
      { x: -29442, y: -3060 },
      { x: -19760, y: -4390 },
      { x: -4907, y: -8914 },
      { x: -5437, y: -17563 },
      { x: -11405, y: -24881 },
      { x: -19495, y: -19559 },
      { x: -32492, y: -17962 },
      { x: -4111, y: -36457 },
      { x: -1989, y: 29006 },
      { x: 11007, y: 25546 },
      { x: 4774, y: 17962 },
      { x: 4243, y: 6918 },
      { x: 12201, y: 12773 },
      { x: 20954, y: 12374 },
      { x: 17771, y: 1463 },
      { x: 26392, y: -4656 },
      { x: 14190, y: -18494 },
      { x: 16975, y: -26078 }
    ],
    jumpTowerCoords: [
      { x: -27585, y: 27276 },
      { x: -29044, y: 12773 },
      { x: -19097, y: 12241 },
      { x: -6896, y: 25413 },
      { x: 6233, y: 18228 },
      { x: 23606, y: 10112 },
      { x: 19760, y: -13438 },
      { x: 13129, y: -26078 },
      { x: -4111, y: -34062 },
      { x: -6233, y: -20357 },
      { x: -19628, y: -21421 },
      { x: -16843, y: -7850 },
      { x: -31431, y: -7318 }
    ],
    tridentCoords: []
  }
}
