import { useState } from 'react';
// types
import { ManageTeamGamesSubView } from '@components/team/types';
// subviews
import PoiPreferences from './subViews/poiPreferences/PoiPreferences';

const Games = () => {
  const [currentSubView, setCurrentSubView] = useState<ManageTeamGamesSubView>(ManageTeamGamesSubView.poiPreferences);

  return (
    <div className='flex flex-col gap-y-2 mt-3'>
      <div className='flex items-start gap-x-4'>
        <h2 className="font-wide font-bold text-xl !leading-6 uppercase text-white flex items-center gap-2">
          <span>Apex Settings</span>
        </h2>
        <div className="flex items-center gap-x-2 -translate-y-[5px]">
          <button type="button"
                  onClick={() => setCurrentSubView(ManageTeamGamesSubView.poiPreferences)}
                  className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentSubView === ManageTeamGamesSubView.poiPreferences ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
            POI Preferences
          </button>
        </div>
      </div>

      <div className={`${currentSubView === ManageTeamGamesSubView.poiPreferences ? '' : 'hidden'}`}>
        <PoiPreferences/>
      </div>
    </div>
  );
};

export default Games;
