// context
import { useTeamContext } from "../../TeamShowProvider";
// components
import ShellText from "@ui/ShellText";

const Sponsors = () => {
  const { team } = useTeamContext();

  return team ? (
    <div className='flex flex-col gap-y-2 mt-2'>
      <h2 className="font-wide font-bold text-xl !leading-6 uppercase text-white flex items-center gap-2">
        <span>Sponsors</span>
      </h2>
      <div className='flex flex-col'>
        {team.sponsorLogoUrls.length > 0 ? (
            <div className="mt-2 mb-10 flex flex-nowrap gap-3 w-full overflow-x-scroll">
              {team.sponsorLogoUrls.map((logoUrl) => (
                <div key={`sponsor-logo-${logoUrl}`}
                     className="bg-lightBlack py-5 xl:py-8 lg:px-5 px-3 flex-shrink-1
                                  rounded-xl h-[9rem] min-w-[200px] flex-grow flex justify-center items-center cursor-pointer hover:opacity-70 duration-300">
                  <img src={logoUrl} alt="image"
                      className="max-w-[120px] lg:max-w-[150px] h-auto aspect-[2] object-contain"/>
                </div>
              ))}
            </div>
          ) : (
            <ShellText className="h-[75px]">
              No Sponsors
            </ShellText>
          )}
      </div>
    </div>
  ) : '';
};

export default Sponsors;
