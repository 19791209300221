import { ReactNode } from "react";

interface IPillButton {
  active: boolean,
  onClick: () => void,
  children: ReactNode
}

const PillButton: React.FC<IPillButton> = ({active, onClick, children}) => {
  return (
    <button type='button'
      onClick={onClick}
      className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                  ${active ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
      {children}
    </button>
  )
}

export default PillButton;
