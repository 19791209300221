// utils
import { formatDate } from '@utils/Date';
// context
import { useTeamContext } from "../../TeamShowProvider";
// icons
import { CalendarIcon } from "@icons/ProfileIcons";
import { CompetedTournamentsIcon } from "@icons/ProfileIcons";
import { TrophyIcon, TeamIcon } from '@icons/Common';

const AboutMobile = () => {
  const { team, teamTrophies } = useTeamContext();

  return team ? (
    <div className="bg-lightBlack p-4 border-t-2 border-lightGray sm:border-transparent sm:rounded-2xl h-full flex flex-col -mt-6 sm:mt-0">
      <div className="flex items-center justify-between w-full py-2">
        <div className="flex items-center gap-4">
            <CalendarIcon />
            <div className="-mb-1">
              <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
                Created
              </p>
              <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
                {formatDate(team.createdAt)}
              </p>
            </div>
          </div>

        <div className="flex items-center gap-4">
          <TeamIcon className='fill-white w-[20px] h-auto aspect-square' />
          <div className="-mb-1">
            <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
              Team Size
            </p>
            <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
              {team.players.length} {team.players.length === 1 ? 'member' : 'members'}
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between w-full py-2">
        <div className={`flex ${team ? 'items-center' : '' } gap-4`}>
          <CompetedTournamentsIcon />
          <div className="-mb-1">
            <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
              Competed
            </p>

            <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
              {team.competedTournaments.length} {team.competedTournaments.length === 1 ? "tournament" : "tournaments"}
            </p>

          </div>
        </div>

        <div className={`flex ${team ? 'items-center' : '' } gap-4`}>
          <TrophyIcon className='fill-green w-[20px] h-auto aspect-square' />
          <div className="-mb-1">
            <p className="font-compact font-normal text-base !leading-4 !!tracking-[0.01rem] text-steelGray">
              Trophies
            </p>
            <p className="font-wide font-bold text-base text-white !leading-5 uppercase mt-1">
              {teamTrophies ? teamTrophies.length : 0} trophies
            </p>
          </div>
        </div>
      </div>

    </div>
  ) : '';
};

// Export AboutMobile component as default
export default AboutMobile;
