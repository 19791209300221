// import { useState } from "react";
import pcPlayer from '../../assets/images/png/homepage/pcPlayer.png'
// import grindAnimationImg from "../../assets/images/webp/homepage/grind-start-img.webp";
import {
  GrindStartsTopLine,
  GrindStartLeftLine,
  GrindStartRightLine,
  GrindTopArrow,
  GrindBgEffect,
} from "../common/icons/home/HomeIcons";
// import playBtn from "../../assets/images/png/homepage/videPlayBtn.png";
// import GamingVideo from "../../assets/video/gaming-grind-star.mp4";
import { Link } from "react-router-dom";

const GrindStarts = () => {
  // // Define the type for the state
  // const [isVideoPlay, setIsVideoPlay] = useState<boolean>(false);

  // const videoHandler = () => {
  //   // Check if the video is paused and play it
  //   const videoElement = document.querySelector("video");
  //   if (videoElement && videoElement.paused) {
  //     videoElement.play();
  //     videoElement.setAttribute("controls", "true");
  //   }
  //   setIsVideoPlay(true);
  // };
  // // Update body overflow based on video stat
  // if (isVideoPlay === true) {
  //   document.body.style.overflow = "";
  // } else {
  //   document.body.style.overflow = "auto";
  // }

  return (
    <>
      <div className="pt-14 pb-14 sm:pb-0 sm:pt-16 lg:pt-16  md:pt-20 lg:pb-5 overflow-hidden  custom_container">
        <div className="px-4 sm:px-8 xl:px-0 relative sm:py-0 xl:py-24">
          {/* ICONS */}
          <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2  -z-[1]">
            <GrindBgEffect />
          </span>
          <span className=" absolute left-0 top-[55%] -translate-y-1/2 hidden xl:block">
            <GrindStartLeftLine />
          </span>
          <span className=" absolute right-0 top-[55%] -translate-y-1/2 hidden xl:block">
            <GrindStartRightLine />
          </span>
          <GrindTopArrow />
          <div className="text-center flex items-center justify-center relative">
            <div className="max-w-[55.5rem]">
              <h2 className="text-white uppercase font-wide font-bold text-2xl lg:text-[1.875rem] xl:text-[2.25rem] leading-[100%] max-w-[15.625rem] sm:max-w-[25rem] m-auto relative z-[1]">
                <span className="text-green">THE GRIND</span> STARTS NOW
              </h2>
              <GrindStartsTopLine />
              {/* VIDEO THUMBNAIL AND PLAY BUTTON */}
              <div className="relative group overflow-hidden rounded-2xl h-[15.5rem] sm:h-auto">
                <img src={pcPlayer} alt="Man playing PC in dark room" />
                {/* {isVideoPlay ? (
                  ""
                ) : (
                  <span
                    onClick={videoHandler}
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 cursor-pointer"
                  >
                    <img
                      className="max-w-[6.25rem] md:max-w-[7.5rem]"
                      src={playBtn}
                      alt="playBtn"
                    />
                  </span>
                )}

                {isVideoPlay ? (
                  ""
                ) : (
                  <img
                    onClick={videoHandler}
                    className="rounded-2xl absolute group-hover:scale-110 duration-300 h-full object-cover"
                    src={grindAnimationImg}
                    alt="grind-Animation-Img"
                  />
                )}

                <video
                  className="w-full h-full object-cover rounded-2xl"
                  muted
                  src={GamingVideo}
                ></video> */}
              </div>
              <div className="pt-10 lg:pt-12 inline-block">
                <Link
                  to="/tournaments"
                  className="bg-green whitespace-nowrap  text-black hover:bg-gorse rounded-xl font-compact text-sm md:text-base font-medium uppercase pt-4 pb-3 px-6 a-auto flex items-center justify-center h-12 w-[10.313rem]"
                >
                  compete now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GrindStarts;
