import { Fragment, useState } from "react";
// context
import { useAuthContext } from "@provider/AuthContextProvider";
// packages
import { Transition } from "@headlessui/react";
// icons
import { GameRemoteIcon, DropDownArrowIcon} from "../../common/icons/Header";
import { ValorantGrayIcon, ApexGrayIcon, FortniteGrayIcon,RocketLeagueGrayIcon } from "../../common/icons/Header";

export type SelectableGame = {
  icon: () => JSX.Element;
  title: string;
};

const SelectableGames: SelectableGame[] = [
  { icon: ValorantGrayIcon, title: "Valorant"},
  { icon: ApexGrayIcon, title: "Apex Legends"},
  { icon: FortniteGrayIcon, title: "Fortnite"},
  { icon: RocketLeagueGrayIcon, title: "Rocket League"},
];


import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from '../../common/icons/Games';
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { toast } from "react-toastify";

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[33px] h-auto aspect-square fill-inherit"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[32px] h-auto aspect-square fill-inherit"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[33px] h-auto aspect-square fill-inherit"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[33px] h-auto aspect-square fill-inherit"/>)
  }
  return 'no icon'
}

const Overlay = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className={"fixed top-0 left-0 bg-black h-screen w-full bg-opacity-[0.4] z-[21]"}
         onClick={onClick}
    ></div>
  );
};

interface IGameSelectButton {
  icon: () => JSX.Element;
  isActive: boolean;
  onClick?: () => void;
}

const GameSelectButton: React.FC<IGameSelectButton> = ({icon: Icon, isActive, onClick }) => {
  return (
    <button className={`p-3 rounded-xl cursor-pointer header_game_pop_up aspect-square
                        ${isActive ? "bg-ebonyClay game_pop_up_icons" : "bg-ebonyClay lg:bg-lightGray hover:bg-ebonyClay"}`}
            onClick={onClick}>
      <Icon/>
    </button>
  );
};


const GameSelector = () => {
  const { userObj } = useAuthContext();
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);

  const handleChangeActiveGame = async (game: string) => {
    if (userObj && userObj.mainGame !== game) {
      const userRef = doc(firestore, 'users', userObj.uid);
      const updatePromise = updateDoc(userRef, {
        mainGame: game
      })
      toast.promise(updatePromise, {
        pending: 'Updating main game',
        success: 'Main game updated',
        error: 'Error updating main game'
      })
    }
  }

  const toggleGameMenu = () => {
    setIsDropDownVisible(!isDropDownVisible);
  }

  const closeGameMenu = () => {
    setIsDropDownVisible(false);
  }

  return (
    <div className="relative inline-block text-left w-full">
      {isDropDownVisible && (
        <Overlay onClick={closeGameMenu}/>
      )}
      <button type="button"
              onClick={toggleGameMenu}
              className={`fill-steelGray hover:fill-green w-full flex items-center gap-2 lg:gap-1 xl:gap-2 outline-none`}>
        {userObj && userObj.mainGame ? (
          <>
            {gameNameToIcon(userObj.mainGame)}
          </>
        ) : (
          <GameRemoteIcon />
        )}
        <DropDownArrowIcon />
      </button>
      <Transition
        as={Fragment}
        show={isDropDownVisible}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <div className={`absolute z-[22] top-[4.3rem] bg-graphiteGray p-2 rounded-xl flex items-center gap-1`}>
          {SelectableGames.map((game) => (
            <GameSelectButton key={game.title}
              icon={game.icon}
              isActive={userObj !== null && userObj.mainGame === game.title}
              onClick={() => {
                closeGameMenu();
                handleChangeActiveGame(game.title);
              }}
            />
          ))}
        </div>
      </Transition>
    </div>
  );
};

export default GameSelector;
