
import { useState, useEffect, useCallback, MutableRefObject } from 'react';
// types
import { ProfileGame } from '@src/firestore/users';
// utils
import { apexLegendWeaponNames, apexLegendNames } from '@utils/Games/ApexLegends';
// icons
import { ChevronRight, DownSelectArrow } from "../common/icons/Common";

interface IGameForm {
  game: ProfileGame | null,
  setGame: (game: ProfileGame) => void,
  closeForm: () => void,
  containerRef: MutableRefObject<HTMLDivElement | null>
}

type Platform = 'PC' | 'Playstation' | 'XBOX' | 'SteamDeck' | 'Switch' | '';

const ApexLegendsForm: React.FC<IGameForm> = ({game, setGame, closeForm, containerRef}) => {

  const [platform, setPlaform] = useState<Platform>(game?.platform ?? '');
  const [platformDropdownOpen, setPlatformDropDownOpen] = useState<boolean>(false);

  const [input, setInput] = useState<string>(game?.input ?? '');
  const [inputDropdownOpen, setInputDropdownOpen] = useState<boolean>(false);

  const [mainLegend, setMainLegend] = useState<string>(game?.mainLegend ?? '');
  const [mainLegendDropdownOpen, setMainLegendDropdownOpen] = useState<boolean>(false);

  const [altLegend, setAltLegend] = useState<string>(game?.altLegend ?? '');
  const [altLegendDropdownOpen, setAltLegendDropdownOpen] = useState<boolean>(false);

  const [favouriteWeapon, setFavouriteWeapon] = useState<string>(game?.favouriteWeapon ?? '');
  const [favouriteWeaponDropdownOpen, setFavouriteWeaponDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    setGame({
      title: 'Apex Legends',
      platform: platform,
      input: input,
      mainLegend: mainLegend,
      altLegend: altLegend,
      favouriteWeapon: favouriteWeapon
    })
  }, [platform, input, mainLegend, altLegend, favouriteWeapon, setGame])

  const closeDropdowns = () => {
    setInputDropdownOpen(false);
    setPlatformDropDownOpen(false);
    setMainLegendDropdownOpen(false);
    setAltLegendDropdownOpen(false);
    setFavouriteWeaponDropdownOpen(false);
  }

  const checkForOutsideInputClick: EventListener = useCallback((e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('.gameInput') && !target.closest('.gameInput')) {
        closeDropdowns();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
  }, [checkForOutsideInputClick])

  return (
    <div className={`${favouriteWeaponDropdownOpen ? 'pb-[125px]' : 'pb-[25px]'}`}>
      <div className="flex items-center gap-x-2 mt-4 w-fit">
        <button onClick={closeForm}
                className="p-1 fill-steelGray hover:fill-white transition-all">
          <ChevronRight className="rotate-180 fill-inherit w-[12px] h-auto aspect-square"/>
        </button>
        <h2 className="uppercase font-wide text-white font-semibold text-left
                      flex items-center gap-x-2 w-fit">
          Apex Legends
        </h2>
      </div>
      <h2 className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
        Platform *
      </h2>
      <div className="w-full block mt-2">
        <div className="gameInput relative inline-block w-full">
          <div
            className={`gap-3 flex items-center justify-between bg-black rounded-xl py-3 px-4 relative cursor-pointer border-2 ${platformDropdownOpen ? 'border-green': 'border-black/0'}`}
            onClick={() => setPlatformDropDownOpen(!platformDropdownOpen)}
          >
            <div className="flex items-center align-middle">
              <p className={`font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${platform ? 'text-white' : 'text-steelGray'}`}>
                {platform ? platform : "Select your platform"}
              </p>
            </div>
            <span
              className={` ${
                platformDropdownOpen ? "open rotate-[180deg]" : ""
              }`}
            >
              <DownSelectArrow />
            </span>
          </div>
          {platformDropdownOpen && (
            <div className="absolute left-0 bottom-0 translate-y-full z-[10] bg-lightBlack bg-no-repeat bg-cover w-full">
              <div className="flex flex-col rounded-md border border-white border-opacity-[0.2] overflow-y-scroll max-h-[110px]">
                {(['PC', 'Playstation', 'XBOX', 'SteamDeck', 'Switch'] as Platform[]).map((platform) => {
                  return (
                    <div key={platform}
                          onClick={() => {
                            setPlaform(platform),
                            setPlatformDropDownOpen(false);
                          }}
                      className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center">
                      {platform}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <h2 className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
        Input *
      </h2>
      <div className="w-full block mt-2">
        <div className="gameInput relative inline-block w-full">
          <div
            className={`gap-3 flex items-center justify-between bg-black rounded-xl py-3 px-4 relative cursor-pointer border-2 ${inputDropdownOpen ? 'border-green': 'border-black/0'}`}
            onClick={() => setInputDropdownOpen(!inputDropdownOpen)}
          >
            <div className="flex items-center align-middle">
              <p className={`font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${input ? 'text-white' : 'text-steelGray'}`}>
                {input ? input : "Select your input method"}
              </p>
            </div>
            <span
              className={` ${
                inputDropdownOpen ? "open rotate-[180deg]" : ""
              }`}
            >
              <DownSelectArrow />
            </span>
          </div>
          {inputDropdownOpen && (
            <div className="absolute left-0 bottom-0 translate-y-full z-[10] bg-lightBlack bg-no-repeat bg-cover w-full">
              <div className="flex flex-col rounded-md border border-white border-opacity-[0.2] overflow-y-scroll max-h-[110px]">
                {['Mouse and Keyboard', 'Controller'].map((input) => {
                  return (
                    <div key={input}
                          onClick={() => {
                            setInput(input),
                            setInputDropdownOpen(false);
                          }}
                      className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center">
                      {input}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <h2 className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
        Main Legend *
      </h2>
      <div className="w-full block mt-2">
        <div className="gameInput relative inline-block w-full">
          <div
            className={`gap-3 flex items-center justify-between bg-black rounded-xl py-3 px-4 relative cursor-pointer border-2 ${mainLegendDropdownOpen ? 'border-green': 'border-black/0'}`}
            onClick={() => setMainLegendDropdownOpen(!mainLegendDropdownOpen)}
          >
            <div className="flex items-center align-middle">
              <p className={`font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${mainLegend ? 'text-white' : 'text-steelGray'}`}>
                {mainLegend ? mainLegend : "Select your main legend"}
              </p>
            </div>
            <span
              className={` ${
                mainLegendDropdownOpen ? "open rotate-[180deg]" : ""
              }`}
            >
              <DownSelectArrow />
            </span>
          </div>
          {mainLegendDropdownOpen && (
            <div className="absolute left-0 bottom-0 translate-y-full z-[10] bg-lightBlack bg-no-repeat bg-cover w-full">
              <div className="flex flex-col rounded-md border border-white border-opacity-[0.2] overflow-y-scroll max-h-[110px]">
                {apexLegendNames.map((legendName) => {
                  const legendAlreadyPicked = altLegend !== '' && legendName === altLegend;
                  return (
                    <button key={legendName}
                          disabled={legendAlreadyPicked}
                          onClick={() => {
                            setMainLegend(legendName),
                            setMainLegendDropdownOpen(false);
                          }}
                          className={`mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center
                                      ${legendAlreadyPicked ? 'bg-red/20 pointer-events-none' : ''}`}>
                      {legendName}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <h2 className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
        Alt Legend *
      </h2>
      <div className="w-full block mt-2">
        <div className="gameInput relative inline-block w-full">
          <div
            className={`gap-3 flex items-center justify-between bg-black rounded-xl py-3 px-4 relative cursor-pointer border-2 ${altLegendDropdownOpen ? 'border-green': 'border-black/0'}`}
            onClick={() => setAltLegendDropdownOpen(!altLegendDropdownOpen)}
          >
            <div className="flex items-center align-middle">
              <p className={`font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${altLegend ? 'text-white' : 'text-steelGray'}`}>
                {altLegend ? altLegend : "Select your alt legend"}
              </p>
            </div>
            <span
              className={` ${
                altLegendDropdownOpen ? "open rotate-[180deg]" : ""
              }`}
            >
              <DownSelectArrow />
            </span>
          </div>
          {altLegendDropdownOpen && (
            <div className="absolute left-0 bottom-0 translate-y-full z-[10] bg-lightBlack bg-no-repeat bg-cover w-full">
              <div className="flex flex-col rounded-md border border-white border-opacity-[0.2] overflow-y-scroll max-h-[110px]">
                {apexLegendNames.map((legendName) => {
                  const legendAlreadyPicked = mainLegend !== '' && legendName === mainLegend;
                  return (
                    <button key={legendName}
                          disabled={legendAlreadyPicked}
                          onClick={() => {
                            setAltLegend(legendName),
                            setAltLegendDropdownOpen(false);
                          }}
                          className={`mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center
                                      ${legendAlreadyPicked ? 'bg-red/20 pointer-events-none' : ''}`}>
                      {legendName}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <h2 className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
        Favourite Weapon *
      </h2>
      <div className="w-full block mt-2">
        <div className="gameInput relative inline-block w-full">
          <div
            className={`gap-3 flex items-center justify-between bg-black rounded-xl py-3 px-4 relative cursor-pointer border-2 ${favouriteWeaponDropdownOpen ? 'border-green': 'border-black/0'}`}
            onClick={() => {
              setFavouriteWeaponDropdownOpen(!favouriteWeaponDropdownOpen)
              setTimeout(() => {
                if (!favouriteWeaponDropdownOpen && containerRef.current) {
                  const container = (containerRef.current as HTMLDivElement);
                  container.scrollTop = container.scrollHeight;
                }
              }, 20)
            }}
          >
            <div className="flex items-center align-middle">
              <p className={`font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${favouriteWeapon ? 'text-white' : 'text-steelGray'}`}>
                {favouriteWeapon ? favouriteWeapon : "Select your favourite weapon"}
              </p>
            </div>
            <span
              className={` ${
                favouriteWeaponDropdownOpen ? "open rotate-[180deg]" : ""
              }`}
            >
              <DownSelectArrow />
            </span>
          </div>
          {favouriteWeaponDropdownOpen && (
            <div className="absolute left-0 bottom-0 translate-y-full z-[10] bg-lightBlack bg-no-repeat bg-cover w-full">
              <div className="flex flex-col rounded-md border border-white border-opacity-[0.2] overflow-y-scroll max-h-[110px]">
                {apexLegendWeaponNames.map((favouriteWeapon) => {
                  return (
                    <div key={favouriteWeapon}
                          onClick={() => {
                            setFavouriteWeapon(favouriteWeapon),
                            setFavouriteWeaponDropdownOpen(false);
                          }}
                      className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center">
                      {favouriteWeapon}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ApexLegendsForm;
