// context
import { useAnnouncementContext } from '@provider/AnnouncementProvider';
// components
import MarkdownConverter from '@components/global/MarkdownConverter';
// icons
import { IoIosInformationCircleOutline } from "react-icons/io";
import { FaXmark } from 'react-icons/fa6';

const AnnouncementBar = () => {
  const { announcement, announcementVisible, announcementDismissable, dismissAnnouncement} = useAnnouncementContext();

  return announcement && announcementVisible ? (
    <div className='relative w-full font-compact text-white bg-lightBlack
                    flex items-center justify-center gap-x-2 h-[30px]'>
      <IoIosInformationCircleOutline className='text-green w-[18px] h-auto aspect-square'/>
      <MarkdownConverter markdown={announcement} inline={true} className='flex items-center gap-x-1 text-sm -mb-1'/>
      {announcementDismissable ? (
        <button type='button'
                aria-label='Dismiss announcement'
                onClick={dismissAnnouncement}
                className='absolute top-1/2 right-3 -translate-y-1/2 flex items-center justify-center w-[16px] h-auto aspect-square
                           text-steelGray hover:text-white transition-colors'>
          <FaXmark className='text-sm'/>
        </button>
      ) : ''}
    </div>
  ) : '';
}

export default AnnouncementBar;
