import { ReactNode, MouseEvent} from "react"

interface IButtonPrimary {
  children: ReactNode,
  className?: string,
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void,
  disabled?: boolean,
  type?: "button" | "submit" | "reset",
  negative?: boolean,
  label?: string,
}

const ButtonPrimary: React.FC<IButtonPrimary> = ({children, className, onClick, disabled, type, label, negative}) => {
  return (
    <button aria-label={label} type={type ? type : 'button'}
            disabled={disabled === true || !onClick}
            onClick={onClick}
            className={`${className} flex justify-center items-center font-compact w-full text-base font-semibold py-[0.938rem] !leading-4 rounded-xl
                uppercase text-center
                ${negative ? "bg-red/70 hover:bg-red gap-2 disabled:opacity-50 disabled:hover:bg-red/70 text-black"
                : "bg-green hover:bg-gorse gap-2 disabled:opacity-50 disabled:hover:bg-green text-black"}`}>
      {children}
    </button>
  )
}

export default ButtonPrimary;
