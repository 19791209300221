// context
import { useSettingsContext } from "../SettingsProvider";
// components
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";
// icons
import { GrayLeftArrow } from "../../common/icons/Header";

const AccountSettings = () => {
  const { setSidebarOpen } = useSettingsContext();

  return (
    <div className="text-white max-lg:mt-6 min-h-[65vh]">
      <div className="font-wide font-bold max-lg:mt-6 text-base !leading-5 uppercase text-white flex gap-2 items-center lg:hidden">
        <button type='button'
                onClick={() => setSidebarOpen(true)}
                className="block cursor-pointer my-3">
          <GrayLeftArrow />
        </button>
        <h2>Account</h2>
      </div>

      <ChangePassword/>
      <DeleteAccount/>
    </div>
  );
};

export default AccountSettings;
