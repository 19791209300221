// context
import { useTournamentContext } from "../TournamentProvider";
// utils
import { decodeBase64 } from '@utils/encodingDecoding';
// components
import MarkdownConverter from '@components/global/MarkdownConverter';

const Rules = () => {
  const { tournament } = useTournamentContext();

  const rules = tournament?.rules;

  const rulesMarkdown = (typeof rules === 'string' && rules !== '') ? decodeBase64(rules) : '# Rules Coming Soon';

  return tournament ? (
    <div className="mt-10 px-4 sm:px-8 lg:px-12 font-compact text-steelGray">
      <MarkdownConverter markdown={rulesMarkdown}/>
    </div>
  ): '';
};

export default Rules;
