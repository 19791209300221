//  TERM OF USE TOPIC LIST MAP

export const TermsTitleList = [

  {
    title: "Platform  Purpose",
  },
  {
    title: "License to Use the platform",
  },
  {
    title: "Eligibility",
  },
  {
    title: "Fees",
  },
  {
    title: "Required Information",
  },
  {
    title: "Deposits and Payments",
  },
  {
    title: "User Accounts",
  },
];
// TERM OF USE DATA LIST MAPPING
export const TermsContentList = [
  {
    title: "Introduction",
    description:
      "Versus Gaming Ltd (Company Number 10510740) registered at, 2 Pembridge Gardens, Stevenage, SG2 8BQ United Kingdom (`Versus`, `we`, `us`, or `our`), offers online esports infrastructure to key stakeholders in the industry including players, teams, organisers and game developers, available at https://www.versusgaming.gg These terms of use (`Terms`) shall govern your (`You`, `Your` or `Yourself`) access and use of the Versus platform. These Terms enter into effect between you and us when you have completed the registration process in accordance with what is stated herein and remain in effect until terminated in accordance herewith.",
  },
  {
    title: "Platform  Purpose",
    description:
      "The Versus platform enables registered users ('Users') and other third parties to provide certain game related services ('Services', as further detailed herein) on the Platform and to transact and communicate directly with other Users that are looking to engage with or purchase such Services. Services may include: i) Offering or operating esports tournaments, leagues, or competitions in competitive video games (together 'Competitions'), ii) coaching and esports related services iii) local events, broadcasting and other gaming experiences, iv) access to exclusive membership services Users are able to compete in competitions which are supported on the Versus platform. Users (and only Users) will be able to participate in such competitions, whether they are organised by Versus, individual Users or Organisers. Competitions provided on the Platform are categorised as 'games of skill' and the outcome of any matches played in a Competition (herein referred to as a 'Match') is solely the result of skill, not chance. The video games ('Game Titles') provided through or integrated with the Versus platform are offered as-is and are owned by the respective game developer or publisher ('Game Owners'). Game Titles that are provided through the Platform are available to Users and Organisers solely for the purpose of playing, hosting and organizing Competitions, for creating Spaces and to monetize these Spaces or audiences on the Platform. For clarity, Game Owners may through separate agreements with Versus restrict or limit the extent to which Users and Organisers can offer services and competitions through the Platform. As such, the use case for the different Game Titles on the Platform may vary.",
  },
  {
    title: "License to Use the platform",
    description:
      "Subject to the terms and restrictions of these Terms, you are upon the completion of the registration process to the Versus platform as described herein granted a limited, revocable, non-exclusive and non-transferable right to during the validity of these Terms – as a User – access and use the Versus platform, solely for the purposes outlined in the section above. The use of the Platform is subject to the applicable fees and other payments. For the avoidance of doubt, You are not granted any other licenses in respect of the Versus platform or otherwise other than expressly set out herein.",
  },
  {
    title: "Eligibility",
    description:
      "In order to use the Platform as a User, You need to register and maintain a 'User Account'. To register a User Account on the Platform, You must: i) be a natural person; ii) be at least thirteen (13) years of age. However, if You are below the age of eighteen (18), You need to have your guardians’ approval to register a User Account. Furthermore, to conduct any payments or transactions on the Versus platform, you need to be at least eighteen (18) years of age or have your guardians’ approval to conduct the payment or transaction; iii) submit your email address and otherwise complete the registration process as follows on the Versus platform (and as further detailed in Section 5 below); and iv) comply with these Terms at all times. Officers, directors and agents of Versus and its affiliates are not eligible to participate in any Competitions, other than for test and product development purposes. You are fully aware of the fact that payments made to participate in competitions, for membership Subscriptions or other activities on the Platform are generally non-refundable unless explicitly stated otherwise herein or as required by applicable mandatory law. By entering into these Terms You acknowledge and agree that the delivery of our obligations commences and that there is no right to withdrawal. Notwithstanding the foregoing, You may terminate these Terms at any time.",
  },
  {
    title: "Fees",
    description:
      "Your use of the Versus platform and services may – depending on what functions of the platform you wish to use or which services you need to access – require you to pay one or more of the fees: i) an entry fee to participate in certain pay-to-enter competitions ii) subscription fees iii) a transaction fee and/or an administration fee to Versus when you withdraw funds according to iv) currency converter fee to the applicable Payment Provider (as defined below) when paying with currency other than EUR;",
  },
  {
    title: "Required Information",
    description:
      "Upon registration and/or during the processing of payments to or from Your User Account, You may for such purposes be required to provide the following information: Username, Password, Email Address, Date of Birth, Full Name, Address, Phone Number, In-Game Information, Payment Information The information provided must be true, accurate, current and complete. If Versus has sufficient reasons to suspect that you are registering a User Account with purposely incorrect information, Versus reserves the right to suspend, or if needed, terminate your User Account. Versus also reserves the right, in its sole discretion, to change and/or suspend or terminate offensive and/or inappropriate usernames.",
  },
  {
    title: "Deposits and Payments",
    description:
      "You may transfer funds to be deposited to Your User Account or use the funds that have been awarded to you via prizes. The minimum limit for deposits to your User Account on the Platform equals €5.00 EUR. No interest is payable on account funds. All payments must be made from a bank or payment account for which you are the named account holder. Versus reserves the right to require confirmation or validation of any or all deposits previously made to your User Account. You acknowledge that in the case of any dispute, your validation may be used by Versus as evidence. To pay for Services (such as a membership Subscription) or to participate in a pay-to-enter competitions, and if you have not acquired funds through playing in competitions, You must deposit funds to Your User Account for the subsequent payment of the applicable fee(s). Any attempt to defraud Versus through the use of credit cards or other methods of payment, regardless of the outcome, or any failure by you to honour legitimate charges or requests for payment, or confirmation of deposits, will result in immediate termination of Your User Account and forfeiture of any prizes.",
  },
  {
    title: "User Accounts",
    description:
      "You are solely responsible for all obligations and compliance with these Terms and are entitled to all benefits accruing thereto. You may not allow any other person to access your User Account, access the Versus platform, accept any prizes, offer any services or participate in any competition using your User Account. User Accounts are non-transferable. All activities undertaken under your User Account will be deemed to have been done by You. You are only permitted to register one active User Account on the Versus platform. Versus reserves the right, but is not obliged, to monitor any effort to establish multiple User Accounts. In the event it is discovered that you have opened more than one User Account in violation of these Terms, all Your User Accounts may be suspended or terminated without any notice. Versus reserves the right to deny access to those Users who use proxy servers and/or IP addresses residing in certain geographical areas. In consideration of the use of the Versus platform and your User Account, you agree to: i) provide accurate, current and complete information about Yourself as may be reasonably required by any registration forms (Registration Data); ii) maintain the security of your password; iii) maintain and promptly update Registration Data, to keep it accurate and complete; and iv) be fully responsible for all use of your User Account and for any actions that take place using your User Account, including all purchases that occur under Your account.",
  },
];

export const PrivacyPolicyTitleList = [

  {
    title: "Personal Data",
  },
  {
    title: "Purposes for Processing",
  },
  {
    title: "Disclosure of Personal Data",
  },
  {
    title: "Cookies",
  },
  {
    title: "Storing of Personal Data",
  },
  {
    title: "Rights",
  },
  {
    title: "Children",
  },
  {
    title: "Complaint",
  },
  {
    title: "Notice of Changes to the Privacy Policy",
  },
  {
    title: "Contact Us",
  },
];

export const PrivacyPolicyList = [
  {
    title: "General Information",
    description:
      "This document ('Privacy Policy') describes how Versus Gaming Ltd (Company Number 10510740) registered at, 2 Pembridge Gardens, Stevenage, SG2 8BQ United Kingdom ('Versus', 'we', 'us', or 'our'), collects, uses, and processes personal data. Versus is data controller processing of your personal data as described by this Privacy Policy. This Privacy Policy applies when using the “Versus” platform. Please notice that upon using Versus, You may be directed to websites or services provided by third parties where Your personal data is processed by such third parties and governed by their respective privacy policies. All definitions in this Privacy Policy shall be interpreted in accordance with applicable data protection laws which refer to the General Data Protection Regulation (GDPR) and the Directive on Privacy and Electronic Communication. Definitions specified in the Terms of Use shall also apply to this Privacy Policy.",
  },
  {
    title: "Personal Data",
    description:
      "Here we describe the personal data we process, our purpose for the processing of said personal data, and our legal basis for the processing of said personal data. Personal Data we collect: Name, Username, Email Address, Password, Profile Image, Address, Telephone Number, Payment Information, Date of Birth, Region. Purposes for processing: Administration of user accounts, In-Game verification, User Support, Direct Marketing, Handling Opt-Out Requests, Promotion of Event Participation/Success, Recommending Relevant Events and Content. Legal basis for processing: Complying with contractual obligations towards users, Providing the best User Experience. We collect personal data directly from you, the user, when you create a Versus account and from third parties. For example, if you choose to register your account by using your Discord account.",
  },
  {
    title: "Purposes for Processing",
    description:
      "Your personal data may be disclosed to publishers, sponsors or partners when you participate in activities or competitions hosted by them. We may also disclose personal data to service providers that we use to support the Platform. Versus will not intentionally disclose or share any personal data about users to third parties without prior consent, except where Versus, in good faith, believes such disclosure is necessary to comply with applicable law or contractual provisions in our Terms of Use. Versus processes personal data in compliance with the EU/EEA. In some cases, personal data may be transferred to and processed in a country outside the EU/EEA. In those cases, Versus ensures that appropriate measures are in place to protect personal data in accordance with applicable data protection law. Versus reserves the right to collect and disclose general demographic and other market information that do not personally identify You, to any person, without Your additional consent.",
  },
  {
    title: "Disclosure of Personal Data",
    description:
      "A 'cookie' is a small text file sent from Versus’ server to your browser. The cookie cannot identify users personally and can only recognise the browser that is installed on a user’s computer which is used to access the Versus platform. Different cookies are sent to different computers if you access Versus from different computers. Cookies are required for the Platform to function correctly and safely, for example when logging into your User Account. Cookies are also used to measure the data traffic to the Platform and for statistical reasons. There are two types of cookies: one type is called 'session cookies', and the other is called 'permanent cookies'. The Platform uses both session cookies and permanent cookies. You can opt-out from our use of cookies on Your computer by editing a setting in Your browser. Such settings may imply that certain functions on the Platform will not function properly or be available.",
  },
  {
    title: "Cookies",
    description:
      "Personal data will be stored as long as you have an active User Account. Two years after your last login, your User Account will be deleted due to inactivity unless the data is required for a specific reason or Versus has agreed with you to store the personal data for a specified period. Versus reserves the right to delete personal data earlier if deemed appropriate.",
  },
  {
    title: "Storing of Personal Data",
    description:
      "Personal data will be stored as long as you have an active User Account. Two years after your last login, your User Account will be deleted due to inactivity unless the data is required for a specific reason or Versus has agreed with you to store the personal data for a specified period. Versus reserves the right to delete personal data earlier if deemed appropriate.",
  },
  {
    title: "Rights",
    description:
      "Rights you have under applicable data protection law are described below. If You wish to send a request regarding any of Your rights, please contact us via the following e-mail address: data@versusgaming.uk Right to Access - You have the right to request access and further information concerning the processing of Your personal data. You have a right to obtain a copy of the personal data that we process relating to you free of charge once each calendar year. For any additional copies requested by You, we may charge a reasonable fee based on administrative costs. Right to Rectification - If the personal data Versus holds about you is inaccurate or not complete, you have the right to ask us to rectify or complete it. If that personal data has been passed to a third party with Your consent or for legal reasons, we will also ask them to rectify or complete Your personal data. Right to Erasure or Restriction - You have the right to erase or restrict the processing of your personal data. Right to Object - You have the right to object to Versus’s processing of your personal data. Right to Withdrawal - If you have given us your consent to process Your personal data but change your mind, you have the right to withdraw your consent at any time. The withdrawal of your consent does not affect the lawfulness of the processing based on the consent before its withdrawal. Data Portability - You have the right to data portability. Data portability means that you can receive the personal data that you have provided to us, in a structured, commonly used and machinereadable format, and have the right to transfer such personal data to a third party.",
  },
  {
    title: "Children",
    description:
      "The Versus platform is not directed to persons under the age of thirteen (13). If you are a parent or guardian of a person under the age of 13 and you become aware of that the child has provided personal data to us without your consent, please contact us via the following email address: support@versusgaming.uk",
  },
  {
    title: "Complaint",
    description:
      "Any complaints regarding our processing of your personal data, you may file a complaint to the relevant data protection authority if you feel that Versus has not adequately responded to Your request.",
  },
  {
    // title: "Notice of Changes to the Privacy Policy",
    title: "Notice of Changes to the Privacy Policy",
    description:
      "This Privacy Policy may occasionally be updated. Any amendments to this Privacy Policy will be published on the platform and, where appropriate, sent to you in writing.",
  },
  {
    title: "Contact Us",
    description:
      "You can contact us at the following address: E-mail: support@versusgaming.uk",
  },
];
