export const GameSelectBottomLine = () => {
    return (
      <svg
        className="max-w-[26.875rem] sm:max-w-[35.25rem] md:max-w-[42.5rem] lg:max-w-[68.75rem]"
        width="900"
        height="80"
        viewBox="0 0 1104 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M552 40H40V8" stroke="#D4FF27" />
        <path d="M88 40H40V8" stroke="#1F272E" />
        <g filter="url(#filter0_d_544_18492)">
          <path d="M36 40L40 44L44 40L40 36L36 40Z" fill="#D4FF27" />
        </g>
        <g filter="url(#filter1_d_544_18492)">
          <path
            d="M28.7071 40L40 51.2929L51.2929 40L40 28.7071L28.7071 40Z"
            stroke="#D4FF27"
            shapeRendering="crispEdges"
          />
        </g>
        <path d="M552 40H1064V8" stroke="#D4FF27" />
        <path d="M1016 40H1064V8" stroke="#1F272E" />
        <g filter="url(#filter2_d_544_18492)">
          <path d="M1068 40L1064 44L1060 40L1064 36L1068 40Z" fill="#D4FF27" />
        </g>
        <g filter="url(#filter3_d_544_18492)">
          <path
            d="M1075.29 40L1064 51.2929L1052.71 40L1064 28.7071L1075.29 40Z"
            stroke="#D4FF27"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_544_18492"
            x="8"
            y="8"
            width="64"
            height="64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_544_18492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_544_18492"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_544_18492"
            x="0"
            y="0"
            width="80"
            height="80"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_544_18492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_544_18492"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_544_18492"
            x="1032"
            y="8"
            width="64"
            height="64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_544_18492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_544_18492"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_544_18492"
            x="1024"
            y="0"
            width="80"
            height="80"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_544_18492"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_544_18492"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };
export const GameSelectAboveLine = () => {
    return (
      <svg
        className="max-w-[33.125rem] sm:max-w-[42.5rem] md:max-w-[55rem] lg:max-w-[85.75rem]"
        width="1344"
        height="151"
        viewBox="0 0 1344 151"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M672 0V55" stroke="#151A1F" />
        <path d="M672 31V55" stroke="#D4FF27" strokeWidth="2" />
        <g filter="url(#filter0_dd_1702_21686)">
          <path
            d="M665 54.9997L672 48L678.999 54.9997L672 61.9994L665 54.9997Z"
            fill="#D4FF27"
          />
        </g>
        <path d="M674 53H162V149" stroke="#D4FF27" />
        <path d="M210 53H162V149" stroke="#1F272E" />
        <g filter="url(#filter1_d_1702_21686)">
          <path d="M158 53L162 49L166 53L162 57L158 53Z" fill="#D4FF27" />
        </g>
        <g filter="url(#filter2_d_1702_21686)">
          <path
            d="M150.707 53L162 41.7071L173.293 53L162 64.2929L150.707 53Z"
            stroke="#D4FF27"
            shapeRendering="crispEdges"
          />
        </g>
        <path d="M527 41H647" stroke="#D4FF27" />
        <path d="M672 55H1184V151" stroke="#D4FF27" />
        <path d="M1136 55H1184V151" stroke="#1F272E" />
        <g filter="url(#filter3_d_1702_21686)">
          <path d="M1188 55L1184 51L1180 55L1184 59L1188 55Z" fill="#D4FF27" />
        </g>
        <g filter="url(#filter4_d_1702_21686)">
          <path
            d="M1195.29 55L1184 43.7071L1172.71 55L1184 66.2929L1195.29 55Z"
            stroke="#D4FF27"
            shapeRendering="crispEdges"
          />
        </g>
        <path d="M819 43H699" stroke="#D4FF27" />
        <defs>
          <filter
            id="filter0_dd_1702_21686"
            x="633"
            y="16"
            width="77.9995"
            height="77.9995"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1702_21686"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="16" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_1702_21686"
              result="effect2_dropShadow_1702_21686"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_1702_21686"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_1702_21686"
            x="130"
            y="21"
            width="64"
            height="64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1702_21686"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1702_21686"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_1702_21686"
            x="122"
            y="13"
            width="80"
            height="80"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1702_21686"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1702_21686"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_d_1702_21686"
            x="1152"
            y="23"
            width="64"
            height="64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1702_21686"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1702_21686"
              result="shape"
            />
          </filter>
          <filter
            id="filter4_d_1702_21686"
            x="1144"
            y="15"
            width="80"
            height="80"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1702_21686"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1702_21686"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };

  // GRIND START ICONS
export const GrindStartsTopLine = () => {
    return (
      <svg
        className="mx-auto lg:mb-4 mt-[-0.625rem] sm:mt-0"
        width="214"
        height="93"
        viewBox="0 0 214 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 40H96" stroke="#D4FF27" />
        <g filter="url(#filter0_d_555_16857)">
          <path d="M103 40L107 36L111 40L107 44L103 40Z" fill="#D4FF27" />
        </g>
        <g filter="url(#filter1_d_555_16857)">
          <path
            d="M95.7071 40L107 28.7071L118.293 40L107 51.2929L95.7071 40Z"
            stroke="#D4FF27"
            shapeRendering="crispEdges"
          />
        </g>
        <g filter="url(#filter2_d_555_16857)">
          <path d="M83 40L107 64L131 40" stroke="#D4FF27" />
        </g>
        <path d="M118 40H214" stroke="#D4FF27" />
        <defs>
          <filter
            id="filter0_d_555_16857"
            x="75"
            y="8"
            width="64"
            height="64"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_555_16857"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_555_16857"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_555_16857"
            x="67"
            y="0"
            width="80"
            height="80"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_555_16857"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_555_16857"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_555_16857"
            x="54.6465"
            y="11.6465"
            width="104.707"
            height="81.0605"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_555_16857"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_555_16857"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };

  export const GrindStartLeftLine = () => {
    return (
      <svg
        width="385"
        height="86"
        viewBox="0 0 385 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M-160 85C-29.3231 85 134.681 85 134.681 85L224.931 1H361"
          stroke="#D4FF27"
        />
        <path
          d="M24 85C154.677 85 158.681 85 158.681 85L248.931 1H385"
          stroke="#D4FF27"
        />
      </svg>
    );
  };
  export const GrindStartRightLine = () => {
    return (
      <svg
        width="385"
        height="86"
        viewBox="0 0 385 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M545 85C414.323 85 250.319 85 250.319 85L160.069 1H24"
          stroke="#D4FF27"
        />
        <path
          d="M361 85C230.323 85 226.319 85 226.319 85L136.069 1H0"
          stroke="#D4FF27"
        />
      </svg>
    );
  };

  export const GrindTopArrow = () => {
    return (
      <svg
        className="mx-auto mb-[-0.625rem] sm:mb-0"
        width="78"
        height="79"
        viewBox="0 0 78 79"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M39 15.5V39.5" stroke="#D4FF27" />
        <g filter="url(#filter0_dd_1945_22122)">
          <path
            d="M32 39.4997L38.9997 32.5L45.9994 39.4997L38.9997 46.4994L32 39.4997Z"
            fill="#D4FF27"
          />
        </g>
        <defs>
          <filter
            id="filter0_dd_1945_22122"
            x="0"
            y="0.5"
            width="78"
            height="77.9994"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="14" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1945_22122"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="16" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_1945_22122"
              result="effect2_dropShadow_1945_22122"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_1945_22122"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );
  };

  export const GrindBgEffect = () => {
    return (
      <svg
        className="w-[40rem] sm:w-[43.75rem] md:w-[50rem] lg:w-[62.5rem] xl:!w-[65.625rem] "
        width="1050"
        height="1050"
        viewBox="0 0 992 992"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 496L496 990L990 496L496 1.99998L2 496Z"
          stroke="#151A1F"
          strokeWidth="2"
        />
        <path
          d="M55 496L496 937L937 496L496 55L55 496Z"
          stroke="#151A1F"
          strokeWidth="2"
        />
      </svg>
    );
  };
