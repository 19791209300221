import { useState, useCallback, useEffect } from "react"; // Import React and useState
import { useNavigate } from "react-router-dom";
// firebase
import { functions } from '@src/firebase';
import { httpsCallable } from "firebase/functions";
// context
import { useAuthContext } from "../../../provider/AuthContextProvider";
// utils
import { logout } from "@utils/AuthHelpers";
// packages
import { toast } from 'react-toastify';
// components
import Modal from "@ui/Modal";

const deleteUser = httpsCallable(functions, 'deleteUser');

const DeleteAccount = () => {
  const navigate = useNavigate();

  const { userObj } = useAuthContext();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState<boolean>(false);

  const [confirmationInput, setConfirmationInput] = useState<string>('');
  const [confirmationValid, setConfirmationValid] = useState<boolean>(false);

  const checkConfirmationValid = useCallback(() => {
    const localConfirmationValid = userObj !== null && userObj.username === confirmationInput;
    setConfirmationValid(localConfirmationValid);
  }, [userObj, confirmationInput]);

  useEffect(() => {
    checkConfirmationValid();
  }, [confirmationInput, checkConfirmationValid])

  const handleDeleteAccount = async () => {
    setSubmitting(true);
    if (userObj) {
      const deletePromise = deleteUser({userId: userObj.uid});
      toast.promise(deletePromise, {
        pending: 'Deleting your account',
        success: 'Your account was deleted',
        error: 'Error deleting your account, please contact support for more help',
      });
      logout();
      navigate('/');
      await deletePromise;
    }
    setSubmitting(false);
  }

  return userObj ? (
    <div className="mb-12">
      <h2 className="text-white font-compact font-semibold text-lg mb-2">Delete Account</h2>
      <div className="flex flex-col gap-y-4">
        <button
          className="bg-red/10 hover:bg-red/20 border border-red/50 rounded-xl mt-2 pt-[1.125rem] pb-[0.875rem] px-6 w-fit
                    text-red uppercase font-compact text-base font-medium !leading-4
          !tracking-[0.01rem] text-center disabled:opacity-30 "
          onClick={() => setDeleteAccountModalOpen(true)}>
          Delete Account
        </button>
        <Modal title={"Delete Account"}
                      open={deleteAccountModalOpen}
                      setOpen={setDeleteAccountModalOpen}
                      buttonOnClick={handleDeleteAccount}
                      buttonText="Delete my Account"
                      buttonNegative={true}
                      buttonDisabled={!confirmationValid || submitting}>
        <div className="flex flex-col gap-y-3">
          <p className="text-red/90 uppercase font-compact text-sm font-thin text-center w-fit mx-auto">
            This action will delete all your data
          </p>
          <p className="text-sm font-thin">
            If you are the captain of a team, all players will be removed from the team and the team will be deleted.
          </p>
          <p className="text-red/90 font-compact text-sm font-thin text-center">To proceed please type <em className="my-2 text-white not-italic p-[6px] bg-lightGray rounded">{userObj.username}</em> into the following box:</p>
          <input type="text"
                value={confirmationInput}
                onChange={(e) => setConfirmationInput(e.target.value)}
                className="w-full p-2 text-white bg-black rounded-lg outline-none border-none"/>
        </div>

        </Modal>
      </div>
    </div>
  ) : '';
}

export default DeleteAccount;
