import { ReactNode, useEffect, useState } from "react";
// context
import { useAuthContext } from "@provider/AuthContextProvider";
// types
import { ApexMapInfos } from "@customTypes/Games/ApexLegends";
// utils
import { getMapNameFromAcronym } from "@utils/Games/ApexLegends";
// components
import CheckInPoiPreferencesMapList from "./CheckInPoiPreferencesMapList";
// icons
import { FaPen } from "react-icons/fa";
import { MouseClickIcon } from "@src/components/common/icons/Common";

interface IMiniDropSpotMapCard {
  mapAcronym: string,
  mapSet: boolean,
  setCurrentMapList: (map: string) => void,
}

const MiniDropSpotMapCard: React.FC<IMiniDropSpotMapCard> = ({ mapAcronym, mapSet, setCurrentMapList}) => {

  return (
    <div className="w-full h-[65px] p-2 px-4 flex items-center justify-between bg-lightGray rounded-xl">
      <div className='w-full flex flex-col items-start justify-center gap-y-2'>
        <p className={`text-sm font-wide font-semibold text-white uppercase leading-4`}>{getMapNameFromAcronym(mapAcronym)}</p>
        <p className={`flex items-center gap-x-2 leading-4
                        font-compact text-steelGray`}>
          <span>{mapAcronym}</span>
        </p>
      </div>
      {mapSet ? (
        <button type="button" aria-label="edit map preferences"
                className="rounded-xl bg-ebonyClay text-white font-compact uppercase font-semibold
                          flex items-center justify-center gap-x-3 hover:opacity-75 transition-opacity px-3 h-[30px]"
                onClick={() => setCurrentMapList(mapAcronym)}>
          <span>
            Edit
          </span>
          <FaPen className="w-[10px] h-auto aspect-square fill-green"/>
        </button>
      ) : (
        <button type="button" aria-label="edit map preferences"
                className="rounded-xl bg-green text-black font-compact uppercase font-semibold
                          flex items-center justify-center gap-x-3 hover:opacity-75 transition-opacity px-3 h-[30px]"
                onClick={() => setCurrentMapList(mapAcronym)}>
          <span>
            Set
          </span>
          <MouseClickIcon className="w-[12px] h-auto aspect-square fill-black"/>
        </button>
      )}
    </div>
  );
}

interface ICheckInPoiPreferences {
  modalRef: React.MutableRefObject<HTMLDivElement | null>,
  setStepValid: (valid: boolean) => void,
  setPoiPreferencesButtonText: (text: ReactNode | null) => void,
  setPoiPreferencesButtonOnClick: (onClick: (() => void) | null) => void,
}

const CheckInPoiPreferences: React.FC<ICheckInPoiPreferences> = ({setStepValid, modalRef, setPoiPreferencesButtonText, setPoiPreferencesButtonOnClick}) => {
  const { userTeam } = useAuthContext();
  const availablePoiMaps = Object.keys(ApexMapInfos);

  const [currentMapList, setCurrentMapList] = useState<string>('');
  const [everyMapSet, setEveryMapSet] = useState<boolean>(false);

  useEffect(() => {
    if (userTeam) {
      const setMaps = Object.keys(userTeam.gamePreferences.apex.poiPreferences);
      const localEveryMapSet = availablePoiMaps.every((mapAcronym) => setMaps.includes(mapAcronym));
      setEveryMapSet(localEveryMapSet)
    }
  }, [userTeam]);

  useEffect(() => {
    setStepValid(everyMapSet);
  }, [everyMapSet]);

  return userTeam ? (
    <div className="flex flex-col gap-y-3 text-steelGray font-compact">
      <p>
        {everyMapSet ? 'Verify' : 'Set'} your POI Preferences.
      </p>

      <div className={`flex flex-col gap-y-2 ${currentMapList === '' ? '' : 'hidden'}`}>
        {availablePoiMaps.map((map) => (
          <MiniDropSpotMapCard mapAcronym={map}
                              mapSet={userTeam.gamePreferences.apex.poiPreferences[map] !== undefined}
                              setCurrentMapList={setCurrentMapList}/>
        ))}
      </div>

      {availablePoiMaps.map((map) => (
        <>
          {currentMapList === map ? (
            <CheckInPoiPreferencesMapList map={map}
                                    modalRef={modalRef}
                                    poiLocationsForMap={ApexMapInfos[map].pois}
                                    team={userTeam}
                                    close={() => setCurrentMapList('')}
                                    setPoiPreferencesButtonText={setPoiPreferencesButtonText}
                                    setPoiPreferencesButtonOnClick={setPoiPreferencesButtonOnClick}/>
          ) : ''}
        </>
      ))}
    </div>
  ) : '';
}

export default CheckInPoiPreferences;
