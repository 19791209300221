import { useProfileContext } from "../ProfileProvider";

function ProfileStream() {
  const { profile } = useProfileContext();

  const getChannelNameFromTwitchLink = (twitchLink: string) => {
    const channelRegex = /twitch\.tv\/(\w+)\/?/;
    const matchData = channelRegex.exec(twitchLink);
    const channelName = matchData ? matchData[1] : '';
    return channelName;
  }

  const hostUrl = import.meta.env.DEV ? 'localhost:5173' : import.meta.env.VITE_HOST_DOMAIN;

  return profile && profile.twitch ? (
    <div className="w-full">
      <h2 className="font-wide font-bold text-white !leading-5 uppercase">
        stream
      </h2>
      <div className="overflow-hidden w-full relative mt-4 ">
        <iframe
          src={`https://player.twitch.tv/?channel=${getChannelNameFromTwitchLink(profile.twitch)}&parent=${hostUrl}&muted=true`}
          height="1080"
          width="1920"
          allowFullScreen
          className="video w-full custom_xsm:min-h-[15rem] rounded-lg block h-[20rem] sm:h-[26rem] lg:h-[28.5rem] object-cover border border-steelGray/50">
        </iframe>
      </div>
    </div>
  ): '';
}

export default ProfileStream;
