import { useRef, DragEvent, ChangeEvent } from "react";
// context
import { useTeamInfoContext } from '@components/team/TeamInfoProvider';
// packages
import { toast } from "react-toastify";
// icons
import { GreenCrossIcon } from "@icons/FilterIcons";
import { UploadLogoIcon } from "@icons/ProfileIcons";

// we provide a existing property on both of these, so that in the update submission we only upload NEW logos (existing = false)
export interface FileWithPreview extends File {
  preview: string,
  existing: boolean, // defaults to false
  toBeRemoved: boolean, // defaults to false
}

export interface ExistingUploadAsFile {
  name: string,
  preview: string,
  existing: boolean, // defaults to true
  toBeRemoved: boolean, // we will check on existing uploads whether we should remove an existing upload
}

const UploadSponsorLogoBox = () => {
  const { teamInfo, setTeamInfo } = useTeamInfoContext();

  const fileInput = useRef<HTMLInputElement>(null);

  const clickFileInput = () => {
    fileInput.current?.click();
  };

  const handleNewAttachement = (e: ChangeEvent<HTMLInputElement> | null, file: FileWithPreview | null) => {
    let localFile: FileWithPreview;
    if (e) { // allow for the file to be passed directly incase invocation from handleFilesDrop
      localFile = (e!.target.files as FileList)[0] as FileWithPreview;
    } else if (file) {
      localFile = file;
    } else {
      return;
    }

    if (!["image/jpeg", "image/png", "image/webp"].includes(localFile.type)) {
      toast.error(`You can only upload jpeg, png or webp image formats`);
      return;
    }

    const filePreview =  URL.createObjectURL(localFile);
    localFile.preview = filePreview;
    localFile.toBeRemoved = false;

    const newSponsorLogos = [...teamInfo.sponsorLogos, localFile];
    setTeamInfo({...teamInfo, sponsorLogos: newSponsorLogos});
  }

  const handleFilesDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0] as FileWithPreview;
    handleNewAttachement(null, file);
  };

  const handleAttachementRemoval = (logoIndex: number) => {
    const localSponsorLogos = [...teamInfo.sponsorLogos];
    if (localSponsorLogos[logoIndex].existing) {
      (localSponsorLogos[logoIndex] as ExistingUploadAsFile).toBeRemoved = true;
    } else {
      localSponsorLogos.splice(logoIndex, 1);
    }
    setTeamInfo({...teamInfo, sponsorLogos: localSponsorLogos});
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <input ref={fileInput}
              type="file" accept=".jpg, .jpeg, .png, .webp"
              multiple={true} hidden={true}
              onChange={(e) => handleNewAttachement(e, null)}/>

      <div aria-label="Attach sponsor logo for upload"
            onDrop={handleFilesDrop}
            onDragOver={handleDragOver}
            onClick={clickFileInput}
            className="cursor-pointer flex justify-center items-start rounded-2xl border-2 border-ebonyClay border-dashed p-6 gap-[1.875rem]">
        <UploadLogoIcon />

        <div className="text-center flex flex-col items-start">
          <p className="font-compact font-normal text-center text-base text-white pb-3 tracking-[1%] leading-normal">
            <span className="text-green">Upload</span> or drag your sponsors
            logo here.
          </p>
          <p className="font-compact font-normal text-start text-sm text-steelGray tracking-[1%] leading-normal line-clamp-3">
            We recommend uploading logos in PNG format, with
            dimensions of 500x500px (up to 5mb).
          </p>
        </div>
      </div>

      <ul className="relative flex flex-col mx-auto gap-y-2 mt-4">
        {teamInfo.sponsorLogos.filter((file) => !file.toBeRemoved).map((file: (FileWithPreview | ExistingUploadAsFile), index: number) => (
          <li key={`sponsor${file.name}-${index}`}
              className="flex justify-between w-100 items-center flex-nowrap bg-lightGray p-4 rounded-xl">
            <span className="flex gap-4 items-center">
              <img
                className="w-[5rem] h-[5rem] object-contain object-center"
                src={file.preview}
                alt={file.name || ""}
              />
              <p className="font-compact text-base font-normal text-steelGray sm:max-w-[12.5rem] whitespace-pre-wrap break-all line-clamp-2">
                {file.name}
              </p>
            </span>
            <button onClick={() => handleAttachementRemoval(index)}
                    className="uppercase font-compact font-medium text-base text-white tracking-[1%] flex items-center h-[1rem] leading-none gap-1 ms-2 duration-300 hover:opacity-70">
              <span className="leading-none mt-1">remove</span>
              <GreenCrossIcon />
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default UploadSponsorLogoBox;
