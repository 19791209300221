import { useState } from 'react'
// components
import LiveBroadcasts from './LiveBroadcasts';
import Vods from './Vods';

const Links = () => {
  const [currentView, setCurrentView] = useState<'live' | 'vods'>('live');

  return (
    <div className='w-full'>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase whitespace-nowrap">
          Stream Links
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            <button type='button'
              onClick={() => setCurrentView('live')}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === 'live' ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Live Broadcasts</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView('vods')}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === 'vods' ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50  hover:border-steelGray'}`}>
              <span>VODs</span>
            </button>
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full lg:w-3/5'>
          {currentView === 'live' ? (
            <LiveBroadcasts/>
          ) : ''}
          {currentView === 'vods' ? (
            <Vods/>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default Links;
