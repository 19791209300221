import { ReactNode, createContext, useState, useEffect, useCallback, useContext } from "react";
import { useParams } from "react-router-dom";
// context
import { useAuthContext } from "../../provider/AuthContextProvider";
// firebase
import { firestore } from "../../firebase";
import { collection, where, query, doc, onSnapshot } from "firebase/firestore";
// types
import { DBTeam, teamConverter } from "../../firestore/teams";
import { tournamentTrophyConverter, TournamentTrophy } from "../../firestore/tournamentTrophies";
import { PerformanceHistory, performanceHistoryConverter } from "@src/firestore/performanceHistory";

// context available across all sub components of show page
// (editModalOpen represents whether the repurposed create team flow (edit) modal is visible)
interface ITeamShowContext {
  team: DBTeam | null,
  teamTrophies: TournamentTrophy[],
  teamPerformanceHistories: PerformanceHistory[],
  setTeam: (team: DBTeam) => void,
  editor: boolean, // is currentUser allowed to edit (captain/manager)
  member: boolean, // is curreentUser member of team
}

const defaultTeamShowContext = {
  team: null,
  teamTrophies: [],
  teamPerformanceHistories: [],
  setTeam: (team: DBTeam) => team,
  editor: false,
  member: false,
}

export const TeamShowContext = createContext<ITeamShowContext>(defaultTeamShowContext);

export const useTeamContext = () => {
  const context = useContext(TeamShowContext);
  return context;
}

interface ITeamShowProvider {
  handleLoadingChange: (loading: boolean) => void,
  handleTeamChange: (team: DBTeam | null) => void,
  children: ReactNode
}

const TeamShowProvider: React.FC<ITeamShowProvider> = ({children, handleTeamChange, handleLoadingChange}) => {
  const params = useParams();
  const { userObj } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(true);

  // context shared state for all sub components
  const [team, setTeam] = useState<DBTeam | null>(null);
  const [teamTrophies, setTeamTrophies] = useState<TournamentTrophy[]>([]);
  const [teamPerformanceHistories, setTeamPerformanceHistories] = useState<PerformanceHistory[]>([]);
  const [editor, setEditor] = useState<boolean>(false);
  const [member, setMember] = useState<boolean>(false);

  const getTeam = useCallback( async () => {
    const teamIdOrName = params.id_or_name;
    if (teamIdOrName) {
      // find the team by either a teamname or teamid
      const teamsCollection = collection(firestore, 'teams');
      let teamQuery;
      if (/([a-zA-Z0-9]{20})/.test(teamIdOrName)) {
        // in this query __name__ actually refers to the document ID
        teamQuery = query(teamsCollection, where('__name__', '==', teamIdOrName)).withConverter(teamConverter);
      } else {
        teamQuery = query(teamsCollection, where('teamName', '==', teamIdOrName)).withConverter(teamConverter);
      }
      onSnapshot(teamQuery, (snapshots) => {
        setLoading(false);
        if (!snapshots.empty) {
          const teamDoc = snapshots.docs[0].data();
          setTeam(teamDoc);
        }
      })
    } else if (userObj) {
      if (userObj.team) {
        const teamRef = doc(firestore, 'teams', userObj.team).withConverter(teamConverter);
        onSnapshot(teamRef, (doc) => {
          setLoading(false);
          const teamDoc = doc.data() ?? null;
          setTeam(teamDoc);
        })
      }
    }
  }, [userObj, params.id_or_name]);

  const getTeamPerformanceHistories = () => {
    let unsubscribe: () => void  = () => false;
    if (team) {
      const performanceHistoriesCollection = collection(firestore, 'performanceHistories').withConverter(performanceHistoryConverter);
      const q = query(performanceHistoriesCollection, where('teamId', '==', team.id));
      unsubscribe = onSnapshot(q, (snapshots) => {
        const performanceHistories = snapshots.docs.map((doc) => doc.data()) as PerformanceHistory[];
        const orderedPerformanceHistories = performanceHistories.sort((a, b) => b.tournamentStartDate.getTime() - a.tournamentStartDate.getTime())
        setTeamPerformanceHistories(orderedPerformanceHistories);
      })
    }
    return unsubscribe;
  }

  const getTeamTrophies = () => {
    let unsubscribe: () => void  = () => false;
    if (team) {
      const trophiesCollection = collection(firestore, 'tournamentTrophies').withConverter(tournamentTrophyConverter);
      const q = query(trophiesCollection, where('team', '==', team.id));
      unsubscribe = onSnapshot(q, (snapshots) => {
        const trophies = snapshots.docs.map((doc) => doc.data());
        const orderTrophies = trophies.sort((a, b) => b.dateRecieved.getTime() - a.dateRecieved.getTime()).sort((a, b) => a.position - b.position);
        setTeamTrophies(orderTrophies);
      })
    }
    return unsubscribe;
  }

  const checkIfEditor = useCallback(() => {
    // check if user authorized to edit (captain/manager) and set editor context state
    if (userObj && team && (userObj.uid === team.manager || userObj.uid === team.captain)) {
      setEditor(true);
    } else {
      setEditor(false);
    }
  }, [team, userObj])

  const checkIfMember = useCallback(() => {
    if (userObj && team && (team.players.includes(userObj.uid))) {
      setMember(true);
    } else {
      setMember(false);
    }
  }, [userObj, team])

  useEffect(() => {
    checkIfMember();
    checkIfEditor();
  }, [team, userObj, checkIfMember, checkIfEditor])

  useEffect(() => {
    handleTeamChange(team); // announce team change to myteam (which otherwise isnt in contenxt provider level)
    const unsubscribe1 = getTeamTrophies();

    const unsubscribe2 = getTeamPerformanceHistories();

    return () => [unsubscribe1, unsubscribe2].forEach((unsub) => unsub());
  }, [team, handleTeamChange])

  useEffect(() => {
    handleLoadingChange(loading);
  }, [loading])

  useEffect(() => {
    getTeam()
    return () => {
      setTeam(null);
    }
  }, [userObj, getTeam, params.id_or_name])

  const contextValue = {
    team: team,
    teamTrophies: teamTrophies,
    teamPerformanceHistories: teamPerformanceHistories,
    setTeam: setTeam,
    editor: editor,
    member: member
  }

  return (
    <TeamShowContext.Provider value={contextValue}>
      {children}
    </TeamShowContext.Provider>
  )
}

export default TeamShowProvider;
