import React, {useState, createContext, useContext, ReactNode, useEffect, useCallback} from 'react'
// firebase
import { firestore, storage } from "../../firebase";
import { DocumentReference, doc, updateDoc, addDoc, getDoc, collection, arrayUnion, arrayRemove } from "firebase/firestore";
import { deleteObject, StorageReference, getDownloadURL, ref, uploadBytes } from "firebase/storage";
// types
import { DBUser, userConverter } from '../../firestore/users';
import { DBTeam, teamConverter } from '../../firestore/teams';
import { FileWithPreview, ExistingUploadAsFile } from "./create/UploadLogo";
// libraries
import { toast } from 'react-toastify';
// utility
import { resizeImage } from "../../utils/ResizeImage";
import { useAuthContext } from "../../provider/AuthContextProvider";
import { TeamShowContext } from "./TeamShowProvider";
import { TeamEditModalStep } from './types';

// ------ Context Provider for Team Form fields through all team (create/edit) modal steps  --------

// not Team DB type - local state for team form info persistence across steps

type ToastText = {
  pending: string,
  success: string,
  error: string,
}

type TeamInfo = {
  id: string,
  originalTeamName?: string, // for edit state, stores the original team name so we dont check for uniquness if it doesnt change
  tournamentsInPlay: string[],
  teamName: string,
  paypalLink: string,
  teamTag: string,
  teamBio: string,
  mainGame: string,
  region: string,
  discordLink: string,
  twitchLink: string,
  twitterLink: string,
  websiteLink: string,
  logo: File | null,
  banner: File | null,
  logoPreviewUrl: string,
  bannerPreviewUrl: string,
  logoUrl?: string,
  bannerUrl?: string,
  sponsorLogos: (FileWithPreview | ExistingUploadAsFile)[],
  sponsorLogoUrls: string[],
  players: DBUser[],
  pendingPlayers: DBUser[],
  originalPendingPlayerUids: string[],
  createdAt?: Date,
}

const defaultTeamInfo = {
  id: '',
  paypalLink: '',
  tournamentsInPlay: [],
  teamName: '',
  teamTag: '',
  teamBio: '',
  region: '',
  discordLink: '',
  twitchLink: '',
  twitterLink: '',
  websiteLink: '',
  mainGame: '',
  players: [],
  pendingPlayers: [],
  originalPendingPlayerUids: [],
  logo: null,
  banner: null,
  sponsorLogos: [],
  sponsorLogoUrls: [],
  logoPreviewUrl: '',
  bannerPreviewUrl: '',
}

interface ITeamInfoContext {
  teamInfo: TeamInfo,
  setTeamInfo: (teamInfo: TeamInfo) => void,
  submitTeam: () => Promise<DocumentReference | boolean>,
  updateTeam: (toastText?: ToastText) => Promise<boolean>,
  edit: boolean,
  cancelPlayerInvite: (uid: string, name: string) => void,
  removePlayer: (uid: string, name: string) => void,
  invitePlayer: (uid: string, name: string) => void,
  leaveTeam: () => void,
  teamMember: boolean,
  currentEditModalStep: TeamEditModalStep | -1,
  setCurrentEditModalStep: (step: TeamEditModalStep | -1) => void,
  changeModalStep: (direction: -1 | 1) => void,
  closeModal: () => void,
}

const defaultTeamInfoContext = {
  teamInfo: defaultTeamInfo,
  setTeamInfo: (teamInfo: TeamInfo) => { return teamInfo},
  submitTeam: () => new Promise<DocumentReference | boolean>((res) => res(false)),
  updateTeam: (toastText?: ToastText) => new Promise<boolean>((res) => {
    toastText;
    res(false);
  }),
  edit: false,
  cancelPlayerInvite: (uid: string, name: string) => {
    [uid, name];
  },
  removePlayer: (uid: string, name: string) => {
    [uid, name];
  },
  invitePlayer: (uid: string, name: string) => {
    [uid, name];
  },
  leaveTeam: () => false,
  teamMember: false,
  currentEditModalStep: -1,
  setCurrentEditModalStep: (step: TeamEditModalStep | -1) => step,
  changeModalStep: (direction: -1 | 1) => direction,
  closeModal: () => false,
};

// context serving local team form info across whole flow
export const TeamInfoContext = createContext<ITeamInfoContext>(defaultTeamInfoContext);

export const useTeamInfoContext = () => {
  return useContext(TeamInfoContext);
}

interface ITeamInfoProvider {
  children: ReactNode,
  team?: DBTeam | null
}

const TeamInfoProvider: React.FC<ITeamInfoProvider> = ({children, team}) => {
  const { user } = useAuthContext();
  const { editor } = useContext(TeamShowContext);

  const [teamInfo, setTeamInfo] = useState<TeamInfo>(defaultTeamInfo);
  const [teamMember, setTeamMember] = useState<boolean>(false);

  const [currentEditModalStep, setCurrentEditModalStep] = useState<TeamEditModalStep | -1>(-1);

  const changeModalStep = (direction: -1 | 1) => {
    let nextModalStep = currentEditModalStep + direction;

    if (nextModalStep < 0) {
      nextModalStep = 0;
    } else if (nextModalStep > 4) {
      nextModalStep = 4
    }

    setCurrentEditModalStep(nextModalStep);
  }

  const closeModal = () => {
    setCurrentEditModalStep(-1);
  }

  useEffect(() => {
    if (user && teamInfo) {
      const inTeam = teamInfo.players.find((player) => player.uid === user.uid) !== undefined;
      setTeamMember(inTeam);
    }
  }, [teamInfo, user])

  const loadExistingSponsorLogos = (sponsorLogoUrls: string[]) => {
    const existingSponsorLogos = sponsorLogoUrls.map((url) => {
      const urlParts = url.split('%2F');
      const urlFileNameParts = urlParts[urlParts.length - 1].split('?');
      const urlFileName = urlFileNameParts[0];
      return {
        name: urlFileName,
        preview: url,
        existing: true,
        toBeRemoved: false,
      };
    })
    return existingSponsorLogos;
  }

  const leaveTeam = async () => {
    if (user && teamInfo) {
      try {
        const teamRef = doc(firestore, 'teams', teamInfo.id);
        const userRef = doc(firestore, 'users', user.uid);
        const teamPromise = updateDoc(teamRef, {
          players: arrayRemove(user.uid)
        })
        const userPromise = updateDoc(userRef, {
          team: ''
        })
        const leavePromise = Promise.all([teamPromise, userPromise]);
        toast.promise(leavePromise, {
          pending: 'Leaving team',
          success: 'Team left',
          error: 'Error leaving team'
        })
      } catch (err) {
        console.error(err);
        toast.error('Error leaving team')
      }
    }
  }

  const loadTeamInfoFromDBTeam = useCallback(async () => {
    if (team) {
      const teamPlayers = await team.getPlayerObjs!();
      const teamPendingPlayers = await team.getPendingPlayerObjs!();
      const teamInfo: TeamInfo = {
        id: team.id,
        originalTeamName: team.teamName,
        tournamentsInPlay: team.tournamentsInPlay,
        teamName: team.teamName,
        paypalLink: team.paypalLink,
        teamTag: team.teamTag,
        teamBio: team.teamBio,
        mainGame: team.mainGame,
        region: team.region,
        discordLink: team.discordLink,
        twitchLink: team.twitchLink,
        twitterLink: team.twitterLink,
        websiteLink: team.websiteLink,
        logo: null,
        banner: null,
        sponsorLogos: loadExistingSponsorLogos(team.sponsorLogoUrls),
        sponsorLogoUrls: team.sponsorLogoUrls,
        players: teamPlayers,
        pendingPlayers: teamPendingPlayers,
        originalPendingPlayerUids: team.pendingPlayers,
        logoPreviewUrl: team.logoUrl,
        logoUrl: team.logoUrl,
        bannerUrl: team.bannerUrl,
        bannerPreviewUrl: team.bannerUrl,
        createdAt: team.createdAt,
      }
      setTeamInfo(teamInfo);
    }
  }, [team]);

  // function to delete given storageRefs in the case of a creation failure after some items have been uploaded
  const deletePartialUploads = async (uploadedImageRefs: StorageReference[]) => {
    uploadedImageRefs.forEach((itemRef) => {
      deleteObject(itemRef).catch((error) => {
        console.error('Error deleting item:', error);
      });
    });
  }

  const cancelPlayerInvite = async (uid: string, name: string) => {
    // remove invite from user
    const userRef = doc(firestore, 'users', uid).withConverter(userConverter);
    const userObj = (await getDoc(userRef)).data()!;
    const filteredTeamRequests = userObj.teamRequests.filter((request) => (request as {teamId: string, timeReceived: Date} | string) !== teamInfo.id! && request.teamId !== teamInfo.id)
    const userPromise = updateDoc(userRef, {
      teamRequests: filteredTeamRequests,
    });
    // remove invite from team
    const teamRef = doc(firestore, 'teams', teamInfo.id);
    const teamPromise = updateDoc(teamRef, {
        pendingPlayers: arrayRemove(uid),
    });

    const combinedPromise = Promise.all([userPromise, teamPromise]);
    toast.promise(combinedPromise, {
      pending: `Cancelling invite to ${name}`,
      success: `Invite to ${name} cancelled`,
      error: `We're sorry, there was an issue cancelling this invite, if the issue persists please contact support`,
    })
  }

  const invitePlayer = async (uid: string, name: string) => {
    // remove invite from user
    const userRef = doc(firestore, 'users', uid).withConverter(userConverter);
    const newTeamRequest = {
      teamId: teamInfo.id,
      timeReceived: new Date(),
    }

    const userPromise = updateDoc(userRef, {
      teamRequests: arrayUnion(newTeamRequest),
    });
    // remove invite from team
    const teamRef = doc(firestore, 'teams', teamInfo.id);
    const teamPromise = updateDoc(teamRef, {
        pendingPlayers: arrayUnion(uid),
    });

    const combinedPromise = Promise.all([userPromise, teamPromise]);
    toast.promise(combinedPromise, {
      pending: `Inviting ${name}`,
      success: `Invite sent to ${name}`,
      error: `We're sorry, there was an issue inviting this player, if the issue persists please contact support`,
    })
  }

  const removePlayer = async (uid: string, name: string) => {
    // remove team from user
    const userRef = doc(firestore, 'users', uid);
    const userPromise = updateDoc(userRef, {
      team: "",
    });
    // remove player from team
    const teamRef = doc(firestore, 'teams', teamInfo.id);
    const teamPromise = updateDoc(teamRef, {
        players: arrayRemove(uid),
    });

    const combinedPromise = Promise.all([userPromise, teamPromise]);
    toast.promise(combinedPromise, {
      pending: `Removing ${name} from team`,
      success: `${name} removed from team`,
      error: `We're sorry, there was an issue removed player from team, if the issue persists please contact support`,
    })
  }

  const submitTeam = async (): Promise<DocumentReference | boolean> => {
    if (user) {
      // upload images
      const logo = teamInfo.logo as File;
      const banner = teamInfo.banner as File;

      let logoUrl = '';
      let bannerUrl = '';

      // storage item refs in global function scope so they can be used for deletion if form doesnt finish submitting
      const uploadedImageRefs: StorageReference[] = [];

      if (logo) {
        const logoResized = await resizeImage(logo, {width: 500});
        if (logoResized) {
          const logoStorageRef = ref(storage, `teams/${teamInfo.teamName}/logo.webp`);
          await uploadBytes(logoStorageRef, logoResized).then(async (snapshot) => {
            logoUrl = await getDownloadURL(snapshot.ref);
            uploadedImageRefs.push(snapshot.ref);
          });
        } else {
          console.error('error uploading logo');
          deletePartialUploads(uploadedImageRefs);
          return false;
        }
      }

      if (banner) {
        const bannerResized = await resizeImage(banner, {width: 1000});
        if (bannerResized) {
          const bannerStorageRef = ref(storage, `teams/${teamInfo.teamName}/banner.webp`);
          await uploadBytes(bannerStorageRef, bannerResized).then( async (snapshot) => {
            bannerUrl = await getDownloadURL(snapshot.ref);
            uploadedImageRefs.push(snapshot.ref);
          });
        } else {
          console.error('error uploading banner');
          deletePartialUploads(uploadedImageRefs);
          return false;
        }
      }

      const sponsorLogoUploads = teamInfo.sponsorLogos.map(async (file) => {
        const sponsorLogoResized = await resizeImage(file as FileWithPreview, {width: 500});
        if (sponsorLogoResized) {
          const sponsorLogoStorageRef = ref(storage, `teams/${teamInfo.teamName}/${file.name.split('.')[0]}.webp`);
          const snapshot = await uploadBytes(sponsorLogoStorageRef, sponsorLogoResized as Blob);
          const sponsorLogoUrl = await getDownloadURL(snapshot.ref);
          uploadedImageRefs.push(snapshot.ref);
          return sponsorLogoUrl;
        } else {
          throw new Error('error uploading sponsor logo');
        }
      });

      let sponsorLogoUrls: string[] = [];

      try {
        sponsorLogoUrls = await Promise.all(sponsorLogoUploads);
      } catch (error){
        console.error(error);
        deletePartialUploads(uploadedImageRefs);
        return false;
      }
      // generate Team Doc
      const formatLink = (link: string) => {
        if (link) {
          const externalLink = link.startsWith('https://') || link.startsWith('http://');
          if (externalLink) {
            return link;
          } else {
            return `https://${link}`;
          }
        } else {
          return '';
        }
      }
      const rawTeam = {
        teamName: teamInfo.teamName.trim(),
        paypalLink: teamInfo.paypalLink,
        teamTag: teamInfo.teamTag.toUpperCase(),
        teamBio: teamInfo.teamBio,
        region: teamInfo.region,
        discordLink: formatLink(teamInfo.discordLink),
        twitterLink: formatLink(teamInfo.discordLink),
        twitchLink: formatLink(teamInfo.discordLink),
        websiteLink: formatLink(teamInfo.discordLink),
        captain: user.uid,
        mainGame: teamInfo.mainGame,
        players: [user.uid],
        pendingPlayers: teamInfo.pendingPlayers.map((player) => player.uid),
        logoUrl: logoUrl,
        bannerUrl: bannerUrl,
        sponsorLogoUrls: sponsorLogoUrls,
        createdAt: new Date(),
      };

      const team = teamConverter.toFirestore(rawTeam as unknown as DBTeam);

      try {
        const teamsCollection = collection(firestore, 'teams').withConverter(teamConverter);
        const teamPromise = addDoc(teamsCollection, team);

        const teamSnapshot = await teamPromise;

        const teamIdPromise = updateDoc(doc(firestore, 'teams', teamSnapshot.id), {
          id: teamSnapshot.id,
        })

        // update captain user object to reflect newly created + joined team
        const userRef = doc(firestore, 'users', user.uid);
        await updateDoc(userRef, {team: teamSnapshot.id});
        // add team id to all pending player teamRequests array
        const pendingRequestsAdditions = team.pendingPlayers.map(async (uid: string) => {
          const userRef = doc(firestore, 'users', uid).withConverter(userConverter);
          // get said users data so we can check if their invite ahs already been added
          const userSnapshot = await getDoc(userRef);
          const userData = userSnapshot.data();
          if (userData && userData.teamRequests.find((request) => request.teamId === teamSnapshot.id) === undefined) {
            const formedTeamInvite = {
              teamId: teamSnapshot.id,
              timeReceived: new Date()
            }
            await updateDoc(userRef, {
                teamRequests: arrayUnion(formedTeamInvite),
            });
          }
        });

        const combinedPromise = Promise.all([
          teamPromise,
          teamIdPromise,
          pendingRequestsAdditions
        ]);

        toast.promise(combinedPromise,
          {
            pending: 'Creating team',
            success: 'Team created',
            error: 'Error creating team',
          }
        );

        await combinedPromise;
        return teamSnapshot;
      } catch (error) {
        console.error('Error creating team', error);
      }
    } else {
      console.error('Error creating team: not logged in');
    }
    return false;
  }

  const updateTeam = async (toastText?: ToastText ): Promise<boolean> => {
    if (editor) {
      const uploadedImageRefs: StorageReference[] = [];

      // initially set the logo and banner url to their original values
      let logoUrl = teamInfo.logoPreviewUrl;
      let bannerUrl = teamInfo.bannerPreviewUrl;

      if (teamInfo.logo) {
        // upload new logo and update logoUrl
        const logo = teamInfo.logo;
        const logoResized = await resizeImage(logo, {width: 500});
        if (logoResized) {
          const logoStorageRef = ref(storage, `teams/${teamInfo.teamName}/logo.webp`);
          await uploadBytes(logoStorageRef, logoResized).then(async (snapshot) => {
            logoUrl = await getDownloadURL(snapshot.ref);
            uploadedImageRefs.push(snapshot.ref);
          });
        } else {
          console.error('error uploading logo');
          deletePartialUploads(uploadedImageRefs);
          return false;
        }
      }

      if (teamInfo.banner) {
        // upload new banner and update bannerUrl
        const banner = teamInfo.banner;
        const bannerResized = await resizeImage(banner, {width: 1000});
        if (bannerResized) {
          const bannerStorageRef = ref(storage, `teams/${teamInfo.teamName}/banner.webp`);
          await uploadBytes(bannerStorageRef, bannerResized).then( async (snapshot) => {
            bannerUrl = await getDownloadURL(snapshot.ref);
            uploadedImageRefs.push(snapshot.ref);
          });
        } else {
          console.error('error uploading banner');
          deletePartialUploads(uploadedImageRefs);
          return false;
        }
      }

      // set sponsorLogoUrls initially to the existing logos that have not been tagged for removal
      let sponsorLogoUrls = teamInfo.sponsorLogos.filter((logo) => logo.existing && !logo.toBeRemoved).map((logo) => logo.preview);

      const sponsorLogoToBeRemoved = teamInfo.sponsorLogos.filter((logo) => logo.toBeRemoved);

      const deleteSponsorLogoUrls = async () => {
        try {
          for (const logo of sponsorLogoToBeRemoved) {
            const storageRef = ref(storage, logo.preview);
            await deleteObject(storageRef);
          }
        } catch (error) {
          console.error('Error deleting sponsor logo/s:', error);
        }
      };

      deleteSponsorLogoUrls();

      const newSponsorLogos = teamInfo.sponsorLogos.filter((logo) => !logo.existing);
      if (newSponsorLogos.length > 0) {
        const newSponsorLogoUploads = newSponsorLogos.map(async (file) => {
          const sponsorLogoResized = await resizeImage(file as FileWithPreview, {width: 500});
          if (sponsorLogoResized) {
            const sponsorLogoStorageRef = ref(storage, `teams/${teamInfo.teamName}/${file.name.split('.')[0]}.webp`);
            const snapshot = await uploadBytes(sponsorLogoStorageRef, sponsorLogoResized as Blob);
            const sponsorLogoUrl = await getDownloadURL(snapshot.ref);
            uploadedImageRefs.push(snapshot.ref);
            return sponsorLogoUrl;
          } else {
            throw new Error('error uploading sponsor logo');
          }
        });

        try {
          const newSponsorLogoUrls = await Promise.all(newSponsorLogoUploads);
          sponsorLogoUrls = [...sponsorLogoUrls, ...newSponsorLogoUrls];
        } catch (error){
          console.error(error);
          deletePartialUploads(uploadedImageRefs);
          return false;
        }
      }

      const formatLink = (link: string) => {
        if (link) {
          const externalLink = link.startsWith('https://') || link.startsWith('http://');
          if (externalLink) {
            return link;
          } else {
            return `https://${link}`;
          }
        } else {
          return '';
        }
      }

      const editedTeam = {
        teamName: teamInfo.teamName.trim(),
        teamTag: teamInfo.teamTag,
        teamBio: teamInfo.teamBio,
        region: teamInfo.region,
        paypalLink: teamInfo.paypalLink,
        discordLink: formatLink(teamInfo.discordLink),
        twitterLink: formatLink(teamInfo.twitterLink),
        twitchLink: formatLink(teamInfo.twitchLink),
        websiteLink: formatLink(teamInfo.websiteLink),
        mainGame: teamInfo.mainGame,
        players: teamInfo.players.map((player) => player.uid),
        pendingPlayers: teamInfo.pendingPlayers.map((player) => player.uid),
        logoUrl: logoUrl,
        bannerUrl: bannerUrl,
        sponsorLogoUrls: sponsorLogoUrls,
        createdAt: teamInfo.createdAt as Date,
      }

      try {
        const teamRef = doc(firestore, 'teams', teamInfo.id).withConverter(teamConverter);
        const teamPromise = updateDoc(teamRef, editedTeam);

        // on promise fulfillment send to toast success prompt / else error
        toast.promise(
          teamPromise,
          toastText ? toastText :
          {
            pending: 'Updating team',
            success: 'Team updated',
            error: 'Error updating team',
          }
        )

        await teamPromise;

        const newInvitationUids = editedTeam.pendingPlayers.filter((uid: string) => !teamInfo.originalPendingPlayerUids.includes(uid));
        const newInvitations = newInvitationUids.map(async (uid: string) => {
          const userRef = doc(firestore, 'users', uid).withConverter(userConverter);
          // get said users data so we can check if their invite ahs already been added
          const userSnapshot = await getDoc(userRef);
          const userData = userSnapshot.data();
          if (userData && userData.teamRequests.find((request) => request.teamId === teamInfo.id!) === undefined) {
            const formedTeamInvite = {
              teamId: teamInfo.id!,
              timeReceived: new Date()
            }
            await updateDoc(userRef, {
                teamRequests: arrayUnion(formedTeamInvite),
            });
          }
        });
        // run the user teamRequest addition operations
        await Promise.all(newInvitations);

        const removeInvitationUids = teamInfo.originalPendingPlayerUids.filter((uid: string) => !editedTeam.pendingPlayers.includes(uid));
        const removeInvitations = removeInvitationUids.map(async (uid: string) => {
          const userRef = doc(firestore, 'users', uid);
          // get said users data so we can check if their invite ahs already been added
          const userSnapshot = await getDoc(userRef);
          const userData = userSnapshot.data();
          if (userData && userData.teamRequests.includes(teamInfo.id)) {
            await updateDoc(userRef, {
                teamRequests: arrayRemove(teamInfo.id),
            });
          }
        });
        // run the user teamRequest removal operations
        await Promise.all(removeInvitations);

        return true;
      } catch (error) {
        console.error('Error editing team', error)
      }
    } else {
      console.error('Error updating team: not logged in');
    }
    return false;
  }

  useEffect(() => {
    loadTeamInfoFromDBTeam();
  }, [team, loadTeamInfoFromDBTeam]);

  const contextValue = {
    teamInfo: teamInfo,
    setTeamInfo: setTeamInfo,
    submitTeam: submitTeam,
    updateTeam: updateTeam,
    edit: team ? true : false,
    cancelPlayerInvite: cancelPlayerInvite,
    removePlayer: removePlayer,
    invitePlayer: invitePlayer,
    leaveTeam: leaveTeam,
    teamMember: teamMember,
    currentEditModalStep,
    setCurrentEditModalStep,
    changeModalStep,
    closeModal
  }

  return (
    <TeamInfoContext.Provider value={contextValue}>
      {children}
    </TeamInfoContext.Provider>
  );
}

export default TeamInfoProvider;
