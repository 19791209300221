import { Link } from "react-router-dom";
// components
import { SkeletonDark, SkeletonLight } from '@common/skeleton-loader/Skeletons';
// assetts
import positionLineImage from "@assets/images/png/find-team-position-line.png";
import findTeamImage from "@assets/images/webp/find-team-3-persons.webp";
// icons
import { DiscordGreenIcon, BannerLine } from "@icons/creators/home";
import { BottomBannerIcon } from "@icons/Common";

interface IFindTeam {
  loaded?: boolean,
  className?: string,
}

const FindTeam: React.FC<IFindTeam> = ({className, loaded}) => {
  return loaded !== false ? (
    <div className={`${className} mt-12 mb-[6rem]`}>
      <div className="bg-green p-4 lg:pb-0 pe-4 sm:pr-10 pt-5 xl:pt-5 rounded-xl relative overflow-hidden">
        <span className="hidden sm:block absolute bottom-2 right-2 z-0 find_team_triangle">
          <BottomBannerIcon />
        </span>
        <img
          className="absolute hidden lg:block top-16 lg:top-12 xl:top-4 2xl:top-8 min-[106.25rem]:top-5 z-0 -end-14 2xl:-end-24 xl:h-[7.6rem] min-[106.25rem]:-end-48 md:-end-5 lg:-end-2"
          src={positionLineImage}
          alt="position Line Image"
        />
        <span className="absolute left-0 lg:!left-[60%] lg:hidden top-4 sm:top-16 md:top-28 z-0  ">
          <BannerLine />
        </span>
        <div className="flex flex-wrap justify-center lg:justify-between items-center z-10 relative">
          <div className="sm:w-8/12 lg:w-3/12">
            <img
              className="max-w-[18.5rem] sm:max-w-full xl:max-w-[18.5rem] 2xl:max-w-[19.375rem] custom_3xl:max-w-[21.875rem]"
              src={findTeamImage}
              alt="find team image"
            />
          </div>
          <div className="w-full md:w-7/12 lg:w-5/12 xl:w-6/12 pt-6 md:py-6 lg:py-7">
            <div className="lg:ps-8 min-[87.5rem]:ps-0">
              <h2 className="font-wide font-bold text-2xl sm:text-3xl xl:text-4xl uppercase text-black mb-0">
                Find a team now!
              </h2>
              <p className="font-compact font-normal text-sm !tracking-[0.009rem] mb-0 mt-1 xl:mt-3 max-w-[22rem] sm:max-w-none xl:max-w-[38rem] opacity-60 text-GreenWaterloo me-auto sm:mx-auto lg:ms-0">
                Connect and compete with others in Versus tournaments and leagues. Join forces, and start your journey to glory.
              </p>
            </div>
          </div>
          <div className="w-full md:w-5/12 lg:w-4/12 xl:w-3/12 md:text-end">
            <div className="inline-block mt-4 md:mt-0">
              <Link
                to={"https://discord.com/invite/versus-gg"}
                target="_blank"
                className="bg-lightGray font-wide font-medium text-sm sm:text-base uppercase text-white flex items-center gap-2 py-3 sm:py-4 px-5 sm:px-6 rounded-xl tracking-[0.01rem] duration-500 hover:opacity-90"
              >
                <DiscordGreenIcon />
                <span className="-mb-[0.188rem]">Join our discord</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={`${className} mt-12 mb-[6rem]`}>
      <SkeletonDark className='flex items-center justify-between w-full h-[150px] rounded-xl p-3 xl:p-6'>
        <div className='w-[70%] h-full flex justify-between gap-x-2'>
          <SkeletonLight className='w-[40%] h-full rounded-lg'/>
          <div className="w-[50%]">
            <SkeletonLight className='w-[80%] h-[30px] rounded-lg mt-2'/>
            <SkeletonLight className='w-[90%] h-[25px] rounded-lg mt-2'/>
            <SkeletonLight className='w-[90%] h-[25px] rounded-lg mt-2'/>
          </div>

        </div>

        <SkeletonLight className='w-[15%] h-[35px] rounded-lg'/>
      </SkeletonDark>
    </div>
  );
};

export default FindTeam;
