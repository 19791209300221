// context
import { useTournamentContext } from "../TournamentProvider";
// utils
import { platformToString } from '@customTypes/Platforms';
import { formatDateTime, getTimezone } from "../../../utils/Date";
// icons
import { TournamentTrophyIcon, GamePlatformIcon, GameModeIcon } from "../../common/icons/TournamentIcons";
import { SlidersIcon } from "@components/common/icons/Common";


const Summary = () => {
  const { tournament } = useTournamentContext();

  return tournament ? (
    <div className="px-4 sm:px-8 lg:px-12 pt-10">
      <h2 className="font-wide font-bold text-2xl !leading-6 uppercase text-white">
        Summary
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 my-6">
        <div className="py-3 px-3 custom_sm:px-4 border-[0.063rem] border-lightGray rounded-lg h-full flex flex-col justify-between duration-200 hover:shadow-card">
          <span><TournamentTrophyIcon /></span>
          <div>
            <p className="font-compact font-normal text-xs custom_sm:text-sm !leading-4 text-slateGray !tracking-[0.009rem] my-2">
              Tournament starts
            </p>
            <h2 className="font-compact font-medium text-sm custom_xsm:!text-base !leading-5 !tracking-[0.01rem] uppercase text-white ">
              <span>
                {formatDateTime(tournament.statusDates.ongoing)}  {getTimezone()}
              </span>
            </h2>
          </div>
        </div>

        <div className="py-3 px-3 custom_sm:px-4 border-[0.063rem] border-lightGray rounded-lg h-full flex flex-col justify-between duration-200 hover:shadow-card">
          <span><GamePlatformIcon /></span>
          <div>
            <p className="font-compact font-normal text-xs custom_sm:text-sm !leading-4 text-slateGray !tracking-[0.009rem] my-2">
            Platform
            </p>
            <h2 className="font-compact font-medium text-sm custom_xsm:!text-base !leading-5 !tracking-[0.01rem] uppercase text-white max-w-[9.375rem]">
              {platformToString(tournament.platform)}
            </h2>
          </div>
        </div>

        <div className="py-3 px-3 custom_sm:px-4 border-[0.063rem] border-lightGray rounded-lg h-full flex flex-col justify-between duration-200 hover:shadow-card">
          <span><GameModeIcon /></span>
          <div>
            <p className="font-compact font-normal text-xs custom_sm:text-sm !leading-4 text-slateGray !tracking-[0.009rem] my-2">
              Game mode
            </p>
            <h2 className="font-compact font-medium text-sm custom_xsm:!text-base !leading-5 !tracking-[0.01rem] uppercase text-white max-w-[9.375rem] whitespace-nowrap">
              {tournament.gameMode}
            </h2>
          </div>
        </div>

        <div className="py-3 px-3 custom_sm:px-4 border-[0.063rem] border-lightGray rounded-lg h-full flex flex-col justify-between duration-200 hover:shadow-card">
          <SlidersIcon className="w-[32px] h-auto aspect-square fill-steelGray stroke-steelGray"/>
          <div>
            <p className="font-compact font-normal text-xs custom_sm:text-sm !leading-4 text-slateGray !tracking-[0.009rem] my-2">
              Format
            </p>
            <h2 className="font-compact font-medium text-sm custom_xsm:!text-base !leading-5 !tracking-[0.01rem] uppercase text-white max-w-[9.375rem] whitespace-nowrap">
              {tournament.format}
            </h2>
          </div>
        </div>
      </div>
      <p className="font-compact font-normal text-base !leading-8 text-slateGray !tracking-[0.01rem]">
        {tournament.summary}
      </p>
    </div>
  ): '';
};

export default Summary;
