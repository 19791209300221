import { ReactNode } from "react";

interface IErrorText {
  children: ReactNode,
  className?: string,
}

const ErrorText: React.FC<IErrorText> = ({children, className}) => {
  return (
    <p className={`${className} text-red font-compact text-sm my-2`}>
      {children}
    </p>
  )
}

export default ErrorText;
