import { useEffect, useState } from 'react'
// types
import { TournamentStage } from "../../../../firestore/tournaments";
// components
import GroupCodes from './GroupCodes';
import { useTournamentContext } from '../../TournamentProvider';

interface ICodes {
  stage: TournamentStage,
}

const StageCodes: React.FC<ICodes> = ({stage}) => {
  const [currentGroup, setCurrentGroup] = useState<string>(''); // either the group's id, or strings: 'unassigned'  | 'declined' | 'ignored'
  const [groupsLoaded, setGroupsLoaded] = useState<boolean>(false);

  const { tournament } = useTournamentContext();
  const finalStage = tournament !== null && (stage.stageNum === tournament.totalStages - 1);

  useEffect(() => {
    if (!groupsLoaded && stage.groups.length > 0) {
      setCurrentGroup(stage.groups[0].id);
      setGroupsLoaded(true);
    }
  }, [stage.groups])

  return (
    <div>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        {finalStage ? '' : (
          <div className='flex items-center gap-x-2 w-full'>
            <div className='flex items-center gap-x-2'>
              {stage.groups.map((group) => (
                <button key={`codes-stage-group-${group.id}`} type="button"
                        onClick={() => setCurrentGroup(group.id)}
                        className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                    ${currentGroup === group.id ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
                  {group.groupName}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className={`flex ${finalStage ? '' : 'mt-6'}`}>
        <div className='w-full lg:w-1/2'>
          <div>
            {stage.groups.map((group) => (
              <div key={`codes-stage-view-${group.id}`}>
              {currentGroup === group.id ? (
                <GroupCodes key={group.id} stageNum={stage.stageNum} group={group}/>
              ) : ''}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StageCodes;
