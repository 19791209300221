// context
import { useTeamInfoContext } from "@components/team/TeamInfoProvider";
// types
import { TeamEditModalStep } from "@components/team/types";
// components
import Modal from "@ui/Modal";
import SearchTeamMate from "./SearchTeamMate";
// icons
import { FaCheck } from "react-icons/fa";

const InviteStep = () => {
  const { currentEditModalStep, setCurrentEditModalStep, closeModal } = useTeamInfoContext();

  return (
    <Modal title='Edit Sponsors'
          scroll={true}
          buttonText={(
            <div className="flex items-center gap-x-2">
              <p>Done</p>
              <FaCheck className="-translate-y-[1px]"/>
            </div>
          )}
          buttonOnClick={closeModal}
          open={currentEditModalStep === TeamEditModalStep.invite}
          setOpen={(open: boolean) => {
            setCurrentEditModalStep(open ? TeamEditModalStep.invite : -1);
          }}>
      <SearchTeamMate/>
    </Modal>
  );
};

export default InviteStep;
