import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
import { gamesAsDropDownOptions, gameModeOptionsByGame, gameFormatOptionsByGame } from '@customTypes/Games/Games';
import { platformsAsDropDownOptions } from '@customTypes/Platforms';
// components
import DropDown from '@ui/DropDown';
import Modal from "@ui/Modal";
// icons
import { BlackArrow } from '@icons/Common';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  stepNames: string[]
}

const GameStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, stepNames}) => {
  const [modalContainer, setModalContainer] = useState<HTMLDivElement | null>(null);

  const [stepValid, setStepValid] = useState<boolean>(true);

  const tournamentGameDropdownOptions = gamesAsDropDownOptions;
  const tournamentPlatformDropdownOptions = platformsAsDropDownOptions;

  useEffect(() => {
    if (tournamentInfo.game !== undefined && tournamentInfo.gameMode && tournamentInfo.platform !== undefined) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentInfo.game, tournamentInfo.gameMode, tournamentInfo.platform]);

  useEffect(() => {
    setTournamentInfo({
      ...tournamentInfo,
      format: gameFormatOptionsByGame(tournamentInfo.game)[0].label
    })
  }, []);

  return (
    <Modal title="Game"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           onContainerLoad={(container) => setModalContainer(container)}
           step={3}
           scroll={true}
           totalSteps={10}
           open={currentStep === CreationFlowPage.game}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.details)}
           buttonOnClick={() => changeStep(CreationFlowPage.format)}>
      <div className="flex flex-col gap-y-2 text-white font-compact items-start">
        <div className='flex flex-col gap-y-3 w-full'>

          <div className='flex flex-col gap-y-2 text-start items-start'>
            <p className='text-sm text-steelGray font-compact'>Game</p>
            <DropDown currentOption={tournamentGameDropdownOptions.find((option) => option.value === tournamentInfo.game) ?? tournamentGameDropdownOptions[0]}
                      options={tournamentGameDropdownOptions}
                      handleOnChange={(newValue) => {
                        const newGameValue = tournamentGameDropdownOptions.find((option) => option.value === newValue)!.value;
                        const newGameModeValue = gameModeOptionsByGame(newGameValue)[0].label;
                        setTournamentInfo({
                          ...tournamentInfo,
                          game: newGameValue,
                          gameMode: newGameModeValue,
                          format: gameFormatOptionsByGame(tournamentInfo.game)[0].label
                        })
                      }}
                      black={true}/>
          </div>

          <div className='flex flex-col gap-y-2 text-start items-start'>
            <p className='text-sm text-steelGray font-compact'>Game Mode</p>
            <DropDown currentOption={gameModeOptionsByGame(tournamentInfo.game).find((option) => option.label === tournamentInfo.gameMode) ?? gameModeOptionsByGame(tournamentInfo.game)[0]}
                      options={gameModeOptionsByGame(tournamentInfo.game)}
                      handleOnChange={(newValue) => {
                        const newGameModeValue = gameModeOptionsByGame(tournamentInfo.game).find((option) => option.value === newValue)!.label;
                        setTournamentInfo({
                          ...tournamentInfo,
                          gameMode: newGameModeValue,
                          format: gameFormatOptionsByGame(tournamentInfo.game)[0].label
                        })
                      }}
                      black={true}/>
          </div>

          <div className='flex flex-col gap-y-2 text-start items-start'>
            <p className='text-sm text-steelGray font-compact'>Platform</p>
            <DropDown currentOption={tournamentPlatformDropdownOptions.find((option) => option.value === tournamentInfo.platform) ?? tournamentPlatformDropdownOptions[0]}
                      options={tournamentPlatformDropdownOptions}
                      handleOnChange={(newValue) => {
                        const newPlatformValue = tournamentPlatformDropdownOptions.find((option) => option.value === newValue)!.value;
                        setTournamentInfo({
                          ...tournamentInfo,
                          platform: newPlatformValue
                        })
                      }}
                      black={true}
                      containerToScroll={modalContainer}/>
          </div>

        </div>
      </div>
    </Modal>
  )
}

export default GameStep;
