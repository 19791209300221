import { useState, useEffect, useRef, ReactNode } from 'react';
// icons
import { DownSelectArrow } from '@components/common/icons/Common';
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';
import { FaCheck } from 'react-icons/fa';

interface DropDownOption {
  value: number,
  label: string,
}

interface IDropDownMultiSelect {
  title: string,
  className?: string,
  icon?: ReactNode
  loaded?: boolean,
  selectedOptions: number[],
  options: DropDownOption[],
  setSelectedOptions: (options: number[]) => void,
}

const DropDownMultiSelect: React.FC<IDropDownMultiSelect> = ({title, selectedOptions, options, icon, setSelectedOptions, loaded, className}) => {
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const handleCloseDropDownOnOutsideClick = (e: MouseEvent) => {
    if (dropDownRef.current && !dropDownRef.current.contains(e.target as Node)) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => handleCloseDropDownOnOutsideClick(e);

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOnChange = (option: number) => {
    const localOptions = [...selectedOptions];
    if (localOptions.includes(option)) {
      localOptions.splice(localOptions.indexOf(option), 1);
    } else {
      localOptions.push(option);
    }
    setSelectedOptions(localOptions);
  }

  return loaded !== false ? (
    <div className={`${className} h-fit flex gap-2 md:gap-3 lg:gap-4 whitespace-nowrap bg-lightBlack rounded-xl`}>
      <div className={`relative inline-block w-full sm:min-w-[12.5rem] md:min-w-[11.25rem] lg:min-w-[14.375rem]`}
           ref={dropDownRef}>
        <div className={`relative gap-1 sm:gap-3 flex items-center justify-between pt-[0.813rem] pb-[0.688rem] px-4 duration-300 cursor-pointer`}
             onClick={() => setDropDownOpen(!dropDownOpen)}>
          {icon ? (
            <div className='w-fit h-fit absolute left-3 top-1/2 -translate-y-1/2'>
              {icon}
            </div>
          ) : ''}
          <div className="flex items-center align-middle">
            <p className={`font-compact font-normal text-xs sm:text-sm !leading-4 tracking-[0.009rem] text-white sm:ms-2 align-middle ${icon ? 'pl-6' :'' }`}>
              {title}
            </p>
          </div>
          <div className='flex items-center gap-x-2'>
            <p className={`flex items-center justify-center w-[15px] h-auto aspect-square rounded flex-shrink-0
                           text-xs font-compact font-semibold transition-colors
                         ${selectedOptions.length > 0 ? 'bg-green text-black' : 'bg-lightGray text-steelGray'}`}>
              <span className='translate-y-[1px]'>
                {selectedOptions.length}
              </span>
            </p>
            <span className={`${dropDownOpen ? "open rotate-[180deg]" : ""}`}>
              <DownSelectArrow />
            </span>
          </div>
        </div>
        {dropDownOpen && (
          <div className="absolute left-0 top-11 z-50 bg-lightBlack bg-no-repeat bg-cover w-full rounded-md">
            <div className="flex flex-col rounded-md border border-white border-opacity-[0.1] overflow-hidden w-full">
              {options.map((option, index) => {
                const selected = selectedOptions.includes(option.value);
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setDropDownOpen(false);
                      handleOnChange(option.value);
                    }}
                    className="w-full mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem]
                            hover:text-white delay-[20ms] border-b border-b-white border-opacity-5
                            flex items-center justify-between">
                    <p>
                    {option.label}
                    </p>

                    <div className={`w-[15px] h-auto aspect-square rounded border border-ebonyClay
                                        ${selected ? "bg-green" : 'bg-transparent'} transition-colors flex items-center justify-center`}>
                      <FaCheck className={`${selected ? '' : 'hidden'} text-black text-xs`}/>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <SkeletonDark className={`w-full sm:min-w-[12.5rem] md:min-w-[11.25rem] lg:min-w-[14.375rem] rounded-xl`}>
      <input type="text" className='opacity-0 pointer-events-none p-2  outline-none font-compact font-thin'/>
    </SkeletonDark>
  );
}

export default DropDownMultiSelect;
