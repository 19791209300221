import { useEffect, useState } from 'react';
// firebase
import { firestore } from '../../../firebase';
import { getDoc, doc } from 'firebase/firestore';
// types
import { DBTeam, teamConverter } from "../../../firestore/teams";
import { TournamentStatus, TournamentTeamStatus } from '../../../firestore/tournaments';
// context
import { useTournamentContext } from "../TournamentProvider";
import { useALGSModeMapContext } from '../ALGSModeMapProvider';
// components
import { LeaderboardStage } from '../LeaderboardProvider';
import LeaderboardGroup from './LeaderboardGroup';

interface ILeaderboardStage {
  stage: LeaderboardStage,
}

const LeaderboardStageV: React.FC<ILeaderboardStage> = ({stage}) => {
  const { setGroupId } = useALGSModeMapContext();
  const { tournament, tournamentTeams } = useTournamentContext();
  const [ tournamentTeamObjs, setTournamentTeamObjs] = useState<DBTeam[]>([]);

  const [groupsLoaded, setGroupsLoaded] = useState<boolean>(false);
  const finalStage = tournament !== null && stage !== null && stage.stageNum === tournament.totalStages - 1;
  const [currentGroup, setCurrentGroup] = useState<string>('');

  const stageComplete = tournament !== null && (stage.stageNum < tournament.activeStage || tournament.status === TournamentStatus.results);
  const stageGroups = stage.groups.sort((a,b) => a.groupNum - b.groupNum);

  const getTournamentTeams = async () => {
    if (tournament) {
      const teamIds = tournamentTeams.filter((team) => team.status === TournamentTeamStatus.confirmed).map((team) => team.id);

      const teamQueries = teamIds.map(async (teamId: string) => {
        const teamRef = doc(firestore, "teams", teamId).withConverter(teamConverter);
        const teamSnapshot = getDoc(teamRef);
        return (await teamSnapshot).data();
      })

      const teams = await Promise.all(teamQueries) as DBTeam[];
      setTournamentTeamObjs(teams);
    }
  }

  useEffect(() => {
    if (!groupsLoaded && stageGroups.length > 0) {
      setCurrentGroup(stageGroups[0].groupId);
      setGroupsLoaded(true);
    }
  }, [stageGroups])

  useEffect(() => {
    getTournamentTeams();
  }, [tournamentTeams]);

  return tournament ? (
    <div className="">
      <div className={`${finalStage ? 'hidden' : ''} flex items-center gap-x-2 mb-4`}>
        {stageGroups.map((group) => (
          <button key={`group-button-${group.groupId}`} type="button"
                  onClick={() => {
                    setGroupId(group.groupId);
                    setCurrentGroup(group.groupId);
                  }}
                  className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentGroup === group.groupId ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
            {group.groupName}
          </button>
        ))}
      </div>

      <div>
        {stageGroups.map((group) => (
          <div key={`group-view-${group.groupId}`}>
            {currentGroup === group.groupId ? (
              <LeaderboardGroup teams={tournamentTeamObjs} groupId={group.groupId} stageNum={stage.stageNum} stageComplete={stageComplete}/>
            ) : ''}
          </div>
        ))}
      </div>
    </div>
  ): '';
};

export default LeaderboardStageV;
