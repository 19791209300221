import { ReactNode } from "react";

interface INoticeText {
  children: ReactNode,
  className?: string,
}

const NoticeText: React.FC<INoticeText> = ({children, className}) => {
  return (
    <p className={`${className} font-wide bg-lightGray uppercase font-semibold text-steelGray w-full flex items-center justify-center rounded-lg p-3 my-2`}>
      {children}
    </p>
  )
}

export default NoticeText;
