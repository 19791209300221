import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react";
// context
import NotificationProvider from "./NotificationProvider";

interface INavbarState {
  profileMenuVisible: boolean;
  notificationMenuVisible: boolean;
  activeTournamentsVisible: boolean,
  signUpMenuVisible: boolean;
  logInMenuVisible: boolean;
  forgotPasswordMenuVisible: boolean,
}

interface IHeaderContext {
  navbarState: INavbarState,
  setNavbarState: Dispatch<SetStateAction<INavbarState>>,
}

const initialContextValue: IHeaderContext = {
  navbarState: {
    profileMenuVisible: false,
    notificationMenuVisible: false,
    activeTournamentsVisible: false,
    signUpMenuVisible: false,
    logInMenuVisible: false,
    forgotPasswordMenuVisible: false,
  },
  setNavbarState: () => {}
};

export const HeaderContext = createContext<IHeaderContext>(initialContextValue);

// eslint-disable-next-line react-refresh/only-export-components
export const useHeaderContext = (): IHeaderContext => {
  return useContext(HeaderContext);
}

interface IHeaderProvider {
  children: ReactNode;
}

export const HeaderProvider: React.FC<IHeaderProvider> = ({children}) => {
  const [navbarState, setNavbarState] = useState<INavbarState>(initialContextValue.navbarState);

  const contextValue: IHeaderContext = {
    navbarState,
    setNavbarState
  };

  return (
    <HeaderContext.Provider value={contextValue}>
      <NotificationProvider>
        {children}
      </NotificationProvider>
    </HeaderContext.Provider>
  );
};
