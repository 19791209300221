import { useState, useEffect, useRef, ReactNode } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc } from 'firebase/firestore';
// context
import { useAuthContext } from '@provider/AuthContextProvider';
// types
import { CheckInModalStep } from "../CheckInViews";
// components
import Modal from "@ui/Modal";
import CheckInPlayerSelect from "./StepViews/CheckInPlayerSelect";
import CheckInPaymentSettings from "./StepViews/CheckInPaymentSettings";
import CheckInConfirmation from "./StepViews/CheckInConfirmation";
import CheckInPoiPreferences from './StepViews/CheckInPoiPreferences';
// icons
import { FaArrowRightLong } from "react-icons/fa6";
import { MouseClickIcon } from "@icons/Common";
import { ImSpinner8 } from "react-icons/im";

interface ICheckInModalFlow {
  algsMode: boolean,
  confirmPresence: () => void,
  checkInSelectedPlayers: string[],
  setCheckInSelectedPlayers: (players: string[]) => void,
  checkInModalStep: CheckInModalStep | -1,
  setCheckInModalStep: (step: CheckInModalStep | -1) => void
}

const CheckInModalFlow: React.FC<ICheckInModalFlow> = ({
  algsMode,
  confirmPresence,
  checkInSelectedPlayers,
  setCheckInSelectedPlayers,
  checkInModalStep,
  setCheckInModalStep}) => {
  const { userTeam } = useAuthContext();
  const poiPreferencesModalRef = useRef<HTMLDivElement | null>(null);

  const [squadStepValid, setSquadStepValid] = useState<boolean>(false);
  const [paymentStepValid, setPaymentStepValid] = useState<boolean>(false);
  const [poiPreferencesStepValid, setPoiPreferencesStepValid] = useState<boolean>(false);

  const [poiPreferencesButtonText, setPoiPreferencesButtonText] = useState<ReactNode | null>(null);
  const [poiPreferencesButtonOnClick, setPoiPreferencesButtonOnClick] = useState<(() => void) | null>(null);

  const [initialPaypalLinkSet, setInitialPaypalLinkSet] = useState<boolean>(false);
  const [paypalLink, setPaypalLink] = useState<string>('');

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (userTeam && !initialPaypalLinkSet) {
      setPaypalLink(userTeam.paypalLink)
      setInitialPaypalLinkSet(true);
    }
  }, [userTeam, initialPaypalLinkSet]);

  const handleConfirmPresence = async () => {
    if (userTeam) {
      setSubmitting(true);
      if (userTeam.paypalLink !== paypalLink) {
        const teamRef = doc(firestore, 'teams', userTeam.id);
        await updateDoc(teamRef, {
          paypalLink: paypalLink
        })
      }
      setCheckInModalStep(-1);
      confirmPresence();
      setSubmitting(false);
    }
  }

  return (
    <>
      <Modal title="Squad Select"
             scroll={false}
             disableClickOff={true}
             step={1}
             totalSteps={algsMode ? 4 : 3}
             buttonDisabled={!squadStepValid}
             buttonText={(
              <div className="flex items-center gap-x-1">
                <span className="-mb-1">Squad Select</span>
                <FaArrowRightLong className="text-black"/>
              </div>
             )}
             buttonOnClick={() => {
              setCheckInModalStep(algsMode ? CheckInModalStep.poiPreferences : CheckInModalStep.paymentSettings)
             }}
             onBackClick={() => {
              setCheckInModalStep(-1);
             }}
             open={checkInModalStep === CheckInModalStep.playerSelect}
             setOpen={(open: boolean) => {
              if (!open) {
                setCheckInModalStep(-1);
              }
             }}>
        <CheckInPlayerSelect checkInSelectedPlayers={checkInSelectedPlayers}
                             setCheckInSelectedPlayers={setCheckInSelectedPlayers}
                             setStepValid={setSquadStepValid}/>
      </Modal>

      {algsMode ? (
        <Modal title="Verify poiPreferences"
              ref={poiPreferencesModalRef}
              scroll={false}
               disableClickOff={true}
              step={2}
              totalSteps={4}
              buttonDisabled={!poiPreferencesButtonOnClick && !poiPreferencesStepValid}
              buttonText={poiPreferencesButtonText ?? (
                <div className="flex items-center gap-x-1">
                  <span className="-mb-1">Verify poiPreferences</span>
                  <FaArrowRightLong className="text-black"/>
                </div>
              )}
              buttonOnClick={poiPreferencesButtonOnClick ?? (() => {
                setCheckInModalStep(CheckInModalStep.paymentSettings);
              })}
              onBackClick={() => {
                setCheckInModalStep(CheckInModalStep.playerSelect);
              }}
              open={checkInModalStep === CheckInModalStep.poiPreferences}
              setOpen={(open: boolean) => {
                if (!open) {
                  setCheckInModalStep(-1);
                }
              }}>
          <CheckInPoiPreferences setStepValid={setPoiPreferencesStepValid}
                            modalRef={poiPreferencesModalRef}
                            setPoiPreferencesButtonText={setPoiPreferencesButtonText}
                            setPoiPreferencesButtonOnClick={setPoiPreferencesButtonOnClick}/>
        </Modal>
      ) : ''}

      <Modal title="Verify Payment Info"
             scroll={false}
             disableClickOff={true}
             step={algsMode ? 3 : 2}
             totalSteps={algsMode ? 4 : 3}
             buttonDisabled={!paymentStepValid}
             buttonText={(
              <div className="flex items-center gap-x-1">
                <span className="-mb-1">Verify Payment Info</span>
                <FaArrowRightLong className="text-black"/>
              </div>
             )}
             buttonOnClick={() => {
              setCheckInModalStep(CheckInModalStep.confirmation)
             }}
             onBackClick={() => {
              setCheckInModalStep(algsMode ? CheckInModalStep.poiPreferences : CheckInModalStep.playerSelect);
             }}
             open={checkInModalStep === CheckInModalStep.paymentSettings}
             setOpen={(open: boolean) => {
              if (!open) {
                setCheckInModalStep(-1);
              }
             }}>
        <CheckInPaymentSettings paypalLink={paypalLink} setPaypalLink={setPaypalLink} setStepValid={setPaymentStepValid}/>
      </Modal>

      <Modal title="Check-In"
             scroll={false}
             disableClickOff={true}
             step={algsMode ? 4 : 3}
             totalSteps={algsMode ? 4 : 3}
             buttonDisabled={submitting}
             buttonText={(
              <div className="flex items-center gap-x-1">
                <span className="-mb-1">Check-In</span>
                {submitting ? (
                  <ImSpinner8 className='text-black animate-spin'/>
                ) : (
                  <MouseClickIcon className="w-[12px] h-auto aspect-square fill-black" />
                )}
              </div>
             )}
             buttonOnClick={() => {
              handleConfirmPresence();
             }}
             onBackClick={() => {
              setCheckInModalStep(CheckInModalStep.paymentSettings);
             }}
             open={checkInModalStep === CheckInModalStep.confirmation}
             setOpen={(open: boolean) => {
              if (!open) {
                setCheckInModalStep(-1);
              }
             }}>
        <CheckInConfirmation/>
      </Modal>
    </>
  )
}

export default CheckInModalFlow
