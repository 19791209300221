// context
import { useTeamContext } from "../../TeamShowProvider";
// types
import { TeamSubView } from "../../types";

interface ISubViewBar {
  currentSubView: TeamSubView;
  setCurrentSubView: (subView: TeamSubView) => void,
}

const SubViewBar: React.FC<ISubViewBar> = ({ currentSubView, setCurrentSubView }) => {
  const { team, editor } = useTeamContext();

  return team ? (
    <div className="w-full mt-2">
      <div className="flex items-center justify-between border-b border-lightGray w-full overflow-x-scroll overflow-y-visible">
        <div className="flex items-center">
          <button className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 relative whitespace-nowrap
                            ${currentSubView === TeamSubView.performance ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                  onClick={() => setCurrentSubView(TeamSubView.performance)}>
            Performance
          </button>
          <button className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 relative whitespace-nowrap
                            ${currentSubView === TeamSubView.members ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                  onClick={() => setCurrentSubView(TeamSubView.members)}>
            Members
          </button>
          <button className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 relative whitespace-nowrap
                            ${currentSubView === TeamSubView.transfers ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                  onClick={() => setCurrentSubView(TeamSubView.transfers)}>
            Transfers
          </button>
          <button className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 relative whitespace-nowrap
                            ${currentSubView === TeamSubView.stats ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                  onClick={() => setCurrentSubView(TeamSubView.stats)}>
            Stats
          </button>
          <button className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 relative whitespace-nowrap
                            ${currentSubView === TeamSubView.sponsors ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                  onClick={() => setCurrentSubView(TeamSubView.sponsors)}>
            Sponsors
          </button>
        </div>

        <div className="flex items-center">
          {editor ? (
            <button className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 relative whitespace-nowrap
                              ${currentSubView === TeamSubView.manage ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                    onClick={() => setCurrentSubView(TeamSubView.manage)}>
              Manage Team
            </button>
          ) : ''}
        </div>
      </div>
    </div>
  ) : '';
};

export default SubViewBar;
