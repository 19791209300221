// context
import { useNotificationContext } from '../../../provider/NotificationProvider';
// components
import MyTournamentNotif from './MyTournamentNotif';

interface IMyTournamentNotifs {
  closeMenu: () => void,
}

const MyTournamentNotifs: React.FC<IMyTournamentNotifs> = ({closeMenu}) => {
  const { activeTournaments } = useNotificationContext();

  return (
    <div className='flex-grow-1 max-h-[100%] flex flex-col gap-y-3 overflow-y-scroll maskedListVert py-[20px] px-4'>
      {activeTournaments.length > 0 ? (
        <>
          {activeTournaments.map((tournament) => (
            <MyTournamentNotif key={`upcoming-tournament-${tournament.id}`}
                                     tournament={tournament}
                                     closeMenu={closeMenu}/>
          ))}
        </>
      ) : (
        <p className='font-wide bg-lightGray uppercase font-semibold text-steelGray w-full text-center rounded-lg p-3 my-2'>
          No tournaments
        </p>
      )}
    </div>
  )
}

export default MyTournamentNotifs;
