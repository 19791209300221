// Importing the image and an icon from specific file paths
import wadeWarrenMember from "../../../assets/images/png/services/wade_warren_member.png";
import { QuotsBg } from "../icons/Services";

// Definition of an array containing objects representing League Operation services
export const LeagueOperationList = [
  {
    title: "integrate",
    description:
      "Integrate esports into products or services to maximise brand visibility and interactions.",
    className: "custom_2xl:pe-3", // Custom class name for styling purposes
  },
  {
    title: "engage",
    description:
      "Drive deeper connections through gaming to enhance brand loyalty and user retention.",
    className: "custom_2xl:pe-6", // Custom class name for styling purposes
  },
  {
    title: "convert",
    description:
      "Funnel users toward conversion points and optimise customer acquisition.",
  },
];

// Definition of an array containing objects representing Production services
export const ProductionList = [
  {
    title: "capture",
    description: "Own an event series to ensure maximum exposure & attention.",
  },
  {
    title: "impact",
    description:
      "Immerse audiences in your brand through custom graphics & animations.",
  },
  {
    title: "grow",
    description:
      "Build trust with gaming communities through events of your own.",
  },
];

// Definition of an array containing objects representing Consulting services
export const ConsultingList = [
  {
    title: "discover",
    description:
      "Gain valuable insights and practices to navigate the esports landscape effectively.",
  },
  {
    title: "leverage",
    description:
      "Leverage our network to secure licenses and partnerships that drive success.",
  },
  {
    title: "accelerate",
    description: "Identify growth opportunities, minimise costs and boost ROI.",
  },
];

// Definition of an array containing objects representing Service Cards
export const ServiceCardsList = [
  {
    image: wadeWarrenMember,
    title: "Wade Warren",
    subTitle: "Marketing Coordinator at Lorem Ipsum",
    description:
      "Exercitation est amet eu aute sint. Do pariatur adipisicing laboris culpa do ad commodo sit eiusmod excepteur anim magna quis exercitation. Sint voluptate ad aliqua occaecat. Fugiat mollit quis consequat anim occaecat cillum magna qui ullamco duis ea quis. Est occaecat proident aliquip sint sunt.",
    icon: <QuotsBg />, // Icon component for additional graphical representation
  },
  {
    image: wadeWarrenMember,
    title: "Wade Warren",
    subTitle: "Marketing Coordinator at Lorem Ipsum",
    description:
      "Exercitation est amet eu aute sint. Do pariatur adipisicing laboris culpa do ad commodo sit eiusmod excepteur anim magna quis exercitation. Sint voluptate ad aliqua occaecat. Fugiat mollit quis consequat anim occaecat cillum magna qui ullamco duis ea quis. Est occaecat proident aliquip sint sunt.",
    icon: <QuotsBg />, // Icon component for additional graphical representation
  },
  {
    image: wadeWarrenMember,
    title: "Wade Warren",
    subTitle: "Marketing Coordinator at Lorem Ipsum",
    description:
      "Exercitation est amet eu aute sint. Do pariatur adipisicing laboris culpa do ad commodo sit eiusmod excepteur anim magna quis exercitation. Sint voluptate ad aliqua occaecat. Fugiat mollit quis consequat anim occaecat cillum magna qui ullamco duis ea quis. Est occaecat proident aliquip sint sunt.",
    icon: <QuotsBg />, // Icon component for additional graphical representation
  },
];
