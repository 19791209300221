import { Link } from "react-router-dom";
// types
import { TournamentTeamStatus } from '@src/firestore/tournaments';
import { TeamObjWithStatus } from "./Entrants";
// libaries
import {toast} from 'react-toastify';
// components
import { DisclosurePanel } from "@headlessui/react";
// assets
import imagePlaceholder from '@assets/images/placeholder/placeholder.png';
// icons
import { DiscordIcon, TwitterIcon, TwitchIcon, InstagramIcon, YouTubeIcon } from '@icons/Socials';
import { SwordsIcon } from "@components/common/icons/TournamentIcons";

interface IEntrantsTeamPlayerList {
  team: TeamObjWithStatus,
}

const EntrantsTeamPlayerList: React.FC<IEntrantsTeamPlayerList> = ({ team }) => {
  const teamPlayers = team.playerData;

  const copyDiscordUsername = (username: string) => {
    toast.info('Discord username copied to clipboard');
    navigator.clipboard.writeText(username);
  }

  return (
    <DisclosurePanel className="p-6 flex flex-col gap-1 md:border-t border-lightGray">
      <div className="flex flex-col gap-6 w-full">
        {teamPlayers.map((player) => {

          const checkedIn = team.status === TournamentTeamStatus.confirmed;
          const playerSelected = team.participatingPlayers.includes(player.id);

          return (
            <div key={`${team.teamName}-${player.id}`}
                  className={`rounded-lg flex gap-4 w-full relative`}>
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center w-fit gap-x-4">
                  <Link to={`/profile/${player.id}`}
                        className={`flex items-center gap-x-4 hover:opacity-75 transition-opacity
                                    ${checkedIn && !playerSelected ? 'opacity-50 hover:opacity-50' : ''}`}>
                    <div className='rounded-full w-[45px] h-auto aspect-square overflow-hidden border border-lightGray hover:opacity-75 transition-opacity'>
                      <img
                        className="w-full h-full object-cover"
                        src={player.displayImage ? player.displayImage : imagePlaceholder}
                        alt="entrants-iamge"
                      />
                    </div>
                    <div className="w-fit">
                      <div className="flex items-center gap-x-4">
                        <h2 className={`font-wide font-medium text-base !leading-6 flex items-center gap-4 text-white`}>
                          {player.displayName}
                        </h2>
                        {checkedIn && playerSelected ? (
                          <SwordsIcon className="w-[14px] h-auto aspect-square fill-green"/>
                          // <FaCheck className="text-sm mb-1 text-green"/>
                        ) : ''}
                      </div>
                      <h3 className="font-compact text-sm text-steelGray">
                        @{player.username}
                      </h3>
                    </div>
                  </Link>
                </div>
                <div className='flex items-center gap-x-4'>
                  {player.discord ? (
                      <button type="button" aria-label="copy discord username"
                              onClick={() => copyDiscordUsername(player.discord)}>
                        <DiscordIcon className='w-[16px] aspect-square h-auto fill-steelGray hover:fill-green transition-[300]'/>
                      </button>
                    ) : ''}
                  {player.twitter ? (
                    <a href={player.twitter} target="_blank">
                      <TwitterIcon className='w-[16px] aspect-square h-auto fill-steelGray hover:fill-green transition-[300]'/>
                    </a>
                  ) : ''}
                  {player.twitch ? (
                    <a href={player.twitch} target="_blank">
                      <TwitchIcon className='w-[16px] aspect-square h-auto fill-steelGray hover:fill-green transition-[300]'/>
                    </a>
                  ) : ''}
                  {player.youtube ? (
                    <a href={player.youtube} target="_blank">
                      <YouTubeIcon className='w-[16px] aspect-square h-auto fill-steelGray hover:fill-green transition-[300]'/>
                    </a>
                  ) : ''}
                  {player.instagram ? (
                    <a href={player.instagram} target="_blank">
                      <InstagramIcon className='w-[16px] aspect-square h-auto fill-steelGray hover:fill-green transition-[300]'/>
                    </a>
                  ) : ''}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </DisclosurePanel>
  )
}

export default EntrantsTeamPlayerList;
