// types
import { TournamentStatus } from '@src/firestore/tournaments';
import { TournamentSubViews } from '@components/tournament/types';
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';

// components
import RegistrationViews from "./Views/RegistrationViews";
import CheckInViews from "./Views/CheckInViews";
import PrepViews from "./Views/PrepViews";
import OngoingViews from "./Views/OngoingViews";
import ResultsCompletedViews from "./Views/ResultsCompletedViews";

interface IJoinButton {
  setCurrentSubView: (subView: TournamentSubViews) => void,
}

const JoinButton: React.FC<IJoinButton> = ({setCurrentSubView}) => {
  const {
    tournament,
  } = useTournamentContext();

  const tournamentInRegistration = tournament !== null && tournament.status === TournamentStatus.registration;
  const tournamentInConfirmation = tournament !== null && tournament.status === TournamentStatus.confirmation;
  const tournamentInPrep = tournament !== null && tournament.status === TournamentStatus.prep;
  const tournamentOngoing = tournament !== null && tournament.status === TournamentStatus.ongoing;
  const tournamentComplete = tournament !== null && tournament.status === TournamentStatus.results;

  if (tournamentInRegistration) {
    return (<RegistrationViews/>);
  } else if (tournamentInConfirmation) {
    return (<CheckInViews/>);
  } else if (tournamentInPrep) {
    return (<PrepViews/>);
  } else if (tournamentOngoing) {
    return (<OngoingViews/>);
  } else if (tournamentComplete) {
    return (<ResultsCompletedViews setCurrentSubView={setCurrentSubView}/>);
  } else {
    return '';
  }
}

export default JoinButton;
