import { ReactNode } from 'react'
import './skeleton.css';

interface ISkeleton {
  className?: string,
  children?: ReactNode,
}

const Skeleton: React.FC<ISkeleton> = ({className, children}) => {
  return (
    <div className={`${className} skeleton-loader bg-green/[0.01]`}>
      {children}
    </div>
  )
}

export default Skeleton;
