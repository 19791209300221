import { createContext, useEffect, useState } from 'react';
// types
import { ApexMapPoi } from '@customTypes/Games/ApexLegends';
// libraries
import { Droppable, DragDropContext, DropResult, DragUpdate } from '@hello-pangea/dnd';
// components
import PoiPreferencesMapPoiCard from './PoiCard';

interface IPoiListContext {
  tempPoiLocationsOrdered: number[],
}

export const PoiListContext = createContext<IPoiListContext>({
  tempPoiLocationsOrdered: Array.from({length: 30}).map((_i, index) => index + 1)
});

interface IPoiList {
  poiLocationsForMap: ApexMapPoi[]
  poiLocationsOrdered: number[],
  setPoiLocationsOrdered: (list: number[]) => void,
  mapModalRef: React.MutableRefObject<HTMLDivElement | null>
}

const PoiList: React.FC<IPoiList> = ({poiLocationsOrdered, poiLocationsForMap, setPoiLocationsOrdered, mapModalRef}) => {
  const [tempPoiLocationsOrdered, setTempPoiLocationsOrdered] = useState<number[]>([]);

  const [dragging, setDragging] = useState<boolean>(false);

  useEffect(() => {
    setTempPoiLocationsOrdered(poiLocationsOrdered.length > 0 ? poiLocationsOrdered : poiLocationsForMap.map((location) => location.poiNumber))
  }, [poiLocationsOrdered]);

  const handleDragEnd = (result: DropResult) => {
    setDragging(false);

    if (!result.destination) return;
    const { source, destination } = result;

    const localPoiOrder = [...poiLocationsOrdered];

    const [movedItem] = localPoiOrder.splice(source.index, 1);
    localPoiOrder.splice(destination.index, 0, movedItem);

    setPoiLocationsOrdered(localPoiOrder);
  };

  const handleDragUpdate = (update: DragUpdate) => {
    setDragging(true);

    if (!update.destination) return;
    const { source, destination } = update;

    const localTempPoiOrder = [...poiLocationsOrdered];

    const [movedItem] = localTempPoiOrder.splice(source.index, 1);
    localTempPoiOrder.splice(destination.index, 0, movedItem);

    setTempPoiLocationsOrdered(localTempPoiOrder);
  };

  const contextValue = {
    tempPoiLocationsOrdered
  };

  return (
    <PoiListContext.Provider value={contextValue}>
      <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
        <Droppable droppableId='list'>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}
                className='relative w-full flex flex-col gap-y-2 max-h-[350px] overflow-y-auto maskedListVert py-[10px]'>
              {poiLocationsOrdered.map((poiNumber, index) => (
                <PoiPreferencesMapPoiCard key={`drop-spot-number-${poiNumber}-${index}`}
                                    index={index}
                                    cardInListDragging={dragging}
                                    mapModalRef={mapModalRef}
                                    poiNumber={poiNumber}
                                    poiLocationsForMap={poiLocationsForMap}/>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </PoiListContext.Provider>
  )
};

export default PoiList;
