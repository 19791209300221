export const CompetitiveLine = () => {
    return (
        <svg
            className="max-lg:w-full"
            width="372"
            height="34"
            viewBox="0 0 372 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className="max-lg:w-full"
                d="M-63 33.5H57.5L90 1H371.5"
                stroke="#D4FF27"
            />
        </svg>
    );
};

export const CompetitiveLineForMobile = () => {
    return (
        <svg
            className="sm:scale-[1.2]"
            width="396"
            height="34"
            viewBox="0 0 396 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0 33H120.361L152.824 1H434" stroke="#D4FF27" />
        </svg>
    );
};

// Competitive Icons for Banner
export const Arrow = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path d="M8 3V0H6V3H8Z" fill="#D4FF27" />
        <path
          d="M0.792893 2.20711L3.29289 4.70711L4.70711 3.29289L2.20711 0.792893L0.792893 2.20711Z"
          fill="#D4FF27"
        />
        <path
          d="M6 5L5 6.00001L8 15H10L10.7172 12.1314L14.2929 15.7071L15.7071 14.2929L12.1314 10.7172L15 10V8L6 5Z"
          fill="#D4FF27"
        />
        <path d="M0 6H3V8H0V6Z" fill="#D4FF27" />
      </svg>
    );
  };
  export const Compete = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M7.99283 1.6003C7.41804 1.60907 6.84445 1.6453 6.27206 1.69906C5.69124 1.75282 5.11704 1.84164 4.54587 1.9515C4.26541 2.00585 4.02897 2.12272 3.86914 2.35997C3.75093 2.53586 3.70871 2.72987 3.72379 2.9379C3.73464 3.09334 3.73102 3.24878 3.74067 3.4048C3.7642 3.79632 3.81245 4.18433 3.88543 4.57001C4.0338 5.35539 4.29255 6.10629 4.66529 6.81804C5.00305 7.46375 5.42163 8.05688 5.92465 8.5939C6.20934 8.89777 6.51754 9.17592 6.84565 9.43362C6.88667 9.46576 6.89752 9.49732 6.88908 9.5464C6.79981 10.0922 6.85168 10.6228 7.07605 11.1341C7.11767 11.2288 7.17195 11.3164 7.22986 11.4017C7.26604 11.4543 7.25398 11.48 7.2003 11.5093C6.76302 11.75 6.33479 12.0048 5.9337 12.2993C5.77387 12.4168 5.61584 12.5377 5.48737 12.6903C5.41138 12.7808 5.36675 12.8772 5.37036 12.997C5.37519 13.17 5.37278 13.343 5.37097 13.5154C5.37097 13.5732 5.38846 13.5948 5.45481 13.5931C5.60258 13.589 5.75095 13.5989 5.89932 13.5989C7.42708 13.6001 8.95484 13.6001 10.4832 13.6001C10.6129 13.6001 10.6135 13.5989 10.6123 13.4739C10.6111 13.3179 10.6044 13.1618 10.6123 13.0064C10.6195 12.8638 10.5652 12.751 10.4675 12.6517C10.2426 12.4226 9.9826 12.2362 9.71299 12.0609C9.41866 11.8698 9.11709 11.6898 8.81009 11.518C8.72686 11.4713 8.72384 11.4613 8.7739 11.3825C9.00973 11.0126 9.11468 10.6076 9.13699 10.1769C9.14785 9.96948 9.13579 9.76379 9.10563 9.55926C9.09658 9.50024 9.11347 9.45992 9.16233 9.42018C9.90359 8.81829 10.5375 8.12466 11.0393 7.32175C11.5707 6.47093 11.9187 5.5529 12.1033 4.57585C12.2058 4.03182 12.2564 3.48252 12.2733 2.92972C12.2866 2.48327 12.0357 2.06428 11.4494 1.95209C11.0085 1.86735 10.5658 1.79256 10.1195 1.73996C9.76125 1.69789 9.40177 1.66049 9.04049 1.64296C8.69067 1.6266 8.34265 1.59738 7.99283 1.6003Z"
          fill="#D4FF27"
        />
        <path
          d="M12.678 11.8085C12.6515 11.4351 12.628 11.0979 12.6027 10.7607C12.5948 10.6584 12.596 10.6514 12.704 10.6356C13.2366 10.5562 13.7547 10.4212 14.2673 10.2628C14.277 10.2599 14.2866 10.2576 14.2957 10.2529C14.3276 10.2365 14.3801 10.2324 14.3771 10.1892C14.3741 10.1465 14.3234 10.1693 14.2951 10.1588C14.1165 10.0928 13.9422 10.018 13.7667 9.94552C13.2094 9.71587 12.6618 9.46693 12.1388 9.16891C11.8439 9.0012 11.5586 8.81946 11.289 8.61494C11.1599 8.51677 11.1123 8.52436 11.0091 8.65058C10.6424 9.09996 10.248 9.52478 9.80829 9.90871C9.72445 9.98175 9.72687 9.98058 9.80708 10.0484C10.5562 10.6836 11.3934 11.1925 12.2619 11.6559C12.3698 11.7132 12.4766 11.7728 12.5852 11.8283C12.6672 11.8698 12.6793 11.861 12.6774 11.8079L12.678 11.8085Z"
          fill="#D4FF27"
        />
        <path
          d="M1.60313 10.2178C2.16647 10.4054 2.73282 10.5533 3.31606 10.6345C3.38904 10.6444 3.39869 10.6766 3.39447 10.7367C3.37336 11.0254 3.35527 11.3141 3.33536 11.6028C3.33114 11.6659 3.3239 11.729 3.31968 11.7915C3.31485 11.8599 3.32692 11.8692 3.38904 11.8371C3.56214 11.7477 3.73464 11.6577 3.90533 11.5648C4.47892 11.251 5.04045 10.9191 5.56639 10.5334C5.78653 10.3715 6.00246 10.205 6.21416 10.032C6.25699 9.99694 6.25397 9.97416 6.21416 9.94026C5.94999 9.71821 5.70632 9.47628 5.46868 9.22793C5.30462 9.05613 5.15323 8.87439 5.00426 8.69032C4.99039 8.67279 4.97712 8.65526 4.96324 8.63773C4.87458 8.52845 4.83417 8.52086 4.72138 8.60559C4.29737 8.92465 3.8426 9.19754 3.36491 9.43362C2.81425 9.70593 2.25332 9.95604 1.6743 10.1687C1.64897 10.1781 1.62243 10.1863 1.60193 10.2178H1.60313Z"
          fill="#D4FF27"
        />
        <path
          d="M12.7589 4.13525C12.7498 4.2007 12.7836 4.22991 12.8469 4.23751C12.8916 4.24277 12.9368 4.24803 12.9808 4.25796C13.0948 4.28309 13.1636 4.37133 13.1334 4.48002C12.9863 5.0112 12.8373 5.54238 12.5405 6.02039C12.4145 6.22374 12.2703 6.41308 12.1051 6.5878C12.064 6.63163 12.0333 6.68188 12.0086 6.73506C11.7655 7.26449 11.4639 7.76061 11.1117 8.22809C11.099 8.24504 11.0737 8.26315 11.0906 8.28478C11.1087 8.30757 11.1316 8.28478 11.1515 8.27835C11.6599 8.11706 12.1141 7.85995 12.5236 7.53154C12.8475 7.2715 13.1262 6.96646 13.3711 6.63396C13.8361 6.00227 14.1594 5.30572 14.3693 4.56008C14.4995 4.09726 14.2016 3.63387 13.7191 3.52868C13.4621 3.47259 13.2028 3.42408 12.9446 3.37383C12.827 3.35104 12.8204 3.35747 12.8198 3.47492C12.8192 3.68003 12.792 3.88222 12.7643 4.08499C12.7619 4.10194 12.7607 4.11889 12.7583 4.13583L12.7589 4.13525Z"
          fill="#D4FF27"
        />
        <path
          d="M1.60072 4.3649C1.59529 4.4508 1.61761 4.54313 1.64535 4.63429C1.87033 5.36824 2.1906 6.0572 2.65924 6.67721C3.0362 7.17566 3.48615 7.59815 4.03621 7.91546C4.28712 8.06038 4.55069 8.18076 4.82633 8.27484C4.84865 8.28244 4.87458 8.30757 4.89449 8.28244C4.91258 8.25965 4.88544 8.23744 4.87157 8.21874C4.66107 7.94819 4.4747 7.66185 4.30642 7.365C4.1864 7.15404 4.0724 6.93958 3.97771 6.71636C3.93911 6.62578 3.87517 6.55566 3.80883 6.48554C3.43005 6.08467 3.2093 5.60199 3.04404 5.09184C2.99278 4.93407 2.94875 4.77395 2.9011 4.61501C2.88602 4.56358 2.87396 4.51157 2.87335 4.45723C2.87215 4.35438 2.91919 4.29127 3.02173 4.26556C3.06998 4.25329 3.11944 4.24452 3.16889 4.23576C3.22076 4.22699 3.24187 4.20011 3.23464 4.14927C3.19905 3.91085 3.17613 3.67127 3.16226 3.43051C3.15985 3.38318 3.13331 3.36448 3.08747 3.37324C2.79736 3.43168 2.50182 3.46382 2.21653 3.54271C1.84258 3.64614 1.60253 3.96929 1.60193 4.36315L1.60072 4.3649Z"
          fill="#D4FF27"
        />
      </svg>
    );
  };
  export const Repeat = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M6 7L7 6L4.70711 3.70711L5.29289 3.12132C6.01086 2.40335 6.98464 2 8 2C9.01536 2 9.98914 2.40335 10.7071 3.12132L10.7929 3.20711C11.5658 3.98 12 5.02828 12 6.12132L12 7L14 7V6.12132C14 4.49785 13.3551 2.94086 12.2071 1.79289L12.1213 1.70711C11.0283 0.614064 9.5458 0 8 0C6.45421 0 4.97172 0.614064 3.87868 1.70711L3.29289 2.29289L1 0L0 1V7H6Z"
          fill="#D4FF27"
        />
        <path
          d="M10.7071 12.8787C9.98914 13.5966 9.01536 14 8 14C6.98464 14 6.01086 13.5967 5.29289 12.8787L5.20711 12.7929C4.43421 12.02 4 10.9717 4 9.87868L4 9L2 9V9.87868C2 11.5022 2.64492 13.0591 3.79289 14.2071L3.87868 14.2929C4.97172 15.3859 6.45421 16 8 16C9.54579 16 11.0283 15.3859 12.1213 14.2929L12.7071 13.7071L15 16L16 15L16 9L10 9L9 10L11.2929 12.2929L10.7071 12.8787Z"
          fill="#D4FF27"
        />
      </svg>
    );
  };
