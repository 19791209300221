// ====🧑‍💻🧑‍💻 HERE IS THE DISCORD ICON 🧑‍💻🧑‍💻====

/**
 * DiscordIcon component represents a Discord icon in SVG format.
 * @returns {JSX.Element} JSX representation of the Discord icon.
 */

export const DiscordIcon = (): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="group-hover:fill-green duration-200"
        d="M12.99 3.32844C12.0722 2.89872 11.088 2.58212 10.0589 2.40079C10.0402 2.39729 10.0215 2.40603 10.0118 2.42352C9.88525 2.65325 9.74504 2.95294 9.64686 3.1885C8.54005 3.01942 7.43891 3.01942 6.3548 3.1885C6.25659 2.94771 6.1113 2.65325 5.98415 2.42352C5.9745 2.40661 5.95577 2.39787 5.93703 2.40079C4.90855 2.58154 3.92434 2.89814 3.00597 3.32844C2.99802 3.33194 2.9912 3.33778 2.98668 3.34535C1.11984 6.19129 0.608435 8.96729 0.859313 11.7089C0.860448 11.7223 0.867827 11.7351 0.878044 11.7433C2.10973 12.6662 3.30283 13.2266 4.47377 13.598C4.49251 13.6038 4.51237 13.5968 4.52429 13.5811C4.80128 13.1951 5.04819 12.7881 5.25989 12.3601C5.27238 12.3351 5.26046 12.3053 5.23492 12.2954C4.84328 12.1438 4.47036 11.959 4.11164 11.7491C4.08327 11.7322 4.081 11.6908 4.1071 11.6709C4.18259 11.6132 4.2581 11.5532 4.33018 11.4925C4.34322 11.4815 4.36139 11.4791 4.37672 11.4861C6.73338 12.584 9.28473 12.584 11.6136 11.4861C11.6289 11.4785 11.6471 11.4809 11.6607 11.492C11.7328 11.5526 11.8083 11.6132 11.8843 11.6709C11.9104 11.6908 11.9087 11.7322 11.8804 11.7491C11.5216 11.9631 11.1487 12.1438 10.7565 12.2948C10.731 12.3048 10.7196 12.3351 10.7321 12.3601C10.9484 12.7875 11.1953 13.1945 11.4671 13.5805C11.4785 13.5968 11.4989 13.6038 11.5177 13.598C12.6943 13.2266 13.8874 12.6662 15.1191 11.7433C15.1299 11.7351 15.1367 11.7229 15.1378 11.7094C15.4381 8.53988 14.6349 5.78665 13.0087 3.34593C13.0048 3.33778 12.998 3.33194 12.99 3.32844ZM5.61182 10.0395C4.9023 10.0395 4.31768 9.37485 4.31768 8.55855C4.31768 7.74225 4.89097 7.07757 5.61182 7.07757C6.33833 7.07757 6.91729 7.74808 6.90593 8.55855C6.90593 9.37485 6.33265 10.0395 5.61182 10.0395ZM10.3967 10.0395C9.68716 10.0395 9.10254 9.37485 9.10254 8.55855C9.10254 7.74225 9.6758 7.07757 10.3967 7.07757C11.1232 7.07757 11.7021 7.74808 11.6908 8.55855C11.6908 9.37485 11.1232 10.0395 10.3967 10.0395Z"
        fill="white"
      />
    </svg>
  );
};
/**
 * TwitchIcon component represents a Twitch icon in SVG format.
 * @returns {JSX.Element} JSX representation of the Twitch icon.
 */

export const TwitchIcon = (): JSX.Element => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="group-hover:fill-green duration-200"
        d="M4.83301 2L2.33301 4.32143V12.6786H5.33301V15L7.83301 12.6786H9.83301L14.333 8.5V2H4.83301ZM13.333 8.03571L11.333 9.89286H9.33301L7.58301 11.5179V9.89286H5.33301V2.92857H13.333V8.03571Z"
        fill="white"
      />
      <path
        className="group-hover:fill-green duration-200"
        d="M11.8331 4.55356H10.8331V7.33927H11.8331V4.55356Z"
        fill="white"
      />
      <path
        className="group-hover:fill-green duration-200"
        d="M9.08308 4.55356H8.08308V7.33927H9.08308V4.55356Z"
        fill="white"
      />
    </svg>
  );
};

/**
 * DisplayEyeIcon component represents an open eye icon in SVG format.
 * @returns {JSX.Element} JSX representation of the open eye icon.
 */
export const DisplayEyeIcon = (): JSX.Element => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Outer circle of the eye */}
      <path
        d="M1.6665 8.50008C1.6665 8.50008 4.33317 3.16675 8.99984 3.16675C13.6665 3.16675 16.3332 8.50008 16.3332 8.50008C16.3332 8.50008 13.6665 13.8334 8.99984 13.8334C4.33317 13.8334 1.6665 8.50008 1.6665 8.50008Z"
        stroke="#4D545E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Pupil of the eye */}
      <path
        d="M9 10.5C10.1046 10.5 11 9.60457 11 8.5C11 7.39543 10.1046 6.5 9 6.5C7.89543 6.5 7 7.39543 7 8.5C7 9.60457 7.89543 10.5 9 10.5Z"
        stroke="#4D545E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

/**
 * HiddenEyeIcon component represents a closed/hidden eye icon in SVG format.
 * @returns {JSX.Element} JSX representation of the closed/hidden eye icon.
 */
export const HiddenEyeIcon = (): JSX.Element => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.909091 1.12889L2.07273 0L17.2727 14.8622L16.1182 16L13.3182 13.2622C12.2727 13.6 11.1636 13.7778 10 13.7778C5.45455 13.7778 1.57273 11.0133 0 7.11111C0.627273 5.54667 1.62727 4.16889 2.9 3.07556L0.909091 1.12889ZM10 4.44444C10.7233 4.44444 11.417 4.7254 11.9285 5.22549C12.4399 5.72559 12.7273 6.40387 12.7273 7.11111C12.7277 7.41384 12.6755 7.71443 12.5727 8L9.09091 4.59556C9.38297 4.4951 9.69039 4.444 10 4.44444ZM10 0.444444C14.5455 0.444444 18.4273 3.20889 20 7.11111C19.258 8.95396 17.9972 10.5535 16.3636 11.7244L15.0727 10.4533C16.3299 9.603 17.3438 8.45249 18.0182 7.11111C17.2832 5.64439 16.1422 4.40868 14.7248 3.54445C13.3074 2.68021 11.6704 2.22211 10 2.22222C9.00909 2.22222 8.03636 2.38222 7.12727 2.66667L5.72727 1.30667C7.03636 0.755555 8.48182 0.444444 10 0.444444ZM1.98182 7.11111C2.71676 8.57783 3.85779 9.81354 5.27521 10.6778C6.69263 11.542 8.32958 12.0001 10 12C10.6273 12 11.2455 11.9378 11.8182 11.8133L9.74546 9.77778C9.11288 9.71148 8.52257 9.43545 8.0727 8.99558C7.62284 8.55571 7.34053 7.97852 7.27273 7.36L4.18182 4.32889C3.28182 5.08444 2.52727 6.02667 1.98182 7.11111Z"
        fill="#4D545E"
      />
    </svg>
  );
};

/**
 * GoogleIcon component represents the Google logo icon in SVG format.
 * @returns {JSX.Element} JSX representation of the Google logo icon.
 */

export const GoogleIcon = (): JSX.Element => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="group-hover:fill-green duration-200"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3465 8.18189C16.3465 7.61462 16.2956 7.06917 16.201 6.54553H8.6665V9.64008H12.972C12.7865 10.6401 12.2229 11.4873 11.3756 12.0546V14.0619H13.961C15.4738 12.6692 16.3465 10.6183 16.3465 8.18189Z"
        fill="white"
      />
      <path
        className="group-hover:fill-green duration-200"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 16C10.8267 16 12.6376 15.2837 13.9612 14.0618L11.3758 12.0546C10.6594 12.5346 9.74303 12.8182 8.66667 12.8182C6.58303 12.8182 4.81939 11.4109 4.1903 9.52002H1.51758V11.5927C2.83394 14.2073 5.53939 16 8.66667 16Z"
        fill="white"
      />
      <path
        className="group-hover:fill-green duration-200"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19014 9.51995C4.03014 9.03995 3.93923 8.52723 3.93923 7.99995C3.93923 7.47268 4.03014 6.95995 4.19014 6.47995V4.40723H1.51741C0.975595 5.48723 0.666504 6.70904 0.666504 7.99995C0.666504 9.29086 0.975595 10.5127 1.51741 11.5927L4.19014 9.51995Z"
        fill="white"
      />
      <path
        className="group-hover:fill-green duration-200"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66667 3.18182C9.84121 3.18182 10.8958 3.58545 11.7248 4.37818L14.0194 2.08364C12.6339 0.792727 10.823 0 8.66667 0C5.53939 0 2.83394 1.79273 1.51758 4.40727L4.1903 6.48C4.81939 4.58909 6.58303 3.18182 8.66667 3.18182Z"
        fill="white"
      />
    </svg>
  );
};
