// types
import { TournamentStatus } from "../../../firestore/tournaments";
// utils
import { formatDateTime, getTimezone } from "../../../utils/Date";
// components
import { Disclosure } from "@headlessui/react";
// icons
import { GreenWatchIcon } from "../../common/icons/Common";
import { useTournamentContext } from "../TournamentProvider";
import { FooterAccordionIcon } from "../../common/icons/FooterIcons";
import { CelenderIcon, WatchIcon } from "../../common/icons/TournamentIcons";
import { RightCheckIcon } from "../../common/icons/ProfileIcons";

interface IMobileStatus {
  registrationActive: boolean,
  confirmationActive: boolean,
  ongoingActive: boolean,
  completed: boolean,
  confirmationCountdown: string,
  ongoingCountdown: string,
  resultsCountdown: string,
}

const MobileStatus: React.FC<IMobileStatus> = ({ registrationActive, confirmationActive, ongoingActive, completed, confirmationCountdown, ongoingCountdown, resultsCountdown }) => {
  const { tournament } = useTournamentContext();

  const formatStatusSummaryTop = (status: TournamentStatus) => {
    switch (status) {
      case 0:
      return (
        <>
          <p className="font-compact font-medium text-base text-white !leading-4 uppercase">
            Registration Open
          </p>
          <h2 className="font-wide font-bold text-2xl mt-2 text-green !leading-[1.875rem] flex items-center gap-2">
            <p>Confirmation in</p>
            <div className="mt-[-0.25rem]">
              <GreenWatchIcon />
            </div>
            {confirmationCountdown}
          </h2>
        </>
      )
      case 1:
      return (
        <>
          <p className="font-compact font-medium text-base text-white !leading-4 uppercase">
            Confirmation
          </p>
          <h2 className="font-wide font-bold text-2xl mt-2 text-green !leading-[1.875rem] flex items-center gap-2">
            <p>Play starts in</p>
            <div className="mt-[-0.25rem]">
              <GreenWatchIcon />
            </div>
            {ongoingCountdown}
          </h2>
        </>
      )
      case 2:
      return (
        <>
          <p className="font-compact font-medium text-base text-white !leading-4 uppercase">
            Ongoing
          </p>
          <h2 className="font-wide font-bold text-2xl mt-2 text-green !leading-[1.875rem] flex items-center gap-2">
            {/* <p>Results in</p>
            <div className="mt-[-0.25rem]">
              <GreenWatchIcon />
            </div>
            {resultsCountdown} */}
          </h2>
        </>
      )
      case 3:
      return (
        <>
          <p className="font-compact font-medium text-base text-white !leading-4 uppercase">
            Completed
          </p>
          <h2 className="font-wide font-bold text-2xl mt-2 text-green !leading-[1.875rem] flex items-center gap-2">
            <p className="text-base">Tournament results released shortly</p>
          </h2>
        </>
      )
      case 4:
      return (
        <>
          <p className="font-compact font-medium text-base text-white !leading-4 uppercase">
            Tournament Completed
          </p>
          <h2 className="font-wide font-bold text-2xl mt-2 text-green !leading-[1.875rem] flex items-center gap-2">
            <p className="text-base">The tournament has finished</p>
          </h2>
        </>
      )

      default:
      return (
        <></>
      )
    }
  }
  const formatStatusSummaryBottom = (status: TournamentStatus) => {
    switch (status) {
      case 0:
      return (
        <span className="text-slateGray text-sm font-normal font-compact leading-4">
          Teams can register for the tournament
        </span>
      )
      case 1:
      return (
        <span className="text-slateGray text-sm font-normal font-compact leading-4">
          Confirmation of Teams Registered
        </span>
      )
      case 2:
      return (
        <span className="text-slateGray text-sm font-normal font-compact leading-4">
          Tournament play is underway
        </span>
      )
      case 3:
      return (
        <span className="text-slateGray text-sm font-normal font-compact leading-4">
          Announcement of tournament winners
        </span>
      )
      case 4:
      return (
        <span className="text-slateGray text-sm font-normal font-compact leading-4">
          Thank you to all participants
        </span>
      )

      default:
      return (
        <></>
      )
    }
  }

  return tournament ? (
    <div className="bg-lightBlack p-3 custom_sm:p-4 rounded-lg mt-6 md:hidden">
      <div className="mx-auto w-full  rounded-lg">
        <Disclosure>
          {({ open }) => (
            <>
              <div
                className={`rounded-lg duration-300 ${open ? "bg-lightGray p-3 custom_sm:p-4" : ""}`}>
                {formatStatusSummaryTop(tournament.status)}
                <div className="flex gap-1 mt-3  w-full">
                  <span className={`h-1 flex bg-green rounded w-full`}></span>
                  <span className={`h-1 flex ${tournament.status > 0 ? 'bg-green' : 'bg-ebonyClay'} rounded w-full`}></span>
                  <span className={`h-1 flex ${tournament.status > 1 ? 'bg-green' : 'bg-ebonyClay'} rounded w-full`}></span>
                  <span className={`h-1 flex ${tournament.status > 2 ? 'bg-green' : 'bg-ebonyClay'} rounded w-full`}></span>
                </div>
                <Disclosure.Button className="flex w-full justify-between rounded-lg pt-3 text-left items-center focus-visible:outline-none">
                  {formatStatusSummaryBottom(tournament.status)}
                  <FooterAccordionIcon
                    accordianArrow={
                      open
                        ? "-rotate-180 duration-300"
                        : "rotate-0 duration-300"
                    }
                  />
                </Disclosure.Button>{" "}
              </div>
              <Disclosure.Panel className="sm:px-4 pb-2 flex flex-col gap-1 mt-6">
                <div className="flex w-full">
                  <div className="flex flex-col gap-[1.375rem] items-center justify-center">

                    <div className={`outline-[0.1875rem] outline-offset-[-0.1875rem] outline outline-green w-6 h-6 rounded-full tournament_details_progress_tricks_line
                                    ${tournament.status > 0 ? 'bg-green' : 'bg-black'} z-20 flex items-center justify-center`}>
                      <RightCheckIcon />
                    </div>

                    <div className={`outline-[0.1875rem] outline-offset-[-0.1875rem] outline outline-green w-6 h-6 rounded-full tournament_details_progress_tricks_line
                                    ${tournament.status > 1 ? 'bg-green' : 'bg-black'} ${tournament.status > 0 ? 'after:!bg-green' : ''} z-20 flex items-center justify-center`}>
                      <RightCheckIcon />
                    </div>

                    <div className={`outline-[0.1875rem] outline-offset-[-0.1875rem] outline outline-green w-6 h-6 rounded-full tournament_details_progress_tricks_line ${tournament.status > 2 ? 'bg-green' : 'bg-black'} ${tournament.status > 1 ? 'after:!bg-green' : ''} z-20 flex items-center justify-center`}>
                      <RightCheckIcon />
                    </div>

                    <div className={`outline-[0.1875rem] outline-offset-[-0.1875rem] outline outline-green w-6 h-6 rounded-full tournament_details_progress_tricks_line ${tournament.status > 3 ? 'bg-green' : 'bg-black'} ${tournament.status > 2 ? 'after:!bg-green' : ''} z-20 flex items-center justify-center`}>
                      <RightCheckIcon />
                    </div>

                  </div>

                  <div className="flex w-full flex-col justify-between ps-3">

                    <div className="mt-1">
                      <h2 className={`font-compact group flex justify-between font-medium text-sm
                                      custom_sm:text-base group-hover:text-white duration-300 !leading-4 tracking-[0.0088rem] text-steelGray`}>
                        <div className={`${registrationActive ? 'text-white' : 'text-steelGray'}`}>Registration</div>
                        <span className="flex items-center gap-2">
                          <p>{tournament.status > 0 ? 'Completed' : 'In progress'}</p>
                        </span>
                      </h2>
                    </div>

                    <div className="mt-1">
                      <h2 className={`font-compact group flex justify-between font-medium text-sm
                                      custom_sm:text-base group-hover:text-white duration-300 !leading-4 tracking-[0.0088rem] text-steelGray`}>
                        <div className={`${confirmationActive ? 'text-white' : 'text-steelGray'}`}>Confirmation</div>
                        <span className="flex items-center gap-2">
                          {confirmationActive ? (
                            <>
                              <p>In progress</p>
                              <span className="-mt-[0.188rem]">
                                <WatchIcon />
                              </span>
                            </>
                          ) : (
                            <>
                              {tournament.status > 1 ? (
                                <p>Completed</p>
                              ) : (
                                <>
                                  <p>{formatDateTime(tournament.statusDates.confirmation)} {getTimezone()}</p>
                                  {confirmationCountdown !== '' ? (
                                    <p>({confirmationCountdown})</p>
                                  ) : ''}
                                  <span className="-mt-[0.188rem]">
                                    <CelenderIcon />
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </h2>
                    </div>

                    <div className="mt-1">
                      <h2 className="font-compact group flex justify-between font-medium text-sm custom_sm:text-base text-steelGray group-hover:text-white duration-300 !leading-4 tracking-[0.0088rem]">
                        <div className={`${ongoingActive ? 'text-white' : 'text-steelGray'}`}>Ongoing</div>
                        <span className="flex items-center gap-2">
                          {ongoingActive ? (
                            <>
                              <p>In progress</p>
                              <span className="-mt-[0.188rem]">
                                <WatchIcon />
                              </span>
                            </>
                          ) : (
                            <>
                              {tournament.status > 2 ? (
                                <p>Completed</p>
                              ) : (
                                <>
                                  <p>{formatDateTime(tournament.statusDates.ongoing)}  {getTimezone()}</p>
                                  {ongoingCountdown !== '' ? (
                                    <p>({ongoingCountdown})</p>
                                  ) : ''}
                                  <span className="-mt-[0.188rem]">
                                    <CelenderIcon />
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </h2>
                    </div>

                    <div className="mt-1">
                      <h2 className="font-compact group flex justify-between font-medium text-sm custom_sm:text-base text-steelGray group-hover:text-white duration-300 !leading-4 tracking-[0.0088rem] mb-1">
                        <div className={`${completed ? 'text-white' : 'text-steelGray'}`}>Results</div>
                        <span className="flex items-center gap-2">
                          {completed ? (
                            <>
                              <p>In progress</p>
                              <span className="-mt-[0.188rem]">
                                <WatchIcon />
                              </span>
                            </>
                          ) : (
                            <>
                              {tournament.status > 3 ? (
                                <p>Completed</p>
                              ) : (
                                <>
                                  <p>{formatDateTime(tournament.statusDates.results)}  {getTimezone()}</p>
                                  {resultsCountdown !== '' ? (
                                    <p>({resultsCountdown})</p>
                                  ) : ''}
                                  <span className="-mt-[0.188rem]">
                                    <CelenderIcon />
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                      </h2>
                    </div>

                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  ) : '';
};

export default MobileStatus;
