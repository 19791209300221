import AboutParaOuterLineMobile from "../../assets/images/svg/about/FrameAboutParaOuterLineMobile.svg";
import heroDiamondImage from "../../assets/images/svg/about/about_hero_diamond_img.svg";
import aboutHeroGlowArrow from "../../assets/images/svg/about/about_hero_glow_arrow.svg";
import aboutLineBoxIcon from "../../assets/images/svg/about/about_line_box_icon.svg";
import ablutHeroImg from "../../assets/images/webp/about/about_hero_img.webp";
import {
  AboutHeroGraphLine,
  AboutHeroGraphSmLine,
  AboutParaOuterLine,
} from "../common/icons/AboutIcon";

const AboutHero = () => {
  return (
    <>
      <div className="relative z-[1]">
        <img
          className="absolute -top-24 sm:-top-16 left-1/2 -translate-x-1/2 max-h-[34.813rem] max-w-[34.813rem] w-full h-full z-10 object-cover"
          src={heroDiamondImage}
          alt="diamond-image"
        />
        <div className="relative z-[11] flex items-center justify-center flex-col">
          <img src={aboutHeroGlowArrow} alt="glow-arrow" />
          <span className="absolute w-full top-[5%] sm:top-[7%] xl:top-[7.5%] hidden sm:block">
            <AboutHeroGraphLine />
          </span>
          <span className="absolute w-full top-[8%] sm:top-[7%] xl:top-[7.5%] block sm:hidden">
            <AboutHeroGraphSmLine />
          </span>
          <div className="relative">
            <h2 className="text-center font-bold text-white pt-10 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-wide max-w-[25.5rem] mx-auto uppercase ">
              ESPORTS REDEFINED
            </h2>
          </div>
          <p className="text-green text-center font-bold text-sm uppercase pt-5 font-wide">
            one game at a time
          </p>
          <img src={aboutLineBoxIcon} alt="box-icon" />
          <img
            className="pt-0 md:pt-[1.875rem] max-w-[52.625rem] w-full"
            src={ablutHeroImg}
            alt="esports redefined team"
          />
          <div className="relative">
            <span className="max-w-[68.75rem] -mt-[3rem] hidden sm:block">
              <AboutParaOuterLine />
            </span>
            <span className="max-w-[68.75rem] absolute sm:hidden w-full -top-2.5">
              {/* <AboutParaOuterLineMobile /> */}
              <img
                className="w-full "
                src={AboutParaOuterLineMobile}
                alt="green-line"
              />
            </span>
            <p className="text-white font-bold text-xl md:text-2xl text-center max-w-[59.5rem] uppercase font-wide  pt-36 sm:pt-8 leading-6 px-6 custom_xsm:px-10 lg:px-0 mt-5 sm:mt-0 mx-auto">
              We enable gamers of all skill levels to access and enjoy esports
              across multiple platforms and titles.{" "}
            </p>
            <p className="pt-6 md:pt-8 text-slateGray text-base leading-8 font-compact text-center max-w-[58.75rem] pb-12 mb-0 custom_xsm:mb-12 sm:mb-0 sm:pb-8 px-6 custom_xsm:px-10 mx-auto">
              VERSUS is a platform where competitive gamers can connect, compete
              and track their performance. VStudios supports businesses with
              their esports competitions, content and community building.
              Bespoke solutions include competition design, game integrations,
              league operations, production services and strategic consulting.
            </p>
            <span className="max-w-[68.75rem] rotate-180 mb-8 hidden sm:block">
              <AboutParaOuterLine />
            </span>
            <span className="max-w-[68.75rem] rotate-180 mb-8 absolute sm:hidden w-full -bottom-20 md:-bottom-12">
              {/* <AboutParaOuterLineMobile /> */}
              <img
                className="w-full"
                src={AboutParaOuterLineMobile}
                alt="green-line"
              />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutHero;
