import React, { ReactNode } from 'react';
import { Link } from "react-router-dom";
// context
import { useHeaderContext } from "@provider/HeaderProvider";
// icons
import { CogIcon, MegaphoneIcon, TeamIcon, VersusIcon, UserIcon, BellIcon, ChevronRight} from '@icons/Common';
import { TwitchIcon, YouTubeIcon, TwitterIcon, DiscordIcon } from '@icons/Socials';
import { TrophyIcon } from '@icons/TournamentIcons';
import { useNotificationContext } from '@provider/NotificationProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
import { useProfileInfoContext } from '@components/completeProfile/ProfileInfoProvider';

interface ISocialLink {
  link: string,
  icon: ReactNode,
  handleClick: () => void,
  className?: string,
}

const SocialLink: React.FC<ISocialLink> = ({link, icon, className, handleClick}) => {
  return (
    <Link to={link}
      onClick={handleClick}
      target="_blank"
      className={`profile_dropdown_navigation_social_links
                  ${className}`}
    >
      {icon}
    </Link>
  );
}

interface ISocialIcons {
  handleClick: () => void,
  className?: string,
}

export const SocialIcons: React.FC<ISocialIcons> = ({ handleClick, className }) => {
  const { setNavbarState, navbarState } = useHeaderContext();

  const handleLinkClick = () => {
    setNavbarState({
      ...navbarState,
      profileMenuVisible: false,
      notificationMenuVisible: false,
    });
    handleClick();
  }

  return (
    <div className={`flex flex-col gap-y-2 ${className}`}>
      <div className="flex items-center gap-4 justify-center mt-[20px]">
        <SocialLink link={"https://www.twitch.tv/versus_gg_"}
                    icon={<TwitchIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />}
                    handleClick={handleClick}/>
        <SocialLink link={"https://youtube.com/@versus_gg"}
                    icon={<YouTubeIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />}
                    handleClick={handleClick}/>
        <SocialLink link={"https://twitter.com/gg_versus"}
                    icon={<TwitterIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />}
                    handleClick={handleClick}/>
        <SocialLink link={"https://discord.com/invite/versus-gg"}
                    icon={<DiscordIcon className="w-[16px] h-auto aspect-square fill-steelGray hover:fill-green transition-colors" />}
                    handleClick={handleClick}/>
      </div>
      <div className='w-fit flex self-center gap-x-4 items-center text-steelGray font-compact font-medium text-sm mt-2 md:hidden'>
        <Link to='/terms' onClick={handleLinkClick}>
          Terms of use
        </Link>
        <div className='h-[15px] w-[1px] bg-lightGray'></div>
        <Link to='/privacy-policy' onClick={handleLinkClick}>
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

interface IMenuLink {
  title: string,
  link: string,
  icon?: ReactNode,
  className?: string,
  handleClick: () => void,
  noArrow?: boolean,
}

const MenuLink: React.FC<IMenuLink> = ({title, link, icon, className, handleClick, noArrow}) => {
  return (
    <Link to={link}
          className={`flex items-center justify-between py-2 my-2 hover:opacity-75 transition-opacity
                      ${className}`}
          onClick={handleClick}>
      <div className="flex items-center gap-2">
        {icon}
        <span className="font-compact text-steelGray font-normal text-base leading-4">
          {title}
        </span>
      </div>
      {!noArrow ? (
        <ChevronRight className='w-[12px] h-auto aspect-square fill-steelGray'/>
      ) : ''}
    </Link>
  );
}

interface IMenuLinks {
  handleClick: () => void,
}

const MenuLinks: React.FC<IMenuLinks> = ({ handleClick }) => {
  const { userObj } = useAuthContext();
  const { notificationsCount, activeTournamentsCount } = useNotificationContext();
  const { setNavbarState, navbarState } = useHeaderContext();

  const { openEditProfile } = useProfileInfoContext();

  return (
    <div className={`${userObj ? 'mt-6' : ''} custom_lg:mb-6 custom_lg:pb-0`}>
        {userObj ? (
          <>
            <button type='button'
                    className={`flex md:hidden items-center justify-between py-2 my-2 w-full hover:opacity-75 transition-opacity`}
                    onClick={() => {
                      setNavbarState({
                        ...navbarState,
                        notificationMenuVisible: true,
                      });
                    }}>

              <div className="flex items-center gap-2">
                <BellIcon className={`w-[15px] h-auto aspect-square
                                      ${notificationsCount > 0 ? "fill-green" : "fill-steelGray"}`}/>
                <span className={`font-compact font-normal text-base leading-4
                                ${notificationsCount > 0 ? "text-green" : "text-steelGray"}`}>
                  Notifications
                </span>
              </div>
              <div className='flex items-center gap-x-2'>
                <p className={`${notificationsCount > 0 ? "flex" : "hidden"} w-[16px] h-[16px] aspect-square rounded font-compact font-semibold
                              items-center justify-center text-black bg-green`}>
                  <span className='mt-1'>
                    {notificationsCount}
                  </span>
                </p>
                <ChevronRight className={`w-[12px] h-auto aspect-square
                                          ${notificationsCount > 0 ? "fill-green" : "fill-steelGray"}`}/>
              </div>
            </button>
            <button type='button'
                    className={`flex items-center justify-between py-2 my-2 w-full hover:opacity-75 transition-opacity`}
                    onClick={() => {
                      setNavbarState({
                        ...navbarState,
                        activeTournamentsVisible: true,
                      });
                    }}>

              <div className="flex items-center gap-2">
                <TrophyIcon className={`w-[15px] h-auto aspect-square
                                      ${activeTournamentsCount > 0 ? "fill-green" : "fill-steelGray"}`}/>
                <span className={`font-compact font-normal text-base leading-4
                                ${activeTournamentsCount > 0 ? "text-green" : "text-steelGray"}`}>
                  My Tournaments
                </span>
              </div>
              <div className='flex items-center gap-x-2'>
                <p className={`${activeTournamentsCount > 0 ? "flex" : "hidden"} w-[16px] h-[16px] aspect-square rounded font-compact font-semibold
                              items-center justify-center text-black bg-green`}>
                  <span className='mt-1'>
                    {activeTournamentsCount}
                  </span>
                </p>
                <ChevronRight className={`w-[12px] h-auto aspect-square
                                          ${activeTournamentsCount > 0 ? "fill-green" : "fill-steelGray"}`}/>
              </div>
            </button>
          </>
        ) : ''}
      <div className="md:hidden">
        <MenuLink title="Home" link="/"
                  icon={<VersusIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                  handleClick={handleClick}/>
        <MenuLink title="Tournaments" link="/tournaments"
                  icon={<TrophyIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                  handleClick={handleClick}/>
        <MenuLink title="Teams" link="/teams"
                  icon={<TeamIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                  handleClick={handleClick}/>
        <MenuLink title="Players" link="/players"
                  icon={<UserIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                  handleClick={handleClick}/>

        <div className="border-t border-lightGray block my-4"></div>
      </div>

      <MenuLink title="Edit Profile" link="/my-profile" className='hidden md:flex'
                icon={<UserIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                noArrow={true}
                handleClick={() => {
                              openEditProfile();
                              handleClick();
                            }}/>
      <MenuLink title="Settings" link="/settings"
                icon={<CogIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                noArrow={true}
                handleClick={handleClick}/>
      {/* <MenuLink title="Account" link="/settings/account" className='hidden md:flex'
                icon={<LockIcon className="w-[15px] h-auto aspect-square stroke-steelGray"/>}
                handleClick={handleClick}/>
      <MenuLink title="Notification Settings" link="/settings/notifications" className='hidden md:flex'
          icon={<BellIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
          handleClick={handleClick}/> */}
      {/* <div className='opacity-50 pointer-events-none'>
        <MenuLink title="Linked Games" link="/settings" className='hidden md:flex'
                  icon={<SwordsIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                  handleClick={handleClick}/>
      </div> */}
      <MenuLink title="Contact" link="/contact" className='md:hidden'
                icon={<MegaphoneIcon className="w-[15px] h-auto aspect-square fill-steelGray"/>}
                handleClick={handleClick}/>
    </div>
  );
};

export default MenuLinks;
