import { ReactNode, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
// types
import { ApexPoiAllocation, TournamentGroup, tournamentStageNumToName, TournamentTeam } from '@src/firestore/tournaments';
// utils
import { gameToString } from '@customTypes/Games/Games';
// context
import { useTournamentContext, TournamentStage } from '@components/tournament/TournamentProvider';
import { useLeaderboardContext } from "@components/tournament/LeaderboardProvider";
import { useNotificationContext } from "@provider/NotificationProvider";
import { useAuthContext } from '@provider/AuthContextProvider';
// types
import { ApexMapPoi, ApexMapInfos } from "@customTypes/Games/ApexLegends";
// utils
import { getMapNameFromAcronym } from "@utils/Games/ApexLegends";
// libraries
import { toast } from "react-toastify";
// components
import PopoverText from "@ui/PopoverText";
import MiniTeamCard from "./MiniTeamCard";
// icons
// -- react-icons
import { FaXmark } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { MdOutlineContentCopy } from "react-icons/md";
// -- custom icons
import { Flag, TargetIcon } from "@icons/Common";
import { ApexLegendsIcon } from "@icons/Games";
import { TwitchIcon, YouTubeIcon } from "@icons/Socials";
import { DiscordSocialIcon } from "@icons/TournamentIcons";

const gameNameToMiniIcon = (gameName: string): ReactNode | string => {
  switch (gameName) {
    case 'Apex Legends':
      return (
        <ApexLegendsIcon className="w-[20px] h-auto aspect-square fill-black"/>
      );
    default:
      return '';
  }
}

interface CodeNotif {
  body: {
    tournamentId: string,
    playerCode: string,
  }
}

const OngoingViews = () => {
  const { userTeam } = useAuthContext();
  const { notifications } = useNotificationContext();

  const {
    tournament,
    tournamentStages,
    tournamentTeams,
    userTournamentState,
  } = useTournamentContext();

  const {
    leaderboardGroups
  } = useLeaderboardContext();

  const [tournamentStage, setTournamentStage] = useState<TournamentStage | undefined>(undefined);

  const [userTournamentTeam, setUserTournamentTeam] = useState<TournamentTeam | undefined>(undefined);

  const [activeGroup, setActiveGroup] = useState<TournamentGroup | undefined>(undefined);
  const [activeGroupFinished, setActiveGroupFinished] = useState<boolean>(false);
  const [activeGroupUnlocked, setActiveGroupUnlocked] = useState<boolean>(false);
  const [positionInActiveGroup, setPositionInActiveGroup] = useState<number>(0);
  const [teamAssignedPoi, setTeamAssignedPoi] = useState<ApexMapPoi | null>(null);


  const getTeamAssignedPoi = useCallback(() => {
    if (tournament && tournament.gameMode === 'ALGS Mode') {
      const activeGame = (activeGroup?.games && activeGroup.games.length >= activeGroup.activeGame) ? activeGroup.games[activeGroup.activeGame - 1] : null;
      const activeGameMapPois = activeGame ? ApexMapInfos[activeGame.map].pois : [];

      const teamPoiAllocations = userTournamentTeam?.poiAllocations;
      const activeTeamPoiNumber = (activeGroup && activeGame && teamPoiAllocations) ? teamPoiAllocations[activeGroup.id][activeGame.map as keyof ApexPoiAllocation] : null;
      setTeamAssignedPoi(activeGameMapPois.find((poi) => poi.poiNumber === activeTeamPoiNumber) ?? null);
    } else {
      setTeamAssignedPoi(null);
    }
  }, [tournament, activeGroup, userTournamentTeam]);

  useEffect(() => {
    getTeamAssignedPoi();
  }, [activeGroup, getTeamAssignedPoi]);

  const [playerCode, setPlayerCode] = useState<string>('');

  useEffect(() => {
    if (tournament) {
      setTournamentStage(tournamentStages.find((stage) => stage.stageNum === tournament.activeStage));
    }
  }, [tournament, tournamentStages]);

  useEffect(() => {
    if (userTeam) {
      setUserTournamentTeam(tournamentTeams.find((tournamentTeam) => tournamentTeam.id === userTeam.id));
    }
  }, [userTeam, tournamentTeams])

  useEffect(() => {
    if (tournamentStage && userTournamentTeam) {
      setActiveGroup(tournamentStage.groups.find((group) => userTournamentTeam.groups.some((userGroup) => userGroup.groupId === group.id)));
    }
  }, [tournament, tournamentStage, userTournamentTeam, leaderboardGroups])

  useEffect(() => {
    if (activeGroup) {
      setActiveGroupFinished(activeGroup && activeGroup.activeGame > activeGroup.gamesNum);
      if (tournament) {
        setActiveGroupUnlocked(activeGroup && !tournament.lockedStages.includes(activeGroup.stage));
      }
      if (userTournamentTeam) {
        setPositionInActiveGroup(userTournamentTeam.groups.find((userGroup) => userGroup.groupId === activeGroup.id)!.groupPosition);
      }
    }
  }, [tournament, userTournamentTeam, activeGroup])


  useEffect(() => {
    const codeNotifsForTournament = notifications.filter((notification) => notification.type === 'tournamentPlayerCode' && tournament && (notification as CodeNotif).body.tournamentId === tournament.id);
    if (codeNotifsForTournament.length > 0) {
      setPlayerCode((codeNotifsForTournament[0] as CodeNotif).body.playerCode);
    } else {
      setPlayerCode('');
    }
  }, [tournament, notifications]);

  // button state
  const [buttonStyle, setButtonStyle] = useState<string>('');
  const [buttonInfo, setButtonInfo] = useState<ReactNode>('');
  const [buttonInner, setButtonInner] = useState<ReactNode | string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [secondaryButton, setSecondaryButton] = useState<ReactNode>('');
  const [buttonOnClick, setButtonOnClick] = useState<() => void>(() => () => false);
  const [buttonPopoverText, setButtonPopoverText] = useState<string>('');

  const setButtonState = useCallback(() => {
    if (!tournament) return;

    setButtonStyle('');
    setButtonInfo('');
    setButtonInner('');
    setButtonDisabled(false);
    setSecondaryButton('');
    setButtonPopoverText('');
    setButtonOnClick(() => () => false);

    setSecondaryButton(tournament.streams.activeStream !== 'none' && tournament.streams.activeStream !== '' ? (
      <a className={`text-black w-1/8 h-[40px] w-auto aspect-square rounded-xl flex items-center justify-center
                      transition-all
                      ${tournament.streams.activeStream === 'twitch' ? "bg-purple" : "bg-white"}`}
              href={tournament.streams.activeStream === 'twitch' ? tournament.streams.twitch : tournament.streams.youtube}
              target="_blank">
        {tournament.streams.activeStream === 'twitch' ? (
          <TwitchIcon className="w-[20px] h-auto aspect-square fill-white"/>
        ) : (
          <YouTubeIcon className="w-[20px] h-auto aspect-square fill-red"/>
        )}
      </a>
    ) : '');

    if (userTournamentState.ignored) {
      setButtonPopoverText('Repeat offenses affect future eligibility');
      setButtonStyle('!bg-red/70 hover:!bg-red/70');
      setButtonInner(
        <>
          <span className="-mb-1">
            Check-In Ignored
          </span>
          <span>
            <Flag className="w-[16px] h-auto aspect-square fill-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    } else if (userTournamentState.declined) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      setButtonStyle('!bg-steelGray hover:!bg-steelGray');
      setButtonInner(
        <>
          <span className="-mb-1">
            Check-In Declined
          </span>
          <span>
            <FaXmark className="w-[16px] h-auto aspect-square fill-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    } else if (userTournamentState.eliminated) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      setButtonStyle('!opacity-50 hover:!bg-green !cursor-default');
      setButtonInner(
        <>
        <span className="-mb-1">
            Eliminated
          </span>
          <span>
            <TargetIcon className="w-[16px] h-auto aspect-square fill-black"/>
          </span>
        </>
      );
      setButtonOnClick(() => () => {
        window.open('https://youtu.be/j9V78UbdzWI?t=29', '_blank');
      });
      setButtonDisabled(false);
    } else if (userTournamentState.confirmed) {
      if (activeGroup) {
        if (activeGroupFinished) {
          setButtonInner(
            <>
            <span className="-mb-1">
                Finishing Soon
              </span>
              <span>
                <FaClock className="text-black"/>
              </span>
            </>
          );
          setButtonDisabled(true)
          setButtonInfo(
            <div className="relative w-full p-2 py-3 rounded-lg border-2 border-ebonyClay
            flex items-center justify-evenly gap-x-2 font-compact font-medium uppercase text-white">
              <p>{tournamentStageNumToName(tournament, activeGroup.stage)}</p>
            </div>
          );
        } else {
          if (activeGroupUnlocked) {
            setButtonInfo(
              <div className="relative w-full p-2 py-3 rounded-lg border-2 border-ebonyClay
              flex items-center justify-evenly gap-x-2 font-compact font-medium uppercase text-white">
                <p>{tournamentStageNumToName(tournament, activeGroup.stage)}</p>
              </div>
            );
          } else {
            setButtonInfo(
              <div className="w-full p-2 py-3 rounded-lg border-2 border-ebonyClay
                              flex flex-col gap-y-[2px] font-compact font-medium uppercase text-white">
                <div className="relative flex items-center w-full justify-center gap-x-4 h-fit">
                  <p>{activeGroup.groupName}: <em className="not-italic text-green">Game {activeGroup.activeGame}</em></p>
                  <div className="block w-[2px] h-[15px] bg-ebonyClay"></div>
                  <p>Team Slot: <em className="not-italic text-green">{positionInActiveGroup > 9 ? '' : '0'}{positionInActiveGroup}</em></p>
                </div>
                {teamAssignedPoi ? (
                  <p className="w-full flex justify-center gap-x-4">
                    <span>{getMapNameFromAcronym(teamAssignedPoi.map)}: </span>
                    <em className="not-italic text-green">{teamAssignedPoi.poiName}</em>
                  </p>
                ) : ''}
              </div>
            );
          }
          if (!playerCode) {
            setButtonInner(
              <>
              <span className="-mb-1">
                  Starting Soon
                </span>
                <span>
                  <FaClock className="text-black"/>
                </span>
              </>
            );
            setButtonDisabled(true)
          } else {
            setButtonInner(
              <>
              <span className="-mb-1">
                  Copy Player Code
                </span>
                <span>
                  <MdOutlineContentCopy/>
                </span>
              </>
            );
            setButtonOnClick(() => () => {
              toast.info('Game code copied to clipboard!')
              navigator.clipboard.writeText(playerCode);
            });
            setButtonDisabled(false);
          }
        }
      } else {
        setButtonInner(
          <>
          <span className="-mb-1">
              Groups Coming Soon
            </span>
            <span>
              {gameNameToMiniIcon(gameToString(tournament.game))}
            </span>
          </>
        );
        setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>);
      }
    } else {
      // spectator
      setButtonInner(
        <>
        <span className="-mb-1">
            In-Progress
          </span>
          <span>
            {gameNameToMiniIcon(gameToString(tournament.game))}
          </span>
        </>
      );
      setButtonDisabled(true)
    }

  }, [activeGroup, activeGroupFinished, activeGroupUnlocked, playerCode, positionInActiveGroup, tournament, userTeam, userTournamentState, teamAssignedPoi]);

  useEffect(() => {
    setButtonState();
  }, [tournament, userTournamentState, setButtonState]);

  return (
    <>
      <div className='w-1/2 h-full flex-grow flex flex-col items-center gap-y-8 justify-end'>
        {buttonInfo}
        <div className="w-full flex items-center gap-x-2">
          <Link to={tournament && tournament.discordSupport ? tournament.discordSupport : "https://discord.gg/versus-gg"}
            target="_blank"
            className="xl:hidden p-2 bg-lightGray rounded-xl h-[40px] w-auto aspect-square flex items-center justify-center">
            <DiscordSocialIcon />
          </Link>
          <div className="relative group w-5/6 flex-grow">
            {buttonPopoverText ? (
              <PopoverText right={true} visible={true} className="opacity-0 group-hover:opacity-100">
                {buttonPopoverText}
              </PopoverText>
            ) : ''}
            <button className={`font-compact font-semibold text-base !leading-4 text-black uppercase w-full h-[40px]
                            flex gap-2 items-center justify-center py-[0.8125rem] bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green opacity rounded-xl transition-opacity
                            ${buttonStyle}`}
              disabled={buttonDisabled}
              onClick={buttonOnClick}>
              {buttonInner}
            </button>
          </div>
          {secondaryButton}
        </div>
      </div>
    </>
  );

}

export default OngoingViews;
