// types
import { DropDownOption } from "@ui/DropDown";
import { apexGameModesAsDropDownOptions, apexFormatsAsDropDownOptions } from "./ApexLegends";

export enum Game {
  apex
}

export const gameToString = (game: Game) => {
  switch (game) {
    case Game.apex:
      return 'Apex Legends';
    default:
      return '';
  }
};

const gameEnumValues = Object.values(Game).filter((value) => typeof value === 'number');

export const gameTitles = gameEnumValues.map((gameEnumValue) => gameToString(gameEnumValue));

export const gamesAsDropDownOptions: DropDownOption[] = gameEnumValues.map((gameEnum) => ({
  value: gameEnum,
  label: gameToString(gameEnum)
}));

export const gameModeOptionsByGame = (game: Game): DropDownOption[] => {
  switch (game) {
    case Game.apex:
      return apexGameModesAsDropDownOptions;
    default:
      return [];
  }
};

export const gameFormatOptionsByGame = (game: Game): DropDownOption[] => {
  switch (game) {
    case Game.apex:
      return apexFormatsAsDropDownOptions;
    default:
      return [];
  }
};
