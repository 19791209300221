import { useState } from 'react'
// components
import Publish from './Publish';
import AnnounceTournament from './AnnounceTournament';
import EventStart from './EventStart';
import EventComplete from './EventComplete';
import Winnings from './Winnings';
import DangerZone from './DangerZone';

enum EventSettingsView {
  publish,
  announce,
  start,
  winnings,
  complete,
  danger
}

const EventSettings = () => {
  const [currentView, setCurrentView] = useState<EventSettingsView>(EventSettingsView.publish);

  return (
    <div className='w-full'>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase whitespace-nowrap">
          Event Settings
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            <button type='button'
              onClick={() => setCurrentView(EventSettingsView.publish)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === EventSettingsView.publish ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Publish</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(EventSettingsView.announce)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === EventSettingsView.announce ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Announce</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(EventSettingsView.start)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === EventSettingsView.start ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Event Start</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(EventSettingsView.winnings)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === EventSettingsView.winnings ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50  hover:border-steelGray'}`}>
              <span>Winnings</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(EventSettingsView.complete)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === EventSettingsView.complete ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50  hover:border-steelGray'}`}>
              <span>Event Complete</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(EventSettingsView.danger)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-red/20 text-white/85 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === EventSettingsView.danger ? 'bg-red/50 border-red/50' : 'bg-red/20 hover:bg-red/30 hover:border-red/50'}`}>
              <span>Danger Zone</span>
            </button>
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full lg:w-3/5'>
          <div className={`${currentView === EventSettingsView.publish ? '' : 'hidden'}`}>
            <Publish/>
          </div>
          <div className={`${currentView === EventSettingsView.announce ? '' : 'hidden'}`}>
            <AnnounceTournament/>
          </div>
          <div className={`${currentView === EventSettingsView.start ? '' : 'hidden'}`}>
            <EventStart/>
          </div>
          <div className={`${currentView === EventSettingsView.winnings ? '' : 'hidden'}`}>
            <Winnings/>
          </div>
          <div className={`${currentView === EventSettingsView.complete ? '' : 'hidden'}`}>
            <EventComplete/>
          </div>
          <div className={`${currentView === EventSettingsView.danger ? '' : 'hidden'}`}>
            <DangerZone/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventSettings;
