// context
import { useHeaderContext } from '@provider/HeaderProvider';
// components
import MyTournamentNotifs from "./MyTournamentNotifs";
import { ChevronRight } from '@components/common/icons/Common';
import { FaXmark } from 'react-icons/fa6';


interface IMyTournaments {
  setDropdownOverlay: (open: boolean) => void,
}

const MyTournaments: React.FC<IMyTournaments> = ({ setDropdownOverlay }) => {
  const { navbarState, setNavbarState } = useHeaderContext();

  const closeMenu = () => {
    document.body.classList.remove("overflow_hidden");
    setDropdownOverlay(false);
    setNavbarState({
      ...navbarState,
      profileMenuVisible: false,
      activeTournamentsVisible: false,
    });
  };

  const backToMainMenu = () => {
    setNavbarState({
      ...navbarState,
      profileMenuVisible: true,
      activeTournamentsVisible: false,
    });
  }

  return (
    <div className="w-full h-full sm:max-h-[82vh] flex flex-col">
      <div className='flex items-center justify-between w-full border-b border-lightGray px-4 py-2'>
        <button type='button'
                onClick={backToMainMenu}
                className="hover:opacity-75 transition-opacity p-1">
          <ChevronRight className='rotate-180 fill-steelGray w-[12px] h-auto aspect-square'/>
        </button>
        <h3 className="text-white text-sm uppercase font-bold font-wide">
          My Tournaments
        </h3>
        <button type='button'
                  onClick={closeMenu}
                  className="hover:opacity-75 transition-opacity p-1">
          <FaXmark className='fill-steelGray w-[20px] h-auto aspect-square'/>
        </button>
      </div>

      <MyTournamentNotifs closeMenu={closeMenu}/>
    </div>
  )
}

export default MyTournaments;
