import { useState, useEffect } from 'react';
// context
import { useTeamsContext } from '@components/teams/utils';
import { TeamsRegionOption, TeamsFilterOption, TeamsSortingOption } from '@components/teams/types';
// types
import { DBTeam } from '@src/firestore/teams';
// components
import TeamCard from './TeamCard';
import PaginationBar from '@ui/PaginationBar';
import SearchSortFilterBar from '@components/global/SearchSortFilterBarTeamsPlayers';
import SkeletonTeamCard from './SkeletonTeamCard';
// constants
import { TeamResultsPerPage } from '@components/teams/utils';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { VscDebugDisconnect } from 'react-icons/vsc';

const regionDropdownOptions = [
  { value: TeamsRegionOption.ALL, label: "All Regions" },
  { value: TeamsRegionOption.EMEA, label: "EMEA" },
  { value: TeamsRegionOption.NA, label: "NA" },
  { value: TeamsRegionOption.LATAM, label: "LATAM" },
  { value: TeamsRegionOption.APAC, label: "APAC" }
];

const sortingDropdownOptions = [
  { value: TeamsSortingOption.totalWinningsDesc, label: 'Winnings: High - Low'},
  { value: TeamsSortingOption.nameDesc, label: 'Name: A - Z'},
  { value: TeamsSortingOption.playerCountDesc, label: 'Size: Team Members: High - Low'},
  { value: TeamsSortingOption.trophiesWonDesc, label: 'Trophies Won: High - Low'},
  { value: TeamsSortingOption.tournamentsPlayedDesc, label: 'Tournaments Played: High - Low'},
  { value: TeamsSortingOption.apexWinNumDesc, label: 'Match Wins: High - Low'},
  { value: TeamsSortingOption.apexWinRateDesc, label: 'Match Win Rate: High - Low'},
];

const filterDropdownOptions = [
  {
    value: TeamsFilterOption.lookingForPlayers,
    label: 'Looking for Players'
  },
  {
    value: TeamsFilterOption.threeTournaments,
    label: '>3 Tournaments Played'
  },
  {
    value: TeamsFilterOption.threeMembers,
    label: '>3 Team Members'
  },
];

const MainList = () => {
  const { teams,
          initiallyLoaded,
          searchQuery,
          setSearchQuery,
          searchQueued,
          loadMoreTeams,
          loadingMore,
          totalTeamResultsCount,
          gameOption,
          setGameOption,
          sortingOption,
          setSortingOption,
          regionOption,
          setRegionOption,
          filterOptions,
          setFilterOptions,
          teamsCountByGame} = useTeamsContext();

  const [teamsInPage, setTeamsInPage] = useState<DBTeam[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [pageNum, setPageNum] = useState<number>(1);
  const [lastPageNum, setLastPageNum] = useState<number>(1);

  const changePage = async (newPageNum: number) => {
    setLoading(true);
    if (newPageNum > 0) {
      const loadedPages = teams.length / TeamResultsPerPage;
      if (newPageNum <= loadedPages) {
        setPageNum(newPageNum);
      } else if (newPageNum <= lastPageNum) {
        const amountNeeded = Math.ceil((newPageNum - loadedPages) * TeamResultsPerPage);
        await loadMoreTeams(amountNeeded);
        setPageNum(newPageNum);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const pageStartIndex = Math.max(0, ((pageNum - 1) * TeamResultsPerPage));
    const pageEndIndex = ((pageNum) * TeamResultsPerPage);
    setTeamsInPage([...teams].slice(pageStartIndex, pageEndIndex));
    setLoading(false);
  }, [teams, pageNum]);

  useEffect(() => {
    setLastPageNum(Math.ceil(totalTeamResultsCount / TeamResultsPerPage));
  }, [totalTeamResultsCount]);

  const gameCheckBoxSubtextValues = {
    'Apex Legends': `${teamsCountByGame.apexLegends} teams`,
    'Valorant': `${2} teams`,
    'Fortnite': `${2} teams`,
    'Rocket League': `${2} teams`
  }

  return (
    <div className='overflow-hidden px-3 sm:px-5 lg:px-12 custom_container pt-[calc(3rem-4px)]'>

    <SearchSortFilterBar listTitle='teams'
                          regionDropdownOptions={regionDropdownOptions}
                          searchEnabled={true}
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                          selectedRegionOption={regionOption}
                          setSelectedRegionOption={setRegionOption}
                          selectedGameOption={gameOption}
                          setSelectedGameOption={setGameOption}
                          gameCheckBoxSubtextValues={gameCheckBoxSubtextValues}
                          selectedSortingOption={sortingOption}
                          setSelectedSortingOption={setSortingOption}
                          sortingOptions={sortingDropdownOptions}
                          filterOptions={filterDropdownOptions}
                          selectedFilterOptions={filterOptions}
                          setSelectedFilterOptions={setFilterOptions}
                          initiallyLoaded={initiallyLoaded}
                          searchBarPlaceholder='Search for a team'/>

      {!loading && initiallyLoaded ? (
        <>
          {searchQueued ? (
            <div className='flex flex-col items-center justify-center min-h-[350px]'>
                <ImSpinner8 className='w-[60px] h-auto aspect-square text-green animate-spin'/>
            </div>
          ) : (
            <>
              {teamsInPage.length > 0 ? (
                <div className="w-full">
                  <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 mt-8 mb-6">
                    {teamsInPage.map((team, index) => (
                      <TeamCard key={`main-list-${team.teamName}-${team.id}-${index}`} team={team}/>
                    ))}
                    {loadingMore ? (
                      <>
                        {Array.from({length: 12}).map((_i, index) => (
                          <SkeletonTeamCard key={`main-list-loader-${index}`}/>
                        ))}
                      </>
                    ) : ''}
                  </div>
                  <PaginationBar className="my-[50px]"
                                 totalPages={lastPageNum}
                                 currentPage={pageNum}
                                 changePage={changePage}/>
                </div>
              ) : (
                <div className='flex flex-col items-center justify-center gap-y-2 min-h-[350px]'>
                  <div className='flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full'>
                    <VscDebugDisconnect className='text-white text-2xl'/>
                  </div>
                  <p className='font-wide text-white text-2xl font-semibold uppercase'>No teams found</p>
                  <p className='text-steelGray font-compact'>Teams will show here when found</p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
          {Array.from({length: TeamResultsPerPage}).map((_i, index) => (
            <SkeletonTeamCard key={`main-list-loader-${index}`}/>
          ))}
        </div>
      )}
    </div>
  )
}

export default MainList;
