import { Link, useLocation } from "react-router-dom";
// context
import { useAuthContext } from "@provider/AuthContextProvider";
// utils
import { HOME_PAGE_ACTIVE } from "@utils/Header";
// components
import { ComingSoonLinks, DesktopNavigationLinks } from "@components/common/content/HeaderHelper";
import HeaderButton from "./HeaderButton";
import GameSelector from "./GameSelector/GameSelector";
import AnnouncementBar from "./AnnouncementBar/AnnouncementBar";
// --- auth
import SignUpModal from "./Auth/SignUpModal";
import LogInModal from './Auth/LogInModal';
import ForgotPasswordModal from './Auth/ForgotPasswordModal';
// assets
import pageLogo from "@assets/images/png/homepage/page_logo.png";
import { FaShield } from "react-icons/fa6";

const Header = () => {
  const { userObj } = useAuthContext();
  const location = useLocation();

  return (
    <div className="bg-lightGray md:border-b border-ebonyClay fixed top-0 left-0 w-full z-40 h-fit">
      {/* GLOBAL ANNOUNCEMENT BAR GOES HERE */}
      <AnnouncementBar/>
      <div className="relative flex items-center custom_container mx-auto px-3 custom_xl:px-6 2xl:px-[2.25rem] h-[55px] justify-between py-[0.45rem]">
        <div className="flex items-center flex-grow">
          <Link to="/"
                className="ps-3 md:ps-0 pr-3 custom_xl:pr-6 hidden md:block">
            <img
              className="w-[9rem] md:w-[6rem] custom_xl:w-[9rem]"
              src={pageLogo}
              alt="page-logo"/>
          </Link>

          <div className="pr-3 md:px-3 custom_xl:px-6 gap-3 custom_xl:gap-6 md:border-x border-ebonyClay items-center flex">
            <GameSelector />
          </div>

          <div className="hidden md:flex items-center justify-between px-3 custom_xl:px-8 flex-grow">
            <div className="flex items-center gap-3 custom_xl:gap-8">
              {DesktopNavigationLinks.map((value, index) => {
                return (
                  <Link
                    to={value.path}
                    key={index}
                    className={`font-bold uppercase text-[0.700rem] custom_xl:text-[0.923rem] font-wide duration-300 flex items-center gap-2 -mb-[0.3rem] ${
                      value.link.toLowerCase() ==
                        location.pathname.split("/")[1].toLocaleLowerCase() ||
                      (location.pathname === HOME_PAGE_ACTIVE &&
                        value.link === "Home")
                        ? "text-green hover:text-green"
                        : "text-steelGray hover:text-slateGray"
                    }`}
                  >
                    {value.link}
                  </Link>
                );
              })}
              {ComingSoonLinks.map((value, index) => {
                return (
                  <Link
                    key={index}
                    to={value.path}
                    className={`font-bold uppercase text-[0.700rem] custom_xl:text-[0.923rem] font-wide duration-300 flex items-center gap-2 -mb-[0.3rem]
                                ${value.link.toLowerCase() == location.pathname.split("/")[1].toLocaleLowerCase()
                                ? "text-green hover:text-green"
                                : "text-steelGray hover:text-slateGray"
                                }`}>
                    {value.link}
                  </Link>
                );
              })}
            </div>
            {userObj && userObj.admin ? (
              <Link to={'/admin'}
                    className="bg-ebonyClay rounded-lg p-2 px-3 text-white flex items-center gap-x-2 justify-center font-compact uppercase
                                hover:opacity-75 transition-opacity">
                <span>
                  Admin Dashboard
                </span>
                <FaShield className="-mt-1 text-green"/>
              </Link>
            ) : ''}
          </div>
        </div>

        <Link to="/"
              className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 ps-3 md:ps-0 pr-3 custom_xl:pr-6 md:hidden">
          <img src={pageLogo}
              alt="page-logo"
              className="w-[9rem] md:w-[6rem] custom_xl:w-[9rem]"/>
        </Link>

        <HeaderButton />

        <SignUpModal/>
        <LogInModal />
        <ForgotPasswordModal/>
      </div>
    </div>
  );
};

export default Header;
