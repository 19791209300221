import ErrorImg from "../../assets/images/webp/error.webp";
const Error = () => {
  return (
    <>
      <div className="text-center text-white flex flex-col items-center m-5 min-h-screen justify-center">
        <img className="max-w-[400px] w-full" src={ErrorImg} alt="ErrorImg" />
        <h2 className="font-compact text-5xl font-bold my-5">Oops !</h2>
        <p className="font-wide text-lg font-normal">
          Error 404 - Page Not Found
        </p>
      </div>
    </>
  );
};

export default Error;
