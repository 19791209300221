import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// firebase
import { firestore } from "../../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { AuthError } from "firebase/auth";
// utils
import { debounce } from "@utils/Debounce";
import { isUsernameValid, usernameRequirementsMessage, isPasswordValid, isEmailValid } from "@utils/validations/users";
import { handleFirebaseAuthError, signup } from "@utils/AuthHelpers";
// context
import { useHeaderContext } from "@provider/HeaderProvider";
// libaries
import { toast } from "react-toastify";
// components
import { InputWrapper } from "@components/common/inputfield-wrapper/InputWrapper";
// icons
import { DiscordIcon, DisplayEyeIcon, HiddenEyeIcon } from "@icons/AuthIcons";
import { InputCross } from "@icons/Common";
import { ImSpinner8 } from "react-icons/im";
import { DiscordLogin } from "@components/common/authicons-wrapper/DiscordLogin";

interface ISignUpInput {
  closeModal: () => void,
}

const SignUpInput: React.FC<ISignUpInput> = ({closeModal}) => {
  const navigate = useNavigate();
  const { navbarState, setNavbarState } = useHeaderContext();

  const emailRef = useRef<string>('');
  const emailValidRef = useRef<boolean>(false);
  const usernameRef = useRef<string>('');
  const usernameValidRef = useRef<boolean>(false);

  const [formValid, setFormValid] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [activeField, setActiveField] = useState<string>('')

  const [email, setEmail] = useState<string>('');

  const [username, setUsername] = useState<string>('');
  const [lastTriedUsername, setLastTriedUsername] = useState<string>('');

  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  // passwords hidden state
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] = useState<boolean>(false);

  // validation state for feedback
  const [usernameFormatValid, setUsernameFormatValid] = useState<boolean>(true);
  const [usernameUnique, setUsernameUnique] = useState<boolean>(true);
  const [usernameUniqueCheckQueued, setUsernameUniqueCheckQueued] = useState<boolean>(false);

  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [emailInUse, setEmailInUse] = useState<boolean>(false);
  const [emailInUseCheckQueued, setEmailInUseCheckQueued] = useState<boolean>(false);

  const [passwordValid, setPasswordValid] = useState<boolean>(true); // true initially to prevent errors before any input
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true); // true initially to prevent errors before any input

  const validateForm = useCallback(async () => {
    const usernameFormatValid = username === '' || isUsernameValid(username);
    // validate emails
    const localEmailValid = email === '' || isEmailValid(email);
    // validate passwords
    const localPasswordValid = password === '' || isPasswordValid(password);
    const localPasswordsMatch = (password === '' || passwordConfirmation === '')|| password === passwordConfirmation;

    setUsernameFormatValid(usernameFormatValid);
    setEmailValid(localEmailValid)
    setPasswordValid(localPasswordValid);
    setPasswordsMatch(localPasswordsMatch);
  }, [email, username, password, passwordConfirmation]);

  const checkUsernnameUnique = useCallback(async () => {
    const username = usernameRef.current;
    const usernameValid = usernameValidRef.current;
    let localUsernameUnique;
    if (username !== '' && usernameValid) {
      const usersCollection = collection(firestore, 'users');
      const q = query(usersCollection, where("username", "==", username.toLowerCase()));
      localUsernameUnique = (await getDocs(q)).docs.length === 0;
      if (!localUsernameUnique) {
        setLastTriedUsername(username);
      }
    } else {
      localUsernameUnique = true;
    }
    setUsernameUniqueCheckQueued(false);
    setUsernameUnique(localUsernameUnique);
  }, []);

  const checkEmailInUse = useCallback(async () => {
    const email = emailRef.current;
    const emailValid = emailValidRef.current;
    let localEmailInUse;
    if (email !== '' && emailValid) {
      const usersCollection = collection(firestore, 'users');
      const q = query(usersCollection, where("email", "==", email));
      localEmailInUse = (await getDocs(q)).docs.length > 0;
    } else {
      localEmailInUse = false;
    }
    setEmailInUseCheckQueued(false);
    setEmailInUse(localEmailInUse);
  }, []);

  const debouncedCheckUsernameUnique = useMemo(() => debounce(checkUsernnameUnique, 500), [checkUsernnameUnique]);
  const debouncedCheckEmailInUse = useMemo(() => debounce(checkEmailInUse, 500), [checkEmailInUse]);

  useEffect(() => {
    debouncedCheckUsernameUnique();
  }, [username, debouncedCheckUsernameUnique]);

  useEffect(() => {
    debouncedCheckEmailInUse();
  }, [email, debouncedCheckEmailInUse]);

  useEffect(() => {
    emailRef.current = email;
    emailValidRef.current = emailValid;
    usernameRef.current = username;
    usernameValidRef.current = usernameFormatValid;
  }, [email, emailValid, username, usernameFormatValid])


  useEffect(() => {
    validateForm();
  }, [email, username, password, passwordConfirmation, validateForm])

  useEffect(() => {
    const localFormValid = (username !== '' && usernameFormatValid && usernameUnique)
                            && (email !== '' && emailValid && !emailInUse)
                            && (password !== '' && passwordValid && passwordsMatch)
                            && (!usernameUniqueCheckQueued && !emailInUseCheckQueued);

    setFormValid(localFormValid);
  }, [email, password, username, usernameFormatValid, usernameUnique, emailValid, emailInUse, passwordValid, passwordsMatch, usernameUniqueCheckQueued, emailInUseCheckQueued])

  const isDev = import.meta.env.DEV;
  const discordLoginLink = (isDev: boolean) => {
    if (isDev) {
      return import.meta.env.VITE_DISCORD_LOGIN_LINK_DEV
    } else {
      return import.meta.env.VITE_DISCORD_LOGIN_LINK;
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formValid) {
      try {
        await signup(
          email,
          password,
          username
        );
        toast.success("Welcome to Versus!")
        setNavbarState({
          ...navbarState,
          signUpMenuVisible: false,
          profileMenuVisible: false,
        });
        setEmail('');
        setUsername('');
        setPassword('');
        navigate("/my-profile");
      } catch (error) {
        handleFirebaseAuthError(error as AuthError)
      }
    }
    setSubmitting(false);
  };

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('.loginInput') || !target.closest('.loginInput')) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
  }, [])

  return (
    <div className="p-6">
      {/* Social media login options */}
      <div className="flex justify-between gap-4 border-b border-lightGray pb-6">
        {/* Google login option */}
        {/* <AuthIcons url="https://www.twitch.tv/" Icon={<GoogleIcon />} /> */}
        {/* Discord login option */}
        <DiscordLogin url={discordLoginLink(isDev)} Icon={<DiscordIcon />} />

        {/* Twitch login option */}

        {/* <AuthIcons url="https://www.twitch.tv/" Icon={<TwitchIcon />} /> */}
      </div>
      {/* Signup form */}

      <form onSubmit={handleSubmit}>
        {/* Username input field */}
        <div className="flex flex-col pt-6">
          <label
            className="font-compact text-base font-normal text-white tracking-[0.01rem] leading-normal text-start"
            htmlFor="signupUsername"
          >
            Username
          </label>
          <InputWrapper>
            <div className="relative w-full">
              <input
                className={`!bg-transparent w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 px-4 rounded-xl border-2 input
                    ${activeField === 'username'
                    ? !usernameFormatValid || !usernameUnique ? "border-red/90  pr-10" : "border-green pr-10"
                    : !usernameFormatValid || !usernameUnique ? "border-red/50" : "border-transparent"}`}
                onClick={() => setActiveField('username')}
                id="signupUsername"
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  setUsernameUniqueCheckQueued(true);
                }}
                placeholder="Your username here"
              />
              {activeField === 'username' ? (
                <span
                  onClick={() => {
                    setActiveField('');
                    setUsername('');
                  }}
                  className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
                >
                  <InputCross />
                </span>
              ) : ''}
            </div>
          </InputWrapper>
          <div className="mt-2">
            <p className={`text-right text-sm text-red/50 ${usernameFormatValid ? 'hidden' : ''}`}>
              {usernameRequirementsMessage}
            </p>
            <p className={`text-right text-sm text-red/50 ${usernameUnique ? 'hidden' : ''}`}>
              Username '{lastTriedUsername.toLowerCase()}' is already taken
            </p>
          </div>
        </div>

        {/* Email input field */}
        <div className="flex flex-col mt-4">
          <label
            className="font-compact text-base font-normal text-white tracking-[0.01rem] leading-normal text-start"
            htmlFor="signupEmail"
          >
            Email
          </label>
          <InputWrapper>
            <div className="relative w-full">
              <input
                className={`!bg-transparent w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 px-4 rounded-xl border-2 input
                            ${activeField === 'email'
                    ? !emailValid ? "border-red/90 pr-10" : "border-green pr-10"
                    : !emailValid ? "border-red/50" : "border-transparent"}`}
                onClick={() => setActiveField('email')}
                id="signupEmail"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailInUseCheckQueued(true);
                }}
                placeholder="Your email here"
              />
              {/* Clear button for email */}
              {activeField === 'email' ? (
                <span onClick={() => {
                  setActiveField('');
                  setEmail('');
                }}
                  className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
                >
                  <InputCross />
                </span>
              ) : ''}
            </div>
          </InputWrapper>
          <div className="mt-2">
            <p className={`text-right text-sm text-red/50 ${emailValid ? 'hidden' : ''}`}>
              Please provide a valid email
            </p>
            <p className={`text-right text-sm text-red/50 ${!emailInUse ? 'hidden' : ''}`}>
              Email already in use
            </p>
          </div>
        </div>

        {/* Password input field */}
        <div className="flex flex-col mt-4">
          <label
            className="font-compact text-base font-normal text-white tracking-[0.01rem] leading-normal text-start"
            htmlFor="signupPassword"
          >
            <div className="flex flex-col gap-1">
              <span>Password</span>
              {/* <span className="text-xs text-steelGray"></span> */}
            </div>
          </label>
          <InputWrapper>
            <div className="relative w-full">
              <input
                className={`!bg-transparent w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 px-4 rounded-xl border-2 input
                            ${activeField === 'password'
                    ? !passwordValid ? "border-red/90 pr-10" : "border-green pr-10"
                    : !passwordValid ? "border-red/50" : "border-transparent"}`}
                id="signupPassword"
                onClick={() => setActiveField('password')}
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your password here"
              />
              <div className="flex items-center justify-center absolute right-3 top-1/2 -translate-y-1/2">
                {passwordVisible ? (
                  <span
                    className="cursor-pointer inline-block p-1"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    <DisplayEyeIcon />
                  </span>
                ) : (
                  <span
                    className="cursor-pointer inline-block p-1"
                    onClick={() =>
                      setPasswordVisible(!passwordVisible)
                    }
                  >
                    <HiddenEyeIcon />
                  </span>
                )}
              </div>
            </div>
          </InputWrapper>
          <div className="mt-2">
            <p className={`text-right text-sm text-red/50 ${passwordValid ? 'hidden' : ''}`}>
              Minimum 8 characters and one capital letter
            </p>
          </div>
        </div>

        <div className="flex flex-col mt-4">
          {/* Label for the Confirm Password input field */}
          <label
            className="font-compact text-base font-normal text-white tracking-[0.01rem] leading-normal text-start"
            htmlFor="signupConfirmPassword"
          >
            Confirm Password
          </label>
          <InputWrapper>
            <div className="relative w-full">
              <input
                className={`!bg-transparent w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 px-4 rounded-xl border-2 input
                            ${activeField === 'passwordConfirmation'
                    ? !passwordsMatch ? "border-red/90 pr-10" : "border-green pr-10"
                    : !passwordsMatch ? "border-red/50" : "border-transparent"}`}
                id="signupConfirmPassword"
                onClick={() => setActiveField('passwordConfirmation')}
                type={passwordConfirmationVisible ? "text" : "password"}
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                placeholder="Confirm password here"
              />
              <div className="flex items-center justify-center absolute right-3 top-1/2 -translate-y-1/2">
                {passwordConfirmationVisible ? (
                  <span
                    className="cursor-pointer inline-block p-1"
                    onClick={() => setPasswordConfirmationVisible(!passwordConfirmationVisible)}
                  >
                    <DisplayEyeIcon />
                  </span>
                ) : (
                  <span
                    className="cursor-pointer inline-block p-1"
                    onClick={() =>
                      setPasswordConfirmationVisible(!passwordConfirmationVisible)
                    }
                  >
                    <HiddenEyeIcon />
                  </span>
                )}
              </div>
            </div>
          </InputWrapper>
          <div className="mt-2">
            <p className={`text-right text-sm text-red/50 ${passwordsMatch ? 'hidden' : ''}`}>
              Passwords do not match
            </p>
          </div>
        </div>

        {/* Message about agreeing to terms and policies */}
        <p className="font-compact text-sm font-normal tracking-[0.01rem] mt-6 text-darkGray">
          By signing up, you agree with our&nbsp;
          <Link
            to="/terms"
            onClick={closeModal}
            className="text-white cursor-pointer hover:text-green duration-200"
          >
            Terms
          </Link>
          <span className="text-white"> and </span>
          <Link
            to="/privacy-policy"
            onClick={closeModal}
            className="text-white hover:text-green duration-200"
          >
            Privacy Policy.
          </Link>
        </p>

        {/* Sign Up button */}
        <button
          type="submit"
          disabled={!formValid || submitting}
          className="font-compact w-full text-base font-medium py-4 !leading-4 rounded-xl uppercase mt-6 text-center
                     bg-green hover:bg-gorse disabled:hover:bg-green disabled:opacity-50
                     flex items-center gap-x-2 justify-center">
          <span>Sign Up</span>
          <ImSpinner8 className={`text-xl animate-spin ${!submitting ? "hidden" : ""}`} />
        </button>

        {/* Message for already having an account and link to log in */}
        <p className="font-compact text-sm font-normal tracking-[0.01rem] !leading-4 text-center mt-6 text-white">
          Already have an account?{" "}
          <span
            onClick={() => {
              setNavbarState({
                ...navbarState,
                logInMenuVisible: true,
                signUpMenuVisible: false,
              });
            }}
            className="text-green hover:opacity-8 duration-200 cursor-pointer inline-block hover:text-gorse"
          >
            Log in.
          </span>
        </p>
      </form>
    </div>
  );
};

export default SignUpInput;
