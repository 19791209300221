import { ControllerIcon, MonitorIcon, MouseIcon } from "@src/components/common/icons/Common";

export const platformStringToIcon = (platform: string, className?: string) => {
  switch (platform) {
    case 'PC':
      return (
        <MonitorIcon className={`${className} w-[10px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors`}/>
      );
    case 'Console':
      return (
        <ControllerIcon className={`${className} w-[10px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors`}/>
      );
    default:
      return '';
  }
}

export const gameInputStringToIcon = (inputName: string, className?: string) => {
  switch (inputName) {
    case 'Mouse and Keyboard':
      return (
        <MouseIcon className={`${className} w-[10px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors`}/>
      );
    case 'Controller':
      return (
        <ControllerIcon className={`${className} w-[10px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors`}/>
      );
    default:
      return '';
  }
}
