import {
  TermsContentList,
  TermsTitleList,
} from "../common/content/ConditonsHelper";
import { ConditionTitleWrapper } from "../common/condition-wrapper/ConditionWrapper";

const Terms = () => {
  return (
    <div className="xl:pb-[6.25rem]">
      <ConditionTitleWrapper
        title="Terms of use"
        conditionsList={TermsContentList}
        conditiontitleList={TermsTitleList}
      />
    </div>
  );
};

export default Terms;
