import { useState, createContext, useContext, ReactNode } from 'react'
import PoiAllocationsVisualiserModal from './PoiAllocationsVisualiserModal';

interface IALGSModeMapContext {
  mapOpen: boolean,
  setMapOpen: (open: boolean) => void,
  setGroupId: (groupId: string) => void,
  setGameNumber: (gameNumber: number) => void,
}

const defaultALGSModeMapContext = {
  mapOpen: false,
  setMapOpen: (open: boolean) => open,
  setGroupId: (groupId: string) => groupId,
  setGameNumber: (gameNumber: number) => gameNumber,
}

const ALGSModeMapContext = createContext<IALGSModeMapContext>(defaultALGSModeMapContext);

export const useALGSModeMapContext = () => {
  return useContext(ALGSModeMapContext);
}

interface IAlgsModeMap {
  children: ReactNode
}

const ALGSModeMapProvider: React.FC<IAlgsModeMap> = ({children}) => {
  const [mapOpen, setMapOpen] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<string | undefined>(undefined);
  const [gameNumber, setGameNumber] = useState<number | undefined>(undefined);


  const contextValue = {
    mapOpen,
    setMapOpen,
    setGroupId,
    setGameNumber
  }

  return (
    <ALGSModeMapContext.Provider value={contextValue}>
      <PoiAllocationsVisualiserModal isOpen={mapOpen} close={() => setMapOpen(false)}
                                     groupId={groupId} gameNumber={gameNumber}/>
      {children}
    </ALGSModeMapContext.Provider>
  )
}

export default ALGSModeMapProvider;
