import { useState } from 'react';
// types
import { LeaderboardGroup, LeaderboardTeam } from '@components/tournament/LeaderboardProvider';
// libaries
import { Disclosure } from '@headlessui/react';
// components
import LeaderboardGameData from './LeaderboardGameData';
import LeaderboardPlayerData from './LeaderboardPlayerData';

interface ILeaderboardTeamGames {
  team: LeaderboardTeam,
  group: LeaderboardGroup
}

const LeaderboardTeamGames: React.FC<ILeaderboardTeamGames> = ({team, group}) => {

  const [currentMode, setCurrentMode] = useState<'game' | 'player'>('player');

  return (
    <Disclosure.Panel className="p-6 flex flex-col gap-1 md:border-t border-lightGray">
      <div className='relative w-full h-fit'>
        <div className='absolute top-0 left-0 flex items-center gap-x-4'>
          <div className='flex items-center gap-x-2'>
            <button type="button"
                    onClick={() => setCurrentMode('game')}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentMode === 'game' ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Game Data
            </button>
            <button type="button"
                    onClick={() => setCurrentMode('player')}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentMode === 'player' ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Player Data
            </button>
          </div>
        </div>
        {currentMode === 'game' ? (
          <LeaderboardGameData team={team} group={group}/>
        ) : ''}
        {currentMode === 'player' ? (
          <LeaderboardPlayerData team={team}/>
        ) : ''}
      </div>
    </Disclosure.Panel>
  )
}


export default LeaderboardTeamGames;
