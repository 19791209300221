// assets
// Map Cover Images
import worldsEdgeCover from '@assets/images/apex/maps/WE/cover.png';
import stormPointCover from '@assets/images/apex/maps/SP/cover.png';
import olympusCover from '@assets/images/apex/maps/OL/cover.png';
import brokenMoonCover from '@assets/images/apex/maps/BM/cover.png';
import kingsCanyonCover from '@assets/images/apex/maps/KC/cover.png';
import eDistrictCover from '@assets/images/apex/maps/ED/cover.png';
// Map Images
import worldsEdge from '@assets/images/apex/maps/WE/map.png';
import stormPoint from '@assets/images/apex/maps/SP/map.png';
import eDistrict from '@assets/images/apex/maps/ED/map.png';
// Map Images with Labels
import worldsEdgePois from '@assets/images/apex/maps/WE/mapPois.png';
import stormPointPois from '@assets/images/apex/maps/SP/mapPois.png';
import eDistrictPois from '@assets/images/apex/maps/ED/mapPois.png';
// Player Card Backgrounds
import ashBackground from '@assets/images/apex/playerCardBackgrounds/ashBanner.png';
import alterBackground from '@assets/images/apex/playerCardBackgrounds/alterBanner.png';
import ballisticBackground from '@assets/images/apex/playerCardBackgrounds/ballisticBanner.png';
import bangaloreBackground from '@assets/images/apex/playerCardBackgrounds/bangaloreBanner.png';
import bloodhoundBackground from '@assets/images/apex/playerCardBackgrounds/bloodhoundBanner.png';
import catalystBackground from '@assets/images/apex/playerCardBackgrounds/catalystBanner.png';
import causticBackground from '@assets/images/apex/playerCardBackgrounds/causticBanner.png';
import conduitBackground from '@assets/images/apex/playerCardBackgrounds/conduitBanner.png';
import cryptoBackground from '@assets/images/apex/playerCardBackgrounds/cryptoBanner.png';
import fuseBackground from '@assets/images/apex/playerCardBackgrounds/fuseBanner.png';
import gibraltarBackground from '@assets/images/apex/playerCardBackgrounds/gibraltarBanner.png';
import horizonBackground from '@assets/images/apex/playerCardBackgrounds/horizonBanner.png';
import lifelineBackground from '@assets/images/apex/playerCardBackgrounds/lifelineBanner.png';
import lobaBackground from '@assets/images/apex/playerCardBackgrounds/lobaBanner.png';
import madmaggieBackground from '@assets/images/apex/playerCardBackgrounds/madmaggieBanner.png';
import mirageBackground from '@assets/images/apex/playerCardBackgrounds/mirageBanner.png';
import newcastleBackground from '@assets/images/apex/playerCardBackgrounds/newcastleBanner.png';
import octaneBackground from '@assets/images/apex/playerCardBackgrounds/octaneBanner.png';
import pathfinderBackground from '@assets/images/apex/playerCardBackgrounds/pathfinderBanner.png';
import rampartBackground from '@assets/images/apex/playerCardBackgrounds/rampartBanner.png';
import revenantBackground from '@assets/images/apex/playerCardBackgrounds/revenantBanner.png';
import seerBackground from '@assets/images/apex/playerCardBackgrounds/seerBanner.png';
import valkyrieBackground from '@assets/images/apex/playerCardBackgrounds/valkyrieBanner.png';
import wattsonBackground from '@assets/images/apex/playerCardBackgrounds/wattsonBanner.png';
import wraithBackground from '@assets/images/apex/playerCardBackgrounds/wraithBanner.png';
import vantageBackground from '@assets/images/apex/playerCardBackgrounds/vantageBanner.png';
// Legends
import ashHead from '@assets/images/apex/legends/Ash Head.png';
import alterHead from '@assets/images/apex/legends/Alter Head.png';
import ballisticHead from '@assets/images/apex/legends/Ballistic Head.png';
import bangaloreHead from '@assets/images/apex/legends/Bangalore Head.png';
import bloodhoundHead from '@assets/images/apex/legends/Bloodhound Head.png';
import catalystHead from '@assets/images/apex/legends/Catalyst Head.png';
import causticHead from '@assets/images/apex/legends/Caustic Head.png';
import conduitHead from '@assets/images/apex/legends/Conduit Head.png';
import cryptoHead from '@assets/images/apex/legends/Crypto Head.png';
import fuseHead from '@assets/images/apex/legends/Fuse Head.png';
import gibraltarHead from '@assets/images/apex/legends/Gibraltar Head.png';
import horizonHead from '@assets/images/apex/legends/Horizon Head.png';
import lifelineHead from '@assets/images/apex/legends/Lifeline Head.png';
import lobaHead from '@assets/images/apex/legends/Loba Head.png';
import maggieHead from '@assets/images/apex/legends/Mad Maggie Head.png';
import mirageHead from '@assets/images/apex/legends/Mirage Head.png';
import newcastleHead from '@assets/images/apex/legends/Newcastle Head.png';
import octaneHead from '@assets/images/apex/legends/Octane Head.png';
import pathfinderHead from '@assets/images/apex/legends/Pathfinder Head.png';
import rampartHead from '@assets/images/apex/legends/Rampart Head.png';
import revenantHead from '@assets/images/apex/legends/Revenant Head.png';
import seerHead from '@assets/images/apex/legends/Seer Head.png';
import valkyrieHead from '@assets/images/apex/legends/Valkyrie Head.png';
import wattsonHead from '@assets/images/apex/legends/Wattson Head.png';
import wraithHead from '@assets/images/apex/legends/Wraith Head.png';
import vantageHead from '@assets/images/apex/legends/Vantage Head.png';
// Weapons
import _3030White from '@assets/images/apex/weapons/3030 white.png';
import alternatorWhite from '@assets/images/apex/weapons/Alternator white.png';
import arcStarWhite from '@assets/images/apex/weapons/Arc star white.png';
import bocekWhite from '@assets/images/apex/weapons/Bocek white.png';
import carSmgWhite from '@assets/images/apex/weapons/CarSMG white.png';
import chargeRifleWhite from '@assets/images/apex/weapons/Charge Rifle white.png';
import devotionWhite from '@assets/images/apex/weapons/Devotion white.png';
import eva8White from '@assets/images/apex/weapons/Eva8 white.png';
import flatlineWhite from '@assets/images/apex/weapons/Flatline White.png';
import fragWhite from '@assets/images/apex/weapons/Frag white.png';
import g7ScoutWhite from '@assets/images/apex/weapons/G7 Scout white.png';
import havocWhite from '@assets/images/apex/weapons/Havoc white.png';
import hemlokWhite from '@assets/images/apex/weapons/Hemlok white.png';
import kraberWhite from '@assets/images/apex/weapons/Kraber white.png';
import longbowWhite from '@assets/images/apex/weapons/Longbow white.png';
import lStarWhite from '@assets/images/apex/weapons/LStar white.png';
import mastiffWhite from '@assets/images/apex/weapons/Mastiff white.png';
import meleeWhite from '@assets/images/apex/weapons/melee white.png';
import mozambiqueWhite from '@assets/images/apex/weapons/Mozambique white.png';
import nemesisWhite from '@assets/images/apex/weapons/Nemesis white.png';
import p2020White from '@assets/images/apex/weapons/P2020 white.png';
import pkWhite from '@assets/images/apex/weapons/PK white.png';
import prowlerWhite from '@assets/images/apex/weapons/Prowler white.png';
import r99White from '@assets/images/apex/weapons/R99 white.png';
import r301White from '@assets/images/apex/weapons/r301 white.png';
import rampageWhite from '@assets/images/apex/weapons/Rampage white.png';
import re45White from '@assets/images/apex/weapons/RE-45 white.png';
import sentinelWhite from '@assets/images/apex/weapons/Sentinel white.png';
import spitfireWhite from '@assets/images/apex/weapons/Spitfire white.png';
import thermiteWhite from '@assets/images/apex/weapons/Thermite white.png';
import tripletakeWhite from '@assets/images/apex/weapons/TripleTake white.png';
import voltWhite from '@assets/images/apex/weapons/Volt White.png';
import wingmanWhite from '@assets/images/apex/weapons/Wingman white.png';

interface WeaponNameToImport {
  [key: string]: string;
}

const weaponNameToImport: WeaponNameToImport = {
  "Arc Star": arcStarWhite,
  "Melee": meleeWhite,
  "Thermite": thermiteWhite,
  "Frag": fragWhite,
  "30-30 Repeater": _3030White,
  "Alternator SMG": alternatorWhite,
  "Bocek Compound Bow": bocekWhite,
  "C.A.R. SMG": carSmgWhite,
  "Charge Rifle": chargeRifleWhite,
  "Devotion LMG": devotionWhite,
  "EVA-8 Auto": eva8White,
  "G7 Scout": g7ScoutWhite,
  "HAVOC Rifle": havocWhite,
  "Hemlok Burst AR": hemlokWhite,
  "Kraber .50-Cal Sniper": kraberWhite,
  "L-STAR EMG": lStarWhite,
  "Longbow DMR": longbowWhite,
  "M600 Spitfire": spitfireWhite,
  "Mastiff Shotgun": mastiffWhite,
  "Mozambique Shotgun": mozambiqueWhite,
  "Nemesis Burst AR": nemesisWhite,
  "P2020": p2020White,
  "Peacekeeper": pkWhite,
  "Prowler Burst PDW": prowlerWhite,
  "R-301 Carbine": r301White,
  "R-99 SMG": r99White,
  "RE-45 Auto": re45White,
  "Rampage LMG": rampageWhite,
  "Sentinel": sentinelWhite,
  "Triple Take": tripletakeWhite,
  "VK-47 Flatline": flatlineWhite,
  "Volt SMG": voltWhite,
  "Wingman": wingmanWhite
};

export const getWeaponImage = (weaponName: string) => {
  return weaponNameToImport[weaponName] !== undefined ? weaponNameToImport[weaponName] : '';
};

export const apexLegendNames = [
  "Alter",
  "Ash",
  "Bangalore",
  "Ballistic",
  "Bloodhound",
  "Caustic",
  "Catalyst",
  "Conduit",
  "Crypto",
  "Fuse",
  "Gibraltar",
  "Horizon",
  "Lifeline",
  "Loba",
  "Mad Maggie",
  "Mirage",
  "Newcastle",
  "Octane",
  "Pathfinder",
  "Rampart",
  "Revenant",
  "Seer",
  "Valkyrie",
  "Vantage",
  "Wattson",
  "Wraith"
];

export const apexLegendWeaponNames = [
  "30-30 Repeater",
  "Alternator SMG",
  "Bocek Compound Bow",
  "C.A.R. SMG",
  "Charge Rifle",
  "Devotion LMG",
  "EVA-8 Auto",
  "G7 Scout",
  "HAVOC Rifle",
  "Hemlok Burst AR",
  "Kraber .50-Cal Sniper",
  "L-STAR EMG",
  "Longbow DMR",
  "M600 Spitfire",
  "Mastiff Shotgun",
  "Mozambique Shotgun",
  "Nemesis Burst AR",
  "P2020",
  "Peacekeeper",
  "Prowler Burst PDW",
  "R-301 Carbine",
  "R-99 SMG",
  "RE-45 Auto",
  "Rampage LMG",
  "Sentinel",
  "Triple Take",
  "VK-47 Flatline",
  "Volt SMG",
  "Wingman"
];


export function getLegendHeadshot(legend: string) {
  if (!legend) return '';
  
  const legendHeadshots: Record<string, string> = {
      'ash': ashHead,
      'alter': alterHead,
      'ballistic': ballisticHead,
      'bangalore': bangaloreHead,
      'bloodhound': bloodhoundHead,
      'catalyst': catalystHead,
      'caustic': causticHead,
      'conduit': conduitHead,
      'crypto': cryptoHead,
      'fuse': fuseHead,
      'gibraltar': gibraltarHead,
      'horizon': horizonHead,
      'lifeline': lifelineHead,
      'loba': lobaHead,
      'madmaggie': maggieHead,
      'mirage': mirageHead,
      'newcastle': newcastleHead,
      'octane': octaneHead,
      'pathfinder': pathfinderHead,
      'rampart': rampartHead,
      'revenant': revenantHead,
      'seer': seerHead,
      'valkyrie': valkyrieHead,
      'wattson': wattsonHead,
      'wraith': wraithHead,
      'vantage': vantageHead
  };

  const formattedLegend = legend.replace(/\s+/g, '').toLowerCase();
  if (legendHeadshots[formattedLegend]) {
      return legendHeadshots[formattedLegend];
  } else {
      return '';
  }
}

export function getLegendBackground(legend: string) {
  interface LegendBackgrounds {
    [key: string]: string;
  }

  const legendBackgrounds: LegendBackgrounds = {
      'ash': ashBackground,
      'alter': alterBackground,
      'ballistic': ballisticBackground,
      'bangalore': bangaloreBackground,
      'bloodhound': bloodhoundBackground,
      'catalyst': catalystBackground,
      'caustic': causticBackground,
      'conduit': conduitBackground,
      'crypto': cryptoBackground,
      'fuse': fuseBackground,
      'gibraltar': gibraltarBackground,
      'horizon': horizonBackground,
      'lifeline': lifelineBackground,
      'loba': lobaBackground,
      'madmaggie': madmaggieBackground,
      'mirage': mirageBackground,
      'newcastle': newcastleBackground,
      'octane': octaneBackground,
      'pathfinder': pathfinderBackground,
      'rampart': rampartBackground,
      'revenant': revenantBackground,
      'seer': seerBackground,
      'valkyrie': valkyrieBackground,
      'wattson': wattsonBackground,
      'wraith': wraithBackground,
      'vantage': vantageBackground
  };

  const formattedLegend = legend.replace(/\s+/g, '').toLowerCase();
  if (legendBackgrounds[formattedLegend]) {
      return legendBackgrounds[formattedLegend];
  } else {
      return 'Image not found';
  }
}

export const getMapCoverImageFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return worldsEdgeCover;
    case 'BM':
      return brokenMoonCover;
    case 'KC':
      return kingsCanyonCover;
    case 'SP':
      return stormPointCover;
    case 'OL':
      return olympusCover;
    case 'ED':
      return eDistrictCover;
    default:
      return '';
  }
}

export const getMapImageFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return worldsEdge;
    case 'SP':
      return stormPoint;
    case 'ED':
      return eDistrict;
    default:
      return 'https://thumbs.dreamstime.com/z/treasure-map-cartoon-doodle-hand-drawn-vector-illustration-worn-out-108132871.jpg';
  }
}

export const getMapPoiImageFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return worldsEdgePois;
    case 'SP':
      return stormPointPois;
    case 'ED':
      return eDistrictPois;
    default:
      return 'https://thumbs.dreamstime.com/z/treasure-map-cartoon-doodle-hand-drawn-vector-illustration-worn-out-108132871.jpg';
  }
}

export const getMapNameFromAcronym = (acronym: string): string => {
  switch (acronym) {
    case 'WE':
      return "World's Edge";
    case 'BM':
      return "Broken Moon";
    case 'KC':
      return "King's Canyon";
    case 'SP':
      return "Storm Point";
    case 'OL':
      return "Olympus";
    case 'ED':
      return "E-District";
    default:
      return '';
  }
}
