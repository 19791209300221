import { useRef, DragEvent, ChangeEvent, useContext } from "react";
// packages
import { toast } from "react-toastify";
// icons
import { GreenCrossIcon } from "../../common/icons/FilterIcons";
import { UploadLogoIcon } from "../../common/icons/ProfileIcons";
import { TeamInfoContext } from "../TeamInfoProvider";

// we provide a existing property on both of these, so that in the update submission we only upload NEW logos (existing = false)
export interface FileWithPreview extends File {
  preview: string,
  existing: boolean, // defaults to false
  toBeRemoved: boolean, // defaults to false
}

export interface ExistingUploadAsFile {
  name: string,
  preview: string,
  existing: boolean, // defaults to true
  toBeRemoved: boolean, // we will check on existing uploads whether we should remove an existing upload
}

const UploadLogo = () => {
  const { teamInfo, setTeamInfo } = useContext(TeamInfoContext);

  const profileImageRef = useRef<HTMLInputElement>(null);
  const bannerImageHandler = () => {
    profileImageRef.current?.click();
  };

  const handleNewAttachement = (e: ChangeEvent<HTMLInputElement> | null, file: FileWithPreview | null) => {
    let localFile: FileWithPreview;
    if (file) { // allow for the file to be passed directly incase invocation from handleFilesDrop
      localFile = file;
    } else {
      localFile = (e!.target.files as FileList)[0] as FileWithPreview;
    }
    if (!["image/jpeg", "image/png", "image/webp"].includes(localFile.type)) {
      toast.error(`You can only upload jpeg, png or webp image formats`)
    } else {
      const filePreview =  URL.createObjectURL(localFile);
      localFile.preview = filePreview;
      localFile.toBeRemoved = false;
      const newSponsorLogos = [...teamInfo.sponsorLogos, localFile];
      setTeamInfo({...teamInfo, sponsorLogos: newSponsorLogos});
    }
  }

  const handleFilesDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0] as FileWithPreview;
    handleNewAttachement(null, file);
  };

  const handleAttachementRemoval = (logoIndex: number) => {
    const localSponsorLogos = [...teamInfo.sponsorLogos];
    if (localSponsorLogos[logoIndex].existing) {
      (localSponsorLogos[logoIndex] as ExistingUploadAsFile).toBeRemoved = true;
    } else {
      localSponsorLogos.splice(logoIndex, 1);
    }
    setTeamInfo({...teamInfo, sponsorLogos: localSponsorLogos});
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div>
      <div>
        <input
          type="file"
          accept=".jpg, .jpeg, .png, .webp"
          id="my-file"
          onChange={(e) => handleNewAttachement(e, null)}
          multiple
          hidden
          ref={profileImageRef}
        />
        <div className="cursor-pointer" onClick={bannerImageHandler}>
          <div
            className="flex justify-center items-start rounded-2xl border-2 border-ebonyClay border-dashed p-6 gap-[1.875rem]"
            onDrop={handleFilesDrop}
            onDragOver={handleDragOver}
          >
            <UploadLogoIcon />
            <div className="text-center flex flex-col items-start">
              <p className="font-compact font-normal text-center text-base text-white pb-3 tracking-[1%] leading-normal">
                <span className="text-green">Upload</span> or drag your sponsors
                logo here.
              </p>
              <p className="font-compact font-normal text-start text-sm text-steelGray tracking-[1%] leading-normal line-clamp-3">
                We recommend uploading logos in PNG format, with
                dimensions of 500x500px (up to 5mb).
              </p>
            </div>
          </div>
        </div>
      </div>
      {teamInfo.sponsorLogos.length > 0 && (
        <div className="mt-4 relative overflow-hidden">
          <ul className="flex justify-between flex-col mx-auto ps-0 !pb-0 gap-2 max-h-[19.063rem] overflow-y-auto">
            {teamInfo.sponsorLogos.map((file: (FileWithPreview | ExistingUploadAsFile), index: number) => (
              <>
                {!file.toBeRemoved ? (
                  <li
                    key={`sponsor${file.name}-${index}`}
                    className="flex justify-between w-100 items-center flex-nowrap bg-lightGray p-4 rounded-xl"
                  >
                    <span className="flex gap-4 items-center">
                      <img
                        className="w-[5rem] h-[5rem] object-contain object-center"
                        src={file.preview}
                        alt={file.name || ""}
                      />
                      <p className="font-compact text-base font-normal text-steelGray sm:max-w-[12.5rem] whitespace-pre-wrap break-all line-clamp-2">
                        {file.name}
                      </p>
                    </span>
                    <button
                      className="uppercase font-compact font-medium text-base text-white tracking-[1%] flex items-center h-[1rem] leading-none gap-1 ms-2 duration-300 hover:opacity-70"
                      onClick={() => handleAttachementRemoval(index)}
                    >
                      <span className="leading-none mt-1">remove</span>{" "}
                      <GreenCrossIcon />
                    </button>
                  </li>
                ) : ''}
              </>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UploadLogo;
