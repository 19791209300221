import { useState, useEffect, createContext, useContext, ReactNode, useCallback, useRef, useMemo } from "react"
// firebase
import { firestore } from "@src/firebase";
import { query, where, collection, onSnapshot, orderBy, startAfter, limit, getDocs, Query, QueryConstraint, QueryDocumentSnapshot, getCountFromServer } from "firebase/firestore";
// context
import { useStorageContext } from "@provider/StorageProvider";
// types
import { PlayersGameOption, PlayersRegionOption, PlayersFilterOption, PlayersSortingOption } from '@components/players/types';
import { algoliaUserConverter, DBUser, userConverter } from "@src/firestore/users"
// utils
import { debounce } from "@utils/Debounce";
// libraries
import algoliasearch from "algoliasearch";
import { PlayerResultsPerPage } from "./utils";

// local types
type LastPlayer = QueryDocumentSnapshot<DBUser> | null;

interface PlayersCount {
  apexLegends: number,
  fortnite: number,
  valorant: number,
  rocketLeague: number,
}

const defaultPlayersCount = {
  apexLegends: 0,
  fortnite: 0,
  valorant: 0,
  rocketLeague: 0
};

interface IPlayersContext {
  players: DBUser[] // all tournaments
  featuredPlayers: DBUser[],
  totalPlayerResultsCount: number,
  playersCountByGame: PlayersCount,
  loadMorePlayers: (amountNeeded: number) => Promise<boolean>,
  loadingMore: boolean,
  searchQuery: string,
  setSearchQuery: (query: string) => void,
  searchQueued: boolean,
  setSearchQueued: (queued: boolean) => void,
  gameOption: PlayersGameOption,
  setGameOption: (option: PlayersGameOption) => void,
  regionOption: PlayersRegionOption,
  setRegionOption: (option: PlayersRegionOption) => void,
  sortingOption: PlayersSortingOption,
  setSortingOption: (option: PlayersSortingOption) => void,
  filterOptions: PlayersFilterOption[],
  setFilterOptions: (options: PlayersFilterOption[]) => void,
  initiallyLoaded: boolean
}

const defaultPlayersContext: IPlayersContext = {
  players: [],
  featuredPlayers: [],
  totalPlayerResultsCount: 0,
  playersCountByGame: defaultPlayersCount,
  loadMorePlayers: async () => false,
  loadingMore: false,
  searchQuery: '',
  setSearchQuery: (query: string) => query,
  searchQueued: false,
  setSearchQueued: (queued: boolean) => queued,
  gameOption: PlayersGameOption.Apex,
  setGameOption: (option: PlayersGameOption) => option,
  regionOption: PlayersRegionOption.ALL,
  setRegionOption: (option: PlayersRegionOption) => option,
  sortingOption: PlayersSortingOption.totalWinningsDesc,
  setSortingOption: (option: PlayersSortingOption) => option,
  filterOptions: [],
  setFilterOptions: (options: PlayersFilterOption[]) => options,
  initiallyLoaded: false
};

export const PlayersContext = createContext<IPlayersContext>(defaultPlayersContext);

export const usePlayersContext = () => {
  const context = useContext(PlayersContext);

  return context;
};

const sortingConstraintConstructors = {
  totalWinningsDesc: (lastPlayer: LastPlayer) => lastPlayer ? [orderBy('totalWinnings', 'desc'), orderBy('uid'), startAfter(lastPlayer)] : [orderBy('totalWinnings', 'desc'), orderBy('uid')],
  nameDesc: (lastPlayer: LastPlayer) => lastPlayer ? [orderBy('displayName'), orderBy('uid'), startAfter(lastPlayer)] : [orderBy('displayName'), orderBy('uid')],
  tournamentsPlayedDesc: (lastPlayer: LastPlayer) => lastPlayer ? [orderBy('tournamentsPlayed', 'desc'), orderBy('uid'), startAfter(lastPlayer)] : [orderBy('tournamentsPlayed', 'desc'), orderBy('uid')],
  trophiesWonDesc: (lastPlayer: LastPlayer) => lastPlayer ? [orderBy('trophyCount', 'desc'), orderBy('uid'), startAfter(lastPlayer)] : [orderBy('trophyCount', 'desc'), orderBy('uid')],
  legendMainDesc: (lastPlayer: LastPlayer) => lastPlayer ? [orderBy('apexMainLegend', 'asc'), orderBy('uid'), startAfter(lastPlayer)] : [orderBy('apexMainLegend', 'asc'), orderBy('uid')],
};


const getQueryConstraintsForSortingOption = (option: PlayersSortingOption, lastPlayer: LastPlayer): QueryConstraint[] => {
  switch (option) {
    case PlayersSortingOption.totalWinningsDesc:
      return sortingConstraintConstructors.totalWinningsDesc(lastPlayer);
    case PlayersSortingOption.nameDesc:
      return sortingConstraintConstructors.nameDesc(lastPlayer);
    case PlayersSortingOption.legendMainDesc:
      return sortingConstraintConstructors.legendMainDesc(lastPlayer);
    case PlayersSortingOption.trophiesWonDesc:
      return sortingConstraintConstructors.trophiesWonDesc(lastPlayer);
    case PlayersSortingOption.tournamentsPlayedDesc:
      return sortingConstraintConstructors.tournamentsPlayedDesc(lastPlayer);
    default:
      return sortingConstraintConstructors.totalWinningsDesc(lastPlayer);
  }
}

const getQueryConstraintsForRegionOption = (option: PlayersRegionOption): QueryConstraint[] => {
  let region: string = '';
  switch (option) {
    case PlayersRegionOption.ALL:
      region = '';
      break;
    case PlayersRegionOption.EMEA:
      region = 'EMEA';
      break;
    case PlayersRegionOption.NA:
      region = 'NA';
      break;
    case PlayersRegionOption.LATAM:
      region = 'LATAM';
      break;
    case PlayersRegionOption.APAC:
      region = 'APAC';
      break;
    default:
      region = '';
  }

  return region !== '' ? [where('region', '==', region)] : [];
};

const getQueryConstraintsForFilterOptions = (options: PlayersFilterOption[]): QueryConstraint[] => {
  const getConstraintForFilterOption = (option: PlayersFilterOption): QueryConstraint => {
    switch (option) {
      case PlayersFilterOption.pc:
        return where('apexPlatform', '==', 'PC');
      case PlayersFilterOption.console:
        return where('apexPlatform', '!=', 'PC');
      case PlayersFilterOption.mouseKeyboard:
        return where('apexInput', '==', 'Mouse and Keyboard');
      case PlayersFilterOption.controller:
        return where('apexInput', '==', 'Controller');
    }
  };

  const constraints: QueryConstraint[] = [];
  for (const option of options) {
    constraints.push(getConstraintForFilterOption(option));
  }

  return constraints;
};

interface IPlayersProvider {
  announcePlayersLoaded: (loaded: boolean) => void,
  children: ReactNode
}

const PlayersProvider: React.FC<IPlayersProvider> = ({ children, announcePlayersLoaded }) => {
  const { storage, updateStorage, storageLoaded } = useStorageContext();
  const [filterOptionsLoaded, setFilterOptionsLoaded] = useState<boolean>(false);

  const [players, setPlayers] = useState<DBUser[]>([]);
  const [totalPlayerResultsCount, setTotalPlayerResultsCount] = useState<number>(0);
  const [featuredPlayers, setFeaturedPlayers] = useState<DBUser[]>([]);
  const [playersCountByGame, setPlayersCountByGame] = useState<PlayersCount>(defaultPlayersCount);

  const searchQueryRef = useRef<string | null>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchQueued, setSearchQueued] = useState<boolean>(false);

  const [gameOption, setGameOption] = useState<PlayersGameOption>(PlayersGameOption.Apex);
  const [regionOption, setRegionOption] = useState<PlayersRegionOption>(PlayersRegionOption.ALL);
  const [sortingOption, setSortingOption] = useState<PlayersSortingOption>(PlayersSortingOption.totalWinningsDesc);

  const [filterOptions, setFilterOptions] = useState<PlayersFilterOption[]>([]);

  const [initiallyLoaded, setInitiallyLoaded] = useState<boolean>(false);

  const [lastDBUser, setLastDBUser] = useState<LastPlayer>(null);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const getFeaturedTeams = async () => {
    const usersCollection = collection(firestore, 'users').withConverter(userConverter);
    const q = query(usersCollection, orderBy('createdAt', 'desc'), where('profileComplete', '==', true), limit(3)); // more indepth freatured algo later perhaps...

    onSnapshot(q, async (snapshots) => {
      const localPlayers = snapshots.docs.map((doc) => doc.data());
      setFeaturedPlayers(localPlayers);
    })
  }

  const getPlayersCountByGame = useCallback(async () => {
    const usersCollection = collection(firestore, 'users');
    const apexCountQuery = query(usersCollection, where('profileComplete', '==', true));
    const apexCount = (await getCountFromServer(apexCountQuery)).data().count;

    const localPlayersCount = {
      apexLegends: apexCount,
      fortnite: 0,
      valorant: 0,
      rocketLeague: 0
    };

    setPlayersCountByGame(localPlayersCount);
  }, []);


  const loadFiltersFromLocalStorage = () => {
    const { playersRegionOption, playersSortingOption, playersFilterOptions } = storage;

    if (playersRegionOption !== undefined) {
      setRegionOption(playersRegionOption);
    }

    if (playersSortingOption !== undefined) {
      setSortingOption(playersSortingOption);
    }

    if (playersFilterOptions !== undefined) {
      setFilterOptions(playersFilterOptions);
    }

    setFilterOptionsLoaded(true);
  };

  const saveFiltersToLocalStorage = () => {
    updateStorage({
      playersRegionOption: regionOption,
      playersSortingOption: sortingOption,
      playersFilterOptions: filterOptions
    });
  };

  useEffect(() => {
    if (storageLoaded && !filterOptionsLoaded) {
      loadFiltersFromLocalStorage();
    }
  }, [storageLoaded, filterOptionsLoaded]);

  const getPlayers = async (reset: boolean, amountNeeded: number) => {
    const usersCollection = (collection(firestore, 'users').withConverter(userConverter));

    const queryRegionConstraints = getQueryConstraintsForRegionOption(regionOption);
    const querySortingConstraints = getQueryConstraintsForSortingOption(sortingOption, reset ? null : lastDBUser);
    const queryFilterConstraints = getQueryConstraintsForFilterOptions(filterOptions);

    const q: Query<DBUser> = query(usersCollection,
                                  ...querySortingConstraints,
                                    ...queryFilterConstraints,
                                    ...queryRegionConstraints,
                                    where('profileComplete', '==', true),
                                    limit(amountNeeded));

    const snapshots = await getDocs(q);
    const localPlayers = snapshots.docs.map((doc) => doc.data());

    const lastPlayer = snapshots.docs[snapshots.docs.length - 1];
    setLastDBUser(lastPlayer);

    if (reset) {
      setPlayers(localPlayers);
    } else {
      setPlayers(prevTeams => [...prevTeams, ...localPlayers]);
    }

    announcePlayersLoaded(true);
    setInitiallyLoaded(true);
    setLoadingMore(false);

    if (filterOptionsLoaded) saveFiltersToLocalStorage();

    return localPlayers.length > 0;
  };

  const getTotalPlayerResultsCount = useCallback(async () => {
    const usersCollection = (collection(firestore, 'users').withConverter(userConverter));

    const queryRegionConstraints = getQueryConstraintsForRegionOption(regionOption);
    const querySortingConstraints = getQueryConstraintsForSortingOption(sortingOption, null);
    const queryFilterConstraints = getQueryConstraintsForFilterOptions(filterOptions);

    const q: Query<DBUser> = query(usersCollection,
                                   ...querySortingConstraints,
                                   ...queryFilterConstraints,
                                   ...queryRegionConstraints,
                                   where('profileComplete', '==', true));
    const count = (await getCountFromServer(q)).data().count;
    setTotalPlayerResultsCount(count);
  }, [sortingOption, filterOptions, regionOption]);

  const searchPlayers = async () => {
    const localSearchQuery = searchQueryRef.current;
    if (localSearchQuery !== null && localSearchQuery !== '') {
      let players: DBUser[] = [];
      if (import.meta.env.VITE_ENV === 'production') {
        const client = algoliasearch('1EFPJPOFKM', 'fc4014a3e91daa3ee6f42d3a64dcba0e');
        const index = client.initIndex('users');
        const { hits } = await index.search(localSearchQuery);
        players = hits.map((hit) => algoliaUserConverter(hit)).filter((player) => player.profileComplete);
      } else if (import.meta.env.VITE_ENV === 'staging') {
        const client = algoliasearch('3JUVR45ABN', '0d5ba6842ac967b9bcd83eb1f908096e');
        const index = client.initIndex('users');
        const { hits } = await index.search(localSearchQuery);
        players = hits.map((hit) => algoliaUserConverter(hit)).filter((player) => player.profileComplete);
      } else {
          const usersCollection = collection(firestore, "users").withConverter(userConverter);
         const q = query( usersCollection,
                          where('displayName', '==', localSearchQuery),
                          where('profileComplete', '==', true),
                          limit(PlayerResultsPerPage));
        const querySnapshot = await getDocs(q);
        players = querySnapshot.docs.map((doc) => doc.data());
      }

      setPlayers(players);
      setTotalPlayerResultsCount(players.length);
    }

    setSearchQueued(false);
  };

  const debouncedSearchPlayers = useMemo(() => debounce(searchPlayers, 500), []);

  useEffect(() => {
    const handleSearch = async () => {
      if (searchQuery !== '') {
        setSearchQueued(true);
        debouncedSearchPlayers();
      } else if (initiallyLoaded) {
        getPlayers(true, PlayerResultsPerPage);
        getTotalPlayerResultsCount();
      }
    };

    searchQueryRef.current = searchQuery;
    handleSearch();

  }, [searchQuery, initiallyLoaded]);

  useEffect(() => {
    getPlayersCountByGame();
    getFeaturedTeams();
  }, []);

  useEffect(() => {
    if (filterOptionsLoaded) {
      setSearchQuery('');
      getPlayers(true, PlayerResultsPerPage);
      getTotalPlayerResultsCount();
      saveFiltersToLocalStorage();
    }
  }, [sortingOption, filterOptions, regionOption, gameOption, filterOptionsLoaded]);

  const loadMorePlayers = (amountNeeded: number) => {
    return getPlayers(false, amountNeeded);
  };

  const contextValue = {
    players: players,
    featuredPlayers: featuredPlayers,
    totalPlayerResultsCount: totalPlayerResultsCount,
    playersCountByGame: playersCountByGame,
    loadMorePlayers: loadMorePlayers,
    loadingMore: loadingMore,
    searchQuery,
    searchQueued,
    setSearchQueued,
    setSearchQuery,
    gameOption,
    setGameOption,
    regionOption,
    setRegionOption,
    sortingOption,
    setSortingOption,
    filterOptions,
    setFilterOptions,
    initiallyLoaded: initiallyLoaded
  }

  return (
    <PlayersContext.Provider value={contextValue}>
      {children}
    </PlayersContext.Provider>
  );
}

export default PlayersProvider;
