export const decodeBase64 = (encodedString: string): string => {
  try {
    const bytes = Uint8Array.from(atob(encodedString), c => c.charCodeAt(0));
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(bytes);
  } catch (e) {
    console.error("Failed to decode Base64 string:", e);
    return '';
  }
};

export const encodeBase64 = (plainString: string): string => {
  try {
    const encoder = new TextEncoder();
    const bytes = encoder.encode(plainString);
    const base64String = btoa(String.fromCharCode(...bytes));
    return base64String;
  } catch (e) {
    console.error("Failed to encode Base64 string:", e);
    return '';
  }
};
