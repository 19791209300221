import { useState } from "react";
// context
import { useAuthContext } from "../../../provider/AuthContextProvider";
// components
import General from "./EditViews/General/General";
import Overview from "./EditViews/Overview/Overview";
import PrizePool from "./EditViews/PrizePool/PrizePool";
import Stages from "./Groups/Stages";
import Codes from "./Codes/Codes";
import Points from "./Points/Points";
import Maps from "./Maps/Maps";
import Links from "./Links/Links";
import EventSettings from "./EventSettings/EventSettings";
import CheckList from "./CheckList/CheckList";

enum AdminSubView {
  general,
  overview,
  prizePool,
  groups,
  codes,
  points,
  maps,
  stream,
  eventSettings
}

const AdminSettings = () => {
  const { userObj } = useAuthContext();

  const [currentSubView, setCurrentSubView] = useState<AdminSubView>(AdminSubView.general);

  return userObj && userObj.admin ? (
    <div className="flex flex-col px-4 sm:px-8 lg:px-12 mt-10 min-h-[50vh]">
      <div className="flex relative items-start justify-between">
        <div className="flex items-center gap-x-6 w-full overflow-x-auto">
          <h2 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase">Settings</h2>

          <div className="flex items-center gap-x-2">
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.general)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentSubView === AdminSubView.general ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              General
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.overview)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentSubView === AdminSubView.overview ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Overview
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.prizePool)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentSubView === AdminSubView.prizePool ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Prize Pool
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.points)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentSubView === AdminSubView.points ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Point System
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.maps)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentSubView === AdminSubView.maps ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Maps
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.stream)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                              ${currentSubView === AdminSubView.stream ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Stream Links
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.groups)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentSubView === AdminSubView.groups ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Groups
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.codes)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentSubView === AdminSubView.codes ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Codes
            </button>
            <button type="button"
                    onClick={() => setCurrentSubView(AdminSubView.eventSettings)}
                    className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentSubView === AdminSubView.eventSettings ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              Event Settings
            </button>
          </div>
        </div>
        <CheckList/>
      </div>

      {/* Subviews */}
      <div className="pt-6">
        <div className={`${currentSubView === AdminSubView.general ? '' : 'hidden'}`}>
          <General/>
        </div>

        <div className={`${currentSubView === AdminSubView.overview ? '' : 'hidden'}`}>
          <Overview/>
        </div>

        <div className={`${currentSubView === AdminSubView.prizePool ? '' : 'hidden'}`}>
          <PrizePool/>
        </div>

        <div className={`${currentSubView === AdminSubView.groups ? '' : 'hidden'}`}>
          <Stages/>
        </div>

        <div className={`${currentSubView === AdminSubView.codes ? '' : 'hidden'}`}>
          <Codes/>
        </div>

        <div className={`${currentSubView === AdminSubView.points ? '' : 'hidden'}`}>
          <Points/>
        </div>

        <div className={`${currentSubView === AdminSubView.maps ? '' : 'hidden'}`}>
          <Maps/>
        </div>

        <div className={`${currentSubView === AdminSubView.stream ? '' : 'hidden'}`}>
          <Links/>
        </div>

        <div className={`${currentSubView === AdminSubView.eventSettings ? '' : 'hidden'}`}>
          <EventSettings/>
        </div>
      </div>
    </div>
  ): '';
}

export default AdminSettings;
