import { Link } from 'react-router-dom';
// types
import { DBUser } from '@src/firestore/users';
// utils
import { getWeaponImage, getLegendHeadshot, getLegendBackground } from '@utils/Games/ApexLegends';
import { getAgeFromDateOfBirth } from '@utils/Date';
// packages
import { getName } from 'country-list';
import "/node_modules/flag-icons/css/flag-icons.min.css";
// assetts
import placeholderImage from '@assets/images/placeholder/placeholder.png';
// components
import PopoverText from '@ui/PopoverText';
// icons
import { ConsoleIcon, ControllerIcon, MonitorIcon, MouseIcon } from '@components/common/icons/Common';
import { TrophyBronzeImage, TrophyGoldImage, TrophySilverImage } from '@components/common/icons/TournamentIcons';

const CardCornerChevron = ({className}: {className: string}) => (
  <svg className={`${className}`} viewBox="0 0 301 300" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M104.375 173.938L221.424 286.485C251.288 315.2 301 294.035 301 252.605V47C301 21.0426 279.957 0 254 0H47.9957C6.62477 0 -14.565 49.5911 14.0315 79.4875L104.375 173.938Z" fill="#D4FF27" className='fill-inherit'/>
  </svg>
);

const platformStringToIcon = (platform: string) => {
  switch (platform) {
    case 'PC':
      return (
        <MonitorIcon className='w-[12px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors'/>
      );
    default:
      return (
        <ConsoleIcon className='w-[18px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors mt-[2px]'/>
      );
  }
}

const gameInputStringToIcon = (inputName: string) => {
  switch (inputName) {
    case 'Mouse and Keyboard':
      return (
        <MouseIcon className='w-[12px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors'/>
      );
    case 'Controller':
      return (
        <ControllerIcon className='w-[12px] h-auto aspect-square fill-steelGray group-hover:fill-white/65  transition-colors'/>
      );
    default:
      return '';
  }
}

interface IPlayerCard {
  player: DBUser,
}

const PlayerCard: React.FC<IPlayerCard> = ({ player }) => {
  const playerTrophies = player.trophyData;

  const apexGameEntry = player.addedGames.find((game) => game.title === 'Apex Legends');
  const goldTrophies = playerTrophies.filter((trophy) => trophy.position === 1);
  const silverTrophies = playerTrophies.filter((trophy) => trophy.position === 2);
  const bronzeTrophies = playerTrophies.filter((trophy) => trophy.position === 3);

  return (
    <Link to={`/profile/${player.uid}`}
          className='relative overflow-hidden w-[90%] lg:w-full rounded-xl bg-lightBlack hover:bg-lightGray p-2 flex flex-col group flex-shrink-0 flex-grow-0 transition-all'>
      <CardCornerChevron className='absolute top-3 right-3 z-[4] w-[15px] h-auto aspect-square fill-green transition-colors'/>
      <div className="absolute top-3 right-3 bg-lightBlack group-hover:bg-lightGray z-[3] w-[100px] h-auto aspect-square rotate-45 origin-center translate-x-[70%] -translate-y-[70%]  transition-all"></div>

      <div className='h-auto min-h-[140px] aspect-[4.5] w-full transition-all relative  z-[2]'>
        <div className="relative w-full h-full px-6 bg-lightBlack group-hover:bg-lightGray transition-colors">

          <div className="absolute top-0 left-0 group-hover:-top-[2.5px] group-hover:-left-[2.5px] w-full h-full rounded-lg overflow-hidden group-hover:w-[calc(100%+5px)] group-hover:h-[calc(100%+5px)] transition-all">
            <div className="absolute top-0 left-0 z-[1] bg-black/70 group-hover:bg-black/35 w-full h-full transition-colors"></div>
            <img src={player.bannerImage !== '' ? player.bannerImage : apexGameEntry ?  getLegendBackground(apexGameEntry.mainLegend!) : placeholderImage}
                alt={`${player.displayName} banner image`}
                className='absolute z-[0] top-0 left-0 w-full h-full object-cover opacity-50'/>
          </div>

          <div className="relative flex items-center gap-x-10 h-full z-[2]">
            <div className="relative z-[2] h-fit w-fit transition-transform">
              {player.teamLogoUrl ? (
                <div className='absolute top-3 -right-3 -translate-y-1/3 !w-[35px] h-auto !aspect-square rounded-xl overflow-hidden border-[3px] border-lightBlack bg-lightGray
                                transition-transform group-hover:translate-y-[calc(-33%-5px)] group-hover:translate-x-[-5px] z-[2]'>
                  <img src={player.teamLogoUrl}
                      alt={`${player.displayName} team logo`}
                      className='w-full h-full object-cover'/>
                </div>
              ) : ''}
              <img src={player.displayImage !== '' ? player.displayImage : placeholderImage} alt={`${player.displayName} display image`}
                  className='relative z-[1] w-[100px] h-auto aspect-square rounded-full group-hover:translate-x-[-5px] bg-lightBlack group-hover:bg-lightGray border-[3px] border-lightBlack object-cover transition-all'/>
            </div>

            <div className='flex flex-col gap-y-2'>
              <h3 className='font-wide font-semibold uppercase text-white text-2xl'>{player.displayName}</h3>
              <div className='flex items-center gap-x-4'>
                <div className="relative group/nation  w-fit h-fit">
                  <div className={`opacity-75 fi fi-${player.nationality}`}></div>
                  <PopoverText className="group-hover/nation:opacity-100" visible={false}>
                    {getName(player.nationality)}
                  </PopoverText>
                </div>
                {player.showAge ? (
                  <p className="text-steelGray group-hover:text-white/65 font-compact font-semibold -mb-1 transition-colors">{getAgeFromDateOfBirth(player.dateOfBirth)}</p>
                ) : ''}
                {apexGameEntry ? (
                  <>
                    <div className="relative group/platform text-steelGray font-compact font-semibold">
                      <p>{platformStringToIcon(apexGameEntry.platform)}</p>
                      <PopoverText className="group-hover/platform:opacity-100" visible={false}>
                        {apexGameEntry.platform}
                      </PopoverText>
                    </div>
                    <p className="text-steelGray font-compact font-semibold">{gameInputStringToIcon(apexGameEntry.input)}</p>
                  </>
                ) : ''}
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className='w-full h-fit pt-3 pb-1 group-hover:pt-[10px] group-hover:pb-[2px] px-2 group-hover:px-1 sm:min-h-[90px] lg:min-h-0 flex items-center justify-between transition-all relative z-[1]'>
        <div className='flex items-center'>
          <div className='flex items-center gap-x-1'>

            <div className="relative w-fit h-fit">
              <div className={`${goldTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                              flex items-center justify-center`}>
                <p className='text-xs mt-[3px] font-compact text-white'>{goldTrophies.length}</p>
              </div>
              <TrophyGoldImage className={`w-[50px] h-auto aspect-square object-contain ${goldTrophies.length > 0 ? '' : 'opacity-50'}`}/>
            </div>

            <div className="relative w-fit h-fit">
              <div className={`${silverTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                              flex items-center justify-center`}>
                <p className='text-xs mt-[3px] font-compact text-white'>{silverTrophies.length}</p>
              </div>
              <TrophySilverImage className={`w-[50px] h-auto aspect-square object-contain ${silverTrophies.length > 0 ? '' : 'opacity-50'}`}/>
            </div>

            <div className="relative w-fit h-fit">
              <div className={`${bronzeTrophies.length > 0 ? '' : 'hidden'} absolute z-[2] top-0 right-0 w-[15px] h-[15px] aspect-square rounded-full bg-lightBlack  border border-lightGray
                              flex items-center justify-center`}>
                <p className='text-xs mt-[3px] font-compact text-white'>{bronzeTrophies.length}</p>
              </div>
              <TrophyBronzeImage className={`w-[50px] h-auto aspect-square object-contain ${bronzeTrophies.length > 0 ? '' : 'opacity-50'}`}/>
            </div>

          </div>
          <div className="h-[35px] w-[2px] bg-lightGray group-hover:bg-ebonyClay ml-2 mr-4"></div>
          <div className='mt-1'>
            <p className="font-wide font-semibold text-white uppercase">Winnings</p>
            <p className="font-wide font-semibold text-green uppercase">{player.totalWinnings > 0 ? `$${Math.round(player.totalWinnings)}` : 'N/A'}</p>
          </div>
        </div>
        {apexGameEntry ? (
          <div className='hidden sm:flex items-center gap-x-2'>
            <img src={getWeaponImage(apexGameEntry.favouriteWeapon!)} className=' h-[40px] w-auto max-w-[98px] object-contain'/>
            <img src={getLegendHeadshot(apexGameEntry.mainLegend!)} className=' w-[40px] h-auto aspect-square rounded-lg bg-lightBlack group-hover:bg-lightGray border border-lightGray group-hover:border-ebonyClay object-cover transition-colors'/>
            <img src={getLegendHeadshot(apexGameEntry.altLegend!)} className=' w-[40px] h-auto aspect-square rounded-lg bg-lightBlack group-hover:bg-lightGray border border-lightGray group-hover:border-ebonyClay  object-cover transition-colors'/>
          </div>
        ) : ''}
      </div>
      <div className="absolute bottom-0 right-0 flex flex-row gap-x-4 rotate-45 -translate-x-1/2 translate-y-1/2">
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
        <div className='hidden sm:block h-[1000px] w-[1px] bg-lightGray group-hover:bg-ebonyClay z-[0]'></div>
      </div>
    </Link>
  )
}

export default PlayerCard;
