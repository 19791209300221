import React from 'react';
// context
import { usePoiListContext } from './utils';
// types
import { ApexMapPoi } from '@customTypes/Games/ApexLegends';
// libraries
import { Draggable } from '@hello-pangea/dnd';
// icons
import { ReOrderIcon, TargetIcon } from '@components/common/icons/Common';

interface IOrderCircle {
  poiNumber: number
}

const OrderCircle: React.FC<IOrderCircle> = React.memo(({poiNumber}) => {
  const { tempPoiLocationsOrdered } = usePoiListContext();
  const index = tempPoiLocationsOrdered.indexOf(poiNumber);

  return (
    <p className='font-wide font-semibold text-white leading-4 translate-y-[1px]'>
      {index + 1}
    </p>
  );
})

interface IPoiCard {
  index: number,
  poiNumber: number,
  mapModalRef: React.MutableRefObject<HTMLDivElement | null>,
  poiLocationsForMap: ApexMapPoi[],
  cardInListDragging: boolean,
}

const PoiCard: React.FC<IPoiCard> = React.memo(({index, poiNumber, mapModalRef, poiLocationsForMap, cardInListDragging}) => {
  const poiLocation = poiLocationsForMap.find((location) => location.poiNumber === poiNumber)!;
  return (
    <Draggable draggableId={`poi-${poiNumber}`} index={index}>
      {(provided, snapshot) => {
        const mapModal = mapModalRef.current;
        const offset = {x: 0, y: 0};

        if (mapModal) {
          offset.x = mapModal.offsetLeft;
          offset.y = mapModal.offsetTop;
        }

        if (snapshot.isDragging && provided.draggableProps.style) {
          const style = provided.draggableProps.style as {left: number, top: number};

          provided.draggableProps.style = {
            ...provided.draggableProps.style,
             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
             // @ts-ignore
            left: style.left - offset.x,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            top: style.top - offset.y
          }
        }

        return (
          <div ref={provided.innerRef} {...provided.draggableProps}
              className={`w-full h-[65px] p-2 flex items-center justify-between bg-lightGray rounded-xl border-2
                         ${snapshot.isDragging ? 'border-green z-[2000]' : `${cardInListDragging ? 'pointer-events-none touch-none' : ''} border-transparent`}
                         transition-all !flex-shrink-0`}>
            <div className='flex items-center gap-x-4'>
              <div className='w-[36px] h-auto aspect-square bg-ebonyClay rounded-full
                              flex items-center justify-center'>
                <OrderCircle poiNumber={poiNumber}/>
              </div>
              <div>
                <p className='font-wide font-semibold text-white uppercase'>{poiLocation.poiName}</p>
                <p className='font-compact text-sm text-steelGray font-medium flex items-center gap-x-1 -my-1'>
                  <TargetIcon className='w-[12px] h-auto aspect-square fill-steelGray'/>
                  <span className='translate-y-[1px]'>
                    Spawn #{poiNumber}
                  </span>
                </p>
              </div>
            </div>
            <button type='button' aria-label='reposition poi location drag button'
                    {...provided.dragHandleProps}
                    className='w-[36px] h-auto aspect-square bg-ebonyClay rounded-xl
                              flex items-center justify-center'>
              <ReOrderIcon className='w-[14px] h-auto aspect-square fill-white'/>
            </button>
          </div>
        );
      }}
    </Draggable>
  );
});

export default PoiCard;
