// context
import { useAuthContext } from "@provider/AuthContextProvider";
import { useTournamentContext } from "./TournamentProvider";
// types
import { TournamentSubViews } from '@components/tournament/types';
import { Tournament, TournamentStatus } from "@src/firestore/tournaments";

interface ISubViewBar {
  currentSubView: TournamentSubViews;
  setCurrentSubView: (subView: TournamentSubViews) => void,
}

const subViewToString = (tournament: Tournament | null, subView: TournamentSubViews) => {
  const tournamentCompleted = tournament !== null && tournament.status === TournamentStatus.results;
  const matchDataPresent = tournament !== null && tournament.matchDataPresent;

  switch (subView) {
    case TournamentSubViews.overview:
      return 'Overview';
    case TournamentSubViews.prizePool:
      return 'Prize Pool';
    case TournamentSubViews.entrants:
      return 'Entrants';
    case TournamentSubViews.leaderboards:
      return matchDataPresent ? tournamentCompleted ? 'Results' : 'Leaderboards' : 'Groups';
    case TournamentSubViews.maps:
      return 'Maps';
    case TournamentSubViews.stats:
      return 'Stats';
    case TournamentSubViews.adminSettings:
      return 'Admin Settings';
    default:
      return undefined;
  }
}

const SubViewBar: React.FC<ISubViewBar> = ({ currentSubView, setCurrentSubView }) => {
  const { tournament } = useTournamentContext();
  const { userObj } = useAuthContext();
  const subViews: [string, TournamentSubViews][] = Object.entries(TournamentSubViews).filter((entry) => typeof entry[1] === 'number') as [string, number][];

  return (
    <div className="md:px-8 lg:px-12 pt-10 overflow-x-auto scroll_bar_hidden custom_container">
      <div className="flex items-center justify-between border-b border-lightGray min-w-[38.125rem] sm:min-w-[40.625rem] md:min-w-full w-fit">
        <div className="flex items-center">
          {subViews.map(([, value]) => {
            return value !== TournamentSubViews.adminSettings ? (
              <button
                key={value} // Use the enum value as key
                className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 leading-[100%] relative whitespace-nowrap
                            ${value === currentSubView ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                onClick={() => setCurrentSubView(value)}>
                {subViewToString(tournament, value)}
              </button>
            ): '';
          })}
        </div>
        <div className="flex items-center">
          {userObj && userObj.admin ? (
            <button className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 leading-[100%] relative whitespace-nowrap
                              ${currentSubView === TournamentSubViews.adminSettings ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
                    onClick={() => setCurrentSubView(TournamentSubViews.adminSettings)}>
              {subViewToString(tournament, TournamentSubViews.adminSettings)}
            </button>
          ) : ''}
        </div>
      </div>
    </div>
  );
};

export default SubViewBar;
