// firebase
import { firestore } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
// context
import { useTournamentContext } from '../../TournamentProvider';
// types
import { TournamentGame, TournamentGroup } from '../../../../firestore/tournaments';
// libraries
import { toast } from 'react-toastify';
// components
import CheckBox from '@ui/CheckBox';
// assets
import worldsEdge from '@assets/images/apex/maps/WE/cover.png';
import stormPoint from '@assets/images/apex/maps/SP/cover.png';
import olympus from '@assets/images/apex/maps/OL/cover.png';
import brokenMoon from '@assets/images/apex/maps/BM/cover.png';
import kingsCanyon from '@assets/images/apex/maps/KC/cover.png';
import eDistrict from '@assets/images/apex/maps/ED/cover.png';

interface IGameMaps {
  game: TournamentGame,
  group: TournamentGroup,
}

const GameMaps: React.FC<IGameMaps> = ({game, group}) => {
  const { tournament } = useTournamentContext();
  const gameCompleted = game.completed;

  const handleMapChange = async (map: string) => {
    if (tournament) {
      try {
        const groupGameRef = doc(firestore, 'tournaments', tournament.id, 'groups', group.id, 'games', game.id);
        await updateDoc(groupGameRef, {
          map: map
        });
        toast.success('Game map updated')
      } catch (err) {
        console.error(err);
        toast.error('Error updating game map')
      }
    }
  }

  return (
    <div className='w-full flex flex-col gap-y-2 mt-6 font-compact font-medium'>
      <div className='w-full flex flex-wrap gap-x-6 gap-y-8'>
        <button type='button'
                disabled={gameCompleted}
                onClick={() => handleMapChange('WE')}
                className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4
                           ${gameCompleted ? 'opacity-50' : ''}`}>
          <img src={worldsEdge} alt="World's Edge Apex Map"
               className={`h-4/5 rounded-lg object-cover ${game.map === 'WE' ? 'opacity-100' : 'opacity-50'} ${gameCompleted ? '' : 'group-hover:!opacity-100'} transition-opacity`}/>
          <div className='flex items-center h-1/5 w-full justify-between'>
            <div className='flex flex-col items-start'>
              <p className={`font-wide ${game.map === 'WE' ? 'text-white' : 'text-white/75'} uppercase`}>World's Edge</p>
              <p className='text-steelGray'>WE</p>
            </div>
            <CheckBox selected={game.map === 'WE'} setSelected={() => false} asDiv={true}/>
          </div>
        </button>

        <button type='button'
                disabled={gameCompleted}
                onClick={() => handleMapChange('SP')}
                className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4
                           ${gameCompleted ? 'opacity-50' : ''}`}>
          <img src={stormPoint} alt="World's Edge Apex Map"
               className={`h-4/5 rounded-lg object-cover ${game.map === 'SP' ? 'opacity-100' : 'opacity-50'} ${gameCompleted ? '' : 'group-hover:!opacity-100'} transition-opacity`}/>
          <div className='flex items-center h-1/5 w-full justify-between'>
            <div className='flex flex-col items-start'>
              <p className={`font-wide ${game.map === 'SP' ? 'text-white' : 'text-white/75'} uppercase`}>Storm Point</p>
              <p className='text-steelGray'>SP</p>
            </div>
            <CheckBox selected={game.map === 'SP'} setSelected={() => false} asDiv={true}/>
          </div>
        </button>

        <button type='button'
                disabled={gameCompleted}
                onClick={() => handleMapChange('OL')}
                className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4
                           ${gameCompleted ? 'opacity-50' : ''}`}>
          <img src={olympus} alt="World's Edge Apex Map"
               className={`h-4/5 rounded-lg object-cover ${game.map === 'OL' ? 'opacity-100' : 'opacity-50'} ${gameCompleted ? '' : 'group-hover:!opacity-100'} transition-opacity`}/>
          <div className='flex items-center h-1/5 w-full justify-between'>
            <div className='flex flex-col items-start'>
              <p className={`font-wide ${game.map === 'OL' ? 'text-white' : 'text-white/75'} uppercase`}>Olympus</p>
              <p className='text-steelGray'>OL</p>
            </div>
            <CheckBox selected={game.map === 'OL'} setSelected={() => false} asDiv={true}/>
          </div>
        </button>

        <button type='button'
                disabled={gameCompleted}
                onClick={() => handleMapChange('BM')}
                className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4
                           ${gameCompleted ? 'opacity-50' : ''}`}>
          <img src={brokenMoon} alt="World's Edge Apex Map"
               className={`h-4/5 rounded-lg object-cover ${game.map === 'BM' ? 'opacity-100' : 'opacity-50'} ${gameCompleted ? '' : 'group-hover:!opacity-100'} transition-opacity`}/>
          <div className='flex items-center h-1/5 w-full justify-between'>
            <div className='flex flex-col items-start'>
              <p className={`font-wide ${game.map === 'BM' ? 'text-white' : 'text-white/75'} uppercase`}>Broken Moon</p>
              <p className='text-steelGray'>BM</p>
            </div>
            <CheckBox selected={game.map === 'BM'} setSelected={() => false} asDiv={true}/>
          </div>
        </button>

        <button type='button'
                disabled={gameCompleted}
                onClick={() => handleMapChange('KC')}
                className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4
                           ${gameCompleted ? 'opacity-50' : ''}`}>
          <img src={kingsCanyon} alt="World's Edge Apex Map"
               className={`h-4/5 rounded-lg object-cover ${game.map === 'KC' ? 'opacity-100' : 'opacity-50'} ${gameCompleted ? '' : 'group-hover:!opacity-100'} transition-opacity`}/>
          <div className='flex items-center h-1/5 w-full justify-between'>
            <div className='flex flex-col items-start'>
              <p className={`font-wide ${game.map === 'KC' ? 'text-white' : 'text-white/75'} uppercase`}>King's Canyon</p>
              <p className='text-steelGray'>KC</p>
            </div>
            <CheckBox selected={game.map === 'KC'} setSelected={() => false} asDiv={true}/>
          </div>
        </button>

        <button type='button'
                disabled={gameCompleted}
                onClick={() => handleMapChange('ED')}
                className={`group w3/4 sm:w-[250px] md:w-[300px] lg:w-[250px] h-auto aspect-[1/1.2] rounded-xl bg-lightGray p-4
                           ${gameCompleted ? 'opacity-50' : ''}`}>
          <img src={eDistrict} alt="World's Edge Apex Map"
               className={`h-4/5 rounded-lg object-cover ${game.map === 'ED' ? 'opacity-100' : 'opacity-50'} ${gameCompleted ? '' : 'group-hover:!opacity-100'} transition-opacity`}/>
          <div className='flex items-center h-1/5 w-full justify-between'>
            <div className='flex flex-col items-start'>
              <p className={`font-wide ${game.map === 'ED' ? 'text-white' : 'text-white/75'} uppercase`}>E-District</p>
              <p className='text-steelGray'>ED</p>
            </div>
            <CheckBox selected={game.map === 'ED'} setSelected={() => false} asDiv={true}/>
          </div>
        </button>
      </div>
    </div>
  )
}

export default GameMaps;
