import { ReactNode, useEffect, useRef, useState, useContext, createContext } from "react";

interface IImageShowCaseContext {
  showCaseImage: string,
  setShowCaseImage: (image: string) => void,
}

const ImageShowCaseContext = createContext<IImageShowCaseContext>({
  showCaseImage: '',
  setShowCaseImage: (image: string) => image,
});

export const useImageShowCaseContext = () => {
  return useContext(ImageShowCaseContext);
}

interface IImageShowCaseProvider {
  children: ReactNode
}

const ImageShowCaseProvider: React.FC<IImageShowCaseProvider> = ({children}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [showCaseImage, setShowCaseImage] = useState<string>('');

  const closeImageOnOutsideClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (imageRef.current && !imageRef.current.contains(e.target as Node)) {
      setShowCaseImage('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeImageOnOutsideClick);

    return () => document.removeEventListener('click', closeImageOnOutsideClick);
  }, []);

  const contextValue = {
    showCaseImage: showCaseImage,
    setShowCaseImage: setShowCaseImage
  }

  return (
    <ImageShowCaseContext.Provider value={contextValue}>
      <div className={`!fixed !top-0 !left-0 w-[100dvw] h-[100dvh] p-4 bg-black/50 backdrop-blur-sm z-[100]
                      ${showCaseImage ? '' : 'opacity-0'} pointer-events-none touch-none transition-opacity
                      flex items-center justify-center`}>
        <img ref={imageRef} id="mapImage"
             src={showCaseImage}
             className={`${showCaseImage ? '' : 'hidden'} max-w-[800px] max-h-[75vh] w-[80vw] h-auto aspect-square object-contain`}/>
      </div>
      {children}
    </ImageShowCaseContext.Provider>
  )
}

export default ImageShowCaseProvider;
