import { Link } from "react-router-dom";
// context
import { useSettingsContext } from "./SettingsProvider";
// utils
import { logout } from "../../utils/AuthHelpers";
// icons
import { SignOutIcon } from "../common/icons/Header";
import { ProfileDropdownSecurityIcon, ProfileDropdownMyGamesIcon, SideBarRightArrow } from "../common/icons/Settings";
import { BellIcon } from "../common/icons/Common";

interface ISettingsSidebar {
  activePage: string,
}

const SettingsSidebar: React.FC<ISettingsSidebar> = ({activePage}) => {
  const { setSidebarOpen } = useSettingsContext();

  return (
    <>
      {/* SIDEBAR COMPONENT */}
      <div className={`lg:bg-lightBlack bg-black  max-lg:h-full max-lg:w-full px-4 lg:px-3 pb-3 pt-1 lg:rounded-xl w-[19.875rem] lg:!sticky lg:top-[6.375rem] start-0`}>
        <h2 className="font-wide font-bold mt-2 py-4 lg:hidden text-base !leading-4 uppercase text-white ">
          Settings
        </h2>
        <div className="max-lg:bg-lightGray rounded-xl overflow-hidden">

          <Link to={`/settings/account`}
                className={`group setting_sidebar`}>
            <div className={`flex items-center justify-between gap-2 group-hover:bg-lightGray px-4 py-[0.875rem] lg:my-2 duration-300 lg:rounded-lg cursor-pointer ${
                             activePage === 'account' ? "lg:bg-lightGray" : ""} max-lg:border-ebonyClay max-lg:border-y`}
                 onClick={() => {
                  setSidebarOpen(false);
                 }}>
              <div className="flex items-center gap-2.5">
                <span
                  className={`settings_icon duration-300 mb-1 ${activePage === 'account' ? "settings_icons": ""}`}>
                  <ProfileDropdownSecurityIcon />
                </span>
                <p
                  className={`font-compact font-normal !tracking-[0.01rem] text-base !leading-4 duration-300 group-hover:text-white
                              ${activePage === 'account' ? "text-white" : "text-steelGray"}`}>
                  Account
                </p>
              </div>
              <span
                className={`block lg:hidden setting_sidebar
                            ${activePage === 'account' ? "setting_sidebar_icon" : ""}`}>
                <SideBarRightArrow />
              </span>
            </div>
          </Link>


          <Link to={`/settings/notifications`}
                className={`group setting_sidebar`}>
            <div className={`flex items-center justify-between gap-2 group-hover:bg-lightGray px-4 py-[0.875rem] lg:my-2 duration-300 lg:rounded-lg cursor-pointer ${
                             activePage === 'notifications' ? "lg:bg-lightGray" : ""} max-lg:border-ebonyClay max-lg:border-y`}
                 onClick={() => {
                  setSidebarOpen(false);
                 }}>
              <div className="flex items-center gap-2.5">
                <BellIcon className={`w-[16px] h-auto aspect-square
                                      ${activePage === 'notifications' ? "fill-white": "fill-steelGray group-hover:fill-white"} transition-colors`} />
                <p
                  className={`font-compact font-normal !tracking-[0.01rem] text-base !leading-4 duration-300 group-hover:text-white
                              ${activePage === 'notifications' ? "text-white" : "text-steelGray"}`}>
                  Notifications
                </p>
              </div>
              <span
                className={`block lg:hidden setting_sidebar
                            ${activePage === 'notifications' ? "setting_sidebar_icon" : ""}`}>
                <SideBarRightArrow />
              </span>
            </div>
          </Link>

          <Link to={`/settings/games`}
                className={`group setting_sidebar pointer-events-none opacity-50`}>
            <div className={`flex items-center justify-between gap-2 group-hover:bg-lightGray px-4 py-[0.875rem] lg:my-2 duration-300 lg:rounded-lg cursor-pointer ${
                             activePage === 'games' ? "lg:bg-lightGray" : ""}`}
                 onClick={() => {
                  setSidebarOpen(false);
                 }}>
              <div className="flex items-center gap-2.5">
                <span
                  className={`settings_icon duration-300 mb-1 ${activePage === 'games' ? "settings_icons": ""}`}>
                  <ProfileDropdownMyGamesIcon />
                </span>
                <p
                  className={`font-compact font-normal !tracking-[0.01rem] text-base !leading-4 duration-300 group-hover:text-white
                              ${activePage === 'games' ? "text-white" : "text-steelGray"}`}>
                  Linked Games
                </p>
              </div>
              <span
                className={`block lg:hidden setting_sidebar
                            ${activePage === 'games' ? "setting_sidebar_icon" : ""}`}>
                <SideBarRightArrow />
              </span>
            </div>
          </Link>
        </div>
        <span className="border-t border-lightGray lg:flex w-full mt-1 pt-1 hidden"></span>
        <div className="px-4 hover:bg-lightGray max-lg:bg-ebonyClay hover:rounded-xl duration-300 max-lg:rounded-xl block mt-2 max-lg:mt-4">
          <Link to="/"
                onClick={logout}
                className="flex items-center gap-2 py-4">
            <SignOutIcon className="w-[15px] h-auto aspect-square !fill-red/50"/>
            <p className="font-compact text-red/50 font-normal text-base leading-4 mt-0.5">
              Sign out
            </p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SettingsSidebar;
