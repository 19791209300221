import { useEffect, useState } from 'react'
// context
import { useTournamentContext } from '../../TournamentProvider';
import { useTournamentAdminContext } from '../TournamentAdminProvider';
// types
import { tournamentStageNumToName, checkIfStageActive } from "../../../../firestore/tournaments";
// components
import Declined from './Declined';
import Ignored from './Ignored';
// icons
import { FaFlag } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";

import Stage from './Stage';

const Stages = () => {
  const { tournament } = useTournamentContext();
  const { stages } = useTournamentAdminContext();

  const [currentStageOrView, setCurrentStageOrView] = useState<number | string>(0); // either the group's id, or strings: 'unassigned'  | 'declined' | 'ignored'

  useEffect(() => {
    if (tournament && tournament.activeStage) {
      setCurrentStageOrView(tournament.activeStage);
    }
  }, [tournament])

  return (
    <div>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase">
          Groups
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            {stages.map((stage) => (
              <button key={`stage-button-${stage.stageNum}`} type="button"
                onClick={() => setCurrentStageOrView(stage.stageNum)}
                className={`p-1 px-2 border-[1px] border-lightGray font-medium rounded-full transition-colors whitespace-nowrap
                ${currentStageOrView === stage.stageNum ? `bg-lightGray ${checkIfStageActive(stage.stageNum, tournament) ? 'text-green' : 'text-white/85'}`
                : `hover:bg-lightGray/20 ${checkIfStageActive(stage.stageNum, tournament) ? 'text-green/70 hover:text-green' : 'text-steelGray hover:border-steelGray'}`}`}>
                {tournamentStageNumToName(tournament, stage.stageNum)}
              </button>
            ))}
            <div className='h-[18px] w-[2px] rounded bg-lightGray'></div>
            <button type="button"
              onClick={() => setCurrentStageOrView('declined')}
              className={`flex items-center gap-x-2 p-1 px-4 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors
                                    ${currentStageOrView === 'declined' ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
              <span> Declined</span>
              <IoCloseSharp className='text-xl' />
            </button>
            <div className='h-[18px] w-[2px] rounded bg-lightGray'></div>
            <button type="button"
              onClick={() => setCurrentStageOrView('ignored')}
              className={`flex items-center gap-x-2 p-1 px-4 bg-red/20 text-black font-medium rounded-full transition-colors
                                  ${currentStageOrView === 'ignored' ? "bg-red/40 text-white" : "hover:bg-red/40"}`}>
              <span>Ignored</span>
              <FaFlag className='text-lg' />
            </button>
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full'>
          <div>
            {stages.map((stage) => (
              <div key={`stage-view-${stage.stageNum}`}>
                {currentStageOrView === stage.stageNum ? (
                  <Stage stage={stage}/>
                ) : ''}
              </div>
            ))}
            <div className='w-full lg:w-1/2'>
              {currentStageOrView === 'declined' ? (
                <Declined/>
              ) : ''}
              {currentStageOrView === 'ignored' ? (
                <Ignored/>
              ) : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stages;
