import { useEffect, useState } from "react";
// context
import { useTournamentContext } from "../../TournamentProvider";
import { useTournamentAdminContext } from "../TournamentAdminProvider";
// types
import { TournamentStatus } from "../../../../firestore/tournaments";
// components
import CheckListCountdown from "./CheckListCountdown";
import CheckListToggle from "./CheckListToggle";
// icons
import { FaCheck } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { FaLink } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlinePassword } from "react-icons/md";
import { AiOutlineStock } from "react-icons/ai";

const CheckList = () => {
  const { tournament } = useTournamentContext();
  const { groupGames } = useTournamentAdminContext();

  const [checkListOpen, setCheckListOpen] = useState<boolean>(false);

  const groupsLocked = tournament && tournament.lockedStages.includes(tournament.activeStage);
  const [codesPopulated, setCodesPopulated] = useState<boolean>(false);
  const [mapsChosen, setMapsChosen] = useState<boolean>(false);
  const pointsPopulated = tournament && tournament.pointSystem !== '';
  const linksPopulated = tournament && (tournament.streams.activeStream === 'none' || tournament.streams.activeStream !== '') && (tournament.vods.activeVod === 'none' || tournament.vods.activeVod !== '');

  useEffect(() => {
    const checkGameCodesPopulated = () => {
      if (groupGames) {
        let populated = true;
        Object.values(groupGames).forEach((groupGames) => {
          groupGames.forEach((game) => {
            if (game.adminCode == '' || game.playerCode == '' || game.statsToken == '') populated = false;
          })
        })
        return populated;
      } else {
        return false;
      }
    }

    const checkGameMapsPopulated = () => {
      if (groupGames) {
        let populated = true;
        Object.values(groupGames).forEach((groupGames) => {
          groupGames.forEach((game) => {
            if (game.map === '') populated = false;
          })
        })
        return populated;
      } else {
        return false;
      }
    }
    setMapsChosen(checkGameMapsPopulated());
    setCodesPopulated(checkGameCodesPopulated());
  }, [groupGames])

  return tournament ? (
    <>
      <div className={`${checkListOpen ? '' : 'hidden'} fixed top-0 left-0 z-[10] w-[100vw] h-[100vh] bg-black/20 backdrop-blur`}></div>
      <div className={`rounded-xl top-0 right-0 w-[200px] h-fit font-wide text-steelGray bg-black
                      ${checkListOpen ? 'z-[50] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex border border-steelGray p-5 w-[300px]' : 'absolute hidden'} lg:flex flex-col gap-y-2`}>
        <p className="flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray text-white">
          <span>General</span>
          <FaCheck className="text-xl" />
        </p>
        <p className="flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray text-white">
          <span>Overview</span>
          <FaCheck className="text-xl" />
        </p>
        <p className="flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray text-white">
          <span>Prize Pool</span>
          <FaCheck className="text-xl" />
        </p>
        <p className={`flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray ${pointsPopulated ? 'text-white' : 'text-steelGray'}`}>
          <span>Points System</span>
          {pointsPopulated ? (
            <FaCheck className="text-xl" />
          ) : (
            <AiOutlineStock className="text-xl" />
          )}
        </p>
        <p className={`flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray ${mapsChosen ? 'text-white' : 'text-steelGray'}`}>
          <span>Maps</span>
          {mapsChosen ? (
            <FaCheck className="text-xl" />
          ) : (
            <FaLocationDot className="text-xl" />
          )}
        </p>
        <p className={`flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray ${linksPopulated ? 'text-white' : 'text-steelGray'}`}>
          <span>Stream Links</span>
          {linksPopulated ? (
            <FaCheck className="text-xl" />
          ) : (
            <FaLink className="text-xl" />
          )}
        </p>
        <p className={`flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray ${groupsLocked ? 'text-white' : 'text-steelGray'}`}>
          <span>Groups</span>
          {groupsLocked ? (
            <FaCheck className="text-xl" />
          ) : (
            <FaUserGroup className="text-xl" />
          )}
        </p>
        <p className={`flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact border-lightGray text-steelGray
                      ${!codesPopulated && tournament && tournament.status === TournamentStatus.ongoing ? 'border-red !text-red' : ''}
                      ${codesPopulated ? 'text-white' : ''}`}>
          <span>Codes</span>
          {codesPopulated ? (
            <FaCheck className="text-xl" />
          ) : (
            <MdOutlinePassword className="text-xl" />
          )}
        </p>
        <CheckListCountdown status={tournament.status}/>
      </div>
      <CheckListToggle checkListOpen={checkListOpen} setCheckListOpen={setCheckListOpen}/>
    </>
  ): '';
}

export default CheckList;
