import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
// firebase
import { firestore } from '@src/firebase';
import { doc, onSnapshot, Timestamp, updateDoc } from 'firebase/firestore';
// context
import { useAuthContext } from '@provider/AuthContextProvider';
// libraries
import { toast } from 'react-toastify';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
// utils
import { decodeBase64, encodeBase64 } from '@utils/encodingDecoding';
// components
import CheckBox from '@ui/CheckBox';
import Input from '@ui/Input';
import { FaInfo } from 'react-icons/fa';
import { ImSpinner8 } from 'react-icons/im';

export interface AnnouncementRaw {
  data: string,
  active: boolean,
  dismissable: boolean,
  timeout: Timestamp | null
}

export interface AnnouncementConverted {
  data: string,
  active: boolean,
  dismissable: boolean,
  timeout: Date | null
}

const Announcements = () => {
  const { userObj } = useAuthContext();

  const [announcementDoc, setAnnouncementDoc] = useState<AnnouncementConverted | null>(null);

  const [announcementText, setAnnouncementText] = useState<string | null>(null);
  const [announcementTimeout, setAnnouncementTimeout] = useState<Date | null>(null);
  const [announcementDismissable, setAnnouncementDismissable] = useState<boolean>(false);
  const [announcementActive, setAnnouncementActive] = useState<boolean>(false);

  const [changesMade, setChangesMade] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSaveChanges = async () => {
    setSubmitting(true);
    try {
      if (userObj && userObj.admin) {
        const announcementDoc = doc(firestore, 'globals', 'announcement');

        const encodedAnnouncement = encodeBase64(announcementText ?? '');

        const updatePromise = updateDoc(announcementDoc, {
          active: announcementActive,
          data: encodedAnnouncement,
          dismissable: announcementDismissable,
          timeout: announcementTimeout
        })

        toast.promise(updatePromise, {
          pending: 'Updating announcement',
          success: 'Announcement Updated',
          error: 'Error updating announcement'
        })

        await updatePromise;
      }
    } catch (err) {
      console.error(err);
    }
    setSubmitting(false);
  }

  useEffect(() => {
    if (announcementDoc && (announcementText !== announcementDoc.data
        || announcementActive !== announcementDoc.active
        || announcementTimeout !== announcementDoc.timeout
        || announcementDismissable !== announcementDoc.dismissable)) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [announcementDoc, announcementText, announcementActive, announcementTimeout, announcementDismissable])

  const getAnnouncement = useCallback(() => {
    const announcementDocRef = doc(firestore, 'globals', 'announcement');

    const unsubscribe = onSnapshot(announcementDocRef, (snapshot) => {
      const announcementDoc = snapshot.data() as AnnouncementRaw | undefined;

      if (announcementDoc) {
        const announcementConverted: AnnouncementConverted = {
          ...announcementDoc,
          data: decodeBase64(announcementDoc.data),
          timeout: announcementDoc.timeout ? new Date(announcementDoc.timeout.seconds * 1000) : null,
        }

        const announcementEncoded = announcementDoc.data;
        const decodedAnnouncement: string = decodeBase64(announcementEncoded);

        setAnnouncementDoc(announcementConverted)

        setAnnouncementText(decodedAnnouncement);
        setAnnouncementActive(announcementConverted.active);
        setAnnouncementDismissable(announcementConverted.dismissable);
        setAnnouncementTimeout(announcementConverted.timeout);
      } else {
        setAnnouncementDoc(null);

        setAnnouncementText('');
        setAnnouncementActive(false);
        setAnnouncementDismissable(false);
        setAnnouncementTimeout(null);
      }
    })

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = getAnnouncement();

    return () => unsubscribe();
  }, [getAnnouncement]);

  return (
    <div className='relative z-[2] p-2 px-3 rounded-lg bg-lightBlack border border-lightGray min-h-[300px] h-fit'>
      <div className='flex flex-col gap-y-2 items-center justify-between h-full'>
        <h3 className='font-wide text-white uppercase w-fit font-semibold'>Site Announcement</h3>

        <button type="button"
                aria-label='Toggle site announcement'
                onClick={() => setAnnouncementActive(!announcementActive)}
                className={`flex items-center gap-x-2 p-2 px-3 bg-lightGray rounded-lg hover:opacity-75 transition-opacity
                           ${announcementActive ? 'text-green' : 'text-steelGray'} font-compact text-sm uppercase`}>
          <span className='translate-y-[2px]'>Active</span>
          <CheckBox selected={announcementActive} setSelected={setAnnouncementActive} asDiv={true} small={true}/>
        </button>

        <Link to="https://www.markdownguide.org/cheat-sheet/" target='_blank'
              className="text-xs text-steelGray w-fit self-start flex items-center gap-x-1 hover:opacity-75 transition-opacity">
          <FaInfo/>
          <span className='underline'>Markdown Syntax</span>
        </Link>

        <Input value={announcementText ?? ''} onChange={(newValue) => setAnnouncementText(newValue)} textArea={true} black={true}/>

        <div className="flex items-center justify-start w-full gap-x-2">
          <button type="button"
                  aria-label='Toggle site announcement dismissable'
                  onClick={() => setAnnouncementDismissable(!announcementDismissable)}
                  className={`flex items-center gap-x-2 py-[6px] px-2 bg-lightGray rounded-lg hover:opacity-75 transition-opacity
                              font-compact text-sm self-start
                              ${announcementDismissable ? 'text-white' : 'text-steelGray '}`}>
            <span className='translate-y-[2px]'>Dismissable</span>
            <CheckBox selected={announcementDismissable} setSelected={setAnnouncementDismissable} asDiv={true} small={true}/>
          </button>

          <button type="button"
                  aria-label='Toggle site timeout'
                  onClick={() => setAnnouncementTimeout(announcementTimeout ? null : new Date(new Date().getTime() + 7_200_000))}
                  className={`flex items-center gap-x-2 py-[6px] px-2 bg-lightGray rounded-lg hover:opacity-75 transition-opacity
                            font-compact text-sm self-start
                            ${announcementTimeout ? 'text-white' : 'text-steelGray '}`}>
            <span className='translate-y-[2px]'>Timeout</span>
            <CheckBox selected={announcementTimeout !== null}
                      setSelected={(selected) => setAnnouncementTimeout(selected ? new Date(new Date().getTime() + 7_200_000) : null)}
                      asDiv={true} small={true}/>
          </button>
        </div>

        {announcementTimeout !== null ? (
          <div className='mt-1 w-full flex flex-col gap-y-1'>
            <p className='text-steelGray font-compact text-sm'>Display until:</p>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker value={announcementTimeout} onChange={(newDate: Date | null) => setAnnouncementTimeout(newDate)}
              format="dd/MM/yyyy hh:mm" />
            </LocalizationProvider>
          </div>
        ) : ''}

        <button type='button' aria-label='Save changes to notification settings'
                  disabled={!changesMade || submitting}
                  onClick={handleSaveChanges}
                  className='w-full flex items-center gap-x-2 justify-center text-black font-compact font-semibold uppercase p-2 mt-6
                            rounded-lg bg-green hover:bg-gorse disabled:hover:bg-green disabled:opacity-50'>
            <span>
              Save Changes
            </span>
            {submitting ? (
              <ImSpinner8 className="animate-spin"/>
            ) : ''}
          </button>
      </div>
    </div>
  )
}

export default Announcements;
