import { createContext, useContext, useState, useEffect, useCallback, ReactNode } from "react";
// firebase
import firebase from "firebase/compat/app";
import { auth, firestore } from "../firebase";
import { doc, getDoc, onSnapshot, Unsubscribe } from "firebase/firestore";
// types
import { User } from "firebase/auth";
import { DBUser, userConverter } from "../firestore/users";
import { DBTeam, teamConverter } from "../firestore/teams";
// utils
import { loginCustomToken } from "@utils/AuthHelpers";

interface IAuthContext {
  user: firebase.User | null,
  userObj: DBUser | null,
  userObjLoaded: boolean,
  userTeam: DBTeam | null
}

const defaultAuthContext = {
  user: null,
  userObj: null,
  userTeam: null,
  userObjLoaded: false,
  loading: true,
}
const AuthContext = createContext<IAuthContext>(defaultAuthContext);

// eslint-disable-next-line react-refresh/only-export-components
export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider"
    );
  }

  return context;
};

interface AuthContextProviderProps {
  children: ReactNode;
}

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  const [userObj, setUserObj] = useState<DBUser | null>(null);
  const [userObjLoaded, setUserObjLoaded] = useState<boolean>(false);

  const [userTeam, setUserTeam] = useState<DBTeam | null>(null);

  const getUser = (authUser: User | null) => {
    setUser(authUser as firebase.User);
    setUserLoaded(true);
  };

  const getToken = async () => {
    const tokenID = localStorage.getItem("oauthState");

    try {
      if (tokenID) {
        const tokenRef = doc(firestore, 'tokens', tokenID);
        const tokenSnap = await getDoc(tokenRef);

        if (tokenSnap.exists()) {
          await loginCustomToken(tokenSnap.data().token);
        }
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  const getUserObj = useCallback(() => {
    let unsubscribe: Unsubscribe | (() => void) = () => false;

    if (user) {
      const userId = user.uid;
      if (userId) {
        const teamRef = doc(firestore, 'users', userId).withConverter(userConverter);
        unsubscribe = onSnapshot(teamRef, (doc) => {
          const userData = doc.data();
          setUserObj(userData ?? null);
          setUserObjLoaded(true);
        })
      } else {
        setUserObj(null);
        setUserObjLoaded(true);
      }
    } else {
      setUserObj(null);
      setUserTeam(null);
      if (userLoaded) {
        setUserObjLoaded(true);
      }
    }

    return () => unsubscribe();
  }, [user, userLoaded]);

  const getUserTeam = useCallback(() => {
    let unsubscribe: Unsubscribe | (() => void) = () => false;

    if (userObj) {
      const userTeamId = userObj.team;

      if (userTeamId !== '') {
        const teamRef = doc(firestore, 'teams', userTeamId).withConverter(teamConverter);

        unsubscribe = onSnapshot(teamRef, (doc) => {
          const teamData = doc.data()!;
          setUserTeam(teamData);
        });
      } else {
        setUserTeam(null);
      }
    }

    return () => unsubscribe();
  }, [userObj]);

  useEffect(() => {
    const unsubscribe = getUserObj();

    if (user === null) {
      getToken();
    }

    return () => unsubscribe();
  }, [user, getUserObj]);

  useEffect(() => {
    const unsubscribe = getUserTeam();

    return () => unsubscribe();
  }, [userObj, getUserTeam]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(getUser);

    return () => unsubscribe();
  }, []);

  const contextValue = {
    user,
    userObj,
    userObjLoaded,
    userTeam
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
