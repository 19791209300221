// HERO ICONS
export const HeroRightGrph = () => {
  return (
    <svg
      className="sm:max-w-[8.625rem] md:max-w-[8.125rem] lg:max-w-full"
      width="182"
      height="26"
      viewBox="0 0 182 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 25C47.5582 25 49 25 49 25L81.5 1H181.5" stroke="#D4FF27" />
      <path d="M15 25C62.0582 25 63.5 25 63.5 25L96 1H145" stroke="#D4FF27" />
    </svg>
  );
};
export const HeroLeftGrph = () => {
  return (
    <svg
      className="sm:max-w-[8.625rem] md:max-w-[8.125rem] lg:max-w-full"
      width="182"
      height="26"
      viewBox="0 0 182 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M181.5 25C134.442 25 133 25 133 25L100.5 1H0.5"
        stroke="#D4FF27"
      />
      <path d="M167 25C119.942 25 118.5 25 118.5 25L86 1H37" stroke="#D4FF27" />
    </svg>
  );
};
export const HeroIndiosLine = () => {
  return (
    <svg
      className="sm:max-w-[9.375rem] lg:max-w-none"
      width="214"
      height="93"
      viewBox="0 0 214 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 53H96" stroke="#D4FF27" />
      <g filter="url(#filter0_d_1665_12003)">
        <path d="M103 53L107 57L111 53L107 49L103 53Z" fill="#D4FF27" />
      </g>
      <g filter="url(#filter1_d_1665_12003)">
        <path
          d="M95.7071 53L107 64.2929L118.293 53L107 41.7071L95.7071 53Z"
          stroke="#D4FF27"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_d_1665_12003)">
        <path d="M83 53L107 29L131 53" stroke="#D4FF27" />
      </g>
      <path d="M118 53H214" stroke="#D4FF27" />
      <defs>
        <filter
          id="filter0_d_1665_12003"
          x="75"
          y="21"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="14" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1665_12003"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1665_12003"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1665_12003"
          x="67"
          y="13"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="14" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1665_12003"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1665_12003"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1665_12003"
          x="54.6465"
          y="0.292969"
          width="104.707"
          height="81.0605"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="14" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1665_12003"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1665_12003"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export const HeroIndiosLineBlack = () => {
  return (
    <svg
      className="sm:max-w-[9.375rem] lg:max-w-none"
      width="214"
      height="93"
      viewBox="0 0 214 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 53H96" stroke="#151A1F" />
      <g filter="url(#filter0_d_1665_11957)">
        <path d="M103 53L107 57L111 53L107 49L103 53Z" fill="#151A1F" />
      </g>
      <g filter="url(#filter1_d_1665_11957)">
        <path
          d="M95.7071 53L107 64.2929L118.293 53L107 41.7071L95.7071 53Z"
          stroke="#151A1F"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter2_d_1665_11957)">
        <path d="M83 53L107 29L131 53" stroke="#151A1F" />
      </g>
      <path d="M118 53H214" stroke="#151A1F" />
      <defs>
        <filter
          id="filter0_d_1665_11957"
          x="75"
          y="21"
          width="64"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="14" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1665_11957"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1665_11957"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_1665_11957"
          x="67"
          y="13"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="14" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1665_11957"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1665_11957"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_1665_11957"
          x="54.6465"
          y="0.292969"
          width="104.707"
          height="81.0605"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="14" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1665_11957"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1665_11957"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const HeroRightGrphBlack = () => {
  return (
    <svg
      className="sm:max-w-[5.625rem] md:max-w-[8.125rem] lg:max-w-full"
      xmlns="http://www.w3.org/2000/svg"
      width="182"
      height="26"
      viewBox="0 0 182 26"
      fill="none"
    >
      <path d="M0.5 25C47.5582 25 49 25 49 25L81.5 1H181.5" stroke="#151A1F" />
      <path d="M15 25C62.0582 25 63.5 25 63.5 25L96 1H145" stroke="#151A1F" />
    </svg>
  );
};
export const HeroLeftGrphBlack = () => {
  return (
    <svg
      className="sm:max-w-[5.625rem] md:max-w-[8.125rem] lg:max-w-full"
      xmlns="http://www.w3.org/2000/svg"
      width="182"
      height="26"
      viewBox="0 0 182 26"
      fill="none"
    >
      <path
        d="M181.5 25C134.442 25 133 25 133 25L100.5 1H0.5"
        stroke="#151A1F"
      />
      <path d="M167 25C119.942 25 118.5 25 118.5 25L86 1H37" stroke="#151A1F" />
    </svg>
  );
};
