import { Link } from "react-router-dom"

interface INoTeamAssociated {
  editor: boolean,
}

const NoTeamAssociated: React.FC<INoTeamAssociated> = ({editor}) => {
  return (
    <div className="bg-lightBlack rounded-xl py-[2.75rem] text-center font-compact">
      <h4 className="font-wide text-xl sm:text-2xl uppercase font-bold text-steelGray">
       No team
      </h4>
      <p className="mt-2 font-compact font-medium text-sm tracking-[0.01rem] text-steelGray">
        {editor ? 'You haven\'t joined or created any team yet.' : 'User is not currently a member of a team'}
      </p>
      {editor ? (
        <Link
          className="uppercase tracking-[0.01rem] px-4 pb-[0.563rem] mt-6 pt-[0.813rem] border border-lightGray !leading-4 rounded-xl inline-block"
          to={"/create-team"}>
          Create a team
        </Link>
      ) : ''}
    </div>
  )
}

export default NoTeamAssociated;
