export const Madels = () => {
    return (
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.41629 5.854L3.66527 2.10298C3.59934 2.03705 3.50991 2 3.41667 2C3.32341 2 3.234 2.03705 3.16807 2.10298L2.10297 3.16808C1.96568 3.30538 1.96568 3.52796 2.10297 3.66526L5.22725 6.78953C5.84429 6.29547 6.59525 5.96221 7.41629 5.854Z"
          fill="white"
        />
        <path
          d="M9.232 4.20939L11.4414 2H4.55469L6.76407 4.20939H9.232Z"
          fill="white"
        />
        <path
          d="M13.8993 3.16808L12.8341 2.10298C12.7682 2.03705 12.6788 2 12.5856 2C12.4923 2 12.4029 2.03705 12.337 2.10296L8.58594 5.854C9.40698 5.96221 10.158 6.29547 10.775 6.78953L13.8993 3.66526C14.0366 3.52796 14.0365 3.30538 13.8993 3.16808Z"
          fill="white"
        />
        <path
          d="M7.99845 6.51904C5.93585 6.51904 4.25781 8.19708 4.25781 10.2597C4.25781 12.3223 5.93585 14.0003 7.99845 14.0003C10.061 14.0003 11.7391 12.3223 11.7391 10.2597C11.7391 8.19708 10.061 6.51904 7.99845 6.51904ZM9.88125 11.3644H9.12814C9.03488 11.3644 8.94546 11.3273 8.87956 11.2614L7.99845 10.3803L7.11733 11.2614C7.05143 11.3273 6.96199 11.3644 6.86876 11.3644H6.11564C5.92148 11.3644 5.76407 11.207 5.76407 11.0128C5.76407 10.8187 5.92148 10.6613 6.11564 10.6613H6.72314L7.50126 9.88313L6.62015 9.00202C6.48286 8.86472 6.48286 8.64213 6.62015 8.50484C6.75743 8.36754 6.98004 8.36754 7.11733 8.50484L7.99845 9.38592L8.87956 8.50481C9.01683 8.36754 9.23944 8.36752 9.37674 8.50481C9.51403 8.64211 9.51403 8.8647 9.37674 9.00199L8.49563 9.8831L9.27375 10.6612H9.88125C10.0754 10.6612 10.2328 10.8186 10.2328 11.0128C10.2328 11.207 10.0754 11.3644 9.88125 11.3644Z"
          fill="white"
        />
      </svg>
    );
  };

  export const RegionsSelectType = () => {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
          fill="#D4FF27"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.08296 7C2.50448 4.48749 4.48749 2.50448 7 2.08296V0H9V2.08296C11.5125 2.50448 13.4955 4.48749 13.917 7H16V9H13.917C13.4955 11.5125 11.5125 13.4955 9 13.917V16H7V13.917C4.48749 13.4955 2.50448 11.5125 2.08296 9H0V7H2.08296ZM4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8Z"
          fill="#D4FF27"
        />
      </svg>
    );
  };

  // ====🧑‍💻🧑‍💻 HERE IS THE TOURNEMENT FILTER LIST ICON 🧑‍💻🧑‍💻====
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ListLayoutToggle = (value: { value: boolean }) => {
  const gridValue = value.value;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={`${gridValue ? "#fff" : "#1F272E"}`}
    >
      <path d="M3 1H1V3H3V1Z" />
      <path d="M3 5H1V7H3V5Z" />
      <path d="M1 9H3V11H1V9Z" />
      <path d="M3 13H1V15H3V13Z" />
      <path d="M15 1H5V3H15V1Z" />
      <path d="M15 5H5V7H15V5Z" />
      <path d="M5 9H15V11H5V9Z" />
      <path d="M15 13H5V15H15V13Z" />
    </svg>
  );
};

// ====🧑‍💻🧑‍💻 HERE IS THE TOURNEMENT FILTER GRID ICON 🧑‍💻🧑‍💻====
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GridLayoutToggle = (value: { value: boolean }) => {
  const gridValue = value.value;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={`${gridValue ? "#1F272E" : " #fff"}`}
    >
      <path d="M1 1H7V7H1V1Z" />
      <path d="M9 1H15V7H9V1Z" />
      <path d="M1 9H7V15H1V9Z" />
      <path d="M9 9H15V15H9V9Z" />
    </svg>
  );
};

// ====🧑‍💻🧑‍💻 HERE IS THE DISCOARD GREEN ICON 🧑‍💻🧑‍💻====
export const DiscordGreenIcon = () => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1842 0.930788C11.2722 0.496902 10.2847 0.181985 9.25614 3.21903e-05C9.24712 -0.000262155 9.23815 0.00146505 9.22985 0.0050924C9.22156 0.00871974 9.21415 0.0141591 9.20814 0.0210268C9.0847 0.251966 8.9407 0.552887 8.8447 0.783827C7.7537 0.615871 6.64418 0.615871 5.55318 0.783827C5.45718 0.545889 5.31317 0.251966 5.18288 0.0210268C5.17603 0.0070305 5.15545 3.21903e-05 5.13488 3.21903e-05C4.10628 0.181985 3.12568 0.496902 2.2068 0.930788C2.19994 0.930788 2.19309 0.937787 2.18623 0.944785C0.321036 3.79304 -0.193264 6.56431 0.060457 9.30759C0.060457 9.32159 0.0673144 9.33559 0.081029 9.34259C1.31535 10.2663 2.50167 10.8262 3.67427 11.1971C3.69484 11.2041 3.71541 11.1971 3.72227 11.1831C3.99656 10.7982 4.24343 10.3923 4.45601 9.96542C4.46972 9.93743 4.45601 9.90944 4.42858 9.90244C4.03771 9.74848 3.66741 9.56653 3.30397 9.35658C3.27655 9.34259 3.27655 9.3006 3.29712 9.2796C3.37255 9.22362 3.44798 9.16063 3.52341 9.10465C3.53712 9.09065 3.5577 9.09065 3.57141 9.09765C5.93033 10.1964 8.4744 10.1964 10.8059 9.09765C10.8196 9.09065 10.8402 9.09065 10.8539 9.10465C10.9293 9.16763 11.0048 9.22362 11.0802 9.2866C11.1076 9.3076 11.1076 9.34958 11.0733 9.36358C10.7167 9.58052 10.3396 9.75548 9.94873 9.90944C9.9213 9.91644 9.91444 9.95143 9.9213 9.97242C10.1407 10.3993 10.3876 10.8052 10.655 11.1901C10.6756 11.1971 10.6962 11.2041 10.7167 11.1971C11.8962 10.8262 13.0825 10.2663 14.3168 9.34259C14.3306 9.33559 14.3374 9.32159 14.3374 9.30759C14.6391 6.13742 13.8368 3.38715 12.2116 0.944785C12.2048 0.937787 12.1979 0.930788 12.1842 0.930788ZM4.81259 7.63503C4.10628 7.63503 3.51655 6.97021 3.51655 6.15142C3.51655 5.33264 4.09257 4.66781 4.81259 4.66781C5.53946 4.66781 6.11548 5.33963 6.10862 6.15142C6.10862 6.97021 5.53261 7.63503 4.81259 7.63503ZM9.59215 7.63503C8.88584 7.63503 8.29611 6.97021 8.29611 6.15142C8.29611 5.33264 8.87213 4.66781 9.59215 4.66781C10.319 4.66781 10.895 5.33963 10.8882 6.15142C10.8882 6.97021 10.319 7.63503 9.59215 7.63503Z"
        fill="#D4FF27"
      />
    </svg>
  );
};

export const BannerLine = () => {
  return (
    <svg
      width="1294"
      height="74"
      viewBox="0 0 1294 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4501_30118)">
        <path
          d="M448.999 73C3282.5 73 218.955 73 71.5529 73L-30.2482 -29H-976.001"
          stroke="url(#paint0_linear_4501_30118)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4501_30118"
          x1="426.001"
          y1="46.0005"
          x2="-421.5"
          y2="22.0005"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9AB300" />
          <stop offset="1" stopColor="#9AB300" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_4501_30118">
          <rect width="1294" height="74" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
