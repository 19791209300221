import { useState } from 'react';
// components
import Modal from '@ui/Modal'
import PillButton from '@ui/PillButton';
import ApexMap from './ApexMap';
import { getMapNameFromAcronym } from '@src/utils/Games/ApexLegends';

interface IApexMap {
  map: 'WE' | 'SP' | 'ED',
  singleMap?: boolean,
  isOpen: boolean,
  close: () => void
}

const ApexMapAidsModal: React.FC<IApexMap> = ({ map, isOpen, close, singleMap }) => {
  const [currentMap, setCurrentMap] = useState<'WE' | 'SP' | 'ED'>(map);

  return (
    <Modal title={singleMap ? getMapNameFromAcronym(map) : 'Apex Maps'} xl={true}
           open={isOpen} setOpen={(open: boolean) => !open ? close() : null}
           disableClickOff={true}>
      <div className="flex flex-col gap-y-4">
        {!singleMap ? (
          <div className='flex items-center gap-x-2'>
            <PillButton active={currentMap === 'WE'} onClick={() => setCurrentMap('WE')}>
              World's Edge
            </PillButton>
            <PillButton active={currentMap === 'SP'} onClick={() => setCurrentMap('SP')}>
              Storm Point
            </PillButton>
            <PillButton active={currentMap === 'ED'} onClick={() => setCurrentMap('ED')}>
              E-District
            </PillButton>
          </div>
        ) : ''}
        <ApexMap map={currentMap}/>
      </div>
    </Modal>
  )
}

export default ApexMapAidsModal;
