import { createContext, useContext, useState, useEffect, ReactNode} from 'react';

interface IDateTimeContext {
  currentTime: number,
  currentDate: Date,
}

const defaultDateTimeContext: IDateTimeContext = {
  currentTime: new Date().getTime(),
  currentDate: new Date()
}

const DateTimeContext = createContext<IDateTimeContext>(defaultDateTimeContext);

export const useDateTimeContext = (): IDateTimeContext => {
  return useContext(DateTimeContext);
}

interface IDateTimeProvider {
  children: ReactNode
}

const DateTimeProvider: React.FC<IDateTimeProvider> = ({children}) => {
  const [currentTime, setCurrentTime] = useState<number>(defaultDateTimeContext.currentTime);
  const [currentDate, setCurrentDate] = useState<Date>(defaultDateTimeContext.currentDate);

  const refreshDateTime = () => {
    const localCurrentDate = new Date();

    setCurrentDate(localCurrentDate)
    setCurrentTime(localCurrentDate.getTime());
  }

  useEffect(() => {
    const interval = setInterval(refreshDateTime, 1000);

    () => clearInterval(interval);
  }, [])

  const contextValue = {
    currentTime,
    currentDate
  }
  return (
    <DateTimeContext.Provider value={contextValue}>
      {children}
    </DateTimeContext.Provider>
  )
}

export default DateTimeProvider;
