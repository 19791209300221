import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export interface ApexCode {
  id: string,
  batch: string;
  activation: Date;
  expiration: Date;
  lastMatchIndex: number,
  inUse: boolean;
  used: boolean;
  highPerformance: boolean;
  statsToken: string;
  adminToken: string;
  playerToken: string;
  groupId: string;
}

export const apexCodeConverter = {
    toFirestore: (apexCode: ApexCode) => {
        return {
            id: apexCode.id ?? '',
            batch: apexCode.batch ?? '',
            activation: apexCode.activation ?? new Date(),
            expiration: apexCode.expiration ?? new Date(),
            lastMatchIndex: apexCode.lastMatchIndex ?? -1,
            inUse: apexCode.inUse ?? false,
            used: apexCode.used ?? false,
            highPerformance: apexCode.highPerformance ?? false,
            statsToken: apexCode.statsToken ?? '',
            adminToken: apexCode.adminToken ?? '',
            playerToken: apexCode.playerToken ?? '',
            groupId: apexCode.groupId ?? '',
        }
    },
    fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
        const data = snapshot.data(options)!;
        const apexCode: ApexCode = {
            id: data.id ?? snapshot.id,
            batch: data.batch ?? '',
            activation: data.activation ? new Date(data.activation.seconds * 1000) : new Date(),
            expiration: data.expiration ? new Date(data.expiration.seconds * 1000) : new Date(),
            lastMatchIndex: data.lastMatchIndex ?? -1,
            inUse: data.inUse ?? false,
            used: data.used ?? false,
            highPerformance: data.highPerformance ?? false,
            statsToken: data.statsToken ?? '',
            adminToken: data.adminToken ?? '',
            playerToken: data.playerToken ?? '',
            groupId: data.groupId ?? '',
        }
        return apexCode
    }
}
