import { useState } from "react";
// context
import { useTeamContext } from "@components/team/TeamShowProvider";
// types
import { ApexMapInfos } from "@customTypes/Games/ApexLegends";
// utils
import { getMapCoverImageFromAcronym, getMapNameFromAcronym } from "@utils/Games/ApexLegends";
// components
import ApexMapPoiPreferencesModal from "./ApexMapPoiPreferencesModal";
// icons
import { Flag, MouseClickIcon } from "@components/common/icons/Common";
import { FaCheck, FaPen } from "react-icons/fa";

interface IDropSpotMapCard {
  mapAcronym: string,
  mapSet: boolean,
  setCurrentMapModal: (map: string) => void,
}

const DropSpotMapCard: React.FC<IDropSpotMapCard> = ({ mapAcronym, mapSet, setCurrentMapModal}) => {

  return (
    <div className={`group w-full h-auto aspect-[1/1.3] max-w-[350px] rounded-xl bg-lightBlack p-4
                     flex flex-col gap-4`}>
      <div className="relative h-4/5 rounded-lg w-full overflow-hidden">
        <div className="absolute top-3 right-3 w-[30px] h-auto aspect-square bg-ebonyClay border border-lightBlack rounded-full
                        flex items-center justify-center">
          {mapSet ? (
            <FaCheck className="w-[16px] h-auto aspect-square fill-green"/>
          ) : (
            <Flag className="w-[16px] h-auto aspect-square fill-white"/>
          )}
        </div>
        <img src={getMapCoverImageFromAcronym(mapAcronym)} alt="World's Edge Apex Map"
          className={`w-full h-full object-cover transition-opacity`}/>
      </div>
      <div className="w-full h-1/6 flex-grow  flex items-center justify-between">
        <div className='w-full flex flex-col items-start justify-center gap-y-2'>
          <p className={`text-sm font-wide font-semibold text-white uppercase leading-4`}>{getMapNameFromAcronym(mapAcronym)}</p>
          <p className={`flex items-center gap-x-2 leading-4
                          font-compact text-steelGray`}>
            <span>{mapAcronym}</span>
          </p>
        </div>
        {mapSet ? (
          <button type="button" aria-label="edit map preferences"
                  className="rounded-lg bg-lightGray text-white font-compact uppercase font-semibold
                             flex items-center justify-center gap-x-3 hover:opacity-75 transition-opacity px-3 h-[30px]"
                  onClick={() => setCurrentMapModal(mapAcronym)}>
            <span>
              Edit
            </span>
            <FaPen className="w-[10px] h-auto aspect-square fill-green"/>
          </button>
        ) : (
          <button type="button" aria-label="edit map preferences"
                  className="rounded-lg bg-green text-black font-compact uppercase font-semibold
                             flex items-center justify-center gap-x-3 hover:opacity-75 transition-opacity px-3 h-[30px]"
                  onClick={() => setCurrentMapModal(mapAcronym)}>
            <span>
              Set
            </span>
            <MouseClickIcon className="w-[12px] h-auto aspect-square fill-black"/>
          </button>
        )}
      </div>
    </div>
  );
}

const PoiPreferences = () => {
  const { team } = useTeamContext();
  const availablePoiMaps = Object.keys(ApexMapInfos);

  const [currentMapModal, setCurrentMapModal] = useState<string>('');

  return team ? (
    <>
      <div className="mt-3">
        <div className='max-sm:maskedListHorz px-[25px] sm:px-0 flex flex-nowrap overflow-x-scroll sm:grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-6 gap-y-8 w-full'>
          {availablePoiMaps.map((map) => (
            <DropSpotMapCard key={`${map}-preferences-card`}
                             mapAcronym={map}
                             mapSet={team.gamePreferences.apex.poiPreferences[map] !== undefined}
                             setCurrentMapModal={setCurrentMapModal}/>
          ))}
        </div>
      </div>

      {availablePoiMaps.map((map) => (
        <ApexMapPoiPreferencesModal key={`poiPreferences-map-modal-${map}`}
                           map={map as 'WE' | 'SP' | 'ED'}
                           team={team}
                           open={currentMapModal === map}
                           setOpen={(open: boolean) => {
                            if (!open) {
                              setCurrentMapModal('');
                            }
                           }}/>
      ))}
    </>
  ): '';
};

export default PoiPreferences;
