import { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
// types
import { Tournament, TournamentStatus, tournamentRegionToString } from "@src/firestore/tournaments";
// utils
import { gameToString } from '@customTypes/Games/Games';
import { formatDateCountdown } from '@utils/Date';
// components
import { TargetIcon, TeamIcon } from '@icons/Common';
import { ApexLegendsIcon, ValorantIcon, RocketLeagueIcon, FortniteIcon } from '@icons/Games';

interface IMyTournamentNotif {
  tournament: Tournament,
  closeMenu: () => void,
}

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[14px] h-auto aspect-square fill-white"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[14px] h-auto aspect-square fill-white"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[14px] h-auto aspect-square fill-white"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[14px] h-auto aspect-square fill-white"/>)
  }
  return 'no icon'
}


const MyTournamentNotif: React.FC<IMyTournamentNotif> = ({tournament, closeMenu}) => {

  const totalPrizePool = tournament.prizePool.prizes.reduce((acc, curr) => acc + curr.prizeAmount, 0);
  const registeredTeamsLength = tournament.entrantsCount;
  const capacityPercentage = Math.round(Math.min((registeredTeamsLength / tournament.teamCapacity) * 100, 100));

  const [checkInCountdown, setCheckInCountdown] = useState<string>('');
  const [playCountdown, setPlayCountdown] = useState<string>('');

  const processCountdowns = (): NodeJS.Timeout => {
    return setInterval(() => {
      const latestCheckInCountdown = formatDateCountdown(tournament.statusDates.confirmation);
      const latestPlayCountdown = formatDateCountdown(tournament.statusDates.ongoing);
      setCheckInCountdown(latestCheckInCountdown);
      setPlayCountdown(latestPlayCountdown);
    }, 1000)
  }

  useEffect(() => {
    // process countdowns dynamically
    const interval = processCountdowns();
    return () => clearInterval(interval);
  }, [])


  return (
    <Link to={`/tournaments/${tournament.id}`}
          onClick={closeMenu}
          className="bg-lightGray rounded-xl overflow-hidden hover:opacity-95 transition-opacity group flex-shrink-0
                      flex flex-col">
      <div className='relative w-full h-fit'>
        <img src={tournament.bannerImage} alt="tournament banner"
             className='w-full h-auto aspect-[2.85] object-cover group-hover:scale-105 transition-all'/>
      </div>
      <div className='p-4 flex flex-col gap-y-2'>
        <h3 className='font-wide font-semibold text-sm text-white uppercase group-hover:text-green transition-colors'>
          {tournament.name}
        </h3>

        <div className="flex items-center gap-x-4 text-sm">
          <div className="flex gap-x-1 items-center">
            {gameNameToIcon(gameToString(tournament.game))}
            <p className='text-white font-compact font-medium'>
              {gameToString(tournament.game)}
            </p>
          </div>

          <div className="flex gap-x-1 items-center">
            <TargetIcon className="w-[12px] h-auto aspect-square fill-white"/>
            <p className='text-white font-compact font-medium'>
              {tournamentRegionToString(tournament.region)}
            </p>
          </div>

          <div className="flex gap-x-1 items-center">
            <TeamIcon className="w-[14px] h-auto aspect-square fill-white"/>
            <p className='text-white font-compact font-medium'>
              {tournament.teamSize}
            </p>
          </div>
        </div>

        <div className={`flex flex-row items-center justify-normal gap-4 w-full h-auto`}>
          <p className="font-medium font-compact text-green">
            {Math.round(capacityPercentage)}%
          </p>
          <div className={`block relative duration-300 bg-ebonyClay h-[4px] w-full rounded-md after:content-[''] after:absolute after:top-0 after:left-0 after:h-[6px] after:bg-green after:rounded-md after:shadow-[0px_0px_24px_4px_yellowGreen] overflow-y-hidden`}>
              <span
                  style={{ width: `${capacityPercentage}%` }}
                  className={`absolute  bg-green h-full`}
              ></span>
          </div>
          <p className="text-sm text-steelGray font-medium font-compact">
            {registeredTeamsLength}/{tournament.teamCapacity}
          </p>
        </div>

        <div className='flex flex-col gap-y-2 w-full text-sm'>
          <div className='w-full flex items-center gap-x-3 text-steelGray font-medium font-compact'>
            {tournament.status === TournamentStatus.registration ? (
              <>
                {checkInCountdown ? (
                  <div className={`w-full flex items-center gap-x-3`}>
                    <p>Check-in Opens: </p>
                    <p className="text-white">{checkInCountdown}</p>
                  </div>
                  ) : (
                    <p>Check-in Opens soon</p>
                  )}
              </>
            ) : tournament.status < TournamentStatus.ongoing ? (
              <>
                {playCountdown ? (
                  <div className={`w-full flex items-center gap-x-3`}>
                    <p>Check-in closes: </p>
                    <p className="text-white">{playCountdown}</p>
                  </div>
                ) : (
                  <p>Check-in closes soon</p>
                )}
              </>
            ) : tournament.status === TournamentStatus.ongoing ? (
              <p>Tournament Ongoing</p>
            ) : (
              <p>Tournament Complete</p>
            )}
          </div>

          <div className='w-full flex items-center gap-x-3 text-steelGray font-medium font-compact'>
            <p>Prize Pool</p>
            <p className='text-white'>
              {tournament.prizePool.currency}{totalPrizePool}
            </p>
          </div>
        </div>

      </div>
    </Link>
  );
}

export default MyTournamentNotif;
