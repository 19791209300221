import { useState, useEffect, useCallback } from "react";
// firebase
import { functions } from '@src/firebase';
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
// context
import { useTeamContext } from "@components/team/TeamShowProvider";
// types
import { DBTeam } from "@src/firestore/teams";
// libraries
import { toast } from "react-toastify";
// components
import Modal from "@ui/Modal";
import ErrorText from "@ui/ErrorText";

const dissolveTeam = httpsCallable(functions, 'dissolveTeam');

interface IDissolveTeam {
  team: DBTeam,
  dissolveTeam: () => Promise<HttpsCallableResult<unknown>>,
}

const DissolveTeam: React.FC<IDissolveTeam> = ({team, dissolveTeam}) => {
  const [confirm, setConfirm] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const inPlay = team.tournamentsInPlay.length > 0;
  const hasActiveTournament = team.activeTournaments.length > 0;

  const [confirmationInput, setConfirmationInput] = useState<string>('');
  const [confirmationValid, setConfirmationValid] = useState<boolean>(false);

  const checkConfirmationValid = useCallback(() => {
    const localConfirmationValid = team !== null && team.teamName === confirmationInput;
    setConfirmationValid(localConfirmationValid);
  }, [team, confirmationInput]);

  useEffect(() => {
    checkConfirmationValid();
  }, [confirmationInput, checkConfirmationValid])

  const handleDissolveTeam = async () => {
    setSubmitting(true);
    const dissolvePromise = dissolveTeam();
    toast.promise(dissolvePromise, {
      success: 'Team dissolved',
      pending: 'Dissolving team',
      error: 'Error dissolving team'
    })
    await dissolvePromise;
    setSubmitting(false);
    setConfirm(false);
  }

  return (
    <div className="flex items-center gap-x-3 w-full mt-2">
      <Modal title="Dissolve Team" open={confirm} setOpen={setConfirm}
             buttonText="Dissolve my Team"
             buttonNegative={true}
             buttonOnClick={handleDissolveTeam}
             buttonDisabled={submitting || inPlay || hasActiveTournament || team.players.length > 1 || !confirmationValid}>
        <div className="flex flex-col gap-y-3">
          <p className="text-red/90 uppercase font-compact text-sm font-thin text-center w-fit mx-auto">
            This action remove all your registrations and team data.
          </p>
          <p className="text-red/90 font-compact text-sm font-thin text-center">To proceed please type <em className="my-2 text-white not-italic p-[6px] bg-lightGray rounded">{team.teamName}</em> into the following box:</p>
          <input type="text"
                value={confirmationInput}
                onChange={(e) => setConfirmationInput(e.target.value)}
                className="w-full p-2 text-white bg-black rounded-lg outline-none border-none"/>
        </div>
      </Modal>
      <div className="w-full">
        <button type="button"
                disabled={inPlay || hasActiveTournament}
                onClick={() => {
                  if (team.players.length > 1) {
                    toast.error('You must remove all teammates before dissolving a team!')
                  } else {
                    setConfirm(true)
                  }
                }}
                className="font-compact font-bold px-4 pt-[0.813rem] pb-[0.688rem] !leading-4 rounded-xl uppercase text-center text-red/70 bg-red/20 hover:opacity-75 transition-opacity w-full
                          disabled:opacity-50 disabled:pointer-events-none">
          Dissolve Team
        </button>
        {inPlay ? (
          <ErrorText>
            Cannot dissolve team while it is engaged in a tournament.
          </ErrorText>
        ) : hasActiveTournament ? (
          <ErrorText>
            Cannot dissolve team while it is enrolled for a tournament.
          </ErrorText>
        ) : ''}
      </div>
    </div>
  );
}

const Settings = () => {
  const { team, editor } = useTeamContext();

  return team ? (
    <div className="mt-3">
      {editor ? (
        <DissolveTeam team={team} dissolveTeam={() => {
          return dissolveTeam({teamId: team.id!});
        }}/>
      ) : ''}
    </div>
  ) : '';
};

export default Settings;
