import burgerKingLogo from "../../../assets/images/png/my-profile/burger-king-logo.png";
import googleLogo from "../../../assets/images/png/my-profile/googleLogo.png";
import loremIpsum from "../../../assets/images/png/my-profile/lorem_ipsum.png";
import redBullImg from "../../../assets/images/png/my-profile/red-bull-logo.png";

import playerImage from "../../../assets/images/png/team/invite1.png";
import playerImage2 from "../../../assets/images/png/team/invite2.png";
import playerImage3 from "../../../assets/images/png/team/invite3.png";
import playerImage4 from "../../../assets/images/png/team/invite4.png";
import playerImage5 from "../../../assets/images/png/team/invite5.png";


import { ApexGrayIcon } from "../icons/Header";
import {
  BronzeCupIcon,
  GoldenCupIcon,
  SilverCupIcon,
  CalendarIcon,
  ClockIcon,
  CompetedTournamentsIcon,
  ProfileDiscordIcon,
  ProfileTwitchIcon,
  ProfileTwitterIcon,
  ProfileWebsiteIcon,
  RemoveMemberIcon,
  TrophiesIcon,
} from "../icons/ProfileIcons";


export const profileSocialIcons = [
  {
    name: 'Discord',
    icon: <ProfileDiscordIcon />,
    url: "https://www.twitch.tv/versus_gg_",
  },
  {
    name: 'Twitter',
    icon: <ProfileTwitterIcon />,
    url: "https://twitter.com/gg_versus",
  },
  {
    name: 'Twitch',
    icon: <ProfileTwitchIcon />,
    url: "https://discord.com/invite/versus-gg",
  },
  {
    name: 'Website',
    icon: <ProfileWebsiteIcon />,
    url: "/teams",
  },
];

export const teammatesList = [
  {
    image: loremIpsum,
    title: "Team Manager",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
  },
  {
    image: loremIpsum,
    title: "Captain",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
  },
  {
    image: loremIpsum,
    title: "Player",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
  },
  {
    image: loremIpsum,
    title: "Player",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
  },
  {
    image: loremIpsum,
    title: "Player",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
  },
  {
    image: loremIpsum,
    title: "Player",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
  },
];

export const profileList = [
  {
    image: <GoldenCupIcon className={undefined} />,
    title: "Winner",
    description: "Valorant Champions LA",
    gmail: "Jun 15, 2023",
    clock: <ClockIcon />,
  },
  {
    image: <SilverCupIcon className={undefined} />,
    title: "3RD",
    description: "Strikefire Showdown",
    gmail: "May 03, 2023",
    clock: <ClockIcon />,
  },
  {
    image: <BronzeCupIcon className={undefined} />,
    title: "2RD",
    description: "May 03, 2023",
    gmail: "Jan 21, 2023",
    clock: <ClockIcon />,
  },
];
//=================== TEAM REGIONS MAP LIST
export const TeamRegionDropDown = [
  {
    regionType: "EMEA",
  },
  {
    regionType: "NA",
  },
  {
    regionType: "LATAM",
  },
  {
    regionType: "APAC",
  },
];
//=================== MAIN GAME MAP LIST
export const MainGameDropDownOptions = [
  // {
  //   svgIcon: <ValorantGrayIcon />,
  //   title: "Valorant",
  // },
  {
    svgIcon: <ApexGrayIcon />,
    title: "Apex Legends",
  },
];
export const sponsorsList = [
  {
    image: redBullImg,
    className: "w-[47.9%] custom_xsm:w-[48%] sm:w-full",
  },
  {
    image: googleLogo,
    className: "w-[47.9%] custom_xsm:w-[48%] sm:w-full",
  },
  {
    image: burgerKingLogo,
    className: "w-full sm:w-full",
  },
];

// ==== HERE IS THE TEAMPLAYERSLIST ARRAY ====

export const teamPlayersList = [
  {
    image: loremIpsum,
    title: "Org Owner",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
  },
  {
    image: loremIpsum,
    title: "Team Manager",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
    btnContent: "Cancel Invite",
    className: "bg-lightGray w-full sm:w-auto text-center mt-4",
    btnStyle: " block",
    iconStyle: "hidden",
    parentClass: "flex-col sm:flex-row !items-start",
  },
  {
    image: loremIpsum,
    title: "Captain",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
    btnContent: "Remove Player",
    btnStyle: "hidden sm:block text-red",
    iconStyle: "block sm:hidden",
    className: "bg-tamarind",
    icon: <RemoveMemberIcon />,
  },
  {
    image: loremIpsum,
    title: "Player",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
    btnContent: "Remove Player",
    btnStyle: "hidden sm:block text-red",
    iconStyle: "block sm:hidden",
    className: "bg-tamarind",
    icon: <RemoveMemberIcon />,
  },
  {
    image: loremIpsum,
    title: "Player",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
    btnContent: "Remove Player",
    btnStyle: "hidden sm:block text-red",
    iconStyle: "block sm:hidden",
    className: "bg-tamarind",
    icon: <RemoveMemberIcon />,
  },
  {
    image: loremIpsum,
    title: "Player",
    description: "Lorem ipsum",
    gmail: "loremipsum@gmail.com",
    btnContent: "Remove Player",
    btnStyle: "hidden sm:block text-red",
    iconStyle: "block sm:hidden",
    className: "bg-tamarind",
    icon: <RemoveMemberIcon />,
  },
];

export const InviteTeamMateslist = [
  { id: "1", image: playerImage },
  { id: "2", image: playerImage2 },
  { id: "3", image: playerImage3 },
  { id: "4", image: playerImage4 },
  { id: "5", image: playerImage5 },
  { id: "6", image: playerImage },
  { id: "7", image: playerImage2 },
  { id: "8", image: playerImage3 },
  { id: "9", image: playerImage4 },
  { id: "10", image: playerImage5 },
];

export const trophyicons = [
  {
    icon: (
      <GoldenCupIcon className="max-[420px] max-w-[64px] sm:max-w-none h-auto" />
    ),
  },
  {
    icon: (
      <BronzeCupIcon className="max-[420px] max-w-[64px] sm:max-w-none h-auto" />
    ),
  },
];
export const aboutDetails = [
  {
    aboutIcon: <CalendarIcon />,
    abouttitle: "Created at",
    aboutDesc: "16 jun 2023",
  },
  {
    aboutIcon: <CompetedTournamentsIcon />,
    abouttitle: "Competed Tournaments",
    aboutDesc: "4 tournaments",
  },
  {
    aboutIcon: <TrophiesIcon />,
    abouttitle: "Trophies won",
    aboutDesc: "2 trophies",
  },
];
