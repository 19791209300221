export const formatLink = (link: string) => {
  if (link) {
    const externalLink = link.startsWith('https://') || link.startsWith('http://');
    if (externalLink) {
      return link;
    } else {
      return `https://${link}`;
    }
  } else {
    return '';
  }
}
