import { ReactNode } from 'react'
// components
import PopoverText from './PopoverText'

interface IInfoPopover {
  children: ReactNode
  className?: string
}

const InfoPopover: React.FC<IInfoPopover> = ({children, className}) => {
  return (
    <div className={`group relative ${className}`}>
      <button className='w-[14px] h-[14px] aspect-square flex items-center justify-center border border-green rounded-full'>
        <p className='font-compact text-xs text-white'>i</p>
      </button>
      <PopoverText visible={true} className='opacity-0 group-hover:opacity-100 transition-opacity'>
        {children}
      </PopoverText>
    </div>
  )
};

export default InfoPopover;
