import { useRef, useEffect } from 'react';
// components
// - Ads -
import SurfsharkAdMobile from '@components/brands/surfshark/SurfsharkAdMobile';
// -------
import AboutDesktop from './AboutDesktop';
import ProfileTrophies from './TrophiesDesktop';

interface IDetails {
  profileHero: HTMLDivElement | null
}

const Details: React.FC<IDetails> = ({profileHero}) => {
  const detailsContainerRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    setTimeout(resizeListener, 500);

    return () => window.removeEventListener('resize', resizeListener);
  });

  const resizeListener = () => {
    const hero = profileHero;
    const detailsContainer = detailsContainerRef.current;
    if (hero && detailsContainer) {
      (detailsContainer as HTMLDivElement).style.height = `${(hero as HTMLDivElement).offsetHeight}px`
    }
  };

  return (
    <div className="hidden md:flex w-[26%] flex-grow flex-col gap-y-6">
      <div ref={detailsContainerRef} className="w-full flex flex-col gap-y-4">
        <AboutDesktop/>
        <div className="h-fit w-auto">
          <SurfsharkAdMobile/>
        </div>
        {/* <div className="relative bg-lightBlack w-full h-auto !aspect-[1.55] rounded-2xl flex items-center justify-center">
          <FindATeamGray/>
        </div> */}
      </div>
      <ProfileTrophies/>
    </div>
  );
};

export default Details;
