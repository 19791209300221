import { useEffect } from 'react';
// context
import { useTournamentContext } from '../TournamentProvider';
import { useLeaderboardContext } from '../LeaderboardProvider';
// types
import { DBTeam } from '../../../firestore/teams';
// components
import Sidebar from './Sidebar/Sidebar';
import LeaderboardTeamCards from './LeaderboardTeamCards/LeaderboardTeamCards';
// icons
import { VscDebugDisconnect } from "react-icons/vsc";
import { SwordsIcon } from '@components/common/icons/TournamentIcons';

interface ILeaderboardGroup {
  stageComplete: boolean,
  stageNum: number,
  teams: DBTeam[],
  groupId: string,
}

const LeaderboardGroup: React.FC<ILeaderboardGroup> = ({ stageNum, groupId, stageComplete}) => {
  const { tournament, tournamentStages } = useTournamentContext();
  const { leaderboardGroups, selectedGameView, setSelectedGameView } = useLeaderboardContext();
  const { tournamentTeams } = useTournamentContext();

  useEffect(() => {
    return () => setSelectedGameView(0);
  }, []);

  const leaderboardGroup = leaderboardGroups.find((group) => group.groupId === groupId)!;

  const tournamentGroup = tournamentStages.find((stage) => stage.stageNum === stageNum)?.groups.find((group) => group.id === groupId) ?? null;
  const tournamentGroupGames = tournamentGroup ? tournamentGroup.games ?? []: [];

  const groupStarted = tournamentGroupGames.find((game) => game.gamePosition === 1)?.playerCodesDistributed ?? false;

  const totalGroupGames = tournamentGroup ? tournamentGroup.gamesNum : 0;
  const activeGroupGame = tournamentGroup ? tournamentGroup.activeGame : 0;

  const qualifyingSlots = leaderboardGroup.qualifyingSlots;
  const dangerQualifyingSlots = leaderboardGroup.dangerQualifyingSlots;

  const finalScore = stageComplete && leaderboardGroup.final;

  const groupTeams = leaderboardGroup.teams;
  const groupTournamentTeams = tournamentTeams.filter((tournamentTeam) =>
    groupTeams.some((groupTeam) => groupTeam.id === tournamentTeam.id)
  );

  const qualifiedNumber = groupTournamentTeams.filter((groupTeam) =>
    groupTeam.qualifiedStages.includes(leaderboardGroup.stageNum)
  ).length;

  return (
    <div className='flex items-start gap-x-10 mt-3'>
      <Sidebar groupStarted={groupStarted}
               finalGroup={tournament && tournamentGroup ? tournamentGroup.stage === tournament.totalStages - 1 : false}
               groupName={tournamentGroup ? tournamentGroup.groupName : ''}
               groupFinished={tournamentGroup ? tournamentGroup.activeGame > tournamentGroup.gamesNum : false}
               activeGroupGame={activeGroupGame}
               totalGroupGames={totalGroupGames}/>
      <div className='flex-grow'>
        {tournament && tournament.lockedStages.includes(stageNum) ? (
          <>
            {selectedGameView < activeGroupGame ? (
              <LeaderboardTeamCards groupTeams={groupTeams}
                                    leaderboardGroup={leaderboardGroup}
                                    stageComplete={stageComplete}
                                    finalScore={finalScore}
                                    qualifiedNumber={qualifiedNumber}
                                    qualifyingSlots={qualifyingSlots}
                                    dangerQualifyingSlots={dangerQualifyingSlots}/>
            ) : (
              <div className="flex flex-col items-center mt-10 gap-y-2">
              <div className="flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full">
                <SwordsIcon className="w-[24px] h-auto aspect-square fill-white" />
              </div>
              <p className="font-wide text-white text-2xl font-semibold uppercase">
                Game {selectedGameView} - In Progress
              </p>
              <p className="text-steelGray font-compact">
                Data will populate after game has finished
              </p>
            </div>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center mt-10 gap-y-2">
            <div className="flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full">
              <VscDebugDisconnect className="text-white text-2xl" />
            </div>
            <p className="font-wide text-white text-2xl font-semibold uppercase">
              No data
            </p>
            <p className="text-steelGray font-compact">
              Groups will populate after teams have been seeded
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeaderboardGroup;
