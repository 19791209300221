// Importing necessary dependencies and assets
import { Link } from "react-router-dom";
import CompetitiveIncectImg from "../../assets/images/webp/homepage/competitive-insect-img.webp";
import {
  CompetitiveLine,
  CompetitiveLineForMobile,
} from "../common/icons/home/Competitive";
import { CompetitiveIconsList } from "../common/content/HomePageContent";

// Defining the UnleashCompetitive functional component
const UnleashCompetitive = () => {
  return (
    <>
      {/* Container for the competitive banner */}
      <div className="px-4 sm:px-8 lg:px-12 mt-10 sm:mt-0 pb-10 sm:py-16 xl:py-24 overflow-x-hidden  custom_container mx-auto">
        {/* Background container with competitive splash image */}
        <div className="relative rounded-xl sm:rounded-2xl overflow-hidden bg-[url('/src/assets/images/svg/homepage/competitive-bg-splash-img.svg')] object-cover bg-cover bg-no-repeat">
          {/* Left zigzag line for decoration */}
          <span className="absolute hidden lg:block top-2.5 lg:top-10 -left-10 xl:left-0">
            <CompetitiveLine />
          </span>
          {/* Main content wrapper */}
          <div className="flex flex-wrap justify-center lg:justify-between items-center relative">
            {/* Left side content */}
            <div className="w-full lg:w-6/12 xl:w-7/12 pb-6 pt-20 lg:pt-20 lg:pb-14 order-2 lg:order-1 relative max-[420px]:px-4 px-6 sm:px-12  xl:pl-24">
              {/* Full zigzag line for small screens */}
              <span className="absolute lg:hidden block top-[0.225rem] lg:top-[2.063rem] w-full left-0 sm:-left-24 md:-left-32 lg:-left-40 max-[420px]:mt-2 mt-6">
                <CompetitiveLineForMobile />
              </span>
              {/* List of competitive features */}
              <div className="flex items-center flex-wrap gap-2 max-[420px]:mt-3 mt-6 lg:mt-0">
                {CompetitiveIconsList.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="flex items-center bg-lightGray rounded-[22px] py-[0.313rem] px-3 min-[420px]:mt-3"
                    >
                      {data.icon}
                      <p className="text-white pt-1 font-wide text-xs md:text-sm font-bold uppercase pl-2">
                        {data.title}
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* Heading */}
              <h3 className="text-green uppercase font-wide font-bold text-2xl lg:text-[1.75rem] xl:text-[2.25rem] md:!leading-9 max-[420px]:pt-3 pt-6 md:pt-5 max-w-[33.625rem]">
                UNLEASH YOUR COMPETITIVE INSTINCT
              </h3>
              {/* Description */}
              <p className="text-darkGray font-compact font-normal text-base max-[420px]:pt-3 pt-6 leading-[150%] tracking-[0.01rem] max-w-[33.625rem]">
                You're not just a player here. You're part of a global community
                of gamers, constantly pushing boundaries and shattering limits.
                From scouting and team management to intense tournaments and
                trophies. This is where passion meets competition. This is
                esports redefined.
              </p>
              {/* Button */}
              <div className="max-[420px]:pt-3 pt-6 inline-block">
                <Link
                  to="/tournaments"
                  className="bg-green whitespace-nowrap  text-black hover:bg-gorse rounded-xl font-compact text-sm md:text-base font-medium uppercase pt-4 pb-3 px-6 a-auto flex items-center justify-center h-12 w-[10.313rem]"
                >
                  compete now
                </Link>
              </div>
            </div>
            {/* Right side content with an image */}
            <div className="w-full lg:w-6/12 order-1 lg:order-2 max-lg:relative bg-[url('/src/assets/images/svg/homepage/competitive-bg-splash-img.svg')] bg-no-repeat bg-cover bg-right">
              <div className="lg:absolute translate-x-[15%] z-10 lg:translate-x-0 lg:bottom-0 lg:right-0 min-[1340px]:right-28">
                {/* Competitive insect image */}
                <img
                  className="w-full mx-auto lg:w-[40.375rem] xl:w-[49.75rem] sm:h-[18.75rem] lg:h-auto object-scale-down pe-4 md:pe-0 xl:pe-[4rem]"
                  src={CompetitiveIncectImg}
                  alt="Competitive-Incect-Img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Exporting the UnleashCompetitive component
export default UnleashCompetitive;
