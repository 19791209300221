// context
import { useTournamentsContext } from "../TournamentsProvider";
// components
import TournamentCard from "./TournamentCard";
import SkeletonTournamentCard from './SkeletonTournamentCard';
// icons
import { VscDebugDisconnect } from 'react-icons/vsc';


const CompletedList = () => {
  const { completedTournaments, completedTournamentsLoaded } = useTournamentsContext();

  return (
    <div className='overflow-hidden px-3 sm:px-5 lg:px-12 custom_container pt-[calc(3rem-4px)]'>
      <h2 className='text-xl lg:text-2xl text-white font-wide font-semibold uppercase mb-8'>Completed Tournaments</h2>
      {completedTournamentsLoaded ? (
        <>
          {completedTournaments.length > 0 ? (
            <div className="overflow-auto">
              <div className="flex justify-start flex-wrap-0 overflow-auto w-full md:overflow-hidden lg:grid lg:grid-cols-3 xl:grid-cols-4 text-white scrollbar_none gap-8 pb-3">
                {completedTournaments.map((tournament) => (
                  <TournamentCard
                    key={`main-list-${tournament.id}`}
                    tournament={tournament}
                  />
                ))}
              </div>
            </div>
          ) : (
            // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
            // </div>
            <div className="flex flex-col items-center justify-center gap-y-2 min-h-[350px]">
              <div className="flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full">
                <VscDebugDisconnect className="text-white text-2xl" />
              </div>
              <p className="font-wide text-white text-2xl font-semibold uppercase">
                No tournaments found
              </p>
              <p className="text-steelGray font-compact">
                Try adjusting your search parameters
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="overflow-auto">
          <div className="flex overflow-auto w-full md:overflow-hidden md:flex-wrap text-white scrollbar_none pb-3">
            {Array.from({ length: 6 }).map((_i, index) => (
              <SkeletonTournamentCard key={`main-list-${index}`} />
            ))}
          </div>
        </div>
        // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
        // </div>
      )}
    </div>
  );
};

export default CompletedList;
