import findTeamImg from "../../../assets/images/webp/about/find_team_img.webp";
import workUs from "../../../assets/images/webp/about/work-with-us.webp";
import { YellowArrowIcon } from "../icons/AboutIcon";


export const findTeamList = [
  {
    img: findTeamImg,
    url: "/tournaments",
    subTitle: "players",
    title: "Find a team now",
    description:
      "Join forces with other top players and dominate. Versus connects you with like-minded people ready to compete at all levels.",
    btnContent: "BROWSE TEAMS",
    className: "custom_xsm:pe-20 sm:pe-0 xl:pe-1 text-white",
    parentStyle: "bg-lightBlack",
    subTitleStyle: "text-green text-green",
    descriptionStyle: "text-white",
  },
  {
    img: workUs,
    subTitle: "business",
    title: "WORK WITH US",
    url: "/services",
    description:
      "Elevate your brand with industry leaders in esports and gaming. Leverage our experience and tech to drive engagement and deliver results.",
    btnContent: "SERVICES",
    imageShadowStyle: "imageShadow",
    className: "custom_xsm:pe-20 sm:pe-0 xl:pe-1 text-black",
    parentStyle: "bg-green",
    descriptionStyle: "text-black",
    subTitleStyle: "text-black",
    btnStyle:
      "!bg-lightGray hover:!bg-lightGray hover:!bg-opacity-95 text-white",
    icon: <YellowArrowIcon />,
  },
];
