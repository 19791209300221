import { ReactNode } from "react";

interface IShellText {
  children: ReactNode,
  className?: string,
}

const ShellText: React.FC<IShellText> = ({children, className}) => {
  return (
    <p className={`${className} font-wide bg-lightBlack uppercase font-semibold text-steelGray w-full flex items-center justify-center rounded-lg p-3 my-2`}>
      {children}
    </p>
  )
}

export default ShellText;
