import { useEffect, useState, useCallback } from 'react';
// utils
import { isPaypalLinkValid } from '@src/utils/validations/paypal';
// components
import Input from '@ui/Input';

interface ICheckInPaymentSettings {
  paypalLink: string,
  setPaypalLink: (newLink: string) => void,
  setStepValid: (valid: boolean) => void,
}

const CheckInPaymentSettings: React.FC<ICheckInPaymentSettings> = ({paypalLink, setPaypalLink, setStepValid}) => {

  const [paypalValid, setPaypalValid] = useState<boolean>(false);

  const validatePaypal = useCallback(() => {
    setPaypalValid(paypalLink === '' || isPaypalLinkValid(paypalLink));
  }, [paypalLink]);

  useEffect(() => {
    validatePaypal();
  }, [paypalLink, validatePaypal])

  useEffect(() => {
    setStepValid(paypalLink.length > 0 && paypalValid);
  }, [paypalLink, paypalValid, setStepValid]);

  return (
    <div className="flex flex-col gap-y-3 text-steelGray font-compact">
      <Input value={paypalLink} onChange={(newValue) => setPaypalLink(newValue)}
             valid={paypalValid}
             black={true}
             inputClassName='text-white'
             placeholder='paypal.me/username - john.smith@gmail.com'/>
      <p>
        Verify your payment information is correct. This is the last chance to make changes until the tournament concludes.
      </p>
      <p className="text-red/70">
        Versus is not liable for any unpaid winnings due to incorrect payment information!
      </p>
    </div>
  )
}

export default CheckInPaymentSettings;
