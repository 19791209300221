import { useState, FormEvent } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
// context
import { useHeaderContext } from "@provider/HeaderProvider";
// libaries
import { toast } from "react-toastify";
// components
import { InputWrapper } from "@components/common/inputfield-wrapper/InputWrapper";
import { InputCross } from "@icons/Common";
// icons
import { CrossIcon } from "@icons/Header";
import { auth } from "../../../firebase";

const ForgotPassword = () => {
  const { setNavbarState, navbarState } = useHeaderContext();

  const [email, setEmail] = useState<string>('');
  const [activeInput, setActiveInput] = useState<string>('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email)
      toast.info("A link has been sent to your email")
      setEmail('');
      closeModal();
      setNavbarState({
        ...navbarState,
        forgotPasswordMenuVisible: false,
        profileMenuVisible: false,
      });
    } catch (error) {
      toast.error("There was an error sending a reset email, please contact support if this issue persists")
      console.error(error);
    }
  };

  const closeModal = () => {
    setNavbarState({
      ...navbarState,
      forgotPasswordMenuVisible: false,
    });
  };

  return (
    <div className="bg-lightBlack sm:rounded-xl w-full h-[300px] theme_scroll_bar flex flex-col">
      {/* Header section with title and close button */}
      <div className="flex items-center justify-between border-b border-lightGray px-4 py-3">
        <h2 className="font-wide text-white text-base font-bold leading-normal tracking-[0.01rem]">
          FORGOT PASSWORD?
        </h2>
        {/* Close button */}
        <span className="cursor-pointer" onClick={closeModal}>
        <CrossIcon />
        </span>
      </div>
      {/* Social login options section */}
      <div className="px-4 sm:px-6 pb-5 h-3/4 flex-grow">
        <form onSubmit={handleSubmit}
              className="flex flex-col justify-between h-full">
          <div className="flex flex-col pt-6">
            <label
              htmlFor="forgotEmail"
              className="font-compact text-base font-normal text-white tracking-[0.01rem] leading-normal text-start"
            >
              Email
            </label>
            <InputWrapper>
              <div className="relative w-full">
                <input
                  className={`!bg-transparent w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 px-4 rounded-xl ${
                    activeInput === 'email'
                      ? "border-green border-2 pr-10 placeholder:text-white"
                      : "border-transparent border-2"
                  }`}
                  onClick={() => setActiveInput('email')}
                  required
                  id="forgotEmail"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email associated with your account"
                />
                {activeInput === 'email' && email ? (
                  <span
                    onClick={() => setEmail('')}
                    className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
                  >
                    <InputCross />
                  </span>
                ): ''}
              </div>
            </InputWrapper>
            <p className="text-steelGray text-left font-semibold mt-3 text-sm">
              If an account matches your email you will recieve instructions to reset your password
            </p>
          </div>
          <div className="">
            <button
              type="submit"
              disabled={!/\w+@\w+\.\w+/.test(email)}
              className="font-compact w-full text-base font-medium py-4 !leading-4 rounded-xl uppercase mt-6 block text-center bg-green hover:bg-gorse text-black
                         disabled:opacity-50 disabled:hover:bg-green">
              Send recovery email
            </button>

            <p className="font-compact text-sm font-normal text-white tracking-[0.01rem] !leading-4 text-center mt-6">
              Remember your password?&nbsp;
              <span
                onClick={() => {
                  setNavbarState({
                    ...navbarState,
                    forgotPasswordMenuVisible: false,
                    logInMenuVisible: true,
                  });
                }}
                className="text-green hover:opacity-7 duration-200 cursor-pointer inline-block hover:text-gorse">
                Login
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

// Exporting the Login component as the default export
export default ForgotPassword;
