import { firestore } from '../firebase';
import { doc, getDoc, DocumentSnapshot, SnapshotOptions, Timestamp, DocumentData } from 'firebase/firestore';
// types
import { ApexPoiPreferences, DBTeam, PlayerData, PlayerDataRaw } from './teams';
import { Game } from '@customTypes/Games/Games';
import { Platform } from '@customTypes/Platforms';

export const tournamentStageNumToName = (tournament: Tournament | null, stageNum: number) => {
  if (tournament) {
    if (tournament.totalStages === 1) {
      return 'Finals'
    } else if (tournament.totalStages === 2) {
      switch (stageNum) {
        case 0:
          return 'Group Stage'
        case 1:
          return 'Finals'
      }
    } else if (tournament.totalStages === 3) {
      switch (stageNum) {
        case 0:
          return 'Group Stage'
        case 1:
          return 'Semi-Finals'
        case 2:
          return 'Finals'
      }
    } else if (tournament.totalStages === 4) {
      switch (stageNum) {
        case 0:
          return 'Group Stage'
        case 1:
          return 'Quarter-Finals'
        case 2:
          return 'Semi-Finals'
        case 3:
          return 'Finals'
      }
    }
  }
}

export const tournamentMenus = {
  SUMMARY: 'summary',
  GROUPS: 'groups',
  RESULTS: 'results',
  ENTRANTS: 'entrants',
  RULES: 'rules'
}

export enum TournamentTeamStatus {
  registered,
  waiting,
  confirmed,
  declined,
  ignored,
}

export interface TournamentGame {
  id: string,
  completed: boolean,
  failedAttempt: Timestamp | null,
  gamePosition: number,
  gameName: string,
  playerCode: string,
  playerCodesDistributed: boolean,
  adminCode: string,
  statsToken: string,
  codeId: string,
  map: string,
}

export interface TournamentStage {
  stageNum: number,
  groups: TournamentGroup[],
}

export const checkIfStageActive = (stageNum: number, tournament: Tournament | null) => {
  return tournament && tournament.status === 3 && tournament.activeStage === stageNum;
};

export interface TournamentGroup {
  id: string,
  activeGame: number,
  groupNum: number,
  groupName: string,
  qualifyingSlots: number,
  stage: number,
  gamesNum: number,
  games: TournamentGame[] | undefined
}

export type ApexPoiAllocations = Record<string, ApexPoiAllocation>; 

export interface ApexPoiAllocation {
  'WE': number,
  'SP': number,
  'ED': number
}

export interface TournamentTeamRaw {
  id: string,
  groups: {groupId: string, groupPosition: number}[],
  teamName: string,
  teamCaptain: string,
  poiPreferences: ApexPoiPreferences,
  poiAllocations: ApexPoiAllocations // ex: {'[group_id]': {'WE': 3, 'SP': 4, 'ED': 12}}
  joinedAt: Timestamp,
  status: TournamentTeamStatus,
  fromWaitingList: boolean,
  qualifiedStages: number[],
  performanceHistoryId: string,
  eliminated: boolean,
  matchPointEligible: boolean,
  participatingPlayers: string[],
  participatingPlayerData: PlayerDataRaw[]
  DBTeam: DBTeam
}
export interface TournamentTeam {
  id: string,
  groups: {groupId: string, groupPosition: number}[],
  teamName: string,
  teamCaptain: string,
  poiPreferences: ApexPoiPreferences,
  poiAllocations: ApexPoiAllocations // ex: {'[group_id]': {'WE': 3, 'SP': 4, 'ED': 12}}
  joinedAt: Timestamp,
  status: TournamentTeamStatus,
  fromWaitingList: boolean,
  qualifiedStages: number[],
  performanceHistoryId: string,
  eliminated: boolean,
  matchPointEligible: boolean,
  participatingPlayers: string[],
  participatingPlayerData: PlayerData[]
  DBTeam: DBTeam
}

export type TournamentTrophy  = {
  tournamentId: string,
  tournamentName: string,
  finalPosition: 1 | 2 | 3,
  tournamentDate: Date,
  winnings: number,
}

export enum Region {
  NA,
  EMEA,
  LATAM,
  APAC,
  GLOBAL
}

export const tournamentRegionToString = (region: Region): string => {
  switch (region) {
    case Region.EMEA:
      return 'EMEA';
    case Region.NA:
      return 'NA';
    case Region.APAC:
      return 'APAC';
    case Region.LATAM:
      return 'LATAM';
    case Region.GLOBAL:
      return 'GLOBAL';
    default:
      return '';
  }
}

export interface Prize {
  special: boolean,
  position?: number,
  prizeName: string,
  prizeDescription: string,
  prizeAmount: number,
}

interface PrizePool {
  currency: string, // '$'
  totalPrizePool: number,
  prizes: Prize[],
}

export interface RuleBlock {
  type: 'heading' | 'subheading' | 'text' | 'bulletPoint' |'boldText' | 'italicText'
  body: string,
}

interface TournamentWinner {
  DBTeam: DBTeam,
  points: number,
}

export enum TournamentStatus {
  registration,
  confirmation,
  prep,
  ongoing,
  results
}

export const tournamentStatusToString = (status: TournamentStatus): string => {
  switch (status) {
    case TournamentStatus.registration:
      return "Registration";
    case TournamentStatus.confirmation:
      return "Confirmation";
    case TournamentStatus.prep:
      return "Preparation";
    case TournamentStatus.ongoing:
      return "Ongoing";
    // case TournamentStatus.results:
    //   return "Results";
    case TournamentStatus.results:
      return "Completed";
    default:
      throw new Error("Invalid Tournament Status");
      return "";
  }
};

interface StatusDates {
  // we dont include registration as that is open unless asnother date is passed
  confirmation: Date,
  ongoing: Date,
  results: Date,
}

export interface MatchPointPlannedMap {
  gameNum: number,
  map: string,
}

export interface Tournament {
  id: string,
  brand: string,
  announce: number,
  matchDataPresent: boolean,
  hidden: boolean,
  name: string,
  summary: string,
  highPerformance: boolean,
  discordSupport: string,
  format: string,
  matchPointFormatFinish: boolean,
  matchPointFormatThreshold: number,
  matchPointFormatMaxGames: number,
  matchPointFormatPlannedMaps: MatchPointPlannedMap[],
  winner: TournamentWinner | null,
  active: boolean,
  status: TournamentStatus,
  statusDates: StatusDates,
  region: Region,
  entryFee: number,
  prizePool: PrizePool,
  game: Game,
  gameMode: string,
  rules: string,
  teamSize: number, // minimum number of players in a team to be able to enter
  maxTeamSize: number, // maximum number of players in a team to be able to enter (teamSize + subs)
  premium: boolean, // is tournament only for premium (subscription) members
  entrantsCount: number,
  teamCapacity: number,
  winners?: string[],
  winningsDistributed: boolean,
  cardImage: string,
  bannerImage: string,
  platform: Platform,
  completed: boolean,
  stagesInPlay: number[],
  startedStages: number[],
  lockedStages: number[],
  pointSystem: string,
  interStages: number,
  totalStages: number,
  activeStage: number,
  streams: {
    activeStream: string,
    twitch: string,
    youtube: string,
  },
  vods: {
    activeVod: string,
    twitch: string,
    youtube: string,
  }
  createdAt: Date,
}

export async function getTournament(id: string) {
  const ref = doc(firestore, 'tournaments', id).withConverter(tournamentConverter);
  const docSnap = await getDoc(ref);
  return docSnap.data();
}

export const tournamentConverter = {
  toFirestore: (tournament: Tournament) => {
    const backupStartDateTimestamp = new Date().getTime() + 604_800_000 // current date + 1 week (in milliseconds)
    return {
      id: tournament.id ?? '',
      brand: tournament.brand ?? '',
      announce: tournament.announce ?? 0,
      matchDataPresent: tournament.matchDataPresent ?? false,
      hidden: tournament.hidden ?? false,
      highPerformance: tournament.highPerformance ?? false,
      discordSupport: tournament.discordSupport ?? '',
      format: tournament.format ?? '',
      matchPointFormatFinish: tournament.matchPointFormatFinish ?? false,
      matchPointFormatThreshold: tournament.matchPointFormatThreshold ?? 40,
      matchPointFormatMaxGames: tournament.matchPointFormatMaxGames ?? 6,
      matchPointFormatPlannedMaps: tournament.matchPointFormatPlannedMaps ?? [],
      name: tournament.name ?? '',
      winner: tournament.winner ?? null,
      summary: tournament.summary ?? '',
      active: tournament.active ?? true,
      status: tournament.status ?? TournamentStatus.registration,
      statusDates: tournament.statusDates ?? {
        confirmation: backupStartDateTimestamp + 86_400_000, // same as startDate
        ongoing: backupStartDateTimestamp + 172_800_000, // 1 day after
        results: backupStartDateTimestamp + 259_200_000, // 2 day after
      },
      region: tournament.region ?? Region.EMEA,
      entryFee: tournament.entryFee ?? 0,
      prizePool: tournament.prizePool ?? "",
      game: tournament.game ?? Game.apex,
      gameMode: tournament.gameMode ?? "",
      rules: tournament.rules ?? '',
      teamSize: tournament.teamSize ?? 3,
      maxTeamSize: tournament.maxTeamSize ?? 5,
      premium: tournament.premium ?? false,
      entrantsCount: tournament.entrantsCount ?? 0,
      teamCapacity: tournament.teamCapacity ?? 10,
      winners: tournament.winners ?? [],
      winningsDistributed: tournament.winningsDistributed ?? false,
      cardImage: tournament.cardImage ?? "",
      bannerImage: tournament.bannerImage ?? "",
      platform: tournament.platform ?? Platform.crossplay,
      completed: tournament.completed ?? false,
      stagesInPlay: tournament.stagesInPlay ?? [],
      startedStages: tournament.startedStages ?? [],
      lockedStages: tournament.lockedStages ?? [],
      pointSystem: tournament.pointSystem ?? '',
      interStages: tournament.interStages ?? 0,
      totalStages: tournament.totalStages ?? 1,
      activeStage: tournament.activeStage ?? 0,
      streams: tournament.streams ?? {activeStream: '', twitch: '', youtube: ''},
      vods: tournament.streams ?? {activeVod: '', twitch: '', youtube: ''},
      createdAt: tournament.createdAt ?? new Date(),
    };
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)!;
    const tournament: Tournament = {
      id: snapshot.id,
      brand: data.brand ?? '',
      announce: data.announce ?? 0,
      matchDataPresent: data.matchDataPresent ?? false,
      hidden: data.hidden,
      highPerformance: data.highPerformance ?? false,
      discordSupport: data.discordSupport ?? '',
      format: data.format ?? '',
      matchPointFormatFinish: data.matchPointFormatFinish ?? false,
      matchPointFormatThreshold: data.matchPointFormatThreshold ?? 40,
      matchPointFormatMaxGames: data.matchPointFormatMaxGames ?? 6,
      matchPointFormatPlannedMaps: data.matchPointFormatPlannedMaps ?? [],
      winner: data.winner,
      name: data.name,
      summary: data.summary,
      active: data.active,
      status: data.status,
      statusDates: {
        confirmation: new Date(data.statusDates.confirmation.seconds * 1000), // firebase timestamp looks like this {seconds: number, nanoseconds: number}
        ongoing: new Date(data.statusDates.ongoing.seconds * 1000), // unix timestamp needs to be in milliseconds so we .seconds * 1000
        results: new Date(data.statusDates.results.seconds * 1000)
      },
      region: data.region,
      entryFee: data.entryFee,
      prizePool: data.prizePool,
      game: data.game ?? Game.apex,
      gameMode: data.gameMode,
      rules: data.rules ?? '',
      teamSize: data.teamSize,
      maxTeamSize: data.maxTeamSize,
      premium: data.premium,
      entrantsCount: data.entrantsCount ?? 0,
      teamCapacity: data.teamCapacity,
      winners: data.winners,
      winningsDistributed: data.winningsDistributed ?? false,
      cardImage: data.cardImage,
      bannerImage: data.bannerImage,
      platform: data.platform ?? Platform.crossplay,
      completed: data.completed,
      stagesInPlay: data.stagesInPlay,
      startedStages: data.startedStages,
      lockedStages: data.lockedStages,
      pointSystem: data.pointSystem,
      interStages: data.interStages,
      totalStages: data.totalStages,
      activeStage: data.activeStage,
      streams: data.streams,
      vods: data.vods,
      createdAt: new Date(data.createdAt.seconds * 1000)
    };
    return tournament;
  },
};

export const algoliaTournamentConverter = (rawTournament: DocumentData): Tournament => {
  return {
    id: rawTournament.id ?? '',
    brand: rawTournament.brand ?? '',
    announce: rawTournament.announce ?? 0,
    matchDataPresent: rawTournament.matchDataPresent ?? false,
    hidden: rawTournament.hidden,
    highPerformance: rawTournament.highPerformance ?? false,
    discordSupport: rawTournament.discordSupport ?? '',
    format: rawTournament.format ?? '',
    matchPointFormatFinish: rawTournament.matchPointFormatFinish ?? false,
    matchPointFormatThreshold: rawTournament.matchPointFormatThreshold ?? 40,
    matchPointFormatMaxGames: rawTournament.matchPointFormatMaxGames ?? 6,
    matchPointFormatPlannedMaps: rawTournament.matchPointFormatPlannedMaps ?? [],
    winner: rawTournament.winner,
    name: rawTournament.name,
    summary: rawTournament.summary,
    active: rawTournament.active,
    status: rawTournament.status,
    statusDates: {
      confirmation: new Date(rawTournament.statusDates.confirmation.seconds * 1000), // firebase timestamp looks like this {seconds: number, nanoseconds: number}
      ongoing: new Date(rawTournament.statusDates.ongoing.seconds * 1000), // unix timestamp needs to be in milliseconds so we .seconds * 1000
      results: new Date(rawTournament.statusDates.results.seconds * 1000)
    },
    region: rawTournament.region,
    entryFee: rawTournament.entryFee,
    prizePool: rawTournament.prizePool,
    game: rawTournament.game ?? Game.apex,
    gameMode: rawTournament.gameMode,
    rules: rawTournament.rules ?? '',
    teamSize: rawTournament.teamSize,
    maxTeamSize: rawTournament.maxTeamSize,
    premium: rawTournament.premium,
    entrantsCount: rawTournament.entrantsCount ?? 0,
    teamCapacity: rawTournament.teamCapacity,
    winners: rawTournament.winners,
    winningsDistributed: rawTournament.winningsDistributed ?? false,
    cardImage: rawTournament.cardImage,
    bannerImage: rawTournament.bannerImage,
    platform: rawTournament.platform ?? Platform.crossplay,
    completed: rawTournament.completed,
    stagesInPlay: rawTournament.stagesInPlay,
    startedStages: rawTournament.startedStages,
    lockedStages: rawTournament.lockedStages,
    pointSystem: rawTournament.pointSystem,
    interStages: rawTournament.interStages,
    totalStages: rawTournament.totalStages,
    activeStage: rawTournament.activeStage,
    streams: rawTournament.streams,
    vods: rawTournament.vods,
    createdAt: new Date(rawTournament.createdAt.seconds * 1000)
  };
}
