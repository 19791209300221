import { useState, useEffect } from 'react'
// firebase
import { firestore } from '@src/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

interface LatestBuildVersion {
  version: string,
}

const VersionCheck = () => {
  const [latestBuildVersion, setLatestBuildVersion] = useState<string>('');

  useEffect(() => {
    const clientBuildVersion: string = import.meta.env.CURRENT_BUILD_VERSION;

    if (latestBuildVersion) {
      console.log('Client Build Version:', clientBuildVersion);
      console.log('Latest Build Version:', latestBuildVersion);

      if (clientBuildVersion !== latestBuildVersion) {
        window.location.reload();
      }
    }

  }, [latestBuildVersion]);

  const geLatestBuildVersion = () => {
    const latestBuildVersionRef = doc(firestore, 'globals', 'latestBuildVersion');

    const unsubscribe = onSnapshot(latestBuildVersionRef, (snapshot) => {
      if (snapshot.data()) {
        const localLatestBuildVersion = (snapshot.data() as LatestBuildVersion).version;
        setLatestBuildVersion(localLatestBuildVersion);
      }
    })

    return unsubscribe;
  }

  useEffect(() => {
    const unsubscribe = geLatestBuildVersion();

    return () => unsubscribe();
  }, []);

  return '';
}

export default VersionCheck
