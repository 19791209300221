import { FaCheck, FaCrown, FaListUl } from 'react-icons/fa';
import { useLeaderboardContext } from '../../LeaderboardProvider';
import { Trophy } from '@components/common/icons/Common';
import { useTournamentContext } from '../../TournamentProvider';
import { useALGSModeMapContext } from '../../ALGSModeMapProvider';

interface ISidebar {
  groupStarted: boolean,
  finalGroup: boolean,
  groupName: string,
  groupFinished: boolean,
  totalGroupGames: number,
  activeGroupGame: number
}

const Sidebar: React.FC<ISidebar> = ({groupStarted, groupName, groupFinished, finalGroup, totalGroupGames, activeGroupGame}) => {
  const { setGameNumber } = useALGSModeMapContext();
  const { tournament } = useTournamentContext();
  const { selectedGameView, setSelectedGameView } = useLeaderboardContext();

  const shellGamesCount = tournament ? tournament.matchPointFormatMaxGames - totalGroupGames : 0;

  return tournament ? (
    <div className={`rounded-xl w-[200px] h-fit font-wide text-steelGray bg-black flex flex-col gap-y-2`}>
      <button type='button' aria-label='Set leaderboard group view to Overall'
              onClick={() => setSelectedGameView(0)}
              className={`flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact font-thin border-lightGray
                        hover:bg-lightGray/50 transition-all ${selectedGameView === 0 ? 'bg-lightGray' : ''}
                         ${groupFinished ? 'text-green' : 'text-white'}`}>
        <span>{groupName === 'Final' ? 'Finals' : groupName} - {groupFinished ? 'Results' : groupStarted ? 'Overall' : 'Team Slots'}</span>
        {finalGroup && groupFinished ? (
          <Trophy className='w-[16px] h-auto aspect-square fill-green'/>
        ) : (
          <FaListUl className="text-xl" />
        )}
      </button>
      {groupStarted ? (
        <>
          {Array.from({length: totalGroupGames}).map((_i, index) => {
            return (
              <button key={`sidebar-game-${index + 1}-button`} type='button' aria-label={`Set leaderboard group view to Game ${index + 1}`}
                      disabled={!groupStarted || index + 1 > activeGroupGame}
                      onClick={() => {
                        setGameNumber(index + 1);
                        setSelectedGameView(index + 1);
                      }}
                      className={`flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact font-thin border-lightGray text-white
                                  hover:bg-lightGray/50 transition-all ${selectedGameView === index + 1 ? 'bg-lightGray' : ''}
                                  ${index + 1 > activeGroupGame ? 'hover:bg-transparent' : ''}`}>
                <span className={`${groupStarted && activeGroupGame === index + 1 ? 'text-green' : groupStarted && activeGroupGame < index + 1 ? 'text-steelGray' : 'text-white'}`}>Game {index + 1}</span>
                {activeGroupGame > index + 1 ? (index === totalGroupGames - 1 && tournament.status === 4 && tournament.matchPointFormatFinish) ? (
                  <FaCrown className="text-xl fill-green" />
                ) : (
                  <FaCheck className="text-xl" />
                ) : groupStarted && activeGroupGame === index + 1 ? (
                  <div className='w-[15px] h-auto aspect-square rounded-full bg-green'>
                  </div>
                ) : (
                  <div className={`w-[15px] h-auto aspect-square rounded-full
                                  ${selectedGameView === index + 1 ? 'bg-ebonyClay' : 'bg-lightGray'} transition-all`}>
                  </div>
                )}
              </button>
            );
          })}
          {tournament.format === 'Match Point' && finalGroup && tournament.status < 4 ? (
            <>
              {Array.from({length: shellGamesCount}).map((_i, index) => {
                return (
                  <div key={`sidebar-game-${totalGroupGames + 1 + index}-shell-button`}
                          className='flex items-center justify-between px-4 w-full h-[45px] rounded-xl border font-compact font-thin border-lightGray text-white
                                      transition-all hover:bg-transparent'>
                    <span className='text-steelGray'>Game {totalGroupGames + 1 + index}</span>
                    <div className='w-[15px] h-auto aspect-square rounded-full bg-lightGray transition-all'>
                    </div>
                  </div>
                );
              })}
            </>
          ) : ''}
        </>
      ) : ''}
    </div>
  ) : '';
}

export default Sidebar
