import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// firebase
import { firestore } from '../firebase';
import { arrayUnion, Timestamp, Unsubscribe } from 'firebase/firestore';
import { doc, getDoc, updateDoc, query, where, collection, onSnapshot, arrayRemove, getDocs } from 'firebase/firestore';
// context
import { useAuthContext } from './AuthContextProvider';
import { useDateTimeContext } from './DateTimeProvider';
// types
import { Notification, notificationConverter } from '../firestore/notifications';
import { teamConverter } from '../firestore/teams';
import { Tournament, tournamentConverter, TournamentTeam, TournamentTeamStatus } from '../firestore/tournaments';
import { userConverter } from '@src/firestore/users';
// libaries
import { toast } from 'react-toastify';

export type TeamInvite = {
  captainId: string,
  captainName: string,
  captainDisplayImage: string,
  teamId: string,
  teamName: string,
  timeReceived: Date,
}

interface ConfirmDeclinePresenceOptions {
  participatingPlayers: string[],
}

interface INotificationContext {
  teamInvites: TeamInvite[],
  acceptTeamInvite: (teamId: string, inviteIndex: number) => Promise<void>,
  declineTeamInvite: (teamId: string, inviteIndex: number) => void,
  confirmTournamentPresence: (notification: Notification, options: ConfirmDeclinePresenceOptions) => void,
  declineTournamentPresence: (notification: Notification) => void,
  activeTournaments: Tournament[],
  notifications: Notification[],
  dismissNotification: (notification: Notification) => void,
  currentTime: number, //ms timestamp
  notificationsCount: number,
  activeTournamentsCount: number,
}

const defaultNotificationContext = {
  teamInvites: [],
  acceptTeamInvite: async (teamId: string, inviteIndex: number) => {
    [teamId, inviteIndex];
  },
  declineTeamInvite: (teamId: string, inviteIndex: number) => {
    [teamId, inviteIndex];
  },
  confirmTournamentPresence: (notification: Notification, options: ConfirmDeclinePresenceOptions) => {
    [notification, options];
  },
  declineTournamentPresence: (notification: Notification) => {
    notification;
  },
  notifications: [],
  dismissNotification: (notification: Notification) => notification,
  currentTime: -1,
  activeTournaments: [],
  notificationsCount: 0,
  activeTournamentsCount: 0
}

const NotificationContext = createContext<INotificationContext>(defaultNotificationContext);

// eslint-disable-next-line react-refresh/only-export-components
export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  return context;
}

interface INotificationProvider {
  children: ReactNode,
}

const NotificationProvider: React.FC<INotificationProvider> = ({ children }) => {
  const navigate = useNavigate();

  const { userObj, userTeam } = useAuthContext();
  const { currentTime } = useDateTimeContext();

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [brandedTimeoutNotifications, setBrandedTimeoutNotifications] = useState<Notification[]>([]);
  const [brandedNoTimeoutNotifications, setBrandedNoTimeoutNotifications] = useState<Notification[]>([]);

  const [globalTimeoutNotifications, setGlobalTimeoutNotifications] = useState<Notification[]>([]);
  const [globalNoTimeoutNotifications, setGlobalNoTimeoutNotifications] = useState<Notification[]>([]);

  const [timeoutNotifications, setTimeoutNotifications] = useState<Notification[]>([]);
  const [noTimeoutNotifications, setNoTimeoutNotifications] = useState<Notification[]>([]);

  const [dismissedBrandedNotifs, setDismissedBrandedNotifications] = useState<string[]>([]);

  const [teamInvites, setTeamInvites] = useState<TeamInvite[]>([]);
  const [activeTournaments, setActiveTournaments] = useState<Tournament[]>([]);

  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [activeTournamentsCount, setActiveTournamentsCount] = useState<number>(0);

  const filterTimeoutNotifications = useCallback(() => {
    setTimeoutNotifications((prevNotifs) => {
      return prevNotifs.filter((notif) => notif.timeout!.getTime() > currentTime);
    });
  }, [currentTime]);

  useEffect(() => {
    filterTimeoutNotifications();
  }, [currentTime, filterTimeoutNotifications]);

  const dismissNotification = async (notification: Notification) => {
    if (userObj && notification.recipient === 'global') {
      const userRef = doc(firestore, 'users', userObj.uid);
      await updateDoc(userRef, {
        dismissedGlobalNotifications: arrayUnion(notification.id)
      });
    } else if (notification.recipient !== 'branded') {
      const notificationRef = doc(firestore, 'notifications', notification.id);
      await updateDoc(notificationRef, {
        dismissed: true
      })
    } else {
      setDismissedBrandedNotifications((prevIds) => [...prevIds, notification.id]);
    }

    setNoTimeoutNotifications((prevNotifications) => prevNotifications.filter((prevNotification) => prevNotification.id !== notification.id));
    setTimeoutNotifications((prevNotifications) => prevNotifications.filter((prevNotification) => prevNotification.id !== notification.id));
  }

  const getNotifications = useCallback(() => {
    let unsubscribe: () => void = () => false;

    if (userObj) {
      const notificationsCollection = collection(firestore, 'notifications').withConverter(notificationConverter);

      const globalBrandedNotificationsTimeoutQuery = query(notificationsCollection,  where('recipient', '==', 'branded'), where('dismissed', '==', false), where('timeout', '!=', null), where('timeout', '>=', Timestamp.now()));
      const globalBrandedNotificationsNoTimeoutQuery = query(notificationsCollection,  where('recipient', '==', 'branded'), where('dismissed', '==', false), where('timeout', '==', null));

      const brandedTimeoutUnsub = onSnapshot(globalBrandedNotificationsTimeoutQuery, (snapshots) => {
        let localNotifications: Notification[] = [];
        snapshots.docs.forEach((doc) => {
          localNotifications.push(doc.data())
        });
        localNotifications = localNotifications.filter((notification) => !userObj.dismissedGlobalNotifications.includes(notification.id));
        setBrandedTimeoutNotifications(localNotifications);
      });

      const brandedNoTimeoutUnsub = onSnapshot(globalBrandedNotificationsNoTimeoutQuery, (snapshots) => {
        let localNotifications: Notification[] = [];
        snapshots.docs.forEach((doc) => {
          localNotifications.push(doc.data())
        });
        localNotifications = localNotifications.filter((notification) => !userObj.dismissedGlobalNotifications.includes(notification.id));
        setBrandedNoTimeoutNotifications(localNotifications);
      });

      const globalNotificationsTimeoutQuery = query(notificationsCollection,  where('recipient', '==', 'global'), where('dismissed', '==', false), where('timeout', '!=', null), where('timeout', '>=', Timestamp.now()), where('timeReceived', '>=', Timestamp.fromDate(userObj.createdAt)));
      const globalNotificationsNoTimeoutQuery = query(notificationsCollection,  where('recipient', '==', 'global'), where('dismissed', '==', false), where('timeout', '==', null), where('timeReceived', '>=', Timestamp.fromDate(userObj.createdAt)));

      const globalTimeoutUnsub = onSnapshot(globalNotificationsTimeoutQuery, (snapshots) => {
        let localNotifications: Notification[] = [];
        snapshots.docs.forEach((doc) => {
          localNotifications.push(doc.data())
        });
        localNotifications = localNotifications.filter((notification) => !userObj.dismissedGlobalNotifications.includes(notification.id));
        setGlobalTimeoutNotifications(localNotifications);
      });

      const globalNoTimeoutUnsub = onSnapshot(globalNotificationsNoTimeoutQuery, (snapshots) => {
        let localNotifications: Notification[] = [];
        snapshots.docs.forEach((doc) => {
          localNotifications.push(doc.data())
        });
        localNotifications = localNotifications.filter((notification) => !userObj.dismissedGlobalNotifications.includes(notification.id));
        setGlobalNoTimeoutNotifications(localNotifications);
      });

      const timeoutNotificationsQuery = query(notificationsCollection, where('dismissed', '==', false), where('recipient', '==', userObj.uid), where('timeout', '!=', null), where('timeout', '>=', Timestamp.now()));
      const timeoutUnsub = onSnapshot(timeoutNotificationsQuery, (snapshots) => {
        const localNotifications: Notification[] = snapshots.docs.map((doc) => doc.data());
        setTimeoutNotifications(localNotifications);
      });

      const noTimeoutNotificationsQuery = query(notificationsCollection, where('recipient', '==', userObj.uid), where('timeout', '==', null), where('dismissed', '==', false));
      const noTimeoutUnsub = onSnapshot(noTimeoutNotificationsQuery, (snapshots) => {
        const localNotifications: Notification[] = snapshots.docs.map((doc) => doc.data());
        setNoTimeoutNotifications(localNotifications);
      });

      unsubscribe = () => [globalTimeoutUnsub, globalNoTimeoutUnsub, brandedTimeoutUnsub, brandedNoTimeoutUnsub, timeoutUnsub, noTimeoutUnsub].forEach((unsub) => unsub());
    }

    return unsubscribe;
  }, [userObj])

  const getActiveTournaments = useCallback(() => {
    let unsubscribe: Unsubscribe | (() => void) = () => false;

    if (userObj && userTeam) {
      const activeTournamentIds = userTeam.activeTournaments;

      if (activeTournamentIds.length > 0) {
        const activeTournamentsQuery = query(collection(firestore, 'tournaments'), where('id', 'in', activeTournamentIds)).withConverter(tournamentConverter);
        unsubscribe = onSnapshot(activeTournamentsQuery, (snapshots) => {
          const activeTournaments = snapshots.docs.map((doc) => doc.data()).sort((a, b) => a.statusDates.ongoing.getTime() - b.statusDates.ongoing.getTime());
          setActiveTournaments(activeTournaments);
        })
      } else {
        setActiveTournaments([]);
      }
    }

    return () => unsubscribe();
  }, [userObj, userTeam])

  const getTeamInvites = useCallback(async () => {
    if (userObj) {
      const teamRequests = userObj.teamRequests;
      const localTeamInvites = [];
      for (const teamRequest of teamRequests) {
        const teamRef = doc(firestore, 'teams', teamRequest.teamId).withConverter(teamConverter);
        const team = (await getDoc(teamRef)).data();
        if (team && !team.dissolved) {
          const teamCaptainRef = doc(firestore, 'users', team.captain).withConverter(userConverter);
          const teamCaptain = (await getDoc(teamCaptainRef)).data()!;
          localTeamInvites.push({
            captainId: teamCaptain.uid!,
            captainName: teamCaptain.displayName,
            captainDisplayImage: teamCaptain.displayImage,
            teamId: team.id!,
            teamName: team.teamName,
            timeReceived: teamRequest.timeReceived
          });
        } else {
          const userRef = doc(firestore, 'users', userObj.uid).withConverter(userConverter);
          const newTeamRequests = userObj.teamRequests.filter((request) => request.teamId !== teamRequest.teamId)
          updateDoc(userRef, {
            teamRequests: newTeamRequests
          });
        }
      }
      setTeamInvites(localTeamInvites);
    }
  }, [userObj, setTeamInvites])

  const confirmTournamentPresence = async (notification: Notification, options: ConfirmDeclinePresenceOptions) => {
    if (userTeam && notification) {
      const body = notification.body as {tournamentId: string};
      const { participatingPlayers } = options;

      try {
        const tournamentId = body.tournamentId;
        const tournamentRef = doc(firestore, 'tournaments', tournamentId).withConverter(tournamentConverter);
        const tournament = (await getDoc(tournamentRef)).data()!;
        // tournamentTeams sub collection for registered slots + manipulations
        const tournamentTeamsCollection = collection(firestore, 'tournaments', tournamentId, 'teams');
        const tournamentTeams = (await getDocs(query(tournamentTeamsCollection))).docs.map((snapshot) => snapshot.data() as TournamentTeam);
        // calculate slots left
        const tournamentSlotsLeft = tournament.teamCapacity - tournamentTeams.filter((team) => team.status === TournamentTeamStatus.confirmed).length;
        const tournamentMinTeamSize = tournament.teamSize;
        const tournamentMaxTeamSize = tournament.maxTeamSize;

        let teamPromise;
        let tournamentTeamPromise;
        if (userTeam.players.length < tournamentMinTeamSize) {
          toast.error(`You do not currently have enough players in your team to participate. (Min: ${tournamentMinTeamSize} players)`);
          return;
        }
        if (userTeam.players.length > tournamentMaxTeamSize) {
          toast.error(`You currently have too many players in your team to participate. (Max: ${tournamentMaxTeamSize} players)`);
          return;
        }
        if (tournamentSlotsLeft > 0) {
          const teamRef = doc(firestore, 'teams', userTeam.id!).withConverter(teamConverter);
          const team = (await getDoc(teamRef)).data();
          if (team) {
            const participatingPlayerData = team.playerData.filter((player) => participatingPlayers.includes(player.id));
            const tournamentTeamRef = doc(firestore, 'tournaments', tournamentId, 'teams', userTeam.id!);
            tournamentTeamPromise = updateDoc(tournamentTeamRef, {
              poiPreferences: team.gamePreferences.apex.poiPreferences,
              participatingPlayers: participatingPlayers,
              participatingPlayerData: participatingPlayerData,
              status: TournamentTeamStatus.confirmed
            })
            teamPromise = updateDoc(teamRef, {
              tournamentsInPlay: arrayUnion(tournamentId)
            })
          }
        } else {
          toast.error('Sorry, the tournament is now at capacity');
          teamPromise = Promise.resolve();
          tournamentTeamPromise = Promise.resolve();
        }

        const notificationRef = doc(firestore, 'notifications', notification.id);
        const notificationPromise = updateDoc(notificationRef, {
          dismissed: true,
        });

        const combinedPromise = Promise.all([teamPromise, tournamentTeamPromise, notificationPromise]);
        if (tournamentSlotsLeft > 0) {
          toast.promise(combinedPromise, {
            pending: 'Confirming tournament entry',
            success: 'Tournament entry confirmed',
            error: 'Error confirming tournament entry',
          })
        }
        await combinedPromise;
      } catch (err) {
        toast.error('Error confirming tournament entry');
        console.error(err);
      }
    }
  }

  const declineTournamentPresence = async (notification: Notification) => {
    if (userTeam && notification) {
      const body = notification.body as {tournamentId: string};
      try {
        const tournamentId = body.tournamentId;
        const tournamentTeamRef = doc(firestore, 'tournaments', tournamentId, 'teams', userTeam.id!);
        const tournamentTeamPromise = updateDoc(tournamentTeamRef, {
          status: TournamentTeamStatus.declined,
        })

        const notificationRef = doc(firestore, 'notifications', notification.id);
        const notificationPromise = updateDoc(notificationRef, {
          dismissed: true,
        });

        const teamRef = doc(firestore, 'teams', userTeam.id!);
        const teamPromise = updateDoc(teamRef, {
          activeTournaments: arrayRemove(tournamentId)
        })

        const combinedPromise = Promise.all([tournamentTeamPromise, notificationPromise, teamPromise]);
        toast.promise(combinedPromise, {
          pending: 'Declining tournament entry',
          success: 'Tournament entry declined',
          error: 'Error declining tournament entry',
        })
      } catch (err) {
        toast.error('Error declining tournament entry');
        console.error(err)
      }
    }
  }

  const acceptTeamInvite = async (teamId: string, inviteIndex: number) => {
    if (userObj) {
      const acceptInvite = async () => {
        const userRef = doc(firestore, 'users', userObj.uid);

        const teamRef = doc(firestore, 'teams', teamId).withConverter(teamConverter);
        const team = (await getDoc(teamRef)).data();

        const teamRequests = userObj.teamRequests.filter((request) => request.teamId !== teamId);

        let userPromise: Promise<void> = Promise.resolve();
        let teamPromise: Promise<void> = Promise.resolve();

        if (team !== undefined && !team.dissolved) {
          if (!team.players.includes(userObj.uid)) team.players.push(userObj.uid);

          if (team.pendingPlayers.includes(userObj.uid)) team.pendingPlayers.splice(team.pendingPlayers.indexOf(userObj.uid), 1);

          const updatedTeamData = {
            players: team.players,
            pendingPlayers: team.pendingPlayers,
          }
          const updatedUserData = {
            team: teamId,
            teamRequests: teamRequests
          }

          userPromise = updateDoc(userRef, updatedUserData);
          teamPromise = updateDoc(teamRef, updatedTeamData);
        } else {
          const updatedUserData = {
            teamRequests: teamRequests
          }

          userPromise = updateDoc(userRef, updatedUserData);
          toast.error('team was not found / has since been dissolved');
        }

        await Promise.all([
          userPromise,
          teamPromise
        ])
      }

      if (userObj.team) {
        toast.error('Please leave your team before attempting to join a new one');
        return;
      }
      try {
        const acceptPromise = acceptInvite();
        toast.promise(acceptPromise, {
          pending: 'Accepting team invite',
          success: 'Team invite accepted',
          error: 'Error accepting team invite',
        });

        await acceptPromise;

        const localTeamInvites = [...teamInvites];
        localTeamInvites.splice(inviteIndex, 1);
        setTeamInvites(localTeamInvites);
        navigate(`/team/${teamId}`);
      } catch (err) {
        console.error(err);
      }
    }
  }

  const declineTeamInvite = (teamId: string, inviteIndex: number) => {
    if (userObj) {
      const declineInvite = async () => {
        const userRef = doc(firestore, 'users', userObj.uid);

        const teamRef = doc(firestore, 'teams', teamId).withConverter(teamConverter);
        const team = (await getDoc(teamRef)).data();

        const teamRequests = userObj.teamRequests.filter((request) => request.teamId !== teamId);

        let teamPromise: Promise<void> = Promise.resolve();

        if (team !== undefined && !team.dissolved) {
          if (team.pendingPlayers.includes(userObj.uid)) team.pendingPlayers.splice(team.pendingPlayers.indexOf(userObj.uid), 1);

          const updatedTeamData = {
            pendingPlayers: team.pendingPlayers,
          }

          teamPromise = updateDoc(teamRef, updatedTeamData);
        } else {
          console.error('team was not found / has since been dissolved');
        }
        const updatedUserData = {
          teamRequests: teamRequests
        }
        const userPromise = updateDoc(userRef, updatedUserData);

        await Promise.all([
          userPromise,
          teamPromise
        ])
      }

      const declinePromise = declineInvite();

      toast.promise(declinePromise, {
        pending: 'Declining team invite',
        success: 'Team invite declined',
        error: 'Error declining team invite',
      });
      declinePromise.then(() => {
        // remove invite notification
        const localTeamInvites = [...teamInvites];
        localTeamInvites.splice(inviteIndex, 1);
        setTeamInvites(localTeamInvites);
      }).catch((err) => {
        console.error(err);
      })
    }
  }

  const [prevTeamInviteLength, setPrevTeamInviteLength] = useState<number>(-1);

  useEffect(() => {
    if (userObj && userObj.teamRequests.length !== prevTeamInviteLength) {
      getTeamInvites();
      setPrevTeamInviteLength(userObj.teamRequests.length);
    }
  }, [userObj, getTeamInvites, prevTeamInviteLength]);

  useEffect(() => {
    const notifUnsubscribe = getNotifications();
    const tournamentUnsubscribe = getActiveTournaments();

    return () => [notifUnsubscribe, tournamentUnsubscribe].forEach((unsub) => unsub());
  }, [userObj, getActiveTournaments, getNotifications]);

  useEffect(() => {
    setActiveTournamentsCount(activeTournaments.length);
    setNotificationCount(teamInvites.length + notifications.length);
  }, [teamInvites, activeTournaments, notifications]);

  useEffect(() => {
    const joinedSortedNotifications = [...globalTimeoutNotifications, ...globalNoTimeoutNotifications, ...timeoutNotifications, ...noTimeoutNotifications]
    .sort((a, b) => b.timeReceived.getTime() - a.timeReceived.getTime());

    joinedSortedNotifications.push(...[...brandedNoTimeoutNotifications, ...brandedTimeoutNotifications].filter((notification) => !dismissedBrandedNotifs.includes(notification.id)));

    setNotifications(joinedSortedNotifications);
  }, [dismissedBrandedNotifs,
      timeoutNotifications,
      noTimeoutNotifications,
      globalTimeoutNotifications,
      globalNoTimeoutNotifications,
      brandedTimeoutNotifications,
      brandedNoTimeoutNotifications]);

  const contextValue = {
    teamInvites: teamInvites,
    acceptTeamInvite: acceptTeamInvite,
    declineTeamInvite: declineTeamInvite,
    confirmTournamentPresence: confirmTournamentPresence,
    declineTournamentPresence: declineTournamentPresence,
    notifications: notifications,
    dismissNotification: dismissNotification,
    notificationsCount: notificationCount,
    activeTournaments: activeTournaments,
    activeTournamentsCount: activeTournamentsCount,
    currentTime: currentTime,
  }

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider;
