// ty[es]
import { TournamentTrophy } from '@src/firestore/tournamentTrophies';
// utils
import { formatDate } from '@utils/Date';
// static content
import grayCupBackground from "@assets/images/png/my-profile/dark_gray_cup.png";
// icons
import { ClockIcon } from '@icons/ProfileIcons';
import { TrophyBronzeImage, TrophyGoldImage, TrophySilverImage,} from "@icons/TournamentIcons";

const getCupIconForTrophy = (finalPosition: number) => {
  switch (finalPosition) {
    case 1:
      return <TrophyGoldImage className='max-w-[70px] h-auto aspect-square object-contain'/>
    case 2:
      return <TrophySilverImage className='max-w-[70px] h-auto aspect-square object-contain'/>
    case 3:
      return <TrophyBronzeImage className='max-w-[70px] h-auto aspect-square object-contain'/>
  }
}

interface ITrophyCard {
  trophy: TournamentTrophy
  fullWidth?: boolean,
}

const TrophyCard: React.FC<ITrophyCard> = ({trophy, fullWidth}) => {

  return (
    <div className={`bg-lightBlack p-4 rounded-xl relative overflow-hidden ${fullWidth ? 'w-full' : 'w-[90%]'} md:w-full flex-shrink-0`}>
      <img
        className="w-[7.188rem] h-[6.75rem] absolute -end-8 sm:end-0 -bottom-3 z-0 opacity-30"
        src={grayCupBackground}
        alt="image"
      />
      <div className="relative flex gap-3 items-center z-[1]">
        {getCupIconForTrophy(trophy.position)}
        <div>
          <p className="absolute top-1 right-1
                        font-compact font-normal text-steelGray text-sm flex gap-2">
            <span><ClockIcon/></span>
            {formatDate(trophy.dateRecieved)}
          </p>
          <p className="font-wide font-bold text-white !leading-4 uppercase mt-2 mb-1 pt-1">
            {trophy.tournamentName}
          </p>
          <p className="text-steelGray font-compact font-medium">
            {trophy.gameName}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TrophyCard;
