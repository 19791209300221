import { useEffect, useState } from 'react';
// context
import { useTournamentContext } from '../../TournamentProvider';
// types
import { TournamentStatus, tournamentStatusToString } from '../../../../firestore/tournaments';
// icons
import { FaClock } from 'react-icons/fa6';

interface ICheckListCountdown {
  status: TournamentStatus,
}

const CheckListCountdown: React.FC<ICheckListCountdown> = ({status}) => {
  const { tournament } = useTournamentContext();
  const [countdownToNextStatus, setCountdownToNextStatus] = useState<[timeUntilNextStatus: number, currentStatusDuration: number]>([0,0]);

  useEffect(() => {
    const clock = setInterval(() => {
      getCountdownToNextStatus(new Date().getTime());
    }, 1000);

    return () => clearInterval(clock);
  }, [])

  const getCountdownToNextStatus = (currentTime: number) => {
    if (tournament) {
      const time = currentTime;
      const tournamentPlayTime = tournament.statusDates.ongoing.getTime();
      let timeUntilNextStatus: number = 0;
      let currentStatusDuration: number = 0;
      const nextStatus = status + 1;
      switch (nextStatus) {
        case TournamentStatus.confirmation:
        timeUntilNextStatus = (tournamentPlayTime - 7_200_000) - time;
        currentStatusDuration = (tournamentPlayTime - 7_200_000) - tournament.createdAt.getTime();
          break;
        case TournamentStatus.prep:
        timeUntilNextStatus = tournamentPlayTime - time;
        currentStatusDuration = 5_400_000;
          break;
        case TournamentStatus.ongoing:
        timeUntilNextStatus = tournamentPlayTime - time;
        currentStatusDuration = 1_800_000;
          break;
      }
      setCountdownToNextStatus([timeUntilNextStatus, currentStatusDuration]);
    }
  }

  return (
    <div className="relative flex items-center justify-center px-4 w-full h-[45px] rounded-xl bg-lightGray text-white font-compact uppercase font-semibold overflow-hidden">
      <div className="absolute left-0 top-0 h-full bg-steelGray/50"
           style={{width: `${Math.round((countdownToNextStatus[0] / countdownToNextStatus[1]) * 100)}%`}}></div>
      <div className="w-fit flex items-center gap-x-4 relative z-[2]">
        <p>{tournamentStatusToString(status)}</p>
        <FaClock className="text-xl" />
      </div>
    </div>
  )
}

export default CheckListCountdown;
