import { useState } from 'react';
// context
import PlayersProvider from "./PlayersProvider";
// components
import Hero from "./components/Hero";
import MainList from "./components/MainList";
import FindTeam from '@common/FindTeam';


const Players = () => {
  const [playersLoaded, setPlayersLoaded] = useState<boolean>(false);

  return (
    <div className="mx-auto custom_container">
      <PlayersProvider announcePlayersLoaded={setPlayersLoaded}>
        <Hero/>
        <div className="px-4 sm:px-0">
          <MainList/>
          <div className="px-3 sm:px-5 lg:px-12">
            <FindTeam loaded={playersLoaded}/>
          </div>
        </div>
      </PlayersProvider>
    </div>
  );
};

export default Players;
