import { ReactNode } from "react";

interface IInputWrapper {
  className?: string,
  children: ReactNode;
}


export const InputWrapper: React.FC<IInputWrapper>= ({ className, children }) => {
  return (
    <>
      <div className={`${className} font-compact text-sm font-normal bg-black outline-none text-white mt-2 w-full h-full rounded-xl`}>
        {children}
      </div>
    </>
  );
};
