// context
import { useTeamInfoContext } from "@components/team/TeamInfoProvider";
// types
import { TeamEditModalStep } from "@components/team/types";
// icons
import { FaHandshakeAngle } from "react-icons/fa6";

const Sponsors = () => {
  const { setCurrentEditModalStep } = useTeamInfoContext();

  return (
    <div className="mt-3">
      <button
      type="button"
      onClick={() => setCurrentEditModalStep(TeamEditModalStep.sponsors)}
      className="w-fit py-2 bg-lightGray duration-200 font-compact pb-[0.688rem] pt-[0.813rem] uppercase font-medium px-6 rounded-xl flex gap-2 items-center !leading-4">
        <span>Manage Sponsors</span>
        <FaHandshakeAngle className="text-green text-xl" />
      </button>
    </div>
  );
};

export default Sponsors;
