import { createContext, useContext, useState, useEffect, ReactNode} from 'react';
// types
import { PlayersFilterOption, PlayersRegionOption, PlayersSortingOption } from '@components/players/types';
import { TeamsFilterOption, TeamsRegionOption, TeamsSortingOption } from '@components/teams/types';
import { TournamentsRegionOption, TournamentsSortingOption } from '@components/tournaments/TournamentsProvider';

interface VersusWebappStorage {
  tournamentsRegionOption?: TournamentsRegionOption,
  tournamentsSortingOption?: TournamentsSortingOption,
  teamsSortingOption?: TeamsSortingOption,
  teamsFilterOptions?: TeamsFilterOption[],
  teamsRegionOption?: TeamsRegionOption,
  playersSortingOption?: PlayersSortingOption,
  playersFilterOptions?: PlayersFilterOption[],
  playersRegionOption?: PlayersRegionOption
}

interface IStorageContext {
  storage: VersusWebappStorage,
  updateStorage: (newStorage: VersusWebappStorage) => void,
  storageLoaded: boolean,
}

const defaultStorageContext: IStorageContext = {
  storage: {},
  updateStorage: (newStorage: VersusWebappStorage) => newStorage,
  storageLoaded: false
}

const StorageContext = createContext<IStorageContext>(defaultStorageContext);

export const useStorageContext = (): IStorageContext => {
  return useContext(StorageContext);
}

interface IStorageProvider {
  children: ReactNode
}

const StorageProvider: React.FC<IStorageProvider> = ({children}) => {
  const storageKey = 'versus-webapp-storage';
  const [storage, setStorage] = useState<VersusWebappStorage>({});
  const [storageLoaded, setStorageLoaded] = useState<boolean>(false);

  const updateStorage = (newStorage: VersusWebappStorage) => {
    const updatedStorage = { ...storage, ...newStorage };
    localStorage.setItem(storageKey, JSON.stringify(updatedStorage));
    setStorage(updatedStorage);
  }

  const readStorage = (): VersusWebappStorage => {
    const storedData = localStorage.getItem(storageKey);

    return storedData ? JSON.parse(storedData) : {};
  }

  useEffect(() => {
    const storageValue = readStorage();
    setStorage(storageValue);
    setStorageLoaded(true);
  }, [])

  const contextValue = {
    storage,
    updateStorage,
    storageLoaded
  }
  return (
    <StorageContext.Provider value={contextValue}>
      {children}
    </StorageContext.Provider>
  )
}

export default StorageProvider;
