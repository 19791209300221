import ContactForm from "./ContactForm";
import Hero from "./Hero";

const ContactMain = () => {
  return (
    <div className="md:-mt-8">
      <Hero />
      <ContactForm context={'contact'} />
    </div>
  );
};

export default ContactMain;
