// icons
import { FaCheck } from "react-icons/fa"

interface ICheckBox {
  selected: boolean,
  setSelected: (selected: boolean) => void,
  className?: string,
  interactive?: boolean,
  label?: string,
  asDiv?: boolean,
  small?: boolean,
}

const CheckBox: React.FC<ICheckBox> = ({selected, setSelected, className, label, interactive, asDiv, small}) => {
  return asDiv ? (
    <div aria-label={label ? label : `CheckBox (${selected ? 'Selected' : 'Not Selected'})`}
            onClick={() => setSelected(!selected)}
            className={`${className} flex items-center justify-center ${small ? 'w-[15px] rounded' : ' w-[20px] rounded-lg'} h-auto aspect-square border border-steelGray text-black
                    ${selected ? 'bg-green' : ''}
                    ${interactive === false ? 'pointer-events-none' : ''}`}>
      {selected ? (
        <FaCheck className="text-xs"/>
      ) : ''}
    </div>
  ) : (
    <button type="button"
            aria-label={label ? label : `CheckBox (${selected ? 'Selected' : 'Not Selected'})`}
            onClick={() => setSelected(!selected)}
            className={`${className} flex items-center justify-center ${small ? 'w-[15px] rounded' : ' w-[20px] rounded-lg'} h-auto aspect-square border border-steelGray text-black
                    ${selected ? 'bg-green' : ''}
                    ${interactive === false ? 'pointer-events-none' : ''}`}>
      {selected ? (
        <FaCheck className="text-xs"/>
      ) : ''}
    </button>
  )
}

export default CheckBox;
