import { useEffect, useState } from "react";
// utils
import { registerInterest } from "../../../utils/NewsEmail";
// packages
import { toast } from "react-toastify";
// assets
import comingSoonImage from "../../../assets/images/webp/coming-soon/coming_soon_image.webp";
import comingSoonSmImage from "../../../assets/images/webp/coming-soon/coming_soon_sm_image.webp";
// icons
import { ImSpinner8 } from "react-icons/im";
import { BlackArrow, InputCross } from "../icons/Common";

interface IComingSoon {
  title: string,
  className?: string,
}

const ComingSoon: React.FC<IComingSoon> = ({ title, className }) => {
  const [email, setEmail] = useState("");
  const [activeField, setActiveField] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('emailInput') && !target.closest('emailInput')) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', checkForOutsideInputClick);

    return () => document.removeEventListener('click', checkForOutsideInputClick);
  }, [])

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const submitPromise = registerInterest(email, [title.toLowerCase()]);
      toast.promise(submitPromise, {
        pending: 'Registering interest',
        success: 'You will recieve an email when we release this feature!',
        error: 'Error registering interest'
      })
      await submitPromise;
      setEmail('');
      setSubmitting(false);
    } catch (err) {
      toast.error("Error registering interest");
    }
  }

  return (
    <>
      <div className={`${className ? className : 'min-h-screen'} flex items-center justify-center overflow-x-hidden`}>
        <div className="px-4 sm:px-8 lg:px-12 w-full">
          <div className="relative">
            <img
              className="max-w-[61.875rem] w-full absolute top-1/2 -translate-y-1/2 start-1/2 -translate-x-1/2 hidden md:block"
              src={comingSoonImage}
              alt="comingSoonImage"
            />
            <img
              className="max-w-[56.25rem] h-[43.75rem] absolute top-1/2 -translate-y-1/2 start-1/2 -translate-x-1/2 block md:hidden"
              src={comingSoonSmImage}
              alt="comingSoonSmImage"
            />
            <div className="relative z-10 pt-8 sm:pt-0">
              <p className="font-compact font-normal text-base !leading-4 !tracking-[0.2rem] uppercase text-green my-4 text-center">
                {title}
              </p>
              <h2 className="text-white font-wide font-bold !leading-[2.688rem] uppercase !text-5xl text-center">
                coming soon!
              </h2>
              <form action="">
                <div className="mt-8 sm:mt-10 flex flex-col md:flex-row items-center gap-3 justify-center">
                  <div
                    id="stayUpdateInput"
                    className="relative lg:max-w-[30.188rem] sm:max-w-[25rem] w-full"
                  >
                    <input
                      className={`px-4 emailInput pt-[0.875rem] pb-[0.625rem] text-sm placeholder:text-steelGray outline-none text-white font-compact rounded-xl bg-lightBlack w-full sm:w-[25rem] md:w-full
                  ${activeField === 'email'
                          ? "border-green border-2 pr-10 placeholder:text-white"
                          : "border-transparent border-2"
                        }`}
                      onClick={() => setActiveField('email')}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      placeholder="Your e-mail"
                      required
                      autoComplete="off"
                    />
                    {activeField === 'email' && (
                      <span
                        onClick={() => {
                          setActiveField('');
                          setEmail(""); // Clear the input value
                        }}
                        className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer"
                      >
                        <InputCross />
                      </span>
                    )}
                  </div>
                  <button type="button"
                          disabled={submitting}
                          onClick={handleSubmit}
                          className={`font-compact font-medium text-base !leading-4 py-4 px-6 common_green_btn uppercase duration-300 ease-out transition-all flex items-center gap-2 w-full sm:w-[25rem] md:w-auto justify-center
                                      ${submitting ? 'opacity-50' : ''}`}>
                    <span className="-mb-[0.188rem] text">
                      Notify Me
                    </span>
                    <span>
                      {submitting ? (
                        <ImSpinner8 className="animate-spin"/>
                      ) : (
                        <BlackArrow />
                      )}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
