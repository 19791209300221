import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export interface ApexPointSystem {
  id: string,
  game: string,
  name: string,
  perKill: number;
  perDamage: number;
  perAssist: number;
  placements: number[],
  tournamentsUsing: string[],
}

export const positionNumberToString = (position: number): string => {
  switch (position) {
    case 1:
      return '1st';
    case 2:
      return '2nd';
    case 3:
      return '3rd';
    case 4:
      return '4th';
    case 5:
      return '5th';
    case 6:
      return '6th';
    case 7:
      return '7th';
    case 8:
      return '8th';
    case 9:
      return '9th';
    case 10:
      return '10th';
    case 11:
      return '11th';
    case 12:
      return '12th';
    case 13:
      return '13th';
    case 14:
      return '14th';
    case 15:
      return '15th';
    case 16:
      return '16th';
    case 17:
      return '17th';
    case 18:
      return '18th';
    case 19:
      return '19th';
    case 20:
      return '20th';
    default:
      return 'N/A'
  }
}

export const apexPointSystemConverter = {
  toFirestore: (pointSystem: ApexPointSystem) => {
    return {
      id: pointSystem.id ?? '',
      game: pointSystem.game,
      name: pointSystem.name,
      perKill: pointSystem.perKill,
      perDamage: pointSystem.perDamage,
      perAssist: pointSystem.perAssist,
      placements: pointSystem.placements,
      tournamentsUsing: pointSystem.tournamentsUsing ?? [],
    };
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)!;
    const pointSystem: ApexPointSystem = {
      id: snapshot.id,
      game: data.game,
      name: data.name,
      perKill: data.perKill,
      perDamage: data.perDamage,
      perAssist: data.perAssist,
      placements: data.placements,
      tournamentsUsing: data.tournamentsUsing ?? [],
    };
    return pointSystem;
  },
};
