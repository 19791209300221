import { Link } from 'react-router-dom';
// context
import { useNotificationContext } from '@provider/NotificationProvider';
// types
import { Notification } from '@src/firestore/notifications';
// utils
import { logEventToAnalytics } from '@utils/Analytics/Analytics';
// icons
import SurfsharkIcon from '@components/brands/surfshark/SurfsharkIcon';
import { FaXmark } from 'react-icons/fa6';

interface ISurfsharkDiscountNotif {
  notification: Notification
}

const SurfsharkDiscountNotif: React.FC<ISurfsharkDiscountNotif> = ({notification}) => {
  const { dismissNotification } = useNotificationContext();

  const handleDismissNotification = async () => {
    logEventToAnalytics('surfshark_discount_dismiss', { source: 'notification'});
    dismissNotification(notification);
  }

  const handleClickThrough = () => {
    logEventToAnalytics('surfshark_discount_click', { source: 'notification'});
  }

  return (
    <div className={`relative bg-lightGray rounded-xl p-4`}>
      <button type="button"
              aria-label='dismiss notification'
              onClick={() => handleDismissNotification()}
              className="absolute top-1 right-1 p-1 fill-ebonyClay hover:opacity-75 transition-opacity">
        <FaXmark className='w-[20px] h-auto aspect-square fill-inherit'/>
      </button>
      <div className="flex items-start gap-x-3 pr-10">
        <div className='p-[0.55rem] rounded-full bg-steelGray/30'>
          <SurfsharkIcon className='w-[18px] h-auto aspect-square fill-white'/>
        </div>
        <div>
          <p className="text-white font-compact whitespace-nowrap">
            Claim your Surfshark Discount
          </p>
          <p className='text-sm font-compact text-steelGray'>
            Sponsored
          </p>
        </div>
      </div>
      <Link to="https://surfshark.com/vs" target="_blank"
            onClick={handleClickThrough}
            className="w-full mt-4 bg-surfsharkTeal rounded-xl py-2 px-[50px] flex items-center justify-center gap-x-2 hover:opacity-75 transition-opacity">
        <span className="font-compact text-white font-medium uppercase translate-y-[1px] whitespace-nowrap">Surfshark Discount</span>
        <SurfsharkIcon className="w-[16px] h-auto aspect-square fill-white"/>
      </Link>
    </div>
  );
};

export default SurfsharkDiscountNotif;
