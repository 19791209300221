// components
import GeneralStep from './modals/GeneralStep';
import RegionGameStep from './modals/RegionGameStep';
import PaymentStep from './modals/PaymentStep';
import SponsorsStep from './modals/SponsorsStep';
import InviteStep from './modals/InviteStep';

const EditModals = () => {
  return (
    <>
      <GeneralStep/>
      <RegionGameStep/>
      <PaymentStep/>

      <SponsorsStep/>
      <InviteStep/>
    </>
  );
};

export default EditModals;
