import { useState } from 'react'
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
// components
import EventDetails from './SubViews/EventDetails';
import Socials from './SubViews/Socials';

enum GeneralViews {
  eventDetails,
  socials
}

const General = () => {
  const { tournament } = useTournamentContext();

  const [currentView, setCurrentView] = useState<GeneralViews>(GeneralViews.eventDetails);

  const checkInStarted = tournament !== null && tournament.status > 0;

  return (
    <div className='w-full'>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase whitespace-nowrap">
          General
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            <button type='button'
              onClick={() => setCurrentView(GeneralViews.eventDetails)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === GeneralViews.eventDetails ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Event Details</span>
            </button>

            <button type='button'
              onClick={() => setCurrentView(GeneralViews.socials)}
              className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 font-medium rounded-full transition-colors whitespace-nowrap
                          ${currentView === GeneralViews.socials ? 'text-white/85 bg-lightGray' : 'text-steelGray border-steelGray/50 hover:border-steelGray'}`}>
              <span>Socials</span>
            </button>
          </div>
        </div>
      </div>
      <div className='flex mt-6'>
        <div className='w-full lg:w-3/5'>
          {currentView === GeneralViews.eventDetails ? (
            <EventDetails checkInStarted={checkInStarted}/>
          ) : ''}
          {currentView === GeneralViews.socials ? (
            <Socials checkInStarted={checkInStarted}/>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default General;
