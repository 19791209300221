// context
import { regionDropdownOptions, sortingDropdownOptions, useTournamentsContext } from "../TournamentsProvider";
// components
import TournamentCard from "./TournamentCard";
import SkeletonTournamentCard from './SkeletonTournamentCard';
import SearchSortFilterBarTournaments from '@components/global/SearchSortFilterBarTournaments';
// icons
import { VscDebugDisconnect } from 'react-icons/vsc';
import { ImSpinner8 } from 'react-icons/im';

const MainList = () => {
  const {
    tournaments,
    tournamentsLoaded,
    initiallyLoaded,
    searchQuery,
    setSearchQuery,
    searchQueued,
    gameOption,
    setGameOption,
    sortingOption,
    setSortingOption,
    regionOption,
    setRegionOption,
    tournamentsCount
  } = useTournamentsContext();

  const gameCheckBoxSubtextValues = {
    'Apex Legends': `${tournamentsCount.apexLegends} tournaments`,
    'Valorant': `${2} tournaments`,
    'Fortnite': `${2} tournaments`,
    'Rocket League': `${2} tournaments`
  }

  return (
    <div className='overflow-hidden px-3 sm:px-5 lg:px-12 custom_container pt-[calc(3rem-4px)]'>
      <SearchSortFilterBarTournaments listTitle='tournaments'
                          searchEnabled={true}
                          searchQuery={searchQuery}
                          setSearchQuery={setSearchQuery}
                          regionDropdownOptions={regionDropdownOptions}
                          selectedRegionOption={regionOption}
                          setSelectedRegionOption={setRegionOption}
                          selectedGameOption={gameOption}
                          setSelectedGameOption={setGameOption}
                          gameCheckBoxSubtextValues={gameCheckBoxSubtextValues}
                          selectedSortingOption={sortingOption}
                          setSelectedSortingOption={setSortingOption}
                          sortingOptions={sortingDropdownOptions}
                          initiallyLoaded={initiallyLoaded}
                          searchBarPlaceholder='Search for a tournament'/>
      {tournamentsLoaded ? (
        <>
          {searchQueued ? (
            <div className="flex flex-col items-center justify-center min-h-[350px]">
              <ImSpinner8 className="w-[60px] h-auto aspect-square text-green animate-spin" />
            </div>
          ) : (
            <>
              {tournaments.length > 0 ? (
                <div className="overflow-auto">
                  <div className="flex justify-start flex-wrap-0 overflow-auto w-full md:overflow-hidden lg:grid lg:grid-cols-3 xl:grid-cols-4 text-white scrollbar_none gap-8 pb-3">
                    {tournaments.map((tournament) => (
                      <TournamentCard
                        key={`main-list-${tournament.id}`}
                        tournament={tournament}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
                // </div>
                <div className="flex flex-col items-center justify-center gap-y-2 min-h-[350px]">
                  <div className="flex items-center justify-center bg-lightGray w-[50px] h-auto aspect-square rounded-full">
                    <VscDebugDisconnect className="text-white text-2xl" />
                  </div>
                  <p className="font-wide text-white text-2xl font-semibold uppercase">
                    No tournaments found
                  </p>
                  <p className="text-steelGray font-compact">
                    Try adjusting your search parameters
                  </p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="overflow-auto">
          <div className="flex overflow-auto w-full md:overflow-hidden md:flex-wrap text-white scrollbar_none pb-3">
            {Array.from({ length: 6 }).map((_i, index) => (
              <SkeletonTournamentCard key={`main-list-${index}`} />
            ))}
          </div>
        </div>
        // <div className="w-full flex flex-wrap-0 overflow-x-scroll lg:grid lg:grid-cols-2 gap-4 my-8 lg:mb-[150px]">
        // </div>
      )}
    </div>
  );
};

export default MainList;
