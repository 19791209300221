import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// context
import { useAuthContext } from "../../provider/AuthContextProvider"
// libraries
import { toast } from 'react-toastify';
// components
import SubViewBar from './SubViewBar';
import Skeleton from "../common/skeleton-loader/Skeleton";
import Tournaments from "./tournaments/Tournaments";
import UploadApexCodes from './codes/UploadApexCodes';
import Announcements from "./announcements/Announcements";
import NoticeText from '../ui/NoticeText';

export enum AdminSubViews {
  tournaments,
  codes
}

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { userObj, userObjLoaded } = useAuthContext();

  const [currentSubView, setCurrentSubView] = useState<AdminSubViews>(AdminSubViews.tournaments);

  useEffect(() => {
    if (userObjLoaded && (!userObj || !userObj.admin)) {
      toast.error('No permissions')
      navigate('/');
    }
  }, [navigate, userObjLoaded, userObj]);

  return userObjLoaded ?
  userObj && userObj.admin ? (
    <div className="custom_container pb-10 md:pb-14 pt-4 md:pt-0 md:px-4 xl:px-0">
      <div className='flex flex-col md:flex-row gap-4 md:items-center w-full h-fit md:h-[300px]'>
        <div className='relative bg-lightBlack border border-lightGray rounded-lg w-full h-full flex-grow-[2] overflow-hidden
                        flex items-center justify-center'>

          <div className='hidden md:flex w-[300px] h-[25px] bg-green text-black text-base font-compact font-semibold uppercase
                          absolute top-[15%] left-[75px] -translate-y-1/2 -translate-x-1/2 items-center justify-center z-[3]
                          rotate-[-30deg]'>
              <span className='mt-[3px] mr-2'>Coming Soon</span>
          </div>

          <NoticeText className='!w-fit'>
            Site Analytics
          </NoticeText>
        </div>
        <div className='md:flex-grow-[1] min-w-[300px] h-full'>
          <Announcements/>
        </div>
      </div>

      <SubViewBar currentSubView={currentSubView} setCurrentSubView={setCurrentSubView}/>

      <div className='w-full mt-8 px-4 md:px-0'>
        {currentSubView === AdminSubViews.tournaments ? (
          <Tournaments/>
        ) : ''}

        {currentSubView === AdminSubViews.codes ? (
          <div className='flex flex-col md:flex-row gap-4 md:items-center'>
            <UploadApexCodes highPerformance={false}/>
            <UploadApexCodes highPerformance={true}/>
            {/* <FaShield className="text-lightGray absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[300px] z-[0]"/> */}
          </div>
        ) : ''}
      </div>

    </div>
  ) : ''
  : (
    <div className="relative custom_container pb-10 md:pb-14 mt-[80px] md:mt-[150px]">
      <Skeleton className="w-[300px] h-[400px] mx-auto rounded-xl"/>
    </div>
  );
}

export default AdminDashboard;
