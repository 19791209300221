import { RefObject, useEffect, useRef, useState, useCallback } from "react";
import '../../css/datePicker.css';
// context
import { useProfileInfoContext } from "./ProfileInfoProvider";
// packages
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { overwrite, getCodeList, getName } from 'country-list';
// utils
import { isDisplayNameValid } from '@utils/validations/users';
import { getAgeFromDateOfBirth } from '@utils/Date';
// components
import { InputWrapper } from "@components/common/inputfield-wrapper/InputWrapper";
// assets
import step1PositionImg from "@assets/images/png/team/step1PositionImg.png";
// icons
import {  GrayCrossIcon } from "@icons/Header";
import { InputCross, BlackArrow, DownSelectArrow } from "@icons/Common";
import { SelectImageIcon, UploadArrowIcon, UploadLogoIcon} from "@icons/ProfileIcons";
import { IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";

overwrite([
  {
    code: 'GB',
    name: 'United Kingdom'
  },
  {
    code: 'RU',
    name: 'Russia'
  }
]);


interface IProfileStep {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

interface IAgeDisplayOptIn {
  optedIn: boolean,
  handleOptInOut: (optIn: boolean) => void,
}

const AgeDisplayOptIn: React.FC<IAgeDisplayOptIn> = ({optedIn, handleOptInOut}) => {
  return (
    <div className="flex items-center gap-x-2 py-2 cursor-pointer" onClick={() => handleOptInOut(!optedIn)}>
      <p className="text-steelGray font-compact text-sm">Display age on profile</p>
      <button type="button"
              aria-label="Opt in to age display"
              className={`flex items-center justify-center w-[20px] h-auto rounded-lg aspect-square border-2 border-lightGray text-black
                       bg-transparent ${optedIn ? '!bg-green' : ''} transition-colors`}>
        <FaCheck className={`text-sm ${optedIn ? '' : 'opacity-0'} transition-opacity`}/>
      </button>
    </div>
  );
}

const Step1: React.FC<IProfileStep> = ({ changeModalStep, closeModal }) => {
  const scrollContainerRef = useRef(null);

  const {profileInfo, setProfileInfo, updateMode, reloadInfo} = useProfileInfoContext();
  const [formValid, setFormValid] = useState(false);

  const [countriesList, setCountriesList] = useState<[string, string][]>(Object.entries(getCodeList()).sort((a,b) => a[1][0].localeCompare(b[1][0])));
  const [countriesFilter, setCountriesFilter] = useState<string>('');
  const [countriesInput, setCountriesInput] = useState<string>('');

  useEffect(() => {
    if (profileInfo.nationality) {
      setCountriesInput(getName(profileInfo.nationality) ?? '');
    }
  }, [profileInfo])

  useEffect(() => {
    const localCountriesList = Object.entries(getCodeList()).sort((a,b) => a[1][0].localeCompare(b[1][0]));
    if (countriesFilter) {
      const filteredCountriesList = localCountriesList.filter((country) => country[1].toLowerCase().includes(countriesFilter.toLowerCase()));
      setCountriesList(filteredCountriesList);
    } else {
      setCountriesList(localCountriesList);
    }
  }, [countriesFilter])


  const [displayNameValid, setDisplayNameValid] = useState<boolean>(false);
  const [dateOfBirthValid, setDateOfBirthValid] = useState<boolean>(false);
  const [nationalityValid, setNationalityValid] = useState<boolean>(false);
  const [displayImageValid, setDisplayImageValid] = useState<boolean>(false);

  const validateFields = useCallback(() => {
    setDisplayImageValid(profileInfo.displayImageUrl !== '' || profileInfo.originalDisplayImageUrl !== '');

    setDisplayNameValid(isDisplayNameValid(profileInfo.displayName));

    setDateOfBirthValid(profileInfo.dateOfBirth === null || (getAgeFromDateOfBirth(profileInfo.dateOfBirth ?? new Date()) >= 13));

    const nationalityRegex = /\w{1,3}/;
    setNationalityValid(countriesInput === '' || nationalityRegex.test(profileInfo.nationality));

  }, [profileInfo, countriesInput])

  useEffect(() => {
    validateFields();
  }, [profileInfo, countriesInput, validateFields]);


  const [activeField, setActiveField] = useState<string | null>(null);

  // emulated clicks so file upload inputs can be opened from external buttons
  const bannerImageRef: RefObject<HTMLInputElement> = useRef(null);
  const logoImageRef: RefObject<HTMLInputElement> = useRef(null);

  const clickBannerUpload = () => {
    bannerImageRef.current!.click();
  };

  const clickLogoUpload = () => {
    logoImageRef.current!.click();
  };

  // Create URL reference to image on input change (user attached file)
  const handleBannerImageAttach = (e: { target: HTMLInputElement  }) => {
    const bannerLocal = e.target.files![0];
    if (!["image/jpeg", "image/png", "image/webp"].includes(bannerLocal.type)) {
      toast.error(`You can only upload jpeg, png or webp image formats`)
    } else {
      const bannerUrl = URL.createObjectURL(bannerLocal);
      setProfileInfo({
        ...profileInfo,
        bannerImage: bannerLocal,
        bannerImageUrl: bannerUrl
      });
    }
  };

  const removeBannerImageAttachement = () => {
    setProfileInfo({
      ...profileInfo,
      bannerImage: null,
      bannerImageUrl: ''
    });
  }

  // Create URL reference to image on input change (user attached file)
  const handleLogoImageAttach = (e: { target: HTMLInputElement }) => {
    const logoLocal = e.target.files![0];
    if (!["image/jpeg", "image/png", "image/webp"].includes(logoLocal.type)) {
      toast.error(`You can only upload jpeg, png or webp image formats`)
    } else {
      const logoUrl = URL.createObjectURL(logoLocal);
      setProfileInfo({
        ...profileInfo,
        displayImage: logoLocal,
        displayImageUrl: logoUrl
      });
    }
  };

  const removeLogoImageAttachement = () => {
    setProfileInfo({
      ...profileInfo,
      displayImage: null,
      displayImageUrl: '',
      originalDisplayImageUrl: ''
    });
  }


  const validateForm = async () => {
    const fieldsValid = (profileInfo.displayName !== '' && displayNameValid)
    && (profileInfo.dateOfBirth !== null && dateOfBirthValid)
    && (profileInfo.nationality !== '' && nationalityValid)
    && displayImageValid;

    const localFormValid = fieldsValid;
    setFormValid(localFormValid);
  }

  useEffect(() => {
    validateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayImageValid, displayNameValid, dateOfBirthValid, nationalityValid]);


  const handleStepSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    changeModalStep(1);
  };


  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('completeInput') && !target.closest('completeInput')) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
  }, [])


  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="flex justify-between items-center border-b-[0.063rem] border-lightGray py-3 px-4 bg-lightBlack">
        <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
          {updateMode ? 'Edit' : 'Complete'} Profile
        </h5>
        <span
          className="cursor-pointer"
          onClick={() => {
            reloadInfo();
            closeModal();
          }}
        >
          <GrayCrossIcon className="group" />
        </span>
      </div>
      <div ref={scrollContainerRef} className={`h-1/2 flex-grow overflow-scroll maskedListVert py-[20px] ${activeField === 'nationality' ? 'pb-[125px]' : 'pb-[25px]'}`}>

        <div className="max-[26.25rem]:p-3 p-6 pb-0 ">
          <div className="flex gap-[1.375rem] items-center justify-center w-full">
            <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full bg-lightBlack"></div>
            <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
            {updateMode ? (
              <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
            ) : ''}
          </div>
          {/* Team image upload section */}

          <div className="rounded-2xl bg-lightGray h-[11.063rem] mt-6 relative overflow-hidden">
            {profileInfo.bannerImageUrl ? (
              <>
                <img
                  className="w-full h-[11.063rem] object-cover"
                  src={profileInfo.bannerImageUrl}
                  alt="banner-image"
                />
                <button
                  type="button"
                  aria-label="remove banner attachment"
                  onClick={() => removeBannerImageAttachement()}
                  className="absolute z-50 top-1 right-1 p-1 bg-black/40 backdrop-blur rounded-full hover:opacity-75"
                >
                  <p className="text-red text-lg">
                    <IoClose />
                  </p>
                </button>
              </>
            ) : (
              <div className="p-4">
                <img
                  className="absolute top-0 start-0 max-w-[19.188rem] h-full"
                  src={step1PositionImg}
                  alt=""
                />
                <div className="flex justify-end">
                  <span className="cursor-pointer" onClick={clickBannerUpload}>
                    <span className="rounded-full bg-ebonyClay p-3 w-[2.5rem] h-[2.5rem] cursor-pointer flex items-center justify-center">
                      <SelectImageIcon />
                    </span>
                  </span>
                </div>
                <input
                  hidden
                  id="upload-photo"
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  ref={bannerImageRef}
                  onChange={(e) => handleBannerImageAttach(e)}
                />
              </div>
            )}
          </div>
          {/* Team logo upload section */}
          <div className="rounded-2xl border-4 border-solid border-lightBlack bg-lightGray w-[7.5rem] h-[7.5rem] mx-auto -mt-[4.375rem] z-50 relative overflow-hidden">
            {profileInfo.displayImageUrl || profileInfo.originalDisplayImageUrl ? (
              <>
                <img
                  className="w-full h-full object-cover"
                  src={profileInfo.displayImageUrl ? profileInfo.displayImageUrl : profileInfo.originalDisplayImageUrl}
                  alt=""
                />
                <button
                  type="button"
                  aria-label="remove logo attachment"
                  onClick={() => removeLogoImageAttachement()}
                  className="absolute z-50 top-1 right-1 p-1 bg-black/40 backdrop-blur rounded-full hover:opacity-75"
                >
                  <p className="text-red text-lg">
                    <IoClose />
                  </p>
                </button>
              </>
            ) : (
              <div className="h-full relative">
                <p className="absolute top-2 right-2 text-steelGray font-compact font-semibold text-xl">*</p>
                <span
                  onClick={clickLogoUpload}
                  className="w-full h-full cursor-pointer flex justify-center items-center"
                >
                  <UploadLogoIcon />
                </span>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png, .webp"
                  hidden
                  required
                  name="photo"
                  ref={logoImageRef}
                  id="upload-logo"
                  onChange={handleLogoImageAttach}
                />
              </div>
            )}
          </div>
          {/* Upload team logo description and arrow icon */}
          <div className="flex gap-1 items-center pt-4 justify-center pb-6">
            <p className="font-compact text-base font-medium tracking-[0.01rem] uppercase text-white text-center !leading-4 -mb-1">
              Profile Image *
            </p>
            <span className="block">
              <UploadArrowIcon />
            </span>
          </div>
          {/* Form for team details */}
        </div>
        {/* Team Name input field */}
        <div className="flex flex-col max-[26.25rem]:p-3 px-6">
          <label
            className="font-compact text-base font-normal text-steelGray tracking-[0.01rem] leading-normal text-start"
            htmlFor="displayName"
          >
            Display Name *
          </label>
          {!displayNameValid ? (
            <p className="text-sm text-red/70 font-compact text-left font-thin">
              Only alphanumerical characters + symbols allowed
            </p>
          ) : ''}
          <InputWrapper>
            <div className="relative w-full">
              <input
                className={`completeInput !bg-transparent !font-compact w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 rounded-xl px-4  ${
                  activeField === "displayName"
                    ? displayNameValid ? "border-green border-2 pr-10" : 'border-red border-2 pr-10'
                    : displayNameValid ? "border-transparent border-2 pr-10" : 'border-red/70 border-2 pr-10'
                }`}
                onClick={() => setActiveField("displayName")}
                required
                id="displayName"
                type="text"
                value={profileInfo.displayName}
                onChange={(e) => {
                  if (/^.{0,15}$/.test(e.target.value)) {
                    setProfileInfo({
                      ...profileInfo,
                      displayName: e.target.value,
                    });
                  }
                }}
                placeholder="display name here"
              />
              {activeField === 'displayName' ? (
                <span
                  onClick={() => {
                    setProfileInfo({
                      ...profileInfo,
                      displayName: '',
                    });
                    setActiveField('');
                  }}
                  className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
                >
                  <InputCross />
                </span>
              ) : ''}
            </div>
          </InputWrapper>
          <p
            className={`font-compact font-normal text-sm tracking-[0.009rem] leading-[114.286%] pt-2 text-end
                         ${profileInfo.displayName.length > 0 ?
                            `${displayNameValid ? "text-green opacity-60" : "text-red opacity-60"}`
                             : "text-white opacity-40"}`}>
            {profileInfo.displayName.length}/{15}
          </p>

          <div className="flex flex-col mt-2">
            <label
              className="font-compact text-base font-normal text-steelGray tracking-[0.01rem] leading-normal text-start"
              htmlFor="username"
            >
              Username *
            </label>
            <InputWrapper>
              <div className="relative w-full">
                <input
                  className={`completeInput w-full h-full focus:outline-none bg-black text-steelGray py-4 sm:py-3 rounded-xl px-4 border-transparent border-2 pointer-events-none opacity-50`}
                  onClick={() => setActiveField("teamTag")}
                  required
                  id="username"
                  type="text"
                  value={`@${profileInfo.username}`}
                  readOnly={true}
                  placeholder="username here"
                />
              </div>
            </InputWrapper>
          </div>

          <label
            className="font-compact text-base font-normal text-steelGray tracking-[0.01rem] leading-normal text-start mt-6"
            htmlFor="dateOfBirth"
          >
            Date of Birth *
          </label>
          {!dateOfBirthValid ? (
            <p className="text-sm text-red/70 font-compact text-left font-thin">
              You must be at least 13 to use our platform
            </p>
          ) : ''}
          <div onClick={() => setActiveField('dateOfBirth')}
               className={`completeInput mt-1 w-full border-2  rounded-lg
                          ${activeField === 'dateOfBirth' ? dateOfBirthValid ? 'border-green' : 'border-red'
                          : dateOfBirthValid ? 'border-transparent' : 'border-red/70'}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker value={profileInfo.dateOfBirth} onChange={(newDate: Date | null) => {
                setProfileInfo({
                  ...profileInfo,
                  dateOfBirth: newDate ?? new Date(),
                });
              }}
              format="dd/MM/yyyy" />
            </LocalizationProvider>
          </div>
      <AgeDisplayOptIn optedIn={profileInfo.showAge} handleOptInOut={(show: boolean) => {
                          setProfileInfo({
                            ...profileInfo,
                            showAge: show,
                          });
                        }}/>
      <label className="font-compact font-normal text-base text-start !tracking-[0.01rem] text-steelGray pt-6">
        Nationality *
      </label>
      <div className="w-full block mt-2">
        <div className="gameInput relative block w-full h-fit"
              onClick={() => {
                if (activeField === 'nationality') {
                  setActiveField('')
                } else {
                  setActiveField('nationality');
                  setTimeout(() => {
                    if (scrollContainerRef.current) {
                      const container = scrollContainerRef.current as HTMLDivElement;
                      container.scrollTop = container.scrollHeight;
                    }
                  }, 10)
                }
                }}>
          <div className={`gap-3 flex items-center justify-between bg-black rounded-xl pr-4 relative cursor-pointer border-2
                          ${activeField === 'nationality' ? nationalityValid ? 'border-green' : 'border-red'
                          : nationalityValid ? 'border-transparent' : 'border-red/70'}`}>
            {/* <div className="flex items-center align-middle">
              <p className={`font-compact font-normal text-sm !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${profileInfo.nationality ? 'text-white' : 'text-steelGray'}`}>
                {profileInfo.nationality ? getName(profileInfo.nationality) : "Select your nationality"}
              </p>
            </div> */}
            <input type="text"
                   id="nationality"
                   autoComplete="off"
                   value={countriesInput}
                   placeholder="Search for a country"
                   onChange={(e) => {
                    setActiveField('nationality');
                     setTimeout(() => {
                       if (scrollContainerRef.current) {
                         const container = scrollContainerRef.current as HTMLDivElement;
                         container.scrollTop = container.scrollHeight;
                       }
                     }, 10)
                    if (countriesInput === getName(profileInfo.nationality)) {
                      const keyPressed = (e.nativeEvent as unknown as {data: string}).data;
                      if (/^[a-zA-Z]$/.test(keyPressed)) {
                        setCountriesFilter(keyPressed);
                        setCountriesInput(keyPressed);
                      } else {
                        setCountriesFilter('');
                        setCountriesInput('');
                      }
                    } else {
                      setCountriesFilter(e.target.value);
                      setCountriesInput(e.target.value);
                    }
                    setProfileInfo({
                      ...profileInfo,
                      nationality: ''
                    })
                   }}
                   className={`w-full h-full font-compact font-normal text-sm py-4 sm:py-3 pl-4 border-0 outline-none !bg-transparent placeholder-steelGray
                              !leading-4 tracking-[0.009rem] align-middle -mb-[0.188rem] ${profileInfo.nationality || countriesInput ? 'text-white' : 'text-steelGray'}`}/>
            <span
              className={` ${
                activeField === 'nationality' ? "open rotate-[180deg]" : ""
              }`}
            >
              <DownSelectArrow />
            </span>
          </div>
          {activeField === 'nationality' && (
            <div className=" bg-lightBlack bg-no-repeat bg-cover w-full">
              <div className="flex flex-col rounded-md border border-white border-opacity-[0.2] overflow-y-scroll max-h-[110px]">
                {countriesList.length > 0 ? (
                  <>
                    {countriesList.map((country) => {
                      return (
                        <div key={country[1]}
                              onMouseDown={() => {
                                setProfileInfo({
                                  ...profileInfo,
                                  nationality: country[0]
                                });
                                setCountriesInput(country[1]);
                                setActiveField('');
                              }}
                          className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-[#B1B1B1] font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center">
                          {country[1]}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="mb-0 cursor-pointer p-3 hover:bg-lightGray text-red/60 font-compact font-normal text-sm tracking-[0.009rem] hover:text-white delay-[20ms] border-b border-b-white border-opacity-5 text-center">
                    No countries found
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
        </div>
      </div>
      <div className="border-t-[0.063rem] border-lightGray py-3 px-4">
        <button
          disabled={!formValid}
          onClick={handleStepSubmit}
          type="submit"
          className={`font-compact text-base font-medium leading-[100%] tracking-[0.01rem] uppercase w-full  rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2 ${
            formValid
              ? "cursor-pointer opacity-100"
              : "cursor-not-allowed opacity-30"
          }`}
        >
          <span className="pt-[0.125rem]">next</span>
          <span>
            <BlackArrow />
          </span>
        </button>
      </div>
    </div>
  );
};
export default Step1;
