import { Fragment, ReactNode } from "react";
// components
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";

interface IProps {
  modalInner: ReactNode,
  modalOpen: boolean,
  closeModal: () => void,
}
const TeamModal = (props: IProps) => {
  const { modalInner, modalOpen } = props;

  return (
    <>
      <div>
          <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[50]" onClose={() => false}>
              <div className="fixed inset-0 overflow-y-auto mt-[4.75rem] lg:mt-[7.5rem]">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/25" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-end sm:items-center justify-center sm:p-4 text-center w-full after:w-full after:h-full after:absolute after:bg-black/70">
                    <TransitionChild
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <DialogPanel className="w-full sm:max-w-[30rem] flex-shrink-0 mx-auto flex items-center relative z-10 rounded-none sm:rounded-xl overflow-hidden">
                        {modalInner}
                      </DialogPanel>
                    </TransitionChild>
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
      </div>
    </>
  );
};
export default TeamModal;
