import { useMemo, useState } from 'react';
// context
import TeamsProvider from "./TeamsProvider";
// components
import Hero from "./components/Hero";
import MainList from "./components/MainList";
import FindTeam from "@common/FindTeam";

const Teams = () => {
  const [teamsLoaded, setTeamsLoaded] = useState<boolean>(false);

  const announceTeamsLoaded = useMemo(() => setTeamsLoaded, []);

  return (
    <div className="custom_container">
      <TeamsProvider announceTeamsLoaded={announceTeamsLoaded}>
        <Hero/>
        <div className="px-4 sm:px-0">
          <MainList/>
          <div className="px-3 sm:px-5 lg:px-12">
            <FindTeam loaded={teamsLoaded}/>
          </div>
        </div>
      </TeamsProvider>
    </div>
  );
};

export default Teams;
