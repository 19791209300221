// types
import { DropDownOption } from "@ui/DropDown";

export enum Region {
  NA,
  EMEA,
  LATAM,
  APAC
}

export const regionToString = (region: Region): string => {
  switch (region) {
    case Region.NA:
      return 'NA'
    case Region.EMEA:
      return 'EMEA'
    case Region.LATAM:
      return 'LATAM'
    case Region.APAC:
      return 'APAC'
    default:
     return '';
  }
};

const regionEnumValues = Object.values(Region).filter((value) => typeof value === 'number');

export const regionNames = regionEnumValues.map((enumValue) => regionToString(enumValue));

export const regionsAsDropdownOptions: DropDownOption[] = regionEnumValues.map((enumValue) => ({
  value: enumValue,
  label: regionToString(enumValue)
}));
