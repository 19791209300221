import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// components
import DropDown from "@ui/DropDown";
import Modal from "@ui/Modal";
import NoticeText from '@ui/NoticeText';
// icons
import { FaCheck } from 'react-icons/fa';
import { BlackArrow } from '@icons/Common';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  loadSelectedPreset: () => void,
  selectedPreset: TournamentInfo | null,
  setSelectedPreset: (preset: TournamentInfo) => void,
  presets: TournamentInfo[],
  stepNames: string[]
}

const PresetStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, selectedPreset, setSelectedPreset, presets, loadSelectedPreset, stepNames}) => {
  const [stepValid, setStepValid] = useState<boolean>(true);

  const presetAsDropDownOptions = presets.map((preset, index) => ({value: index, label: preset.presetName}))

  useEffect(() => {
    setStepValid(true);
  }, []);

  return (
    <Modal title="Use Preset"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={1}
           totalSteps={10}
           open={currentStep === CreationFlowPage.preset}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           buttonOnClick={() => changeStep(CreationFlowPage.details)}>
      <div className="flex flex-col gap-y-3 text-white font-compact items-start">
        {presetAsDropDownOptions.length > 0 ? (
          <div className='flex flex-col gap-y-2 text-start items-start'>
            <p className='text-sm text-steelGray font-compact'>Preset</p>
            <DropDown currentOption={selectedPreset ? presetAsDropDownOptions.find((option) => option.label === selectedPreset.presetName)! : presetAsDropDownOptions[0]}
                      options={presetAsDropDownOptions}
                      handleOnChange={(newValue) => {
                        const newPresetName = presetAsDropDownOptions.find((option) => option.value === newValue)!.label;
                        const newSelectedPreset = presets.find((preset) => preset.presetName === newPresetName)!;
                        setSelectedPreset(newSelectedPreset);
                      }}
                      black={true}/>
          </div>
        ) : (
          <NoticeText>
            No Presets Available
          </NoticeText>
        )}
        <button type='button' aria-label='Load Tournament Preset'
                disabled={!selectedPreset || selectedPreset.presetName === tournamentInfo.presetLoaded}
                onClick={loadSelectedPreset}
                className='flex items-center justify-center gap-x-2 w-full p-2 text-green font-compact uppercase font-semibold rounded-lg
                          bg-ebonyClay hover:opacity-75 disabled:opacity-50 disabled:hover:opacity-50 transition-all'>
          <span className='pt-[2px]'>
            {selectedPreset?.presetName === tournamentInfo.presetLoaded ? 'Preset Loaded' : 'Load Preset'}
          </span>
          {selectedPreset?.presetName === tournamentInfo.presetLoaded ? (
            <FaCheck/>
          ) : ''}
        </button>
      </div>
    </Modal>
  )
}

export default PresetStep;
