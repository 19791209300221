import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { BannerContent } from "../common/content/HomePageContent";

const Hero = () => {

  return (
      <Swiper
        className="landing_hero_swiper"
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        effect={"fade"}
        autoplay={{
          delay: 5000,
        }
        }
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        loop={true}
      >
        {/* HERO SECTION SLIDER DATA THROUGH THE MAP WITH THE HELP OF THE ARRAY */}
        {BannerContent.map((obj, index) => (
          <SwiperSlide
            key={index}
            className={`h-[40.625rem] sm:h-[36.25rem] xl:h-[45rem] relative flex items-center w-full bg-cover bg-no-repeat ${obj.image}`}
          >
            <img
              className={`hidden sm:block absolute right-0 bottom-0 sm:bottom-[-15.563rem] md:bottom-[-1.188rem] lg:bottom-[-0.188rem]  ${
                index === 0 ? "z-0" : "z-[1]"
              }`}
              src={obj.bannerEffectForBottom}
              alt="cartton-img-1"
            />
            <img
              className={`sm:hidden absolute right-0 bottom-0 ${
                index === 0 ? "z-0" : "z-[1]"
              }`}
              src={obj.bannerEffectForBottomMobile}
              alt="cartton-img-1"
            />
            <div className="text-center flex flex-col justify-center relative w-full custom_container mx-auto ">
              <button className="absolute swiper-button-next "></button>
              <button className="absolute swiper-button-prev"></button>
              <img
                className={`${
                  index === 0
                    ? "z-0 w-[19.375rem] top-[3.625rem] right-1/2 translate-x-1/2 sm:translate-x-0 sm:w-[16.25rem] md:w-[17.5rem] lg:!w-[21.875rem] xl:!w-[29.25rem] sm:right-[4.688rem] md:right-40 lg:right-[15.625rem] xl:right-[20.25rem] min-[1599px]:right-[24.25rem]  sm:top-[7.813rem] md:top-[11.8rem] lg:top-[4.5rem] xl:top-[0.875rem] custom_4xl:top-[-9.375rem]"
                    : "!w-[19.375rem] !top-[-1.375rem] !right-1/2 !translate-x-1/2 sm:!translate-x-0 sm:!w-[18.75rem] md:!w-[17.625rem] lg:!w-[19.5rem] xl:!w-[25rem] sm:!right-5 md:!right-[4.375rem] min-[1599px]:!right-[8%] sm:!top-[5.438rem] md:!top-[5.875rem] lg:!top-[1.375rem] xl:!-top-[2.25rem] !z-[-1]"
                } absolute hidden sm:block`}
                src={obj.avatar}
                alt="cartton-img-1"
              />
              <img
                className={`absolute w-full left-1/2 -translate-x-1/2 sm:hidden top-[-5rem] ${
                  index === 0 ? "" : "opacity-60"
                }`}
                src={obj.bannerCartoonForMobile}
                alt="bannerCartoonForMobile"
              />
              <img
                className={`absolute right-[0.188rem] sm:right-0 md:right-20 xl:right-[11.313rem] lg:right-[10.438rem] min-[1599px]:right-[16.313rem] z-[-1] w-[9.375rem] md:w-[10.625rem] lg:w-[12.5rem] xl:w-[14.875rem] top-[-4.875rem] sm:top-[6.25rem] md:top-[10.438rem] lg:top-[2.938rem] xl:top-[1.063rem]
                ${index === 0 ? "hidden sm:block" : "hidden"}`}
                src={obj.avatarSecond}
                alt="cartton-img-2"
              />
              <img
                className={`${
                  index === 0
                    ? "w-[16.25rem] md:w-[18.75rem] lg:w-[22.75rem] xl:w-auto left-[0.188rem] sm:left-[4.313rem] md:left-[11.125rem] lg:left-[14.313rem]  xl:left-[19.188rem] min-[99.938rem]:left-[22%] z-0 sm:top-[8rem] md:top-[10.875rem] lg:top-[2.938rem] xl:-top-0.5"
                    : "!left-[0.188rem] sm:!left-5 w-[11.25rem] md:w-[13.875rem] lg:w-[16.5rem] xl:w-[20rem] md:!left-[7.625rem] xl:!left-[10.438rem] min-[1599px]:!left-[15%] z-0 sm:!top-[7.813rem] md:!top-[8rem] lg:!top-[1.375rem] xl:!top-[2.375rem]"
                } !hidden sm:!block absolute `}
                src={obj.avatarThird}
                alt="cartton-img-3"
              />
              <img
                className={`${
                  index === 0 ? "block" : "!hidden"
                }   hidden sm:block absolute  w-[11.25rem] md:w-[13.875rem] lg:w-[18.75rem] left-0 md:left-24 lg:left-[9.25rem] xl:left-[12.375rem] min-[1599px]:left-[14%] z-[-1] sm:top-[8rem] md:top-[11.813rem] lg:top-[3.125rem] xl:top-[5.313rem]`}
                src={obj.avatarFour}
                alt="cartton-img-4"
              />
              <div className="w-full sm:w-8/12 mx-auto relative sm:pb-48 lg:pb-14 pt-6 sm:pt-0">
                <span className="flex justify-center">
                  {obj.bannerLine}
                </span>
                <h1
                  className={`${
                    index === 0
                      ? "text-white"
                      : "text-lightGray max-w-[50rem] mx-auto"
                  } uppercase leading-[90%] max-w-[46rem] px-12 font-bold font-wide text-[3.15rem] sm:text-[3.6rem] md:text-[3.78rem] lg:text-[5.04rem] xl:text-[5.58rem] pt-3 sm:pt-0 lg:pt-5 mx-auto text-center flex items-center justify-center`}
                >
                  {obj.title}
                </h1>
                <p
                  className={`${
                    index === 0 ? "text-white" : "text-lightGray"
                  } text-base uppercase tracking-[0.2rem] font-normal font-compact py-10 sm:py-6 lg:py-8 xl:py-10 max-[26.25rem]:py-4`}
                >
                  {obj.description}
                </p>
                <div className="flex justify-center gap-6 z-10">
                  <span>{obj.bannerLeftLine}</span>
                  <Link
                    to={obj.link}
                    className={`pt-3 pb-2 sm:pt-4 sm:pb-3 a-auto flex items-center justify-center duration-300 rounded-xl font-compact text-sm md:text-base font-medium uppercase relative z-[1] whitespace-nowrap ${
                      index === 0
                        ? "bg-green px-[2.47rem] hover:bg-gorse text-black"
                        : "bg-lightGray border-lightGray hover:bg-black text-white border px-[3.7rem]"
                    }`}
                  >
                    {obj.button}
                  </Link>
                  <span>{obj.bannerRightLine}</span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
  );
};

export default Hero;
