export const ChatmessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        className="duration-300"
        d="M4.125 0L1 2.85714L1 13.1429H4.75V16L7.875 13.1429L10.375 13.1429L16 8V0L4.125 0ZM14.75 7.42857L12.25 9.71429H9.75L7.5625 11.7143L7.5625 9.71429H4.75L4.75 1.14286L14.75 1.14286L14.75 7.42857Z"
        fill="#4D545E"
      />
      <path
        className="duration-300"
        d="M12.8751 3.14284H11.6251V6.57141L12.8751 6.57141V3.14284Z"
        fill="#4D545E"
      />
      <path
        className="duration-300"
        d="M9.43759 3.14284H8.18759V6.57141H9.43759V3.14284Z"
        fill="#4D545E"
      />
    </svg>
  );
};
export const YoutubeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        className="duration-300"
        d="M15.6655 3.76C15.575 3.41961 15.3967 3.10895 15.1485 2.8591C14.9002 2.60926 14.5907 2.429 14.2509 2.33636C13 2 8 2 8 2C8 2 3 2 1.74909 2.33636C1.40929 2.429 1.09977 2.60926 0.851526 2.8591C0.603279 3.10895 0.425003 3.41961 0.334545 3.76C-6.71907e-07 5.01636 0 7.63636 0 7.63636C0 7.63636 -6.71907e-07 10.2564 0.334545 11.5127C0.425003 11.8531 0.603279 12.1638 0.851526 12.4136C1.09977 12.6635 1.40929 12.8437 1.74909 12.9364C3 13.2727 8 13.2727 8 13.2727C8 13.2727 13 13.2727 14.2509 12.9364C14.5907 12.8437 14.9002 12.6635 15.1485 12.4136C15.3967 12.1638 15.575 11.8531 15.6655 11.5127C16 10.2564 16 7.63636 16 7.63636C16 7.63636 16 5.01636 15.6655 3.76ZM6.36364 10.0155L6.36364 5.25727L10.5455 7.63636L6.36364 10.0155Z"
        fill="#4D545E"
      />
    </svg>
  );
};
export const TwitterIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3675 8.66L18.6963 1.25H17.1962L11.7025 7.68375L7.3125 1.25H2.25L8.8875 10.98L2.25 18.75H3.75L9.5525 11.955L14.1888 18.75H19.2512L12.3675 8.66ZM10.3138 11.065L9.64125 10.0962L4.29 2.3875H6.59375L10.9112 8.60875L11.5838 9.5775L17.1975 17.665H14.8937L10.3138 11.065Z"
        fill="#4C545D"
      />
    </svg>
  );
};
export const DiscordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        className="duration-300"
        d="M12.99 3.32832C12.0722 2.8986 11.088 2.58199 10.0589 2.40066C10.0402 2.39716 10.0215 2.40591 10.0118 2.4234C9.88525 2.65313 9.74504 2.95282 9.64686 3.18838C8.54005 3.01929 7.43891 3.01929 6.3548 3.18838C6.25659 2.94758 6.1113 2.65313 5.98415 2.4234C5.9745 2.40649 5.95577 2.39775 5.93703 2.40066C4.90855 2.58141 3.92434 2.89802 3.00597 3.32832C2.99802 3.33182 2.9912 3.33765 2.98668 3.34523C1.11984 6.19117 0.608435 8.96716 0.859313 11.7087C0.860448 11.7221 0.867827 11.735 0.878043 11.7431C2.10973 12.6661 3.30283 13.2265 4.47377 13.5979C4.49251 13.6037 4.51237 13.5967 4.52429 13.581C4.80128 13.195 5.04819 12.788 5.25989 12.36C5.27238 12.3349 5.26046 12.3052 5.23492 12.2953C4.84328 12.1437 4.47036 11.9589 4.11164 11.749C4.08327 11.7321 4.081 11.6907 4.1071 11.6708C4.18259 11.6131 4.2581 11.553 4.33018 11.4924C4.34322 11.4813 4.36139 11.479 4.37672 11.486C6.73338 12.5839 9.28473 12.5839 11.6136 11.486C11.6289 11.4784 11.6471 11.4808 11.6607 11.4918C11.7328 11.5525 11.8083 11.6131 11.8843 11.6708C11.9104 11.6907 11.9087 11.7321 11.8804 11.749C11.5216 11.9629 11.1487 12.1437 10.7565 12.2947C10.731 12.3046 10.7196 12.3349 10.7321 12.36C10.9484 12.7874 11.1953 13.1944 11.4671 13.5804C11.4785 13.5967 11.4989 13.6037 11.5177 13.5979C12.6943 13.2265 13.8874 12.6661 15.1191 11.7431C15.1299 11.735 15.1367 11.7227 15.1378 11.7093C15.4381 8.53976 14.6349 5.78653 13.0087 3.34581C13.0048 3.33765 12.998 3.33182 12.99 3.32832ZM5.61182 10.0394C4.9023 10.0394 4.31768 9.37472 4.31768 8.55842C4.31768 7.74213 4.89097 7.07744 5.61182 7.07744C6.33833 7.07744 6.91729 7.74796 6.90593 8.55842C6.90593 9.37472 6.33265 10.0394 5.61182 10.0394ZM10.3967 10.0394C9.68716 10.0394 9.10254 9.37472 9.10254 8.55842C9.10254 7.74213 9.6758 7.07744 10.3967 7.07744C11.1232 7.07744 11.7021 7.74796 11.6908 8.55842C11.6908 9.37472 11.1232 10.0394 10.3967 10.0394Z"
        fill="#4D545E"
      />
    </svg>
  );
};

//FOOTER ACCORDION CLOSE OPEN ICON
import React from "react";

interface FooterAccordionIconProps {
  accordianArrow: string;
}

export const FooterAccordionIcon: React.FC<FooterAccordionIconProps> = ({
  accordianArrow,
}) => {
  return (
    <svg
      className={` ${accordianArrow} `}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 5L8 11L14 5"
        stroke="#D4FF27"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GreenSubmitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.3335 8.00016H11.8695M7.9999 3.3335L12.6666 8.00016L7.9999 12.6668"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
