// ABOUT HERO GRAPH LINE
export const AboutHeroGraphLine = () => (
  <svg
    className="w-full"
    width="1440"
    height="86"
    viewBox="0 0 1440 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-161 85C-30.3231 85 133.681 85 133.681 85L223.931 1H360"
      stroke="#D4FF27"
    />
    <path
      d="M23 85C153.677 85 157.681 85 157.681 85L247.931 1H720"
      stroke="#D4FF27"
    />
    <path
      d="M1601 85C1470.32 85 1306.32 85 1306.32 85L1216.07 1H1080"
      stroke="#D4FF27"
    />
    <path
      d="M1417 85C1286.32 85 1282.32 85 1282.32 85L1192.07 1H720"
      stroke="#D4FF27"
    />
  </svg>
);

// ABOUT HERO PARA TOP-LINE
export const AboutParaOuterLine = () => (
  <svg
    className="w-full"
    width="1104"
    height="80"
    viewBox="0 0 1104 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M552 40H40V72" stroke="#D4FF27" />
    <path d="M88 40H40V72" stroke="#1F272E" />
    <g filter="url(#filter0_d_1600_26347)">
      <path d="M36 40L40 36L44 40L40 44L36 40Z" fill="#D4FF27" />
    </g>
    <g filter="url(#filter1_d_1600_26347)">
      <path
        d="M28.7071 40L40 28.7071L51.2929 40L40 51.2929L28.7071 40Z"
        stroke="#D4FF27"
        shapeRendering="crispEdges"
      />
    </g>
    <path d="M552 40H1064V72" stroke="#D4FF27" />
    <path d="M1016 40H1064V72" stroke="#1F272E" />
    <g filter="url(#filter2_d_1600_26347)">
      <path d="M1068 40L1064 36L1060 40L1064 44L1068 40Z" fill="#D4FF27" />
    </g>
    <g filter="url(#filter3_d_1600_26347)">
      <path
        d="M1075.29 40L1064 28.7071L1052.71 40L1064 51.2929L1075.29 40Z"
        stroke="#D4FF27"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1600_26347"
        x="8"
        y="8"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1600_26347"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1600_26347"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1600_26347"
        x="0"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1600_26347"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1600_26347"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_1600_26347"
        x="1032"
        y="8"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1600_26347"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1600_26347"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_1600_26347"
        x="1024"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1600_26347"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1600_26347"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
// ABOUT HERO GRAPH LINE
export const AboutHeroGraphSmLine = () => (
  <svg
    className=" w-full"
    width="640"
    height="30"
    viewBox="0 0 640 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1704_28504)">
      <path d="M115.227 29L143.31 1H165" stroke="#D4FF27" />
      <path d="M-178 29H131.227L161.31 1" stroke="#D4FF27" />
      <path d="M165 1H475" stroke="#D4FF27" />
      <path d="M524.773 29L496.69 1H475" stroke="#D4FF27" />
      <path d="M818 29H508.773L478.69 1" stroke="#D4FF27" />
    </g>
    <defs>
      <clipPath id="clip0_1704_28504">
        <rect width="640" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const AboutParaOuterLineMobile = () => (
  <svg
    className="w-full"
    width="420"
    height="80"
    viewBox="0 0 420 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M121.5 40H40.5V72" stroke="#D4FF27" />
    <path d="M88.5 40H40.5V72" stroke="#1F272E" />
    <g filter="url(#filter0_d_1945_20893)">
      <path d="M36.5 40L40.5 36L44.5 40L40.5 44L36.5 40Z" fill="#D4FF27" />
    </g>
    <g filter="url(#filter1_d_1945_20893)">
      <path
        d="M29.2071 40L40.5 28.7071L51.7929 40L40.5 51.2929L29.2071 40Z"
        stroke="#D4FF27"
        shapeRendering="crispEdges"
      />
    </g>
    <path d="M121 40H299" stroke="#D4FF27" />
    <path d="M298.5 40H379.5V72" stroke="#D4FF27" />
    <path d="M331.5 40H379.5V72" stroke="#1F272E" />
    <g filter="url(#filter2_d_1945_20893)">
      <path d="M383.5 40L379.5 36L375.5 40L379.5 44L383.5 40Z" fill="#D4FF27" />
    </g>
    <g filter="url(#filter3_d_1945_20893)">
      <path
        d="M390.793 40L379.5 28.7071L368.207 40L379.5 51.2929L390.793 40Z"
        stroke="#D4FF27"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1945_20893"
        x="8.5"
        y="8"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1945_20893"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1945_20893"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1945_20893"
        x="0.5"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1945_20893"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1945_20893"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_1945_20893"
        x="347.5"
        y="8"
        width="64"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1945_20893"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1945_20893"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_1945_20893"
        x="339.5"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1945_20893"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1945_20893"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const FindTeamTriangleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 8H11.87M8.00039 3.33334L12.6671 8L8.00039 12.6667"
      stroke="#D4FF27"
      strokeWidth="2.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export const HeroTrianglediamondIcon = () => (
  <svg
    className="max-h-[34.813rem] max-w-[34.813rem] w-full h-full object-cover"
    width="561"
    height="551"
    viewBox="0 0 561 551"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 270.5L280.5 519L529 270.5L280.5 22L32 270.5Z"
      stroke="#151A1F"
      strokeWidth="2"
    />
    <path
      d="M2 270.5L280.5 549L559 270.5L280.5 -8L2 270.5Z"
      stroke="#151A1F"
      strokeWidth="2"
    />
  </svg>
);
export const YellowArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 8H11.87M8.00039 3.33334L12.6671 8L8.00039 12.6667"
      stroke="#D4FF27"
      strokeWidth="2.5"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
export const PrizesWonIcon = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="100" cy="100" r="100" fill="url(#paint0_linear_1642_12619)" />
    <ellipse
      cx="100"
      cy="100.625"
      rx="98.75"
      ry="98.125"
      fill="url(#paint1_linear_1642_12619)"
    />
    <circle cx="100" cy="100" r="83.75" fill="url(#paint2_linear_1642_12619)" />
    <ellipse cx="100" cy="99.375" rx="82.5" ry="81.875" fill="#D4FF27" />
    <circle cx="100" cy="100" r="70.5" stroke="#AACF15" strokeWidth="4" />
    <path
      d="M99.9575 61.6013C96.5087 61.6539 93.0672 61.8713 89.6329 62.1939C86.1479 62.5164 82.7028 63.0494 79.2757 63.7085C77.5929 64.0346 76.1743 64.7358 75.2153 66.1593C74.506 67.2147 74.2527 68.3787 74.3432 69.6269C74.4083 70.5595 74.3866 71.4922 74.4445 72.4283C74.5857 74.7774 74.8752 77.1055 75.313 79.4196C76.2033 84.1318 77.7558 88.6372 79.9922 92.9077C82.0188 96.782 84.5303 100.341 87.5484 103.563C89.2565 105.386 91.1057 107.055 93.0744 108.601C93.3205 108.794 93.3856 108.983 93.335 109.278C92.7994 112.553 93.1106 115.736 94.4568 118.804C94.7065 119.372 95.0322 119.898 95.3796 120.41C95.5968 120.726 95.5244 120.88 95.2023 121.055C92.5786 122.5 90.0092 124.028 87.6027 125.795C86.6437 126.5 85.6956 127.226 84.9247 128.141C84.4688 128.684 84.201 129.263 84.2227 129.982C84.2516 131.02 84.2372 132.057 84.2263 133.092C84.2263 133.439 84.3312 133.569 84.7293 133.558C85.6159 133.533 86.5062 133.593 87.3964 133.593C96.563 133.6 105.73 133.6 114.9 133.6C115.678 133.6 115.681 133.593 115.674 132.843C115.667 131.907 115.627 130.97 115.674 130.038C115.718 129.182 115.392 128.506 114.806 127.91C113.456 126.535 111.896 125.417 110.278 124.365C108.512 123.218 106.703 122.138 104.861 121.108C104.362 120.827 104.344 120.768 104.644 120.294C106.059 118.075 106.689 115.645 106.822 113.061C106.888 111.816 106.815 110.582 106.634 109.355C106.58 109.001 106.681 108.759 106.974 108.521C111.422 104.909 115.225 100.747 118.236 95.93C121.425 90.8251 123.513 85.3169 124.62 79.4546C125.235 76.1904 125.539 72.8946 125.641 69.5778C125.72 66.8991 124.215 64.3852 120.697 63.712C118.052 63.2036 115.396 62.7548 112.718 62.4393C110.568 62.1868 108.411 61.9625 106.243 61.8573C104.144 61.7591 102.056 61.5838 99.9575 61.6013Z"
      fill="#0D1012"
    />
    <path
      d="M128.069 122.85C127.91 120.61 127.768 118.587 127.616 116.564C127.569 115.95 127.577 115.908 128.224 115.813C131.42 115.337 134.528 114.527 137.604 113.576C137.662 113.559 137.72 113.545 137.775 113.517C137.966 113.419 138.281 113.394 138.263 113.135C138.245 112.879 137.941 113.015 137.771 112.952C136.7 112.556 135.654 112.107 134.601 111.673C131.257 110.295 127.971 108.801 124.834 107.013C123.064 106.007 121.352 104.916 119.735 103.689C118.96 103.1 118.674 103.146 118.055 103.903C115.855 106.599 113.488 109.148 110.85 111.452C110.347 111.89 110.362 111.883 110.843 112.29C115.338 116.101 120.361 119.155 125.572 121.935C126.22 122.279 126.86 122.636 127.511 122.969C128.004 123.218 128.076 123.166 128.065 122.847L128.069 122.85Z"
      fill="#0D1012"
    />
    <path
      d="M61.6193 113.307C64.9993 114.432 68.3974 115.319 71.8969 115.806C72.3347 115.866 72.3926 116.059 72.3673 116.42C72.2406 118.152 72.1321 119.884 72.0127 121.616C71.9873 121.995 71.9439 122.373 71.9186 122.749C71.8896 123.159 71.962 123.215 72.3347 123.022C73.3733 122.486 74.4083 121.946 75.4325 121.388C78.874 119.505 82.2432 117.514 85.3988 115.2C86.7197 114.229 88.0152 113.229 89.2855 112.192C89.5424 111.981 89.5243 111.844 89.2855 111.641C87.7004 110.309 86.2384 108.857 84.8126 107.367C83.8282 106.336 82.9199 105.246 82.026 104.141C81.9428 104.036 81.8632 103.931 81.7799 103.826C81.248 103.17 81.0055 103.125 80.3288 103.633C77.7847 105.547 75.0561 107.185 72.19 108.601C68.886 110.235 65.5204 111.736 62.0463 113.012C61.8943 113.068 61.7351 113.117 61.6121 113.307H61.6193Z"
      fill="#0D1012"
    />
    <path
      d="M128.554 76.811C128.499 77.2037 128.702 77.379 129.082 77.4246C129.35 77.4561 129.621 77.4877 129.885 77.5473C130.569 77.698 130.982 78.2275 130.801 78.8796C129.918 82.0667 129.024 85.2538 127.244 88.1218C126.487 89.342 125.622 90.478 124.631 91.5263C124.385 91.7893 124.2 92.0908 124.052 92.4099C122.593 95.5864 120.784 98.5631 118.671 101.368C118.595 101.47 118.443 101.578 118.544 101.708C118.652 101.845 118.79 101.708 118.909 101.67C121.96 100.702 124.685 99.1592 127.142 97.1887C129.086 95.6285 130.758 93.7983 132.227 91.8033C135.017 88.0131 136.957 83.8338 138.216 79.36C138.998 76.5831 137.21 73.8027 134.315 73.1716C132.773 72.835 131.217 72.544 129.668 72.2425C128.963 72.1057 128.923 72.1443 128.919 72.849C128.916 74.0797 128.753 75.2928 128.586 76.5095C128.572 76.6111 128.565 76.7128 128.55 76.8145L128.554 76.811Z"
      fill="#0D1012"
    />
    <path
      d="M61.6048 78.1889C61.5723 78.7043 61.7061 79.2583 61.8726 79.8052C63.2225 84.209 65.1441 88.3427 67.9559 92.0627C70.2177 95.0535 72.9174 97.5884 76.2178 99.4923C77.7232 100.362 79.3047 101.084 80.9585 101.649C81.0924 101.694 81.248 101.845 81.3674 101.694C81.476 101.557 81.3131 101.424 81.2299 101.312C79.9669 99.6886 78.8487 97.9706 77.839 96.1895C77.1189 94.9238 76.4349 93.637 75.8667 92.2977C75.6351 91.7542 75.2515 91.3335 74.8535 90.9127C72.5808 88.5075 71.2563 85.6114 70.2647 82.5506C69.9571 81.6039 69.693 80.6432 69.4071 79.6895C69.3166 79.381 69.2442 79.069 69.2406 78.7429C69.2334 78.1258 69.5156 77.7471 70.1309 77.5929C70.4204 77.5192 70.7171 77.4666 71.0138 77.4141C71.3251 77.3615 71.4517 77.2002 71.4083 76.8951C71.1948 75.4646 71.0573 74.0271 70.974 72.5826C70.9596 72.2986 70.8003 72.1864 70.5253 72.239C68.7846 72.5896 67.0114 72.7824 65.2997 73.2558C63.056 73.8763 61.6157 75.8152 61.6121 78.1784L61.6048 78.1889Z"
      fill="#0D1012"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1642_12619"
        x1="140"
        y1="5"
        x2="60.5"
        y2="190"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ECFFA0" />
        <stop offset="0.0833333" stopColor="#D5FF2E" />
        <stop offset="0.223958" stopColor="#D5FF28" />
        <stop offset="1" stopColor="#D5FF28" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1642_12619"
        x1="56.5"
        y1="178.5"
        x2="131"
        y2="-18.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#101400" />
        <stop offset="0.723958" stopColor="#343F08" />
        <stop offset="1" stopColor="#BEEB06" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1642_12619"
        x1="100"
        y1="16.25"
        x2="100"
        y2="183.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D5FF28" />
        <stop offset="1" stopColor="#DEFF57" />
      </linearGradient>
    </defs>
  </svg>
);
export const TournamentsRunIcon = () => (
  <svg
    width="202"
    height="202"
    viewBox="0 0 202 202"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="101" cy="101" r="100" fill="url(#paint0_linear_1510_14272)" />
    <ellipse cx="101" cy="101.625" rx="98.75" ry="98.125" fill="#191B23" />
    <circle cx="101" cy="101" r="83.75" fill="url(#paint1_linear_1510_14272)" />
    <ellipse cx="101" cy="100.375" rx="82.5" ry="81.875" fill="#0D1012" />
    <g filter="url(#filter0_d_1510_14272)">
      <circle
        cx="101"
        cy="101"
        r="70.5"
        stroke="#D4FF27"
        strokeWidth="4"
        shapeRendering="crispEdges"
      />
    </g>
    <g clipPath="url(#clip0_1510_14272)">
      <path
        d="M110.719 69.0421H105.517C104.829 69.0421 104.205 68.6274 103.928 67.9905L100.803 60.7576C100.495 60.0417 99.4911 60.0417 99.184 60.7576L96.059 67.9905C95.7811 68.6324 95.1571 69.0421 94.4697 69.0421H89.2678L83.1933 64.1198C82.8082 63.8088 82.2573 64.1791 82.3938 64.658L86.8692 80.5062C87.1812 81.6121 88.1807 82.3724 89.3117 82.3724H110.675C111.811 82.3724 112.805 81.6121 113.117 80.5062L117.593 64.658C117.729 64.1791 117.178 63.8088 116.793 64.1198L110.719 69.0421Z"
        fill="#D4FF27"
      />
      <path
        d="M111.535 86.3999H89.4575C88.881 86.3999 88.4136 86.9409 88.4136 87.6082V90.2261C88.4136 90.8934 88.881 91.4344 89.4575 91.4344H111.535C112.112 91.4344 112.579 90.8934 112.579 90.2261V87.6082C112.579 86.9409 112.112 86.3999 111.535 86.3999Z"
        fill="#D4FF27"
      />
      <path
        d="M133.031 122.471V112.736H123.343V102.527H101.981V96.9536C101.981 96.1282 101.316 95.4619 100.491 95.4619C99.6662 95.4619 99.0005 96.1282 99.0005 96.9536V102.527H77.6386V112.736H67.9512V122.471C64.6178 123.172 62.1239 126.19 62.2381 129.76C62.3574 133.47 65.3878 136.548 69.0938 136.722C73.2221 136.916 76.645 133.609 76.645 129.522C76.645 126.056 74.1909 123.157 70.932 122.471V115.719H88.3196V122.471C84.9861 123.177 82.4922 126.195 82.6115 129.765C82.7307 133.475 85.7611 136.553 89.4671 136.727C93.5955 136.921 97.0183 133.614 97.0183 129.527C97.0183 126.061 94.5642 123.162 91.3053 122.476V112.74H80.6243V105.516H120.367V112.74H109.686V122.476C106.353 123.182 103.859 126.2 103.978 129.77C104.098 133.48 107.128 136.558 110.834 136.732C114.962 136.925 118.385 133.619 118.385 129.532C118.385 126.066 115.931 123.167 112.672 122.481V115.729H130.06V122.481C126.726 123.187 124.232 126.205 124.352 129.775C124.471 133.345 127.501 136.563 131.207 136.737C135.336 136.93 138.759 133.624 138.759 129.537C138.759 126.071 136.304 123.172 133.045 122.486L133.031 122.471ZM73.6643 129.522C73.6643 131.854 71.7715 133.748 69.4416 133.748C67.1116 133.748 65.2189 131.854 65.2189 129.522C65.2189 127.19 67.1116 125.295 69.4416 125.295C71.7715 125.295 73.6643 127.19 73.6643 129.522ZM94.0326 129.522C94.0326 131.854 92.1399 133.748 89.8099 133.748C87.48 133.748 85.5872 131.854 85.5872 129.522C85.5872 127.19 87.48 125.295 89.8099 125.295C92.1399 125.295 94.0326 127.19 94.0326 129.522ZM115.395 129.522C115.395 131.854 113.502 133.748 111.172 133.748C108.842 133.748 106.949 131.854 106.949 129.522C106.949 127.19 108.842 125.295 111.172 125.295C113.502 125.295 115.395 127.19 115.395 129.522ZM131.54 133.748C129.21 133.748 127.317 131.854 127.317 129.522C127.317 127.19 129.21 125.295 131.54 125.295C133.87 125.295 135.763 127.19 135.763 129.522C135.763 131.854 133.87 133.748 131.54 133.748Z"
        fill="#666E77"
      />
      <rect
        x="28"
        y="28"
        width="146"
        height="146"
        fill="url(#paint2_linear_1510_14272)"
        fillOpacity="0.8"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1510_14272"
        x="0.5"
        y="0.5"
        width="201"
        height="201"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="14" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1510_14272"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1510_14272"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1510_14272"
        x1="141"
        y1="6"
        x2="61.5"
        y2="191"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#CBD3BF" />
        <stop offset="0.0833333" stopColor="#525665" />
        <stop offset="0.223958" stopColor="#1C1E27" />
        <stop offset="1" stopColor="#191B22" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1510_14272"
        x1="101"
        y1="17.25"
        x2="101"
        y2="184.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#151720" />
        <stop offset="1" stopColor="#272C3A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1510_14272"
        x1="133"
        y1="138.5"
        x2="91"
        y2="79.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#071015" />
        <stop offset="1" stopColor="#0D1012" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_1510_14272">
        <rect x="28" y="28" width="146" height="146" rx="73" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const TeamsCreatedIcon = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="52" fill="url(#paint0_linear_1510_14284)" />
    <ellipse cx="51.9999" cy="52.3248" rx="51.35" ry="51.025" fill="#131418" />
    <circle
      cx="52.0002"
      cy="52.0002"
      r="43.55"
      fill="url(#paint1_linear_1510_14284)"
    />
    <ellipse
      cx="52.0002"
      cy="51.5668"
      rx="41.1667"
      ry="40.7333"
      fill="#0D1012"
    />
    <g filter="url(#filter0_d_1510_14284)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9918 30.7666C44.728 36.1032 40.4431 39.4316 35.1001 39.6951V51.8485C35.1001 56.7979 37.3668 61.4762 41.2526 64.5478L50.5377 71.8859C51.6482 72.7639 53.2177 72.7639 54.3292 71.8859L63.6142 64.5478C67.501 61.4762 69.7668 56.7988 69.7668 51.8485V39.6951C64.4301 39.431 60.149 36.1101 59.876 30.7855C59.8757 30.7792 59.8754 30.7729 59.8751 30.7666H44.9918ZM57.6457 33.3666H47.2213C46.0316 38.019 42.2144 41.0059 37.7001 41.9707V51.8485C37.7001 56.0018 39.6022 59.9289 42.8648 62.508C42.8648 62.508 42.8647 62.5079 42.8648 62.508L52.1498 69.846C52.3147 69.9764 52.5505 69.9776 52.7175 69.8457L62.0021 62.508C65.2653 59.9291 67.1668 56.0031 67.1668 51.8485V41.9704C62.6528 41.0051 58.8354 38.0184 57.6457 33.3666Z"
        fill="#D4FF27"
      />
    </g>
    <g filter="url(#filter1_d_1510_14284)">
      <path
        d="M57.5774 57.4267C58.0637 56.8644 60.5643 52.9947 58.1533 49.3291C56.9352 47.4773 54.9388 46.4191 51.2149 45.5H53.502C57.1225 45.5 59.9265 45.8486 61.1719 47.1833C64.5313 50.7833 58.5039 56.6953 57.5774 57.4267Z"
        fill="#D4FF27"
      />
      <path
        d="M55.3513 58.6392C55.3513 58.6392 54.8598 58.7755 54.3161 58.7755C53.6233 58.7755 52.9718 58.6218 52.4012 58.3496C51.9077 58.1176 51.4734 57.7959 51.1288 57.4101L42.0332 45.5H47.0924L54.316 55.1194C54.316 55.1194 54.3249 55.1129 54.3429 55.0986C54.6606 54.852 57.2053 52.6045 56.0156 49.2652C56.6994 49.8607 57.3993 50.7764 57.5932 52.2267C58.0561 55.6829 55.9077 58.1256 55.3514 58.6394L55.3513 58.6392Z"
        fill="#D4FF27"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1510_14284"
        x="23.8334"
        y="19.4999"
        width="57.1998"
        height="64.3112"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5.63333" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1510_14284"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1510_14284"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1510_14284"
        x="30.7665"
        y="34.2333"
        width="42.6813"
        height="35.8087"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5.63333" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.831373 0 0 0 0 1 0 0 0 0 0.152941 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1510_14284"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1510_14284"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1510_14284"
        x1="72.8"
        y1="2.6"
        x2="31.46"
        y2="98.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D2F36" />
        <stop offset="0.1875" stopColor="#1C1E27" />
        <stop offset="0.411458" stopColor="#1C1E27" />
        <stop offset="1" stopColor="#191B22" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1510_14284"
        x1="52.0002"
        y1="8.4502"
        x2="52.0002"
        y2="95.5502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#101115" />
        <stop offset="1" stopColor="#272C3A" />
      </linearGradient>
    </defs>
  </svg>
);
export const EstablishedIcon = () => (
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="52" cy="52" r="52" fill="url(#paint0_linear_1510_14293)" />
    <ellipse cx="51.9999" cy="52.3248" rx="51.35" ry="51.025" fill="#131418" />
    <circle
      cx="52.0002"
      cy="52.0002"
      r="43.55"
      fill="url(#paint1_linear_1510_14293)"
    />
    <ellipse
      cx="52.0002"
      cy="51.5668"
      rx="41.1667"
      ry="40.7333"
      fill="#0D1012"
    />
    <g filter="url(#filter0_d_1510_14293)">
      <path
        d="M48.967 37.7002H43.767V41.1669H40.3003V46.3669H64.567V41.1669H61.1003V37.7002H55.9003V41.1669H48.967V37.7002Z"
        fill="#D4FF27"
      />
      <path
        d="M64.567 49.8335H40.3003V63.7002H64.567V49.8335Z"
        fill="#D4FF27"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1510_14293"
        x="17.7669"
        y="16.9002"
        width="69.3334"
        height="69.3334"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="10.4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.835294 0 0 0 0 1 0 0 0 0 0.156863 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1510_14293"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1510_14293"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1510_14293"
        x1="72.8"
        y1="2.6"
        x2="31.46"
        y2="98.8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D2F36" />
        <stop offset="0.1875" stopColor="#1C1E27" />
        <stop offset="0.411458" stopColor="#1C1E27" />
        <stop offset="1" stopColor="#191B22" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1510_14293"
        x1="52.0002"
        y1="8.4502"
        x2="52.0002"
        y2="95.5502"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#101115" />
        <stop offset="1" stopColor="#272C3A" />
      </linearGradient>
    </defs>
  </svg>
);
