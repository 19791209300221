import { DocumentSnapshot, SnapshotOptions } from "firebase/firestore";

export interface Notification {
  id: string,
  recipient: string,
  type: string,
  body: unknown,
  sender: string | null,
  dismissed: boolean,
  timeReceived: Date,
  timeout: Date | null,
}

export const notificationConverter = {
  toFirestore: (notification: Notification) => {
    return {
      id: notification.id ?? '',
      recipient: notification.recipient ?? '',
      type: notification.type ?? '', // dictates how the body is treated
      body: notification.body ?? {},
      sender: notification.sender ?? '',
      dismissed: notification.dismissed,
      timeReceived: notification.timeReceived ?? new Date(),
      timeout: notification.timeout ?? null,
    };
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options)!;
    const notification: Notification = {
      id: snapshot.id,
      recipient: data.recipient,
      type: data.type,
      body: data.body,
      sender: data.sender,
      dismissed: data.dismissed,
      timeReceived: new Date(data.timeReceived.seconds * 1000),
      timeout: data.timeout ? new Date(data.timeout.seconds * 1000) : null,
    };
    return notification;
  },
};
