import brandSliderImg1 from "../../../assets/images/svg/about/ea_icon.svg";
import brandSliderImg2 from "../../../assets/images/svg/about/ubisoft_icon.svg";
import brandSliderImg3 from "../../../assets/images/svg/about/riotGames_icon.svg";
import brandSliderImg4 from "../../../assets/images/svg/about/discord_icon.svg";
import brandSliderImg5 from "../../../assets/images/svg/about/twitch_icon.svg";
import brandSliderImg6 from "../../../assets/images/svg/about/virtex_icon.svg";
import brandSliderImg7 from "../../../assets/images/svg/about/esi_icon.svg";
import brandSliderImg8 from "../../../assets/images/svg/about/e_icon.svg";
import brandSliderImg9 from "../../../assets/images/svg/about/readyPlayer_icon.svg";
import brandSliderImg10 from "../../../assets/images/svg/about/ginx_icon.svg";
import brandSliderImg11 from "../../../assets/images/svg/about/eye_icon.svg";
import brandSliderImg12 from "../../../assets/images/svg/about/sizzle_icon.svg";
import brandSliderImg13 from "../../../assets/images/svg/about/metafy_icon.svg";
import brandSliderImg14 from "../../../assets/images/svg/about/exitlag_icon.svg";
import brandSliderImg15 from "../../../assets/images/svg/about/a_icon.svg";
import brandSliderImg16 from "../../../assets/images/svg/about/s_icon.svg";
import brandSliderImg17 from "../../../assets/images/svg/about/u_icon.svg";

export const BrandSliderList = [
  {
    image: brandSliderImg1,
  },
  {
    image: brandSliderImg2,
  },
  {
    image: brandSliderImg3,
  },
  {
    image: brandSliderImg4,
  },
  {
    image: brandSliderImg5,
  },
  {
    image: brandSliderImg6,
  },
  {
    image: brandSliderImg7,
  },
  {
    image: brandSliderImg8,
  },
  {
    image: brandSliderImg9,
  },
  {
    image: brandSliderImg10,
  },
  {
    image: brandSliderImg11,
  },
  {
    image: brandSliderImg12,
  },
  {
    image: brandSliderImg13,
  },
  {
    image: brandSliderImg14,
  },
  {
    image: brandSliderImg15,
  },
  {
    image: brandSliderImg16,
  },
  {
    image: brandSliderImg17,
  },
];
export const BrandLogoListForMobile = [
  {
    image: brandSliderImg1,
  },
  {
    image: brandSliderImg2,
  },
  {
    image: brandSliderImg3,
  },
  {
    image: brandSliderImg4,
  },
  {
    image: brandSliderImg5,
  },
  {
    image: brandSliderImg6,
  },
];
export const BrandLogoSecondListForMobile = [
  {
    image: brandSliderImg7,
  },
  {
    image: brandSliderImg8,
  },
  {
    image: brandSliderImg9,
  },
  {
    image: brandSliderImg10,
  },
  {
    image: brandSliderImg11,
  },
  {
    image: brandSliderImg12,
  },
];
export const BrandLogoThirdListForMobile = [
  
  {
    image: brandSliderImg13,
  },
  {
    image: brandSliderImg14,
  },
  {
    image: brandSliderImg15,
  },
  {
    image: brandSliderImg16,
  },
  {
    image: brandSliderImg17,
  },
];