import { useState } from "react";
import ConnectAccountPopup from "./ConnectAccountPopup";
import { GrayLeftArrow } from "../../common/icons/Settings";
import { useSettingsContext } from "../SettingsProvider";

interface PopupValueType {
  mygame_icon_color: string;
}

import {
  ApexLegendsIcon,
  FortniteIcon,
  RocketLeagueIcon,
  ValorantIcon
} from "@icons/SearchFilter";

const MyGamesList = [
  {
    icon: <ValorantIcon />,
    title: "Valorant",
    turnaments: "12",
    mygame_icon_color: "mygame_icon1",
  },
  {
    icon: <ApexLegendsIcon />,
    title: "Apex Legends",
    turnaments: "12",
    mygame_icon_color: "mygame_icon2",
  },
  {
    icon: <FortniteIcon />,
    title: "Fortnite",
    turnaments: "12",
    mygame_icon_color: "mygame_icon3",
  },
  {
    icon: <RocketLeagueIcon />,
    title: "Rocket League",
    turnaments: "12",
    mygame_icon_color: "mygame_icon4",
  },
];

const GamesSettings = () => {
  // INITIAL STATES FOR CONNECTED AND OPEN POPUPS
  const initialConnected: boolean[] = Array(MyGamesList.length).fill(false);
  const [connected, setConnected] = useState(initialConnected);
  const [openPopups, setOpenPopups] = useState<{ [key: number]: boolean }>(
    initialConnected
  );
  const isAnyPopupOpen = Object.values(openPopups).some(
    (value) => value === true
  );
  const [popupValue, setPopupValue] = useState<PopupValueType | undefined>();

  const { setSidebarOpen } = useSettingsContext();

  // MANAGE BODY OVERFLOW BASED ON POPUP STATUS
  if (isAnyPopupOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  // HANDLES CONNECT CLICK FOR CONNECT/DISCONNECT AND OPEN POPUPS
  const handleConnectClick = (data: object, index: number) => {
    const updatedConnected = [...connected];
    updatedConnected[index] = !updatedConnected[index];
    setConnected(updatedConnected);

    setOpenPopups((prevPopups) => ({
      ...prevPopups,
      [index]: !prevPopups[index], // Toggle the popup for this index
    }));
    setPopupValue(data as PopupValueType);
  };

  return (
    <div className="min-h-[65vh]">
      <div className="font-wide font-bold max-lg:mt-6 text-base !leading-5 uppercase text-white flex gap-2 items-center lg:hidden">
        <button type='button'
                onClick={() => setSidebarOpen(true)}
                className="block cursor-pointer my-3">
          <GrayLeftArrow />
        </button>
        <h2>My Games</h2>
      </div>
      <div className="flex flex-wrap lg:mt-2 -mx-2">
        {/* RENDER MY GAMES LIST */}
        {MyGamesList.map((data, index) => (
          <div
            className="2xl:w-1/4 xl:w-1/3 sm:w-1/2 w-full px-2 mt-4"
            key={index}
          >
            <div
              className={`rounded-xl bg-lightBlack hover:bg-lightGray duration-300 max-[26.25rem]:p-4 p-6 lg:p-4 mygame_card_hover group ${
                connected[index] ? "hover:bg-lightBlack" : "hover:!bg-lightGray"
              } `}
            >
              <div className="flex gap-3 items-center">
                <span
                  className={` mygame_icon_hover duration-300 ${
                    connected[index]
                      ? `${data.mygame_icon_color}`
                      : "text-white"
                  }${connected[index] ? "" : "mygame_icon"}`}
                >
                  {data.icon}
                </span>
                <div className="flex flex-col gap-1 mt-1">
                  <h2 className="font-compact font-bold uppercase text-base !leading-4 text-white">
                    {data.title}
                  </h2>
                  <p className="font-compact font-normal text-sm !leading-4 tracking-[0.009rem] text-white/40">
                    {data.turnaments} tournaments
                  </p>
                </div>
              </div>
              {/* CONNECT/DISCONNECT BUTTON */}
              <span
                className={`font-compact font-medium text-base cursor-pointer !leading-4 tracking-[0.01rem] text-black hover:bg-gorse hover:text-black duration-300 ${
                  connected[index]
                    ? "bg-lightGray text-white hover:!bg-ebonyClay hover:text-white"
                    : "bg-green hover:bg-ebonyClay"
                }   px-6 pt-[1.125rem] pb-[0.875rem] rounded-xl inline-block w-full text-center uppercase max-[26.25rem]:mt-4 mt-6 lg:mt-4`}
                onClick={() => handleConnectClick(data,index)}
              >
                {connected[index] ? (
                  <span className="font-wide ">disConnect</span>
                ) : (
                  "Connect"
                )}
              </span>

              {/* CONNECT ACCOUNT POPUP */}
              <div
                className={`fixed left-0 duration-300 lg:hidden flex justify-center items-center w-full z-[100] ${
                  openPopups[index]
                    ? "bottom-1/2  translate-y-1/2"
                    : "max-lg:-bottom-full"
                }`}
              >
                <ConnectAccountPopup
                  setOpenPopups={setOpenPopups}
                  icon={""}
                  title={""}
                  turnaments={data.turnaments} popupValue={undefined}                               />
              </div>
              {/* CONNECT ACCOUNT POPUP FOR LG SCREENS */}
              {openPopups[index] ? (
                <div className="fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] duration-300 hidden lg:block">
                  <ConnectAccountPopup
                    setOpenPopups={setOpenPopups}
                    icon={data.icon}
                    turnaments={data.turnaments}
                    title={data.title}

                    popupValue={popupValue}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GamesSettings;
