import { ReactNode, useState, useEffect, useCallback  } from "react";
import { Link, useLocation, useNavigate} from "react-router-dom";
// context
import { useTournamentContext } from '@components/tournament/TournamentProvider';
import { useNotificationContext } from "@provider/NotificationProvider";
import { useHeaderContext } from '@provider/HeaderProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
// packages
import { toast } from "react-toastify";
// components
import Modal from "@ui/Modal";
import MiniTeamCard from "./MiniTeamCard";
import PopoverText from "@ui/PopoverText";
import CheckInModalFlow from "./CheckInModalFlow/CheckInModalFlow";
// icons
// -- react-icons
import { FaCheck, FaLock } from "react-icons/fa";
import { FaClock } from "react-icons/fa";
import { FaPen, FaXmark } from "react-icons/fa6";
import { ImSpinner8 } from "react-icons/im";
// -- custom icons
import { LeaveIcon, MouseClickIcon, PlayersIcon, TeamIcon, VersusIcon, Flag } from "@icons/Common";
import { DiscordSocialIcon } from "@icons/TournamentIcons";

export enum CheckInModalStep {
  playerSelect,
  paymentSettings,
  confirmation,
  poiPreferences
}

const CheckInViews = () => {
  const navigate = useNavigate();
  const location = useLocation();


  const { userTeam } = useAuthContext();
  const { setNavbarState } = useHeaderContext();
  const { notifications, confirmTournamentPresence, declineTournamentPresence } = useNotificationContext();

  const {
    tournament,
    tournamentRegionName,
    joinWaitingList,
    timeUntilWaitingListCheckin,
    leaveWaitingList,
    userTournamentState,
    registeredTeamsLength
  } = useTournamentContext();

  const [joinCooldown, setJoinCooldown] = useState<number>(0);

  const [waitingModalOpen, setWaitingModalOpen] = useState<boolean>(false);

  const [checkInModalStep, setCheckInModalStep] = useState<CheckInModalStep | -1>(-1);
  const [checkInSelectedPlayers, setCheckInSelectedPlayers] = useState<string[]>([]);

  const confirmationNotifications = notifications.filter((notification) => notification.type === 'tournamentConfirmation');
  const confirmNotification = confirmationNotifications.find((notification) => tournament !== null && (notification.body as {tournamentId: string}).tournamentId === tournament.id);

  const waitingConfirmationNotifications = notifications.filter((notification) => notification.type === 'tournamentWaitingConfirmation');
  const waitingConfirmNotification = waitingConfirmationNotifications.find((notification) => tournament !== null && (notification.body as {tournamentId: string}).tournamentId === tournament.id);

  const [confirmingPresence, setConfirmingPresence] = useState<boolean>(false);

  const handleConfirmPresence = useCallback(async () => {
    setConfirmingPresence(true)
    if (confirmNotification) {
      await confirmTournamentPresence(confirmNotification, { participatingPlayers: checkInSelectedPlayers });
    } else if (waitingConfirmNotification) {
      await confirmTournamentPresence(waitingConfirmNotification, { participatingPlayers: checkInSelectedPlayers });
    }
    setConfirmingPresence(false);
  }, [confirmNotification, waitingConfirmNotification, confirmTournamentPresence, checkInSelectedPlayers]);

  const openSignUpModal = useCallback(() => {
    setNavbarState((prevState) => ({
      ...prevState,
      signUpMenuVisible: true})
    )
  }, [setNavbarState]);

  const [checkInFlowInitallyOpened, setCheckInFlowInitallyOpened] = useState<boolean>(false);

  useEffect(() => {
    const checkInRegex = /\/checkIn$/;
    if ((confirmNotification || waitingConfirmNotification) && checkInRegex.test(location.pathname) && !checkInFlowInitallyOpened) {
      setCheckInModalStep(CheckInModalStep.playerSelect);
      setCheckInFlowInitallyOpened(true);
    }
  }, [confirmNotification, waitingConfirmNotification, location.pathname, checkInFlowInitallyOpened]);

  const openCreateTeam = useCallback(() => {
    navigate('/create-team');
  }, [navigate]);

  const openTeamPage = useCallback(() => {
    navigate('/my-team');
  }, [navigate]);
  // button state
  const [buttonStyle, setButtonStyle] = useState<string>('');
  const [buttonInfo, setButtonInfo] = useState<ReactNode>('');
  const [buttonInner, setButtonInner] = useState<ReactNode | string>('');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [secondaryButton, setSecondaryButton] = useState<ReactNode>('');
  const [buttonOnClick, setButtonOnClick] = useState<() => void>(() => () => false);
  const [buttonPopoverText, setButtonPopoverText] = useState<string>('');

  const setButtonState = useCallback(() => {
    if (!tournament) return;

    setButtonStyle('');
    setButtonInfo('');
    setButtonInner('');
    setButtonPopoverText('');
    setButtonDisabled(false);
    setSecondaryButton('');
    setButtonOnClick(() => () => false);

    if (userTournamentState.registered) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      if (userTournamentState.teamCaptain) {
        if (confirmNotification) {
          setButtonInner(
            <>
              <span className="-mb-1">
                Check-In
              </span>
              <span>
                {confirmingPresence ? (
                  <ImSpinner8 className="w-[16px] h-auto aspect-square fill-black animate-spin" />
                ) : (
                  <MouseClickIcon className="w-[16px] h-auto aspect-square fill-black" />
                )}
              </span>
            </>
          );
          setButtonDisabled(confirmingPresence);
          setButtonOnClick(() => () => {
            setCheckInModalStep(CheckInModalStep.playerSelect);
          });
          setSecondaryButton(
            <button className="bg-red/60 text-black w-1/8 h-[40px] w-auto aspect-square py-[0.8125rem] rounded-xl flex items-center justify-center
                                hover:bg-red/80 transition-colors"
                    onClick={() => declineTournamentPresence(confirmNotification)}>
              <LeaveIcon className="w-[14px] h-auto aspect-square fill-black"/>
            </button>
          );
        } else {
          setButtonInner(
            <>
              <span className="-mb-1">
                Check-In Incoming
              </span>
              <span>
                <FaClock/>
              </span>
            </>
          );
          setButtonDisabled(true);
        }
      } else {
        setButtonPopoverText('Team Captain must check in team');
        setButtonInfo(
          <>
            <MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>
          </>
        )
        setButtonInner(
          <>
            <span className="-mb-1">
              Check-In Started
            </span>
          </>
        );
        setButtonDisabled(true);
      }
    } else if (userTournamentState.waiting) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      if (userTournamentState.waitingListCheckInActive) {
        if (userTournamentState.tournamentFull) {
          setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
          setButtonInner(
            <>
              <span className="-mb-1">
                All slots taken
              </span>
              <span>
                <FaClock className="text-black" />
              </span>
            </>
          );
          setButtonDisabled(true);
        } else {
            if (waitingConfirmNotification) {
              const spots = tournament.teamCapacity - registeredTeamsLength;
              setButtonInfo(
              <>
                <MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>
                <p className="text-white font-compact -mb-6">
                  <em className={`not-italic font-semibold text-lg
                                  ${spots > 1 ? 'text-green' : 'text-red/70'}`}>
                    {spots}{' '}
                  </em>
                  spot{spots === 1 ? '' : 's'} available
                </p>
              </>
            )
              setButtonInner(
                <>
                  <span className="-mb-1">
                    Claim-Spot
                  </span>
                  <span>
                    {confirmingPresence ? (
                      <ImSpinner8 className="w-[16px] h-auto aspect-square fill-black animate-spin" />
                    ) : (
                      <MouseClickIcon className="w-[16px] h-auto aspect-square fill-black" />
                    )}
                  </span>
                </>
              );
              setSecondaryButton(
                <button className="bg-red/60 text-black w-1/8 h-[40px] w-auto aspect-square py-[0.8125rem] rounded-xl flex items-center justify-center
                                    hover:bg-red/80 transition-colors"
                        onClick={() => declineTournamentPresence(waitingConfirmNotification)}>
                  <LeaveIcon className="w-[14px] h-auto aspect-square fill-black"/>
                </button>
              );
              setButtonDisabled(confirmingPresence);
              setButtonOnClick(() => () => {
                setCheckInModalStep(CheckInModalStep.playerSelect);
              });
            } else {
              setButtonInner(
                <>
                  <span className="-mb-1">
                    Waiting Check-In Incoming
                  </span>
                  <span>
                    <FaClock/>
                  </span>
                </>
              );
              setButtonDisabled(true);
            }
        }
      } else {
        setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
        setButtonInner(
          <>
            <span className="-mb-1">
              Registered Check-in in progress
            </span>
            <span>
              <FaClock className="text-black" />
            </span>
          </>
        );
        setSecondaryButton(
          <button className="bg-red/60 text-black w-1/8 h-[40px] w-auto aspect-square py-[0.8125rem] rounded-xl flex items-center justify-center
                            hover:bg-red/80 transition-colors"
            onClick={leaveWaitingList}>
            <LeaveIcon className="w-[14px] h-auto aspect-square fill-black" />
          </button>
        );
        setButtonDisabled(true);
      }
    } else if (userTournamentState.confirmed) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      setButtonInner(
        <>
         <span className="-mb-1">
            Checked-In
          </span>
          <span>
            <FaCheck className="text-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    } else if (userTournamentState.ignored) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      setButtonPopoverText('Repeat offenses affect future eligibility')
      setButtonStyle('!bg-red/70 hover:!bg-red/70');
      setButtonInner(
        <>
          <span className="-mb-1">
            Check-In Ignored
          </span>
          <span>
            <Flag className="w-[16px] h-auto aspect-square fill-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    } else if (userTournamentState.declined) {
      setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>)
      setButtonStyle('!bg-steelGray hover:!bg-steelGray');
      setButtonInner(
        <>
          <span className="-mb-1">
            Check-In Declined
          </span>
          <span>
            <FaXmark className="w-[16px] h-auto aspect-square fill-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    } else if (userTournamentState.waitingListOpen) {
      if (!userTournamentState.signedIn) {
        setButtonPopoverText('You need an account to register!');
        setButtonDisabled(false);
        setButtonOnClick(() => () => {
          openSignUpModal();
        });
        setButtonInner(
          <>
            <span className="-mb-1">
              Create Account
            </span>
            <span>
              <VersusIcon className="w-[16px] h-auto aspect-square fill-black"/>
            </span>
          </>
        );
      } else if (!userTournamentState.hasTeam) {
        setButtonPopoverText('You need a team to register!');
        setButtonOnClick(() => () => {
          openCreateTeam();
        });
        setButtonInner(
          <>
            <span className="-mb-1">
              Create Team
            </span>
            <span>
              <TeamIcon className="w-[16px] h-auto aspect-square fill-black"/>
            </span>
          </>
        );
        setButtonDisabled(false);
      } else if (userTournamentState.wrongRegion) {
        setButtonPopoverText(`Your team must be from ${tournamentRegionName} to compete in this event!`);
        setButtonInner(
          <>
            <span className="-mb-1">
              Region Locked
            </span>
            <span>
              <FaLock/>
            </span>
          </>
        );
        setButtonStyle("bg-red");
        setButtonDisabled(true);
      } else if (!userTournamentState.teamCaptain) {
        setButtonPopoverText('Permissions required to register! Ask your Team Owner');
        setButtonInner(
          <>
           <span className="-mb-1">
            Join waiting list
            </span>
            <span>
              <FaLock/>
            </span>
          </>
        );
        setButtonDisabled(true);
      } else if (userTournamentState.teamTooSmall) {
        setButtonPopoverText(`Your team needs a minimum of ${tournament.teamSize} players!`);
        setButtonOnClick(() => () => {
          openTeamPage();
        });
        setButtonInner(
          <>
           <span className="-mb-1">
              Invite Players
            </span>
            <span>
              <PlayersIcon className="w-[16px] h-auto aspect-square fill-black"/>
            </span>
          </>
        );
        setButtonDisabled(true);
      } else if (userTournamentState.teamTooLarge) {
        setButtonPopoverText(`Your team cannot exceed ${tournament.maxTeamSize} players!`);
        setButtonOnClick(() => () => {
          openTeamPage();
        });
        setButtonInner(
          <>
           <span className="-mb-1">
              Edit Team
            </span>
            <span>
              <FaPen className="text-sm text-black"/>
            </span>
          </>
        );
        setButtonDisabled(true);
      } else if (!userTournamentState.waitingListCheckInActive) {
        setButtonPopoverText('No spaces left! You can still join the waiting list');
        setButtonOnClick(() => () => setWaitingModalOpen(true));
        setButtonInner(
          <>
           <span className="-mb-1">
              Join waiting list
            </span>
            <span>
              <FaClock className="text-black"/>
            </span>
          </>
        );
        setButtonDisabled(false);
      } else {
        if (userTournamentState.confirmed) setButtonInfo(<MiniTeamCard team={userTeam} status={userTournamentState.teamStatus}/>);

        setButtonInner(
          <>
           <span className="-mb-1">
              Tournament Starting soon
            </span>
            <span>
              <FaClock className=" text-black"/>
            </span>
          </>
        );
        setButtonDisabled(true);
      }
    } else {
      setButtonInner(
        <>
         <span className="-mb-1">
            Tournament Starting soon
          </span>
          <span>
            <FaClock className=" text-black"/>
          </span>
        </>
      );
      setButtonDisabled(true);
    }
  }, [confirmingPresence, confirmNotification, declineTournamentPresence, waitingConfirmNotification, registeredTeamsLength, leaveWaitingList, openCreateTeam, openSignUpModal, openTeamPage, tournament, tournamentRegionName, userTeam, userTournamentState]);

  useEffect(() => {
    setButtonState();
  }, [tournament, userTournamentState, setButtonState]);

  return tournament ? (
    <>
      <div className='w-1/2 h-full flex-grow flex flex-col items-center gap-y-8 justify-end'>
        {buttonInfo}
        <div className="w-full flex items-center gap-x-2">
          <Link to={tournament && tournament.discordSupport ? tournament.discordSupport : "https://discord.gg/versus-gg"}
            target="_blank"
            className="xl:hidden p-2 bg-lightGray rounded-xl h-[40px] w-auto aspect-square flex items-center justify-center">
            <DiscordSocialIcon />
          </Link>
          <div className="relative group w-5/6 flex-grow">
            {buttonPopoverText ? (
              <PopoverText right={true} visible={true} className="opacity-0 group-hover:opacity-100">
                {buttonPopoverText}
              </PopoverText>
            ) : ''}
            <button className={`font-compact font-semibold text-base !leading-4 text-black uppercase w-full h-[40px]
                            flex gap-2 items-center justify-center py-[0.8125rem] bg-green hover:bg-gorse disabled:opacity-50 disabled:hover:bg-green opacity rounded-xl transition-opacity
                            ${buttonStyle}`}
              disabled={buttonDisabled}
              onClick={buttonOnClick}>
              {buttonInner}
            </button>
          </div>
          {secondaryButton}
        </div>
      </div>
      <Modal title="Waiting List Info"
             open={userTournamentState.waitingListOpen && waitingModalOpen} setOpen={setWaitingModalOpen}
             buttonText={(
              <div className="flex items-center gap-x-2">
                <span>
                  Join Waiting List
                </span>
                <FaClock/>
              </div>
             )}
             buttonOnClick={() => {
              if (joinCooldown < new Date().getTime()) {
                joinWaitingList();
                setJoinCooldown(new Date().getTime() + 30_000);
              } else {
                toast.error('You Cannot do that again so quickly');
              }
              setWaitingModalOpen(false);
            }}>
        <div className="flex flex-col gap-y-4 text-white/90 font-compact font-thin text-sm">
          <div className="max-w-[90%] mx-auto flex flex-col gap-y-4">
            <p>
            Waiting list teams will receive spots that become available before registration closes.
            </p>
            <p>
              If still waiting when registration closes, grab spots made available by teams that’s don’t check-in!
            </p>
            <p>
              1 hour before the event starts, notifications are sent to waiting list teams giving you
              <em className="not-italic text-green uppercase"> 30 MINS </em>
              to claim available spots on a first come, first served basis.
            </p>
          </div>
          <div className="flex items-center text-base justify-center gap-x-2 w-full p-2 border-2 border-lightGray rounded-lg
                          uppercase">
            <p>Claim Notification:</p>
            <p className="text-green">
              {timeUntilWaitingListCheckin}
            </p>
          </div>
        </div>
      </Modal>
      <CheckInModalFlow algsMode={tournament.gameMode === 'ALGS Mode'}
                        confirmPresence={handleConfirmPresence}
                        checkInModalStep={checkInModalStep}
                        setCheckInModalStep={setCheckInModalStep}
                        checkInSelectedPlayers={checkInSelectedPlayers}
                        setCheckInSelectedPlayers={setCheckInSelectedPlayers}/>
    </>
  ): '';

}

export default CheckInViews;
