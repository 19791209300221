import { useCallback, useEffect, useState } from "react";
// icons
import { ArrowIcon } from "../common/icons/Common";

interface IPaginationBar {
  className?: string,
  totalPages: number,
  currentPage: number,
  changePage: (pageNum: number) => void,
}

const PaginationBar: React.FC<IPaginationBar> = ({
  className,
  totalPages,
  currentPage,
  changePage
}) => {

  const [cooldown, setCooldown] = useState<boolean>(false);

  const handleSetCooldown = () => {
    setCooldown(true);
    setTimeout(() => setCooldown(false), 500);
  };

  const handleChangePage = (newPageNum: number) => {
    if (!cooldown) {
      handleSetCooldown();
      changePage(newPageNum);
    }
  };

  const [pillNumbers, setPillNumbers] = useState<number[]>([]);

  const recomputePillNumbers = useCallback(() => {
    const totalPills = Math.min(totalPages, 6);

    const pillNumbers: number[] = [];

    const middlePillNumber = Math.ceil((totalPills / 2));

    if (totalPills < totalPages && (currentPage > middlePillNumber && currentPage <= (totalPages - middlePillNumber))) {
      for (let i = 0; i < totalPills; i++) {
        const offset = -middlePillNumber;
        pillNumbers.push(currentPage + i + offset);
      }
    } else if (currentPage <= middlePillNumber) {
      for (let i = 0; i < totalPills; i++) {
        pillNumbers.push(i + 1);
      }
    } else {
      for (let i = totalPages - totalPills; i < totalPages; i++) {
        pillNumbers.push(i + 1);
      }
    }


    setPillNumbers(pillNumbers);
  }, [currentPage, totalPages]);

  useEffect(() => {
    recomputePillNumbers();
  }, [currentPage, totalPages, recomputePillNumbers])

  return (
    <div className={`${className} w-full flex items-center justify-between`}>
      <button type='button'
              disabled={cooldown || currentPage === 1}
              aria-label='previous page of results'
              onClick={() => handleChangePage(currentPage - 1)}
              className={`flex items-center gap-x-2 h-[35px] px-3
                          rounded-lg border border-lightGray text-white font-compact font-thin uppercase
                          hover:opacity-75
                          disabled:opacity-50 disabled:pointer-events-none`}>
        <ArrowIcon className="h-[12px] w-auto aspect-square stroke-steelGray"/>
        <span className='-mb-[3px]'>
          Prev
        </span>
      </button>

      <div className="flex items-center gap-x-2">
        {pillNumbers.map((pillNumber) => {
          return (
            <button key={`pill-${pillNumber}`}
                    type='button'
                    disabled={cooldown || pillNumber === currentPage}
                    onClick={() => handleChangePage(pillNumber)}
                    className={`w-[30px] h-auto aspect-square rounded-full
                              border border-lightGray font-compact font-thin text-sm uppercase
                              flex items-center justify-center
                              hover:opacity-75
                              disabled:opacity-50 disabled:pointer-events-none
                              ${pillNumber === currentPage ? 'text-white bg-ebonyClay' : 'text-steelGray'} transition-all`}>
              <span>
                {pillNumber}
              </span>
            </button>
          );
        })}
      </div>

      <button type='button'
              disabled={cooldown || currentPage === totalPages}
              aria-label='next page of results'
              onClick={() => handleChangePage(currentPage + 1)}
              className={`flex items-center gap-x-2 h-[35px] px-3
                          rounded-lg border border-lightGray text-white font-compact font-thin uppercase
                          hover:opacity-75
                          disabled:opacity-50 disabled:pointer-events-none`}>
        <span className='-mb-[3px]'>
          Next
        </span>
        <ArrowIcon className="h-[12px] w-auto aspect-square stroke-steelGray rotate-180"/>
      </button>

    </div>
  );
};

export default PaginationBar;
