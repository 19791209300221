// utils
import { formatDateTime, getTimezone } from "@utils/Date";
// icons
import { TeamFeesIcon } from "@icons/TournamentIcons";
import { CelenderIcon } from "@icons/TournamentIcons";
import { useTournamentContext } from "@components/tournament/TournamentProvider";

const Banner = () => {
  const { tournament } = useTournamentContext();

  const totalPrizePool = tournament ? tournament.prizePool.prizes.reduce((acc, prize) => acc + prize.prizeAmount, 0) : 0;

  return tournament ? (
    <div className="rounded-xl overflow-hidden h-full flex flex-col">
      <img
        className="h-auto w-full aspect-[3] object-cover hidden md:flex "
        src={tournament.bannerImage}
        alt="tournament hero image"
      />
      <div className="p-6 xl:p-8 hidden md:flex flex-grow items-center">
        <div className="w-full">
          <h2 className="font-wide uppercase font-bold text-base !leading-5 text-white mb-4">
            {tournament.name}
          </h2>
          <div className="hidden md:flex items-center gap-4">
            <p className="font-compact font-normal text-sm custom_sm:text-base !leading-4 text-steelGray flex gap-2 items-center">
              <span className="-mt-0.5"><CelenderIcon/></span>
              <span className="-m-0.5">{formatDateTime(tournament.statusDates.ongoing)} {getTimezone()}</span>
            </p>
          </div>{" "}
        </div>
        <div className="flex justify-end">
            <div className="flex items-center justify-between w-[18.75rem]">
              <div className="hidden md:flex flex-col items-center w-full md:border-e  border-ebonyClay">
                <h2 className="font-compact whitespace-nowrap font-normal text-sm text-white !leading-4 tracking-[0.0088rem] mb-0">
                  Entry
                </h2>
                <div className="flex items-center gap-2 mt-2">
                  {tournament.entryFee ? (
                    <TeamFeesIcon />
                  ) : ''}
                  <h2 className="font-wide font-medium text-2xl sm:text-base text-green uppercase !leading-4 tracking-[0.0088rem] mt-[0.188rem] whitespace-nowrap">
                    {tournament.entryFee ? `${tournament.entryFee} tokens` : 'Free'}
                  </h2>
                </div>
              </div>
              <div className="flex max-md:gap-3 md:flex-col max-md:justify-center items-center  w-full">
                <h2 className="font-compact whitespace-nowrap font-normal text-sm text-white !leading-4 tracking-[0.0088rem] -mb-1 md:mb-0">
                  Prize Pool
                </h2>
                <div className="flex items-center gap-2 md:mt-2">
                  {/* <span className="trophy_icon_tournament_hero">
                    <GreenTrophyIcon />
                  </span> */}
                  <h2 className="font-wide text-green uppercase font-medium text-2xl sm:text-base !leading-4 tracking-[0.0088rem] md:mt-1 mt-1">
                    {totalPrizePool > 0 ? `${tournament.prizePool.currency}${totalPrizePool}` : 'N/A'}
                  </h2>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  ) : '';
};

export default Banner;
