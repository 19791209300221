import { ReactNode } from 'react';
import { Link } from "react-router-dom";
// types
import { TournamentStatus, TournamentTeamStatus } from "@src/firestore/tournaments";
import { TeamObjWithStatus } from "./Entrants";
// libaries
import {toast} from 'react-toastify';
// components
import { Disclosure, DisclosureButton } from "@headlessui/react";
import EntrantsTeamPlayerList from "./EntrantsTeamPlayerList";
// icons
import { Flag, LeaveIcon } from '@icons/Common';
import { FooterAccordionIcon } from "@icons/FooterIcons";
import { DiscordIcon, TwitterIcon, TwitchIcon, WebsiteIcon } from '@icons/Socials';
import { FaClock, FaCheck } from "react-icons/fa";
import { useTournamentContext } from '../TournamentProvider';
import PopoverText from '@ui/PopoverText';

const getEntrantsTeamIconFromStatus = (status: TournamentTeamStatus): ReactNode => {
  switch (status) {
    case TournamentTeamStatus.registered:
      return <FaClock className='text-white text-lg'/>
    case TournamentTeamStatus.waiting:
      return <FaClock className='text-white text-lg'/>
    case TournamentTeamStatus.confirmed:
      return <FaCheck className='text-green'/>
    case TournamentTeamStatus.declined:
      return <LeaveIcon className='w-[12px] h-auto aspect-square fill-steelGray'/>
    case TournamentTeamStatus.ignored:
      return <Flag className='w-[16px] h-auto aspect-square fill-red'/>
    default:
      return '';
  }
}

const teamStatusToString= (status: TournamentTeamStatus): string => {
  switch (status) {
    case TournamentTeamStatus.registered:
      return 'Registered';
    case TournamentTeamStatus.waiting:
      return 'In Waiting List';
    case TournamentTeamStatus.confirmed:
      return 'Checked-In';
    case TournamentTeamStatus.declined:
      return 'Declined Check In';
    case TournamentTeamStatus.ignored:
      return 'Ignored Check In';
    default:
      return '';
  }
}

interface IEntrantsTeamCard {
  team: TeamObjWithStatus
}

const EntrantsTeamCard: React.FC<IEntrantsTeamCard> = ({team}) => {
  const { tournament } = useTournamentContext();
  const teamCheckedIn = team.status === TournamentTeamStatus.confirmed;

  return (
    <div key={`entrants-team-${team.id!}`}
          className={`relative mx-auto w-full bg-lightBlack mb-3 rounded-lg `}>
      <Disclosure>
        {({ open }) => (
          <>
            <div
              className={` rounded-lg duration-300 group/card ${
                open ? "max-md:bg-lightGray max-md:m-2" : ""
              }`}
            >
              <DisclosureButton className="flex flex-col md:flex-row w-full  p-4 md:p-0 justify-between rounded-lg text-left items-center focus-visible:outline-none ">
                <div
                  className={`rounded-lg gap-4 w-full relative md:hidden flex`}>
                  <Link to={`/team/${team.id}`}
                        onClick={(e) => {
                          if (team.dissolved) {
                            e.preventDefault();
                            e.stopPropagation();
                            toast.error('Team has been dissolved');
                          }
                        }}
                        className='hover:opacity-75 transition-opacity'>
                    <div className="w-fit rounded-lg overflow-hidden min-w-[3rem] h-12 border border-lightGray">
                      <img
                        className="h-full w-auto aspect-square object-cover"
                        src={team.logoUrl}
                        alt="entrants-image"
                      />
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <div className="w-full mt-[0.313rem]">
                        <p className="font-compact font-normal text-sm !leading-4 uppercase tracking-[0.0088rem] text-steelGray mb-1 md:mb-0 sm:mt-1">
                          {team.teamTag}
                        </p>
                        <h2 className="uppercase font-wide font-bold text-sm text-white !leading-4 flex items-center gap-4">
                          {team.teamName}{" "}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="border-t border-lightGray md:border-none flex justify-between w-full md:mx-0 mt-3 md:mt-0 pt-4 md:py-0">
                  <div className={`rounded-lg ps-4 py-4 pe-6 gap-4 w-full relative items-center hidden md:flex`}>
                    <div className="flex justify-start gap-x-4 w-full">
                      <Link to={`/team/${team.id}`}
                            onClick={(e) => {
                              if (team.dissolved) {
                                e.preventDefault();
                                e.stopPropagation();
                                toast.error('Team has been dissolved');
                              }
                            }}
                            className='flex items-center gap-x-4 hover:opacity-75 transition-opacity'>
                        <div className="min-w-[3.5rem] h-14 rounded-lg overflow-hidden border border-lightGray">
                          <img
                            className="h-full w-auto aspect-square object-cover"
                            src={team.logoUrl}
                            alt="entrants-image"
                          />
                        </div>
                        <div className="w-fit">
                          <h2 className="font-wide font-medium uppercase text-base text-white !leading-6 flex items-center gap-4 group-hover/card:text-green transition-colors">
                            {team.teamName}
                          </h2>
                          <p className="font-compact text-sm text-steelGray">
                            {team.teamTag}
                          </p>
                        </div>
                      </Link>
                      {/* <div className='h-[20px] w-[1px] bg-lightGray mx-2 mt-[0.35rem]'></div> */}
                      <span className="flex items-center gap-x-2 h-fit mt-[0.35rem]">
                        {team.discordLink ? (
                          <Link target="_blank" to={team.discordLink}>
                            <DiscordIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                          </Link>
                        ) : ''}
                        {team.twitterLink ? (
                          <Link target="_blank" to={team.twitterLink}>
                            <TwitterIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                          </Link>
                        ) : ''}
                        {team.twitchLink ? (
                          <Link target="_blank" to={team.twitchLink}>
                            <TwitchIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                          </Link>
                        ) : ''}
                        {team.websiteLink ? (
                          <Link target="_blank" to={team.websiteLink}>
                            <WebsiteIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                          </Link>
                        ) : ''}
                      </span>
                    </div>
                  </div>
                  <span className=" flex md:hidden gap-4 sm:gap-2 items-center -mt-1 ">
                    {team.discordLink ? (
                      <Link target="_blank" to={team.discordLink}
                            className='rounded border-[1px] border-steelGray/20 group'>
                        <DiscordIcon className='w-[30px] h-auto aspect-square p-[0.60rem] transition-[300] fill-white group-hover:fill-green' />
                      </Link>
                    ) : ''}
                    {team.twitterLink ? (
                      <Link target="_blank" to={team.twitterLink}
                            className='rounded border-[1px] border-steelGray/20 group'>
                        <TwitterIcon className='w-[30px] h-auto aspect-square p-[0.60rem] transition-[300] fill-white group-hover:fill-green' />
                      </Link>
                    ) : ''}
                    {team.twitchLink ? (
                      <Link target="_blank" to={team.twitchLink}
                            className='rounded border-[1px] border-steelGray/20 group'>
                        <TwitchIcon className='w-[30px] h-auto aspect-square p-[0.60rem] transition-[300] fill-white group-hover:fill-green' />
                      </Link>
                    ) : ''}
                    {team.websiteLink ? (
                      <Link target="_blank" to={team.websiteLink}
                            className='rounded border-[1px] border-steelGray/20 group'>
                        <WebsiteIcon className='w-[30px] h-auto aspect-square p-[0.60rem] transition-[300] fill-white group-hover:fill-green' />
                      </Link>
                    ) : ''}
                  </span>

                  <div className='flex items-center gap-x-3'>
                    <div className='hidden sm:flex items-center w-fit justify-end mr-5'>
                      {team.playerData.map((player, index) => {
                        const playerSelected = team.participatingPlayers.includes(player.id);

                        return (
                          <div key={`${player.id}-image-stack`}
                               className={`relative w-[32px] h-auto aspect-square rounded-full bg-lightBlack border-2 border-lightBlack overflow-hidden -ml-[9px]`}
                               style={{zIndex: index + 1}}>
                              <img src={player.displayImage} alt={`${player.displayName} image`}
                                  className={`w-full h-full object-cover
                                              ${teamCheckedIn ? playerSelected ? '' : 'opacity-20 saturate-0' : ''}`}/>
                          </div>
                        );
                      })}
                      {tournament && tournament.status > TournamentStatus.registration ? (
                        <div className={`relative z-[10] w-[32px] h-auto aspect-square rounded-full border-2 border-lightBlack -ml-[9px]
                                        bg-lightGray flex items-center justify-center flex-shrink-0 group`}>
                          <PopoverText visible={true} className='opacity-0 group-hover:opacity-100'>
                            {teamStatusToString(team.status)}
                          </PopoverText>
                          <span>
                            {getEntrantsTeamIconFromStatus(team.status)}
                          </span>
                        </div>
                      ) : ''}
                    </div>
                    <span className={`md:p-9 md:border-l border-lightGray entrants_dropdown_arrow`}>
                      <FooterAccordionIcon
                        accordianArrow={
                          open
                            ? "-rotate-180 duration-300"
                            : "rotate-0 duration-300"
                        }
                      />
                    </span>
                  </div>
                </div>
              </DisclosureButton>{" "}
            </div>
            <EntrantsTeamPlayerList team={team}/>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default EntrantsTeamCard;
