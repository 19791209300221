import aboutHeroGlowArrow from "../../assets/images/svg/about/about_hero_glow_arrow.svg";
import aboutLineBoxIcon from "../../assets/images/svg/about/about_line_box_icon.svg";
import {
  AboutHeroGraphLine,
  AboutHeroGraphSmLine,
  HeroTrianglediamondIcon,
} from "../common/icons/AboutIcon";

const Hero = () => {
  return (
    <>
      <div className="relative pb-[10rem] -mb-40 sm:-mb-14">
        {/* <img
          className="absolute -top-10 sm:top-0 left-1/2 -translate-x-1/2 max-h-[34.813rem] max-w-[34.813rem] w-full h-full z-10"
          src={heroDiamondImage}
          alt="diamond-image"
        /> */}
        <span className="absolute -top-24 sm:-top-16 left-1/2 -translate-x-1/2 z-10">
          <HeroTrianglediamondIcon />
        </span>{" "}
        <div className="relative z-[11] flex items-center justify-center flex-col">
          <img src={aboutHeroGlowArrow} alt="glow-arrow" />
          <span className="absolute services_hero_graph_sm_line w-full top-[26%] sm:top-[20%] xl:top-[23%] block sm:hidden">
            <AboutHeroGraphSmLine />
          </span>
          <span className="absolute w-full top-[13%] sm:top-[20%] xl:top-[23%] hidden sm:block">
            <AboutHeroGraphLine />
          </span>
          <h2 className="text-white font-wide text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl  uppercase font-bold pt-[2.375rem] md:pt-12">
            Contact us
          </h2>
          <p className="text-white text-center font-normal text-sm md:text-base pt-3 sm:pt-6 font-compact">
            Feel free to get in touch with us. We're here to help you!
          </p>
          <img src={aboutLineBoxIcon} alt="box-icon" />
        </div>
      </div>
    </>
  );
};

export default Hero;
