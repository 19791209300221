import { Link } from "react-scroll";

interface TopicListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  conditiontitleList: { title: string }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}

const TopicList: React.FC<TopicListProps> = ({ conditiontitleList, title }) => {
  return (
    <div className=" top-10 pb-5 hidden md:block">
      <div className="bg-lightBlack p-6 rounded-lg relative z-10">
        <h2 className="text-white font-wide font-bold text-base uppercase leading-4 border-l-[3px] border-green ps-2.5 lg:ps-4">
          {title}
        </h2>
        {/* HEADING BOTTOM BORDER */}
        <span className="inline-block w-full h-0 border-t border-lightGray my-3 lg:my-6"></span>
        <div>
          <div className="flex flex-col gap-4 lg:gap-5 h-full">
            {/* MAPPING THROUGH THE TOPIC DATA */}
            <Link
              className={`text-Trout text-steelGray`}
              spy
              smooth={true}
              duration={500}
              offset={-150}
              activeClass="!text-green"
              to="1"
            >
              <div className="flex gap-0.5 group cursor-pointer">
                <span className="font-compact font-normal text-sm lg:text-base  group-hover:text-green transition duration-300 !leading-4">
                  1.
                </span>
                <h3 className="font-compact font-normal capitalize text-sm lg:text-base group-hover:text-green transition duration-300 !leading-4 tracking-[0.16px] whitespace-nowrap ">
                  {title === "Privacy Policy"
                    ? "General Information"
                    : "Introduction"}
                </h3>
              </div>
            </Link>
            {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              conditiontitleList.map((data, index) => {
                const scrollValue = index + 2;
                return (
                  <Link
                    key={index}
                    className={`${
                      data.title === "Introduction"
                        ? "!text-green"
                        : "text-Trout text-steelGray"
                    }`}
                    spy={true}
                    to={scrollValue.toString()}
                    smooth={true}
                    duration={500}
                    offset={-95}
                    activeClass="!text-green"
                  >
                    <div className="flex gap-0.5 group cursor-pointer">
                      <span className="font-compact font-normal text-sm lg:text-base  group-hover:text-green transition duration-300 !leading-4">
                        {index + 2}.
                      </span>
                      <h3 className="font-compact font-normal capitalize text-sm lg:text-base group-hover:text-green transition duration-300 !leading-4 tracking-[0.16px] whitespace-nowrap ">
                        {data.title}
                      </h3>
                    </div>
                  </Link>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopicList;
