import { useState, useEffect } from 'react';
// context
import { useTournamentContext } from "@components/tournament/TournamentProvider";
import { useTournamentAdminContext } from "../../../TournamentAdminProvider";
// utils
import { decodeBase64, encodeBase64 } from '@utils/encodingDecoding';
// components
import PopoverText from '@ui/PopoverText';
import Input from "@ui/Input";
import ButtonPrimary from "@ui/ButtonPrimary";
// icons
import { ImSpinner8 } from 'react-icons/im';
import { FaTicket } from 'react-icons/fa6';
import { FaLock } from 'react-icons/fa';

interface ITournamentEditPage {
  checkInStarted: boolean
}

const EventRules: React.FC<ITournamentEditPage> = ({checkInStarted}) => {
  const { tournament } = useTournamentContext();
  const { tournamentEditInfo, setTournamentEditInfo, saveTournamentEditInfo } = useTournamentAdminContext();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [discordLinkValid, setDiscordLinkValid] = useState<boolean>(true);
  const [changesMade, setChangesMade] = useState<boolean>(false);

  const handleSaveChanges = async () => {
    setSubmitting(true);
    await saveTournamentEditInfo();
    setSubmitting(false);
  }

  useEffect(() => {
    if (tournament && tournamentEditInfo) {
      if (tournament.discordSupport !== tournamentEditInfo.discordSupport
          || tournament.rules !== tournamentEditInfo.rules) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    } else {
      setChangesMade(false);
    }
  }, [tournament, tournamentEditInfo])

  useEffect(() => {
    setDiscordLinkValid(/(https?:\/\/)?(www\.)?discord\.gg\/[a-zA-Z._-]+/.test(tournamentEditInfo?.discordSupport ?? ''))
  }, [tournamentEditInfo]);

  return tournament && tournamentEditInfo ? (
    <div className='text-white font-compact flex flex-col gap-y-3'>
      <div className='flex flex-col gap-y-2 text-start items-start w-full'>
        <p>Rules</p>
        <Input value={decodeBase64(tournamentEditInfo.rules)}
                onChange={(newValue) => setTournamentEditInfo({
                ...tournamentEditInfo,
                rules: encodeBase64(newValue)
                })}
                textArea={true} wrapperClassName='w-full !pr-0' rows={10}/>
      </div>

      <div className="flex flex-col gap-y-2">
        <p>Support Channel</p>
        <div className="group w-full flex items-center gap-x-4 p-3 bg-lightBlack rounded-lg transition-colors">
          <div className="w-[36px] h-auto aspect-square rounded-lg bg-lightGray transition-colors
                          flex items-center justify-center">
            <FaTicket className="w-[18px] h-auto aspect-square fill-white"/>
          </div>
          <Input value={tournamentEditInfo.discordSupport}
                 onChange={(newValue) => setTournamentEditInfo({
                   ...tournamentEditInfo,
                   discordSupport: newValue
                 })}
                 onClick={(e) => e?.stopPropagation()}
                 wrapperClassName='w-1/2 flex-grow'
                 inputClassName='!mt-0 text-white border-transparent'
                 valid={discordLinkValid}
                 placeholder='discord username'
                 border={true}
                 black={false}/>
        </div>
      </div>

      <div className='relative group w-full h-fit mt-4'>
        {checkInStarted ? (
          <PopoverText visible={true} right={true} className='opacity-0 group-hover:opacity-100 transition-opacity'>
            Unable to affect changes after check-in has started
          </PopoverText>
        ) : ''}
        <ButtonPrimary disabled={checkInStarted || submitting || !changesMade}
                      onClick={handleSaveChanges}>
          <span>Save Changes</span>
          {checkInStarted ? (
            <FaLock/>
          )
          : submitting ? (
            <ImSpinner8/>
          ) : ''}
        </ButtonPrimary>
      </div>
    </div>
  ) : '';
}

export default EventRules;
