import { useState, FormEvent, useEffect } from "react";
// context
import { useHeaderContext } from "@provider/HeaderProvider";
// types
import { AuthError } from "firebase/auth";
// utils
import { handleFirebaseAuthError, login } from "@utils/AuthHelpers";
// packages
import { toast } from "react-toastify";
// components
import { InputWrapper } from "@components/common/inputfield-wrapper/InputWrapper";
// icons
import { InputCross } from "@icons/Common";
import { DisplayEyeIcon, HiddenEyeIcon } from "@icons/AuthIcons";

const LoginInput = () => {
  const { setNavbarState, navbarState } = useHeaderContext();

  const [usernameOrEmail, setUsernameOrEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [activeField, setActiveField] = useState<string>('');

  const handlePasswordToggle = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleForgotPassword = async () => {
    setNavbarState({
      ...navbarState,
      logInMenuVisible: false,
      forgotPasswordMenuVisible: true,
    });
  }

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('.loginInput') && !target.closest('.loginInput')) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', checkForOutsideInputClick);

    return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
  }, [])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await login(usernameOrEmail, password);
      toast.success("Welcome back!")
      setNavbarState({
        ...navbarState,
        logInMenuVisible: false,
        profileMenuVisible: false,
      });
      setUsernameOrEmail('');
      setPassword('');
    } catch (error) {
      handleFirebaseAuthError(error as AuthError);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col pt-6">
          <label
            htmlFor="loginEmail"
            className="font-compact text-base font-normal text-white tracking-[0.01rem] leading-normal text-start"
          >
            Username or Email
          </label>
          <InputWrapper>
            <div className="relative w-full">
              <input
                className={`!bg-transparent w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 px-4 rounded-xl loginInput ${
                  activeField === 'usernameOrEmail'
                    ? "border-green border-2 pr-10 placeholder:text-white"
                    : "border-transparent border-2"
                }`}
                onClick={() => setActiveField("usernameOrEmail")}
                required
                id="usernameOrEmail"
                type="usernameOrEmail"
                value={usernameOrEmail}
                onChange={(e) => setUsernameOrEmail(e.target.value)}
                placeholder="Your username or email here"
              />
              {activeField === 'usernameOrEmail' && (
                <span
                  onClick={() => setUsernameOrEmail('')}
                  className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer validation_pop_up_cross_icon"
                >
                  <InputCross />
                </span>
              )}
            </div>
          </InputWrapper>
        </div>

        <div className="flex flex-col mt-4">
          <label
            htmlFor="loginPassword"
            className="font-compact text-base font-normal text-white tracking-[0.01rem] leading-normal text-start"
          >
            Password
          </label>
          <InputWrapper>
            <div className="relative w-full h-fit">
              <input
                className={
                  `!bg-transparent w-full h-full focus:outline-none placeholder:text-darkGray py-4 sm:py-3 px-4 rounded-xl loginInput ${
                    activeField === 'password'
                      ? "border-green border-2 pr-10 placeholder:text-white"
                      : "border-transparent border-2"
                  }`
                }
                onClick={() => setActiveField("password")}
                required
                id="loginPassword"
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your password here"
              />
              <span
                className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer inline-block ps-3"
                onClick={handlePasswordToggle}
              >
                {isPasswordVisible ? <DisplayEyeIcon /> : <HiddenEyeIcon />}
              </span>
            </div>
          </InputWrapper>
        </div>

        <div className="flex justify-end mt-6">
          <button type='button'
                  onClick={handleForgotPassword}
                  className="font-compact text-sm font-normal text-white hover:text-green duration-200 tracking-[0.01rem] cursor-pointer">
            Forgot password
          </button>
        </div>

        <button
          type="submit"
          className="font-compact w-full text-base font-medium py-4 !leading-4 rounded-xl uppercase mt-6 block text-center common_green_btn"
        >
          Login
        </button>

        <p className="font-compact text-sm font-normal text-white tracking-[0.01rem] !leading-4 text-center mt-6">
          Don't have an account?&nbsp;
          <span
            onClick={() => {
              setNavbarState({
                ...navbarState,
                logInMenuVisible: false,
                signUpMenuVisible: true,
              });
            }}
            className="text-green hover:opacity-7 duration-200 cursor-pointer inline-block hover:text-gorse"
          >
            Sign Up.
          </span>
        </p>
      </form>
    </>
  );
};

export default LoginInput;
