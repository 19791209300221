import { ReactNode, useEffect, useRef, useState } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc } from 'firebase/firestore';
// context
import { useImageShowCaseContext } from '@src/provider/ImageShowcaseProvider';
// types
import { DBTeam, teamConverter } from '@src/firestore/teams';
import { ApexMapPoi } from '@customTypes/Games/ApexLegends';
// utils
import { getMapNameFromAcronym, getMapPoiImageFromAcronym } from '@utils/Games/ApexLegends';
// libraries
import { toast } from 'react-toastify';
// components
import PoiList from '@src/components/team/show/subViews/manageTeam/subViews/games/subViews/poiPreferences/PoiList'; // LOL
// icons
import { FaArrowLeft, FaCheck, FaEye } from 'react-icons/fa';

interface ICheckInPoiPreferencesMapList {
  map: string,
  poiLocationsForMap: ApexMapPoi[],
  close: () => void,
  team: DBTeam,
  modalRef: React.MutableRefObject<HTMLDivElement | null>,
  setPoiPreferencesButtonText: (text: ReactNode | null) => void,
  setPoiPreferencesButtonOnClick: (onClick: (() => void) | null) => void,
}

const CheckInPoiPreferencesMapList: React.FC<ICheckInPoiPreferencesMapList> = ({map, poiLocationsForMap, close, team, modalRef, setPoiPreferencesButtonText, setPoiPreferencesButtonOnClick }) => {
  const { setShowCaseImage } = useImageShowCaseContext();
  const [editMode, setEditMode] = useState<boolean>(false);

  const poiLocationsOrderedRef = useRef<number[]>([]);
  const [poiLocationsOrdered, setPoiLocationsOrdered] = useState<number[]>([]);

  useEffect(() => {
    poiLocationsOrderedRef.current = poiLocationsOrdered;
  }, [poiLocationsOrdered]);

  useEffect(() => {
    const poiLocationNumbersForMap = team.gamePreferences.apex.poiPreferences[map];
    if (poiLocationNumbersForMap) {
      setPoiLocationsOrdered(poiLocationNumbersForMap);
      setEditMode(true);
    } else {
      setPoiLocationsOrdered(poiLocationsForMap.map((location) => location.poiNumber));
    }
  }, [team, map, poiLocationsForMap]);

  const handleSavePreferences = async () => {
    try {
      setPoiPreferencesButtonText(null);
      setPoiPreferencesButtonOnClick(null);
      close();
      const teamRef = doc(firestore, 'teams', team.id).withConverter(teamConverter);
      const updatePromise = updateDoc(teamRef, {
        gamePreferences: {
          ...team.gamePreferences,
          apex: {
            ...team.gamePreferences.apex,
            poiPreferences: {
              ...team.gamePreferences.apex.poiPreferences,
              [map]: poiLocationsOrderedRef.current
            }
          }
        }
      });

      toast.promise(updatePromise, {
        pending: `${editMode ? 'Updating' : 'Setting'} ${getMapNameFromAcronym(map)} drop preferences`,
        success: `${getMapNameFromAcronym(map)} drop preferences ${editMode ? 'updated' : 'set'}`,
        error: `Error ${editMode ? 'updating' : 'setting'} ${getMapNameFromAcronym(map)} drop preferences`,
      });
      await updatePromise;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setPoiPreferencesButtonText((
      <>
        <span>Save</span>
        <FaCheck/>
      </>
    ));
    setPoiPreferencesButtonOnClick(() => handleSavePreferences);
  }, []);

  return (
    <div className='flex flex-col gap-y-2'>
      <div className='w-full flex items-center justify-between'>
        <div className='flex items-center gap-x-2'>
          <button type='button' aria-label='back'
                  className="cursor-pointer"
                  onClick={close}>
            <FaArrowLeft className='text-lg -translate-y-[1px]'/>
          </button>
          <h3 className='font-compact text-white uppercase font-semibold'>{getMapNameFromAcronym(map)}</h3>
        </div>
        <button type='button' aria-label='open map visual aid'
                onClick={() => setShowCaseImage(getMapPoiImageFromAcronym(map))}
                className='p-2 px-3 rounded-lg bg-ebonyClay text-white font-compact text-sm uppercase hover:opacity-75 transition-opacity
                          flex items-center gap-x-2'>
          <span>View Map</span>
          <FaEye className='w-[16px] h-auto aspect-square fill-green -translate-y-[1px]'/>
        </button>
      </div>
      <PoiList poiLocationsForMap={poiLocationsForMap}
               mapModalRef={modalRef}
              poiLocationsOrdered={poiLocationsOrdered}
              setPoiLocationsOrdered={setPoiLocationsOrdered}/>
    </div>
  )
}

export default CheckInPoiPreferencesMapList;
