// types
import { Notification } from '@src/firestore/notifications';
// libraries
import { toast } from 'react-toastify';
// icons
import { MdOutlineContentCopy } from "react-icons/md";
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from '../../../common/icons/Games';
import SurfsharkIcon from '@components/brands/surfshark/SurfsharkIcon';

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>)
  }
  return 'no icon'
}


interface ITournamentConfirmation {
  notification: Notification,
  closeMenu: () => void,
}

const TournamentCodeNotif: React.FC<ITournamentConfirmation> = ({notification}) => {
  const body = notification.body as {
    tournamentId: string,
    tournamentName: string,
    brand: string,
    gameName: string,
    groupName: string,
    playerCode: string,
    groupPosition: number,
    title: string,
  };

  const { groupName, gameName, tournamentName, playerCode, groupPosition, brand, title } = body;

  const copyCodeToClipboard = () => {
    toast.info('Game code copied to clipboard!')
    navigator.clipboard.writeText(playerCode);
  }

  return (
    <div className="relative bg-lightGray rounded-xl p-4 pr-10" key={notification.id}>
      <div className="flex items-start gap-x-3">
        <div className='p-[0.55rem] rounded-full bg-steelGray/30'>
          {brand === 'Surfshark' ? (
            <SurfsharkIcon className='w-[18px] h-auto aspect-square fill-white'/>
          ) : (
            <>{gameNameToIcon(title)}</>
          )}
        </div>
        <div>
          <h3 className="text-base leading-6 text-white font-compact uppercase">{tournamentName}</h3>
          <div className='flex items-center gap-x-2 font-compact'>
            <p className='text-white'>{groupName}: <em className='not-italic text-green'>{gameName}</em></p>
            <div className='w-[1px] h-[12px] bg-steelGray'></div>
            <p className='text-white'>Team Slot: <em className='not-italic text-green'>{groupPosition}</em></p>
          </div>
        </div>
      </div>
      <button
        onClick={copyCodeToClipboard}
        className={`relative w-full mt-3 bg-green hover:bg-gorse text-black overflow-hidden focus_visible_hidden text-base leading-4 px-3 py-[0.8rem] rounded-xl font-compact flex items-center gap-2 justify-center
                    transition-all uppercase font-medium`}>
        <span>Copy Player Code</span>
        {brand === 'Surfshark' ? (
          <SurfsharkIcon className='w-[16px] h-auto aspect-square fill-black'/>
        ) : (
          <MdOutlineContentCopy />
        )}
      </button>
    </div>
  );
}

export default TournamentCodeNotif;
