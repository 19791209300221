// types
import { AdminSubViews } from "./AdminDashboard";

interface ISubViewBar {
  currentSubView: AdminSubViews;
  setCurrentSubView: (subView: AdminSubViews) => void,
}

const subViewToString = (subView: AdminSubViews) => {

  switch (subView) {
    case AdminSubViews.tournaments:
      return 'Tournaments';
    case AdminSubViews.codes:
      return 'Codes';
    default:
      return undefined;
  }
}

const SubViewBar: React.FC<ISubViewBar> = ({ currentSubView, setCurrentSubView }) => {
  const subViews: [string, AdminSubViews][] = Object.entries(AdminSubViews).filter((entry) => typeof entry[1] === 'number') as [string, number][];

  return (
    <div className="pt-10 overflow-x-auto scroll_bar_hidden custom_container">
      <div className="flex items-center justify-between border-b border-lightGray min-w-[38.125rem] sm:min-w-[40.625rem] md:min-w-full w-fit">
        <div className="flex items-center">
          {subViews.map(([, value]) => (
            <button key={value} // Use the enum value as key
            className={`font-medium text-base font-compact pb-4 mx-4 sm:mx-0 px-2 sm:px-4 duration-300 leading-[100%] relative whitespace-nowrap
                        ${value === currentSubView ? "after:bg-green text-green after:content-[''] after:absolute after:start-0 after:-bottom-[0.063rem] after:h-[0.188rem] after:w-full" : "border-transparent text-darkGray hover:text-lightSlateGray"}`}
            onClick={() => setCurrentSubView(value)}>
            {subViewToString(value)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubViewBar;
