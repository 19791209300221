// components
import Input from './Input';
import { SkeletonDark } from '../common/skeleton-loader/Skeletons';
// icons
import { MagnifyingGlassIcon } from "@icons/Common";

interface ISearchBar {
  loaded?: boolean,
  className?: string,
  placeholder?: string,
  value: string,
  setValue: (newValue: string) => void,
}

const SearchBar: React.FC<ISearchBar> = ({value, setValue, placeholder, loaded, className}) => {
  return loaded !== false ? (
    <div className={`${className} relative`}>
      <Input type="text"
              icon={<MagnifyingGlassIcon className="w-[16px] h-auto aspect-square stroke-green"/>}
              value={value}
              onChange={(newValue) => setValue(newValue)}
              placeholder={placeholder ? placeholder : ''}/>
    </div>
  ) : (
    <SkeletonDark className={`${className} relative h-fit rounded-xl`}>
      <input type="text" className='opacity-0 pointer-events-none p-2  outline-none font-compact font-thin'/>
    </SkeletonDark>
  );
}

export default SearchBar;
