import { useContext } from "react";
// context
import { TeamsContext } from "./TeamsProvider";

export const useTeamsContext = () => {
  const context = useContext(TeamsContext);
  return context;
};

export const TeamResultsPerPage = 30;
