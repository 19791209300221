import { Link } from 'react-router-dom';
import { GamesSelectList } from "../common/content/HomePageContent";
import comingSoon from "../../assets/images/png/homepage/coming-soon-img.png";
import {
  GameSelectAboveLine,
  GameSelectBottomLine,
} from "../common/icons/home/HomeIcons";

const GameSelect = () => {
  return (
    <>
      {/* Main container for the GameSelect component */}
      <div className="px-4 sm:px-8 lg:px-12 relative pt-16 sm:pt-24 pb-20  xl:pt-48 xl:pb-28 overflow-hidden custom_container">
        <div className="flex flex-col items-center justify-center text-center mt-4 md:mt-0 md:mx-36 mx-3">
          {/* Heading and subheading */}

          <h2 className="text-white uppercase font-wide font-bold text-2xl">
            game select
          </h2>
          <p className="text-darkGray font-compact font-normal text-base pt-2 lg:pt-3.5">
            Tactical shooters, battle royales, fighters and more
          </p>
        </div>
        <div className="flex flex-wrap pt-4 md:pt-8 lg:pt-10 custom_2xl:gap-6 justify-center mx-auto">
          <span className="absolute -top-7 md:-top-4 xl:top-16">
            <GameSelectAboveLine />
          </span>
          <span className="absolute -bottom-8 hidden sm:block">
            <GameSelectBottomLine />
          </span>
          {/* MAPPING THROUGH GAME DATA TO RENDER */}
          {GamesSelectList.map((obj, index) => (
            <GameSelectCards
              key={index}
              icon={obj.icon}
              isActive={obj.isActive}
              thumbnail={obj.thumbnail}
              title={obj.title}
            />
          ))}
        </div>
      </div>
    </>
  );
};
export default GameSelect;

interface GameCardProps {
  title: string;
  thumbnail: string;
  icon: JSX.Element;
  isActive: boolean;
}

const GameSelectCards = ({ title, thumbnail, icon, isActive }: GameCardProps) => {
  return (
    <Link to={'/tournaments'}
      className={`custom_sm:w-6/12 lg:w-3/12 custom_2xl:w-[19.875rem] max-[420px]:px-0 px-2 xl:px-3 custom_2xl:px-0  pt-4 w-full h-fit${
        isActive ? "cursor-pointer" : "cursor-not-allowed"
      }`}
    >
      <div
        className={
          "rounded-2xl relative bg-lightBlack p-2 sm:p-4 sm:h-[19.5rem] md:h-[21.2rem] lg:h-[20.50rem] xl:h-[25.5rem] group flex flex-col gap-y-2 sm:gap-y-4"
        }
      >
        {/* CONDITIONAL RENDERING OF 'COMING SOON' label */}
        {!isActive && (
          <img
            className={`absolute top-0 left-0 z-10`}
            src={comingSoon}
            alt="coming-soon"
          />
        )}
        {/* DISPLAYING THUMBNAIL OF THE GAME */}
        <div className="overflow-hidden rounded-lg  h-[14rem] xl:h-[19rem] custom_2xl:w-[17.875rem] relative">
          <img
            className={`group-hover:scale-110 duration-200 w-full h-full object-cover ${isActive ? '' : 'saturate-0'}`}
            src={thumbnail}
            alt="thumbnail-img"
          />
        </div>
        {/* GAME ICON AND DETAILS */}
        <div className="flex items-center">
          <span className="bg-black rounded-xl">
            {icon}
          </span>
          <span className="pl-1 sm:pl-3 custom_sm:mt-5 sm:mt-0">
            <h4 className="text-white uppercase font-wide font-bold text-[0.938rem] pt-1 whitespace-nowrap md:whitespace-normal">
              {title}
            </h4>
          </span>
        </div>
      </div>
    </Link>
  );
};
