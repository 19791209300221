import { FormEvent, useState, useEffect } from 'react';
// icons
import { GreenSubmitIcon } from "../common/icons/FooterIcons";
import { InputCross } from "../common/icons/Common";
import { registerInterest } from '../../utils/NewsEmail';
import { toast } from 'react-toastify';
import { ImSpinner8 } from 'react-icons/im';

const StayUpdatedForm = () => {
  const [email, setEmail] = useState<string>('');
  const [activeField, setActiveField] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const submitPromise = registerInterest(email, ['general']);
      toast.promise(submitPromise, {
        pending: 'Registering interest',
        success: 'You are registered for any updates regarding Versus!',
        error: 'Error registering interest'
      })
      await submitPromise;
      setEmail('');
      setSubmitting(false);
    } catch (err) {
      toast.error("Error registering interest");
    }
  }

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('emailInput') && !target.closest('emailInput')) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', checkForOutsideInputClick);

    return () => document.removeEventListener('click', checkForOutsideInputClick);
  }, [])

  return (
    <>
      <h4 className="text-base font-normal font-compact text-white md:block hidden">
        Stay Updated
      </h4>
      <form
        onSubmit={handleSubmit}
        className="py-6 items-center md:flex hidden">
        <div id="stayUpdateInput" className="relative w-full">
          <input
            className={`emailInput px-4 py-3 text-sm w-full h-[40px] placeholder:text-steelGray outline-none text-white bg-black font-compact rounded-xl border-2 border-transparent ${activeField === 'email'
                ? "pr-10 placeholder:text-white !border-green"
                : ""
              }`}
            onClick={() => setActiveField('email')}
            onChange={(e) => setEmail(e.target.value)}
            required
            id="email"
            type="email"
            value={email}
            placeholder="Your e-mail"
            autoComplete="off"
          />
          {activeField === 'email' && (
            <span
              onClick={() => {
                setEmail('');
                setActiveField('');
              }}
              className="absolute top-1/2 -translate-y-1/2 end-4 cursor-pointer"
            >
              <InputCross />
            </span>
          )}
        </div>
        <button
          type="submit"
          className={`p-[0.813rem] rounded-xl ml-3 duration-300 ${location.pathname === "/services"
              ? "bg-purple hover:opacity-90 cursor-not-allowed"
              : "bg-green hover:bg-gorse"
            } `}
        >
          {submitting ? (
            <ImSpinner8 className='animate-spin'/>
          ) : (
            <GreenSubmitIcon />
          )}
        </button>
      </form>
    </>

  )
}

export default StayUpdatedForm;
