import { ReactNode } from 'react';
// context
import { useNotificationContext } from '@provider/NotificationProvider';
// types
import { Notification } from '@src/firestore/notifications';
// components
import TeamInviteNotifs from "./Common/TeamInviteNotifs";
import { TournamentCheckInNotif, TournamentWaitingCheckInNotif } from "./Tournament/TournamentCheckInNotifs";
import TournamentCodeNotif from './Tournament/TournamentCodeNotif';
import TournamentSlotAssignedNotif from './Tournament/TournamentSlotAssignedNotif';
import TournamentAnnouncmentNotif from './Tournament/TournamentAnnouncementNotif';
import TournamentStartsInHourNotif from './Tournament/TournamentStartsInHourNotif';
import TournamentGroupReadyNotif from './Tournament/TournamentGroupReadyNotif';
import TournamentPlacementCongratulationsNotif from './Tournament/TournamentPlacementCongratulationsNotif';
import TournamentWinningsDistributedNotif from './Tournament/TournamentWinningsDistributedNotif';
import BrandedNotifs from './Branded/BrandedNotifs';

interface INotificationList {
  closeMenu: () => void
}

const NotificationList: React.FC<INotificationList> = ({closeMenu}) => {
  const { notifications, notificationsCount, dismissNotification } = useNotificationContext();

  const renderNotification = (notification: Notification): ReactNode => {
    switch (notification.type) {
      case 'tournamentPlayerCode':
        return <TournamentCodeNotif key={`notification-${notification.id}`} notification={notification} closeMenu={closeMenu}/>
      case 'tournamentConfirmation':
        return <TournamentCheckInNotif key={`notification-${notification.id}`} notification={notification} closeMenu={closeMenu}/>
      case 'tournamentWaitingConfirmation':
        return <TournamentWaitingCheckInNotif key={`notification-${notification.id}`} notification={notification} closeMenu={closeMenu}/>
      case 'waitingListAssignedSlot':
        return <TournamentSlotAssignedNotif key={`notification-${notification.id}`} notification={notification} dismissNotification={dismissNotification} closeMenu={closeMenu}/>
      case 'tournamentAnnouncement':
        return <TournamentAnnouncmentNotif key={`notification-${notification.id}`} notification={notification} dismissNotification={dismissNotification} closeMenu={closeMenu}/>
      case 'tournamentStartsInHour':
        return <TournamentStartsInHourNotif key={`notification-${notification.id}`} notification={notification} dismissNotification={dismissNotification} closeMenu={closeMenu} />
      case 'tournamentGroupReady':
        return <TournamentGroupReadyNotif key={`notification-${notification.id}`} notification={notification} dismissNotification={dismissNotification} closeMenu={closeMenu} />
      case 'tournamentPlacementCongratulations':
        return <TournamentPlacementCongratulationsNotif key={`notification-${notification.id}`} notification={notification} dismissNotification={dismissNotification} closeMenu={closeMenu} />
      case 'tournamentWinningsDistributed':
        return <TournamentWinningsDistributedNotif key={`notification-${notification.id}`} notification={notification} dismissNotification={dismissNotification} closeMenu={closeMenu} />
    }
    return '';
  }


  return (
    <div className='flex-grow-1 max-h-[100%] flex flex-col gap-y-3 overflow-y-scroll maskedListVert py-[20px] px-4'>
      {notificationsCount > 0 ? (
        <>
          <BrandedNotifs/>
          <TeamInviteNotifs/>
          {notifications.map((notification) => {
            return renderNotification(notification);
          })}
        </>
      ) : (
        <p className='font-wide bg-lightGray uppercase font-semibold text-steelGray w-full text-center rounded-lg p-3 my-2'>
          No new notifications
        </p>
      )}
    </div>
  )
}

export default NotificationList;
