import { Link } from 'react-router-dom';
// types
import { Notification } from '../../../../firestore/notifications';
// icons
import { ApexLegendsIcon, ValorantIcon, FortniteIcon, RocketLeagueIcon } from '../../../common/icons/Games';
import { FaXmark } from 'react-icons/fa6';

const gameNameToIcon = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (<ApexLegendsIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>);
    case 'Valorant':
      return (<ValorantIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>);
    case 'Fortnite':
      return (<FortniteIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>)
    case 'Rocket League':
      return (<RocketLeagueIcon className="w-[18px] h-auto aspect-square fill-white text-xl"/>)
  }
  return 'no icon'
}

interface INotification {
  notification: Notification,
  dismissNotification: (notification: Notification) => void,
  closeMenu: () => void,
}

const TournamentSlotAssignedNotif: React.FC<INotification> = ({notification, dismissNotification}) => {

  const body = notification.body as {
    tournamentId: string,
    tournamentName: string,
    title: string,
  };

  const { tournamentId, tournamentName, title } = body;

  return (
    <div className="relative bg-lightGray rounded-xl p-4 pr-10" key={notification.id}>
      <button type="button"
              aria-label='dismiss notification'
              onClick={() => dismissNotification(notification)}
              className="absolute top-1 right-1 p-1 fill-ebonyClay hover:opacity-75 transition-opacity">
        <FaXmark className='w-[20px] h-auto aspect-square fill-inherit'/>
      </button>
      <div className="flex items-start gap-x-3">
        <div className='p-[0.55rem] rounded-full bg-steelGray/30'>
          {gameNameToIcon(title)}
        </div>
        <div>
          <p className="text-white font-compact whitespace-nowrap">
            <span>You have been allocated a slot in{' '}</span>
            <Link to={`/tournaments/${tournamentId}`}
                  className='uppercase text-green hover:text-gorse transition-colors'>
              {tournamentName}
            </Link>
            <span className='font-thin'>!</span>
          </p>
        </div>
      </div>
      <Link
        to={`/tournaments/${tournamentId}`}
        className={`relative w-full mt-3 bg-green hover:bg-gorse text-black font-semibold overflow-hidden focus_visible_hidden text-base leading-4 px-3 py-[0.8rem] rounded-xl font-compact flex items-center gap-2 justify-center
                    transition-all uppercase`}>
        <span>View</span>
      </Link>
    </div>
  )
}

export default TournamentSlotAssignedNotif;
