import { ReactNode, createContext, useState, useEffect, useContext, useCallback } from "react"
// firebase
import { firestore, storage } from '../../firebase';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { deleteObject, getDownloadURL, ref, uploadBytes } from "firebase/storage";
// types
import { userConverter } from '../../firestore/users';
// context
import { useAuthContext } from "../../provider/AuthContextProvider";
// libaries
import { toast } from 'react-toastify';
// utils
import { resizeImage } from "../../utils/ResizeImage";
import { formatLink } from "../../utils/Link";

interface UserInfo { // not DBUser, state to be used for form collection and submission
  displayName: string,
  username: string,
  bio: string,
  discord: string,
  twitter: string,
  twitch: string,
  instagram: string,
  youtube: string,
  displayImage: File | null,
  bannerImage: File | null,
  displayImageUrl: string,
  bannerImageUrl: string,
  originalDisplayImageUrl: string,
  originalBannerImageUrl: string,
}

interface IUserInfoContext {
  userInfo: UserInfo | null,
  setUserInfo: (userInfo: UserInfo) => void,
  updateUser: () => Promise<unknown>,
  uploadBanner: () => Promise<boolean>,
  uploadDisplayImage: () => Promise<boolean>,
}

const defaultUserInfoContext = {
  userInfo: null,
  setUserInfo: (userInfo: UserInfo) => userInfo,
  updateUser: () => new Promise<boolean>(() => false),
  uploadBanner: () => new Promise<boolean>(() => false),
  uploadDisplayImage: () => new Promise<boolean>(() => false),
}

const UserInfoContext = createContext<IUserInfoContext>(defaultUserInfoContext);

export const useUserInfoContext = () => {
  const context = useContext(UserInfoContext);
  return context;
}

interface IUserInfoProvider {
  children: ReactNode,
}

const UserInfoProvider: React.FC<IUserInfoProvider> = ({children}) => {
  const { user } = useAuthContext();
  // we use this to only populate user info once intially on auth user load and not on subsequent updates, which would override changes
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [userLoaded, setUserLoaded] = useState<boolean>(false);

  const updateUser = async () => {


    if (user && userInfo) {
      const userRef = doc(firestore, 'users', user.uid).withConverter(userConverter);
      const updatedUser = {
        displayName: userInfo.displayName,
        bio: userInfo.bio,
        discord: userInfo.discord,
        twitter: formatLink(userInfo.twitter),
        twitch: formatLink(userInfo.twitch),
        instagram: formatLink(userInfo.instagram),
        youtube: formatLink(userInfo.youtube),
      }
      try {
        const updatePromise = updateDoc(userRef, updatedUser);
        toast.promise(updatePromise, {
          pending: 'Updating profile',
          success: 'Profile updated successfully',
          error: 'Error updating profile'
        })
        return updatePromise;
      } catch (err) {
        console.error(err);
      }
    }
  }

  const uploadBanner = async () => {
    if (user && userInfo) {
      const upload = async () => {
        const banner = userInfo.bannerImage! as File;
        const bannerResized = await resizeImage(banner, {width: 1000});
        if (bannerResized) {
          try {
            const bannerStorageRef = ref(storage, `users/${user.uid}/${banner.name}`);
            const storageSnapshot = await uploadBytes(bannerStorageRef, bannerResized as Blob);
            const userRef = doc(firestore, 'users', user.uid);
            const bannerImageUrl = await getDownloadURL(storageSnapshot.ref);
            if (userInfo.bannerImageUrl) {
              try {
                const previousBannerRef = ref(storage, userInfo.bannerImageUrl);
                await deleteObject(previousBannerRef);
              } catch (error) {
                console.error('Error deleting banner:', error);
                return false;
              }
            }
            setUserInfo({...userInfo, bannerImage: null, bannerImageUrl: bannerImageUrl });
            await updateDoc(userRef, {bannerImage: bannerImageUrl});
            return true;
          } catch (err) {
            console.error('Error uploading banner', err)
            return false;
          }
        } else {
          console.error('Error resizing banner');
          return false;
        }
      }

      const uploadPromise = upload();

      toast.promise(uploadPromise, {
        pending: 'Uploading banner',
        success: 'Banner uploaded',
        error: 'Error uploading banner'
      })

      return uploadPromise;
    } else {
      return false;
    }
  }

  const uploadDisplayImage = async () => {
    if (user && userInfo) {
      const upload = async () => {
        const displayImage = userInfo.displayImage! as File;
        const displayImageResized = await resizeImage(displayImage, {width: 1000});
        if (displayImageResized) {
          try {
            const bannerStorageRef = ref(storage, `users/${user.uid}/${displayImage.name}`);
            const storageSnapshot = await uploadBytes(bannerStorageRef, displayImageResized as Blob);
            const userRef = doc(firestore, 'users', user.uid);
            const displayImageUrl = await getDownloadURL(storageSnapshot.ref);
            if (userInfo.displayImageUrl) {
              try {
                const previousDisplayImageRef = ref(storage, userInfo.displayImageUrl);
                await deleteObject(previousDisplayImageRef);
              } catch (error) {
                console.error('Error deleting logo:', error);
                return false;
              }
            }
            setUserInfo({...userInfo, displayImage: null, displayImageUrl: displayImageUrl});
            await updateDoc(userRef, {displayImage: displayImageUrl});
            return true;
          } catch (err) {
            console.error('Error uploading display image', err)
            return false;
          }
        } else {
          console.error('Error uploading display image');
          return false;
        }
      }

      const uploadPromise = upload();

      toast.promise(uploadPromise, {
        pending: 'Uploading display image',
        success: 'Display image uploaded',
        error: 'Error uploading display image'
      })

      return uploadPromise;
    } else {
      return false;
    }
  }

  const getUserObj = useCallback(async () => {
    if (user && !userLoaded) {
      const userRef = doc(firestore, 'users', user.uid).withConverter(userConverter);
      const userDoc = (await getDoc(userRef)).data()!;
      const localUserInfo: UserInfo = {
        displayName: userDoc.displayName,
        username: userDoc.username,
        bio: userDoc.bio,
        discord: userDoc.discord ?? '',
        twitter: userDoc.twitter ?? '',
        twitch: userDoc.twitch ?? '',
        instagram: userDoc.instagram ?? '',
        youtube: userDoc.youtube ?? '',
        displayImage: null,
        bannerImage: null,
        displayImageUrl: userDoc.displayImage ?? '',
        bannerImageUrl: userDoc.bannerImage ?? '',
        originalDisplayImageUrl: userDoc.displayImage ?? '',
        originalBannerImageUrl: userDoc.bannerImage ?? '',
      }
      setUserInfo(localUserInfo);
      setUserLoaded(true);
    }
  }, [user, userLoaded])

  useEffect(() => {
    getUserObj();
  }, [user, getUserObj])

  return (
    <UserInfoContext.Provider value={{userInfo: userInfo, setUserInfo: setUserInfo, updateUser: updateUser, uploadBanner: uploadBanner, uploadDisplayImage: uploadDisplayImage}}>
      {children}
    </UserInfoContext.Provider>
  )
}

export default UserInfoProvider;
