import { DocumentSnapshot, SnapshotOptions, Timestamp } from "firebase/firestore";

interface FirestoreEnquiry {
  id: string,
  name: string,
  email: string,
  message: string,
  context: string,
  dateSent: Timestamp
}

export interface Enquiry {
  id: string,
  name: string,
  email: string,
  message: string,
  context: string,
  dateSent: Date
}

export const enquiryConverter = {
  toFirestore: (enquiry: Enquiry): Enquiry => {
    return {
      id: enquiry.id,
      name: enquiry.name,
      email: enquiry.email,
      message: enquiry.message,
      context: enquiry.context,
      dateSent: enquiry.dateSent
    }
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions): Enquiry => {
    const data = snapshot.data(options)! as FirestoreEnquiry;
    const enquiry: Enquiry = {
        id: data.id,
        name: data.name,
        email: data.email,
        message: data.message,
        context: data.context,
        dateSent: new Date(data.dateSent.seconds * 1000)
    }
    return enquiry;
  }
}
