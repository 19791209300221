import { Link } from "react-router-dom";
import {  GoToIcon } from "../../common/icons/Settings";
import { CrossIcon } from '../../common/icons/FilterIcons'

interface ConnectAccountPopupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setOpenPopups: any
  icon: React.ReactNode; // Update this type based on the actual type of your icon component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  turnaments: any // Update this type based on the actual type of turnaments
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popupValue:any
}

const ConnectAccountPopup: React.FC<ConnectAccountPopupProps> = ({
  setOpenPopups,
  icon,
  turnaments,
  title,
  popupValue,
}) => {
  // FUNCTION TO CLOSE THE POPUP
  const onClose = () => {
    setOpenPopups(false); // Close the popup
  };

  return (
    <div className="relative w-screen h-screen flex justify-center items-end sm:items-center">
      {/* BACKGROUND OVERLAY */}
      <div
        onClick={onClose}
        className="absolute block top-0 left-0 w-screen h-screen bg-black/40"
      ></div>
      <div>
        <div className="z-50 bg-lightBlack rounded-xl relative text-white mx-auto sm:max-w-[30rem] w-full">
          <h2 className="font-wide font-bold text-base !leading-4 uppercase px-4 pt-[1.125rem] pb-[0.875rem] border-b-[0.063rem] border-lightGray relative w-full">
            Connect your account
            <span
              className="absolute top-3 -mt-[0.125rem] end-3 cursor-pointer"
              onClick={onClose}
            >
              <CrossIcon />
            </span>
          </h2>
          <div className="border-t border-solid border-lightBlack p-6">
            <span className={popupValue ? popupValue.mygame_icon_color : ""}>
              {icon}
            </span>
            <h2 className="font-wide font-bold text-base !leading-4 text-white pt-4 uppercase">
              {title}
            </h2>
            <p className="font-compact font-normal text-sm !leading-4 !tracking-[0.009rem] text-white/40 py-3">
              {turnaments} tournaments
            </p>
            <p className="font-compact font-normal !leading-6 !tracking-[0.01rem] text-white/40 text-slateGray">
              You'll be redirected to the Valorant Login page in order to
              complete this process.
            </p>
            <Link
              to="#"
              className={`font-compact font-medium text-base !leading-4 !tracking-[0.01rem] text-black bg-green px-6 pt-[1.063rem] pb-[0.938rem]
              rounded-xl w-full text-center uppercase flex gap-2 items-center hover:bg-gorse justify-center mt-6`}
            >
              Login to your account <GoToIcon />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectAccountPopup;
