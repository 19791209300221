// firebase
import {  DocumentSnapshot, SnapshotOptions, Timestamp } from 'firebase/firestore';
// types
import { PlayerData } from "./teams";

export enum PerformanceHistoryStatus {
  registered,
  waiting,
  ongoing,
  eliminated,
  placement,
  declined,
  ignored,
  confirmed
}

export const performanceHistoryStatusToString = (status: PerformanceHistoryStatus): string => {
  switch(status) {
    case PerformanceHistoryStatus.registered:
      return 'Registered';
    case PerformanceHistoryStatus.waiting:
      return 'Waiting List';
    case PerformanceHistoryStatus.confirmed:
      return 'Confirmed';
    case PerformanceHistoryStatus.ongoing:
      return 'Ongoing';
    case PerformanceHistoryStatus.eliminated:
      return 'Eliminated';
    default:
      return '';
  }
}

interface PerformanceHistoryFirestore {
  id: string,
  teamId: string,
  tournamentId: string,
  tournamentName: string,
  tournamentStartDate: Timestamp,
  placement: number,
  prized: boolean,
  status: PerformanceHistoryStatus,
  participatingPlayerData: PlayerData[],
  winnings: number | null
}


export interface PerformanceHistory {
  id: string,
  teamId: string,
  tournamentId: string,
  tournamentName: string,
  tournamentStartDate: Date,
  placement: number,
  prized: boolean,
  status: PerformanceHistoryStatus,
  participatingPlayerData: PlayerData[],
  winnings: number | null
}

export const performanceHistoryConverter = {
  toFirestore: (performanceHistory: PerformanceHistory): PerformanceHistory => {
    return {
      id: performanceHistory.id,
      teamId: performanceHistory.teamId,
      tournamentId: performanceHistory.tournamentId,
      tournamentName: performanceHistory.tournamentName,
      tournamentStartDate: performanceHistory.tournamentStartDate,
      placement:  performanceHistory.placement,
      prized: performanceHistory.prized ?? false,
      status: performanceHistory.status,
      participatingPlayerData: performanceHistory.participatingPlayerData,
      winnings: performanceHistory.winnings
    };
  },
  fromFirestore: (snapshot: DocumentSnapshot, options: SnapshotOptions): PerformanceHistory => {
    const data = snapshot.data(options)! as PerformanceHistoryFirestore;

    return {
      id: data.id,
      teamId: data.teamId,
      tournamentId: data.tournamentId,
      tournamentName: data.tournamentName,
      tournamentStartDate: new Date(data.tournamentStartDate.seconds * 1000),
      placement:  data.placement,
      prized: data.prized ?? false,
      status: data.status,
      participatingPlayerData: data.participatingPlayerData,
      winnings: data.winnings
    };
  }
}
