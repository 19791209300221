import { useEffect, useState, useRef } from "react";
// context
import { useProfileInfoContext } from "./ProfileInfoProvider";
// types
import { ProfileGame } from '@src/firestore/users';
// components
import ApexLegendsForm from "./ApexLegendsForm";
// icons
import {  GrayCrossIcon } from "@icons/Header";
import { CreateBackArrowIcon, RightCheckIcon } from "../common/icons/ProfileIcons";
import { FaCheck, FaPlus } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { ImSpinner8 } from "react-icons/im";
import { ApexLegendsIcon } from "../common/icons/Games";

const gameNameToSquare = (gameName: string) => {
  switch (gameName) {
    case 'Apex Legends':
      return (
        <div className="w-[40px] h-auto aspect-square rounded bg-[#CC3131]
                                    flex items-center justify-center">
          <ApexLegendsIcon className="w-[33px] h-auto aspect-square fill-white"/>
        </div>
      );
  }
  return 'no icon'
}

export enum SupportedGame {
  apex
}

interface IProfileStep {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

const Step2: React.FC<IProfileStep> = ({ changeModalStep, closeModal }) => {
  const scrollContainerRef = useRef(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { profileInfo, setProfileInfo, completeProfile, updateMode, updateProfile, reloadInfo} = useProfileInfoContext();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [gameValid, setGameValid] = useState<boolean>(false);

  const [game, setGame] = useState<ProfileGame | null>(null);
  const [existingGameIndex, setExistingGameIndex] = useState<number>(-1);

  useEffect(() => {
    if (game) {
      setExistingGameIndex(profileInfo.games.findIndex((existingGame) => existingGame.title === game.title));
    } else {
      setExistingGameIndex(-1);
    }
  }, [game])

  const handleAddGame = () => {
    if (game) {
      if (existingGameIndex !== -1) {
        const localGames = profileInfo.games;
        localGames[existingGameIndex] = game;
        setProfileInfo({
          ...profileInfo,
          games: localGames
        });
      } else {
        setProfileInfo({
          ...profileInfo,
          games: [
            ...profileInfo.games,
            game
          ]
        });
      }
      setGame(null);
      setSelectingGame(false);
      setSelectedGame(-1);
    }
  }


  const handleRemoveGame = (gameTitle: string) => {
    const gameIndex = profileInfo.games.findIndex((existingGame) => existingGame.title === gameTitle);
    if (gameIndex !== -1) {
      const localGames = [...profileInfo.games];
      localGames.splice(gameIndex, 1);
      setProfileInfo({
        ...profileInfo,
        games: localGames
      });
      setGame(null);
    }
  }

  useEffect(() => {
    if (game) {
      const localGameValid = game.platform !== '' && game.input !== '' && game.mainLegend !== '' && game.altLegend !== '' && game.favouriteWeapon !== '';
      setGameValid(localGameValid);
    } else {
      setGameValid(false);
    }
  }, [game])

  const [selectingGame, setSelectingGame] = useState<boolean>(false);
  const [selectedGame, setSelectedGame] = useState<SupportedGame | -1>(-1);


  const handleStepSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setSubmitting(true);
    e.preventDefault();
    if (updateMode) {
      await updateProfile();
    } else {
      await completeProfile()
    }
    setSubmitting(false);
  };

  useEffect(() => {
    setFormValid(profileInfo.games.length > 0);
  }, [profileInfo])


  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="flex justify-between items-center border-b-[0.063rem] border-lightGray py-3 px-4 bg-lightBlack">
        <span
          className="cursor-pointer"
          onClick={() => {
            changeModalStep(-1);
          }}>
          <CreateBackArrowIcon />
        </span>
        <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
          {updateMode ? 'Edit' : 'Complete'} Profile
        </h5>
        <span
          className="cursor-pointer"
          onClick={() => {
            reloadInfo();
            closeModal();
          }}
        >
          <GrayCrossIcon className="group" />
        </span>
      </div>
      <div ref={scrollContainerRef} className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]">

        <div className="max-[26.25rem]:p-3 p-6 pb-0 ">
          <div className="flex gap-[1.375rem] items-center justify-center">
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center">
              <RightCheckIcon />
            </div>
            {updateMode ? (
              <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-green flex justify-center items-center">
                <RightCheckIcon />
              </div>
            ) : ''}
            <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-green bg-lightBlack"></div>
          </div>

          <div className="flex items-center w-full justify-between">
            <p className="font-compact text-steelGray font-medium text-left w-fit">
              {selectingGame ?  selectedGame !== -1 ? '' : 'Add Game' : 'Games'}
            </p>
          </div>

          {selectingGame ? (
            <>
              {selectedGame !== -1 ? (
                <>
                  {selectedGame === SupportedGame.apex ? (
                    <ApexLegendsForm game={game} setGame={setGame}
                                     containerRef={scrollContainerRef}
                                     closeForm={() => {
                                       if (existingGameIndex === -1) {
                                        setSelectedGame(-1)
                                       } else {
                                        setSelectedGame(-1);
                                        setSelectingGame(false);
                                       }
                                     }}/>
                  ) : ''}
                </>
                ) : (
                <div className="grid grid-cols-3 gap-4 mt-4">
                  <button type="button"
                          disabled={profileInfo.games.some((game) => game.title === 'Apex Legends')}
                          onClick={() => setSelectedGame(SupportedGame.apex)}
                          className={`relative w-full h-auto aspect-square bg-[#CC3131] rounded-lg
                                      transition-all flex items-center justify-center
                                     ${profileInfo.games.some((game) => game.title === 'Apex Legends') ? 'opacity-50' : 'hover:scale-105'}`}>
                    {profileInfo.games.some((game) => game.title === 'Apex Legends') ? (
                      <div className="bg-green w-[20px] h-auto aspect-square rounded-full
                                        absolute top-0 right-0 translate-x-1/3 -translate-y-1/3 flex items-center justify-center">
                        <FaCheck className="text-black w-[10px]"/>
                      </div>
                    ) : ''}
                    <ApexLegendsIcon className="w-[70px] h-auto aspect-square fill-white"/>
                  </button>

                  <button type="button"
                          onClick={() => setSelectingGame(false)}
                          className="w-full h-auto aspect-square bg-lightGray rounded-lg
                                  hover:opacity-75 transition-all flex items-center justify-center">
                    <FaXmark className="w-[50px] h-auto aspect-square text-steelGray"/>
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div>
                {profileInfo.games.map((game) => (
                  <button key={`game-button-${game}`}
                          type="button"
                          onClick={() => {
                            setGame(game);
                            setSelectingGame(true);
                            setSelectedGame(SupportedGame.apex);
                          }}
                          className="relative w-full rounded-xl bg-lightGray p-3 flex items-center justify-between mt-2 hover:opacity-75 transition-opacity">
                    <div className="flex items-center gap-x-3">
                      <div className="w-[40px] h-auto aspect-square rounded bg-ebonyClay
                                      flex items-center justify-center">
                        {gameNameToSquare(game.title)}
                      </div>
                      <p className="font-wide text-white font-semibold uppercase">{game.title}</p>
                    </div>

                    <div aria-label={`remove ${game.title}`}
                         onClick={(e) => {
                           e.stopPropagation();
                           handleRemoveGame(game.title)
                         }}
                         className="bg-red/70 w-[20px] h-auto aspect-square rounded-full
                                        absolute top-0 right-0 translate-x-1/3 -translate-y-1/3 flex items-center justify-center hover:bg-red transition-colors">
                      <FaXmark className="w-[10px]"/>
                    </div>
                  </button>
                ))}
              </div>
              <button type="button"
                      onClick={() => setSelectingGame(true)}
                      className="w-full rounded-xl bg-lightGray p-3 flex items-center gap-x-3 mt-2 hover:opacity-75 transition-opacity">
                <div className="w-[40px] h-auto aspect-square rounded bg-ebonyClay
                                flex items-center justify-center">
                  <FaPlus className="text-white text-xl"/>
                </div>
                <p className="font-wide text-white font-semibold uppercase">Add Game</p>
              </button>
            </>
          )}

        </div>
      </div>
      <div className="border-t-[0.063rem] border-lightGray py-3 px-4">
        {selectedGame !== -1 ? (
            <button
              disabled={!gameValid}
              onClick={handleAddGame}
              type="submit"
              className={`font-compact text-base font-medium leading-[100%] tracking-[0.01rem] uppercase w-full  rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2
                          disabled:opacity-30`}>
              <span className="pt-[0.125rem]">{existingGameIndex === -1 ? 'Add Game' : 'Update Game'}</span>
              <span>
                <FaCheck />
              </span>
            </button>
        ) : (
          <button
            disabled={!formValid || submitting}
            onClick={handleStepSubmit}
            type="submit"
            className={`font-compact text-base font-medium leading-[100%] tracking-[0.01rem] uppercase w-full  rounded-xl text-center py-[0.938rem] common_green_btn flex items-center justify-center gap-2
                        disabled:opacity-30`}>
            <span className="pt-[0.125rem]">{updateMode ? 'Update Profile' : 'Complete Profile'}</span>
            <span>
              {submitting ? (
                <ImSpinner8 className="animate-spin"/>
              ) : (
                <FaCheck />
              )}
            </span>
          </button>
        )}

      </div>
    </div>
  );
};
export default Step2;
