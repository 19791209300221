import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// firebase
import { firestore } from '@src/firebase';
import { collection, query, onSnapshot, where } from 'firebase/firestore';
// types
import { Tournament, tournamentConverter } from '@src/firestore/tournaments';
// utils
import { formatDateTime, getTimezone } from '@utils/Date';
// components
import Modal from '@ui/Modal';
import NoticeText from '@ui/NoticeText';
// icons
import { FaCalendar } from "react-icons/fa";

const PublishedTournaments = () => {
  const [listOpen, setListOpen] = useState<boolean>(false);

  const [publishedTournaments, setPublishedTournaments] = useState<Tournament[]>([]);

  const getTournaments = () => {
    const publishedTournamentsCollection = collection(firestore, 'tournaments').withConverter(tournamentConverter);
    const publishedTournamentsQuery = query(publishedTournamentsCollection, where('hidden', '==', false), where('completed', '==', false));

    const unsubscribe = onSnapshot(publishedTournamentsQuery, (snapshots) => {
      const localPublishedTournaments = snapshots.docs.map((doc) => doc.data());
      localPublishedTournaments.sort((a, b) => a.statusDates.ongoing.getTime() - b.statusDates.ongoing.getTime());
      setPublishedTournaments(localPublishedTournaments);
    });

    return unsubscribe;
  }

  useEffect(() => {
    const unsubscribe = getTournaments();

    return () => unsubscribe();
  }, [])

  return (
    <>
      <div className="flex flex-col items-center bg-lightBlack border border-lightGray p-3 rounded-lg gap-y-2 h-[150px] justify-around">
        <h3 className='text-white font-wide uppercase font-semibold whitespace-nowrap'>Published Tournaments</h3>
        <p className='font-compact font-semibold'><em className='not-italic text-green'>{publishedTournaments.length}</em> tournaments</p>
        <button type='button' aria-label='Create Tournament'
                onClick={() => setListOpen(true)}
                className='relative flex items-center justify-center gap-x-2 w-full p-3 text-black font-wide uppercase font-semibold rounded-lg
                          bg-green hover:bg-gorse transition-all text-sm max-w-[300px]'>
          <span className='pt-[2px]'>
            View <strong className='font-bold'></strong>
          </span>
          {/* <FaEyeSlash className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                 w-[50px] h-auto aspect-square z-[0] fill-black/40'/> */}
        </button>
      </div>

      <Modal title='Published Tournaments'
             open={listOpen} setOpen={setListOpen}>
        <div className='flex flex-col gap-y-2 text-white font-compact w-full'>
          {publishedTournaments.length > 0 ? (
            <>
              {publishedTournaments.map((tournament) => (
                <Link key={`published-tournament-${tournament.id}`} to={`/tournaments/${tournament.id}`} onClick={() => setListOpen(false)}
                      className='w-full bg-lightGray rounded-lg flex items-center justify-between p-4 hover:opacity-75 transition-opacity'>
                  <div className='flex flex-col gap-y-1 items-start'>
                    <h3 className='font-semibold whitespace-nowrap'>{tournament.name}</h3>
                    <p className='text-sm text-steelGray'>Organiser</p>
                  </div>
                  <div className='flex items-center gap-x-2'>
                    <FaCalendar className='text-green'/>
                    <p className='text-sm text-white whitespace-nowrap'>{formatDateTime(tournament.statusDates.ongoing)}  {getTimezone()}</p>
                  </div>
                </Link>
              ))}
            </>
          ) : (
            <NoticeText>
              No published tournaments
            </NoticeText>
          )}
        </div>
      </Modal>
    </>
  )
}

export default PublishedTournaments;
