import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// components
import Modal from "@ui/Modal";
// icons
import { BlackArrow } from '@icons/Common';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Prize } from '@src/firestore/tournaments';
import { positionNumberToString } from '@src/firestore/tournamentPointsSystems';
import { toast } from 'react-toastify';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  stepNames: string[]
}

const PrizeStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, stepNames}) => {
  const [stepValid, setStepValid] = useState<boolean>(true);

  const [editPrizeIndex, setEditPrizeIndex] = useState<number>(-1);

  const placementPrizes = tournamentInfo.prizePool.prizes.filter((prize) => prize.position).sort((a,b) => a.position! - b.position!);

  const addPlacementPrize = () => {
    const localPlacementPrizes = [...placementPrizes];

    if (localPlacementPrizes.length === 20) {
      toast.error('Cannot add more than 20 placement prizes');
      return;
    }

    const nextPrizePosition = localPlacementPrizes.length > 0 ? localPlacementPrizes[localPlacementPrizes.length - 1].position! + 1 : 1;

    const newPrize: Prize = {
      position: nextPrizePosition,
      prizeName: positionNumberToString(nextPrizePosition),
      special: false,
      prizeAmount: 0,
      prizeDescription: 'placement'
    }

    localPlacementPrizes.push(newPrize);

    const otherPrizes = [...tournamentInfo.prizePool.prizes.filter((prize) => !prize.position)];

    setTournamentInfo({
      ...tournamentInfo,
      prizePool: {
        ...tournamentInfo.prizePool,
        prizes: [...localPlacementPrizes, ...otherPrizes]
      }
    })
  }

  const removePlacementPrize = (prizeIndex: number) => {
    const localPlacementPrizes = [...placementPrizes];

    localPlacementPrizes.splice(prizeIndex, 1);

    const otherPrizes = [...tournamentInfo.prizePool.prizes.filter((prize) => !prize.position)];

    setTournamentInfo({
      ...tournamentInfo,
      prizePool: {
        ...tournamentInfo.prizePool,
        prizes: [...localPlacementPrizes, ...otherPrizes]
      }
    })
  }

  const updatePlacementPrize = (prizeIndex: number, newValue: string) => {
    const localPlacementPrizes = [...placementPrizes];

    const valueConverted = parseInt(newValue, 10);
    const valueNumbered = isNaN(valueConverted) ? 0 : valueConverted;
    const valueStripped = valueNumbered === 0 ? 0 : parseInt(valueNumbered.toString().replace(/^0+/, ''), 10);

    localPlacementPrizes[prizeIndex].prizeAmount = valueStripped;

    const otherPrizes = [...tournamentInfo.prizePool.prizes.filter((prize) => !prize.position)];

    setTournamentInfo({
      ...tournamentInfo,
      prizePool: {
        ...tournamentInfo.prizePool,
        prizes: [...localPlacementPrizes, ...otherPrizes]
      }
    })
  }


  useEffect(() => {
    let placementPrizesDecreasingPrizeAmount = true;
    let previousPrizeAmount = -1;
    for (const prize of placementPrizes) {
      if (previousPrizeAmount === -1 || prize.prizeAmount <= previousPrizeAmount) {
        previousPrizeAmount = prize.prizeAmount;
      } else {
        placementPrizesDecreasingPrizeAmount = false;
        break;
      }
    }
    setStepValid(placementPrizesDecreasingPrizeAmount);
  }, [placementPrizes])

  return (
    <Modal title="Prize Pool"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={6}
           totalSteps={10}
           scroll={true}
           open={currentStep === CreationFlowPage.prize}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.entry)}
           buttonOnClick={() => changeStep(CreationFlowPage.comms)}>
      <div className="flex flex-col gap-y-3 text-white font-compact items-start w-full">
        <div className='flex flex-col gap-y-2 w-full'>
           <p className="text-white font-compact text-start">Placement Prizes</p>
           <div className='flex flex-col w-full gap-y-4'>
            {placementPrizes.map((prize, index) => {
              return (
                <div key={`placement-prize-${prize.position}-${index}`}
                     className={`relative w-full h-fit p-4 rounded-lg bg-lightGray flex items-start justify-between flex-shrink-0`}>
                  <div className='flex flex-col items-start font-compact'>
                    <p className="text-steelGray">Placement:</p>
                    <p className="text-white">{prize.prizeName}</p>
                  </div>

                  <div className='flex flex-col font-compact items-end min-w-[100px]'>
                    <p className="text-steelGray">Amount:</p>
                    <div className='flex items-center gap-x-2'>
                      {editPrizeIndex === index ? (
                        <input type="text" value={prize.prizeAmount}
                               onChange={(e) => updatePlacementPrize(index, e.target.value)}
                               className='bg-black rounded text-white placeholder:text-steelGray outline-none remove-arrow font-compact w-[60px] text-center'/>
                      ) : (
                        <p className="text-white">{tournamentInfo.prizePool.currency}{prize.prizeAmount}</p>
                      )}
                      {editPrizeIndex === index ? (
                        <button type='button' aria-label='Save Prize Amount'
                                onClick={() => setEditPrizeIndex(-1)}
                                className='p-1 px-2 bg-ebonyClay text-green text-xs font-compact uppercase rounded hover:opacity-75 transition-opacity'>
                          Save
                        </button>
                      ) : (
                        <button type='button' aria-label='Save Prize Amount'
                                disabled={editPrizeIndex !== -1}
                                onClick={() => setEditPrizeIndex(index)}
                                className='p-1 px-2 bg-ebonyClay text-green text-xs font-compact uppercase rounded hover:opacity-75 disabled:opacity-50 disabled:hover:opacity-50 transition-opacity'>
                          Edit
                        </button>
                      )}
                    </div>
                  </div>
                  {index === placementPrizes.length - 1 ? (
                    <button type='button' aria-label='Remove Prize'
                            onClick={() => removePlacementPrize(index)}
                            className='!w-[16px] !h-[16px] rounded absolute top-1 right-1 -translate-y-1/2 translate-x-1/2 flex items-center justify-center
                                       bg-ebonyClay hover:opacity-75 transition-opacity flex-shrink-0'>
                      <FaMinus className='fill-red/70 text-xs'/>
                    </button>
                  ) : ''}
                </div>
              );
            })}
            <button type="button" aria-label='Add Prize'
                    disabled={placementPrizes.length >= 20}
                    onClick={addPlacementPrize}
                    className='relative w-full h-fit p-4 rounded-lg
                              bg-lightGray fill-steelGray hover:bg-ebonyClay hover:fill-green disabled:opacity-50 disabled:hover:bg-lightGray disabled:hover:fill-steelGray
                                flex items-start justify-center flex-shrink-0 transition-all'>
              <FaPlus className='text-xl fill-inherit'/>
            </button>
           </div>
        </div>
      </div>
    </Modal>
  )
}

export default PrizeStep;
