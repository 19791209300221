// firebase
import { firestore } from "../firebase";
import { getDocs, doc, collection, setDoc, query, where, updateDoc } from "firebase/firestore";

export const registerInterest = async (email: string, interests: string[]) => {
  if (/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
    try {
      const existingNewsEmailQuery = query(collection(firestore, 'newsEmails'), where('email', '==', email));
      const existingNewsEmail = (await getDocs(existingNewsEmailQuery)).docs[0];
      if (!existingNewsEmail) {
        const newsEmailRef = doc(collection(firestore, 'newsEmails'));
        const usesrWithEmailQuery = query(collection(firestore, 'users'), where('email', '==', email));
        const existingUser = (await getDocs(usesrWithEmailQuery)).docs.length > 0;
        const createPromise = setDoc(newsEmailRef, {
          id: newsEmailRef.id,
          email: email,
          interests: interests,
          exisingUser: existingUser,
        })
        await createPromise;
      } else {
        const newsEmail = existingNewsEmail.data();
        const newInterests = newsEmail.interests;
        interests.forEach((interest) => {
          if (!newInterests.includes(interest)) {
            newInterests.push(interest);
          }
        });
        const newsEmailRef = doc(collection(firestore, 'newsEmails'), newsEmail.id);
        const updatePromise = updateDoc(newsEmailRef, {
          interests: newInterests
        });
        await updatePromise;
      }
    } catch (err) {
      throw new Error(err as string);
    }
  } else {
    throw new Error('Invalid email provided');
  }
}
