import { useState, useEffect } from 'react';
// types
import { CreationFlowPage, CreationFlowStep, TournamentInfo } from "../CreateTournament";
// components
import Input from "@ui/Input";
import Modal from "@ui/Modal";
// icons
import { BlackArrow } from '@icons/Common';

interface IFlowPage {
  currentStep: CreationFlowStep,
  changeStep: (step: CreationFlowStep) => void,
  tournamentInfo: TournamentInfo,
  setTournamentInfo: (info: TournamentInfo) => void,
  stepNames: string[]
}

const CommsStep: React.FC<IFlowPage> = ({currentStep, changeStep, tournamentInfo, setTournamentInfo, stepNames}) => {
  const [stepValid, setStepValid] = useState<boolean>(true);

  const [discordLinkValid, setDiscordLinkValid] = useState<boolean>(false);

  useEffect(() => {
    setDiscordLinkValid(/(https?:\/\/)?(www\.)?discord\.gg\/[a-zA-Z._-]+/.test(tournamentInfo.discordSupport))
  }, [tournamentInfo]);

  useEffect(() => {
    if (discordLinkValid && tournamentInfo.discordSupport) {
      setStepValid(true);
    } else {
      setStepValid(false);
    }
  }, [tournamentInfo, discordLinkValid]);

  return (
    <Modal title="Comms"
           changeStep={changeStep}
           stepNames={stepNames}
           disableClickOff={true}
           step={7}
           totalSteps={10}
           open={currentStep === CreationFlowPage.comms}
           setOpen={(open) => {
            if (!open) changeStep(-1);
           }}
           buttonDisabled={!stepValid}
           buttonText={(
            <div className='flex items-center gap-x-2'>
              <span className="pt-[2px]">next</span>
              <BlackArrow />
            </div>
           )}
           onBackClick={() => changeStep(CreationFlowPage.prize)}
           buttonOnClick={() => changeStep(CreationFlowPage.date)}>
      <div className="flex flex-col gap-y-3 w-full items-start text-start">
        <Input label='Discord Support Server' placeholder='discord.gg/versus'
               value={tournamentInfo.discordSupport}
               valid={discordLinkValid}
               onChange={(newValue) => setTournamentInfo({...tournamentInfo, discordSupport: newValue})}
               black={true}/>
      </div>
    </Modal>
  )
}

export default CommsStep;
