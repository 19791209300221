import { useEffect, useRef, useState } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc } from 'firebase/firestore';
// types
import { DBTeam, teamConverter } from '@src/firestore/teams';
import { ApexMapInfos } from '@customTypes/Games/ApexLegends';
// utils
import { getMapNameFromAcronym } from "@utils/Games/ApexLegends";
// libraries
import { toast } from 'react-toastify';
// components
import Modal from "@ui/Modal";
// import CheckBox from '@ui/CheckBox';
import InfoPopover from '@ui/InfoPopover';
import PoiPreferencesMapList from './PoiList';
import ApexMapAidsModal from '@src/components/apex/ApexMap/ApexMapAidsModal';
// icons
import { FaCheck, FaEye } from "react-icons/fa";
// import { SwordsIcon } from '@components/common/icons/TournamentIcons';
import { ImSpinner8 } from 'react-icons/im';

interface IMapModal {
  map: 'WE' | 'SP' | 'ED',
  open: boolean,
  setOpen: (open: boolean) => void,
  team: DBTeam
}

const ApexMapPoiPreferencesModal: React.FC<IMapModal> = ({ map, open, setOpen, team }) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [mapViewOpen, setMapViewOpen] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const poiLocationsForMap = ApexMapInfos[map].pois;

  const [poiLocationsOrdered, setPoiLocationsOrdered] = useState<number[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    const poiLocationNumbersForMap = team.gamePreferences.apex.poiPreferences[map];
    if (poiLocationNumbersForMap) {
      setPoiLocationsOrdered(poiLocationNumbersForMap);
      setEditMode(true);
    } else {
      setPoiLocationsOrdered(poiLocationsForMap.map((location) => location.poiNumber));
    }
  }, [team, map, poiLocationsForMap]);

  // const [contestsEnabled, setContestsEnabled] = useState<boolean>(false);

  const handleSavePreferences = async () => {
    try {
      setSubmitting(true);
      const teamRef = doc(firestore, 'teams', team.id).withConverter(teamConverter);
      const updatePromise = updateDoc(teamRef, {
        gamePreferences: {
          ...team.gamePreferences,
          apex: {
            ...team.gamePreferences.apex,
            poiPreferences: {
              ...team.gamePreferences.apex.poiPreferences,
              [map]: poiLocationsOrdered
            }
          }
        }
      });

      toast.promise(updatePromise, {
        pending: `${editMode ? 'Updating' : 'Setting'} ${getMapNameFromAcronym(map)} drop preferences`,
        success: `${getMapNameFromAcronym(map)} drop preferences ${editMode ? 'updated' : 'set'}`,
        error: `Error ${editMode ? 'updating' : 'setting'} ${getMapNameFromAcronym(map)} drop preferences`,
      });
      await updatePromise;
      setSubmitting(false);
      setOpen(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Modal title={getMapNameFromAcronym(map)}
            disableClickOff={true}
            ref={modalRef}
            open={open}
            setOpen={setOpen}
            buttonText={(
              <div className="flex items-center gap-x-2">
                <span>Save</span>
                {submitting ? (
                  <ImSpinner8 className='animate-spin'/>
                ) : (
                  <FaCheck/>
                )}
              </div>
            )}
            buttonDisabled={submitting}
            buttonOnClick={handleSavePreferences}
            scroll={false}>
        <div className='flex flex-col gap-y-6 text-left'>
          {/* <div className='opacity-50 pointer-events-none flex flex-col gap-y-2'>
            <div className='flex items-center gap-x-1'>
              <h3 className='text-sm font-compact text-white'>Contests</h3>
              <p className='text-xs text-steelGray'>(coming soon)</p>
            </div>
            <button type='button' aria-label='toggle contests enabled for dropspots'
                    onClick={() => setContestsEnabled(!contestsEnabled)}
                    className='w-full p-2 py-3 flex items-center justify-between bg-lightGray rounded-xl'>
              <div className='flex items-center gap-x-2 text-sm font-compact text-white'>
                <SwordsIcon className='w-[10px] h-auto aspect-square fill-green'/>
                <p>Contests Enabled?</p>
              </div>
              <CheckBox selected={contestsEnabled}
                        setSelected={(selected: boolean) => selected}
                        interactive={false}
                        asDiv={true}/>
            </button>
          </div> */}

          <div className='flex flex-col gap-y-2'>
            <div className="flex justify-between items-center">
              <div className='flex items-center gap-x-1'>
                <h3 className='text-sm font-compact text-white'>POI Preferences</h3>
                <InfoPopover>
                  Order your POI preferences to determine your picks in ALGS mode events
                </InfoPopover>
              </div>
              <button type='button' aria-label='open map visual aid'
                      onClick={() => setMapViewOpen(true)}
                      className='p-2 px-3 rounded-lg bg-ebonyClay text-white font-compact text-sm uppercase hover:opacity-75 transition-opacity
                                flex items-center gap-x-2'>
                <span>View Map</span>
                <FaEye className='w-[16px] h-auto aspect-square fill-green -translate-y-[1px]'/>
              </button>
            </div>
            <PoiPreferencesMapList mapModalRef={modalRef}
                              poiLocationsForMap={poiLocationsForMap}
                              poiLocationsOrdered={poiLocationsOrdered}
                              setPoiLocationsOrdered={setPoiLocationsOrdered}/>
          </div>

        </div>
      </Modal>
      <ApexMapAidsModal map={map} singleMap={true}
                        isOpen={mapViewOpen} close={() => setMapViewOpen(false)}/>
    </>
  )
};

export default ApexMapPoiPreferencesModal;
