import { ReactNode } from "react";

interface ISkeleton {
  className?: string,
  children?: ReactNode
}

export const SkeletonDark: React.FC<ISkeleton> = ({className, children}) => {
  return (
    <div className={`${className} bg-lightGray`}>
      {children}
    </div>
  );
}

export const SkeletonLight: React.FC<ISkeleton> = ({className, children}) => {
  return (
    <div className={`${className} bg-ebonyClay`}>
      {children}
    </div>
  );
}
