import React from "react";
import TopicList from "./TopicList";

interface Condition {
  title: string;
  description: string;
  // Add other properties as needed
}
interface ConditionTitle {
  title: string;
}
interface ConditionTitleWrapperProps {
  title: string;
  conditionsList: Condition[];
  conditiontitleList: ConditionTitle[];
}

export const ConditionTitleWrapper: React.FC<ConditionTitleWrapperProps> = ({
  title,
  conditionsList,
  conditiontitleList,
}) => {
  return (
    <>
      <div className="flex justify-between lg:px-12 custom_container">
        <div className="relative">
          <div className="absolute md:sticky md:top-[calc(8rem)] md:start-4">
            <TopicList conditiontitleList={conditiontitleList} title={title} />
          </div>
        </div>
        {/* DISPLAY AREA FOR PRIVACY POLICY CONTENT */}
        <div className="md:ps-4">
          <div className="xl:px-20">
            <h2 className="text-white md:text-green font-wide font-bold text-2xl sm:text-[1.75rem] md:text-3xl uppercase leading-[1.875rem] bg-lightBlack md:bg-transparent p-6 md:p-0 mb-4 md:mb-10">
              {title}
            </h2>
            {/* DISPLAY PRIVACY POLICY DATA THROUGH MAPPING */}
            <div className="px-4 md:px-0">
              {conditionsList.map((condition: Condition, index: number) => {
                const isLastItem = index === conditionsList.length - 1;
                const scrollValue = index + 1;
                return (
                  <div
                    id={scrollValue.toString()}
                    key={index}
                    className="topic-element"
                    data-sectiontype={condition.title}
                  >
                    <div>
                      <h2
                        className={`text-white font-wide font-bold text-base md:text-base uppercase !leading-5 lg:tracking-[0.01rem] `}
                      >
                        {condition.title}
                      </h2>
                      <p className="text-base lg:text-base font-normal font-compact leading-8 lg:leading-8 lg:tracking-[0.01rem text-[#6E7681] pb-10 pt-6">
                        {condition.description}
                      </p>
                    </div>
                    {/* Render the border conditionally */}
                    {isLastItem ? null : (
                      <div className="h-[0.063rem] w-full bg-ebonyClay mb-10"></div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
