// Importing the component from the specified path
import BrandLogoSlider from "../common/BrandLogoSlider";
import FindTeam from "./FindTeam";
import Hero from "./Hero";
import AboutCards from "./AboutCards";

// AboutUsMain component, which serves as the main container for various sections
const AboutUsMain = () => {
  return (
    <div className="md:-mt-8">
      <Hero />
      <AboutCards />
      <FindTeam />
      <BrandLogoSlider />
    </div>
  );
};

// Export the AboutUsMain component as the default export
export default AboutUsMain;
