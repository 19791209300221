import { useState } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc} from 'firebase/firestore';
// context
import { useTournamentContext } from "../../TournamentProvider";
import { useAuthContext } from '@provider/AuthContextProvider';
// types
import { TournamentStatus } from "@src/firestore/tournaments";
// packages
import { toast } from 'react-toastify';
// icons
import { ImSpinner8 } from 'react-icons/im';
import Modal from '@ui/Modal';
import { FaCheck } from 'react-icons/fa';

const EventStart = () => {
  const { userObj } = useAuthContext();
  const { tournament } = useTournamentContext();

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const setTournamentToOngoing = async () => {
    setSubmitting(true);
    if (tournament && tournament.status === TournamentStatus.prep && userObj && userObj.admin) {
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const tournamentStatusUpdatePromise = updateDoc(tournamentRef, {
        status: TournamentStatus.ongoing
      })

      toast.promise(tournamentStatusUpdatePromise, {
        pending: 'Starting event',
        success: 'Event started',
        error: 'Error starting event'
      })
      await tournamentStatusUpdatePromise;
    }
    setSubmitting(false);
    setConfirmOpen(false);
  }

  return tournament ? (
    <div>
      <button type="button"
              disabled={tournament.status !== TournamentStatus.prep}
              onClick={() => setConfirmOpen(true)}
              className="flex items-center justify-center gap-x-2 w-full py-[0.8125rem] text-black bg-green rounded-xl font-compact font-medium text-base !leading-6 uppercase
                        hover:bg-gorse transition-colors disabled:opacity-50 disabled:hover:bg-green">

        <span>
          {tournament.status > TournamentStatus.prep ? 'Event Started' : 'Start Event'}
        </span>
        {tournament.status > TournamentStatus.prep ? (
          <FaCheck/>
        ) : submitting ? (
          <ImSpinner8 className='animate-spin'/>
        ) : ''}
      </button>
      {tournament.status < TournamentStatus.prep ? (
        <p className={`text-sm font-compact my-2 text-red`}>
          Cannot start tournament until prep stage
        </p>
      ) : ''}
      <Modal title='Event Start'
             open={confirmOpen}
             setOpen={setConfirmOpen}
             buttonDisabled={tournament.status !== TournamentStatus.prep}
             buttonText={(
              <div className='flex items-center gap-x-2'>
                <span>
                  Confirm
                </span>
                {submitting ? (
                  <ImSpinner8 className='animate-spin'/>
                ) : (
                  <FaCheck/>
                )}
              </div>
             )}
             buttonOnClick={setTournamentToOngoing}>
        <div className="flex flex-col gap-y-4 text-white/90 font-compact font-thin text-sm">
          <div className="max-w-[90%] mx-auto flex flex-col gap-y-4">
            <p>
              <em className="not-italic text-red/70 uppercase"> WARNING! </em>
              Starting events cannot be undone.
            </p>
            <p>
              By confirming you acknowledge that all preperations have been completed including groups seeding.
            </p>
            <p>
              Are you sure?
            </p>
          </div>
        </div>
      </Modal>
    </div>
  ) : '';
}

export default EventStart;
