import { useEffect, useState } from 'react'
// types
import { TournamentGroup, TournamentStage } from "../../../../firestore/tournaments";
// components
import GroupMaps from './GroupMaps';
import { useTournamentContext } from '../../TournamentProvider';

interface IStageMaps {
  stage: TournamentStage,
}

const StageMaps: React.FC<IStageMaps> = ({stage}) => {
  const groups = stage.groups;
  const { tournament } = useTournamentContext();

  const finalStage = tournament !== null && (stage.stageNum === tournament.totalStages - 1);

  const [initialGroupFocused, setInitialGroupFocused] = useState<boolean>(false);
  const [currentGroup, setCurrentGroup] = useState<string>(''); // either the group's id, or strings: 'unassigned'  | 'declined' | 'ignored'

  useEffect(() => {
    if (!initialGroupFocused && groups.length > 0) {
      setCurrentGroup(groups[0].id);
      setInitialGroupFocused(true);
    }
  }, [groups])

  return (
    <div>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <div className='flex items-center gap-x-2 w-full'>
          {finalStage ? '' : (
            <div className='flex items-center gap-x-2'>
              {groups.map((group: TournamentGroup) => (
                <button key={`maps-group-${group.id}`} type="button"
                        onClick={() => setCurrentGroup(group.id)}
                        className={`p-1 px-2 border-[1px] border-lightGray text-steelGray font-medium rounded-full transition-colors whitespace-nowrap
                                    ${currentGroup === group.id ? "bg-lightGray text-white/85" : "hover:border-steelGray hover:bg-lightGray/20"}`}>
                  {group.groupName}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={`flex ${finalStage ? '' : 'mt-6'}`}>
        <div className='w-full lg:w-3/4'>
          <div>
            {groups.map((group) => (
              <div key={`maps-group-view-${group.id}`}>
              {currentGroup === group.id ? (
                <GroupMaps key={group.id} group={group}/>
              ) : ''}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StageMaps;
