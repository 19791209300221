import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import { FooterLinksCollection } from "./FooterHelper";
import { FooterAccordionIcon } from "./icons/FooterIcons";

// FooterAccordion component to display links in an accordion style
const FooterAccordion = () => {
  return (
    <div className="w-full pt-3">
      {/* Map through the FooterLinksCollection to generate accordion items */}
      {FooterLinksCollection.map((data, index) => (
        <div
          className="mx-auto w-full bg-lightGray rounded-lg mt-3"
          key={index}
        >
          <Disclosure>
            {({ open }) => (
              <>
                {/* Accordion button */}
                <Disclosure.Button className="flex w-full justify-between rounded-lg p-4 text-left items-center focus-visible:outline-none">
                  <span className="text-white text-base font-normal font-compact mb-[1px] leading-4">
                    {data.title}
                  </span>
                  {/* Accordion icon */}
                  <FooterAccordionIcon
                    accordianArrow={
                      open
                        ? "-rotate-180 duration-300 mt-1"
                        : "rotate-0 duration-300 mt-1"
                    }
                  />
                </Disclosure.Button>
                {/* Accordion panel with links */}
                <Disclosure.Panel className="px-4 pb-2 flex flex-col gap-3">
                  {/* Map through links in the accordion panel */}
                  {data.links.map((linkData, linkIndex) => (
                    <Link
                      to={linkData.url}
                      key={linkIndex}
                      className="hover:text-green duration-300 text-sm font-normal text-lightSlateGray font-compact"
                    >
                      {linkData.links}
                    </Link>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ))}
    </div>
  );
};

export default FooterAccordion;
