import { useState, useEffect, ReactNode } from 'react';
import { Link } from "react-router-dom";
// context
import { useTournamentContext } from "@components/tournament/TournamentProvider";
// types
import { Prize } from "@src/firestore/tournaments";
import { LeaderboardGroup, LeaderboardTeam, useLeaderboardContext } from "@components/tournament/LeaderboardProvider";
// components
import PopoverText from '@ui/PopoverText';
import { Disclosure, DisclosureButton } from "@headlessui/react";
import LeaderboardTeamGames from "./Inner/LeaderboardTeamGames";
// libraries
import { toast } from 'react-toastify';
// assets
import skullWhite from '@assets/images/apex/icons/Kills white.png'
// icons
import { FooterAccordionIcon } from "@icons/FooterIcons";
import { DiscordIcon, TwitterIcon, TwitchIcon, WebsiteIcon } from '@icons/Socials';
// import { Star } from "@icons/Common";
import { TrophyGoldImageNoCup, TrophySilverImageNoCup, TrophyBronzeImageNoCup  } from '@icons/TournamentIcons';
import { FaCrown } from 'react-icons/fa';
import SurfsharkIcon from '@components/brands/surfshark/SurfsharkIcon';

interface ILeaderboardTeamCard {
  team: LeaderboardTeam,
  teamIndex: number,
  group: LeaderboardGroup,
  stageComplete: boolean,
  groupStarted: boolean,
  finalScore: boolean,
  matchPointEligible: boolean,
  wonMatch: boolean
}

const LeaderboardTeamCard: React.FC<ILeaderboardTeamCard> = ({team, teamIndex, group, stageComplete, groupStarted, finalScore, matchPointEligible, wonMatch}) => {
  const { tournament, tournamentTeams } = useTournamentContext();
  const { selectedGameView } = useLeaderboardContext();

  const [prizes, setPrizes] = useState<Prize[]>([]);

  const gameResults = selectedGameView === 0 ? team.gameResults : team.gameResults.filter((game) => parseInt(game.gameName.split(' ')[1]) === selectedGameView);
  const teamScore = selectedGameView === 0 ? team.score : gameResults[0]?.gameScore ?? 0;
  const teamKills = selectedGameView === 0 ? team.kills : gameResults[0]?.result.kills ?? 0;

  const teamObj = team.team;

  useEffect(() => {
    if (tournament) {
      setPrizes(tournament.prizePool.prizes);
    }
  }, [tournament])

  const getPrizeTextColorForPosition = (position: number) => {
    const prizeForPosition = prizes.find((prize) => prize.position === position);
    if (prizeForPosition) {
      switch (position) {
        case 1:
          return '!text-yellow'
        case 2:
          return '!text-slateGray'
        case 3:
          return '!text-lightRed'
        default:
          return '!text-green'
      }
    }
    return null;
  }

  const getPrizeIconForPosition = (position: number) => {
    const prizeForPosition = prizes.find((prize) => prize.position === position);
    let prizeIcon: ReactNode = '';

    if (prizeForPosition) {
      switch (position) {
        case 1:
          prizeIcon = <TrophyGoldImageNoCup className='w-[50px] h-auto aspect-square object-contain'/>;
          break;
        case 2:
          prizeIcon = <TrophySilverImageNoCup className='w-[50px] h-auto aspect-square object-contain'/>;
          break;
        case 3:
          prizeIcon = <TrophyBronzeImageNoCup className='w-[50px] h-auto aspect-square object-contain'/>;
          break;
      }
      return (
        <div className='w-fit h-fit relative'>
          <p className={`${getPrizeTextColorForPosition(position)} font-compact text-xl leading-none
                          absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10`}>
            {position}
          </p>
          {prizeIcon}
        </div>
      );
    }
    return null;
  }

  const tournamentTeam = tournamentTeams.find((tournamentTeam) => tournamentTeam.id === team.id)!;
  const qualified = stageComplete && tournamentTeam.qualifiedStages.includes(group.stageNum);

  const qualifying = teamIndex < group.qualifyingSlots;
  const dangerQualifying = (group.qualifyingSlots >= teamIndex) && (teamIndex < group.qualifyingSlots + group.dangerQualifyingSlots);

  const prizeIcon = getPrizeIconForPosition(teamIndex + 1);
  const prizeColor = getPrizeTextColorForPosition(teamIndex + 1);

  return team && teamObj && tournament ? (
    <div className="mx-auto w-full bg-lightBlack rounded-lg">
      <Disclosure>
        {({ open }) => (
          <>
            <div className={`relative rounded-lg duration-300 group/card
                            ${open ? "max-md:bg-lightGray max-md:m-2" : ""}`}>
              <DisclosureButton disabled={!groupStarted} className="relative flex flex-row w-full h-[130px] md:h-fit p-0 justify-between rounded-lg text-left items-center focus-visible:outline-none ">
                <div className="flex md:hidden items-center justify-center h-full w-1/4 max-w-[80px] border-r border-lightGray">
                  <div className={`relative flex md:hidden items-center justify-center h-full w-full aspect-square
                                      font-compact uppercase  text-2xl font-semibold
                                      text-white`}>
                    {finalScore && selectedGameView === 0 && prizeIcon ? (
                      <>
                        {prizeIcon}
                      </>
                    ) : (
                      <p className={`${open || qualifying || dangerQualifying ? '' : 'opacity-50'} z-[10]`}>{teamIndex + 1}</p>
                    )}

                  </div>
                </div>
                <div className="flex flex-col justify-evenly w-full h-full px-4">
                  <Link to={`/team/${teamObj.id}`}
                        onClick={(e) => {
                          if (teamObj.dissolved) {
                            e.preventDefault();
                            e.stopPropagation();
                            toast.error('Team has been dissolved');
                          }
                        }}
                        className='hover:opacity-75 transition-opacity flex md:hidden items-center gap-x-3 h-fit w-fit'>
                    <img
                        className="w-[45px] h-auto aspect-square object-cover rounded-lg"
                        src={teamObj.logoUrl}
                        alt="entrants-image"
                      />
                    <div className="w-full mt-[0.313rem]">
                      <p className="font-compact font-medium !leading-4 uppercase tracking-[0.0088rem] text-steelGray mb-2 md:mb-0 sm:mt-1">
                        {teamObj.teamTag}
                      </p>
                      <h2 className={`uppercase font-wide font-bold text-lg !leading-4 flex items-center gap-4 whitespace-nowrap
                                    text-white`}>
                        {teamObj.teamName}
                      </h2>
                    </div>
                  </Link>

                  <div className="border-t border-lightGray md:border-none flex w-full justify-between pt-4 md:pt-0">
                    <div className="hidden md:flex">
                      <div className={`relative hidden md:flex items-center justify-center h-full w-auto aspect-square border-r border-lightGray
                                      font-compact uppercase  text-2xl font-semibold
                                      ${finalScore && selectedGameView === 0 && prizeColor ? prizeColor : 'text-white'}`}>
                        {finalScore && selectedGameView === 0 && prizeIcon ? (
                          <>
                            {prizeIcon}
                          </>
                        ) : (
                          <p className={`${(!stageComplete && selectedGameView === 0 && (open || qualifying || dangerQualifying))
                                          || (stageComplete && selectedGameView === 0 && qualified)
                                          || tournament.format === 'Match Point' && selectedGameView === 0
                                          ? (stageComplete && qualified) || matchPointEligible ? 'text-green' : ''
                                          : 'opacity-50'} z-[10]`}>
                            {teamIndex + 1}
                          </p>
                        )}
                      </div>
                      <div
                        className={`relative rounded-lg ps-4 py-4 pe-6 gap-4 w-full items-center hidden md:flex`}>
                        <div className={`flex h-full justify-start gap-x-4 w-full`}>
                          <Link to={`/team/${teamObj.id}`}
                                onClick={(e) => {
                                  if (teamObj.dissolved) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    toast.error('Team has been dissolved');
                                  }
                                }}
                                className='flex items-center gap-x-4 hover:opacity-75 transition-opacity'>
                            <div className="min-w-[3.5rem] h-14 rounded-lg overflow-hidden border border-lightGray">
                              <img
                                className="h-full w-auto aspect-square object-cover"
                                src={teamObj.logoUrl}
                                alt="entrants-image"
                              />
                            </div>
                            <div className="w-fit">
                              <h2 className={`font-wide font-medium uppercase text-base !leading-6 flex items-center gap-4 transition-colors whitespace-nowrap
                                            text-white
                                            ${qualified && selectedGameView === 0 ? '!text-green' : ''}
                                            ${finalScore && selectedGameView === 0 && prizeColor ? prizeColor : 'text-white'}`}>
                                {teamObj.teamName}
                              </h2>
                              <p className="font-compact text-sm text-steelGray">
                                {teamObj.teamTag}
                              </p>
                            </div>
                          </Link>
                          {/* <div className='h-[20px] w-[1px] bg-lightGray mx-2 mt-[0.35rem]'></div> */}
                          <span className="flex items-center gap-x-2 h-fit mt-[0.35rem]">
                            {teamObj.discordLink ? (
                              <Link target="_blank" to={teamObj.discordLink}>
                                <DiscordIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                              </Link>
                            ) : ''}
                            {teamObj.twitterLink ? (
                              <Link target="_blank" to={teamObj.twitterLink}>
                                <TwitterIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                              </Link>
                            ) : ''}
                            {teamObj.twitchLink ? (
                              <Link target="_blank" to={teamObj.twitchLink}>
                                <TwitchIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                              </Link>
                            ) : ''}
                            {teamObj.websiteLink ? (
                              <Link target="_blank" to={teamObj.websiteLink}>
                                <WebsiteIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                              </Link>
                            ) : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex w-fit md:hidden gap-x-2 items-center -mt-1 ">
                      {teamObj.discordLink ? (
                        <Link target="_blank" to={teamObj.discordLink}>
                          <DiscordIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                        </Link>
                      ) : ''}
                      {teamObj.twitterLink ? (
                        <Link target="_blank" to={teamObj.twitterLink}>
                          <TwitterIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                        </Link>
                      ) : ''}
                      {teamObj.twitchLink ? (
                        <Link target="_blank" to={teamObj.twitchLink}>
                          <TwitchIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                        </Link>
                      ) : ''}
                      {teamObj.websiteLink ? (
                        <Link target="_blank" to={teamObj.websiteLink}>
                          <WebsiteIcon className='w-[18px] h-auto aspect-square transition-[300] fill-steelGray hover:fill-green' />
                        </Link>
                      ) : ''}
                    </div>
                    <div className='flex items-center gap-x-3'>
                      {groupStarted ? (
                        <>
                          {wonMatch ? (
                            <div className={`relative group hidden lg:flex items-center gap-x-2 font-compact uppercase text-base custom_xsm:text-xl font-medium md:font-semibold
                                            text-white px-2 sm:px-6 h-[35px] border border-lightGray rounded-lg`}>
                              <PopoverText visible={true} className='opacity-0 group-hover:opacity-100 transition-opacity'>
                                {stageComplete && selectedGameView === 0 ? 'Match Point Winner' : 'Game Winner'}
                              </PopoverText>
                              <FaCrown className='w-[17px] h-auto aspect-square fill-green'/>
                            </div>
                          ) : ''}

                          <div className={`flex items-center justify-center gap-x-2 font-compact uppercase text-base custom_xsm:text-xl font-medium md:font-semibold
                                          text-white w-[80px] h-[35px] border border-lightGray rounded-lg`}>
                            <img src={skullWhite} alt="skull icon"
                                className='w-[17px] h-auto object-contain'/>
                            <p className={`${finalScore && selectedGameView === 0 && prizeColor ? prizeColor : 'text-white'} font-wide -mb-1`}>
                              {teamKills ? teamKills : 0}
                            </p>
                          </div>

                          <div className={`flex items-center justify-center gap-x-2 font-compact uppercase text-base custom_xsm:text-xl font-medium md:font-semibold mr-4
                                           w-[100px] h-[35px] rounded-lg text-white bg-lightGray`}>
                            {tournament.brand === 'Surfshark' ? (
                              <>
                                <SurfsharkIcon className='w-[24px] h-auto aspect-square fill-white'/>
                                <p className={`${finalScore && selectedGameView === 0 && prizeColor ? prizeColor : ''} font-wide translate-y-[1px]`}>
                                  {teamScore}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className={`${finalScore && selectedGameView === 0 && prizeColor ? prizeColor : ''} font-wide translate-y-[1px]`}>
                                  {teamScore}
                                </p>
                                <p className="font-normal text-base translate-y-[1px]">pts</p>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className={`flex items-center gap-x-2 font-compact uppercase mr-5
                                        text-white px-2 sm:px-12 h-[35px] bg-lightGray rounded-lg`}>
                          <p className="text-white font-normal text-base uppercase">Team slot <em className='not-italic text-green'>{team.groupPosition > 9 ? '' : '0'}{team.groupPosition}</em></p>
                        </div>
                      )}
                      <span className="md:p-9 md:border-l border-lightGray entrants_dropdown_arrow group-hover/card:opacity-50 transition-opacity">
                        <FooterAccordionIcon
                          accordianArrow={
                            open
                              ? "-rotate-180 duration-300"
                              : "rotate-0 duration-300"
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </DisclosureButton>{" "}
            </div>
            <LeaderboardTeamGames team={team} group={group}/>
          </>
        )}
      </Disclosure>
    </div>
  ): '';
}

export default LeaderboardTeamCard;
