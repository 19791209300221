import { useState, useEffect, useCallback } from "react";
import {Link} from 'react-router-dom';
// context
import { useProfileContext } from "../ProfileProvider";
// types
import { DBUser } from "../../../firestore/users";
// utils
import { copyDiscordUsername } from "../../../utils/Socials";
// components
import NoTeamAssociated from "./NoTeamAssociated";
// assets
import imagePlaceholder from '../../../assets/images/placeholder/placeholder.png';
// icons
import { DiscordIcon, TwitterIcon, TwitchIcon, InstagramIcon, YouTubeIcon } from "../../common/icons/Socials";

const TeamPlayers = () => {
  const { editor, profileTeam } = useProfileContext();

  const [profileTeamPlayers, setProfileTeamPlayers] = useState<DBUser[]>([]);

  const getProfileTeamPlayers = useCallback(async () => {
    if (profileTeam) {
      const teamPlayers = await profileTeam.getPlayerObjs!();
      setProfileTeamPlayers(teamPlayers)
    }
  }, [profileTeam])

  useEffect(() => {
    getProfileTeamPlayers();
  }, [profileTeam, getProfileTeamPlayers])

  return (
    <>
      <div className="mt-3">
        <h2 className="font-wide font-bold text-white !leading-5 uppercase mb-6
                       flex items-center gap-x-2">
          <p>Teammates</p>
          {profileTeam ? (
            <em className="text-sm font-semibold text-green not-italic">({profileTeam.teamName})</em>
          ) : ''}
        </h2>
        {/* Mapping through the list of team players */}
        {profileTeam ? (
          <>
            {profileTeamPlayers.map((player: DBUser, index) => (
              <div key={index}>
                <div className="bg-lightBlack p-4 rounded-xl mt-3 sm:mt-2">
                  <div
                    className={`flex justify-between items-center`}>
                      <Link to={`/profile/${player.uid}`}
                            className="flex items-center gap-x-4 hover:opacity-75 transition-opacity">
                        <img
                          className="w-[3rem] max-w-none h-auto aspect-square rounded-full object-cover"
                          src={player.displayImage ? player.displayImage : imagePlaceholder}
                          alt="image"
                        />
                        <div className="flex flex-col items-start w-full gap-y-1">
                          <div className="flex items-center gap-x-2">
                            <h2 className="font-wide text-left font-bold text-white leading-4">
                              {player.displayName}
                            </h2>
                            <p className={`font-compact font-semibold bg-ebonyClay px-2 py-[0.1rem] pb-[0.07rem] text-xs mb-[2px] rounded-full
                                          ${player.uid === profileTeam.captain ? "text-green" : "text-slateGray"}`}>
                              {player.uid === profileTeam.captain ? 'Owner' : 'Player'}
                            </p>
                          </div>
                          <h3 className="font-compact text-sm text-steelGray">
                            @{player.username}
                          </h3>
                        </div>
                      </Link>
                      <div className="flex items-center gap-x-4">
                        <div className='flex items-center gap-x-3'>
                          {player.discord ? (
                              <button type="button" aria-label="copy discord username"
                                      onClick={() => copyDiscordUsername(player.discord)}>
                                <DiscordIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                              </button>
                            ) : ''}
                          {player.twitter ? (
                            <a href={player.twitter} target="_blank">
                              <TwitterIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                            </a>
                          ) : ''}
                          {player.twitch ? (
                            <a href={player.twitch} target="_blank">
                              <TwitchIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                            </a>
                          ) : ''}
                          {player.youtube ? (
                            <a href={player.youtube} target="_blank">
                              <YouTubeIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                            </a>
                          ) : ''}
                          {player.instagram ? (
                            <a href={player.instagram} target="_blank">
                              <InstagramIcon className='w-[20px] aspect-square h-auto fill-white hover:fill-green transition-[300]'/>
                            </a>
                          ) : ''}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            ))}
            <Link to={`/team/${profileTeam.id}`}
                  className="w-full bg-green uppercase font-medium common_green_btn text-base !leading-4 px-6
                  pt-4 pb-3 rounded-xl font-compact block text-center mt-6">
              See team page
            </Link>
          </>
        ) : (
          <NoTeamAssociated editor={editor}/>
        )}
      </div>
    </>
  );
};

export default TeamPlayers;
