import React, { FormEvent, useState, useEffect, ReactNode } from "react";
import { InputCross } from "../common/icons/Common";
import { toast } from "react-toastify";
import { ImSpinner8 } from "react-icons/im";
import { sendEnquiry } from "../../utils/Enquiry";

interface IContactForm {
  purple?: boolean,
  context: string,
  hero?: ReactNode
}

const ContactForm: React.FC<IContactForm> = ({hero, purple, context}) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const [activeField, setActiveField] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const checkForOutsideInputClick: EventListener = (e: Event) => {
    if (e.target) {
      const target = (e.target as HTMLElement);
      if (!target.classList.contains('contactInput') && !target.closest('contactInput')) {
        setActiveField('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', checkForOutsideInputClick);

    return () => document.removeEventListener('click', checkForOutsideInputClick);
  }, [])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const enquiry = {
        name: name,
        email: email,
        message: message,
        context: context,
      }
      const submitPromise = sendEnquiry(enquiry);
      toast.promise(submitPromise, {
        pending: 'Sending message',
        success: 'Your message has been recieved, we will be in touch shortly!',
        error: 'Error sending message'
      })
      await submitPromise;
      setName('');
      setEmail('');
      setMessage('');
      setSubmitting(false);
    } catch (err) {
      toast.error("Error sending message");
    }
  }

  return (
    <div className="px-4 sm:px-8 lg:px-12 max-w-[96rem] mx-auto mt-10 mb-10 lg:mb-20 xl:mb-24 text-white">
      {hero}
      <form onSubmit={handleSubmit} className="mt-10 max-w-[55.5rem] mx-auto">
        <div className="w-full mt-2 mb-4 sm:mb-8 lg:mb-10 xl:mb-14">
          <label
            htmlFor={"name"}
            className="text-base font-normal font-compact leading-6 text-white "
          >
            Your name
          </label>
          <div className="relative w-full h-fit mt-2">
            <input
              onClick={() => setActiveField('name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className={`h-fit contactInput text-sm placeholder:text-sm font-compact font-normal !leading-6 lg:!leading-4 tracking-[0.009rem] outline-none border border-lightGray text-white placeholder:text-steelGray bg-lightBlack w-full rounded-xl py-3 px-4
              ${activeField === 'name'
                  ? `${purple ? '!border-purple' : '!border-green'} border-2 pr-10 placeholder:text-white`
                  : "border-transparent border-2"
                }`}
              type={"text"}
              placeholder={`Your name here`}
              id={"name"}
            />
            {activeField === 'name' && (
              <span
                onClick={() => {
                  setName('');
                  setActiveField('');
                }}
                className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer validation_pop_up_cross_icon"
              >
                <InputCross />
              </span>
            )}
          </div>
        </div>

        <div className="w-full mt-2 mb-4 sm:mb-8 lg:mb-10 xl:mb-14">
          <label
            htmlFor={"email"}
            className="text-base font-normal font-compact leading-6 text-white "
          >
            Your email
          </label>
          <div className="relative w-full h-fit mt-2">
            <input
              onClick={() => setActiveField('email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={`h-fit contactInput text-sm placeholder:text-sm font-compact font-normal !leading-6 lg:!leading-4 tracking-[0.009rem] outline-none border border-lightGray text-white placeholder:text-steelGray bg-lightBlack w-full rounded-xl py-3 px-4
              ${activeField === 'email'
                  ? `${purple ? '!border-purple' : '!border-green'} border-2 pr-10 placeholder:text-white`
                  : "border-transparent border-2"
                }`}
              type={"text"}
              placeholder={`Your email here`}
              id={"email"}
            />
            {activeField === 'email' && (
              <span
                onClick={() => {
                  setEmail('');
                  setActiveField('');
                }}
                className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer validation_pop_up_cross_icon"
              >
                <InputCross />
              </span>
            )}
          </div>
        </div>

        <div className="w-full mt-2 mb-4 sm:mb-8 lg:mb-10 xl:mb-14">
          <label
            htmlFor={"name"}
            className="text-base font-normal font-compact leading-6 text-white "
          >
            Your message
          </label>
          <div className="relative w-full h-fit mt-2">
            <textarea
              onClick={() => setActiveField('message')}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className={`h-fit contactInput text-sm placeholder:text-sm font-compact font-normal !leading-6 lg:!leading-4 tracking-[0.009rem] outline-none border border-lightGray text-white placeholder:text-steelGray bg-lightBlack w-full rounded-xl py-3 px-4
              ${activeField === 'message'
                  ? `${purple ? '!border-purple' : '!border-green'} border-2 pr-10 placeholder:text-white`
                  : "border-transparent border-2"
                }`}
              placeholder={`Your message here`}
              id={"message"}
            />
            {activeField === 'message' && (
              <span
                onClick={() => {
                  setMessage('');
                  setActiveField('');
                }}
                className="absolute right-4 top-4 cursor-pointer validation_pop_up_cross_icon"
              >
                <InputCross />
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-end mt-4 sm:mt-8 lg:mt-10 xl:mt-14">
          <button
            disabled={submitting}
            type="submit"
            className={`flex items-center gap-x-2 justify-center place-end font-compact !leading-4 px-6 pt-[1.063rem] pb-[0.938rem] rounded-xl uppercase ${purple ? 'bg-purple' : 'bg-green'} text-black font-medium hover:opacity-80 disabled:hover:opacity-50 disabled:opacity-50 duration-300 w-full sm:w-auto`}>
            <span>
            Send Message
            </span>
            {submitting ? (
              <ImSpinner8 className="animate-spin"/>
            ) : ""}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
