// context
import { useHeaderContext } from "@provider/HeaderProvider";
// components
import SignupInput from "./SignupInput.tsx";
// icons
import { CrossIcon } from "@icons/Header.tsx";

// SignUp component handles the signup popup and related functionality
const SignUp = () => {
  const { setNavbarState, navbarState } =
    useHeaderContext();

  const closeModal = () => {
    setNavbarState({
      ...navbarState,
      signUpMenuVisible: false,
    })
  }

  return (
    <div className="bg-lightBlack sm:rounded-t-lg sm:rounded-lg flex w-full overflow-y-auto theme_scroll_bar sm:h-[80vh] max-h-[80vh] sm:max-h-[43.75rem]">
      <div className="z-50 relative flex-grow">
        {/* Header of the signup popup with close button */}
        <div className="flex items-center justify-between border-b border-lightGray px-4 py-3">
          <h2 className="font-wide text-white text-base font-bold leading-normal tracking-[0.01rem]">
            SIGN UP
          </h2>
          <span
            className=" cursor-pointer"
            onClick={closeModal}>
            <CrossIcon />
          </span>
        </div>
        {/* The signup form content goes here */}
        <SignupInput closeModal={closeModal}/>
      </div>
    </div>
  );
};

export default SignUp;
