import { Link } from 'react-router-dom';
// utils
import { logEventToAnalytics } from '@utils/Analytics/Analytics';
// components
import SurfsharkIcon from './SurfsharkIcon';

interface ISurfsharkDiscountButton {
  className?: string
}

const SurfsharkDiscountButton: React.FC<ISurfsharkDiscountButton> = ({className}) => {

  const handleClick = () => {
    logEventToAnalytics('surfshark_discount_click', { source: 'tournament_button'});
  }

  return (
    <Link to="https://surfshark.com/vs" target="_blank"
          onClick={handleClick}
          className={`bg-surfsharkTeal rounded-xl py-2 px-[50px] flex items-center justify-center gap-x-2 hover:opacity-75 transition-opacity ${className}`}>
      <span className="font-compact text-white font-medium uppercase translate-y-[1px]">Surfshark Discount</span>
      <SurfsharkIcon className="w-[16px] h-auto aspect-square fill-white"/>
    </Link>
  )
};

export default SurfsharkDiscountButton;
