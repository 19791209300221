import { ReactNode, createContext, useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
// context
import { useAuthContext } from "../../provider/AuthContextProvider";
// firebase
import { firestore } from "../../firebase";
import { collection, where, query, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
// types
import { DBUser, userConverter } from "../../firestore/users";
import { DBTeam, teamConverter } from "../../firestore/teams";
import { TournamentTrophy, tournamentTrophyConverter } from "../../firestore/tournamentTrophies";

interface IProfileContext {
  profile: DBUser | null,
  profileTeam: DBTeam | null,
  profileTrophies: TournamentTrophy[],
  editor: boolean,
}

const ProfileContext = createContext<IProfileContext>({ profile: null, profileTeam: null, profileTrophies: [], editor: false });

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (!context) {
    console.error("useProfileContext must be used within ProfileProvider");
  }
  return context;
}

interface IProfileProvider {
  children: ReactNode,
  handleProfileChange: (profile: DBUser | null) => void,
}

const ProfileProvider: React.FC<IProfileProvider> = ({children, handleProfileChange}) => {
  const params = useParams();
  const { userObj } = useAuthContext();

  const [profile, setProfile] = useState<DBUser | null>(null);
  const [profileTeam, setProfileTeam] = useState<DBTeam | null>(null);
  const [profileTrophies, setProfileTrophies] = useState<TournamentTrophy[]>([]);
  const [editor, setEditor] = useState<boolean>(false);

  const getProfile = useCallback(() => {
    let unsubscribe: (Unsubscribe | (() => void)) = () => false;
    const userIdOrName = params.id_or_name;
    if (userIdOrName) {
      const usersCollection = collection(firestore, 'users');
      let userQuery;
      if (/([a-zA-Z0-9]{20})/.test(userIdOrName)) {
        // in this query __name__ actually refers to the document ID
        userQuery = query(usersCollection, where('__name__', '==', userIdOrName)).withConverter(userConverter);
      } else {
        const lowercaseName = userIdOrName.toLowerCase();
        const startName = lowercaseName;
        const endName = lowercaseName + '\uf8ff';
        userQuery = query(
          usersCollection,
          where('displayName', '>=', startName),
          where('displayName', '<=', endName)
        ).withConverter(userConverter);
      }
      unsubscribe = onSnapshot(userQuery, (snapshots) => {
        if (!snapshots.empty) {
          const userObj = snapshots.docs[0].data() as DBUser;
          setProfile(userObj);
        }
      })
    } else if (userObj) {
      setProfile(userObj);
    }
    return unsubscribe;
  }, [userObj, params.id_or_name])

  const getProfileTeam = useCallback(() => {
    let unsubscribe: Unsubscribe | (() => void) = () => false;
    if (profile && profile.team) {
      const teamUid = profile.team;
      const teamRef = doc(firestore, 'teams', teamUid).withConverter(teamConverter);
      unsubscribe = onSnapshot(teamRef, (snapshot) => {
        const localTeam = snapshot.data()!;
        setProfileTeam(localTeam);
      })
    }
    return unsubscribe;
  }, [profile])

  const getProfileTrophies = () => {
    let unsubscribe: () => void  = () => false;
    if (profile) {
      const trophiesCollection = collection(firestore, 'tournamentTrophies').withConverter(tournamentTrophyConverter);
      const q = query(trophiesCollection, where('players', 'array-contains', profile.uid));
      unsubscribe = onSnapshot(q, (snapshots) => {
        const trophies = snapshots.docs.map((doc) => doc.data());
        const orderTrophies = trophies.sort((a, b) => a.position - b.position);
        setProfileTrophies(orderTrophies);
      })
    }
    return unsubscribe;
  }

  const checkIfEditor = useCallback(() => {
    if (userObj && profile) {
      setEditor(userObj.uid === profile.uid)
    }
  }, [userObj, profile])

  useEffect(() => {

  })

  useEffect(() => {
    handleProfileChange(profile);
    const teamUnsubscribe = getProfileTeam();
    checkIfEditor();
    const trophyUnsubscribe = getProfileTrophies();

    return () => {
      teamUnsubscribe();
      trophyUnsubscribe();
    };
  }, [profile, checkIfEditor, getProfileTeam, handleProfileChange])

  useEffect(() => {
    const unsubscribe = getProfile()

    return () => {
      unsubscribe();
      setProfile(null);
    };
  }, [userObj, params.id_or_name, getProfile])

  return (
    <ProfileContext.Provider value={{ profile: profile, profileTeam: profileTeam, profileTrophies: profileTrophies, editor: editor }}>
      {children}
    </ProfileContext.Provider>
  )
}

export default ProfileProvider;
