// context
import { useProfileInfoContext } from "@components/completeProfile/ProfileInfoProvider";
// icons
import { FaPlus } from "react-icons/fa";

interface INoBio {
  editor: boolean,
}

const NoBio: React.FC<INoBio> = ({editor}) => {
  const { setCurrentModalStep } = useProfileInfoContext();
  return (
    <div className="border border-lightBlack rounded-xl py-[2.75rem] font-compact
                    flex flex-col items-center">
      <h4 className="font-wide text-xl sm:text-2xl uppercase font-bold text-steelGray">
       No bio added
      </h4>
      <p className="mt-2 font-compact font-medium text-sm tracking-[0.01rem] mb-6 text-steelGray">
        {editor ? 'You haven\'t added a bio yet.' : 'User has not added a bio'}
      </p>
      {editor ? (
          <button type="button"
                  aria-label="add sponsor logos"
                  onClick={() => setCurrentModalStep(1)}
                  className="flex items-center p-2 gap-x-2 transition-opacity font-semibold text-steelGray border-2 border-steelGray/20 rounded-lg hover:opacity-75 w-fit">
            <p className="font-wide">Add</p>
            <FaPlus />
          </button>
      ) : ''}
    </div>
  )
}

export default NoBio;
