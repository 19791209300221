export const formatDate = (date: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // add 0 infront of day number's with less than 2 chars ex: '9' => '09'
  const day = String(date.getDate()).padStart(2, '0');
  // get mini month string from months array
  const month = months[date.getMonth()];
  // get full year number
  const year = date.getFullYear();

  // expected format: 'DD MMM YYYY'
  return `${day} ${month} ${year}`;
}

export const formatDateTime = (date: Date) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // add 0 infront of day number's with less than 2 chars ex: '9' => '09'
  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const hoursRaw = date.getHours().toString();
  const hours = hoursRaw.length > 1 ? hoursRaw : `0${hoursRaw}`;

  const minutesRaw = date.getMinutes().toString();
  const minutes = minutesRaw.length > 1 ? minutesRaw : `0${minutesRaw}`;


  // expected format: 'DD MMM YYYY'
  return `${day} ${month} ${year} @ ${hours}:${minutes}`;
}

export const getTimezone = (): string => {
  const date = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const options = {
    timeZone,
    timeZoneName: 'short' as 'short' | 'long',
  };

  const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
  const [{ value: timeZoneName }] = dateTimeFormat.formatToParts(date).filter(part => part.type === 'timeZoneName');

  const timeZoneSpaceFormatted = timeZoneName.replace('+', ' + ');
  return timeZoneSpaceFormatted;
}

export const formatDateCountdown = (targetDate: Date): string => {
  const currentDate = new Date();
  const timeDifference = targetDate.getTime() - currentDate.getTime(); // milliseconds

  if (timeDifference > 0) {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    let timeUntilDate = '';
    if (days > 0) {
      timeUntilDate += `${days}d `;
    }
    if (hours > 0) {
      timeUntilDate += `${hours}h `;
    }
    if (minutes > 0) {
      timeUntilDate += `${minutes}m `;
    }
    return timeUntilDate;
  } else {
    return ''
  }

};

export const formatDateCountdownLong = (targetDate: Date): string => {
  const currentDate = new Date();
  const timeDifference = targetDate.getTime() - currentDate.getTime(); // milliseconds

  if (timeDifference > 0) {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    // const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    let timeUntilDate = '';
    if (days > 0) {
      timeUntilDate += `${days} days `;
    }
    if (hours > 0) {
      timeUntilDate += `${hours} hours `;
    }
    if (minutes > 0) {
      timeUntilDate += `${minutes} mins `;
    }
    return timeUntilDate;
  } else {
    return ''
  }
};

export const formatTimeElapsed = (eventTime: Date, currentTime?: number): string => {
  const timeDifference = (currentTime ? currentTime : new Date().getTime()) - eventTime.getTime();

  if (timeDifference > 0) {
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let timeElapsedString = '';
    if (days > 0) {
      timeElapsedString = `${days} days `;
    } else if (hours > 0) {
      timeElapsedString = `${hours} hours `;
    } else if (minutes > 0) {
      timeElapsedString = `${minutes} mins `;
    } else {
      timeElapsedString = `Just now`;
    }
    return timeElapsedString;
  } else {
    return '';
  }
}

export const getAgeFromDateOfBirth = (dateOfBirth: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDifference = today.getMonth() - dateOfBirth.getMonth();
  const dayDifference = today.getDate() - dateOfBirth.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
  }
  return age;
};
