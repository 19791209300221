import { useContext } from 'react';
// context
import { TeamInfoContext } from '../TeamInfoProvider';
// components
import UploadLogo from "./UploadLogo";
// icons
import { CrossIcon } from "../../common/icons/FilterIcons";
import { BlackArrow } from "../../common/icons/Common";
import { CreateBackArrowIcon, RightCheckIcon } from "../../common/icons/ProfileIcons";

interface IProps {
  closeModal: () => void,
  changeModalStep: ( direction: -1 | 1) => void,
}

const CreateTeamLogo = (props: IProps) => {
  const { edit } = useContext(TeamInfoContext);

  const { changeModalStep, closeModal } = props;

  const handleStepSubmit = () => {
    changeModalStep(1);
  }

  return (
    <div className="w-full mx-auto h-[60vh] overflow-hidden !bg-lightBlack flex flex-col">
      <div className="px-4 py-3 border-b-[0.063rem] border-lightGray">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <span
              className="cursor-pointer"
              onClick={() => {
                changeModalStep(-1)
              }}>
              <CreateBackArrowIcon />
            </span>
            <h5 className="text-white font-bold font-wide text-base leading-[100%] uppercase pt-1">
              {edit ? 'Edit Team' : 'Create team'}
            </h5>
          </div>
          <span
            className="cursor-pointer"
            onClick={() => {
              closeModal();
            }}>
            <CrossIcon />
          </span>
        </div>
      </div>
      <div className="h-1/2 flex-grow overflow-scroll maskedListVert py-[20px]">

        <div className="w-full p-6 ">
          <div className="flex gap-[1.375rem] items-center justify-center">
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center">
              <RightCheckIcon />
            </div>
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center progress_tricks_line after:!bg-green">
              <RightCheckIcon />
            </div>
            <div className="bg-green w-[1.5rem] h-[1.5rem] rounded-full flex justify-center items-center progress_tricks_line after:!bg-green">
                <RightCheckIcon />
              </div>
            <div className="outline-[0.188rem] -outline-offset-[0.188rem] outline outline-green w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-green bg-lightBlack"></div>
            <div className="outline-[0.125rem] -outline-offset-[0.125rem] outline outline-ebonyClay w-[1.5rem] h-[1.5rem] rounded-full progress_tricks_line after:!bg-ebonyClay bg-lightBlack"></div>
          </div>
          <h2 className="text-steelGray pt-6 !text-center pb-4 font-compact flex justify-start items-center gap-x-2">
            <span>Sponsors Logos</span>
            <p className="text-sm opacity-80 mb-[0.5px]">(optional)</p>
          </h2>
          <UploadLogo/>
        </div>
      </div>
      <div className="py-3 px-4 border-t-[0.063rem] border-lightGray">
        <button
          onClick={() => handleStepSubmit()}
          className="flex justify-center items-center font-compact w-full text-base font-medium py-[0.938rem] !leading-4 rounded-xl uppercase text-center common_green_btn gap-2"
        >
          next{" "}
          <span className="pb-[0.125rem]">
            <BlackArrow />
          </span>
        </button>
      </div>
    </div>
  );
};

export default CreateTeamLogo;
