import { Link } from 'react-router-dom';
// context
import { useDateTimeContext } from '@provider/DateTimeProvider';
// types
import { Notification } from '@src/firestore/notifications';
// utils
import { formatTimeElapsed } from '@utils/Date';
// icons
import { FaXmark } from 'react-icons/fa6';
import { MegaphoneIcon } from '@components/common/icons/Common';
import SurfsharkIcon from '@components/brands/surfshark/SurfsharkIcon';

interface INotification {
  notification: Notification,
  dismissNotification: (notification: Notification) => void,
  closeMenu: () => void,
}

const TournamentSlotAssignedNotif: React.FC<INotification> = ({notification, closeMenu, dismissNotification}) => {
  const { currentTime } = useDateTimeContext();

  const body = notification.body as {
    tournamentId: string,
    tournamentName: string,
    brand: string
  };

  const { tournamentId, tournamentName, brand } = body;

  const elapsedTime = formatTimeElapsed(notification.timeReceived, currentTime);

  return (
    <div className="relative bg-lightGray rounded-xl p-4 pr-10" key={notification.id}>
      <button type="button"
              aria-label='dismiss notification'
              onClick={() => dismissNotification(notification)}
              className="absolute top-1 right-1 p-1 fill-ebonyClay hover:opacity-75 transition-opacity">
        <FaXmark className='w-[20px] h-auto aspect-square fill-inherit'/>
      </button>
      <div className="flex items-start gap-x-3">
        <div className='p-[0.55rem] rounded-full bg-steelGray/30'>
          {brand === 'Surfshark' ? (
            <SurfsharkIcon className='w-[18px] h-auto aspect-square fill-white'/>
          ) : (
            <MegaphoneIcon className='w-[16px] h-auto aspect-square fill-white'/>
          )}
        </div>
        <div>
          <p className="text-white font-compact whitespace-nowrap">
            {brand === 'Surfshark' ? (
              <>
                <Link to={`/tournaments/${tournamentId}`}
                      onClick={closeMenu}
                      className='uppercase text-green hover:text-gorse transition-colors'>
                  {tournamentName}
                </Link>
                <span> announced!</span>
              </>
            ) : (
              <>
                <span>New</span>
                <Link to={`/tournaments/${tournamentId}`}
                      onClick={closeMenu}
                      className='uppercase text-green hover:text-gorse transition-colors'>
                  {' Apex '}
                </Link>
                <span className='font-thin'>tournament announced!</span>
              </>
            )}
          </p>
          <p className='text-sm font-compact text-steelGray'>
            {elapsedTime === 'Just now' ? elapsedTime : `${elapsedTime} ago`}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TournamentSlotAssignedNotif;
