import { MouseEvent, useEffect, useRef, useState, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
// types
import { DBTeam, PlayerData } from '@src/firestore/teams';
import { ApexMapInfos, ApexMapMeasurements } from '@customTypes/Games/ApexLegends';
// libraries
import { FullGestureState, useDrag, useWheel } from '@use-gesture/react';
import { Transition, TransitionChild } from '@headlessui/react';
// utils
import { gameInputStringToIcon, platformStringToIcon } from '@utils/Players/Icons';
import { getAgeFromDateOfBirth } from '@utils/Date';
import { getLegendHeadshot, getWeaponImage } from '@src/utils/Games/ApexLegends';
// components
import PopoverText from '@ui/PopoverText';
import CheckBox from '@ui/CheckBox';
// assets
import placeholderImage from '@assets/images/placeholder/placeholder.png';
import respawnBeacon from '@assets/images/apex/icons/respawnBeacon.png';
import surveyBeacon from '@assets/images/apex/icons/surveyBeacon.png';
import ringConsole from '@assets/images/apex/icons/ringConsole.png';
import jumpTower from '@assets/images/apex/icons/jumpTower.png';
import trident from '@assets/images/apex/icons/trident.png';
// icons
import { SlidersIcon, TargetIcon } from '@icons/Common';
import { TrophyBronzeImage, TrophyGoldImage, TrophySilverImage } from '@icons/TournamentIcons';

interface IApexMapLabel {
  coords: {x: number, y: number},
  rotation?: number,
  mapMeasurements: ApexMapMeasurements,
  children: ReactNode,
  className?: string,
  popoverText?: string,
  priority?: 1 | 2 | 3,
  hidden?: boolean,
  notInteractive?: boolean,
}

const ApexMapLabel: React.FC<IApexMapLabel> = ({coords, mapMeasurements, children, className, popoverText, hidden, priority, notInteractive, rotation}) => {
  const getPositionStylesFromCoordinates = useCallback((coords: {x: number, y: number}) => {
    const { x, y} = coords;

    const xPercentageOffset = x * mapMeasurements.x.coordinateToWidth;
    const yPercentageOffset = -(y * mapMeasurements.y.coordinateToWidth);

    return {
      left: `${50+xPercentageOffset}%`,
      top: `${50+yPercentageOffset}%`,

    };
  }, [mapMeasurements]);

  return (
    <div className={`${className} group absolute -translate-x-1/2 -translate-y-1/2 z-[${priority ? 4 - priority : 1}] ${!notInteractive ? 'hover:z-[4]' : ''}
                     w-fit h-auto aspect-square rounded-full
                     ${hidden ? 'pointer-events-none touch-none opacity-0' : ''} transition-all`}
    style={{...getPositionStylesFromCoordinates(coords), rotate: `${rotation ?? 0}deg`}}>
      {children}
      {popoverText ? (
        <PopoverText visible={true} className='opacity-0 group-hover:opacity-100'>
          {popoverText}
        </PopoverText>
      ) : ''}
    </div>
  );
}

interface IApexMap {
  map: 'WE' | 'SP' | 'ED',
  allocationsReady?: boolean,
  teamMapLabels?: {
    teamId: string,
    teamName: string,
    teamTag: string,
    teamLogo: string,
    participatingPlayerData: PlayerData[],
    DBTeam: DBTeam,
    poiAllocation: number
  }[]
}

const mapImages: Record<string, HTMLImageElement> = {};
const maps = ['WE', 'SP', 'ED'];

for (const map of maps) {
  const image = new Image();
  const imageSrc = ApexMapInfos[map].image;
  image.src = imageSrc;
  image.onload = () => `${map} image loaded`;
  mapImages[map] = image;
}

const ApexMap: React.FC<IApexMap> = ({ map, teamMapLabels, allocationsReady}) => {
  const [poiLabelsVisible, setPoiLabelsVisible] = useState<boolean>(true);
  const [respawnBeaconsVisible, setRespawnBeaconsVisible] = useState<boolean>(true);
  const [surveyBeaconsVisible, setSurveyBeaconsVisible] = useState<boolean>(false);
  const [ringConsolesVisible, setRingConsolesVisible] = useState<boolean>(false);
  const [jumpTowersVisible, setJumpTowersVisible] = useState<boolean>(false);
  const [tridentsVisible, setTridentsVisible] = useState<boolean>(false);

  const imageRef = useRef<HTMLImageElement | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapContainerWidth = mapContainerRef.current?.offsetWidth ?? 0;
  const [mapContainerOffset, setMapContainerOffset] = useState<[x: number, y: number]>([0, 0]);

  const [zoomLevel, setZoomLevel] = useState<number>(110);


  const [visible, setVisible] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);

  const [showcaseTeam, setShowcaseTeam] = useState<{
    teamId: string,
    teamName: string,
    teamTag: string,
    teamLogo: string,
    participatingPlayerData: PlayerData[],
    DBTeam: DBTeam,
    poiAllocation: number
  } | null>(null);

  const showcaseTeamGoldTrophies = showcaseTeam?.DBTeam.trophyData.filter((trophy) => trophy.position === 1) ?? [];
  const showcaseTeamSilverTrophies = showcaseTeam?.DBTeam.trophyData.filter((trophy) => trophy.position === 2) ?? [];
  const showcaseTeamBronzeTrophies = showcaseTeam?.DBTeam.trophyData.filter((trophy) => trophy.position === 3) ?? [];

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  useEffect(() => {
    setVisible(false);

    let transform: string = '';

    switch (map) {
      case 'WE':
        setZoomLevel(105);
        transform = `translate(-10px, -40px)`;
        break;
      case 'SP':
        setZoomLevel(105);
        transform = `translate(-10px, 10px)`;
        break;
      case 'ED':
        setZoomLevel(110);
        transform = `translate(25px, -25px)`;
        break;
    }

    if (mapContainerRef.current) {
      mapContainerRef.current.style.transform = transform;
    }

    setTimeout(() => {
      setVisible(true);
    }, 150);
  }, [map]);

  const mapInfo = ApexMapInfos[map];
  const mapMeasurements = mapInfo.measurements;

  const width = imageRef.current?.offsetWidth ?? 0;
  const height = imageRef.current?.offsetHeight ?? 0;

  const repositionMap = useCallback((options: {newOffset?: [x: number, y: number], newZoomLevel?: number}) => {
    const { newOffset, newZoomLevel } = options;

    let offset = mapContainerOffset;
    if (newOffset) offset = newOffset;
    setMapContainerOffset(offset);

    let zoom = zoomLevel;
    if (newZoomLevel) zoom = newZoomLevel;

    const [x, y] = offset;
    const zoomMultiplier = zoom / 100;

    const xShift = x !== 0 ? (x / zoomMultiplier) : 0;
    const yShift = y !== 0 ? (y / zoomMultiplier) : 0;

    const mapScale = mapContainerWidth / 634;

    const shiftBoundary = mapScale * 500;

    const boundariedXShift = xShift > 0 ? Math.min(xShift, shiftBoundary) : Math.max(xShift, -shiftBoundary);
    const boundariedYShift = yShift > 0 ? Math.min(yShift, shiftBoundary) : Math.max(yShift, -shiftBoundary);

    if (mapContainerRef.current) {
      const transform = `translate(${boundariedXShift}px, ${boundariedYShift}px)`;
      mapContainerRef.current.style.transform = transform;
    }
  }, [mapContainerOffset, mapContainerWidth, zoomLevel]);

  type DragState = Omit<FullGestureState<"drag">, "event"> & {
    event: PointerEvent | globalThis.MouseEvent | TouchEvent | KeyboardEvent;
  }

  type WheelState = Omit<FullGestureState<"wheel">, "event"> & { event: PointerEvent | MouseEvent | TouchEvent | KeyboardEvent; };

  const dragStateHandler = useCallback((state: DragState) => {
    const { offset, dragging} = state;
    repositionMap({newOffset: offset});
    setDragging(dragging ?? false);
  }, [repositionMap]);

  const wheelStateHandler = useCallback((state: WheelState) => {
    const { direction: [, yDirection] } = state;
    let newZoomLevel: number;

    if (yDirection === -1) {
      console.log(yDirection, 'positive');
      newZoomLevel = Math.max(zoomLevel + 25, 70);
    } else {
      newZoomLevel = Math.max(zoomLevel - 25, 70);
    }

    setZoomLevel(newZoomLevel);
    repositionMap({newZoomLevel});
  }, [repositionMap, zoomLevel]);

  const getCoordsForMouseCursor = (e: MouseEvent<HTMLDivElement>) => {
    console.log(e);
    console.clear();
    const xOffset = (e.nativeEvent?.offsetX as number / width) * 100;
    const yOffset = (e.nativeEvent?.offsetY as number / height) * 100;
    const coords = {x: mapMeasurements.x.widthToCoordinate * (xOffset - 50), y: mapMeasurements.y.widthToCoordinate * (50 - yOffset)};
    console.log('Latest coordinates for mouse position:')
    console.log(coords);
  }

  const dragBind = useDrag(dragStateHandler);
  const wheelBind = useWheel(wheelStateHandler);

  return (
    <Transition show={visible} appear={true} unmount={false}>
      <div className='relative flex flex-col gap-y-4'>
        <button type='button' aria-label='Open filters menu'
                onClick={() => setFiltersOpen(true)}
                onMouseOver={() => setFiltersOpen(true)}
                className={`absolute top-5 left-5 w-[30px] h-auto aspect-square bg-lightBlack border border-steelGray rounded-xl
                            hover:opacity-75 flex items-center justify-center z-[3]
                            ${filtersOpen ? 'opacity-0 pointer-events-none touch-none' : ''} transition-all`}>
          <SlidersIcon className='w-[14px] h-auto aspect-square stroke-green'/>
        </button>
        <div className={`absolute top-5 left-5 w-fit p-4 h-fit border border-steelGray bg-lightBlack rounded-xl z-[3]
                        flex flex-col gap-y-2 justify-start overflow-x-scroll
                        ${filtersOpen ? '' : 'opacity-0 pointer-events-none touch-none'} transition-opacity`}
             onMouseLeave={() => setFiltersOpen(false)}>
          {/* <button type='button' aria-label='Close filters menu'
                  onClick={() => setFiltersOpen(false)}
                  className='absolute top-1 right-1 w-[20px] h-auto aspect-square'>
            <FaXmark className='w-[14px] h-auto aspect-square fill-steelGray hover:fill-white transition-colors'/>
          </button> */}
          <button type='button'
                  onClick={() => setPoiLabelsVisible(!poiLabelsVisible)}
                  className={`px-4 py-2 text-white font-compact uppercase text-sm rounded-lg whitespace-nowrap
                            flex items-center justify-between gap-x-3 hover:opacity-75 bg-lightGray transition-all`}>
            <span className="flex items-center gap-x-2">
              <TargetIcon className='w-[12px] h-auto aspect-square fill-white'/>
              <span>
                POI Labels{teamMapLabels ? '/Teams' : ''}
              </span>
            </span>
            <CheckBox selected={poiLabelsVisible} setSelected={() => false} asDiv={true} small={true}/>
          </button>

          <button type='button'
                  onClick={() => setRespawnBeaconsVisible(!respawnBeaconsVisible)}
                  className={`px-4 py-2 text-white font-compact uppercase text-sm rounded-lg whitespace-nowrap
                            flex items-center justify-between gap-x-3 hover:opacity-75 bg-lightGray transition-all`}>
            <span className="flex items-center gap-x-2">
              <img src={respawnBeacon} alt='Respawn beacon icon'
                   className='w-[16px] h-auto aspect-square fill-white'/>
              <span>
                Respawn Beacons
              </span>
            </span>
            <CheckBox selected={respawnBeaconsVisible} setSelected={() => false} asDiv={true} small={true}/>
          </button>

          <button type='button'
                  onClick={() => setSurveyBeaconsVisible(!surveyBeaconsVisible)}
                  className={`px-4 py-2 text-white font-compact uppercase text-sm rounded-lg whitespace-nowrap
                            flex items-center justify-between gap-x-3 hover:opacity-75 bg-lightGray transition-all`}>
            <span className="flex items-center gap-x-2">
              <img src={surveyBeacon} alt='Survey beacon icon'
                   className='w-[12px] scale-125 h-auto aspect-square fill-white'/>
              <span>
                Survey Beacons
              </span>
            </span>
            <CheckBox selected={surveyBeaconsVisible} setSelected={() => false} asDiv={true} small={true}/>
          </button>

          <button type='button'
                  onClick={() => setRingConsolesVisible(!ringConsolesVisible)}
                  className={`px-4 py-2 text-white font-compact uppercase text-sm rounded-lg whitespace-nowrap
                            flex items-center justify-between gap-x-3 hover:opacity-75 bg-lightGray transition-all`}>
            <span className="flex items-center gap-x-2">
              <img src={ringConsole} alt='Ring console icon'
                   className='w-[12px] scale-125 h-auto aspect-square fill-white'/>
              <span>
                Ring Consoles
              </span>
            </span>
            <CheckBox selected={ringConsolesVisible} setSelected={() => false} asDiv={true} small={true}/>
          </button>

          <button type='button'
                  onClick={() => setJumpTowersVisible(!jumpTowersVisible)}
                  className={`px-4 py-2 text-white font-compact uppercase text-sm rounded-lg whitespace-nowrap
                            flex items-center justify-between gap-x-3 hover:opacity-75 bg-lightGray transition-all`}>
            <span className="flex items-center gap-x-2">
              <img src={jumpTower} alt='Jump tower icon'
                   className='w-[12px] scale-125 h-auto aspect-square fill-white'/>
              <span>
                Jump Towers
              </span>
            </span>
            <CheckBox selected={jumpTowersVisible} setSelected={() => false} asDiv={true} small={true}/>
          </button>

          {mapInfo.tridentCoords.length > 0 ? (
            <button type='button'
                    onClick={() => setTridentsVisible(!tridentsVisible)}
                    className={`px-4 py-2 text-white font-compact uppercase text-sm rounded-lg whitespace-nowrap
                              flex items-center justify-between gap-x-3 hover:opacity-75 bg-lightGray transition-all`}>
              <span className="flex items-center gap-x-2">
                <img src={trident} alt='Jump tower icon'
                    className='w-[12px] scale-125 h-auto aspect-square fill-white'/>
                <span>
                  Tridents
                </span>
              </span>
              <CheckBox selected={tridentsVisible} setSelected={() => false} asDiv={true} small={true}/>
            </button>
          ) : ''}
        </div>

        <div className={`absolute top-5 right-5 w-[250px] p-4 h-fit border border-steelGray bg-lightBlack rounded-xl z-[3]
                        flex flex-col gap-y-2 justify-start overflow-x-scroll pointer-events-none touch-none scale-[85%] origin-top-right
                        ${showcaseTeam ? 'transition-opacity delay-[100]' : 'opacity-0'}`}>
          {showcaseTeam ? (
            <div className='flex flex-col gap-y-3'>
              <div className='flex items-center gap-x-4'>
                <img src={showcaseTeam.DBTeam.logoUrl} alt={`${showcaseTeam.teamName} logo`}
                     className='w-[42px] h-auto aspect-square overflow-hidden rounded-lg object-cover'/>
                <div className='flex flex-col items-start'>
                  <h3 className={`text-white font-wide uppercase font-semibold w-fit
                                    ${showcaseTeam.teamName.length >= 15 ? 'text-sm' : 'text-lg'}`}>
                    {showcaseTeam.teamName}
                  </h3>
                  <p className={`font-compact text-steelGray uppercase font-medium w-fit
                                ${showcaseTeam.teamName.length >= 15 ? 'text-xs' : 'text-base -mt-1'}`}>
                    {showcaseTeam.teamTag}
                  </p>
                </div>
              </div>

              <div className='flex items-center gap-x-3'>
                <div className='flex items-center gap-x-2'>
                  <div className={`relative w-[36px] h-auto aspect-square ${showcaseTeamGoldTrophies.length > 0 ? '' : 'opacity-50'}`}>
                    {showcaseTeamGoldTrophies.length > 0 ? (
                      <p className='absolute top-0 right-[1px] w-[14px] h-[14px] border border-lightGray bg-lightBlack rounded-full text-white font-compact text-xs
                                    flex items-center justify-center'>
                        <span className='translate-x-[1px] translate-y-[1px]'>
                          {showcaseTeamGoldTrophies.length}
                        </span>
                      </p>
                    ) : ''}
                    <TrophyGoldImage className='w-full h-full'/>
                  </div>

                  <div className={`relative w-[36px] h-auto aspect-square ${showcaseTeamSilverTrophies.length > 0 ? '' : 'opacity-50'}`}>
                    {showcaseTeamSilverTrophies.length > 0 ? (
                      <p className='absolute top-0 right-[1px] w-[14px] h-[14px] border border-lightGray bg-lightBlack rounded-full text-white font-compact text-xs
                                    flex items-center justify-center'>
                        <span className='translate-x-[1px] translate-y-[1px]'>
                          {showcaseTeamSilverTrophies.length}
                        </span>
                      </p>
                    ) : ''}
                    <TrophySilverImage className='w-full h-full'/>
                  </div>

                  <div className={`relative w-[36px] h-auto aspect-square ${showcaseTeamBronzeTrophies.length > 0 ? '' : 'opacity-50'}`}>
                    {showcaseTeamBronzeTrophies.length > 0 ? (
                      <p className='absolute top-0 right-[1px] w-[14px] h-[14px] border border-lightGray bg-lightBlack rounded-full text-white font-compact text-xs
                                    flex items-center justify-center'>
                        <span className='translate-x-[1px] translate-y-[1px]'>
                          {showcaseTeamBronzeTrophies.length}
                        </span>
                      </p>
                    ) : ''}
                    <TrophyBronzeImage className='w-full h-full'/>
                  </div>
                </div>
                <div className='relative flex flex-col justify-start w-fit ml-[10px]'>
                  <div className='absolute left-0 top-1/2 -translate-x-[10px] -translate-y-1/2 h-full w-[1px] bg-lightGray'></div>
                  <div className='py-1'>
                    <p  className='text-white font-wide text-xs uppercase font-semibold w-fit'>Winnings</p>
                    <p  className='text-green font-wide text-xs uppercase font-semibold w-fit'>{showcaseTeam.DBTeam.totalWinnings > 0 ? `$${showcaseTeam.DBTeam.totalWinnings}` : 'n/a'}</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-x-2">
                <div className='flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[50%] flex-shrink rounded-xl py-1 pb-[2px] px-6 transition-colors'>
                  <p className='text-white font-compact text-sm whitespace-nowrap'>Match Wins</p>
                  <p className='text-green font-wide font-semibold text-xs'>{showcaseTeam.DBTeam.apexMatchNum > 0 ? showcaseTeam.DBTeam.apexMatchWins : 'N/A' }</p>
                </div>

                <div className='flex flex-col gap-y-1 items-center bg-lightGray group-hover:bg-ebonyClay w-[50%] flex-shrink rounded-xl py-1 pb-[2px] px-6 transition-colors'>
                  <p className='text-white font-compact text-sm whitespace-nowrap'>Win Rate</p>
                  <p className='text-green font-wide font-semibold text-xs'>{showcaseTeam.DBTeam.apexMatchNum > 0 ? `${showcaseTeam.DBTeam.apexMatchWinrate}%` : 'N/A' }</p>
                </div>
              </div>

              <div className='flex flex-col gap-y-4 w-full mt-1'>
                {showcaseTeam.participatingPlayerData.map((player) => (
                  <div className='flex items-center gap-x-6 justify-between'>
                    <div className='flex items-center gap-x-3'>
                      <img src={player.displayImage !== '' ? player.displayImage : placeholderImage} alt={`${player.displayName} image`}
                          className='w-[30px] h-auto aspect-square object-cover rounded-full overflow-hidden border border-lightGray flex-shrink-0'/>

                      <div className='flex flex-col'>
                        <p  className='text-white font-wide text-[0.74rem] uppercase font-semibold w-fit whitespace-nowrap'>{player.displayName.length > 9 ? `${player.displayName.substring(0, 9)}...` : player.displayName}</p>
                        <div className='flex items-center gap-x-3'>
                          <div className={`w-[10px] opacity-75 fi fi-${player.nationality}`}></div>
                          {player.showAge ? (
                            <p className="text-steelGray text-xs group-hover:text-white/65 font-compact font-medium -mb-1 transition-colors">{getAgeFromDateOfBirth(player.dateOfBirth)}</p>
                          ) : ''}
                          {platformStringToIcon(player.platform, '!w-[8px] h-auto aspect-square')}
                          {gameInputStringToIcon(player.input, '!w-[8px] h-auto aspect-square')}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center gap-x-2">
                      <img src={getWeaponImage(player.apexFavouriteWeapon)} alt={`${player.apexFavouriteWeapon} weapon image`}
                           className='max-w-[50px] max-h-[20px] h-auto object-contain flex-shrink-0'/>
                      <img src={getLegendHeadshot(player.apexMainLegend)} alt={`${player.apexMainLegend} legend image`}
                           className='w-[30px] h-auto object-cover rounded-lg border border-lightGray overflow-hidden flex-shrink-0'/>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : ''}
        </div>

        <div className='border border-steelGray rounded-2xl overflow-hidden sm:max-w-[500px] custom_xl:max-w-[625px]'>
          <div  ref={mapContainerRef}
                className={`relative  user-select-none touch-none origin-center
                          ${dragging ? 'cursor-grabbing' : ''}`}
                style={{scale: `${zoomLevel}%`}}
                {...dragBind()}
                {...wheelBind()}
                onMouseMove={(e) => {
                  if (import.meta.env.VITE_ENV !== 'production') {
                    getCoordsForMouseCursor(e);
                  }
                }}>

            <TransitionChild unmount={false}>
              <div className='transition duration-[300] delay-[300] data-[closed]:opacity-0 data-[leave]:transition-none'>
                {mapInfo.pois.map((poi) => {
                  const allocatedTeam = teamMapLabels ? teamMapLabels.find((team) => team.poiAllocation === poi.poiNumber) : null;
                  return (
                    <ApexMapLabel key={`${poi.poiName}-poi`}
                                  coords={poi.coordinates ?? {x: 0, y: 0}}
                                  mapMeasurements={mapMeasurements}
                                  hidden={!poiLabelsVisible}
                                  priority={1}
                                  className='hover:scale-110 hover:translate-y-[-4px]'>
                      <div onMouseOver={() => setShowcaseTeam(allocatedTeam ?? null)}
                           onTouchStart={() => setShowcaseTeam(allocatedTeam ?? null)}
                           onMouseLeave={() => setShowcaseTeam(null)}>
                        {!allocationsReady || !teamMapLabels ? (
                          <TargetIcon className='w-[10px] transition-all h-auto aspect-square fill-white group-hover:fill-green
                                absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                            stroke-black stroke-[1px]'/>
                        ) : ''}
                        <p className={`${allocationsReady && teamMapLabels ? 'group-hover:text-green' : 'pointer-events-none touch-none -translate-y-[calc(50%-14px)]'} absolute left-1/2 top-1/2 -translate-x-1/2
                                        font-compact uppercase font-medium text-xs custom_xl:text-sm
                                        ${allocationsReady && teamMapLabels && !allocatedTeam ? 'text-white/75' : 'text-white'}`}>
                          <span className={`${poi.congested ? poi.inverted ? '' : 'block translate-y-[20px]' : 'whitespace-nowrap'}
                                            ${poi.inverted ? 'block -translate-y-[100%]' : ''}`}
                                style={{WebkitTextStroke: zoomLevel > 125 ? '1px black' : '2px black', paintOrder: 'stroke fill'}}>
                            {poi.poiName}
                          </span>
                          {allocatedTeam ? (
                            <div className={`absolute top-0 left-1/2 -translate-x-1/2
                                              flex items-center gap-x-2
                                              ${zoomLevel > 125 ? poi.inverted ? '-translate-y-[10px]' : 'translate-y-[5px]'  : poi.inverted ? '-translate-y-[15px]': 'translate-y-[5px]'}`}>
                              <Link to={`/team/${allocatedTeam.teamId}`} target='_blank'
                                    className={`${zoomLevel > 125 ? 'w-[24px] translate-y-[15px] rounded-md' : 'w-[36px] translate-y-[15px] rounded-lg'}
                                                relative h-auto aspect-square bg-lightBlack border border-steelGray`}>
                                <img src={allocatedTeam.teamLogo} alt={`${allocatedTeam.teamName} logo`}
                                      className={`${zoomLevel > 125 ? 'rounded-md' : 'rounded-lg'} object-cover w-full aspect-square h-auto`}/>
                              </Link>
                            </div>
                          ) : ''}
                        </p>
                      </div>
                    </ApexMapLabel>
                  );
                })}



                {mapInfo.respawnBeaconCoords.map((coords) => (
                  <ApexMapLabel key={`${coords.x}-${coords.y}-respawnBeacon`}
                                coords={coords ?? {x: 0, y: 0}}
                                mapMeasurements={mapMeasurements}
                                hidden={!respawnBeaconsVisible}
                                priority={2}
                                className='hover:scale-125 hover:translate-y-[-4px]'>
                    <div className={`w-[4px] h-auto aspect-square rounded-full bg-[#adfb56]
                                    absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`}></div>
                    <div className='block w-[32px] h-[32px]
                                    absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                    pointer-events-none opacity-0 scale-0 group-hover:scale-100 group-hover:opacity-100 group-hover:pointer-events-auto transition-all'>
                      <img src={respawnBeacon}/>
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.surveyBeaconCoords.map((coords) => (
                  <ApexMapLabel key={`${coords.x}-${coords.y}-surveyBeacon`}
                                coords={coords ?? {x: 0, y: 0}}
                                mapMeasurements={mapMeasurements}
                                hidden={!surveyBeaconsVisible}
                                priority={2}
                                className='hover:scale-125 hover:translate-y-[-4px]'>
                    <div className='block w-[20px] h-auto
                                    absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                    group-hover:w-[32px] transition-all'>
                      <img src={surveyBeacon}/>
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.ringConsoleCoords.map((coords) => (
                  <ApexMapLabel key={`${coords.x}-${coords.y}-ringConsole`}
                                coords={coords ?? {x: 0, y: 0}}
                                mapMeasurements={mapMeasurements}
                                hidden={!ringConsolesVisible}
                                priority={2}
                                className='hover:scale-125 hover:translate-y-[-4px]'>
                    <div className='block w-[16px] h-auto
                                    absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                    group-hover:w-[32px] transition-all'>
                      <img src={ringConsole}/>
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.jumpTowerCoords.map((coords) => (
                  <ApexMapLabel key={`${coords.x}-${coords.y}-jumpTower`}
                                coords={coords ?? {x: 0, y: 0}}
                                mapMeasurements={mapMeasurements}
                                hidden={!jumpTowersVisible}
                                priority={2}
                                className='hover:scale-125 hover:translate-y-[-4px]'>
                    <div className='block w-[16px] h-auto
                                    absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                    group-hover:w-[32px] transition-all'>
                      <img src={jumpTower}/>
                    </div>
                  </ApexMapLabel>
                ))}

                {mapInfo.tridentCoords.length > 0 ? (
                  <>
                    {mapInfo.tridentCoords.map((coords) => (
                      <ApexMapLabel key={`${coords.x}-${coords.y}-trident`}
                                    coords={coords ?? {x: 0, y: 0}}
                                    mapMeasurements={mapMeasurements}
                                    hidden={!tridentsVisible}
                                    priority={2}
                                    className='hover:scale-125 hover:translate-y-[-4px]'>
                        <div className='block w-[16px] h-auto
                                        absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
                                        group-hover:w-[32px] transition-all'>
                          <img src={trident}/>
                        </div>
                      </ApexMapLabel>
                    ))}
                  </>
                ) : ''}
              </div>
            </TransitionChild>


            <TransitionChild unmount={false}>
              <div className='transition duration-[150] ease-in data-[closed]:opacity-0 data-[leave]:duration-0'>
                <img ref={imageRef} src={mapImages[map].src} alt={`Apex ${map} map card`}
                    className="relative block w-full h-full image-smooth opacity-[80%] object-contain z-[1] pointer-events-none touch-none"
                    style={{objectPosition: `${width * mapMeasurements.originOffset.x}px ${height * mapMeasurements.originOffset.y}px`}}/>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default ApexMap;
