// content
import { useHeaderContext } from '@provider/HeaderProvider';
import { useAuthContext } from '@provider/AuthContextProvider';
// components
import NotificationMenu from "./Notifications/NotificationMenu";
import ProfileMenu from "./ProfileMenu/ProfileMenu";

const HeaderButton = () => {
  const { userObj } = useAuthContext();
  const { setNavbarState, navbarState } = useHeaderContext();

  const openSignUp = () => {
    setNavbarState({
      ...navbarState,
      signUpMenuVisible: true,
    })
  }

  const openLogIn = () => {
    setNavbarState({
      ...navbarState,
      logInMenuVisible: true,
    })
  }

  return (
    <div className="items-center md:border-l-[0.125rem] border-ebonyClay ps-3 xl:ps-6 gap-5 custom_lg:gap-3 xl:gap-6 flex max-h-[33px]">
      <div>
        {userObj ? (
          <div className='flex items-center gap-x-4'>
            <div className="hidden md:block">
              <NotificationMenu />
            </div>
            <div className="relative z-20 mt-[0.2rem]">
              <div className="flex items-center">
                <ProfileMenu />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='hidden md:flex gap-x-4'>
              <div>
                <button type="button"
                  onClick={openLogIn}
                  className='text-white font-semibold w-fit px-3 py-3 pb-[0.5rem] uppercase font-compact bg-ebonyClay rounded-xl hover:opacity-75 transition-opacity'>
                  Log In
                </button>
              </div>
              <div>
                <button type="button"
                  onClick={openSignUp}
                  className='text-black font-semibold w-fit px-3 py-3 pb-[0.5rem] uppercase font-compact bg-green rounded-xl hover:opacity-75 transition-opacity'>
                  Sign Up
                </button>
              </div>
            </div>
            <div className="md:hidden relative z-20 mt-[0.2rem]">
              <div className="flex items-center">
                <ProfileMenu />
              </div>
            </div>
          </>
        )}
      </div>
      {/* <Sidebar className="md:hidden"/> */}
    </div>
  );
};

export default HeaderButton;
