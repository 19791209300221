import { useState, useEffect, useCallback } from 'react';
// context
import { useTournamentContext } from "@components/tournament/TournamentProvider";
import { formatDateCountdown } from '@utils/Date';

const CheckInConfirmation = () => {
  const { tournament } = useTournamentContext();

  const [timeUntilPlay, setTimeUntilPlay] = useState<string>('');

  const updateTimeUntilPlay = useCallback(() => {
    if (tournament) {
      const newTimeUntilPlay = formatDateCountdown(tournament.statusDates.ongoing);
      setTimeUntilPlay(newTimeUntilPlay);
    }
  }, [tournament]);

  useEffect(() => {
    updateTimeUntilPlay();
    const interval = setInterval(updateTimeUntilPlay, 15000);

    return () => clearInterval(interval);
  }, [updateTimeUntilPlay]);

  if (!tournament) return '';

  return (
    <div className="flex flex-col gap-y-3 text-white font-compact">
      <p>
        Complete your check in and prepare to compete!
      </p>
      <p>
        Groups will be announced shortly with player codes distributed at the event start time.
      </p>
      <div className="flex items-center text-base justify-center gap-x-2 w-full p-2 border-2 border-lightGray rounded-lg
                          uppercase">
        <p>Event Starts:</p>
        <p className="text-green">
          {timeUntilPlay}
        </p>
      </div>
    </div>
  )
}

export default CheckInConfirmation;
