import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
// icons
import { FaMinus, FaPlus } from 'react-icons/fa';

interface IInput {
  value: number,
  onChange: (newValue: number) => void,
  label?: string,
  wrapperClassName?: string,
  labelClassName?: string,
  inputClassName?: string,
  active?: boolean,
  onClick?: (e?: MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  valid?: boolean,
  required?: boolean,
  black?: boolean
  border?: boolean,
  interval?: number,
  minValue?: number,
  maxValue?: number
}

const NumericalInput: React.FC<IInput> =
({active,
  wrapperClassName,
  labelClassName,
  inputClassName,
  value,
  label,
  onChange,
  onClick,
  valid,
  required,
  black,
  border,
  interval,
  minValue,
  maxValue}) => {

    const id = `id-${Math.random().toString(16).slice(2)}`;

    const [localActive, setLocalActive] = useState<boolean>(false);

    const checkForOutsideInputClick: EventListener = useCallback((e: Event) => {
      if (e.target) {
        const target = (e.target as HTMLElement);
        if (!target.classList.contains(`.${id}-class`) && !target.closest(`.${id}-class`)) {
          setLocalActive(false);
        }
      }
    }, [id]);

    useEffect(() => {
      document.addEventListener('mousedown', checkForOutsideInputClick);

      return () => document.removeEventListener('mousedown', checkForOutsideInputClick);
    }, [checkForOutsideInputClick])

  return (
    <div className={`${wrapperClassName} w-full`}>
      {label ? (
        <label htmlFor={`${id}`} className={`${labelClassName} text-sm font-compact text-steelGray`}>
          {label}
        </label>
      ) : ''}
      <div className={`${id}-class ${inputClassName} relative mt-2 w-full h-fit flex items-center gap-x-2`}>
        <button type="button" aria-label='Decrease stage num'
                disabled={minValue !== undefined && value === minValue}
                onClick={() => onChange(value - (interval ? interval : 1))}
                className='flex items-center justify-center p-2 rounded-lg bg-ebonyClay hover:opacity-75 disabled:opacity-50 disabled:hover:opacity-50 transition-opacity text-red/70'>
          <FaMinus/>
        </button>
      <input className={`remove-arrow w-[50px] h-full text-sm text-center font-compact font-normal outline-none border-2 ${border ? 'border-lightGray' : 'border-transparent'} text-white placeholder:text-steelGray rounded-xl p-3
                            ${black ? 'bg-black' : 'bg-lightBlack'}
                            ${localActive || active ? valid !== false ? "!border-green" : "!border-red"
                                      : valid !== false ? "focus:!border-green" : "!border-red/70 focus:!border-red"} transition-all select-none`}
          onClick={(e) => {
            setLocalActive(true);
            onClick ? onClick(e) : '';
          }}
          value={value}
          onChange={(e) => {
            const newValue = parseInt(e.target.value, 10);
            if ((!minValue || newValue >= minValue) && (!maxValue || newValue <= maxValue)) {
              onChange(newValue);
            } else {
              const nativeEvent = e.nativeEvent as InputEvent;
              const numberPressed = parseInt(nativeEvent.data ?? '1', 10);
              if ((!minValue || numberPressed >= minValue) && (!maxValue || numberPressed <= maxValue)) {
                onChange(numberPressed);
              }
            }
          }}
          required={required}
          type='number'
          id={id}
          />
      <button type="button" aria-label='Increase stage num'
              disabled={minValue !== undefined && value === maxValue}
              onClick={() => onChange(value + (interval ? interval : 1))}
              className='flex items-center justify-center p-2 rounded-lg bg-ebonyClay hover:opacity-75 disabled:opacity-50 disabled:hover:opacity-50 transition-opacity text-green'>
        <FaPlus/>
      </button>
      </div>
    </div>
  )
}

export default NumericalInput;
