// types
import { LeaderboardGroup, LeaderboardTeam, useLeaderboardContext } from '@components/tournament/LeaderboardProvider';
// utils
import { positionNumberToString } from '@src/firestore/tournamentPointsSystems';
import { getLegendHeadshot } from '@utils/Games/ApexLegends';
// assets
import podiumWhite from '@assets/images/tournament/podiumWhite.png';
import skullWhite from '@assets/images/tournament/skullWhite.png';
import damageWhite from '@assets/images/tournament/damageWhite.png';
import assistWhite from '@assets/images/tournament/assistWhite.png';
import knockdownWhite from '@assets/images/tournament/knockdownWhite.png';
import reviveWhite from '@assets/images/tournament/reviveWhite.png';
import { useTournamentContext } from '@src/components/tournament/TournamentProvider';
import { ApexMapPoi, ApexMapInfos } from '@src/types/Games/ApexLegends';

interface ILeaderboardGameData {
  team: LeaderboardTeam,
  group: LeaderboardGroup,
}

const LeaderboardGameData: React.FC<ILeaderboardGameData> = ({team, group}) => {
  const { tournamentTeams } = useTournamentContext();
  const { selectedGameView } = useLeaderboardContext();
  const teamGameResults = selectedGameView === 0 ? team.gameResults : team.gameResults.filter((game) => parseInt(game.gameName.split(' ')[1]) === selectedGameView);

  const tournamentTeam = tournamentTeams.find((tournamentTeam) => tournamentTeam.id === team.id)!;
  const teamPoiAllocations = tournamentTeam.poiAllocations[group.groupId];
  const teamPoiAllocationObjects: Record<string, ApexMapPoi> = {};

  for (const map in teamPoiAllocations) {
    const safeMap = map as 'WE' | 'SP' | 'ED';
    const poiNumber = teamPoiAllocations[safeMap];
    teamPoiAllocationObjects[safeMap] = ApexMapInfos[safeMap].pois.find((poi) => poi.poiNumber === poiNumber)!;
  }

  return (
    <div className='flex w-full justify-between'>
      <div className='flex w-full flex-col gap-y-6 mt-[50px]'>
        {teamGameResults.sort((a, b) => a.gamePosition - b.gamePosition).map((gameResult, index) => (
          <div key={`${team.id}-${gameResult.gameId}`} className='w-full flex justify-between'>
            <div>
              <p className='font-compact text-white'>{gameResult.gameName}</p>
              <p className='flex items-center gap-x-2 font-compact uppercase text-steelGray'>
                <span>
                  {gameResult.map}
                </span>
                {teamPoiAllocationObjects[gameResult.map] ? (
                  <span>
                    - {teamPoiAllocationObjects[gameResult.map].poiName}
                  </span>
                ) : ''}
              </p>
            </div>
            <div className='flex gap-x-6'>

            <div className='relative'>
              { index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Legends</p>
              ) : ''}
              <div className='flex items-center gap-x-1 h-[30px]'>
                {gameResult.result.characterName.sort((a, b) => a[0].localeCompare(b[0])).map((char, index) => (
                  <img key={`${char}-${index}`} src={getLegendHeadshot(char)} alt={`${char} legend headshot`}
                        className='h-full w-auto aspect-square'/>
                ))}
              </div>
            </div>

            <div className='relative'>
              {index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Place</p>
              ) : ''}
              <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                <p className='ml-5 font-wide text-white font-semibold uppercase min-w-[40px]'>{positionNumberToString(gameResult.result.teamPlacement)}</p>
                <img src={podiumWhite} className="h-3/4 w-auto aspect-square mb-1" alt="podium icon" />
              </div>
            </div>

            <div className='relative'>
              {index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Kills</p>
              ) : ''}
              <div className='relative flex items-center gap-x-3 h-[30px]'>
                <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                <p className='ml-5 font-wide text-white font-semibold uppercase min-w-[20px]'>{gameResult.result.kills}</p>
                <img src={skullWhite} className="h-3/4 w-auto aspect-square mb-1" alt="skull icon" />
              </div>
            </div>

            <div className='relative'>
              {index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Damage</p>
              ) : ''}
              <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                <p className='ml-5 font-wide text-white font-semibold uppercase min-w-[40px]'>{gameResult.result.damageDealt}</p>
                <img src={damageWhite} className="h-4/5 w-auto aspect-square object-contain mb-1" alt="damage icon" />
              </div>
            </div>

            <div className='relative'>
              {index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Assists</p>
              ) : ''}
              <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                <p className='ml-5 font-wide text-white font-semibold uppercase'>{gameResult.result.assists}</p>
                <img src={assistWhite} className="h-3/4 w-auto aspect-square mb-1" alt="assist icon" />
              </div>
            </div>

            <div className='relative'>
              {index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Knocks</p>
              ) : ''}
              <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-green rounded'></div>
                <p className='ml-5 font-wide text-white font-semibold uppercase'>{gameResult.result.knockdowns}</p>
                <img src={knockdownWhite} className="h-3/4 w-auto aspect-square mb-1" alt="knockdown icon" />
              </div>
            </div>

            <div className='relative'>
              {index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Revives</p>
              ) : ''}
              <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-red rounded'></div>
                <p className='ml-5 font-wide text-white font-semibold uppercase'>{gameResult.result.revivesGiven}</p>
                <img src={reviveWhite} className="h-3/4 w-auto aspect-square mb-1" alt="knockdown icon" />
              </div>
            </div>

            <div className='relative'>
              {index === 0 ? (
                <p className='absolute top-[-46px] left-0 text-steelGray font-compact'>Points</p>
              ) : ''}
              <div className='relative flex items-center gap-x-3 min-w-[70px] h-[30px]'>
                <div className='absolute left-0 top-1/2 -translate-y-1/2 h-3/4 w-[2px] bg-red rounded'></div>
                <p className='ml-5 font-wide text-white font-semibold uppercase'>{gameResult.gameScore}</p>
              </div>
            </div>

            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LeaderboardGameData;
