import { useState } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, deleteDoc } from 'firebase/firestore';
// context
import { useTournamentContext } from "../../TournamentProvider";
import { useAuthContext } from '@provider/AuthContextProvider';
// types
import { TournamentStatus } from "@src/firestore/tournaments";
// packages
import { toast } from 'react-toastify';
// components
import Modal from '@ui/Modal';
// icons
import { ImSpinner8 } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';

const DangerZone = () => {
  const { userObj } = useAuthContext();
  const { tournament } = useTournamentContext();

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const deleteTournament = async () => {
    setSubmitting(true);
    if (userObj && userObj.admin && tournament) {
      const tournamentRef = doc(firestore, 'tournaments', tournament.id);
      const deletePromise = deleteDoc(tournamentRef);

      toast.promise(deletePromise, {
        pending: 'Deleting tournament',
        success: 'Tournament deleted',
        error: 'Error deleting tournament'
      })

      await deletePromise;
    }

    setSubmitting(false);
  }


  return tournament ? (
    <div>
      <button type="button"
              disabled={tournament.status > TournamentStatus.registration || tournament.entrantsCount > 0}
              onClick={() => setDeleteConfirmOpen(true)}
              className="flex items-center justify-center gap-x-2 w-full py-[0.8125rem] text-white bg-red/70 rounded-xl font-compact font-medium text-base !leading-6 uppercase
                        hover:bg-red/80 transition-colors disabled:opacity-50 disabled:hover:bg-red/70">

        <span>
          Delete Tournament
        </span>
        {submitting ? (
          <ImSpinner8 className='animate-spin'/>
        ) : ''}
      </button>
      {tournament.status > TournamentStatus.registration || tournament.entrantsCount > 0 ? (
        <p className='text-red font-compact text-sm my-2'>
          Cannot delete event after registration phase / if teams are registered.
        </p>
      ) : ''}
      <Modal title='Delete Tournament'
             open={deleteConfirmOpen}
             setOpen={setDeleteConfirmOpen}
             buttonNegative={true}
             buttonDisabled={tournament.status < TournamentStatus.registration || tournament.entrantsCount > 0}
             buttonText={(
              <div className='flex items-center gap-x-2'>
                <span>
                  Confirm
                </span>
                {submitting ? (
                  <ImSpinner8 className='animate-spin'/>
                ) : (
                  <FaCheck/>
                )}
              </div>
             )}
             buttonOnClick={deleteTournament}>
        <div className="flex flex-col gap-y-4 text-white/90 font-compact font-thin text-sm">
          <div className="max-w-[90%] mx-auto flex flex-col gap-y-4">
            <p>
              <em className="not-italic text-red/70 uppercase"> WARNING! </em>
              Deleting tournament cannot be undone
            </p>
            <p>
              By confirming you acknowledge that you will be permanently deleting this tournament
            </p>
            <p>
              Are you sure?
            </p>
          </div>
        </div>
      </Modal>
    </div>
  ) : '';
}

export default DangerZone;
