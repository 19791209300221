export const isDiscordUsernameValid = (username: string): boolean => {
  const discordUsernameRegex = /^[a-zA-Z0-9_.]{2,32}$/;
  return discordUsernameRegex.test(username);
}

export const isDiscordLinkValid = (discordLink: string): boolean => {
  const discordLinkRegex = /^((https|http):\/\/)?discord\.gg\/[a-zA-Z0-9_-]+\/?$/i;
  return discordLinkRegex.test(discordLink);
}

export const isTwitterLinkValid = (twitterLink: string): boolean => {
  const twitterLinkRegex = /^((https|http):\/\/)?(www\.)?(twitter\.com|x\.com)\/[a-zA-Z0-9_]{2,15}\/?$/i;
  return twitterLinkRegex.test(twitterLink);
}

export const isTwitchLinkValid = (twitchLink: string): boolean => {
  const twitchLinkRegex = /^((https|http):\/\/)?(www\.)?twitch\.tv\/[a-zA-Z0-9_]{2,15}\/?$/i;
  return twitchLinkRegex.test(twitchLink);
}

export const isYoutubeLinkValid = (youtubeLink: string): boolean => {
  const youtubeLinkRegex = /^((https|http):\/\/)?(www\.)?youtube\.com\/((channel\/[a-zA-Z0-9_]+)|@?[a-zA-Z0-9_]+)\/?$/i;
  return youtubeLinkRegex.test(youtubeLink);
}

export const isInstagramLinkValid = (instagramLink: string): boolean => {
  const instagramLinkRegex = /^((https|http):\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]{2,30}\/?$/i;
  return instagramLinkRegex.test(instagramLink);
}

export const isWebsiteLinkValid = (websiteLink: string): boolean => {
  const websiteLinkRegex = /^((https|http):\/\/)?(www\.)?\w+\.\w+(\w+\/)*\/?$/i;
  return websiteLinkRegex.test(websiteLink);
}
