// STAY UPDATED SECTION GREEN LINE FOR MOBILE
export const LineForMobile = () => {
    return (
        <svg
            className="w-full"
            width="425"
            height="44"
            viewBox="0 0 396 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M533 43H372.751L329.53 1H-72"
                stroke="url(#paint0_linear_1945_22168)"
                strokeWidth="2"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1945_22168"
                    x1="194"
                    y1="22"
                    x2="-80.4992"
                    y2="21.5244"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9AB300" />
                    <stop offset="1" stopColor="#9AB300" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

// INPUT CROSS ICON
export const Cross = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8" cy="8" r="8" fill="#1F272E" />
            <path
                d="M10.945 9.82007L9.12489 8L10.945 6.17993C10.9798 6.14463 10.9994 6.09701 10.9994 6.04738C10.9994 5.99776 10.9798 5.95014 10.945 5.91484L10.0852 5.05505C10.0678 5.0376 10.0471 5.02376 10.0244 5.01431C10.0016 5.00486 9.97724 5 9.95262 5C9.92799 5 9.9036 5.00486 9.88085 5.01431C9.85811 5.02376 9.83745 5.0376 9.82007 5.05505L8 6.87511L6.17993 5.05505C6.14478 5.0199 6.0971 5.00016 6.04738 5.00016C5.99767 5.00016 5.94999 5.0199 5.91484 5.05505L5.05505 5.91484C5.0376 5.93222 5.02376 5.95288 5.01431 5.97562C5.00486 5.99837 5 6.02276 5 6.04738C5 6.07201 5.00486 6.0964 5.01431 6.11915C5.02376 6.14189 5.0376 6.16255 5.05505 6.17993L6.87511 8L5.05505 9.82007C5.0199 9.85522 5.00016 9.9029 5.00016 9.95262C5.00016 10.0023 5.0199 10.05 5.05505 10.0852L5.91484 10.945C5.93222 10.9624 5.95288 10.9762 5.97562 10.9857C5.99837 10.9951 6.02276 11 6.04738 11C6.07201 11 6.0964 10.9951 6.11915 10.9857C6.14189 10.9762 6.16255 10.9624 6.17993 10.945L8 9.12489L9.82007 10.945C9.85522 10.9801 9.9029 10.9998 9.95262 10.9998C10.0023 10.9998 10.05 10.9801 10.0852 10.945L10.945 10.0852C10.9624 10.0678 10.9762 10.0471 10.9857 10.0244C10.9951 10.0016 11 9.97724 11 9.95262C11 9.92799 10.9951 9.9036 10.9857 9.88085C10.9762 9.85811 10.9624 9.83745 10.945 9.82007Z"
                fill="black"
            />
        </svg>
    );
};
