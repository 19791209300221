import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
// utils
import { formatLink } from '@utils/Link';
// libraries
import Markdown from 'markdown-to-jsx';

interface ITextBlock {
  children: ReactNode
}

const MainHeading: React.FC<ITextBlock> = ({children}) => {
  return (
    <h2 className="font-wide font-semibold text-2xl text-white uppercase my-3">
      {children}
    </h2>
  );
}

const SubHeading: React.FC<ITextBlock> = ({children}) => {
  return (
    <h3 className="font-wide font-semibold text-xl text-white uppercase my-2">
      {children}
    </h3>
  );
}

const MiniHeading: React.FC<ITextBlock> = ({children}) => {
  return (
    <h4 className="font-compact font-semibold text-white uppercase my-2">
      {children}
    </h4>
  );
}

const MainText: React.FC<ITextBlock> = ({children}) => {
  return (
    <p className="my-1">
      {children}
    </p>
  );
}

const BoldText: React.FC<ITextBlock> = ({children}) => {
  return (
    <strong className="font-semibold text-white/80 my-1">
      {children}
    </strong>
  );
}

const EmphasisedText: React.FC<ITextBlock> = ({children}) => {
  return (
    <strong className="font-thin text-green/75 my-1">
      {children}
    </strong>
  );
}

interface ILink extends ITextBlock {
  href: string,
}

const LinkText: React.FC<ILink>= ({children, href}) => {
  const externalLink = /^external-/.test(href);
  if (externalLink) {
    href = href.replace('external-', '');
  }

  return (
    <Link to={externalLink ? formatLink(href) : href}
          target={externalLink ? '_blank' : ''}
          className="underline font-compact text-green/75 hover:text-gorse transition-colors">
      {children}
    </Link>
  );
}

interface IMarkdownConverter {
  markdown: string,
  inline?: boolean,
  className?: string
}

const MarkdownConverter: React.FC<IMarkdownConverter> = ({markdown, inline, className}) => {
  const overRideOptions = {
    h1: {
      component: MainHeading,
    },
    h2: {
      component: SubHeading,
    },
    h3: {
      component: MiniHeading,
    },
    strong: {
      component: BoldText,
    },
    em: {
      component: EmphasisedText,
    },
    a: {
      component: LinkText,
    },
    p: {
      component: MainText,
    }
  }

  return (
    <Markdown options={{
                overrides: overRideOptions,
                forceBlock: true,
                enforceAtxHeadings: true,
                forceInline: inline === true
              }}
              className={`${className}`}>
      {markdown}
    </Markdown>
  );
}

export default MarkdownConverter;
