// context
import { useProfileContext } from '../ProfileProvider';
// icons
import ProfileTrophyCard from './ProfileTrophyCard';


interface IProfileTrophies {
  className?: string,
}

const ProfileTrophies: React.FC<IProfileTrophies> = ({ className }) => {
  const { profileTrophies } = useProfileContext();

  return (
    <div className={`${className} rounded-xl w-full`}>
      <h2 className="font-wide font-bold text-white !leading-5 uppercase
                      flex items-center gap-x-2">
        <p>Trophies</p>
      </h2>
      <div className='w-full max-h-[400px] py-[20px] maskedListVert overflow-auto flex flex-row flex-nowrap md:flex-col mt-4 md:mt-0 gap-4'>
        {profileTrophies && profileTrophies.length > 0 ? (
          <>
            {profileTrophies.map((trophy, index) => (
              <ProfileTrophyCard key={`profile-trophy-${index}`} trophy={trophy}/>
            ))}
          </>
        ) : (
          <div className='w-full h-auto flex items-center justify-center bg-lightBlack my-4 rounded-lg aspect-[3.15]'>
            <p className="font-wide font-semibold uppercase text-2xl text-steelGray text-center">
              No trophies
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileTrophies;
