import { useState, useEffect, useCallback } from 'react';
// firebase
import { firestore } from '@src/firebase';
import { doc, updateDoc } from 'firebase/firestore';
// context
import { useAuthContext } from '@provider/AuthContextProvider';
import { useSettingsContext } from "../SettingsProvider";
// types
import { userConverter } from '@src/firestore/users';
// libaries
import { toast } from 'react-toastify';
// components
import Input from "@ui/Input";
import CheckBox from '@ui/CheckBox';
// icons
import { GrayLeftArrow } from "../../common/icons/Header";
import { DiscordIcon } from "@components/common/icons/Socials";
import { ImSpinner8 } from 'react-icons/im';
import { isDiscordUsernameValid } from '@utils/validations/userSocials';

const NotificationSettings = () => {
  const { userObj } = useAuthContext();

  const { setSidebarOpen } = useSettingsContext();

  const [changesMade, setChangesMade] = useState<boolean>(false);

  const [discordUsername, setDiscordUsername] = useState<string>('');
  const [discordUsernameInitiallySet, setDiscordUsernameInitiallySet] = useState<boolean>(false);
  const [discordUsernameValid, setDiscordUsernameValid] = useState<boolean>(false);

  const [discordNotificationsEnabled, setDiscordNotificationsEnabled] = useState<boolean>(false);
  const [discordNotificationsInitiallySet, setDiscordNotificationsEnabledInitiallySet] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSaveChanges = async () => {
    setSubmitting(true);
    if (userObj) {
      try {
        const userRef = doc(firestore, 'users', userObj.id).withConverter(userConverter);
        const updatePromise = updateDoc(userRef, {
          discord: discordUsername,
          discordNotificationsEnabled: discordNotificationsEnabled
        })

        toast.promise(updatePromise, {
          pending: 'Updating notification settings',
          success: 'Notification settings updated',
          error: 'Error updating notification settings'
        })

        await updatePromise;
      } catch (err) {
        console.error(err);
      }
    }
    setSubmitting(false);
  }

  useEffect(() => {
    if (userObj && !discordUsernameInitiallySet) {
      setDiscordUsername(userObj.discord);
      setDiscordUsernameInitiallySet(true);
    }

    if (userObj && !discordNotificationsInitiallySet) {
      setDiscordNotificationsEnabled(userObj.discordNotificationsEnabled);
      setDiscordNotificationsEnabledInitiallySet(true);
    }
  }, [userObj, discordUsernameInitiallySet, discordNotificationsInitiallySet]);

  useEffect(() => {
    if (discordUsernameInitiallySet && !discordUsername) {
      setDiscordNotificationsEnabled(false);
    } else {
      setDiscordUsernameValid(isDiscordUsernameValid(discordUsername));
    }
  }, [discordUsername, discordUsernameInitiallySet]);

  const checkForChanges = useCallback(() => {
    if (userObj) {
      if (discordUsername !== userObj.discord || discordNotificationsEnabled !== userObj.discordNotificationsEnabled) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    }
  }, [userObj, discordUsername, discordNotificationsEnabled]);

  useEffect(() => {
    checkForChanges();
  }, [userObj, discordUsername, discordNotificationsEnabled, checkForChanges])

  return (
    <div className="text-white max-lg:mt-6 min-h-[65vh]">
      <div className="font-wide font-bold max-lg:mt-6 text-base !leading-5 uppercase text-white flex gap-2 items-center lg:hidden">
        <button type='button'
                onClick={() => setSidebarOpen(true)}
                className="block cursor-pointer my-3">
          <GrayLeftArrow />
        </button>
        <h2>Account</h2>
      </div>

      <div className="text-steelGray font-compact flex flex-col gap-y-6">
        <h2 className="text-lg text-white font-semibold">Notifications</h2>
        <div className='flex flex-col gap-y-2'>
          <h3 className='text-white'>Discord</h3>
          <button type="button" aria-label="toggle discord notifications"
                  onClick={() => {
                    if (discordUsername) {
                      setDiscordNotificationsEnabled(!discordNotificationsEnabled)
                    } else {
                      toast.error('Please provide username to enable notifications')
                    }
                  }}
                  className="group w-full flex items-center gap-x-4 p-3 bg-lightBlack rounded-lg hover:bg-lightGray transition-colors">
            <div className="w-[36px] h-auto aspect-square rounded-lg bg-lightGray group-hover:bg-ebonyClay transition-colors
                            flex items-center justify-center">
              <DiscordIcon className="w-[18px] h-auto aspect-square fill-white"/>
            </div>
            <Input value={discordUsername} onChange={(newValue) => setDiscordUsername(newValue)}
                  onClick={(e) => e?.stopPropagation()}
                  wrapperClassName='w-1/2 flex-grow'
                  inputClassName='!mt-0 text-white'
                  valid={discordUsernameValid}
                  placeholder='discord username'
                  border={true}/>
            <CheckBox selected={discordNotificationsEnabled} setSelected={setDiscordNotificationsEnabled} asDiv={true}/>
          </button>
          <button type='button' aria-label='Save changes to notification settings'
                  disabled={!changesMade || submitting || !discordUsernameValid}
                  onClick={handleSaveChanges}
                  className='w-full flex items-center gap-x-2 justify-center text-black font-compact font-semibold uppercase p-2 mt-6
                            rounded-lg bg-green hover:bg-gorse disabled:hover:bg-green disabled:opacity-50'>
            <span>
              Save Changes
            </span>
            {submitting ? (
              <ImSpinner8 className="animate-spin"/>
            ) : ''}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
