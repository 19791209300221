// context
import { ProfileCompletionStep, useProfileInfoContext } from './ProfileInfoProvider';
// components
import ProfileModal from './ProfileModal';
import Step1 from './Step1';
import SocialStep from './SocialsStep';
import Step2 from './Step2';

const CompleteProfile = () => {
  const { currentModalStep, setCurrentModalStep, updateMode} = useProfileInfoContext();

  const changeModalStep = (direction: -1 | 1) => {
    let localModalStep = currentModalStep;
    if (direction === 1) {
      localModalStep += !updateMode && currentModalStep === ProfileCompletionStep.main ? 2 : 1;
      if (localModalStep > ProfileCompletionStep.confirm) localModalStep = ProfileCompletionStep.confirm;
    } else {
      localModalStep -= !updateMode && currentModalStep === ProfileCompletionStep.game ? 2 : 1;
      if (localModalStep < ProfileCompletionStep.main) localModalStep = ProfileCompletionStep.main;
    }
    setCurrentModalStep(localModalStep);
  }

  const closeModal = () => {
    setCurrentModalStep(-1);
  }

  return (
    <div>
      <ProfileModal modalOpen={currentModalStep === ProfileCompletionStep.main}
                    closeModal={closeModal}
                    modalInner={<Step1 closeModal={closeModal} changeModalStep={changeModalStep}/>}/>
      <ProfileModal modalOpen={currentModalStep === ProfileCompletionStep.social}
                    closeModal={closeModal}
                    modalInner={<SocialStep closeModal={closeModal} changeModalStep={changeModalStep}/>}/>
      <ProfileModal modalOpen={currentModalStep === ProfileCompletionStep.game}
                    closeModal={closeModal}
                    modalInner={<Step2 closeModal={closeModal} changeModalStep={changeModalStep}/>}/>
    </div>
  );
}

export default CompleteProfile;
