// Importing BrandLogo components from the BrandsLogo module
import {
  BrandLogoListForMobile,
  BrandLogoSecondListForMobile,
  BrandLogoThirdListForMobile,
  BrandSliderList,
} from "./content/BrandsLogo";

// Component for rendering a brand logo slider
const BrandLogoSlider = () => {
  return (
    <>
      {/* Mobile Brand Logo Slider */}
      <div className="px-12 relative h-[3.125rem] overflow-hidden sm:hidden mt-[4.5rem]">
        {/* First set of brand logos */}
        <div className="flex items-center reverse_brand_slider_parent absolute left-0 top-0 px-4 h-full">
          {BrandLogoSecondListForMobile.map((obj, index) => {
            return (
              <div
                key={index}
                className="mx-4 w-[6.563rem] flex justify-center h-full"
              >
                <img src={obj.image} alt="hero_img_slider" />
              </div>
            );
          })}
        </div>
        {/* Second set of brand logos */}
        <div className="flex items-center reverse_brand_slider_parent2 absolute left-0 top-0 px-4 h-full">
          {BrandLogoSecondListForMobile.map((obj, index) => {
            return (
              <div
                key={index}
                className="mx-[16px] w-[6.563rem] flex justify-center h-full"
              >
                <img src={obj.image} alt="hero_img_slider" />
              </div>
            );
          })}
        </div>
      </div>

      {/* Desktop Brand Logo Slider */}
      <div>
        {/* Main container for the desktop brand logo slider */}
        <div className="px-4 sm:px-8 lg:px-12 relative h-[3.125rem] lg:h-[4.5rem] overflow-hidden my-14 sm:my-24 max-w-[104.3rem] mx-auto">
          {/* First set of brand logos with styling */}
          <div className="hidden sm:flex items-center brand_slider_parent absolute left-0 top-0 px-4 h-full">
            {BrandSliderList.map((obj, index) => {
              return (
                <div
                  key={index}
                  className="mx-10 w-[13.875rem] h-full relative flex justify-center after:content-[''] after:absolute after:top-0 after:-left-10 after:h-full after:w-[0.063rem] after:bg-ebonyClay"
                >
                  <img src={obj.image} alt="hero_img_slider" />
                </div>
              );
            })}
          </div>
          
          {/* Second set of brand logos with styling */}
          <div className="hidden sm:flex items-center brand_slider_parent2 absolute left-0 top-0 px-4 h-full">
            {BrandSliderList.map((obj, index) => {
              return (
                <div
                  key={index}
                  className="mx-[40px] w-[13.875rem] h-full relative flex justify-center after:content-[''] after:absolute after:top-0 after:-left-10 after:h-full after:w-[0.063rem] after:bg-ebonyClay"
                >
                  <img src={obj.image} alt="hero_img_slider" />
                </div>
              );
            })}
          </div>

          {/* Mobile Brand Logo Slider for Desktop */}
          <div className="flex sm:hidden items-center brand_slider_parent absolute left-0 top-0 px-4 h-full">
            {BrandLogoListForMobile.map((obj, index) => {
              return (
                <div
                  key={index}
                  className="mx-[16px] w-[6.563rem] flex justify-center h-full"
                >
                  <img src={obj.image} alt="hero_img_slider" />
                </div>
              );
            })}
          </div>

          {/* Second set of mobile brand logos for Desktop */}
          <div className="flex sm:hidden items-center brand_slider_parent2 absolute left-0 top-0 px-4 h-full">
            {BrandLogoListForMobile.map((obj, index) => {
              return (
                <div
                  key={index}
                  className="mx-[16px] w-[6.563rem] flex justify-center h-full"
                >
                  <img src={obj.image} alt="hero_img_slider" />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Additional Mobile Brand Logo Slider */}
      <div className="px-12 relative h-[3.125rem] overflow-hidden mb-[4.5rem] sm:hidden">
        {/* First set of brand logos */}
        <div className="flex items-center reverse_brand_slider_parent absolute left-0 top-0 px-4 h-full">
          {BrandLogoThirdListForMobile.map((obj, index) => {
            return (
              <div
                key={index}
                className="mx-[16px] w-[6.563rem] flex justify-center h-full"
              >
                <img src={obj.image} alt="hero_img_slider" />
              </div>
            );
          })}
        </div>
        {/* Second set of brand logos */}
        <div className="flex items-center reverse_brand_slider_parent2 absolute left-0 top-0 px-4 h-full">
          {BrandLogoThirdListForMobile.map((obj, index) => {
            return (
              <div
                key={index}
                className="mx-[16px] w-[6.563rem] flex justify-center h-full"
              >
                <img src={obj.image} alt="hero_img_slider" />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BrandLogoSlider;
