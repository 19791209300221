import React, { createContext, useContext, useState, ReactNode } from "react";

interface ILayoutContext {
  listLayout: boolean;
  setListLayout: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialContextValue: ILayoutContext = {
  listLayout: false,
  setListLayout: () => { },
};

export const LayoutContext = createContext<ILayoutContext>(initialContextValue);

// eslint-disable-next-line react-refresh/only-export-components
export function useTournamentProvider(): ILayoutContext {
  const context = useContext(LayoutContext);
  return context;
}

interface ILayoutProvider {
  children: ReactNode;
}
// serves global state for whether certain lists whould be rendered as a grid or vertical list
export const LayoutProvider: React.FC<ILayoutProvider> = ({ children }) => {
  const [listLayout, setListLayout] = useState<boolean>(false);

  const contextValue: ILayoutContext = {
    listLayout,
    setListLayout
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
}
