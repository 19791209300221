// Importing necessary icons from the "FooterIcons" module
import {
  ChatmessageIcon,
  DiscordIcon,
  TwitterIcon,
  YoutubeIcon,
} from "./icons/FooterIcons";

// Collection of social icons with their corresponding URLs
export const SocialFooterIconsCollection = [
  {
    icon: <ChatmessageIcon />,
    url: "https://www.twitch.tv/versus_gg_",
  },
  {
    icon: <YoutubeIcon />,
    url: "https://www.youtube.com/@versus_gg",
  },
  {
    icon: <TwitterIcon />,
    url: "https://twitter.com/gg_versus",
  },
  {
    icon: <DiscordIcon />,
    url: "https://discord.com/invite/versus-gg",
  },
];

// Collection of footer links categorized by sections
export const FooterLinksCollection = [
  {
    title: "Legal",
    orderClass: "order-4 sm:order-1",
    links: [
      {
        url: "/terms",
        links: "Terms of Use",
      },
      {
        url: "/privacy-policy",
        links: "Privacy Policy",
      },
    ],
  },
  {
    title: "Company",
    orderClass: "order-3 sm:order-2",
    links: [
      {
        url: "/about",
        links: "About",
      },
      {
        url: "/services",
        links: "Services",
      },
      {
        url: "/contact",
        links: "Contact",
      },
    ],
  },
  {
    title: "Browse",
    orderClass: "order-1 sm:order-3",
    links: [
      // {
      //   url: "/news",
      //   links: "News",
      // },
      // {
      //   url: "/creators",
      //   links: "Creators",
      // },
      {
        url: "/tournaments",
        links: "Tournaments",
      },
      {
        url: "/teams",
        links: "Teams",
      },
    ],
  },
  {
    title: "Games",
    orderClass: "order-2 sm:order-4",
    links: [
      {
        url: "/tournaments",
        links: "Apex Legends",
      },
      // {
      //   url: "/#valorant",
      //   links: "Valorant",
      // },
      // {
      //   url: "/#fortnite",
      //   links: "Fortnite",
      // },
      // {
      //   url: "/",
      //   links: "Rocket League",
      // },
    ],
  },
];
