import { useEffect, useState } from 'react'
// firebase
import { firestore } from '../../../../firebase';
import { collection, onSnapshot, query } from 'firebase/firestore';
// types
import { ApexPointSystem, apexPointSystemConverter } from '../../../../firestore/tournamentPointsSystems';
// context
import { useTournamentContext } from '../../TournamentProvider';
// components
import PointSystem from './PointSystem';
import CustomPointSystem from './CustomPointSystem';
// icons
import { FaPlus  } from "react-icons/fa";

const Points = () => {
  const { tournament } = useTournamentContext();

  const [pointSystems, setPointSystems] = useState<ApexPointSystem[]>([]);
  const [pointSystemsInitialSet, setPointSystemsInitialSet] = useState<boolean>(false);
  const [currentPointsSystem, setCurrentPointsSystem] = useState<string>('new'); // pointSystem or 'new'

  const getPointSystems = async () => {
    const pointSystemsCollection = collection(firestore, 'apexTournamentPointSystems').withConverter(apexPointSystemConverter);
    const pointSystemsQuery = query(pointSystemsCollection);
    onSnapshot(pointSystemsQuery, (snapshots) => {
      const localPointSystems = snapshots.docs.map((doc) => doc.data() as ApexPointSystem);
      setPointSystems(localPointSystems);
      if (!pointSystemsInitialSet) {
        setCurrentPointsSystem(localPointSystems[0].id);
        setPointSystemsInitialSet(true);
      }
    })
  }

  useEffect(() => {
    getPointSystems();
  }, [])

  return (
    <div>
      <div className='flex items-center gap-x-6 w-full overflow-x-auto'>
        <h3 className="font-wide font-bold text-xl md:text-2xl text-white !leading-6 uppercase whitespace-nowrap">
          Points System
        </h3>

        <div className='flex items-center gap-x-2 w-full'>
          <div className='flex items-center gap-x-2'>
            {pointSystems.map((pointSystem) => (
              <button key={`points-template-button-${pointSystem.id}`}type='button'
                      onClick={() => setCurrentPointsSystem(pointSystem.id)}
                      className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 bg-lightGray font-medium rounded-full transition-colors whitespace-nowrap
                                  ${tournament && tournament.pointSystem === pointSystem.id ?
                                    currentPointsSystem === pointSystem.id ? 'text-green' : 'text-green/60 hover:border-steelGray'
                                  : currentPointsSystem === pointSystem.id ? 'text-white/85' : 'text-steelGray hover:border-steelGray'}`}>
                <span>{pointSystem.name}</span>
              </button>
            ))}
            <button type='button'
                    onClick={() => setCurrentPointsSystem('new')}
                    className={`relative flex items-center gap-x-2 p-1 px-2 border-[1px] border-steelGray/0 bg-lightGray font-medium rounded-full transition-colors whitespace-nowrap
                                ${currentPointsSystem === 'new' ? 'text-white/85' : 'text-steelGray hover:border-steelGray'}`}>
              <span>Add custom</span>
              <FaPlus className='text-lg'/>
            </button>
          </div>
        </div>
      </div>
      {tournament && tournament.pointSystem !== '' && pointSystems.find((pointSystem) => pointSystem.id === tournament.pointSystem) === undefined ? (
        <p className='text-lg font-compact text-red/70 mt-2'>The point system that was used for this tournament has since been removed</p>
      ) : ''}
      <div className='flex mt-6'>
        <div className='w-full lg:w-1/2'>
          {pointSystems.map((pointSystem) => (
            <div key={`points-template-view-${pointSystem.id}`}>
              {currentPointsSystem === pointSystem.id ? (
                <PointSystem pointSystem={pointSystem}/>
              ) : ''}
            </div>
          ))}
          {currentPointsSystem === 'new' ? (
            <CustomPointSystem setCurrentPointsSystem={setCurrentPointsSystem}/>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default Points;
