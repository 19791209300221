import { ReactNode } from "react";
// context
import { useNotificationContext } from "@provider/NotificationProvider";
// types
import { Notification } from "@src/firestore/notifications";
// components
import SurfsharkDiscountNotif from "./surfshark/SurfsharkDiscountNotif";

const BrandedNotifs = () => {
  const { notifications } = useNotificationContext();

  const brandedNotifications = notifications.filter((notif) => notif.recipient === 'branded');

  const renderNotification = (notification: Notification): ReactNode => {
    switch (notification.type) {
      case 'surfsharkDiscount':
        return <SurfsharkDiscountNotif notification={notification}/>
    }
    return '';
  }

  return (
    <>
      {brandedNotifications.map((notif) => (
        <div key={notif.id}>{renderNotification(notif)}</div>
      ))}
    </>
  )
}

export default BrandedNotifs;
